import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// components
import FiltersPlaceholder from '../placeholder'

// mixins
import {
  FilterBar,
  FilterItem,
  FilterIcon,
  FilterBarFooter,
  FilterBarRemoveFilters,
  FilterBarApplied,
  RemoveFilter,
  ActiveFilter,
  ActiveFilterTag,
  FilterName,
  SidebarMenu,
  SidebarMenuTitle,
  SidebarMenuContent,
  SidebarMenuFacet,
} from './mixins'

// store
import { setFilterApplied } from '../../../../../../redux/actions/listado-empresas'
import { FilterCore } from '../../../../../../redux/types/listado-empresas'
import { DataLayerListadoEmpresas } from '../../../../../../constants/gtm-events-listado-empresas'

const FilterSidebar = ({ filters, filtersApplied, filtersSettings, loading, deleteAll, location }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [filtersMenuCollapsed, setFiltersMenuCollapsed] = useState(false)
  const [filterSelected, setFilterSelected] = useState<FilterCore>()

  const { colors } = useContext<{ colors }>(ThemeContext)

  const hasFiltersApplied = filtersApplied.length > 0
  const clearFiltersButtonActivated = deleteAll && hasFiltersApplied

  const queryParams = new URLSearchParams(location.search)

  const type = {
    query: 'query',
    industry: 'industria',
    province: 'ubicacion',
  }

  // set filters in the store
  const handleFilterApplied = (filter, facet) => {
    const facetApplied = filter.facets.find(item => item.id === facet.id)
    const result = [{ type: filter.type, facets: facetApplied }]
    dispatch(setFilterApplied(filtersApplied.concat(result)))
  }

  // set filters in the sidebarmenu
  const setFacetsInSidebarMenu = filter => {
    setFilterSelected({ ...filter, disabled: true })
    setFiltersMenuCollapsed(true)
  }

  // set filters in the store and render into list
  const handleFacetSeleted = (filter, { facetType, facet }) => {
    DataLayerListadoEmpresas.filter(facetType, facet.name)
    handleFilterApplied(filter, facet)
    queryParams.delete('page')
    queryParams.append(type[facetType], facet.id)
    history.push({
      search: queryParams.toString(),
    })

    setFiltersMenuCollapsed(false)
  }

  // delete filters applied
  const handleResetFiltersApplied = () => {
    dispatch(setFilterApplied([]))
    history.replace({
      search: '',
    })
  }

  // delete filters
  const handleRemoveFilterSelected = item => {
    const filterRewrite = filtersApplied.filter(filter => filter.id !== item.id)
    dispatch(setFilterApplied(filterRewrite))
    queryParams.delete('page')
    queryParams.delete(type[item.id])
    history.replace({
      search: queryParams.toString(),
    })
  }

  if (!filters) {
    return null
  }

  if (loading) {
    return <FiltersPlaceholder />
  }

  return (
    <>
      <div>
        {filtersApplied && (
          <FilterBarApplied>
            {filtersApplied.map(item => {
              return (
                <ActiveFilterTag key={item.id}>
                  <ActiveFilter>
                    <FilterName>{item.value}</FilterName>
                    <RemoveFilter onClick={() => handleRemoveFilterSelected(item)}>
                      <Icon name="icon-light-close" color="white" size="16" />
                    </RemoveFilter>
                  </ActiveFilter>
                </ActiveFilterTag>
              )
            })}
          </FilterBarApplied>
        )}

        <FilterBar>
          {filters
            .filter(f => filtersApplied && filtersApplied.every(a => a.id !== f.type))
            .map(filter => (
              <FilterItem key={filter.type} onClick={() => setFacetsInSidebarMenu(filter)}>
                <FilterIcon
                  // eslint-disable-next-line prettier/prettier
                  name={filtersSettings?.filters?.find(item => item.type === filter?.type)?.icon}
                  size="16"
                  color="#231F20"
                />
                {filtersSettings?.filters?.find(item => item.type === filter.type)?.label}
              </FilterItem>
            ))}
          <FilterBarFooter>
            <FilterBarRemoveFilters onClick={() => handleResetFiltersApplied()}>
              <Icon
                name="icon-light-trash-2"
                color={clearFiltersButtonActivated ? `${colors.primary.normal}` : `${colors.grey24}`}
                size="20"
              />
            </FilterBarRemoveFilters>
          </FilterBarFooter>
        </FilterBar>

        <SidebarMenu
          position="left"
          closeType="backFilter"
          visible={filtersMenuCollapsed}
          handleClose={() => setFiltersMenuCollapsed(false)}
        >
          <SidebarMenuTitle>
            <FilterIcon
              name={filtersSettings?.filters?.find(item => item.type === filterSelected?.type)?.icon}
              size="20"
              color={colors.secondary.normal}
            />
            {filtersSettings?.filters?.find(item => item.type === filterSelected?.type)?.label}
          </SidebarMenuTitle>

          <SidebarMenuContent>
            {filterSelected &&
              filterSelected.facets &&
              filterSelected.facets.map(facet => {
                return (
                  <SidebarMenuFacet
                    key={`${facet.id}`}
                    onClick={() => handleFacetSeleted(filterSelected, { facetType: filterSelected.type, facet })}
                  >
                    {facet.name} <span>({facet.quantity})</span>
                  </SidebarMenuFacet>
                )
              })}
          </SidebarMenuContent>
        </SidebarMenu>
      </div>
    </>
  )
}

export default FilterSidebar
