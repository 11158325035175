/**
 * Function que utilizamos para movernos sobre las secciones del micrositio de empresas
 * Esta function esta relacionada con el componente Anchor Bar, obtenemos la "sectionId" (string)
 * para realizar un scroll hacia la seccion
 * @param scrollTop number
 * @param setVisibleid (visibleId: string) => void
 * @param setNavResize (isNavResized: boolean) => void
 * @returns
 */

interface ScrollToSectionTypes {
  scrollTop: number
  setVisibleid: (visibleId: string) => void
  setNavResize: (isNavResized: boolean) => void
}

const setSectionActive = setVisibleid => {
  const detailSection = document.getElementById('section-detalle')?.getBoundingClientRect()
  const companySection = document.getElementById('section-calificaciones')?.getBoundingClientRect()
  const avisoSection = document.getElementById('section-avisos')?.getBoundingClientRect()
  const anchorBar = document.getElementById('anchorBarComponent')?.getBoundingClientRect()

  if (anchorBar) {
    if (detailSection && detailSection?.bottom > anchorBar?.y + anchorBar?.height) {
      setVisibleid('section-detalle')
      return
    }
    if (companySection && companySection?.bottom > anchorBar?.y + anchorBar?.height) {
      setVisibleid('section-calificaciones')
      return
    }
    if (avisoSection && avisoSection?.bottom > anchorBar?.y + anchorBar?.height) {
      setVisibleid('section-avisos')
    }
  }
}

export const scrollToSection = ({ scrollTop, setVisibleid, setNavResize }: ScrollToSectionTypes) => {
  if (scrollTop) {
    setSectionActive(setVisibleid)

    if (scrollTop >= 160) {
      return setNavResize(true)
    }

    setNavResize(false)
  }
}
