import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const Score = styled.div`
  width: 20px;
  margin-right: 4px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-right: 8px;
  }
`

export const StarContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Spacer = styled.div`
  margin-bottom: 12px;
`

export const CustomIcon = styled(Icon)`
  :not(:first-child) {
    margin-left: 4px;
  }
`

export const StarValueContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StarIcon = styled(Icon)`
  align-self: center;
`
