import _ from 'lodash'
import { SearchStack as recentSearches } from '../../../../../services/search-stack'
import features from '../../../../../constants/features-per-country'
import { SITE_ID } from '../../../../../constants'

export interface NuevoFiltro {
  type: string
  facetSelected: { value: string; label: string } | undefined
}

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
const CAPITAL_FEDERAL = searchAvisosV2Enabled ? 'argentina|buenos-aires|capital-federal' : 'capital-federal'

const fixLocation = (listaFiltrosAplicadosNuevos: NuevoFiltro[], allAppliedFilters, filtro) => {
  // Se elimina la provincia del valor de localidad. Esta funcion es la misma que se usa en la uikit, en el hook de filtros
  if (filtro.localidad?.value) {
    const provinciaFilter = allAppliedFilters.find(a => a.type === 'provincia')
    const provinciaId = provinciaFilter?.idSemantico || provinciaFilter?.id
    filtro.localidad.value = filtro.localidad?.value.replace(new RegExp(`-${provinciaId}$`), '')
  }

  const siAlgunoEsProvincia = listaFiltrosAplicadosNuevos?.some(f => f.type.toString() === 'provincia')
  const siAlgunoEsLocalidad = listaFiltrosAplicadosNuevos?.some(f => f.type.toString() === 'localidad')

  if (siAlgunoEsProvincia && !siAlgunoEsLocalidad) {
    const indiceLocalidad = allAppliedFilters?.findIndex(f => f.type === 'localidad')
    if (indiceLocalidad > 0) {
      // Se elimina la localidad cuando se cambia de provincia / Capital Federal en la barra de busqueda
      const provinciaFilter = allAppliedFilters?.find(f => f.type === 'provincia')
      const provincia = provinciaFilter?.idSemantico || provinciaFilter?.id
      const provinciaNuevo = filtro.provincia?.value
      if (
        provincia !== provinciaNuevo ||
        (allAppliedFilters[indiceLocalidad].idSemantico || allAppliedFilters[indiceLocalidad].id) === CAPITAL_FEDERAL
      ) {
        allAppliedFilters.splice(indiceLocalidad, 1)
      }
    }
  }
}

export const pushFilterToRecentSearches = (listaFiltrosAplicadosNuevos: NuevoFiltro[], allAppliedFilters) => {
  const filtro: { localidad?: any; provincia?: any } = {}
  listaFiltrosAplicadosNuevos.forEach(f => {
    if (f.facetSelected) {
      const tipo = f.type.toString()
      filtro[tipo] = f.facetSelected
    }
  })

  fixLocation(listaFiltrosAplicadosNuevos, allAppliedFilters, filtro)

  const filterApplied: any[] = allAppliedFilters?.map(item => {
    let newItemFilter
    const newFilterApplied = {}

    if (item?.id === 'query') {
      newItemFilter = 'busqueda'
      newFilterApplied[newItemFilter] = {
        value: item?.value,
        label: item?.label || item?.value,
      }
    } else {
      newItemFilter = item?.type?.toString()
      newFilterApplied[newItemFilter] = {
        value: item?.idSemantico || item?.id,
        label: item?.name,
      }
    }

    return newFilterApplied
  })

  const newAllFilters = {}

  if (filterApplied) {
    filterApplied.forEach(item => {
      Object.keys(item).forEach(function (key) {
        if (item[key]) {
          newAllFilters[key] = item[key]
        }
      })
    })
  }

  const filtersToPush = { ...newAllFilters, ...filtro }
  Object.keys(filtersToPush).forEach(function (key) {
    if (!filtersToPush[key].value || !filtersToPush[key].value.trim()) {
      delete filtersToPush[key]
    }
  })

  if (!_.isEmpty(filtersToPush)) {
    return recentSearches?.getInstance()?.pushFilters(filtersToPush)
  }
  // en caso de que no haya filtros aplicados
  return '/empleos.html'
}
