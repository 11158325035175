import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const REGISTRAR_ACTIVIDAD = 'REGISTRAR_ACTIVIDAD'
export const REGISTRAR_ACTIVIDAD_SUCCESS = 'REGISTRAR_ACTIVIDAD_SUCCESS'
export const REGISTRAR_ACTIVIDAD_ERROR = 'REGISTRAR_ACTIVIDAD_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface ActividadState {
  registrarActividad: {
    status: StatusType
  }
}

export const initialState: ActividadState = {
  registrarActividad: {
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface RegistrarActividad {
  type: typeof REGISTRAR_ACTIVIDAD
  payload: null
}

export interface RegistrarActividadSuccess {
  type: typeof REGISTRAR_ACTIVIDAD_SUCCESS
  payload: null
}

export interface RegistrarActividadError {
  type: typeof REGISTRAR_ACTIVIDAD_ERROR
  payload: null
}

export type ActividadActions = RegistrarActividad | RegistrarActividadSuccess | RegistrarActividadError
