import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import CtaMobile from './CtaMobile'
import CtaDesktop from './CtaDesktop'

// constants
import { SITE_ID } from '../../constants'
import storeLinks from '../../constants/cta-download-app-links'
import features from '../../constants/features-per-country'

// store
import { setComponentSettings } from '../../redux/actions/cta-download-app'

interface CtaDownloadAppProps {
  type: string
  settings: {
    enable: {
      desktop: boolean
      mobile: boolean
    }
  }
}

const CtaDownloadApp = ({ type, settings }: CtaDownloadAppProps) => {
  const dispatch = useDispatch()
  const [showComponent, setShowComponent] = useState(false)
  const { isMobile } = useWindowSize()
  const { ctaDownloadApp } = features[SITE_ID || 'BMAR']

  useEffect(() => {
    dispatch(setComponentSettings(ctaDownloadApp))
  }, [])

  useEffect(() => {
    if (settings) {
      const declineApp = sessionStorage.getItem('declineApp') === 'true'
      setShowComponent((settings.enable.mobile && isMobile && !declineApp) || (settings.enable.desktop && !isMobile))
    }
  }, [settings, isMobile])

  if (!showComponent) {
    return null
  }

  switch (type) {
    case 'mobile':
      return <CtaMobile storeLinks={storeLinks} />
    case 'desktop':
      return <CtaDesktop storeLinks={storeLinks} />
    default:
      return null
  }
}

const states = ({ ctaDownloadAppStore }) => ({
  settings: ctaDownloadAppStore.settings,
})

export default connect(states)(CtaDownloadApp)
