import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Container, CustomSelect, SelectFilterContainer } from './mixins'

import { SITE_ID } from '../../../../../../constants'
import settings from '../../../../../../constants/features-per-country'
import { mergeFiltersSettings } from '../../utils'
import { FilterWithSettings } from '../../types'

import { setFilterApplied } from '../../../../../../redux/actions/company-summary'

const SelectFilter = ({ handleOnChange, handleOnClear, name, type, filtrosFiltered, filter }) => {
  let options: { id: string; idSemantico: string; name: string; quantity: number }[] = []
  if (filtrosFiltered?.facets?.length > 0) {
    options = filtrosFiltered?.facets
  } else if (filter) {
    options = [filter]
  }

  return (
    <CustomSelect
      placeholder={name}
      name={type}
      onChange={(_typeSelected, valueSelected) => {
        if (valueSelected === null) {
          handleOnClear(valueSelected, type)
        } else {
          handleOnChange(valueSelected, filtrosFiltered)
        }
      }}
      value={filter ? { value: filter?.facets?.name, label: filter?.facets?.name } : null}
      options={options.map(item => ({ value: item.idSemantico || item.id, label: `${item.name} (${item.quantity})` }))}
      reactSelectOptions={{
        isSearchable: true,
        isClearable: true,
      }}
      disabled={options.length === 0}
      noBottomMargin
    />
  )
}

const FilterBar = ({ filtersApplied, filters }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [filtersWithSettings, setFiltersWithSettings] = useState<FilterWithSettings[]>()
  const queryParams = new URLSearchParams(window.location.search)

  const filtersSettings = {
    ...settings[`${SITE_ID}`].filtersPerfilEmpresa,
    filters: settings[`${SITE_ID}`].filtersPerfilEmpresa.filters(),
  }

  const handleFilterApplied = (filter, facet) => {
    const facetApplied = filter.facets.find(item =>
      item.idSemantico ? item.idSemantico === facet.idSemantico : item.id === facet.id
    )
    const result = [{ type: filter.type, facets: facetApplied }]
    dispatch(setFilterApplied(filtersApplied?.concat(result)))
  }

  const deletePageParam = () => {
    queryParams.delete('page')
    history.replace({
      search: queryParams.toString(),
    })
  }

  const handleOnSelect = (e, filter) => {
    deletePageParam()
    const facet = filter?.facets.find(f => (f.idSemantico || f.id) === e.value)
    handleFilterApplied(filter, facet)
  }

  const handleOnClear = (_e, type: string) => {
    deletePageParam()
    let newFilters = []
    if (type === 'provincia') {
      newFilters = filtersApplied?.filter(f => f.type !== 'localidad' && f.type !== 'provincia')
    } else {
      newFilters = filtersApplied?.filter(f => f.type !== type)
    }
    dispatch(setFilterApplied(newFilters))
  }

  useEffect(() => {
    setFiltersWithSettings(mergeFiltersSettings(filters, filtersApplied, filtersSettings?.filters))
  }, [filters, filtersApplied])

  return (
    <Container>
      {filtersWithSettings?.map(filtro => {
        return (
          <SelectFilterContainer>
            <SelectFilter
              handleOnChange={handleOnSelect}
              handleOnClear={handleOnClear}
              name={filtro.label}
              type={filtro.type}
              filtrosFiltered={filtro}
              filter={filtersApplied?.find(f => f.type === filtro.type)}
            />
          </SelectFilterContainer>
        )
      })}
    </Container>
  )
}

export default FilterBar
