import {
  GET_LISTADO_AVISOS_EMPRESA,
  GetListadoAvisosEmpresa,
  GetListadoAvisosEmpresaRequestDto,
  GET_FICHA_EMPRESA,
  GetFichaEmpresa,
  SET_FICHA_EMPRESA_FILTERS_APPLIED,
  FiltersAppliedType,
  SetFichaEmpresaFiltersApplied,
  PostSeguirEmpresa,
  POST_SEGUIR_EMPRESA,
  DELETE_SEGUIR_EMPRESA,
  DeleteSeguirEmpresa,
  GET_SEGUIR_EMPRESA,
  GetSeguirEmpresa,
  PostDeleteSeguirEmpresaReset,
  POST_DELETE_SEGUIR_EMPRESA_RESET,
} from '../types/company-summary/company-summary'

export const getListadoAvisosEmpresa = (payload: GetListadoAvisosEmpresaRequestDto): GetListadoAvisosEmpresa => {
  return {
    type: GET_LISTADO_AVISOS_EMPRESA,
    payload,
  }
}

export const setFilterApplied = (value: FiltersAppliedType[]): SetFichaEmpresaFiltersApplied => {
  return {
    type: SET_FICHA_EMPRESA_FILTERS_APPLIED,
    payload: value,
  }
}

export const getFichaEmpresa = (empresaId: number): GetFichaEmpresa => {
  return {
    type: GET_FICHA_EMPRESA,
    payload: { id: empresaId },
  }
}

export const esSeguidor = (empresaId: number): GetSeguirEmpresa => {
  return {
    type: GET_SEGUIR_EMPRESA,
    payload: empresaId,
  }
}

export const seguirEmpresa = (empresaId: number): PostSeguirEmpresa => {
  return {
    type: POST_SEGUIR_EMPRESA,
    payload: empresaId,
  }
}

export const dejarDeSeguirEmpresa = (empresaId: number): DeleteSeguirEmpresa => {
  return {
    type: DELETE_SEGUIR_EMPRESA,
    payload: empresaId,
  }
}

export const postDeleteSeguirEmpresaReset = (): PostDeleteSeguirEmpresaReset => {
  return {
    type: POST_DELETE_SEGUIR_EMPRESA_RESET,
    payload: null,
  }
}
