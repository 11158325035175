export const START_ACTION = 'START_ACTION'
export const STOP_ACTION = 'STOP_ACTION'
export const REFRESH_ACTION_START = 'REFRESH_ACTION_START'
export const REFRESH_ACTION_STOP = 'REFRESH_ACTION_STOP'

interface UiStore {
  loader: {
    actions: []
    refreshing: []
  }
}

export const initialState: UiStore = {
  loader: {
    actions: [],
    refreshing: [],
  },
}
