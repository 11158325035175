export const SET_COMPONENT_SETTINGS = 'SET_COMPONENT_SETTINGS'

export interface Settings {
  enable: {
    mobile: boolean
    desktop: boolean
  }
}

export interface CtaDownloadAppState {
  settings: Settings | null
}

export const initialState: CtaDownloadAppState = {
  settings: null,
}

export interface SetComponentSettings {
  type: typeof SET_COMPONENT_SETTINGS
  payload: Settings
}

export type CtaDownloadAppActions = SetComponentSettings
