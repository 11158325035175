export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
// Snackbar
export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR
  payload: { text: string; icon: string | null; error: boolean }
}

export interface HideSnackbar {
  type: typeof HIDE_SNACKBAR
  payload: null
}
