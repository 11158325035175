export const GET_ANALYSISCV = 'GET_ANALYSISCV'
export const GET_ANALYSISCV_SUCCESS = 'GET_ANALYSISCV_SUCCESS'
export const GET_ANALYSISCV_ERROR = 'GET_ANALYSISCV_ERROR'

export const POST_ANALYSISCV = 'POST_ANALYSISCV'
export const POST_ANALYSISCV_SUCCESS = 'POST_ANALYSISCV_SUCCESS'
export const POST_ANALYSISCV_ERROR = 'POST_ANALYSISCV_ERROR'

export const POST_ANALYSISCV_PREVIEW = 'POST_ANALYSISCV_PREVIEW'
export const POST_ANALYSISCV_PREVIEW_SUCCESS = 'POST_ANALYSISCV_PREVIEW_SUCCESS'
export const POST_ANALYSISCV_PREVIEW_ERROR = 'POST_ANALYSISCV_PREVIEW_ERROR'
export const POST_ANALYSISCV_PREVIEW_RESET = 'POST_ANALYSISCV_PREVIEW_RESET'

export interface GetAnalysisCv {
  type: typeof GET_ANALYSISCV
  payload: null
}
export interface GetAnalysisCvSuccess {
  type: typeof GET_ANALYSISCV_SUCCESS
  payload: string
}
export interface GetAnalysisCvError {
  type: typeof GET_ANALYSISCV_ERROR
  payload: null
}

export interface PostAnalysisCv {
  type: typeof POST_ANALYSISCV
  payload: null
}
export interface PostAnalysisCvSuccess {
  type: typeof POST_ANALYSISCV_SUCCESS
  payload: string
}
export interface PostAnalysisCvError {
  type: typeof POST_ANALYSISCV_ERROR
  payload: null
}

export interface PostAnalysisCvPreview {
  type: typeof POST_ANALYSISCV_PREVIEW
  payload: null
}
export interface PostAnalysisCvPreviewSuccess {
  type: typeof POST_ANALYSISCV_PREVIEW_SUCCESS
  payload: string
}
export interface PostAnalysisCvPreviewError {
  type: typeof POST_ANALYSISCV_PREVIEW_ERROR
  payload: null
}

export interface PostAnalysisCvPreviewReset {
  type: typeof POST_ANALYSISCV_PREVIEW_RESET
  payload: null
}

export interface AnalysisCvState {
  url: string | null
  previewUrl: string | null
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
  postStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
  previewStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: AnalysisCvState = {
  url: null,
  previewUrl: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
  postStates: {
    loading: false,
    success: false,
    error: false,
  },
  previewStates: {
    loading: false,
    success: false,
    error: false,
  },
}

export type AnalysisCvActions =
  | GetAnalysisCv
  | GetAnalysisCvSuccess
  | GetAnalysisCvError
  | PostAnalysisCv
  | PostAnalysisCvSuccess
  | PostAnalysisCvError
  | PostAnalysisCvPreview
  | PostAnalysisCvPreviewSuccess
  | PostAnalysisCvPreviewError
  | PostAnalysisCvPreviewReset
