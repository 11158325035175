import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from '../../mixins'

const MyLoader = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={271}
        viewBox={`0 0 ${wrapperWidth} 271`}
        backgroundColor="#f3f3f3"
        foregroundColor="#d4d4d4"
        {...props}
      >
        <rect x="16" y="18" rx="4" ry="4" width="115" height="16" />
        <rect x="16" y="46" rx="8" ry="8" width="90" height="56" />
        <rect x="114" y="52" rx="4" ry="4" width="198" height="16" />
        <rect x="114" y="70" rx="4" ry="4" width="155" height="16" />
        <path d={`M 16 224 h ${wrapperWidth - 40} v 1 H 16 z`} />
        <rect x="16" y="243" rx="4" ry="4" width="20" height="20" />
        <rect x={wrapperWidth - 40} y="243" rx="4" ry="4" width="20" height="20" />
        <rect x="16" y="120" rx="8" ry="8" width={wrapperWidth - 40} height="36" />
        <rect x="16" y="172" rx="8" ry="8" width={wrapperWidth - 40} height="36" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default MyLoader
