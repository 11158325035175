import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const FiltersPlaceholder = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={380}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="16" rx="8" ry="8" width={wrapperWidth} height="44" />
        <rect x="0" y="72" rx="8" ry="8" width={wrapperWidth} height="44" />
        <rect x="0" y="128" rx="8" ry="8" width={wrapperWidth} height="44" />
        <rect x="0" y="240" rx="8" ry="8" width={wrapperWidth} height="44" />
        <rect x="0" y="184" rx="8" ry="8" width={wrapperWidth} height="44" />
        <rect x={(wrapperWidth - 20) / 2} y="358" rx="4" ry="4" width="22" height="22" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default FiltersPlaceholder
