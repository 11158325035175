import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CalificacionesPlaceholderTablet = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const barX = 228
  const barWitdh = wrapperWidth - barX - 36
  const middleX = wrapperWidth / 2
  const lastSquareOffset = barX + 4

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width="100%"
        height={435}
        viewBox="0 0 100% 435"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 16 80 C 16 35.817 51.817 0 96 0 s 80 35.817 80 80 H 16 z" />
        <rect x="18" y="92" rx="4" ry="4" width="156" height="24" />
        <rect x="18" y="122" rx="4" ry="4" width="156" height="16" />

        <rect x="196" y="12" rx="4" ry="4" width="12" height="12" />
        <rect x="212" y="12" rx="4" ry="4" width="12" height="12" />
        <rect x="228" y="12" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="12" rx="4" ry="4" width="12" height="12" />

        <rect x="196" y="40" rx="4" ry="4" width="12" height="12" />
        <rect x="212" y="40" rx="4" ry="4" width="12" height="12" />
        <rect x="228" y="40" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="40" rx="4" ry="4" width="12" height="12" />

        <rect x="196" y="68" rx="4" ry="4" width="12" height="12" />
        <rect x="212" y="68" rx="4" ry="4" width="12" height="12" />
        <rect x="228" y="68" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="68" rx="4" ry="4" width="12" height="12" />

        <rect x="196" y="96" rx="4" ry="4" width="12" height="12" />
        <rect x="212" y="96" rx="4" ry="4" width="12" height="12" />
        <rect x="228" y="96" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="96" rx="4" ry="4" width="12" height="12" />

        <rect x="196" y="124" rx="4" ry="4" width="12" height="12" />
        <rect x="212" y="124" rx="4" ry="4" width="12" height="12" />
        <rect x="228" y="124" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="124" rx="4" ry="4" width="12" height="12" />

        <rect x="16" y="166" rx="4" ry="4" width="126" height="16" />
        <rect x="16" y="186" rx="4" ry="4" width="24" height="16" />
        <rect x="46" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="66" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="106" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="86" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="126" y="186" rx="4" ry="4" width="16" height="16" />

        <rect x="228" y="166" rx="4" ry="4" width="126" height="16" />
        <rect x="228" y="186" rx="4" ry="4" width="24" height="16" />
        <rect x="258" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="278" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="318" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="298" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="338" y="186" rx="4" ry="4" width="16" height="16" />

        <rect x="440" y="166" rx="4" ry="4" width="126" height="16" />
        <rect x="440" y="186" rx="4" ry="4" width="24" height="16" />
        <rect x="470" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="490" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="530" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="510" y="186" rx="4" ry="4" width="16" height="16" />
        <rect x="550" y="186" rx="4" ry="4" width="16" height="16" />

        <rect x="16" y="218" rx="4" ry="4" width="126" height="16" />
        <rect x="16" y="238" rx="4" ry="4" width="24" height="16" />
        <rect x="46" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="66" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="106" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="86" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="126" y="238" rx="4" ry="4" width="16" height="16" />

        <rect x="228" y="218" rx="4" ry="4" width="126" height="16" />
        <rect x="228" y="238" rx="4" ry="4" width="24" height="16" />
        <rect x="258" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="278" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="318" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="298" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="338" y="238" rx="4" ry="4" width="16" height="16" />

        <rect x="440" y="218" rx="4" ry="4" width="126" height="16" />
        <rect x="440" y="238" rx="4" ry="4" width="24" height="16" />
        <rect x="470" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="490" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="530" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="510" y="238" rx="4" ry="4" width="16" height="16" />
        <rect x="550" y="238" rx="4" ry="4" width="16" height="16" />

        <rect x={middleX - barWitdh / 2} y="280" rx="4" ry="4" width={barWitdh} height="16" />

        <path d={`M 12 322 h ${wrapperWidth - 24} v 1 H 12 z`} />
        <rect x={middleX - barWitdh / 2} y="351" rx="4" ry="4" width={barWitdh} height="20" />
        <rect x={middleX - 100} y="387" rx="8" ry="8" width="200" height="48" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CalificacionesPlaceholderTablet
