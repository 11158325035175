import {
  DisponibleOfertasActions,
  DisponibleOfertasState,
  SWITCH_DISPONIBLE_OFERTAS,
  SWITCH_DISPONIBLE_OFERTAS_ERROR,
  SWITCH_DISPONIBLE_OFERTAS_SUCCESS,
  initialState,
} from '../types/disponible-ofertas'
import { status } from '../types/status'

const reducers = (
  state: DisponibleOfertasState = initialState,
  action: DisponibleOfertasActions
): DisponibleOfertasState => {
  switch (action.type) {
    case SWITCH_DISPONIBLE_OFERTAS:
      return {
        ...state,
        switchDisponibleOfertas: {
          ...state.switchDisponibleOfertas,
          status: status.loading,
        },
      }
    case SWITCH_DISPONIBLE_OFERTAS_SUCCESS:
      return {
        ...state,
        switchDisponibleOfertas: {
          ...state.switchDisponibleOfertas,
          value: action.payload,
          status: status.success,
        },
      }
    case SWITCH_DISPONIBLE_OFERTAS_ERROR:
      return {
        ...state,
        switchDisponibleOfertas: {
          ...state.switchDisponibleOfertas,
          status: status.error,
        },
      }

    default:
      return state
  }
}

export default reducers
