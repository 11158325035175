export const GET_SETTINGS_SORT = 'GET_SETTINGS_SORT'
export const GET_SETTINGS_SORT_SUCCESS = 'GET_SETTING_SORT_SUCCESS'
export const GET_POSTULACIONES_SETTINGS_SORT = 'GET_POSTULACIONES_SETTINGS_SORT'
export const GET_POSTULACIONES_SETTINGS_SORT_SUCCESS = 'GET_POSTULACIONES_SETTINGS_SORT_SUCCESS'
export const GET_EMPRESAS_SETTINGS_SORT = 'GET_EMPRESAS_SETTINGS_SORT'
export const GET_EMPRESAS_SETTINGS_SORT_SUCCESS = 'GET_EMPRESAS_SETTINGS_SORT_SUCCESS'

export interface GetSettingsSort {
  type: typeof GET_SETTINGS_SORT
  payload: null
}

export interface GetSettingsSortSuccess {
  type: typeof GET_SETTINGS_SORT_SUCCESS
  payload: SortState
}

export interface GetPostulacionesSortSettings {
  type: typeof GET_POSTULACIONES_SETTINGS_SORT
  payload: null
}

export interface GetPostulacionesSortSettingsSuccess {
  type: typeof GET_POSTULACIONES_SETTINGS_SORT_SUCCESS
  payload: SortState
}

export interface GetEmpresasSortSettings {
  type: typeof GET_EMPRESAS_SETTINGS_SORT
  payload: null
}

export interface GetEmpresasSortSettingsSuccess {
  type: typeof GET_EMPRESAS_SETTINGS_SORT_SUCCESS
  payload: SortState
}

export interface SortState {
  enabled: boolean
  initValue: string
  items:
    | {
        key: string
        to: string
        label: string
      }[]
    | null
}

export const initialState: SortState = {
  enabled: false,
  initValue: '',
  items: null,
}

export type SortActions =
  | GetSettingsSort
  | GetSettingsSortSuccess
  | GetPostulacionesSortSettings
  | GetPostulacionesSortSettingsSuccess
  | GetEmpresasSortSettings
  | GetEmpresasSortSettingsSuccess
