export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_RESET = 'GET_NOTIFICATIONS_RESET'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCES'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATIONS_RESET = 'SET_NOTIFICATIONS_RESET'
export const SET_NOTIFICATIONS_SUCCESS = 'SET_NOTIFICATIONS_SUCCESS'
export const SET_NOTIFICATIONS_ERROR = 'SET_NOTIFICATIONS_ERROR'

export interface GetNotifications {
  type: typeof GET_NOTIFICATIONS
  payload: Content[] | undefined
}
export interface GetNotificationsSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS
  payload: Content[]
}

export interface GetNotificationsReset {
  type: typeof GET_NOTIFICATIONS_RESET
  payload: undefined
}

export interface GetNotificationsError {
  type: typeof GET_NOTIFICATIONS_ERROR
  payload: boolean
}

export interface SetNotifications {
  type: typeof SET_NOTIFICATIONS
  payload: {
    value: number
    update: boolean
  }
}

export interface SetNotificationsError {
  type: typeof SET_NOTIFICATIONS_ERROR
  payload: boolean
}

export interface SetNotificationsReset {
  type: typeof SET_NOTIFICATIONS_RESET
  payload: undefined
}

export interface SetNotificationsSuccess {
  type: typeof SET_NOTIFICATIONS_SUCCESS
  payload: number
}

export interface Content {
  nombre: string
  habilitado: boolean
  id: number
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}
export interface NotificationsState {
  notificationSetting: Content[]
  getStates: Status
  setStates: Status
}

export const initialState: NotificationsState = {
  notificationSetting: [],
  getStates: {
    loading: false,
    success: false,
    error: false,
  },
  setStates: {
    loading: false,
    success: false,
    error: false,
  },
}

export type NotificationsActions =
  | GetNotifications
  | SetNotifications
  | GetNotificationsError
  | GetNotificationsReset
  | GetNotificationsSuccess
  | SetNotificationsError
  | SetNotificationsSuccess
  | SetNotificationsReset
