/**
 * Capitaliza palabras
 *
 * @param {*} str string
 * @return string
 */
export const capitalize = str => {
  if (typeof str !== 'string') return ''

  return str
    .split(' ') // Divide la cadena en palabras
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza cada palabra
    .join(' ') // Une las palabras de nuevo en una cadena
}
