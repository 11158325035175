import React from 'react'
import { useTranslation } from 'react-i18next'

// style
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { LegendWrapper, LegendText } from './mixins'

// constants
import { SITE_ID } from '../../../constants'
import { PaymentMPProductSKU } from '../../../constants/payment-mp-product-sku'
import features from '../../../constants/features-per-country'

// images
import pdaLogo from '../../../assets/images/products/products-logos/pda.svg'
import nulingaLogo from '../../../assets/images/products/products-logos/nulinga.svg'
import miguruLogo from '../../../assets/images/products/products-logos/miguru.svg'
import doctorCvLogo from '../../../assets/images/products/products-logos/doctorCv.svg'

// eslint-disable-next-line prettier/prettier
const showProductPda = features[SITE_ID || 'BMAR']?.products?.pda?.marketplace
const showProductNulinga = features[SITE_ID || 'BMAR']?.products?.nulinga?.marketplace
const showProductLabLab = features[SITE_ID || 'BMAR']?.products?.lablab?.marketplace
const showProductDoctorCv = features[SITE_ID || 'BMAR']?.products?.doctorcv?.marketplace

export function useProducts(isUserLoggedIn) {
  const { t } = useTranslation()

  const urlCurriculum = () => {
    return isUserLoggedIn ? '/candidatos/curriculum' : '/login?returnTo=/postulantes/productos'
  }

  const productsMarketplace = [
    {
      key: 'PDA',
      enabled: showProductPda,
      logo: pdaLogo,
      title: t('products.products.pda.title'),
      body: t('products.products.pda.body'),
      price: '',
      href: 'productos/pda',
      alt: 'Logo PDA',
      hrefText: t('products.products.pda.cta'),
      sku: PaymentMPProductSKU.PDA,
      acquiredText: t('products.products.pda.acquiredText'),
      acquiredHref: urlCurriculum(),
    },
    {
      key: 'Nulinga',
      enabled: showProductNulinga,
      logo: nulingaLogo,
      title: t('products.products.nulinga.title'),
      body: t('products.products.nulinga.body'),
      price: '',
      href: 'productos/nulinga',
      alt: 'Logo Nulinga',
      hrefText: t('products.products.nulinga.cta'),
      sku: PaymentMPProductSKU.NULINGA,
      acquiredText: t('products.products.nulinga.acquiredText'),
      acquiredHref: urlCurriculum(),
    },
    {
      key: 'Mi Guru',
      enabled: showProductLabLab,
      logo: miguruLogo,
      title: t('products.products.lablab.title'),
      body: t('products.products.lablab.body'),
      price: '',
      href: 'productos/miguru',
      alt: 'Logo Miguru',
      hrefText: t('products.products.lablab.cta'),
      sku: PaymentMPProductSKU.MIGURU,
      acquiredText: t('products.products.lablab.acquiredText'),
      acquiredHref: 'productos/miguru',
      alternativeAcquired: (
        <LegendWrapper>
          <Icon name="icon-light-verified" size="16" color="#0A26EE" />
          <LegendText>{t('products.products.lablab.discountLegend')}</LegendText>
        </LegendWrapper>
      ),
      tag: (
        <LegendWrapper>
          <Icon name="icon-light-verified" size="16" color="#FFFFFF" />
          <LegendText>{t('products.products.lablab.discountTag')}</LegendText>
        </LegendWrapper>
      ),
    },
    {
      key: 'Doctor Cv',
      enabled: showProductDoctorCv,
      logo: doctorCvLogo,
      title: t('products.products.doctorCv.title'),
      body: t('products.products.doctorCv.body'),
      price: '',
      href: 'productos/doctorcv',
      alt: 'Logo Doctor Cv',
      hrefText: t('products.products.doctorCv.cta'),
      sku: PaymentMPProductSKU.DOCTORCV,
      acquiredText: t('products.products.doctorCv.acquiredText'),
      acquiredHref: urlCurriculum(),
    },
  ]

  return productsMarketplace.filter(product => product.enabled)
}
