import areaCarrousel from './area-carrousel'
import modalActivarCuenta from './modal-activar-cuenta'
import { modalPreguntas } from './modal-preguntas'
import modalDenuncia from './modal-denuncia'
import modalCompartirAviso from './modal-compartir-aviso'
import modalCrearAlerta from './modal-crear-alerta'
import newFeature from './new-feature'
import searchBar from './search-bar'
import errorPages from './error-pages'
import header from './header'
import busquedaEncontrada from './busqueda-encontrada'
import busquedaNoEncontrada from './busqueda-no-encontrada'
import filters from './filters'
import filtersSettings from './filters-settings'
import cantidadEmpleos from './cantidad-empleos'
import fichaAviso from './ficha-aviso'
import fichaAvisoEmptyState from './ficha-aviso-empty-state'
import { seoWordings } from './seo-wordings'
import { facetsName } from './facets-name'
import crearAlerta from './crear-alerta'
import PermanentSearchDialog from './permanent-search'
import ctaDownloadApp from './cta-download-app'
import { testPersonalidad } from './test-personalidad'
import facets from './facets'
import { paymentMP } from './payment-mp'
import modalPostulacionExitosa from './modal-postulacion-exitosa'
import signIn from './sign-in'
import signUp from './sign-up'
import changePassword from './change-password'
import resendEmail from './resend-email'
import changePasswordSuccess from './change-password-success'
import account from './account'
import notificationAlerts from './notification-alerts'
import productsTab from './products-tab'
import postulaciones from './postulaciones'
import socialShare from './social-share'
import landingJovenesProfesionales from './landing-jovenes-profesionales'
import companySummary from './company-summary'
import confirmReference from './confirm-reference'
import { nulinga } from './nulinga'
import { saveJobPosting } from './save-job-posting'
import card from './card'
import { mensajes } from './mensajes'
import { postulacionesStatistics } from './postulaciones-statistics'
import { productLanding } from './product-landing'
import searchBarCompanies from './search-bar-companies'
import listadoEmpresas from './listado-empresas'
import { products } from './products'
import { homeHeader } from './home-header'
import { alertas } from './alertas'
import { review } from './review'
import logoEmpresas from './logo-empresas'
import suggestionsHome from './suggestions-home'
import navbar from './navbar'
import saveCompany from './save-company'
import avisosRecomendadosIA from './avisos-recomendados-ia'
import { perfil } from './perfil'
import feria from './feria'
import { modalActualizacionCv } from './modal-actualizacion-cv'
import bannerConsejos from './banner-consejos'
import legales from './legales'

const es = {
  cv: 'CV',
  razonSocial: {
    ZJAR: 'Zonajobs.com Argentina S.A.',
    BMAR: 'Bumeran.com Argentina S.A.',
    BMCL: 'Laborum.cl Chile S.A.',
    BMEC: 'Multitrabajos.com Ecuador S.A.',
    BMMX: 'Bumeran.com.mx Mexico S.A.',
    BMPA: 'Konzerta.com Panamá S.A.',
    BMPE: 'Bumeran.com Perú S.A.C.',
    BMVE: 'Bumeran.com.ve Venezuela S.A.',
  },
  logoEmpresas,
  ...areaCarrousel,
  ...modalActivarCuenta,
  modalPreguntas,
  modalDenuncia,
  ...modalCompartirAviso,
  ...modalCrearAlerta,
  newFeature,
  suggestionsHome,
  ...searchBar,
  errorPages,
  fichaAviso,
  ...header,
  ...busquedaEncontrada,
  ...busquedaNoEncontrada,
  ...filters,
  ...filtersSettings,
  ...cantidadEmpleos,
  ...fichaAvisoEmptyState,
  seoWordings,
  facets,
  facetsName,
  ...crearAlerta,
  ...PermanentSearchDialog,
  ctaDownloadApp,
  ...testPersonalidad,
  paymentMP,
  ...modalPostulacionExitosa,
  signIn,
  signUp,
  ...changePassword,
  ...resendEmail,
  ...changePasswordSuccess,
  account,
  notificationAlerts,
  ...productsTab,
  products,
  postulaciones,
  ...socialShare,
  companySummary,
  confirmReference,
  landingJovenesProfesionales,
  nulinga,
  saveJobPosting,
  card,
  mensajes,
  postulacionesStatistics,
  productLanding,
  searchBarCompanies,
  listadoEmpresas,
  homeHeader,
  alertas,
  review,
  navbar,
  saveCompany,
  avisosRecomendadosIA,
  perfil,
  feria,
  modalActualizacionCv,
  bannerConsejos,
  legales,
}

export default es
