const modalDenuncia = {
  title: 'Denunciar este aviso',
  titleMotivo: 'Motivo de la denuncia',
  avisoDuplicado: 'Ingresa el enlace',
  textArea: 'Cuentanos que pasó',
  titles: {
    1: '¿Por qué quieres denunciar como <strong>ofensivo y/o discriminatorio</strong> este aviso?',
    2: '¿Por qué quieres denunciar como <strong>prácticas fraudulentas</strong> este aviso?',
    3: '¿Por qué sospechas que este aviso contiene <strong>actividades ilegales</strong>?',
    4: '¿Por qué quieres denunciar como <strong>impreciso o con datos incorrectos</strong> este aviso?',
    5: '¿Por qué quieres denunciar este aviso como <strong>duplicado</strong>?',
    6: '¿Por qué quieres denunciar este aviso?',
  },
}

export default modalDenuncia
