import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  DenunciaAviso,
  GET_MOTIVOS_DENUNCIA,
  GET_MOTIVOS_DENUNCIA_ERROR,
  GET_MOTIVOS_DENUNCIA_SUCCESS,
  POST_DENUNCIA_AVISO,
  POST_DENUNCIA_AVISO_ERROR,
  POST_DENUNCIA_AVISO_SUCCESS,
} from '../types/denuncia'

function* getMotivosDenuncia() {
  try {
    const result = yield call(services.denuncia.getMotivosDenuncia)
    yield put({ type: GET_MOTIVOS_DENUNCIA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_MOTIVOS_DENUNCIA_ERROR, error })
  }
}

function* denunciarAviso({ payload }) {
  try {
    const result = yield call(services.denuncia.denunciar, payload)
    yield put({ type: POST_DENUNCIA_AVISO_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: POST_DENUNCIA_AVISO_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* denuncia() {
  yield takeLatest<any>(GET_MOTIVOS_DENUNCIA, getMotivosDenuncia)
  yield takeLatest<any>(POST_DENUNCIA_AVISO, denunciarAviso)
}
