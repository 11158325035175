import {
  SET_VISIBLE_RESULT,
  SET_VISIBLE_RESULT_SUCCESS,
  SET_VISIBLE_RESULT_ERROR,
  GET_LANGUAGE_TEST_RESULT,
  GET_LANGUAGE_TEST_RESULT_SUCCESS,
  GET_LANGUAGE_TEST_RESULT_ERROR,
  initialState,
  LanguageTestActions,
  LanguageTestState,
} from '../types/language-test'

const reducers = (state: LanguageTestState = initialState, action: LanguageTestActions): LanguageTestState => {
  switch (action.type) {
    case GET_LANGUAGE_TEST_RESULT:
    case SET_VISIBLE_RESULT:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_LANGUAGE_TEST_RESULT_SUCCESS:
      return {
        ...state,
        level: action.payload?.level,
        status: action.payload?.status,
        visible: action.payload?.visible,
        completedAt: action.payload?.completedAt,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_LANGUAGE_TEST_RESULT_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SET_VISIBLE_RESULT_SUCCESS:
      return {
        ...state,
        visible: action.payload?.visible,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SET_VISIBLE_RESULT_ERROR:
      return {
        ...state,
        visible: null,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
