const products = {
  pda: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  nulinga: {
    marketplace: false,
    curriculumMarketplace: false,
    curriculumLanguage: false,
    landing: false,
  },
  lablab: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
    discountPopover: false,
  },
  doctorcv: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  coderhouse: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  nulingaCourse: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  salario: {
    landing: false,
  },
}

export default products
