import React, { useEffect } from 'react'

// Tools
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

// Components
import { Col, Row, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import ModalFooter from './footer'
import LoadingComponent from '../../common/Loading/loading'
import { SITE_ID } from '../../../../constants'

// store
import { resetDeleteAlert } from '../../../../redux/actions/alerts-v2'
import { OperationStatus } from '../../../../redux/types/applicant/commons'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { DeleteAlertType } from '../../../../redux/types/alert-v2'

// tracking
import { DataLayer } from '../../../../constants/gtm-events-alertasv2'

// styles
import { ModalComponent, ModalBodyComponent, ModalBodyContainer, TitleModal } from './mixins'
import imgRemoveAlertBm from '../../../../assets/images/remove-alert.svg'
import imgRemoveAlertZj from '../../../../assets/images/remove-alert-zj.svg'

const imgRemoveAlert = SITE_ID === 'ZJAR' ? imgRemoveAlertZj : imgRemoveAlertBm

interface ModalRemoveAlertProps {
  idAlert: DeleteAlertType
  modalDeleteVisible: boolean
  status: OperationStatus
  onClose: () => void
}

const ModalRemoveAlertV2 = ({ idAlert, modalDeleteVisible, status, onClose }: ModalRemoveAlertProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { error, loading, success } = status

  const multipleDispatch = () => {
    dispatch(showSnackbar(t('alertas.snackbarEliminar.success'), 'icon-light-trash-2'))
    dispatch(resetDeleteAlert())
    DataLayer.handlerAlertDeletedTraking()
    onClose()
  }

  useEffect(() => {
    if (success) {
      multipleDispatch()
    } else if (error) {
      dispatch(showSnackbarError(t('alertas.snackbarEliminar.error')))
    }
  }, [status])

  const showModalBody = () => (
    <ModalBodyComponent>
      <ContainerFluid>
        <Row>
          <Col>
            <ModalBodyContainer>
              <img src={imgRemoveAlert} alt="Eliminar alerta" />
              <TitleModal>{t('alertas.modalEliminar.title')}</TitleModal>
            </ModalBodyContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </ModalBodyComponent>
  )

  return (
    <ModalComponent
      width={575}
      visible={modalDeleteVisible}
      onClose={() => onClose()}
      skin="with-info"
      footer={!loading ? <ModalFooter idAlert={idAlert} /> : null}
    >
      {loading ? <LoadingComponent /> : showModalBody()}
    </ModalComponent>
  )
}

const states = ({ modalStore, alertsV2Store }) => ({
  status: alertsV2Store.deleteAlert.status,
  modalDeleteVisible: modalStore.activeModal === 'modal-delete-alerta',
})

export default connect(states)(ModalRemoveAlertV2)
