import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
  }
`

export const EmptyStateMessage = styled.h3`
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 16px;
  line-height: 20px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const EmptyStateImg = styled.img`
  width: 100%;
  max-width: 260px;
  margin: 0px 0px 16px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 0px 32px 0px 0px;
  }
`
