export const productBannerComponentSelector = (colSizeXl, screen) => {
  const xl = `
    @media (min-width: ${screen.xlMin}) {
      background-size: 460px;
      background-position: 90% 45%;
    }
  `
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      background-size: 280px;
      background-position: 90% 50%;
      padding: 21px 24px;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      height: auto;
      background-size: 220px;
      background-position: 100% 50%;
    }
  `

  if (colSizeXl === 12) {
    return `${md} ${lg} ${xl}`
  }
  if (colSizeXl === 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl === 6) {
    return md
  }
  return ''
}

export const divSelector = (colSizeXl, screen) => {
  const md = `
    @media (min-width: ${screen.mdMin}) {
      display: inline;
    }
  `

  if (colSizeXl >= 4) {
    return md
  }
  return ''
}

export const constainerSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.mdMin}) {
      align-items: center;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      align-items: flex-end;
      flex-direction: row;
    }
  `
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl > 3) {
    return `${md}`
  }
  return ''
}

export const felxboxSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      align-items: center;
      flex-direction: row;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      height: auto;
    }
  `
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl > 3) {
    return `${md}`
  }
  return ''
}

export const imageSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      margin-bottom: 0px;
    }
  `
  if (colSizeXl >= 9) {
    return lg
  }
  return ''
}

export const flexboxTitleSelector = (colSizeXl, screen) => {
  const xl = `
    @media (min-width: ${screen.lgMin}) {
      max-width: 576px;
    }
  `
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      max-width: 436px
      border-left: 1px solid #c3c2c8;
      padding-left: 16px;
      margin-left: 16px;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      max-width: 408px;
    }
  `
  if (colSizeXl === 12) {
    return `${md} ${lg} ${xl}`
  }
  if (colSizeXl === 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl === 6) {
    return md
  }
  return ''
}

export const titleSelector = (colSizeXl, screen) => {
  const xl = `
    @media (min-width: ${screen.lgMin}) {
      max-width: 560px;
    }
  `
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      max-width: 420px
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      max-width: 392px;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      align-items: flex-end;
    }
  `
  if (colSizeXl === 12) {
    return `${md} ${lg} ${xl}`
  }
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl === 6) {
    return md
  }
  return ''
}

export const claimSelector = (colSizeXl, screen) => {
  const md = `
    @media (min-width: ${screen.mdMin}) {
      font-size: 14px;
      line-height: 20px;
      display: inline;
    }
`

  if (colSizeXl > 4) {
    return md
  }
  return ''
}

export const flexboxButtonsSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      align-items: center;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      justify-content: flex-end;
    }
  `

  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl === 6) {
    return `${md}`
  }
  return ''
}

export const linkSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      width: 100%;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      margin-top: 0;
      width: 36px;
      height: 36px;
    }
  `
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }
  if (colSizeXl === 6 || colSizeXl === 4) {
    return `${md}`
  }
  return ''
}

export const iconSelector = (colSizeXl, screen) => {
  const lg = `
    @media (min-width: ${screen.lgMin}) {
      display: none;
    }
  `
  const md = `
    @media (min-width: ${screen.mdMin}) {
      display: inline;
    }
  `
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }

  if (colSizeXl === 6) {
    return md
  }
  return ''
}

export const ctaSelector = (colSizeXl, screen) => {
  const lg = `
  @media (min-width: ${screen.lgMin}) {
    display: inline;
  }
`

  const md = `
    @media (min-width: ${screen.mdMin}) {
      display: none;
    }
  `
  if (colSizeXl >= 9) {
    return `${md} ${lg}`
  }

  if (colSizeXl === 6) {
    return md
  }
  return ''
}
