import {
  GET_AVISOS,
  GET_AVISOS_V2,
  GET_WORDINGS,
  CANTIDAD_EMPLEOS,
  ADS,
  FACET_BANNER,
  RESET_LISTADO_AVISOS_STORE,
  GetAvisos,
  GetWordings,
  GetAvisosParams,
  CantidadEmpleos,
  Ads,
  FacetBanner,
  ResetListadoAvisosStore,
  ActiveLanding,
  SET_ACTIVE_LANDING,
} from '../types/listado-avisos'

export const getAvisos = (value: GetAvisosParams): GetAvisos => {
  return {
    type: GET_AVISOS,
    payload: value,
  }
}

export const getAvisosV2 = (value: GetAvisosParams): GetAvisos => {
  return {
    type: GET_AVISOS_V2,
    payload: value,
  }
}

export const getWordings = (value: any): GetWordings => {
  return {
    type: GET_WORDINGS,
    payload: value,
  }
}

export const cantidadEmpleos = (value: boolean): CantidadEmpleos => {
  return {
    type: CANTIDAD_EMPLEOS,
    payload: value,
  }
}

export const ads = (value: boolean): Ads => {
  return {
    type: ADS,
    payload: value,
  }
}

export const facetBanner = (value: any): FacetBanner => {
  return {
    type: FACET_BANNER,
    payload: value,
  }
}

export const resetListadoAvisosStore = (): ResetListadoAvisosStore => {
  return {
    type: RESET_LISTADO_AVISOS_STORE,
    payload: null,
  }
}

export const setActiveLanding = (landing: string): ActiveLanding => {
  return {
    type: SET_ACTIVE_LANDING,
    payload: landing,
  }
}
