import { ValidateEmail, VALIDATE_EMAIL, VALIDATE_EMAIL_RESET, ValidateEmailReset } from '../types/email'

export const validateEmail = (email: string): ValidateEmail => {
  return {
    type: VALIDATE_EMAIL,
    payload: email,
  }
}

export const validateEmailReset = (): ValidateEmailReset => {
  return {
    type: VALIDATE_EMAIL_RESET,
    payload: null,
  }
}
