import { IS_ZONA_JOBS } from '.'
import ImageResultadoBM from '../assets/images/calificar-empresas/resultado.svg'
import ImageResultadoZJ from '../assets/images/calificar-empresas/resultado-zj.svg'
import emptyDesktopBM from '../assets/images/calificar-empresas/empty-state-desktop.svg'
import emptyDesktopZJ from '../assets/images/calificar-empresas/empty-state-desktop-zj.svg'
import ImgBuscarEmpresaBM from '../assets/images/calificar-empresas/buscar-empresa.svg'
import ImgBuscarEmpresaZJ from '../assets/images/calificar-empresas/buscar-empresa-zj.svg'
import ImgConfirmacionBM from '../assets/images/calificar-empresas/confirmacion.svg'
import ImgConfirmacionZJ from '../assets/images/calificar-empresas/confirmacion-zj.svg'
import PostulanteBM from '../assets/images/calificar-empresas/postulante.svg'
import EmpleadoBM from '../assets/images/calificar-empresas/empleado.svg'
import PostulanteZJ from '../assets/images/calificar-empresas/postulante-zj.svg'
import EmpleadoZJ from '../assets/images/calificar-empresas/empleado-zj.svg'
import LogoReviewBackgroundBM from '../assets/images/product-banners/logo-review-bkg.svg'
import LogoReviewBackgroundZJ from '../assets/images/product-banners/logo-review-bkg-zj.svg'

interface CalificarImg {
  resultado: string
  emptyDesktop: string
  emptyMobile: string
  buscarEmpresa: string
  confirmacion: string
  empleado: string
  postulante: string
  banner: string
}

const calificarImg: CalificarImg = IS_ZONA_JOBS
  ? {
      resultado: ImageResultadoZJ,
      emptyDesktop: emptyDesktopZJ,
      buscarEmpresa: ImgBuscarEmpresaZJ,
      confirmacion: ImgConfirmacionZJ,
      empleado: EmpleadoZJ,
      postulante: PostulanteZJ,
      banner: LogoReviewBackgroundZJ,
    }
  : {
      resultado: ImageResultadoBM,
      emptyDesktop: emptyDesktopBM,
      buscarEmpresa: ImgBuscarEmpresaBM,
      confirmacion: ImgConfirmacionBM,
      empleado: EmpleadoBM,
      postulante: PostulanteBM,
      banner: LogoReviewBackgroundBM,
    }

export default calificarImg
