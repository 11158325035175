import styled from 'styled-components'

export const PageContent = styled.div`
  padding: 0px 0px 16px;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
  }
`

export const CustomMainContainer = styled.div`
  padding-left: 0px;
  padding-right: 0px;
`
