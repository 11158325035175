import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// uikit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import Text from '@navent-jobs/ui-kit/components/text'

// feature
import features from '../../constants/features-per-country'
import { SITE_ID } from '../../constants'

// utils
import { userLoggedIn } from '../../utils/cookies'

// style
import { Wrapper, ContentContainer, Title, Claim, CustomLink, ImgCustom } from './mixins'
import { getApplicantReview } from '../../redux/actions/review'
import { DataLayersReviews } from '../../constants/gtm-events-reviews'

// constants
import calificarImg from '../../constants/calificar-img'

const Component = ({ idCompany, loading, applicantReview, success, isFichaAviso = false, mobileView = false }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isMobile, isTabletPortrait } = useScreenSize()
  const isUserLogged = userLoggedIn()
  const reviewEnabled = features[SITE_ID || 'BMAR'].reviews.enabled

  useEffect(() => {
    if (isUserLogged && idCompany && !success) {
      dispatch(getApplicantReview(idCompany))
    }
  }, [idCompany])

  if (!reviewEnabled || loading) {
    return null
  }

  const handleOnClick = () => {
    if (!applicantReview) {
      DataLayersReviews.goToReview(window.location.pathname)
    }
  }

  return (
    <MainContainer noGutter>
      <Row noGutter>
        <Col noGutter>
          <Wrapper mobileView={mobileView} isFichaAviso={isFichaAviso}>
            {!isFichaAviso && (!mobileView || isTabletPortrait) && !isMobile && (
              <ImgCustom src={calificarImg.emptyDesktop} alt="acceso" />
            )}
            <ContentContainer mobileView={mobileView} isFichaAviso={isFichaAviso}>
              <Title mobileView={mobileView} isFichaAviso={isFichaAviso}>
                <Text size="lg" fontWeight="semibold" variant="secondary" as="h3">
                  {t('review.access.titleEmpty')}
                </Text>
              </Title>
              <Claim mobileView={mobileView} isFichaAviso={isFichaAviso}>
                <Text size="md" variant="gray84">
                  {t('review.access.claim')}
                </Text>
              </Claim>
              <div onClick={handleOnClick}>
                <CustomLink
                  href={
                    isUserLogged
                      ? `/perfiles/${idCompany}/calificacion`
                      : `/login?returnTo=/perfiles/${idCompany}/calificacion`
                  }
                  disabled={applicantReview}
                  linkoptions={{ variant: applicantReview ? 'gray' : 'primary', type: 'fill', size: 'medium' }}
                >
                  {t(!applicantReview ? 'review.access.button' : 'review.access.buttonReviewed')}
                </CustomLink>
              </div>
            </ContentContainer>
          </Wrapper>
        </Col>
      </Row>
    </MainContainer>
  )
}

const mapStateToProps = ({ reviewStore }) => ({
  applicantReview: reviewStore.applicantReview,
  loading: reviewStore.states.loading,
  success: reviewStore.states.success,
})

export default connect(mapStateToProps)(Component)
