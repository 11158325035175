import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  PromedioSalarialOnClick: () => {
    /**
     * El objetivo es conocer el uso que le dan los usuarios al boton de Salarios.
     * Debe dispararse cuando el usuario hace click en el boton de Salarios.
     */

    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado',
      eventAction: 'Ir a Salarios',
      eventLabel: 'BTN header',
    })
  },
}
