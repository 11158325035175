import {
  SET_SAVE_JOB_POSTING,
  DELETE_SAVE_JOB_POSTING,
  SetSaveJobPosting,
  SaveJobPostingRequestDto,
  DeleteSaveJobPosting,
  GetAvisosGuardados,
  GetAvisosGuardadosParams,
  GET_AVISOS_GUARDADOS,
  DeleteSaveJobPostingReset,
  DELETE_SAVE_JOB_POSTING_RESET,
  SetSaveJobPostingReset,
  SET_SAVE_JOB_POSTING_RESET,
} from '../types/save-job-posting'

export const setSaveJobPosting = (payload: SaveJobPostingRequestDto): SetSaveJobPosting => {
  return {
    type: SET_SAVE_JOB_POSTING,
    payload,
  }
}

export const setSaveJobPostingReset = (): SetSaveJobPostingReset => {
  return {
    type: SET_SAVE_JOB_POSTING_RESET,
  }
}

export const deleteSaveJobPosting = (payload: SaveJobPostingRequestDto): DeleteSaveJobPosting => {
  return {
    type: DELETE_SAVE_JOB_POSTING,
    payload,
  }
}

export const deleteSaveJobPostingReset = (): DeleteSaveJobPostingReset => {
  return {
    type: DELETE_SAVE_JOB_POSTING_RESET,
    payload: null,
  }
}

export const getAvisosGuardados = (value: GetAvisosGuardadosParams): GetAvisosGuardados => {
  return {
    type: GET_AVISOS_GUARDADOS,
    payload: value,
  }
}
