import { put, takeLatest } from 'redux-saga/effects'
import { SITE_ID } from '../../constants'
import settings from '../../constants/features-per-country'
import {
  SET_FILTERS_SETTINGS,
  SET_FILTERS_SETTINGS_SUCCESS,
  SET_FILTERS_SETTINGS_ERROR,
  SET_FILTERS_DEFAULT,
  SET_FILTERS_DEFAULT_SUCCESS,
  SET_FILTERS_DEFAULT_ERROR,
  SET_FILTERS_DELETE_ALL,
  SET_FILTERS_DELETE_ALL_SUCCESS,
  SET_FILTERS_DELETE_ALL_ERROR,
  SET_FILTERS_HIDE,
  SET_FILTERS_HIDE_SUCCESS,
  SET_FILTERS_HIDE_ERROR,
} from '../types/filters'

function* setFilterSettings() {
  try {
    const filterSettings = {
      ...settings[`${SITE_ID}`].filters,
      filters: settings[`${SITE_ID}`].filters.filters(),
    }
    yield put({ type: SET_FILTERS_SETTINGS_SUCCESS, payload: filterSettings })
  } catch (error) {
    yield put({ type: SET_FILTERS_SETTINGS_ERROR, error })
  }
}

function* setFiltersDefault({ payload }) {
  try {
    yield put({ type: SET_FILTERS_DEFAULT_SUCCESS, payload })
  } catch (error) {
    yield put({ type: SET_FILTERS_DEFAULT_ERROR, error })
  }
}

function* setFilterDeleteAll({ payload }) {
  try {
    yield put({ type: SET_FILTERS_DELETE_ALL_SUCCESS, payload })
  } catch (error) {
    yield put({ type: SET_FILTERS_DELETE_ALL_ERROR, error })
  }
}

function* setFilterHide({ payload }) {
  const selectedFilterHide = settings[`${SITE_ID}`].filters.filters().filter(filter => filter.type === payload.type)
  const filterSettings = {
    ...settings[`${SITE_ID}`].filters,
    filters: settings[`${SITE_ID}`].filters.filters(),
  }
  const selectedFilterIndex = filterSettings.filters.findIndex(filter => filter.type === payload.type)
  selectedFilterHide[0].disabled = true
  filterSettings.filters.splice(selectedFilterIndex, 1, ...selectedFilterHide.flat())
  try {
    yield put({ type: SET_FILTERS_HIDE_SUCCESS, payload: filterSettings })
  } catch (error) {
    yield put({ type: SET_FILTERS_HIDE_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SET_FILTERS_SETTINGS, setFilterSettings)
  yield takeLatest<any>(SET_FILTERS_DEFAULT, setFiltersDefault)
  yield takeLatest<any>(SET_FILTERS_DELETE_ALL, setFilterDeleteAll)
  yield takeLatest<any>(SET_FILTERS_HIDE, setFilterHide)
}
