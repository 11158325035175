import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Page = styled.div`
  padding: 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    ${({ noPaddingTop }) => !noPaddingTop && 'padding-top: 96px;'};
    ${({ noPaddingBottom }) => !noPaddingBottom && 'padding-bottom: 96px;'};
  }
`

export const ImgCustom = styled.img`
  width: 100%;
  object-position: right;
  object-fit: cover;
  height: ${({ imgHeightMobile }) => (imgHeightMobile && `${imgHeightMobile}`) || '250px'};
  padding: ${({ imgGutterMobile }) => (imgGutterMobile ? '0 16px' : '0')};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 0;
    min-width: 50%;
    height: ${({ imgHeight }) => (imgHeight && `${imgHeight}`) || 'auto'};
    width: auto;
  }
`

export const Container = styled.div`
  margin: auto 16px;
  padding: 28px 0px 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: auto 48px;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 20px;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Response = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
      margin-bottom: 20px;
    }
  }
`

export const CustomIcon = styled(Icon)`
  margin-top: 3px;
  margin-right: 14px;
`

export const CustomText = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const CustomContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.grey0};
  flex-direction: column;
  ${({ mobileStyles }) => mobileStyles};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row;
    ${({ styles }) => styles};
  }
`

export const DescriptionText = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const AnswerTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
`

export const InfoText = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const InfoTextLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.primary.normal};
  padding-left: 0px;
  padding-right: 0px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const CustomDiv = styled.div`
  display: initial;
`

export const ImgLogo = styled.img`
  height: 19px;
  vertical-align: baseline;
  ${({ mobileStyles }) => mobileStyles};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: 24px;
    ${({ styles }) => styles};
  }
`

export const Iframe = styled.iframe`
  width: 100%;
  border-radius: 24px;
  margin: 16px 0 8px;
  height: 180px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    height: 335px;
  }
`
