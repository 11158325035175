import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_CARROUSEL_COMPANIES,
  GET_CARROUSEL_COMPANIES_SUCCESS,
  GET_CARROUSEL_COMPANIES_ERROR,
  SET_FILTERS_SETTINGS_COMPANIES,
  SET_FILTERS_SETTINGS_COMPANIES_SUCCESS,
  SET_FILTERS_SETTINGS_COMPANIES_ERROR,
  SET_FILTERS_APPLIED,
  SET_FILTERS_APPLIED_SUCCESS,
  SET_FILTERS_APPLIED_ERROR,
  GET_COMPANIES_RECOMMENDED_ERROR,
  GET_COMPANIES_RECOMMENDED,
  GET_COMPANIES_RECOMMENDED_SUCCESS,
} from '../types/listado-empresas'
import { SITE_ID } from '../../constants'
import settings from '../../constants/features-per-country'
import { userLoggedIn } from '../../utils/cookies'

const TOTAL_AVISOS_PER_PAGE = 20

function* getCompanies({ payload }) {
  try {
    const result = yield call(services.listadoEmpresas.getCompanies, payload)
    const companies = result && result.content.length > 0 ? result.content : null

    const filters = result ? result.filters : null

    const filtersApplied = result ? result.filtersApplied : null

    const pagination = {
      currentPage: result ? result.number + 1 : 1,
      totalPages: result.total > TOTAL_AVISOS_PER_PAGE ? Math.ceil(result.total / result.size) : 0,
      elementsPerPage: result ? result.size : 20,
      totalElements: result ? result.total : 0,
    }

    const totals = {
      totalEmpresas: result ? result.total : 0,
    }

    const listadoEmpresas = {
      companies,
      filters,
      filtersApplied,
      pagination,
      ...totals,
    }

    yield put({ type: GET_COMPANIES_SUCCESS, payload: listadoEmpresas })
  } catch (error) {
    yield put({ type: GET_COMPANIES_ERROR, error })
  }
}

function* getCarrouselCompanies() {
  try {
    const recommend = settings[SITE_ID || 'BMAR']?.carrouselCompanies?.recommend
    let carrouselCompanies, carrouselRecommended

    if (recommend && userLoggedIn()) {
      carrouselCompanies = yield call(services.listadoEmpresas.getCompaniesRecommended, 4)
      carrouselRecommended = true
    }

    if (!carrouselCompanies || carrouselCompanies.length < 2) {
      const mostWanted = yield call(services.listadoEmpresas.getCompaniesMostWanted)
      carrouselCompanies = mostWanted && mostWanted.content.length > 0 ? mostWanted.content : null
      carrouselRecommended = false
    }

    const listadoEmpresasCarrousel = {
      carrouselCompanies,
      carrouselRecommended,
    }
    yield put({ type: GET_CARROUSEL_COMPANIES_SUCCESS, payload: listadoEmpresasCarrousel })
  } catch (error) {
    yield put({ type: GET_CARROUSEL_COMPANIES_ERROR, error })
  }
}

function* getCompaniesRecommended({ payload }) {
  try {
    const result = yield call(services.listadoEmpresas.getCompaniesRecommended, payload?.pageSize)
    yield put({ type: GET_COMPANIES_RECOMMENDED_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_COMPANIES_RECOMMENDED_ERROR, error })
  }
}

function* setFilterSettingsCompanies() {
  try {
    const filterSettings = {
      ...settings[`${SITE_ID}`].filtersCompanies,
      filters: settings[`${SITE_ID}`].filtersCompanies.filters(),
    }

    yield put({ type: SET_FILTERS_SETTINGS_COMPANIES_SUCCESS, payload: filterSettings })
  } catch (error) {
    yield put({ type: SET_FILTERS_SETTINGS_COMPANIES_ERROR, error })
  }
}

function* setFilterApplied({ payload }) {
  try {
    yield put({ type: SET_FILTERS_APPLIED_SUCCESS, payload })
  } catch (error) {
    yield put({ type: SET_FILTERS_APPLIED_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_COMPANIES, getCompanies)
  yield takeLatest<any>(GET_CARROUSEL_COMPANIES, getCarrouselCompanies)
  yield takeLatest<any>(GET_COMPANIES_RECOMMENDED, getCompaniesRecommended)
  yield takeLatest<any>(SET_FILTERS_SETTINGS_COMPANIES, setFilterSettingsCompanies)
  yield takeLatest<any>(SET_FILTERS_APPLIED, setFilterApplied)
}
