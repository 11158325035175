import {
  MessagesActions,
  MessagesState,
  initialState,
  ENVIAR_MENSAJE,
  ENVIAR_MENSAJE_ERROR,
  ENVIAR_MENSAJE_SUCCESS,
  LEER_CHAT,
  LEER_CHAT_ERROR,
  LEER_CHAT_SUCCESS,
  statusState,
  LEER_CHAT_RESET,
  LISTADO_MENSAJES,
  LISTADO_MENSAJES_ERROR,
  LISTADO_MENSAJES_RESET,
  LISTADO_MENSAJES_SUCCESS,
  MARCAR_LEIDO,
  MARCAR_LEIDO_ERROR,
  MARCAR_LEIDO_SUCCESS,
  ListadoItemType,
} from '../types/messages'

const changeLectura = (items: ListadoItemType[], idMensaje: number) =>
  items.map(item => {
    return { ...item, leido: item.leido || item.id === idMensaje }
  })

const reducers = (state: MessagesState = initialState, action: MessagesActions): MessagesState => {
  switch (action.type) {
    case LISTADO_MENSAJES:
      return {
        ...state,
        listado: {
          ...state.listado,
          status: statusState.loading,
        },
      }
    case LISTADO_MENSAJES_SUCCESS:
      return {
        ...state,
        listado: {
          value: action.payload,
          status: statusState.success,
        },
      }
    case LISTADO_MENSAJES_ERROR:
      return {
        ...state,
        listado: {
          ...state.listado,
          status: statusState.error,
        },
      }
    case LISTADO_MENSAJES_RESET:
      return {
        ...state,
        listado: {
          value: null,
          status: statusState.initial,
        },
      }
    case LEER_CHAT:
      return {
        ...state,
        chat: {
          ...state.chat,
          status: statusState.loading,
        },
      }
    case LEER_CHAT_SUCCESS:
      return {
        ...state,
        chat: {
          value: action.payload,
          status: statusState.success,
        },
      }
    case LEER_CHAT_ERROR:
      return {
        ...state,
        chat: {
          ...state.chat,
          status: statusState.error,
        },
      }
    case LEER_CHAT_RESET:
      return {
        ...state,
        chat: {
          value: null,
          status: statusState.initial,
        },
      }
    case ENVIAR_MENSAJE:
      return {
        ...state,
        envio: {
          ...state.envio,
          status: statusState.loading,
        },
      }
    case ENVIAR_MENSAJE_SUCCESS:
      return {
        ...state,
        envio: {
          ...state.envio,
          status: statusState.success,
        },
      }
    case ENVIAR_MENSAJE_ERROR:
      return {
        ...state,
        envio: {
          ...state.envio,
          status: statusState.error,
        },
      }
    case MARCAR_LEIDO:
      return {
        ...state,
        marca: {
          ...state.marca,
          status: statusState.loading,
        },
      }
    case MARCAR_LEIDO_SUCCESS:
      return {
        ...state,
        marca: {
          ...state.marca,
          status: statusState.success,
        },
        listado: {
          ...state.listado,
          value: changeLectura(state.listado.value ?? [], action.payload.id),
        },
      }
    case MARCAR_LEIDO_ERROR:
      return {
        ...state,
        marca: {
          ...state.marca,
          status: statusState.error,
        },
      }
    default:
      return state
  }
}

export default reducers
