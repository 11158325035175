import styled from 'styled-components'
import { Select } from '@navent-jobs/ui-kit/components/select'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Sort from '../../components/sort'

const selectStyles = `
  .select__control {
    padding: 0;

    .select__value-container {
      padding: 0;
      line-height: 32px;
      font-size: 16px;

      .select__single-value {
        span {
          color: rgba(0, 0, 24, 0.84);
        }
      }
    }

    .select__placeholder {
      color:#000;
    }
  }

  .select__control:hover {
    padding: 0;
  }

  .select__menu {
    left: 0px;
    top: calc( 100% + 4px );

    .select__menu-list {
      .select__option {
        margin-top: 5px !important;
      }
    }
  }
`

export const TitleSectionComponent = styled.div`
  min-height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 16px 28px;
    min-height: 68px;
  }

  @media (min-width: 1024px) {
    min-height: 64px;
    background: linear-gradient(90deg, #3d47f5 16.42%, #f13d89 83.44%);
    padding: 24px 48px;
    margin: 0px;
  }
`

export const PostulacionesPageTitle = styled.div<{ theme }>`
  padding: 16px;
  background-color: #fff;
  margin: 24px 0;
  margin-top: -40px;
  border-radius: 10px;
  height: 80px;
  color: ${props => props.theme.colors.secondary.normal};
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const SortPostulaciones = styled(Sort)`
  flex-direction: column;
  align-items: start;
  margin: 0px 0px 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    align-items: center;
    margin: 16px 0px 16px;
  }
`

export const FiltersContainer = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 7px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-bottom: 3px;
  }
`

export const FilterSelect = styled(Select)``

export const FilterWrapper = styled.div`
  margin-bottom: 16px;
  ${selectStyles};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: 100%;
    max-width: 246px;
    margin-bottom: 0px;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const OptionIcon = styled(Icon)`
  margin-right: 8px;
`

export const OptionLabel = styled.span`
  color: ${({ theme }) => theme.colors.grey60};
  font-size: 14px;
  line-height: 20px;
`

export const Spacer = styled.div`
  margin-bottom: 16px;
`
