import { status } from '../types/status'

import {
  GET_POSTULACIONES,
  GET_POSTULACIONES_SUCCESS,
  GET_POSTULACIONES_ERROR,
  GET_POSTULACION,
  GET_POSTULACION_SUCCESS,
  GET_POSTULACION_ERROR,
  PostulacionesActions,
  initialState,
  PostulacionesState,
  GET_ESTADOS_SELECCION_POSTULACION,
  GET_ESTADOS_SELECCION_POSTULACION_SUCCESS,
  GET_ESTADOS_SELECCION_POSTULACION_ERROR,
  PUT_ESTADO_SELECCION_POSTULACION,
  PUT_ESTADO_SELECCION_POSTULACION_SUCCESS,
  PUT_ESTADO_SELECCION_POSTULACION_ERROR,
  PUT_ESTADO_SELECCION_POSTULACION_RESET,
} from '../types/postulaciones'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: PostulacionesActions): PostulacionesState => {
  switch (action.type) {
    case GET_POSTULACIONES:
      return {
        ...state,
        ...status.loading,
      }
    case GET_POSTULACION:
      return {
        ...state,
        postulacionStates: status.loading,
      }
    case GET_POSTULACIONES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        ...status.success,
      }
    case GET_POSTULACION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        postulacionStates: status.success,
      }
    case GET_POSTULACIONES_ERROR:
      return {
        ...state,
        ...status.error,
      }
    case GET_POSTULACION_ERROR:
      return {
        ...state,
        postulacionStates: status.error,
      }
    case GET_ESTADOS_SELECCION_POSTULACION:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          state: status.loading,
        },
      }
    case GET_ESTADOS_SELECCION_POSTULACION_SUCCESS:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          state: status.success,
          estados: action.payload,
        },
      }
    case GET_ESTADOS_SELECCION_POSTULACION_ERROR:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          state: status.error,
          estados: [],
        },
      }
    case PUT_ESTADO_SELECCION_POSTULACION:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          putState: status.loading,
        },
      }
    case PUT_ESTADO_SELECCION_POSTULACION_SUCCESS:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          putState: status.success,
        },
      }
    case PUT_ESTADO_SELECCION_POSTULACION_ERROR:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          putState: status.error,
        },
      }
    case PUT_ESTADO_SELECCION_POSTULACION_RESET:
      return {
        ...state,
        estados_seleccion: {
          ...state.estados_seleccion,
          putState: status.initial,
        },
      }
    default:
      return state
  }
}

export default reducers
