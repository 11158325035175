import React from 'react'
import CardCompany from '../../../../../../components/card-company/small'
import { CardContainer } from './mixins'
import { getCompanyURL } from '../../../../../../hooks/get-company-url'
import { DataLayerListadoEmpresas } from '../../../../../../constants/gtm-events-listado-empresas'

const CardCompanyComponent = ({ company, loading, isRecommended }) => {
  const dataUrl = {
    cantidadAvisos: company?.jobPostingsQuantity,
    denominacion: company?.name,
    id: company?.companyId,
  }

  const settingsCompanyURL = getCompanyURL({ company: dataUrl })

  const handleOnClick = event => {
    if (!(event.ctrlKey || event.metaKey) && !isRecommended) {
      DataLayerListadoEmpresas.mostWantedCompanies(company?.name)
    }
  }

  return (
    <CardContainer onClick={e => handleOnClick(e)}>
      <CardCompany sameCardHeight={true} company={company} settingsCompanyURL={settingsCompanyURL} loading={loading} />
    </CardContainer>
  )
}

export default CardCompanyComponent
