import React, { useContext } from 'react'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'styled-components'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useTranslation } from 'react-i18next'

// components
import Title from './components/Title'
import Company from './components/Company'
import Preguntas from './components/Preguntas'

// mixins
import {
  CardHeaderContainer,
  CardHeader,
  LogoWrapper,
  Logo,
  TitleAndCompany,
  LogoTitleWrapper,
  OffsetRight,
  Flexbox,
  LinkCustom,
  IconCustom,
  CustomButton,
  EstadoWrapper,
  EstadisticasContainer,
  CustomText,
} from './mixins'

// constants
import { DataLayers } from '../../../../../../constants/gtm-events-button-estaisticas'
import EstadoPostulacion from '../CardFooter/EstadoPostulacion'

const AccessStatistics = ({ id, sectionCard, colors }) => {
  /**
   * Renderizamos el acceso a estadisticas sólo si el usuario esta en la page Mis Postulaciones, no en el detalle de la postulación
   */
  if (sectionCard !== 'mis-postulaciones') {
    return null
  }

  return (
    <LinkCustom
      href={`/postulantes/postulaciones/estadisticas-${id}`}
      onClick={() => {
        DataLayers.VerEstadisticasOnClick('postulaciones')
      }}
      linkoptions={{
        size: 'large',
        variant: 'primary',
        type: 'link',
      }}
    >
      <EstadisticasContainer>
        <IconCustom name="icon-light-graph" size="22" color={colors.primary.normal} />
        <CustomText>Estadísticas</CustomText>
      </EstadisticasContainer>
    </LinkCustom>
  )
}

const Component = ({
  refTo,
  onClick,
  onClickSendAnswers,
  sectionCard,
  isLoading,
  postulacion,
  activeAvisoStates,
  onClickChangeStatus,
}) => {
  const { estado, empresa, logoURL, titulo, id, estadoSeleccion, cantidadComentarios } = postulacion
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()

  const hasAvisoFinalizado = estado === 'finalizada'
  const confidencial = empresa === 'Confidencial' || empresa === null
  const disableEstadoSeleccion = estadoSeleccion === 'SELECCIONADO' || estadoSeleccion === 'NO_SELECCIONADO'
  const totalComments = cantidadComentarios === null ? '0' : cantidadComentarios
  const avisoTipoPostingNormal = postulacion.tipoDePostingId === 1
  const showStatistics = !hasAvisoFinalizado && avisoTipoPostingNormal
  const showEstadoSeleccion = avisoTipoPostingNormal

  const EstadoSeleccionButton = () => (
    <CustomButton
      id="cambiarEstado"
      buttonoptions={{
        variant: 'primary',
        block: false,
        size: 'small',
        type: 'fill',
      }}
      type="button"
      onClick={() => (disableEstadoSeleccion ? undefined : onClickChangeStatus(id, titulo))}
      disabled={disableEstadoSeleccion}
    >
      <CustomText>
        {estadoSeleccion
          ? t(`postulaciones.seleccion_estado.estados.${estadoSeleccion}`)
          : t(`postulaciones.seleccion_estado.actualizarEstado`)}
      </CustomText>
    </CustomButton>
  )

  return (
    <CardHeaderContainer>
      <CardHeader hasCompanyName={!confidencial} hasLogo={!isEmpty(logoURL)}>
        {!isDesktop && (
          <EstadoWrapper estado={estado}>
            <EstadoPostulacion estado={estado} totalComments={totalComments} />
          </EstadoWrapper>
        )}
        <LogoTitleWrapper>
          {!isEmpty(logoURL) && (
            <LogoWrapper>
              <Logo src={logoURL} hasAvisoFinalizado={hasAvisoFinalizado} />
            </LogoWrapper>
          )}

          <TitleAndCompany>
            <Title
              id={id}
              refTo={refTo}
              onClick={onClick}
              titulo={titulo}
              estado={estado}
              tipoDePostingId={postulacion.tipoDePostingId}
            />
            <Company confidencial={confidencial} empresa={empresa} />
          </TitleAndCompany>
        </LogoTitleWrapper>

        <OffsetRight>
          <Preguntas
            postulacion={postulacion}
            onClickSendAnswers={onClickSendAnswers}
            isLoading={isLoading}
            hasAvisoFinalizado={hasAvisoFinalizado}
            activeAvisoStates={activeAvisoStates}
            sectionCard={sectionCard}
          />

          <Flexbox>
            {showEstadoSeleccion && <EstadoSeleccionButton />}
            {showStatistics && <AccessStatistics id={id} sectionCard={sectionCard} colors={colors} />}
          </Flexbox>
        </OffsetRight>
      </CardHeader>
    </CardHeaderContainer>
  )
}

export default Component
