export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Provincia',
      localidadTitle: 'Cantón',
    },
  },
  cvAdjunto: {
    title: '{{cv}} adjunta',
    confirmDelete: '¿Quieres eliminar tu {{cv}} adjunta?',
  },
  legales:
    'es responsable por la Base de Datos. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos personalmente o por correo postal a la dirección Av. 6 de Diciembre E10A y Jean Boussingault, Edificio Torre 6, piso 11, Oficina 1103, Parroquia Iñaquito, Cantón Quito, Provincia de Pichincha (170508), Ecuador. ',
  preferenciaSalarial: {
    salarioDescription: 'Sueldo bruto pretendido: ',
    form: {
      salarioPlaceholder: 'Sueldo bruto pretendido',
    },
  },
  onboarding: {
    steps: {
      bienvenida: {
        info: 'Podrás revisar y editar tus datos desde la {{cv}}.',
      },
      cvParcer: {
        volverAlCv: 'Volver a la {{cv}}',
        parcerInfo: 'La conversión de la {{cv}} puede tardar hasta 1 minuto.',
      },
      modalidadCargaDeDatos: {
        linkedinRegistro: 'Podés descargar tu {{cv}} de Linkedin y subirla en el próximo paso',
      },
    },
  },
  bannerOnboardingAccess: {
    new: {
      subtitle: '¡Tu {{cv}} está incompleta! Súbela en pdf y nuestra IA la autocompletará.',
    },
  },
}
