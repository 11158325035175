import { Request, Post } from '@navent-jobs/config'
import { SwitchDisponibleOfertasType } from '../redux/types/disponible-ofertas'

export class ErrorApplicant extends Error {}

class DisponibleOfertas {
  public async postDisponibleOfertas(disponible: SwitchDisponibleOfertasType) {
    const request = new Request()
    request.path = `/api/disponible-ofertas?disponible=${disponible}`
    request.method = Post
    return request.call().catch(error => {
      throw new ErrorApplicant('Error al cambiar disponibile-ofertas', error)
    })
  }
}

const disponibleOfertasService = new DisponibleOfertas()
export default disponibleOfertasService
