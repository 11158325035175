import React from 'react'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import CardPlaceholder from './CardPlaceholder'
import CardPlaceholderMobile from './CardPlaceholderMobile'

const ListadoAvisosPlaceholder = ({ total }) => {
  const { isDesktop } = useWindowSize()

  return (
    <>
      {[...Array(total)].map(key => {
        if (!isDesktop) {
          return <CardPlaceholderMobile key={key} />
        }

        return <CardPlaceholder key={key} />
      })}
    </>
  )
}

export default ListadoAvisosPlaceholder
