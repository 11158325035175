import { FilterApplied } from '../redux/types/filters'
import { makeURL } from '../utils/search-avisos-v2'

export class ErrorBreadcrumb extends Error {}

declare global {
  interface Window {
    SEO_PROTOCOL: any
    SEO_HOST: any
  }
}

interface BreadcrumbLink {
  title: string
  link: string
}

const BASE_URL = `${window.SEO_PROTOCOL || window.location.protocol}//${window.SEO_HOST || window.location.host}`

// Filtros que se muestran en el breadcrumb (se muestran en el orden de esta lista)
const FILTROS_EN_BREADCRUMB = [
  'area',
  'subarea',
  'provincia',
  'localidad',
  'tipo_trabajo',
  'nivel_laboral',
  'modalidad_trabajo',
  'apto_discapacitado',
]

class Breadcrumb {
  public createBreadcrumbLinks = (filtersApplied: FilterApplied[]): BreadcrumbLink[] => {
    const links: BreadcrumbLink[] = []
    const bolsaEmpleo = { title: `Bolsa de empleo`, link: makeURL({}) }

    if (!filtersApplied) {
      links.push(bolsaEmpleo)
      return links
    }

    // Dejo solo los filtros que van en el breadcrumb
    const filtros = filtersApplied.filter(filtro => FILTROS_EN_BREADCRUMB.includes(filtro.type))

    if (!filtros.length) {
      links.push(bolsaEmpleo)
      return links
    }

    const filtrosId = Object.assign({}, ...filtros.map(filtro => ({ [filtro.type]: filtro.idSemantico || filtro.id })))
    const filtrosNombre = Object.assign({}, ...filtros.map(filtro => ({ [filtro.type]: filtro.name })))

    const filtrosEnBreadCrumb = FILTROS_EN_BREADCRUMB.slice().reverse()
    filtrosEnBreadCrumb.forEach(filtro => {
      if (filtrosId[filtro]) {
        links.push({ title: filtrosNombre[filtro], link: makeURL(filtrosId) })
      }
      delete filtrosId[filtro]
    })

    // Bolsa de empleo es el primero
    links.push(bolsaEmpleo)

    links.reverse()
    return links
  }

  public makeBreadcrumbsSEOJSON = (breadcrumbLinks: BreadcrumbLink[]): string => {
    const data = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        breadcrumbLinks.map((link, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@id': `${BASE_URL}${link.link}`,
              name: link.title,
            },
          }
        }),
      ],
    }

    return JSON.stringify(data)
  }
}

const breadcrumb = new Breadcrumb()
export default breadcrumb
