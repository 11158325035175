export const POST_TO_APPLY = 'POST_TO_APPLY'
export const POST_TO_APPLY_SUCCESS = 'POST_TO_APPLY_SUCCESS'
export const POST_TO_APPLY_ERROR = 'POST_TO_APPLY_ERROR'

export const SEND_ANSWERS = 'SEND_ANSWERS'
export const SEND_ANSWERS_SUCCESS = 'SEND_ANSWERS_SUCCESS'
export const SEND_ANSWERS_ERROR = 'SEND_ANSWERS_ERROR'
export const SEND_ANSWERS_RESET = 'SEND_ANSWERS_RESET'

export interface PostToApplyRequestDto {
  actualizarSalario: boolean
  id: string
  salarioBrutoPretendido?: string
  feria?: boolean
  respuestas?: {}[]
  preguntasObligatorias: boolean
}

export interface PostToApply {
  type: typeof POST_TO_APPLY
  payload: PostToApplyRequestDto
}

export interface PostToApplySuccess {
  type: typeof POST_TO_APPLY_SUCCESS
  payload: null
}

export interface PostToApplyError {
  type: typeof POST_TO_APPLY_ERROR
  payload: boolean
}

export interface SendAnswersRequestDto {
  idPostulacion: number
  respuestas: {}[]
}

export interface SendAnswers {
  type: typeof SEND_ANSWERS
  payload: SendAnswersRequestDto
}

export interface SendAnswersSuccess {
  type: typeof SEND_ANSWERS_SUCCESS
  payload: null
}

export interface SendAnswersError {
  type: typeof SEND_ANSWERS_ERROR
  payload: boolean
}

export interface SendAnswersRest {
  type: typeof SEND_ANSWERS_RESET
  payload: null
}

export interface PostularBarState {
  PostToApply: {
    data: {} | null
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
  SendAnswers: {
    data: {} | null
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
}

export const initialState: PostularBarState = {
  PostToApply: {
    data: {} || null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  SendAnswers: {
    data: {} || null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
}

export type PostularBarAction =
  | PostToApply
  | PostToApplySuccess
  | PostToApplyError
  | SendAnswers
  | SendAnswersSuccess
  | SendAnswersError
  | SendAnswersRest
