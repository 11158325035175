export const facetsName = {
  // EXAMPLE:
  // tipo_trabajo: { //FACET TYPE
  //   pasantia: 'Práctica Profesional', // FACET_ID: "FACET NAME TRANSLATED"
  // },
  apto_discapacitado: {
    'discapacitados-apto': 'Postulantes con discapacidad',
  },
  url: {
    salario: {
      'salario-sin-especificar': 'salario-sin-especificar',
    },
    'tipo-trabajo': {
      'full-time': 'full-time',
      'part-time': 'part-time',
      pasantia: 'pasantia',
      'por-horas': 'por-horas',
      temporario: 'temporario',
      'por-contrato': 'por-contrato',
      teletrabajo: 'teletrabajo',
      'primer-empleo': 'primer-empleo',
      'fines-de-semana': 'fines-de-semana',
      voluntario: 'voluntario',
    },
    area: {
      'administracion-contabilidad-y-finanzas': 'administracion-contabilidad-y-finanzas',
      'aduana-y-comercio-exterior': 'aduana-y-comercio-exterior',
      'abastecimiento-y-logistica': 'abastecimiento-y-logistica',
      'ingenieria-civil-y-construccion': 'ingenieria-civil-y-construccion',
      'comercial-ventas-y-negocios': 'comercial-ventas-y-negocios',
      'educacion-docencia-e-investigacion': 'educacion-docencia-e-investigacion',
      'gastronomia-y-turismo': 'gastronomia-y-turismo',
      'gerencia-y-direccion-general': 'gerencia-y-direccion-general',
      legales: 'legales',
      'marketing-y-publicidad': 'marketing-y-publicidad',
      'produccion-y-manufactura': 'produccion-y-manufactura',
      'recursos-humanos-y-capacitacion': 'recursos-humanos-y-capacitacion',
      'comunicacion-relaciones-institucionales-y-publicas': 'comunicacion-relaciones-institucionales-y-publicas',
      'tecnologia-sistemas-y-telecomunicaciones': 'tecnologia-sistemas-y-telecomunicaciones',
      'oficios-y-otros': 'oficios-y-otros',
      'salud-medicina-y-farmacia': 'salud-medicina-y-farmacia',
      'atencion-al-cliente-call-center-y-telemarketing': 'atencion-al-cliente-call-center-y-telemarketing',
      'secretarias-y-recepcion': 'secretarias-y-recepcion',
      seguros: 'seguros',
      'mineria-petroleo-y-gas': 'mineria-petroleo-y-gas',
      ingenierias: 'ingenierias',
      diseno: 'diseno',
      enfermeria: 'enfermeria',
      'sociologia-trabajo-social': 'sociologia-trabajo-social',
      'departamento-tecnico': 'departamento-tecnico',
    },
    subarea: {
      administracion: 'administracion',
      finanzas: 'finanzas',
      'organizacion-y-metodos': 'organizacion-y-metodos',
      'creditos-y-cobranzas': 'creditos-y-cobranzas',
      'cuentas-corrientes': 'cuentas-corrientes',
      'evaluacion-economica': 'evaluacion-economica',
      'inversiones-proyectos-de-inversion': 'inversiones-proyectos-de-inversion',
      'planeamiento-economico-financiero': 'planeamiento-economico-financiero',
      tesoreria: 'tesoreria',
      'analisis-de-riesgos': 'analisis-de-riesgos',
      'corporate-finance-banca-inversion': 'corporate-finance-banca-inversion',
      consultoria: 'consultoria',
      contabilidad: 'contabilidad',
      auditoria: 'auditoria',
      facturacion: 'facturacion',
      'control-de-gestion': 'control-de-gestion',
      impuestos: 'impuestos',
      'finanzas-internacionales': 'finanzas-internacionales',
      'ventas-internacionales-exportacion': 'ventas-internacionales-exportacion',
      'apoderado-aduanal': 'apoderado-aduanal',
      'compras-internacionales-importacion': 'compras-internacionales-importacion',
      'consultorias-comercio-exterior': 'consultorias-comercio-exterior',
      logistica: 'logistica',
      distribucion: 'distribucion',
      compras: 'compras',
      'almacen-deposito-expedicion': 'almacen-deposito-expedicion',
      abastecimiento: 'abastecimiento',
      'asistente-de-trafico': 'asistente-de-trafico',
      transporte: 'transporte',
      'direccion-de-obra': 'direccion-de-obra',
      'seguridad-e-higiene': 'seguridad-e-higiene',
      arquitectura: 'arquitectura',
      urbanismo: 'urbanismo',
      construccion: 'construccion',
      operaciones: 'operaciones',
      topografia: 'topografia',
      instrumentacion: 'instrumentacion',
      'ingenieria-civil': 'ingenieria-civil',
      comercial: 'comercial',
      ventas: 'ventas',
      'desarrollo-de-negocios': 'desarrollo-de-negocios',
      'planeamiento-comercial': 'planeamiento-comercial',
      'comercio-exterior': 'comercio-exterior',
      'negocios-internacionales': 'negocios-internacionales',
      'educacion-docentes': 'educacion-docentes',
      'bienestar-estudiantil': 'bienestar-estudiantil',
      'investigacion-y-desarrollo': 'investigacion-y-desarrollo',
      educacion: 'educacion',
      idiomas: 'idiomas',
      'educacion-especial': 'educacion-especial',
      casinos: 'casinos',
      camareros: 'camareros',
      gastronomia: 'gastronomia',
      turismo: 'turismo',
      hoteleria: 'hoteleria',
      'gerencia-direccion-general': 'gerencia-direccion-general',
      direccion: 'direccion',
      legal: 'legal',
      'asesoria-legal-internacional': 'asesoria-legal-internacional',
      'media-planning': 'media-planning',
      'community-management': 'community-management',
      marketing: 'marketing',
      'business-intelligence': 'business-intelligence',
      producto: 'producto',
      creatividad: 'creatividad',
      multimedia: 'multimedia',
      'e-commerce': 'e-commerce',
      'mercadotecnia-internacional': 'mercadotecnia-internacional',
      produccion: 'produccion',
      'programacion-de-produccion': 'programacion-de-produccion',
      mantenimiento: 'mantenimiento',
      calidad: 'calidad',
      seleccion: 'seleccion',
      capacitacion: 'capacitacion',
      'compensacion-y-planilla': 'compensacion-y-planilla',
      'recursos-humanos': 'recursos-humanos',
      'administracion-de-personal': 'administracion-de-personal',
      'capacitacion-comercio-exterior': 'capacitacion-comercio-exterior',
      'responsabilidad-social': 'responsabilidad-social',
      'relaciones-institucionales-publicas': 'relaciones-institucionales-publicas',
      comunicacion: 'comunicacion',
      periodismo: 'periodismo',
      'comunicaciones-internas': 'comunicaciones-internas',
      'comunicaciones-externas': 'comunicaciones-externas',
      internet: 'internet',
      'tecnologia-sistemas': 'tecnologia-sistemas',
      'data-entry': 'data-entry',
      'administracion-de-base-de-datos': 'administracion-de-base-de-datos',
      'analisis-funcional': 'analisis-funcional',
      'liderazgo-de-proyecto': 'liderazgo-de-proyecto',
      'data-warehousing': 'data-warehousing',
      programacion: 'programacion',
      redes: 'redes',
      'seguridad-informatica': 'seguridad-informatica',
      sistemas: 'sistemas',
      infraestructura: 'infraestructura',
      'testing-qa-qc': 'testing-qa-qc',
      'analisis-de-datos': 'analisis-de-datos',
      'tecnologias-de-la-informacion': 'tecnologias-de-la-informacion',
      telecomunicaciones: 'telecomunicaciones',
      'soporte-tecnico': 'soporte-tecnico',
      'practicas-profesionales': 'practicas-profesionales',
      otros: 'otros',
      independientes: 'independientes',
      'arte-y-cultura': 'arte-y-cultura',
      'back-office': 'back-office',
      cadeteria: 'cadeteria',
      caja: 'caja',
      'mantenimiento-y-limpieza': 'mantenimiento-y-limpieza',
      'oficios-y-profesiones': 'oficios-y-profesiones',
      'promotoras-es': 'promotoras-es',
      seguridad: 'seguridad',
      'transporte-de-pasajeros': 'transporte-de-pasajeros',
      'jovenes-profesionales': 'jovenes-profesionales',
      'estetica-y-cuidado-personal': 'estetica-y-cuidado-personal',
      'pasantia-trainee': 'pasantia-trainee',
      planeamiento: 'planeamiento',
      traduccion: 'traduccion',
      servicios: 'servicios',
      farmaceutica: 'farmaceutica',
      medicina: 'medicina',
      veterinaria: 'veterinaria',
      bioquimica: 'bioquimica',
      quimica: 'quimica',
      'anatomia-patologica-tecnicos': 'anatomia-patologica-tecnicos',
      'anestesia-tecnicos': 'anestesia-tecnicos',
      'auditoria-medica': 'auditoria-medica',
      'cosmiatra-dermocosmiatria': 'cosmiatra-dermocosmiatria',
      'diagnostico-por-imagenes': 'diagnostico-por-imagenes',
      salud: 'salud',
      emergentologia: 'emergentologia',
      'enfermeria-ver-enfermeria': 'enfermeria-ver-enfermeria',
      esterilizacion: 'esterilizacion',
      fonoaudiologia: 'fonoaudiologia',
      'hoteleria-hospitalaria': 'hoteleria-hospitalaria',
      'instrumentacion-quirurgica': 'instrumentacion-quirurgica',
      'kinesiologia-terapia-ocupacional': 'kinesiologia-terapia-ocupacional',
      'medicina-laboral': 'medicina-laboral',
      'medicina-transfusional': 'medicina-transfusional',
      'neurologia-tecnicos': 'neurologia-tecnicos',
      nutricion: 'nutricion',
      odontologia: 'odontologia',
      'otras-areas-tecnicas-en-salud': 'otras-areas-tecnicas-en-salud',
      'otras-especialidades-medicas': 'otras-especialidades-medicas',
      'practicas-cardiologicas': 'practicas-cardiologicas',
      psicologia: 'psicologia',
      'traslados-choferes-camilleros': 'traslados-choferes-camilleros',
      'farmacia-comercial': 'farmacia-comercial',
      'farmacia-hospitalaria': 'farmacia-hospitalaria',
      'farmacia-industrial': 'farmacia-industrial',
      'prevencion-de-riesgo': 'prevencion-de-riesgo',
      laboratorio: 'laboratorio',
      'atencion-al-cliente': 'atencion-al-cliente',
      telemarketing: 'telemarketing',
      'call-center': 'call-center',
      asistente: 'asistente',
      telefonista: 'telefonista',
      secretaria: 'secretaria',
      recepcionista: 'recepcionista',
      seguros: 'seguros',
      'administracion-de-seguros': 'administracion-de-seguros',
      'venta-de-seguros': 'venta-de-seguros',
      'tecnico-de-seguros': 'tecnico-de-seguros',
      'auditoria-de-seguros': 'auditoria-de-seguros',
      'ingenieria-en-petroleo-y-petroquimica': 'ingenieria-en-petroleo-y-petroquimica',
      'ingenieria-en-minas': 'ingenieria-en-minas',
      'exploracion-minera-y-petroquimica': 'exploracion-minera-y-petroquimica',
      'instrumentacion-minera': 'instrumentacion-minera',
      'ingenieria-geologica': 'ingenieria-geologica',
      'seguridad-industrial': 'seguridad-industrial',
      'medio-ambiente': 'medio-ambiente',
      'mineria-petroleo-gas': 'mineria-petroleo-gas',
      'ingenieria-automotriz': 'ingenieria-automotriz',
      'ingenieria-electrica-y-electronica': 'ingenieria-electrica-y-electronica',
      'ingenieria-textil': 'ingenieria-textil',
      'ingenieria-mecanica': 'ingenieria-mecanica',
      'ingenieria-industrial': 'ingenieria-industrial',
      'ingenieria-metalurgica': 'ingenieria-metalurgica',
      'ingenieria-quimica': 'ingenieria-quimica',
      'otras-ingenierias': 'otras-ingenierias',
      'ingenieria-de-procesos': 'ingenieria-de-procesos',
      'ingenieria-de-producto': 'ingenieria-de-producto',
      'ingenieria-de-ventas': 'ingenieria-de-ventas',
      'ingenieria-electromecanica': 'ingenieria-electromecanica',
      'ingenieria-en-alimentos': 'ingenieria-en-alimentos',
      'ingenieria-oficina-tecnica-proyecto': 'ingenieria-oficina-tecnica-proyecto',
      'ingenieria-agronoma': 'ingenieria-agronoma',
      diseno: 'diseno',
      'diseno-de-interiores-decoracion': 'diseno-de-interiores-decoracion',
      'diseno-grafico': 'diseno-grafico',
      'diseno-industrial': 'diseno-industrial',
      'diseno-multimedia': 'diseno-multimedia',
      'diseno-textil-e-indumentaria': 'diseno-textil-e-indumentaria',
      'diseno-web': 'diseno-web',
      'diseno-3d': 'diseno-3d',
      'central-de-emergencias-adultos': 'central-de-emergencias-adultos',
      'central-de-emergencias-pedriatricas': 'central-de-emergencias-pedriatricas',
      'clinica-medica': 'clinica-medica',
      consultorios: 'consultorios',
      'cuidados-intensivos-cardiologicos-adultos': 'cuidados-intensivos-cardiologicos-adultos',
      hemodinamia: 'hemodinamia',
      'internacion-domiciliaria': 'internacion-domiciliaria',
      'internacion-general-pediatrica': 'internacion-general-pediatrica',
      maternidad: 'maternidad',
      nefrologia: 'nefrologia',
      oncologia: 'oncologia',
      'recien-nacido-sano': 'recien-nacido-sano',
      'recuperacion-de-anestesia': 'recuperacion-de-anestesia',
      'terapia-intensiva-adultos': 'terapia-intensiva-adultos',
      'terapia-intensiva-pediatrica': 'terapia-intensiva-pediatrica',
      'terapia-intermedia-adultos': 'terapia-intermedia-adultos',
      'terapia-intermedia-pediatrica': 'terapia-intermedia-pediatrica',
      'terapia-neonatal': 'terapia-neonatal',
      vacunatorio: 'vacunatorio',
      'trabajo-social': 'trabajo-social',
      'educacion-docencia': 'educacion-docencia',
      'terapia-intermedia-e-intensiva': 'terapia-intermedia-e-intensiva',
      'central-de-emergencias': 'central-de-emergencias',
      enfermeria: 'enfermeria',
      psicopedagogia: 'psicopedagogia',
      garantias: 'garantias',
      'gps-y-alarma-de-seguridad': 'gps-y-alarma-de-seguridad',
    },
    discapacitados: {
      'apto-discapacitado': 'apto',
    },
    distancia: {
      '2': '2km',
      '3': '3km',
      '4': '4km',
      '5': '5km',
      '10': '10km',
      '20': '20km',
      '30': '30km',
      '40': '40km',
      '50': '50km',
    },
  },
  'url-vuelta': {
    salario: {
      'salario-sin-especificar': 'salario-sin-especificar',
    },
    'tipo-trabajo': {
      'full-time': 'full-time',
      'part-time': 'part-time',
      pasantia: 'pasantia',
      'por-horas': 'por-horas',
      temporario: 'temporario',
      'por-contrato': 'por-contrato',
      teletrabajo: 'teletrabajo',
      'primer-empleo': 'primer-empleo',
      'fines-de-semana': 'fines-de-semana',
      voluntario: 'voluntario',
    },
    area: {
      'administracion-contabilidad-y-finanzas': 'administracion-contabilidad-y-finanzas',
      'aduana-y-comercio-exterior': 'aduana-y-comercio-exterior',
      'abastecimiento-y-logistica': 'abastecimiento-y-logistica',
      'ingenieria-civil-y-construccion': 'ingenieria-civil-y-construccion',
      'comercial-ventas-y-negocios': 'comercial-ventas-y-negocios',
      'educacion-docencia-e-investigacion': 'educacion-docencia-e-investigacion',
      'gastronomia-y-turismo': 'gastronomia-y-turismo',
      'gerencia-y-direccion-general': 'gerencia-y-direccion-general',
      legales: 'legales',
      'marketing-y-publicidad': 'marketing-y-publicidad',
      'produccion-y-manufactura': 'produccion-y-manufactura',
      'recursos-humanos-y-capacitacion': 'recursos-humanos-y-capacitacion',
      'comunicacion-relaciones-institucionales-y-publicas': 'comunicacion-relaciones-institucionales-y-publicas',
      'tecnologia-sistemas-y-telecomunicaciones': 'tecnologia-sistemas-y-telecomunicaciones',
      'oficios-y-otros': 'oficios-y-otros',
      'salud-medicina-y-farmacia': 'salud-medicina-y-farmacia',
      'atencion-al-cliente-call-center-y-telemarketing': 'atencion-al-cliente-call-center-y-telemarketing',
      'secretarias-y-recepcion': 'secretarias-y-recepcion',
      seguros: 'seguros',
      'mineria-petroleo-y-gas': 'mineria-petroleo-y-gas',
      ingenierias: 'ingenierias',
      diseno: 'diseno',
      enfermeria: 'enfermeria',
      'sociologia-trabajo-social': 'sociologia-trabajo-social',
      'departamento-tecnico': 'departamento-tecnico',
    },
    subarea: {
      administracion: 'administracion',
      finanzas: 'finanzas',
      'organizacion-y-metodos': 'organizacion-y-metodos',
      'creditos-y-cobranzas': 'creditos-y-cobranzas',
      'cuentas-corrientes': 'cuentas-corrientes',
      'evaluacion-economica': 'evaluacion-economica',
      'inversiones-proyectos-de-inversion': 'inversiones-proyectos-de-inversion',
      'planeamiento-economico-financiero': 'planeamiento-economico-financiero',
      tesoreria: 'tesoreria',
      'analisis-de-riesgos': 'analisis-de-riesgos',
      'corporate-finance-banca-inversion': 'corporate-finance-banca-inversion',
      consultoria: 'consultoria',
      contabilidad: 'contabilidad',
      auditoria: 'auditoria',
      facturacion: 'facturacion',
      'control-de-gestion': 'control-de-gestion',
      impuestos: 'impuestos',
      'finanzas-internacionales': 'finanzas-internacionales',
      'ventas-internacionales-exportacion': 'ventas-internacionales-exportacion',
      'apoderado-aduanal': 'apoderado-aduanal',
      'compras-internacionales-importacion': 'compras-internacionales-importacion',
      'consultorias-comercio-exterior': 'consultorias-comercio-exterior',
      logistica: 'logistica',
      distribucion: 'distribucion',
      compras: 'compras',
      'almacen-deposito-expedicion': 'almacen-deposito-expedicion',
      abastecimiento: 'abastecimiento',
      'asistente-de-trafico': 'asistente-de-trafico',
      transporte: 'transporte',
      'direccion-de-obra': 'direccion-de-obra',
      'seguridad-e-higiene': 'seguridad-e-higiene',
      arquitectura: 'arquitectura',
      urbanismo: 'urbanismo',
      construccion: 'construccion',
      operaciones: 'operaciones',
      topografia: 'topografia',
      instrumentacion: 'instrumentacion',
      'ingenieria-civil': 'ingenieria-civil',
      comercial: 'comercial',
      ventas: 'ventas',
      'desarrollo-de-negocios': 'desarrollo-de-negocios',
      'planeamiento-comercial': 'planeamiento-comercial',
      'comercio-exterior': 'comercio-exterior',
      'negocios-internacionales': 'negocios-internacionales',
      'educacion-docentes': 'educacion-docentes',
      'bienestar-estudiantil': 'bienestar-estudiantil',
      'investigacion-y-desarrollo': 'investigacion-y-desarrollo',
      educacion: 'educacion',
      idiomas: 'idiomas',
      'educacion-especial': 'educacion-especial',
      casinos: 'casinos',
      camareros: 'camareros',
      gastronomia: 'gastronomia',
      turismo: 'turismo',
      hoteleria: 'hoteleria',
      'gerencia-direccion-general': 'gerencia-direccion-general',
      direccion: 'direccion',
      legal: 'legal',
      'asesoria-legal-internacional': 'asesoria-legal-internacional',
      'media-planning': 'media-planning',
      'community-management': 'community-management',
      marketing: 'marketing',
      'business-intelligence': 'business-intelligence',
      producto: 'producto',
      creatividad: 'creatividad',
      multimedia: 'multimedia',
      'e-commerce': 'e-commerce',
      'mercadotecnia-internacional': 'mercadotecnia-internacional',
      produccion: 'produccion',
      'programacion-de-produccion': 'programacion-de-produccion',
      mantenimiento: 'mantenimiento',
      calidad: 'calidad',
      seleccion: 'seleccion',
      capacitacion: 'capacitacion',
      'compensacion-y-planilla': 'compensacion-y-planilla',
      'recursos-humanos': 'recursos-humanos',
      'administracion-de-personal': 'administracion-de-personal',
      'capacitacion-comercio-exterior': 'capacitacion-comercio-exterior',
      'responsabilidad-social': 'responsabilidad-social',
      'relaciones-institucionales-publicas': 'relaciones-institucionales-publicas',
      comunicacion: 'comunicacion',
      periodismo: 'periodismo',
      'comunicaciones-internas': 'comunicaciones-internas',
      'comunicaciones-externas': 'comunicaciones-externas',
      internet: 'internet',
      'tecnologia-sistemas': 'tecnologia-sistemas',
      'data-entry': 'data-entry',
      'administracion-de-base-de-datos': 'administracion-de-base-de-datos',
      'analisis-funcional': 'analisis-funcional',
      'liderazgo-de-proyecto': 'liderazgo-de-proyecto',
      'data-warehousing': 'data-warehousing',
      programacion: 'programacion',
      redes: 'redes',
      'seguridad-informatica': 'seguridad-informatica',
      sistemas: 'sistemas',
      infraestructura: 'infraestructura',
      'testing-qa-qc': 'testing-qa-qc',
      'analisis-de-datos': 'analisis-de-datos',
      'tecnologias-de-la-informacion': 'tecnologias-de-la-informacion',
      telecomunicaciones: 'telecomunicaciones',
      'soporte-tecnico': 'soporte-tecnico',
      'practicas-profesionales': 'practicas-profesionales',
      otros: 'otros',
      independientes: 'independientes',
      'arte-y-cultura': 'arte-y-cultura',
      'back-office': 'back-office',
      cadeteria: 'cadeteria',
      caja: 'caja',
      'mantenimiento-y-limpieza': 'mantenimiento-y-limpieza',
      'oficios-y-profesiones': 'oficios-y-profesiones',
      'promotoras-es': 'promotoras-es',
      seguridad: 'seguridad',
      'transporte-de-pasajeros': 'transporte-de-pasajeros',
      'jovenes-profesionales': 'jovenes-profesionales',
      'estetica-y-cuidado-personal': 'estetica-y-cuidado-personal',
      'pasantia-trainee': 'pasantia-trainee',
      planeamiento: 'planeamiento',
      traduccion: 'traduccion',
      servicios: 'servicios',
      farmaceutica: 'farmaceutica',
      medicina: 'medicina',
      veterinaria: 'veterinaria',
      bioquimica: 'bioquimica',
      quimica: 'quimica',
      'anatomia-patologica-tecnicos': 'anatomia-patologica-tecnicos',
      'anestesia-tecnicos': 'anestesia-tecnicos',
      'auditoria-medica': 'auditoria-medica',
      'cosmiatra-dermocosmiatria': 'cosmiatra-dermocosmiatria',
      'diagnostico-por-imagenes': 'diagnostico-por-imagenes',
      salud: 'salud',
      emergentologia: 'emergentologia',
      'enfermeria-ver-enfermeria': 'enfermeria-ver-enfermeria',
      esterilizacion: 'esterilizacion',
      fonoaudiologia: 'fonoaudiologia',
      'hoteleria-hospitalaria': 'hoteleria-hospitalaria',
      'instrumentacion-quirurgica': 'instrumentacion-quirurgica',
      'kinesiologia-terapia-ocupacional': 'kinesiologia-terapia-ocupacional',
      'medicina-laboral': 'medicina-laboral',
      'medicina-transfusional': 'medicina-transfusional',
      'neurologia-tecnicos': 'neurologia-tecnicos',
      nutricion: 'nutricion',
      odontologia: 'odontologia',
      'otras-areas-tecnicas-en-salud': 'otras-areas-tecnicas-en-salud',
      'otras-especialidades-medicas': 'otras-especialidades-medicas',
      'practicas-cardiologicas': 'practicas-cardiologicas',
      psicologia: 'psicologia',
      'traslados-choferes-camilleros': 'traslados-choferes-camilleros',
      'farmacia-comercial': 'farmacia-comercial',
      'farmacia-hospitalaria': 'farmacia-hospitalaria',
      'farmacia-industrial': 'farmacia-industrial',
      'prevencion-de-riesgo': 'prevencion-de-riesgo',
      laboratorio: 'laboratorio',
      'atencion-al-cliente': 'atencion-al-cliente',
      telemarketing: 'telemarketing',
      'call-center': 'call-center',
      asistente: 'asistente',
      telefonista: 'telefonista',
      secretaria: 'secretaria',
      recepcionista: 'recepcionista',
      seguros: 'seguros',
      'administracion-de-seguros': 'administracion-de-seguros',
      'venta-de-seguros': 'venta-de-seguros',
      'tecnico-de-seguros': 'tecnico-de-seguros',
      'auditoria-de-seguros': 'auditoria-de-seguros',
      'ingenieria-en-petroleo-y-petroquimica': 'ingenieria-en-petroleo-y-petroquimica',
      'ingenieria-en-minas': 'ingenieria-en-minas',
      'exploracion-minera-y-petroquimica': 'exploracion-minera-y-petroquimica',
      'instrumentacion-minera': 'instrumentacion-minera',
      'ingenieria-geologica': 'ingenieria-geologica',
      'seguridad-industrial': 'seguridad-industrial',
      'medio-ambiente': 'medio-ambiente',
      'mineria-petroleo-gas': 'mineria-petroleo-gas',
      'ingenieria-automotriz': 'ingenieria-automotriz',
      'ingenieria-electrica-y-electronica': 'ingenieria-electrica-y-electronica',
      'ingenieria-textil': 'ingenieria-textil',
      'ingenieria-mecanica': 'ingenieria-mecanica',
      'ingenieria-industrial': 'ingenieria-industrial',
      'ingenieria-metalurgica': 'ingenieria-metalurgica',
      'ingenieria-quimica': 'ingenieria-quimica',
      'otras-ingenierias': 'otras-ingenierias',
      'ingenieria-de-procesos': 'ingenieria-de-procesos',
      'ingenieria-de-producto': 'ingenieria-de-producto',
      'ingenieria-de-ventas': 'ingenieria-de-ventas',
      'ingenieria-electromecanica': 'ingenieria-electromecanica',
      'ingenieria-en-alimentos': 'ingenieria-en-alimentos',
      'ingenieria-oficina-tecnica-proyecto': 'ingenieria-oficina-tecnica-proyecto',
      'ingenieria-agronoma': 'ingenieria-agronoma',
      diseno: 'diseno',
      'diseno-de-interiores-decoracion': 'diseno-de-interiores-decoracion',
      'diseno-grafico': 'diseno-grafico',
      'diseno-industrial': 'diseno-industrial',
      'diseno-multimedia': 'diseno-multimedia',
      'diseno-textil-e-indumentaria': 'diseno-textil-e-indumentaria',
      'diseno-web': 'diseno-web',
      'diseno-3d': 'diseno-3d',
      'central-de-emergencias-adultos': 'central-de-emergencias-adultos',
      'central-de-emergencias-pedriatricas': 'central-de-emergencias-pedriatricas',
      'clinica-medica': 'clinica-medica',
      consultorios: 'consultorios',
      'cuidados-intensivos-cardiologicos-adultos': 'cuidados-intensivos-cardiologicos-adultos',
      hemodinamia: 'hemodinamia',
      'internacion-domiciliaria': 'internacion-domiciliaria',
      'internacion-general-pediatrica': 'internacion-general-pediatrica',
      maternidad: 'maternidad',
      nefrologia: 'nefrologia',
      oncologia: 'oncologia',
      'recien-nacido-sano': 'recien-nacido-sano',
      'recuperacion-de-anestesia': 'recuperacion-de-anestesia',
      'terapia-intensiva-adultos': 'terapia-intensiva-adultos',
      'terapia-intensiva-pediatrica': 'terapia-intensiva-pediatrica',
      'terapia-intermedia-adultos': 'terapia-intermedia-adultos',
      'terapia-intermedia-pediatrica': 'terapia-intermedia-pediatrica',
      'terapia-neonatal': 'terapia-neonatal',
      vacunatorio: 'vacunatorio',
      'trabajo-social': 'trabajo-social',
      'educacion-docencia': 'educacion-docencia',
      'terapia-intermedia-e-intensiva': 'terapia-intermedia-e-intensiva',
      'central-de-emergencias': 'central-de-emergencias',
      enfermeria: 'enfermeria',
      psicopedagogia: 'psicopedagogia',
      garantias: 'garantias',
      'gps-y-alarma-de-seguridad': 'gps-y-alarma-de-seguridad',
    },
    discapacitados: {
      apto: 'apto-discapacitado',
    },
    distancia: {
      '2km': '2.00',
      '3km': '3.00',
      '4km': '4.00',
      '5km': '5.00',
      '10km': '10.00',
      '20km': '20.00',
      '30km': '30.00',
      '40km': '40.00',
      '50km': '50.00',
    },
  },
}
