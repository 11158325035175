import React from 'react'
import { useTranslation } from 'react-i18next'

import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'

import Carrousel from './components/carrousel'
import { Title } from './mixins'

import { CardCompany } from '../../../../redux/types/listado-empresas'
import { SectionCarrouselPlaceholder } from './components/placeholder/SectionCarrouselPlaceholder'

interface Args {
  companies: CardCompany[]
  feature: boolean
  loading: boolean
  isRecommended: boolean
}

const SectionCarousel = ({ companies, feature, loading, isRecommended }: Args) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()
  const noCompanies = !companies || companies.length < 1
  const showPlaceholder = loading && noCompanies

  const renderOnLoad = () => {
    const device = Object.keys(screenSize).find(size => screenSize[size])
    return <SectionCarrouselPlaceholder device={device} />
  }

  if (!feature || (!loading && noCompanies)) {
    return null
  }

  return (
    <Row>
      <Col>
        <Title>{t(`listadoEmpresas.carousel.${isRecommended ? 'recommendedTitle' : 'title'}`)}</Title>
        {showPlaceholder ? (
          renderOnLoad()
        ) : (
          <Carrousel companies={companies} loading={loading} isRecommended={isRecommended} />
        )}
      </Col>
    </Row>
  )
}

export default SectionCarousel
