import { Request, Get } from '@navent-jobs/config'

export class ErrorCareerSite extends Error {}

class CareerSite {
  public async getCareerSite(alias) {
    const request = new Request()
    request.path = `/api/career-site?alias=${alias}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorCareerSite(`Career Site no encontrado`)
      })
  }
}

const careerSite = new CareerSite()
export default careerSite
