import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const CTAComponent = styled<{ background: string }>('div')`
  position: fixed;
  bottom: 55px;
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url(${({ background }) => background}) no-repeat;
  background-size: cover;
  pointer-events: auto;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const CTAHeader = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 16px;
`

export const ImageAppLogo = styled(Image)`
  width: 36px;
  height: 36px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const Heading = styled('div')`
  margin-left: 10px;
`

export const CTALinks = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 12px 16px 0 16px;
`

export const LinkToStore = styled(Link)`
  &:last-child {
    margin-left: 12px;
  }
`
