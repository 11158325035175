import styled from 'styled-components'

export const ReviewsContainer = styled.div``

export const ReviewLabel = styled.label`
  position: relative;
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 0px;
  }
`

export const FormRow = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  :not(:last-child) {
    margin-bottom: 12px;
  }
`

export const Recommended = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    translate: 32px;
  }
`
