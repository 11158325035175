export const productLanding = {
  nulinga: {
    because: {
      answer2: {
        answer:
          'Podrás mostrar tu nivel del idioma a las empresas para destacar tu postulación en las búsquedas de Laborum si así lo deseas.',
      },
    },
    description: {
      firstRow: {
        secondColumn: {
          description:
            'Los profesionales de Nulinga analizarán tu resultado y lo obtendrás en 48 horas en la sección de idiomas de tu CV de Laborum.',
        },
      },
    },
  },
}
