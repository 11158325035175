export const nulinga = {
  text: 'Test nivelación de inglés',
  action: 'Hacer el test',
  resultPendingTitlea: 'El resultado de tu test está siendo procesado por ',
  resultPendingTitleb: ', y lo verás reflejado en menos de 48 horas',
  resultPendingTitleMarketplace:
    'El resultado de tu test está siendo procesado, y lo verás reflejado en menos de 48 horas',
  notCompletedText: 'Si todavía no lo completaste, hacé',
  levelEnglish: 'Nivel de inglés: ',
  validatedText: 'Validado por ',
  showToCompanies: 'Mostrar a empresas',
  clickHere: 'click acá.',
}
