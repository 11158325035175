export const POSTULAR_QR = 'POSTULAR_QR'
export const POSTULAR_QR_SUCCESS = 'POSTULAR_QR_SUCCESS'
export const POSTULAR_QR_ERROR = 'POSTULAR_QR_ERROR'
export const POSTULAR_QR_RESET = 'POSTULAR_QR_RESET'

export interface PostularQrInput {
  idAviso: number
}

export interface PostularQrOutput {
  estado: 'espera' | 'realizada'
  motivoEspera?: 'ACTIVACION' | 'CV_INCOMPLETO'
}

export interface OperationStatus {
  success: boolean
  loading: boolean
  error: boolean
}

export interface PostularQr {
  type: typeof POSTULAR_QR
  payload: PostularQrInput
}

export interface PostularQrSuccess {
  type: typeof POSTULAR_QR_SUCCESS
  payload: PostularQrOutput
}

export interface PostularQrError {
  type: typeof POSTULAR_QR_ERROR
  payload: null
}

export interface PostularQrReset {
  type: typeof POSTULAR_QR_RESET
  payload: null
}

export interface PostularQrState {
  output: PostularQrOutput | null
  status: OperationStatus
}

export const defaultOperationStatus: OperationStatus = {
  success: false,
  loading: false,
  error: false,
}

export const initialState: PostularQrState = {
  output: null,
  status: defaultOperationStatus,
}

export type PostularQrAction = PostularQr | PostularQrSuccess | PostularQrError | PostularQrReset
