import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/disponible-ofertas'
import {
  SWITCH_DISPONIBLE_OFERTAS,
  SWITCH_DISPONIBLE_OFERTAS_ERROR,
  SWITCH_DISPONIBLE_OFERTAS_SUCCESS,
  SwitchDisponibleOfertas,
} from '../types/disponible-ofertas'

function* switchDisponibleOfertas({ payload }: SwitchDisponibleOfertas) {
  try {
    const resp: SwitchDisponibleOfertas = yield call(service.postDisponibleOfertas, payload)
    yield put({ type: SWITCH_DISPONIBLE_OFERTAS_SUCCESS, payload: resp })
  } catch (error) {
    yield put({ type: SWITCH_DISPONIBLE_OFERTAS_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* disponibleOfertas() {
  yield takeLatest<SwitchDisponibleOfertas>(SWITCH_DISPONIBLE_OFERTAS, switchDisponibleOfertas)
}
