import { StatusType, status } from './status'

export const GET_MOTIVOS_DENUNCIA = 'GET_MOTIVOS_DENUNCIA'
export const GET_MOTIVOS_DENUNCIA_SUCCESS = 'GET_MOTIVOS_DENUNCIA_SUCCESS'
export const GET_MOTIVOS_DENUNCIA_ERROR = 'GET_MOTIVOS_DENUNCIA_ERROR'

export const POST_DENUNCIA_AVISO = 'POST_DENUNCIA_AVISO'
export const POST_DENUNCIA_AVISO_SUCCESS = 'POST_DENUNCIA_AVISO_SUCCESS'
export const POST_DENUNCIA_AVISO_ERROR = 'POST_DENUNCIA_AVISO_ERROR'

interface SubMotivoDenuncia {
  id: number
  name: string
}

interface MotivosDenuncia {
  idMotivo: number
  nombreMotivo: string
  subMotivosDenuncia: SubMotivoDenuncia[]
}

export interface DenunciaAviso {
  idAviso: number
  idMotivo: number
  idSubmotivo?: number
  descripcion?: string
}

export interface GetMotivosDenuncia {
  type: typeof GET_MOTIVOS_DENUNCIA
  payload: null
}
export interface GetMotivosDenunciaSuccess {
  type: typeof GET_MOTIVOS_DENUNCIA_SUCCESS
  payload: MotivosDenuncia[]
}
export interface GetMotivosDenunciaError {
  type: typeof GET_MOTIVOS_DENUNCIA_ERROR
  payload: null
}

export interface PostDenunciaAviso {
  type: typeof POST_DENUNCIA_AVISO
  payload: DenunciaAviso
}
export interface PostDenunciaAvisoSuccess {
  type: typeof POST_DENUNCIA_AVISO_SUCCESS
  payload: null
}
export interface PostDenunciaAvisoError {
  type: typeof POST_DENUNCIA_AVISO_ERROR
  payload: null
}

export interface DenunciaState {
  denunciaAvisoStates: StatusType
  motivos: MotivosDenuncia[] | null
  motivosStates: StatusType
}

export const initialState: DenunciaState = {
  denunciaAvisoStates: status.initial,
  motivos: null,
  motivosStates: status.initial,
}

export type DenunciaActions =
  | GetMotivosDenuncia
  | GetMotivosDenunciaSuccess
  | GetMotivosDenunciaError
  | PostDenunciaAviso
  | PostDenunciaAvisoError
  | PostDenunciaAvisoSuccess
