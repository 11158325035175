export const GET_LISTADO_AVISOS_EMPRESA = 'GET_LISTADO_AVISOS'
export const GET_LISTADO_AVISOS_EMPRESA_SUCCESS = 'GET_LISTADO_AVISOS_SUCCESS'
export const GET_LISTADO_AVISOS_EMPRESA_ERROR = 'GET_LISTADO_AVISOS_ERROR'

export const GET_FICHA_EMPRESA = 'GET_FICHA_EMPRESA'
export const GET_FICHA_EMPRESA_SUCCESS = 'GET_FICHA_EMPRESA_SUCCESS'
export const GET_FICHA_EMPRESA_ERROR = 'GET_FICHA_EMPRESA_ERROR'

export const SET_FICHA_EMPRESA_FILTERS_APPLIED = 'SET_FICHA_EMPRESA_FILTERS_APPLIED'
export const SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS = 'SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS'
export const SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR = 'SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR'

export const GET_SEGUIR_EMPRESA = 'GET_SEGUIR_EMPRESA'
export const GET_SEGUIR_EMPRESA_SUCCESS = 'GET_SEGUIR_EMPRESA_SUCCESS'
export const GET_SEGUIR_EMPRESA_ERROR = 'GET_SEGUIR_EMPRESA_ERROR'

export const POST_SEGUIR_EMPRESA = 'POST_SEGUIR_EMPRESA'
export const POST_SEGUIR_EMPRESA_SUCCESS = 'POST_SEGUIR_EMPRESA_SUCCESS'
export const POST_SEGUIR_EMPRESA_ERROR = 'POST_SEGUIR_EMPRESA_ERROR'

export const DELETE_SEGUIR_EMPRESA = 'DELETE_SEGUIR_EMPRESA'
export const DELETE_SEGUIR_EMPRESA_SUCCESS = 'DELETE_SEGUIR_EMPRESA_SUCCESS'
export const DELETE_SEGUIR_EMPRESA_ERROR = 'DELETE_SEGUIR_EMPRESA_ERROR'

export const POST_DELETE_SEGUIR_EMPRESA_RESET = 'POST_DELETE_SEGUIR_EMPRESA_RESET'

export const REFRESH_EMPRESAS_PERFIL = 'REFRESH_EMPRESAS_PERFIL'

export interface GetListadoAvisosEmpresaRequestDto {
  page: number
  pageSize: number
  empresaId: number
  filters: FiltersAppliedType
}

export interface GetFichaEmpresaRequestDto {
  id: number
}

//  Get listado avisos
export interface GetListadoAvisosEmpresa {
  type: typeof GET_LISTADO_AVISOS_EMPRESA
  payload: GetListadoAvisosEmpresaRequestDto
}

export interface GetListadoAvisosEmpresaSuccess {
  type: typeof GET_LISTADO_AVISOS_EMPRESA_SUCCESS
  payload
}

export interface GetListadoAvisosEmpresaError {
  type: typeof GET_LISTADO_AVISOS_EMPRESA_ERROR
  payload: boolean
}

export interface FiltersAppliedType {
  type: string
  facets: {
    id: string
    idSemantico?: string
    name: string
    quantity: number
  }
}

/**
 * Set Filters Applied
 */
export interface SetFichaEmpresaFiltersApplied {
  type: typeof SET_FICHA_EMPRESA_FILTERS_APPLIED
  payload: FiltersAppliedType[]
}

export interface SetFichaEmpresaFiltersAppliedSuccess {
  type: typeof SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS
  payload: FiltersAppliedType[]
}

export interface SetFichaEmpresaFiltersAppliedError {
  type: typeof SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR
  payload: null
}

//  Get ficha empresa
export interface GetFichaEmpresa {
  type: typeof GET_FICHA_EMPRESA
  payload: GetFichaEmpresaRequestDto
}

export interface GetFichaEmpresaSuccess {
  type: typeof GET_FICHA_EMPRESA_SUCCESS
  payload
}

export interface GetFichaEmpresaError {
  type: typeof GET_FICHA_EMPRESA_ERROR
  payload: boolean
}

// Get seguir empresa
export interface GetSeguirEmpresa {
  type: typeof GET_SEGUIR_EMPRESA
  payload: number
}

export interface GetSeguirEmpresaSuccess {
  type: typeof GET_SEGUIR_EMPRESA_SUCCESS
  payload: boolean
}

export interface GetSeguirEmpresaError {
  type: typeof GET_SEGUIR_EMPRESA_ERROR
  payload: null
}

// Seguir empresa
export interface PostSeguirEmpresa {
  type: typeof POST_SEGUIR_EMPRESA
  payload: number
}

export interface PostSeguirEmpresaSuccess {
  type: typeof POST_SEGUIR_EMPRESA_SUCCESS
  payload: number
}

export interface PostSeguirEmpresaError {
  type: typeof POST_SEGUIR_EMPRESA_ERROR
  payload: number
}

// Dejar de seguir empresa
export interface DeleteSeguirEmpresa {
  type: typeof DELETE_SEGUIR_EMPRESA
  payload: number
}

export interface DeleteSeguirEmpresaSuccess {
  type: typeof DELETE_SEGUIR_EMPRESA_SUCCESS
  payload: number
}

export interface DeleteSeguirEmpresaError {
  type: typeof DELETE_SEGUIR_EMPRESA_ERROR
  payload: number
}

export interface PostDeleteSeguirEmpresaReset {
  type: typeof POST_DELETE_SEGUIR_EMPRESA_RESET
  payload: null
}

export type CompanySummaryActions =
  | GetListadoAvisosEmpresa
  | GetListadoAvisosEmpresaSuccess
  | GetListadoAvisosEmpresaError
  | GetFichaEmpresa
  | GetFichaEmpresaSuccess
  | GetFichaEmpresaError
  | SetFichaEmpresaFiltersApplied
  | SetFichaEmpresaFiltersAppliedSuccess
  | SetFichaEmpresaFiltersAppliedError
  | PostSeguirEmpresa
  | PostSeguirEmpresaSuccess
  | PostSeguirEmpresaError
  | DeleteSeguirEmpresa
  | DeleteSeguirEmpresaSuccess
  | DeleteSeguirEmpresaError
  | PostDeleteSeguirEmpresaReset
  | GetSeguirEmpresa
  | GetSeguirEmpresaSuccess
  | GetSeguirEmpresaError
