import {
  GET_FICHA_EMPRESA_FERIA,
  GetFichaEmpresaFeria,
  POST_LISTADO_EMPRESAS_FERIA,
  ListadoEmpresasFeriaReq,
  PostListadoEmpresasFeria,
  GetFeria,
  GET_FERIA,
  GET_CONFERENCIAS_EMPRESA,
  GetConferenciasEmpresa,
  GET_CONFERENCIAS,
  GET_VIDEOS_CONFERENCIA,
} from '../types/feria'

export const getFichaEmpresaFeria = (idFeria: number, idEmpresa: number): GetFichaEmpresaFeria => {
  return {
    type: GET_FICHA_EMPRESA_FERIA,
    payload: { idFeria, idEmpresa },
  }
}

export const getListadoEmpresasFeria = (payload: ListadoEmpresasFeriaReq): PostListadoEmpresasFeria => {
  return {
    type: POST_LISTADO_EMPRESAS_FERIA,
    payload,
  }
}

export const getFeria = (slug: string): GetFeria => {
  return {
    type: GET_FERIA,
    payload: { slug },
  }
}

export const getConferencias = (idFeria: number) => {
  return {
    type: GET_CONFERENCIAS,
    payload: { idFeria },
  }
}

export const getConferenciasEmpresa = (idFeria: number, idEmpresa: number): GetConferenciasEmpresa => {
  return {
    type: GET_CONFERENCIAS_EMPRESA,
    payload: { idFeria, idEmpresa },
  }
}

export const getVideosConferencia = (idFeria: number) => {
  return {
    type: GET_VIDEOS_CONFERENCIA,
    payload: { idFeria },
  }
}
