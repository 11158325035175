import styled from 'styled-components'

export const SidebarTitle = styled('h3')`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  position: absolute;
  top: 15px;
  left: 50px;
  right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ModalReminderText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;

  &:first-letter {
    text-transform: capitalize;
  }

  &:before {
    content: '*';
    color: red;
  }
`

export const TitleWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  align-items: center;
`

export const CustomModalTitle = styled('p')`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
`

export const SidebarShadow = styled('div')`
  width: 100%;
  height: 56px;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const SidebarContent = styled('div')`
  height: 100%;
  overflow: auto;
  padding: 20px 16px 92px 16px;
`

export const SidebarReminder = styled('p')`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  padding-bottom: 20px;

  &:first-letter {
    text-transform: capitalize;
  }

  &:before {
    content: '*';
    color: red;
  }
`

export const ModalContent = styled('div')`
  padding: 0px 12px;
`

export const SidebarFooter = styled('div')`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey0};
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`
