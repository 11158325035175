const curriculum = {
  marketplace: {
    enabled: true,
    mobileView: true,
    products: {
      enabled: false,
    },
    benefits: {
      enabled: false,
      coderhouse: false,
    },
  },
  expoFeria: {
    enabled: false,
  },
}

export default curriculum
