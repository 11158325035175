import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ShareButtonWrapper = styled('div')`
  align-items: center;
  display: flex;
`

export const ShareButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${({ theme }) => theme.colors.grey0};
  border: none;
  border-radius: 4px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey0} !important;
    background-color: ${({ theme }) => theme.colors.grey0} !important;
    border: none;
  }

  @media (min-width: 1024px) {
    padding: 0px 13px;
  }
`

export const IconContainer = styled('div')`
  display: flex;
  line-height: 20px;
  gap: 12px;
  align-items: center;
`

export const IconCustom = styled(Icon)`
  @media (min-width: 1024px) {
    ${({ hasChildren }) => hasChildren && 'margin-right: 13px;'}
  }
`

export const ShareModalContainer = styled('div')`
  display: flex;

  div:last-child {
    margin: 0px;
  }
`

export const InputWrapper = styled('div')`
  margin-top: 32px;
`

export const SocialButtonWrapper = styled('div')`
  display: flex;
  margin-right: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey8};
  width: 74px;
  height: 74px;
  border-radius: 50%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.tertiary.normal};
  }
`

export const StrongLabel = styled.strong`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`

export const CopyButton = styled(Button)`
  margin: 0;
  padding: 0px 4px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey16};
  }
`

export const SnackbarText = styled('span')`
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    flex-grow: 0;
  }
`
