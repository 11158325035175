import React from 'react'
import { useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google'
import { signInGoogle } from '../../../../redux/actions/sign-in'
import { MainContainer } from './mixins'

const GoogleLoginButton = () => {
  const dispatch = useDispatch()
  const successHandler = response => dispatch(signInGoogle({ token: response.credential }))

  return (
    <MainContainer>
      <GoogleLogin
        onSuccess={successHandler}
        theme="filled_blue"
        shape="rectangular"
        width="330px"
        size="large"
        useOneTap={false}
        ux_mode="popup"
        onError={() => {}}
      />
    </MainContainer>
  )
}

export default GoogleLoginButton
