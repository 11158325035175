import { SiteIds } from '@navent-jobs/config'

export const portalsBySiteId = {
  [SiteIds.BMAR]: 'Bumeran',
  [SiteIds.BMCL]: 'Laborum',
  [SiteIds.BMEC]: 'Multitrabajos',
  [SiteIds.BMMX]: 'Bumeran',
  [SiteIds.BMPA]: 'Konzerta',
  [SiteIds.BMPE]: 'Bumeran',
  [SiteIds.BMVE]: 'Bumeran',
  [SiteIds.ZJAR]: 'Zonajobs',
}
