import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import {
  claimSelector,
  constainerSelector,
  ctaSelector,
  divSelector,
  felxboxSelector,
  flexboxButtonsSelector,
  flexboxTitleSelector,
  iconSelector,
  imageSelector,
  linkSelector,
  productBannerComponentSelector,
  titleSelector,
} from './utils'

export const ProductBannerComponent = styled.div`
  background: ${({ theme }) => theme.colors.grey0};
  background-image: url(${({ bkg }) => bkg});
  background-repeat: no-repeat;
  ${({ verticalSize }) => {
    if (verticalSize === 'lg') {
      return `
        background-size: 475px;
        background-position: bottom -20px left 50% ;
        height: 620px;`
    }
    if (verticalSize === 'md') {
      return `
        background-size: 320px;
        background-position: bottom -32px left -52px;`
    }
    if (verticalSize === 'sm') {
      return `
        background-size: 200px;
        background-position: bottom -38px left -20px`
    }
    return ''
  }}
  padding: 16px;
  margin-bottom: 12px;
  border: 1px solid #c3c2c8;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  ${({ colSizeXl, theme }) => productBannerComponentSelector(colSizeXl, theme.screen)}
`

export const Div = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background-image: url(${({ bkg }) => bkg});
  background-repeat: no-repeat;
  mix-blend-mode: ${({ blendmode }) => blendmode};
  position: absolute;
  left: 0;
  top: 0;

  ${({ colSizeXl, theme }) => divSelector(colSizeXl, theme.screen)}
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ colSizeXl, theme }) => constainerSelector(colSizeXl, theme.screen)}
`

export const Flexbox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: ${({ verticalSize }) => (verticalSize === 'md' ? '250px' : ' auto')};

  ${({ colSizeXl, theme }) => felxboxSelector(colSizeXl, theme.screen)}
`

export const ImageCustom = styled(Image)`
  margin-bottom: ${({ verticalSize }) => (verticalSize === 'lg' ? '12px' : '8px')};

  ${({ colSizeXl, theme }) => imageSelector(colSizeXl, theme.screen)}
`

export const FlexboxTitle = styled.div`
  ${({ withLogo, colSizeXl, theme }) => withLogo && flexboxTitleSelector(colSizeXl, theme.screen)}
`

export const Title = styled.div`
  ${({ verticalSize }) => {
    return verticalSize === 'lg'
      ? `
        font-size: 24px;
        line-height: 32px;
      `
      : `
        max-width: 260px;
        font-size: 16px;
        line-height: 20px;
      `
  }}
  font-weight: 600;
  margin-bottom: 12px;

  ${({ colSizeXl, theme }) => titleSelector(colSizeXl, theme.screen)}
`

export const Claim = styled.div`
  ${({ verticalSize }) => {
    return verticalSize === 'lg'
      ? `
      font-size: 18px;
      line-height: 24px;
    `
      : `
      font-size: 16px;
      line-height: 20px;
    `
  }}

  ${({ verticalSize }) => verticalSize === 'sm' && 'display: none;'}
  ${({ colSizeXl, theme }) => claimSelector(colSizeXl, theme.screen)};
`

export const FlexboxButtons = styled.div`
  height: 100%;
  display: flex;
  justify-content: ${({ verticalSize }) => (verticalSize === 'lg' ? 'center' : 'flex-end')};

  ${({ colSizeXl, theme }) => flexboxButtonsSelector(colSizeXl, theme.screen)}
`

export const LinkCustom = styled(Link)`
  height: ${({ verticalSize }) => (verticalSize === 'lg' ? '48px' : 'auto')};
  width: 100%;
  max-width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ verticalSize }) => {
    if (verticalSize === 'lg') return `24px`
    if (verticalSize === 'md') return `0px`
    if (verticalSize === 'sm') return `12px`
    return ''
  }};
  ${({ colSizeXl, theme }) => linkSelector(colSizeXl, theme.screen)};
`

export const IconCustom = styled(Icon)`
  display: none;

  ${({ sizeCol, theme }) => iconSelector(sizeCol, theme.screen)}
`

export const Cta = styled.div`
  ${({ colSizeXl, theme }) => ctaSelector(colSizeXl, theme.screen)}
`
