import { ArchivoAdjuntoDiscapacidad, GetArchivoAdjuntoDiscapacidad } from '../commons'

export const DELETE_DISCAPACIDAD = 'DELETE_DISCAPACIDAD'
export const DELETE_DISCAPACIDAD_RESET = 'DELETE_DISCAPACIDAD_RESET'
export const DELETE_DISCAPACIDAD_SUCCESS = 'DELETE_DISCAPACIDAD_SUCCESS'
export const DELETE_DISCAPACIDAD_ERROR = 'DELETE_DISCAPACIDAD_ERROR'

export const ADD_DISCAPACIDAD = 'ADD_DISCAPACIDAD'
export const ADD_DISCAPACIDAD_RESET = 'ADD_DISCAPACIDAD_RESET'
export const ADD_DISCAPACIDAD_SUCCESS = 'ADD_DISCAPACIDAD_SUCCESS'
export const ADD_DISCAPACIDAD_ERROR = 'ADD_DISCAPACIDAD_ERROR'

export const GET_DISCAPACIDAD = 'GET_DISCAPACIDAD'
export const GET_DISCAPACIDAD_RESET = 'GET_DISCAPACIDAD_RESET'
export const GET_DISCAPACIDAD_SUCCESS = 'GET_DISCAPACIDAD_SUCCESS'
export const GET_DISCAPACIDAD_ERROR = 'GET_DISCAPACIDAD_ERROR'

// delete discapacidad
export interface DeleteDiscapacidad {
  type: typeof DELETE_DISCAPACIDAD
  payload: null
}
export interface DeleteDiscapacidadReset {
  type: typeof DELETE_DISCAPACIDAD_RESET
  payload: null
}
export interface DeleteDiscapacidadSuccess {
  type: typeof DELETE_DISCAPACIDAD_SUCCESS
  payload: null
}
export interface DeleteDiscapacidadError {
  type: typeof DELETE_DISCAPACIDAD_ERROR
  payload: null
}

// Add discapacidad
export interface AddDiscapacidad {
  type: typeof ADD_DISCAPACIDAD
  payload: ArchivoAdjuntoDiscapacidad
}
export interface AddDiscapacidadReset {
  type: typeof ADD_DISCAPACIDAD_RESET
  payload: null
}
export interface AddDiscapacidadSuccess {
  type: typeof ADD_DISCAPACIDAD_SUCCESS
  payload: null
}
export interface AddDiscapacidadError {
  type: typeof ADD_DISCAPACIDAD_ERROR
  payload: null
}

// Get discapacidad
export interface GetDiscapacidad {
  type: typeof GET_DISCAPACIDAD
  payload: GetArchivoAdjuntoDiscapacidad
}
export interface GetDiscapacidadReset {
  type: typeof GET_DISCAPACIDAD_RESET
  payload: null
}
export interface GetDiscapacidadSuccess {
  type: typeof GET_DISCAPACIDAD_SUCCESS
  payload: string | null
}
export interface GetDiscapacidadError {
  type: typeof GET_DISCAPACIDAD_ERROR
  payload: null
}
