const Share = {
  share: {
    labelButton: 'Compartir',
    snackbarCopySuccess: 'Enlace copiado',
    modal: {
      inputLabel: 'Copiar el enlace',
      fichaAviso: {
        title: 'Compartí este aviso',
      },
      perfilEmpresa: {
        title: 'Compartí los avisos de esta empresa',
      },
    },
  },
}

export default Share
