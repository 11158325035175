import React, { useContext, useState } from 'react'
import Text from '@navent-jobs/ui-kit/components/text'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'

import {
  ApplicationStatusContainer,
  ApplicationStatusWrapper,
  ActionsContainer,
  IconContainer,
  PostuladoContainer,
  ReadContainer,
  Tooltip,
} from '../../mixins'

import { ActionButtons } from '../ActionButtons'

// muestra el estado de postulacion representado por un icon: completa e incompleta
// si no esta postulado muestra icon para guardar aviso
const ApplicationStatus = ({
  index,
  tipoAviso,
  read,
  estadoPostulacionRealizada,
  estadoPostulacionEspera,
  saveJobPostingEnabled,
  handleSavePosting,
  savePosting,
  isDesktop,
  mobileFormat,
  notInterestedJobPostingEnabled = false,
  id,
  handleNotInterested,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [showTooltip, setShowTooltip] = useState(false)

  const tooltipClick = () => {
    if (!isDesktop) {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 2000)
    }
  }

  const tooltipEnter = () => {
    isDesktop && setShowTooltip(true)
  }
  const tooltipLeave = () => {
    isDesktop && setShowTooltip(false)
  }

  if (tipoAviso === 'zonajobs') return null
  return (
    <>
      <ApplicationStatusContainer
        mobileFormat={mobileFormat}
        saveJobPostingEnabled={saveJobPostingEnabled}
        notInterestedJobPostingEnabled={notInterestedJobPostingEnabled}
      >
        <ApplicationStatusWrapper>
          {read && !(estadoPostulacionRealizada || estadoPostulacionEspera) && (
            <ReadContainer>
              <Icon name="icon-light-checkmark-double" size="20" color={colors.grey0} />
            </ReadContainer>
          )}
          {estadoPostulacionRealizada && (
            <PostuladoContainer>
              {showTooltip && (
                <Tooltip>
                  <Text size="xs" fontWeight="semibold" variant="gray0" as="span">
                    Ya Postulado
                  </Text>
                </Tooltip>
              )}
              <IconContainer onMouseEnter={tooltipEnter} onMouseLeave={tooltipLeave} onClick={tooltipClick}>
                <Icon name="icon-light-checkbox-checked" size="24" color={colors.secondary.normal} />
              </IconContainer>
            </PostuladoContainer>
          )}
          {estadoPostulacionEspera && <Icon name="icon-light-alert-triangle" size="24" color={colors.danger.normal} />}
          {isDesktop && !mobileFormat && (
            <ActionButtons
              index={index}
              tipoAviso={tipoAviso}
              showSavePosting={saveJobPostingEnabled}
              handleSavePosting={handleSavePosting}
              savePosting={savePosting}
              showNotInterested={notInterestedJobPostingEnabled}
              id={id}
              handleNotInterested={handleNotInterested}
            />
          )}
        </ApplicationStatusWrapper>
      </ApplicationStatusContainer>
      {(!isDesktop || mobileFormat) && (
        <ActionsContainer>
          <ActionButtons
            index={index}
            tipoAviso={tipoAviso}
            showSavePosting={saveJobPostingEnabled}
            handleSavePosting={handleSavePosting}
            savePosting={savePosting}
            showNotInterested={notInterestedJobPostingEnabled}
            id={id}
            handleNotInterested={handleNotInterested}
          />
        </ActionsContainer>
      )}
    </>
  )
}

export default ApplicationStatus
