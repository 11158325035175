import styled from 'styled-components'
import { Col } from '@navent-jobs/ui-kit/components/grid'

export const Page = styled('div')`
  background-color: white;
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const ColImage = styled(Col)`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    order: 1;
  }
`

export const FormGroup = styled('div')`
  margin-bottom: 24px;
`

export const Text = styled('p')`
  font-size: 18px;
  text-align: left;
  line-height: 24px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 24px;
  }
`

export const MessageError = styled('p')`
  color: red;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  margin-top: 15px;
`
