export const options = [
  {
    label: 'Inmediata',
    value: 0,
    type: 'instantaneo',
  },
  {
    label: 'Diaria',
    value: 1,
    type: 'diario',
  },
]

export const frecuency = x => options.find(e => e.value === x.idFrecuencia)
