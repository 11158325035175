export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Departamento',
      localidadTitle: 'Distrito',
    },
  },
  legales:
    'es responsable por la Base de Datos USUARIOS DE PLATAFORMAS WEB Y MÓVIL propiedad de BUMERAN, registrado mediante Resolución N° 3111-2016-JUS/DGPDP-DRN y código de inscripción RNPDP-PJP N° 11153. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos, personalmente o por correo postal a la dirección Avenida Benavides N° 786, Int. 1205, Miraflores, Lima Perú. La Dirección General de Protección de Datos Personales, Órgano de Control de la Ley de Protección de Datos Personales – Ley Nº 29733 y su Reglamento, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.',
  marketplace: {
    products: {
      nulinga: {
        claim: {
          default: 'Certifica tu nivel de Inglés con un rápido cuestionario',
        },
      },
    },
  },
}
