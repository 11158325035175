import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { SortContainer, SortLabel, SortOptionsContainer, SortOption, SortLink } from './mixins'

interface SortItem {
  key: string
  to: string
  label: string
}

export interface SortProps {
  enabled?: boolean
  initValue?: string
  items?: SortItem[] | null
  path: string
  queryParams: URLSearchParams
  dataLayers?: any
  label?: string
  className?: string
  noFollow?: boolean
}

const buildQueryString = (queryParams: URLSearchParams, items?: SortItem[] | null): string => {
  const filteredQueryParams = new URLSearchParams(queryParams.toString())
  items?.forEach(item => {
    filteredQueryParams.delete(item.key)
  })
  return filteredQueryParams.toString() ? `&${filteredQueryParams.toString()}` : ''
}

export const Sort = ({
  className,
  enabled,
  initValue,
  items,
  path,
  dataLayers,
  queryParams,
  label = 'Ordenar por:',
  noFollow = false,
}: SortProps) => {
  const [activeSortOption, setActiveSortOption] = useState(initValue)

  useEffect(() => {
    if (queryParams && items) {
      const activeItem = items.filter(item => {
        return queryParams.get(item.key)
      })

      const activeItemKey = activeItem[0] ? activeItem[0].key : initValue

      setActiveSortOption(activeItemKey)
    }
  }, [initValue, items, queryParams])

  const queryString = buildQueryString(queryParams, items)

  if (!enabled) {
    return null
  }

  return (
    <SortContainer className={className}>
      <SortLabel>{label}</SortLabel>
      <SortOptionsContainer>
        {items &&
          items.map(item => {
            return (
              <SortOption key={`${item.key}`}>
                <SortLink
                  to={`${path}${item.to}${queryString}`}
                  rel={noFollow ? 'nofollow' : null}
                  active={activeSortOption ? item.key.includes(activeSortOption) : false}
                  onClick={() => {
                    setActiveSortOption(item.key)
                    if (dataLayers) {
                      dataLayers.ordenamiento(item.label)
                    }
                  }}
                  linkOptions={{
                    native: true,
                  }}
                >
                  {item.label}
                </SortLink>
              </SortOption>
            )
          })}
      </SortOptionsContainer>
    </SortContainer>
  )
}

const states = ({ sortStore }) => ({
  ...sortStore,
})

export default connect(states)(Sort)
