import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const MainWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 0px 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: 'center';
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding: 0px;
  }
`
export const Title = styled.h3`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
`

export const BannerDescription = styled.p`
  max-width: ${({ direction }) => (direction === 'row' ? 'auto' : '256px')};
  font-size: 16px;
  color: ${props => props.theme.colors.grey100};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
`

export const ImgContrasenia = styled.img`
  width: 100%;
  max-width: 120px;
  margin-bottom: 20px;
  border-radius: 8px 8px 0px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 160px;
    margin-bottom: 24px;
  }
`

export const CtaContinuar = styled(Button)`
  width: 100%;

  &&:focus {
    outline: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 168px;
  }
`

export const ButtonContainer = styled('div')`
  text-align: center;
`
