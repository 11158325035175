import React, { useContext } from 'react'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'
import Separador from '../separador'
import SectionTitle from '../section-title'
import DownloadApp from '../item-download-app'
import IconMapaDelEmpleo from '../../../../assets/images/icon-mapa-del-empleo.svg'
import { DataLayerListadoEmpresas } from '../../../gtm-events-listado-empresas'
import { Text, Container, ImageCustom } from '../mixins'
import { CrossSiteDataLayers } from '../../../gtm-events-cross-site'
import i18n from '../../../../i18n'

const allPortalsBM = ['BMAR', 'BMCL', 'BMEC', 'BMMX', 'BMPA', 'BMPE', 'BMVE']
const allPortals = [...allPortalsBM, 'ZJAR']

const linksUserMenu = {
  options: (variant, type, target = '_self') => ({
    size: 'small',
    variant,
    type,
    native: false,
    target,
  }),
}

interface MenuItemIconProps {
  iconName: string
}

const MenuItemIcon = ({ iconName }: MenuItemIconProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  return <Icon name={iconName} color={colors.grey60} />
}

export const commonItems = {
  separador: {
    content: <Separador />,
    key: 'separador',
    visibleIn: ['mobile'],
    portals: allPortals,
  },

  explorar: {
    content: <SectionTitle text="Explorar" colorText="black84" />,
    key: 'seccion',
    visibleIn: ['mobile'],
    portals: allPortals,
  },

  listadoEmpresas: {
    href: '/listado-empresas',
    content: (
      <Container onClick={DataLayerListadoEmpresas.goToCompanyListHeader}>
        <Text>Buscar empresas</Text>
      </Container>
    ),
    before: <MenuItemIcon iconName="icon-light-office" />,
    key: 'listado-de-empresas',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Listado de Empresas'),
    portals: allPortals,
  },

  promedioSalarial: {
    href: '/salarios',
    content: <Text>Salarios</Text>,
    before: <MenuItemIcon iconName={i18n.t(`currencyMask.icon`)} />,
    key: 'salarios',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Salarios'),
    portals: ['BMAR', 'BMCL', 'BMEC', 'BMMX', 'BMPA', 'BMPE', 'ZJAR'],
  },

  jovenesProfesionalesIcon: {
    href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
    content: <Text>Jóvenes profesionales</Text>,
    before: <MenuItemIcon iconName="icon-light-graduation-cap" />,
    key: 'jovenes-profesionales-icon',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    portals: allPortalsBM,
  },

  puestosEjecutivos: {
    href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
    content: <Text>Puestos ejecutivos</Text>,
    before: <MenuItemIcon iconName="icon-light-briefcase" />,
    key: 'puestos-ejecutivos',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    portals: allPortals,
  },

  downloadApp: storeUrl => ({
    href: storeUrl,
    content: <DownloadApp />,
    key: 'download-app',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('black', 'link'),
    metric: () => CrossSiteDataLayers.header('Bajar App'),
    portals: allPortalsBM,
  }),

  mapaEmpleos: {
    href: 'https://mapadelempleo.fundaciontelefonica.com/arg?_ga=2.225395162.1382093837.1642787986-1271303123.1639425988',
    rel: 'nofollow',
    content: <Text>Mapa del empleo</Text>,
    before: <ImageCustom src={IconMapaDelEmpleo} alt="mapa empleo" />,
    key: 'mapa-del-empleo',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Mapa del Empleo'),
    portals: ['BMAR'],
  },

  separadorSitiosInteres: {
    content: <Separador />,
    key: 'separador',
    visibleIn: ['mobile'],
    portals: ['BMCL'],
  },

  sitiosDeInteres: {
    content: <SectionTitle text="Sitios de interés" colorText="black84" />,
    key: 'seccion',
    visibleIn: ['mobile'],
    portals: ['BMCL'],
  },

  empleosInclusivos: {
    href: '/empleos-discapacitados-apto.html?landing-empleos-inclusivos=true',
    content: <Text>Empleos inclusivos</Text>,
    before: <MenuItemIcon iconName="icon-light-discapacity" />,
    key: 'empleos-inclusivos',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Empleos inclusivos'),
    portals: ['BMCL'],
  },

  empleosPartTime: {
    href: '/empleos-part-time.html?landing-empleos-part-time=true',
    content: <Text>Empleos Part-time</Text>,
    before: <MenuItemIcon iconName="icon-light-clock" />,
    key: 'empleos-part-time',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Empleos part-time'),
    portals: ['BMCL'],
  },

  practicasProfesionales: {
    href: '/empleos-practica-profesional.html?landing-practicas-profesionales=true',
    content: <Text>Prácticas profesionales</Text>,
    before: <MenuItemIcon iconName="icon-light-briefcase" />,
    key: 'practicas-profesionales',
    visibleIn: ['mobile'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Practicas profesionales'),
    portals: ['BMCL'],
  },
}
