import {
  GET_POSTULACIONES,
  GET_POSTULACION,
  GetPostulaciones,
  GetPostulacion,
  GetPostulacionesParams,
  GetPostulacionParams,
  GET_ESTADOS_SELECCION_POSTULACION,
  PUT_ESTADO_SELECCION_POSTULACION,
  PUT_ESTADO_SELECCION_POSTULACION_RESET,
} from '../types/postulaciones'

export const getPostulacion = (value: GetPostulacionParams): GetPostulacion => {
  return {
    type: GET_POSTULACION,
    payload: value,
  }
}

export const getPostulaciones = (value: GetPostulacionesParams): GetPostulaciones => {
  return {
    type: GET_POSTULACIONES,
    payload: value,
  }
}

export const getEstadosSeleccion = () => {
  return {
    type: GET_ESTADOS_SELECCION_POSTULACION,
  }
}

export const putEstadoSeleccion = (postulacionId, estadoSeleccionado) => {
  return {
    type: PUT_ESTADO_SELECCION_POSTULACION,
    payload: { postulacionId, estadoSeleccionado },
  }
}

export const resetPutEstadoSeleccion = () => {
  return {
    type: PUT_ESTADO_SELECCION_POSTULACION_RESET,
  }
}
