import { put, call, takeLatest } from 'redux-saga/effects'
import emailService from '../../services/mail-service'
import services from '../../services'
import {
  CHANGE_PASSWORD_SEND_EMAIL,
  CHANGE_PASSWORD_SEND_EMAIL_SUCCESS,
  CHANGE_PASSWORD_SEND_EMAIL_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  ChangePasswordSendEmail,
  ChangePassword,
  ChangePasswordSendEmailSuccessDto,
} from '../types/change-password'

function* sendEmailChangePassword({ payload }: ChangePasswordSendEmail) {
  try {
    const changePasswordServiseResponse: ChangePasswordSendEmailSuccessDto = yield call(
      emailService.sendEmailChangePassword,
      payload
    )
    yield put({ type: CHANGE_PASSWORD_SEND_EMAIL_SUCCESS, payload: changePasswordServiseResponse })
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_SEND_EMAIL_ERROR, payload: null })
  }
}

function* setNewPassword({ payload }: ChangePassword) {
  try {
    const result = yield call(services.applicant.changePassword, payload)
    yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* changePassword() {
  yield takeLatest<ChangePasswordSendEmail>(CHANGE_PASSWORD_SEND_EMAIL, sendEmailChangePassword)
  yield takeLatest<ChangePassword>(CHANGE_PASSWORD, setNewPassword)
}
