import { Request, Get } from '@navent-jobs/config/dist/utils/rest-client'

export class ErrorPaymentInventory extends Error {}

class PaymentInventory {
  public async GetPaymentIventory() {
    const request = new Request()
    request.path = `/api/candidates/inventory`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorPaymentInventory(`No se pudo obtener el inventario`)
        }
      })
  }
}

const paymentInventory = new PaymentInventory()
export default paymentInventory
