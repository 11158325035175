import styled, { keyframes } from 'styled-components'

const animateUp = keyframes`
  0% {
      opacity: 0;
      transform: translateY(40px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
`

const imageAnimateDesktop = keyframes`
  0% {
      opacity: 0;
      transform: translateX(-40px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
`

export const BackgroundCoverage = styled.div`
  background: ${({ theme }) => theme.colors.secondary.ultralighten};
  background-image: ${({ bkg }) => `url(${bkg})`};
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 584px;

  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    height: 524px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: 510px;
    overflow: initial;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    height: 552px;
  }
`

export const SerchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: 510px;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    height: 552px;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.grey84};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin: 24px 0px 0px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 395px;
    margin: 0px 0px 24px;
    font-size: 40px;
    line-height: 60px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-width: 376px;

    margin: 0px auto 24px;
  }
`

export const MarktingHeaderImg = styled.img`
  width: 485px;
  animation: ${animateUp} 1s ease-in-out;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: 100%;
    max-width: 720px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    animation: ${imageAnimateDesktop} 1s ease-in-out;
    max-width: 586px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-width: 636px;
  }
`

export const MarketingWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    justify-content: space-around;
    height: 100%;
  }
`

export const LinkContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey0};
  padding-left: 23px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  animation: ${animateUp} 2s ease-in-out;
  position: absolute;
  top: auto;
  bottom: 8px;
  left: auto;
  border-radius: 8px 8px 0px 0px;
  right: auto;
}

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    border-radius: 8px 0px 0px 8px;
    right: 0px;
    top: 198px;
    bottom: auto;
  }
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    top: 324px;
    bottom: auto;
  }
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    top: 438px;
    right: 0px;
    border-radius: 8px;
    bottom: auto;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    top: 479px;
    right: 0px;
    bottom: auto;
  }
`
