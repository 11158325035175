import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const CardComponentWrapper = styled('div')`
  height: ${({ height }) => (height ? height + 'px' : '100%')};
  background: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  padding: 16px;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  }
`

export const LinkCustom = styled(Link)`
  display: block;
  position: relative;
  font-weight: normal;
  text-align: left;
  padding: 0;
  border: none;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  &:focus:not(:active) {
    .company-single-star:before {
      background-color: #fab306 !important;
    }
  }

  &:focus,
  &:active,
  &:hover:not(:active) {
    .company-active-searching i:before {
      background-color: ${({ theme }) => theme.colors.extra.normal} !important;
    }
  }
`
