import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services/products'
import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  SEND_DATA_FORM,
  SEND_DATA_FORM_SUCCESS,
  SEND_DATA_FORM_ERROR,
  SendDataForm,
  GET_PRODUCTS_STATUS,
  GET_PRODUCTS_STATUS_ERROR,
  GET_PRODUCTS_STATUS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCT_AVAILABILITY,
  GET_PRODUCT_AVAILABILITY_SUCCESS,
  GET_PRODUCT_AVAILABILITY_ERROR,
} from '../types/products'

function* getProduct({ payload }) {
  try {
    const result = yield call(services.getProduct, payload)
    yield put({ type: GET_PRODUCT_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PRODUCT_ERROR, error })
  }
}

function* getProductAvailability({ payload }) {
  try {
    const result = yield call(services.getProductAvailability, payload)
    yield put({ type: GET_PRODUCT_AVAILABILITY_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PRODUCT_AVAILABILITY_ERROR, error })
  }
}

function* sendDataForm(payload: SendDataForm) {
  try {
    const result = yield call(services.sendFormData, payload)
    yield put({ type: SEND_DATA_FORM_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: SEND_DATA_FORM_ERROR, error })
  }
}

function* getProductsStatus() {
  try {
    const result = yield call(services.getProductStatus)
    yield put({ type: GET_PRODUCTS_STATUS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PRODUCTS_STATUS_ERROR, error })
  }
}

function* getProducts({ payload }) {
  try {
    const result = yield call(services.getProducts, payload)
    yield put({ type: GET_PRODUCTS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PRODUCTS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* products() {
  yield takeLatest<any>(GET_PRODUCT, getProduct)
  yield takeLatest(SEND_DATA_FORM, sendDataForm)
  yield takeLatest(GET_PRODUCTS_STATUS, getProductsStatus)
  yield takeLatest<any>(GET_PRODUCTS, getProducts)
  yield takeLatest<any>(GET_PRODUCT_AVAILABILITY, getProductAvailability)
}
