import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  JovenesProfesionalesOnLoad: pathname => {
    /**
     * Page donde se realizó la búsqueda
     */
    let page
    if (pathname === '/') {
      page = 'Impresion-Home'
    } else if (pathname.includes('/empleos/')) {
      page = 'Impresion-Aviso'
    } else if (pathname.includes('/empleos') || pathname.includes('/empleos-')) {
      page = 'Impresion-Listado'
    } else {
      page = 'Impresion-Otro'
    }

    /**
     * El objetivo es conocer el uso que le dan los usuarios al boton de Jovenes Profesionales.
     *
     * Debe dispararse cuando se renderiza el boton.
     */

    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'CTA Jóvenes Profesionales',
      eventLabel: page,
    })
  },

  JovenesProfesionalesOnClick: pathname => {
    /**
     * Page donde se realizó la búsqueda
     */
    let page
    if (pathname === '/') {
      page = 'Click-Home'
    } else if (pathname.includes('/empleos/')) {
      page = 'Click-Aviso'
    } else if (pathname.includes('/empleos') || pathname.includes('/empleos-')) {
      page = 'Click-Listado'
    } else {
      page = 'Click-Otro'
    }

    /**
     * El objetivo es conocer el uso que le dan los usuarios al boton de Jovenes Profesionales.
     *
     * Debe dispararse cuando el usuario hace click en el boton de Jovenes Profesionles.
     */

    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'CTA Jóvenes Profesionales',
      eventLabel: page,
    })
  },
}
