import { Request, Get, Put } from '@navent-jobs/config'
import { EditarSuscripcionType, GetSuscripcionMapType, GetSuscripcionType } from '../redux/types/suscripcion'

export class ErrorSuscription extends Error {}

class Suscriptions {
  public getSuscripciones(): Promise<GetSuscripcionType[]> {
    const path = '/api/suscripcion'
    const request = new Request()
    request.method = Get
    request.path = path

    return request.call().catch(error => {
      throw new ErrorSuscription(`Error obteniendo lista de suscripciones, ${error}`)
    })
  }

  public async getSuscripcionesMap(): Promise<GetSuscripcionMapType> {
    const suscripciones = await this.getSuscripciones()
    return suscripciones.reduce((acc, suscripcion) => {
      acc[suscripcion.idCategoria] = suscripcion
      return acc
    }, {})
  }

  public async editarSuscripcion(suscripcion: EditarSuscripcionType) {
    const request = new Request()
    request.path = `/api/suscripcion/${suscripcion.idCategoria}`
    request.method = Put
    request.body = {
      email: suscripcion.email,
      push: suscripcion.push,
    }
    return request.call().catch(error => {
      throw new ErrorSuscription(`Error editando la suscripcion, ${error}`)
    })
  }
}

const suscriptions = new Suscriptions()

export default suscriptions
