/* eslint-disable max-len */
const avisosFake = {
  aviso: {
    localizacion: 'Capital Federal, Buenos Aires',
    idPais: 1,
  },
  banners: {
    2: {
      titulo: 'Chofer / Taxista / Remisero (extranjero)',
      detalle:
        'Compañía de movilidad incorpora ingenieros y arquitectos extranjeros con grandes necesidades de trabajar, para desempeñarse como choferes. Trato cordial, disponibilidad para viajar sin previo aviso.',
    },
    4: {
      titulo: 'Community Manager  trans/no binarie - Multinacional sector alimenticio',
      detalle:
        'Candidatos trans/no binaries para cumplir con la cuota de diversidad exigida de casa matriz. Recibidos en comunicación y carreras afines, con inglés bilingüe y maestría finalizada o en curso.',
    },
    6: {
      titulo: 'Diseñador gráfico part-time zona sur (disponible full time)',
      detalle:
        'Excelente oportunidad de primera experiencia para estudiantes dispuestos a asumir las responsabilidades de un trabajador en relación de dependencia, pero sin los mismos beneficios.',
    },
    8: {
      titulo: 'Abogada sin hijos - Estudio jurídico, modalidad presencial',
      detalle:
        'Especializada en familia, preferentemente sin familia. Sueldo 30% menor al de sus pares masculinos. Se valora experiencia previa y tolerancia a la frustración. Escasas oportunidades de progreso.',
    },
    10: {
      titulo: 'Operario hombre Rubro minería',
      detalle:
        'Vacantes para personas con experiencia en el sector y gran fuerza física, capaces de manipular objetos pesados de manera autónoma. Candidatas mujeres abstenerse.',
    },
  },
}

export default avisosFake
