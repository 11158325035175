import {
  POST_TO_APPLY,
  POST_TO_APPLY_SUCCESS,
  POST_TO_APPLY_ERROR,
  SEND_ANSWERS,
  SEND_ANSWERS_SUCCESS,
  SEND_ANSWERS_ERROR,
  SEND_ANSWERS_RESET,
  initialState,
  PostularBarAction,
  PostularBarState,
} from '../types/postular-bar'

const reducers = (state: PostularBarState = initialState, action: PostularBarAction): PostularBarState => {
  switch (action.type) {
    case POST_TO_APPLY:
      return {
        ...state,
        PostToApply: {
          data: action.payload,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case POST_TO_APPLY_SUCCESS:
      return {
        ...state,
        PostToApply: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case POST_TO_APPLY_ERROR:
      return {
        ...state,
        PostToApply: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case SEND_ANSWERS:
      return {
        ...state,
        SendAnswers: {
          data: action.payload,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case SEND_ANSWERS_SUCCESS:
      return {
        ...state,
        SendAnswers: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case SEND_ANSWERS_ERROR:
      return {
        ...state,
        SendAnswers: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case SEND_ANSWERS_RESET:
      return {
        ...state,
        SendAnswers: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      }
    default:
      return state
  }
}

export default reducers
