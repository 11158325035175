import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const MyLoader = props => {
  const height = props.isMobile ? 50 : 95
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={height}
        viewBox={`0 0 ${wrapperWidth} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#d4d4d4"
        {...props}
      >
        <rect x="12" y={height / 2.5} rx="4" ry="4" width={wrapperWidth / 3} height="12" />
        {!props.isMobile && (
          <>
            <rect x={wrapperWidth / 2} y="16" rx="4" ry="4" width={wrapperWidth / 4} height="12" />
            <rect x={wrapperWidth / 2} y="38" rx="4" ry="4" width={wrapperWidth / 4 + 25} height="16" />
            <rect x={wrapperWidth / 2} y="66" rx="4" ry="4" width={wrapperWidth / 4 + 35} height="12" />
          </>
        )}
        <rect x={wrapperWidth - 40} y={height / 2.5} rx="4" ry="4" width="16" height="16" />
      </ContentLoader>
    </LoaderContainer>
  )
}
export default MyLoader
