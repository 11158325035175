import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

// uikit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'

// style
import { Wrapper, ImgContainer, Title, Claim, ButtonBack, Warning, WarningText } from './mixins'
import { ContentWrapper, FlexContainer } from '../../mixins'
import { goBackOrHome } from '../../../../utils/navigation-util'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'
import calificarImg from '../../../../constants/calificar-img'

const Component = ({ nameCompany }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    DataLayersReviews.thankYouPrint(nameCompany)
  }, [])

  return (
    <MainContainer>
      <Row>
        <Col>
          <FlexContainer>
            <ContentWrapper>
              <Wrapper>
                <ImgContainer>
                  <img src={calificarImg.confirmacion} alt="acceso" />
                </ImgContainer>
                <Title>
                  <Text size="lg" fontWeight="semibold">
                    {t('review.confirmation.title', { nameCompany })}
                  </Text>
                </Title>
                <Claim>
                  <Text size="md">{t('review.confirmation.claim')}</Text>
                </Claim>
                <Warning>
                  <Icon name="icon-light-info" size="20" color={colors.grey84} />
                  <WarningText>
                    <Text size="sm">{t('review.confirmation.warn')}</Text>
                  </WarningText>
                </Warning>

                <ButtonBack
                  onClick={() => goBackOrHome(history)}
                  buttonoptions={{ variant: 'primary', type: 'fill', size: 'medium' }}
                >
                  {t('review.confirmation.button')}
                </ButtonBack>
              </Wrapper>
            </ContentWrapper>
          </FlexContainer>
        </Col>
      </Row>
    </MainContainer>
  )
}

export default Component
