import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import FilterSidebar from './components/filter-sidebar'
import FiltersMobile from './components/filters-mobile'

const FiltersSection = ({ filters, filtersApplied, filtersSettings, loading, location }) => {
  const { isMobile, isTabletPortrait } = useWindowSize()

  return isMobile || isTabletPortrait ? (
    <FiltersMobile
      location={location}
      filters={filters}
      filtersApplied={filtersApplied}
      filtersSettings={filtersSettings}
      deleteAll={filtersApplied && filtersApplied.length > 0}
    />
  ) : (
    <FilterSidebar
      location={location}
      filters={filters}
      filtersApplied={filtersApplied}
      filtersSettings={filtersSettings}
      deleteAll={filtersSettings.deleteAll}
      loading={loading}
    />
  )
}

const FiltersSectionWithRoute = withRouter(FiltersSection)

const states = ({ listadoEmpresasStore }) => ({
  filters: listadoEmpresasStore.filters,
  filtersApplied: listadoEmpresasStore.filtersApplied,
  filtersSettings: listadoEmpresasStore.filtersSettings,
  loading: listadoEmpresasStore.companiesStates.loading,
  ...listadoEmpresasStore,
})

export default connect(states)(FiltersSectionWithRoute)
