import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'
import { Heading, Lead } from './mixins'

const Component = () => {
  const { t } = useTranslation()
  return (
    <>
      <Heading>
        <Text type="title" size="sm" fontWeight="semibold" variant="secondary" as="h2">
          {t('Cambiá tu contraseña')}
        </Text>
      </Heading>
      <Lead>{t('Elegí una nueva contraseña para ingresar a tu cuenta.')}</Lead>
    </>
  )
}

export default Component
