import styled from 'styled-components'

export const Component = styled('div')`
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 20px;
  }
`

export const Heading = styled('div')`
  max-width: 200px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    max-width: initial;
  }
`

export const Image = styled('img')`
  max-width: 220px;
  position: absolute;
  right: -59px;
  top: -33px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) and (max-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 150px;
    right: -40px;
    top: -22px;
  }
`
