import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { changePassword } from '../../../../redux/actions/applicant/account/changePassword'
import { SkeletonChangePass } from '../common/skeleton/PasswordChange'
import {
  Content,
  Header,
  FormGroup,
  SubTitle,
  RequiredTitle,
  RightCol,
  LeftCol,
  CustomSubmitButton,
  CustomLabel,
} from './mixins'
import { Title, Divider } from '../../mixins'

const MIN_CHARACTER_VALUE = 6

interface ChangePassProps {
  state: { success: boolean; error: boolean; loading: boolean }
  statusCode: string | null
  isLogged: boolean
}

const PasswordChangeContent = ({ statusCode, state, isLogged }: ChangePassProps) => {
  const {
    formState: { errors },
    setError,
    trigger,
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { t } = useTranslation()
  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [newPassRepeat, setNewPassRepeat] = useState('')
  const [samePassword, setSamePassword] = useState(false)
  const { success, error, loading } = state

  const dispatch = useDispatch()

  const buttonIsDisabled =
    oldPass.length < MIN_CHARACTER_VALUE ||
    newPass.length < MIN_CHARACTER_VALUE ||
    newPassRepeat.length < MIN_CHARACTER_VALUE ||
    !samePassword ||
    loading

  const onSubmit = () => {
    dispatch(changePassword({ passwordActual: oldPass, passwordNueva: newPass }))
  }

  useEffect(() => {
    if (success) {
      dispatch(showSnackbar(t('account.snackbarMessage.success'), 'icon-light-save'))
      setNewPass('')
      setOldPass('')
      setNewPassRepeat('')
    } else if (error) {
      if (statusCode === '400') {
        clearErrors('oldPass')
        setError('oldPass', {
          type: 'manual',
          message: t('account.password.checkPassword'),
        })
      } else {
        dispatch(showSnackbarError(t('account.snackbarMessage.error')))
      }
    }
  }, [state])

  const checkInput = target => {
    const isFilled = target.value.replace(/\s/g, '') !== ''
    if (target.value.length < MIN_CHARACTER_VALUE || !isFilled) {
      clearErrors(target.name)
      setError(target.name, {
        type: 'manual',
        /* Si hay datos en el imput vale el error de 6 caracteres, sinó, vale el error de contraseña valida  */
        message: !isFilled ? t('account.password.checkNewPassword') : t('account.password.characterValidation'),
      })
    } else if (target.name !== 'newPassRepeat') {
      clearErrors(target.name)
    }
  }

  const comparePassword = (password, compareToPassword) => {
    if (newPassRepeat.length >= MIN_CHARACTER_VALUE - 1) {
      const comparePasswordValidate = password === compareToPassword
      setSamePassword(comparePasswordValidate)
      clearErrors('newPassRepeat')
      trigger('newPassRepeat')

      if (!comparePasswordValidate) {
        clearErrors('newPassRepeat')
        setError('newPassRepeat', {
          type: 'manual',
          message: t('account.password.checkMachError'),
        })
      }
    }
  }

  if (isLogged === null) {
    return <SkeletonChangePass />
  }

  return (
    <Content>
      <Header>
        <Title>{t('account.password.title')}</Title>
        <Divider />
        <SubTitle>{t('account.password.subTitle')}</SubTitle>
        <RequiredTitle>{t('account.requiredTitle')}</RequiredTitle>
      </Header>
      <ContainerFluid>
        <form id="form-change-password" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftCol sm={6} noGutter>
              <FormGroup>
                <Input
                  id="oldPass"
                  name="oldPass"
                  type="password"
                  value={oldPass}
                  rules={{ required: true }}
                  onBlur={({ target }) => {
                    checkInput(target)
                  }}
                  placeholder={t('account.password.currentPassword')}
                  fieldOptions={{
                    label: <CustomLabel> {'Contraseña actual'} </CustomLabel>,
                    variant: 'darken',
                    maxLength: 50,
                  }}
                  onChange={({ target: { value } }) => {
                    setOldPass(value)
                  }}
                  errors={{ ...errors }}
                />
              </FormGroup>
            </LeftCol>
          </Row>
          <Row>
            <LeftCol sm={6} noGutter>
              <FormGroup>
                <Input
                  id="newPass"
                  name="newPass"
                  type="password"
                  value={newPass}
                  rules={{ required: true }}
                  placeholder={t('account.password.newPassword')}
                  onChange={({ target: { value } }) => {
                    setNewPass(value)
                    comparePassword(value, newPassRepeat)
                  }}
                  onBlur={({ target }) => {
                    checkInput(target)
                  }}
                  fieldOptions={{
                    label: <CustomLabel> {'Nueva contraseña'} </CustomLabel>,
                    variant: 'darken',
                    maxLength: 50,
                  }}
                  errors={{ ...errors }}
                />
              </FormGroup>
            </LeftCol>
            <RightCol sm={6} noGutter>
              <FormGroup>
                <Input
                  id="newPassRepeat"
                  name="newPassRepeat"
                  type="password"
                  value={newPassRepeat}
                  rules={{ required: true }}
                  placeholder={t('account.password.newPasswordAgain')}
                  onChange={({ target: { value } }) => {
                    setNewPassRepeat(value)
                    comparePassword(value, newPass)
                  }}
                  onBlur={({ target }) => {
                    checkInput(target)
                  }}
                  fieldOptions={{
                    label: <CustomLabel> {t('account.password.newPasswordAgainLabel')} </CustomLabel>,
                    variant: 'darken',
                    maxLength: 50,
                  }}
                  errors={{ ...errors }}
                />
              </FormGroup>
            </RightCol>
          </Row>
          <Row>
            <Col noGutter>
              <CustomSubmitButton idForm="form-change-password" isLoading={loading} disabled={buttonIsDisabled}>
                {t('Cambiar contraseña')}
              </CustomSubmitButton>
            </Col>
          </Row>
        </form>
      </ContainerFluid>
    </Content>
  )
}

const states = ({ accountChangePasswordStore }) => {
  const { state, statusCode } = accountChangePasswordStore
  return {
    state,
    statusCode,
  }
}

export default connect(states)(PasswordChangeContent)
