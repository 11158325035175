import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_RECOMMENDED,
  GET_COMPANIES_RECOMMENDED_SUCCESS,
  GET_COMPANIES_RECOMMENDED_ERROR,
  SET_FILTERS_SETTINGS_COMPANIES,
  SET_FILTERS_SETTINGS_COMPANIES_SUCCESS,
  SET_FILTERS_SETTINGS_COMPANIES_ERROR,
  SET_FILTERS_APPLIED,
  SET_FILTERS_APPLIED_SUCCESS,
  SET_FILTERS_APPLIED_ERROR,
  GET_CARROUSEL_COMPANIES,
  GET_CARROUSEL_COMPANIES_SUCCESS,
  GET_CARROUSEL_COMPANIES_ERROR,
  ListadoEmpresasState,
  ListadoEmpresasActions,
  initialState,
} from '../types/listado-empresas'
import { status } from '../types/status'

const reducers = (state: any = initialState, action: ListadoEmpresasActions): ListadoEmpresasState => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companiesStates: status.loading,
      }
    case GET_CARROUSEL_COMPANIES:
      return {
        ...state,
        carrouselCompaniesStates: status.loading,
      }
    case GET_COMPANIES_RECOMMENDED:
      return {
        ...state,
        companiesRecommendedStates: status.loading,
      }
    case SET_FILTERS_SETTINGS_COMPANIES:
    case SET_FILTERS_APPLIED:
      return {
        ...state,
        filterStates: status.loading,
      }
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        companiesStates: status.success,
      }
    case GET_CARROUSEL_COMPANIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        carrouselCompaniesStates: status.success,
      }
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        companiesStates: status.error,
      }
    case GET_CARROUSEL_COMPANIES_ERROR:
      return {
        ...state,
        carrouselCompaniesStates: status.error,
      }
    case GET_COMPANIES_RECOMMENDED_ERROR:
      return {
        ...state,
        companiesRecommendedStates: status.error,
      }
    case GET_COMPANIES_RECOMMENDED_SUCCESS:
      return {
        ...state,
        companiesRecommended: action.payload,
        companiesRecommendedStates: status.success,
      }
    case SET_FILTERS_SETTINGS_COMPANIES_SUCCESS:
      return {
        ...state,
        filtersSettings: {
          ...action.payload,
        },
        filterStates: status.success,
      }
    case SET_FILTERS_SETTINGS_COMPANIES_ERROR:
      return {
        ...state,
        filtersSettings: null,
        filterStates: status.error,
      }
    case SET_FILTERS_APPLIED_SUCCESS:
      return {
        ...state,
        filtersApplied: action.payload,
        filterStates: status.success,
      }
    case SET_FILTERS_APPLIED_ERROR:
      return {
        ...state,
        filtersApplied: null,
        filterStates: status.error,
      }
    default:
      return state
  }
}

export default reducers
