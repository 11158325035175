export default {
  password: {
    subTitle: 'Modificá tu contraseña actual por una nueva.',
    currentPassword: 'Ingresá tu contraseña actual',
    newPassword: 'Ingresá tu nueva contraseña',
    newPasswordAgain: 'Volvé a ingresar la contraseña',
    newPasswordAgainLabel: 'Repetí tu nueva contraseña',
    checkPassword: 'Revisá tu contraseña.',
    characterValidation: 'Ingresá al menos 6 caracteres.',
    checkNewPassword: 'Ingresá una contraseña válida',
  },
  deleteAccount: {
    title: 'Eliminar mi cuenta',
    subtitle:
      'Si eliminás tu cuenta de {{nombre_portal}} no podrás postularte a más ofertas de trabajo ni revisar tus postulaciones.',
    password: {
      placeholder: 'Ingresá tu contraseña',
      checkPassword: 'Revisá tu contraseña.',
      characterValidation: 'Ingresá al menos 6 caracteres.',
      invalidPassword: 'Ingresá una contraseña válida.',
    },
    reason: {
      title: '¿Por qué querés eliminar tu cuenta?',
      placeholder: 'Escribí tu respuesta',
    },
  },

updateEmail: {
    subTitle: 'Modificá tu email de ingreso',
    warning: 'El mail de ingreso es el mismo que se les muestra a las empresas.',
    currentEmail: 'Email actual',
    newEmailPlaceholder: 'Ingresá un email válido.',
    newEmail: 'Ingresá nuevo email',
    passwordAgain: 'Ingresá tu contraseña actual',
    passwordAgainLabel: 'Contraseña actual',
      validations: {
      invalid: 'Ingresá un email válido',
      underUse: 'El email ya está en uso',
      equal: 'Ingresá un mail diferente al actual',
      wrongPass:'Ingresá una contraseña válida'
    },
      snackbarMessage: {
      success: 'Revisá tu email para confirmar el cambio',
    }
  },
}
