import React from 'react'
import { CardComponent } from './mixins'

interface CardProps {
  children
}

const Card = ({ children }: CardProps) => {
  return <CardComponent>{children}</CardComponent>
}

export default Card
