const saveCompany = {
  empty: {
    subtitle: '¡Hacé una búsqueda y guardá las empresas que más te interesen!',
  },
  cards: {
    info: {
      text: 'Podés <0>seguir a las empresas y recibir notificaciones o mails</0> con actualizaciones de empleos, sueldos y evaluaciones de las empresas ',
    },
    alerts: {
      text: 'Para modificar todas las notificaciones podés ir a <0>Ajustes de notificaciones y alertas</0>',
    },
  },
  snackbar: {
    seguir: 'Seguís esta empresa',
  },
}

export default saveCompany
