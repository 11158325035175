import { getCookieData, setCookieData } from '../../utils/cookies'

const MarketingUTM = () => {
  const query = new URLSearchParams(window.location.search)
  // Obtenemos el parametro de UTM por url
  const utmParam = query.get('utm_campaign')

  // Si lo enviaron entonces verificamos si ya no existe una cookie previa
  if (utmParam) {
    const utmCookie = getCookieData('utm_campaign', document.cookie)

    // Si no existe la seteamos por X segundos
    if (!utmCookie) {
      setCookieData('utm_campaign', utmParam, 1800)
    }
  }
  return null
}

export default MarketingUTM
