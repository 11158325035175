import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'

// ui-kit
import { Container, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import {
  ImageContainer,
  ImgCoderhouse,
  Paragraph,
  Title,
  CopyclipboardContainer,
  Label,
  CopyButton,
  ButtonWrapper,
  ButtonSuccess,
  ButtonCancel,
  Legales,
} from './mixins'

// assets
import coderhouseImage from '../../../assets/images/coderhouse/coderhouse-modal.svg'

// redux
import { hideModal } from '../../../redux/actions/modal'
import { showSnackbar } from '../../../redux/actions/applicant/curriculum/snackbar'
import { DataLayersCoderhouse } from '../../../constants/gtm-events-coderhouse'

interface ComponentProps {
  visible: boolean
}

const Component = ({ visible }: ComponentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const nav = navigator as any

  const code = t('products.modal.coderhouse.code')

  const handleOnClick = () => {
    DataLayersCoderhouse.interaccion('Copiar codigo')
    nav.clipboard.writeText(code)
    dispatch(showSnackbar('Código copiado', 'icon-light-copy'))
  }

  const closeModal = () => {
    DataLayersCoderhouse.interaccion('Cerrar Modal')
    dispatch(hideModal())
  }

  const onCancel = () => {
    DataLayersCoderhouse.interaccion('Cancelar')
    dispatch(hideModal())
  }

  const onSubmit = () => {
    DataLayersCoderhouse.interaccion('Ir a CoderHouse')
    dispatch(hideModal())
    window.open(t('products.modal.coderhouse.url'))
  }

  useEffect(() => {
    if (visible) {
      DataLayersCoderhouse.printModal()
    }
  }, [visible])

  return (
    <>
      <Modal width={496} visible={visible} onClose={closeModal}>
        <Container>
          <Row>
            <Col>
              <ImageContainer>
                <ImgCoderhouse src={coderhouseImage} width="100%" height="100%" alt="producto coderhouse" />
              </ImageContainer>

              <Title>{t('products.modal.coderhouse.title')}</Title>

              <Paragraph>{ReactHtmlParser(t('products.modal.coderhouse.description'))}</Paragraph>

              <CopyclipboardContainer>
                <Label>{t('products.modal.coderhouse.label')}</Label>

                <Input
                  id="InputCodeCoderhouse"
                  name="InputCodeCoderhouse"
                  type="text"
                  value={code}
                  fieldOptions={{
                    variant: 'darken',
                    maxLength: 50,
                    after: (
                      <CopyButton
                        onClick={handleOnClick}
                        buttonoptions={{ variant: 'secondary', color: '#0a26ee', type: 'link', size: 'small' }}
                      >
                        <Icon name="icon-light-copy" color="#0a26ee" size="24" />
                      </CopyButton>
                    ),
                  }}
                />
              </CopyclipboardContainer>

              <ButtonWrapper>
                <ButtonSuccess
                  buttonoptions={{ variant: 'primary', size: 'medium', type: 'fill', block: true }}
                  onClick={onSubmit}
                >
                  {t('products.modal.coderhouse.buttons.success')}
                </ButtonSuccess>

                <ButtonCancel
                  buttonoptions={{ variant: 'primary', size: 'medium', type: 'outline', block: true }}
                  onClick={onCancel}
                >
                  {t('products.modal.coderhouse.buttons.cancel')}
                </ButtonCancel>
              </ButtonWrapper>

              <Legales>{t('products.modal.coderhouse.legal')}</Legales>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  )
}

const states = ({ modalStore }) => ({
  visible: modalStore.activeModal === 'modal-code-coderhouse',
})

export default connect(states)(Component)
