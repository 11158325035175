import { put, call, takeLatest, select } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_POSTULACIONES,
  GET_POSTULACIONES_SUCCESS,
  GET_POSTULACIONES_ERROR,
  GET_POSTULACION,
  GET_POSTULACION_SUCCESS,
  GET_POSTULACION_ERROR,
  GET_ESTADOS_SELECCION_POSTULACION_SUCCESS,
  GET_ESTADOS_SELECCION_POSTULACION_ERROR,
  GET_ESTADOS_SELECCION_POSTULACION,
  PUT_ESTADO_SELECCION_POSTULACION,
  PUT_ESTADO_SELECCION_POSTULACION_SUCCESS,
  PUT_ESTADO_SELECCION_POSTULACION_ERROR,
} from '../types/postulaciones'

const TOTAL_AVISOS_PER_PAGE = 10

function* getPostulacion({ payload }) {
  try {
    const result = yield call(
      services.postulaciones.getPostulacion,
      payload.idPostulacion,
      payload.statsTypes,
      payload.onlyStats
    )
    yield put({ type: GET_POSTULACION_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_POSTULACION_ERROR, error })
  }
}

function* getPostulaciones({ payload }) {
  try {
    const result = yield call(services.postulaciones.getPostulaciones, payload)
    const pagination = {
      currentPage: result ? result.number + 1 : 1,
      totalPages: result.total > TOTAL_AVISOS_PER_PAGE ? Math.ceil(result.total / result.size) : 0,
      elementsPerPage: result ? result.size : 10,
      totalElements: result ? result.total : 0,
    }

    const postulaciones = result && result.content.length > 0 ? result.content : null

    const totalAvisos = result ? result.total : 0
    const filters = result?.filters

    const state = yield select()
    const { perfil } = state.postulacionesStore
    const pendientes = postulaciones?.filter(p => p.tienePreguntasPendientes)?.length || 0

    if (pagination.currentPage === 1) {
      perfil.primerPostulacion = postulaciones && postulaciones.length > 0 ? postulaciones[0].id : null
      perfil.totalPreguntasPendientes = pendientes
    } else {
      perfil.totalPreguntasPendientes += pendientes
    }

    const listadoPostulaciones = {
      pagination,
      postulaciones,
      totalAvisos,
      filters,
      perfil,
    }

    yield put({ type: GET_POSTULACIONES_SUCCESS, payload: listadoPostulaciones })
    !postulaciones && (yield put({ type: GET_POSTULACIONES_ERROR, error: true }))
  } catch (error) {
    yield put({ type: GET_POSTULACIONES_ERROR, error })
  }
}

function* getEstadosSeleccion() {
  try {
    const result = yield call(services.postulaciones.getEstadosSeleccion)
    yield put({ type: GET_ESTADOS_SELECCION_POSTULACION_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_ESTADOS_SELECCION_POSTULACION_ERROR, error })
  }
}

function* putEstadoSeleccion({ payload }) {
  try {
    yield call(services.postulaciones.putEstadoSeleccion, payload)
    yield put({ type: PUT_ESTADO_SELECCION_POSTULACION_SUCCESS })
    if (window.location.pathname.includes('postulantes/postulaciones/estadisticas-')) {
      yield put({ type: GET_POSTULACION, payload: { idPostulacion: payload?.postulacionId?.toString() } })
    }
  } catch (error) {
    yield put({ type: PUT_ESTADO_SELECCION_POSTULACION_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_POSTULACIONES, getPostulaciones)
  yield takeLatest<any>(GET_POSTULACION, getPostulacion)
  yield takeLatest<any>(GET_ESTADOS_SELECCION_POSTULACION, getEstadosSeleccion)
  yield takeLatest<any>(PUT_ESTADO_SELECCION_POSTULACION, putEstadoSeleccion)
}
