import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Col, ContainerFluid, Row } from '@navent-jobs/ui-kit/components/grid'

// mixins
import { CustomContainer, CustomGrid } from './mixins'

// components
import CardPromedioSalarial from '../card'
import CardPlaceholder from '../card-placeholder'
import EmptyWithButton from '../../../../components/empty-with-button'

// redux
import { showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'

const TOTAL_CARDS_PLACEHOLDER = 10

interface ListadoPromedioSalarialProps {
  error
  loading
  promedios
  title?: Function | null
  onClick?: Function | null
  iconBtn?: boolean
}

const ListadoPromedioSalarial = ({
  error,
  loading,
  promedios,
  onClick,
  iconBtn,
  title = null,
}: ListadoPromedioSalarialProps) => {
  const { isMobile, isTabletPortrait } = useWindowSize()
  const dispatch = useDispatch()
  const showEmpty = (!promedios || promedios.length < 1) && !loading

  const goToSearch = () => {
    window.location.href = '/salarios'
  }

  useEffect(() => {
    if (error) {
      dispatch(showSnackbarError('Ocurrió un error inesperado.'))
    }
  }, [error])

  return (
    <CustomContainer>
      {showEmpty ? (
        <ContainerFluid>
          <Row>
            <Col sm={12} noGutter>
              <EmptyWithButton
                heading="Por el momento no tenemos información para este salario"
                subtitle="¡Realizá otra búsqueda para ver los salarios!"
                buttonProps={{ text: 'Buscar salarios', onClick: goToSearch }}
              />
            </Col>
          </Row>
        </ContainerFluid>
      ) : (
        <>
          {title && title()}
          <CustomGrid>
            {loading ? (
              <>
                {[...Array(TOTAL_CARDS_PLACEHOLDER)].map(index => {
                  return <CardPlaceholder key={index} isMobile={isMobile || isTabletPortrait} />
                })}
              </>
            ) : (
              promedios &&
              promedios.map(promedio => (
                <CardPromedioSalarial promedioSalarial={promedio} onClick={onClick} iconBtn={iconBtn} />
              ))
            )}
          </CustomGrid>
        </>
      )}
    </CustomContainer>
  )
}

export default ListadoPromedioSalarial
