import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import SubmitButton from '../../../../components/SubmitButton'

export const Header = styled.div`
  width: 100%;
`
export const Content = styled.div`
  margin-top: 40px;
`

export const ContentIreversibleAction = styled.div`
  height: 20px;
  display: flex;
  margin-bottom: 12px;
`

export const TextIrreversibleAction = styled.p`
  color: ${({ theme }) => theme.colors.danger.normal};
  font-size: 14px;
  line-height: 20px;
`

export const IconIrreversibleAction = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`

export const CustomSubmitButton = styled(SubmitButton)`
  max-width: 100%;
  margin: 0px 0px 8px 0px;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 200px;
    margin: 0 auto;
  }
`
