import {
  GET_APPLICANT_UNREAD_NOTIFICATIONS,
  GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR,
  GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS,
  NotificationActions,
  NotificationState,
  initialState,
  GET_APPLICANT_NOTIFICATIONS,
  GET_APPLICANT_NOTIFICATIONS_SUCCESS,
  GET_APPLICANT_NOTIFICATIONS_ERROR,
  READ_MARK_APPLICANT_NOTIFICATIONS,
} from '../types/notifications'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: NotificationState = initialState, action: NotificationActions): NotificationState => {
  switch (action.type) {
    case GET_APPLICANT_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        newNotifications: {
          count: null,
          status: {
            success: false,
            error: false,
            loading: true,
          },
        },
      }
    case GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        newNotifications: {
          count: action.payload,
          status: {
            success: true,
            error: false,
            loading: false,
          },
        },
      }
    case GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        newNotifications: {
          count: null,
          status: {
            success: false,
            error: true,
            loading: false,
          },
        },
      }
    case GET_APPLICANT_NOTIFICATIONS:
      return {
        ...state,
        notificationList: {
          data: [],
          status: {
            success: false,
            error: false,
            loading: true,
          },
        },
      }
    case GET_APPLICANT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: {
          data: action.payload,
          status: {
            success: true,
            error: false,
            loading: false,
          },
        },
      }
    case GET_APPLICANT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationList: {
          data: [],
          status: {
            success: false,
            error: true,
            loading: false,
          },
        },
      }
    case READ_MARK_APPLICANT_NOTIFICATIONS:
      return {
        ...state,
        markAsReadNotification: null,
      }
    default:
      return state
  }
}

export default reducers
