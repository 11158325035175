import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_PROMEDIOS_SALARIALES,
  GET_PROMEDIOS_SALARIALES_SUCCESS,
  GET_PROMEDIOS_SALARIALES_ERROR,
  GET_PROMEDIO_SALARIAL_AREA,
  GET_PROMEDIO_SALARIAL_AREA_SUCCESS,
  GET_PROMEDIO_SALARIAL_AREA_ERROR,
  CALCULAR_SALARIO_NETO,
  CALCULAR_SALARIO_NETO_SUCCESS,
  CALCULAR_SALARIO_NETO_ERROR,
} from '../types/promedioSalarial'

function* getPromediosSalariales() {
  try {
    const result = yield call(services.promedioSalarial.getPromediosSalariales)
    yield put({ type: GET_PROMEDIOS_SALARIALES_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PROMEDIOS_SALARIALES_ERROR, error })
  }
}

function* getPromedioSalarialArea({ payload }) {
  try {
    const result = yield call(services.promedioSalarial.getPromedioSalarialArea, payload)
    yield put({ type: GET_PROMEDIO_SALARIAL_AREA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PROMEDIO_SALARIAL_AREA_ERROR, error })
  }
}

function* calcularSalarioNeto({ payload }) {
  try {
    const result = yield call(services.promedioSalarial.calculateSalary, payload)
    yield put({ type: CALCULAR_SALARIO_NETO_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: CALCULAR_SALARIO_NETO_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* promedioSalarial() {
  yield takeLatest<any>(GET_PROMEDIOS_SALARIALES, getPromediosSalariales)
  yield takeLatest<any>(GET_PROMEDIO_SALARIAL_AREA, getPromedioSalarialArea)
  yield takeLatest<any>(CALCULAR_SALARIO_NETO, calcularSalarioNeto)
}
