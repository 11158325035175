import React, { lazy, Suspense, useEffect } from 'react'
// Components
import { Row, Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import PageTitle from '../../components/PageTitle'
import LayoutAuthHeader from '../../components/layouts/layout-auth-header'
import PageLoader from '../../components/PageLoader'
import Meta from './components/Meta'
// Tools
import { handleSmoothScroll } from '../../hooks/handle-smooth-scroll'
import { SITE_ID } from '../../constants'
// Styles
import { PageContent } from './mixins'

// List of questions by country
const FaqcontentAr = lazy(
  () => import(/* webpackChunkName: "FaqcontentAr" */ './components/page-content-by-country/faq-content-ar')
)
const FaqcontentZjAr = lazy(
  () => import(/* webpackChunkName: "FaqcontentZjAr" */ './components/page-content-by-country/faq-content-zj-ar')
)
const FaqcontentCl = lazy(
  () => import(/* webpackChunkName: "FaqcontentCl" */ './components/page-content-by-country/faq-content-cl')
)
const FaqcontentEc = lazy(
  () => import(/* webpackChunkName: "FaqcontentEc" */ './components/page-content-by-country/faq-content-ec')
)
const FaqcontentMx = lazy(
  () => import(/* webpackChunkName: "FaqcontentMx" */ './components/page-content-by-country/faq-content-mx')
)
const FaqcontentPa = lazy(
  () => import(/* webpackChunkName: "FaqcontentPa" */ './components/page-content-by-country/faq-content-pa')
)
const FaqcontentPe = lazy(
  () => import(/* webpackChunkName: "FaqcontentPe" */ './components/page-content-by-country/faq-content-pe')
)
const FaqcontentVe = lazy(
  () => import(/* webpackChunkName: "FaqcontentVe" */ './components/page-content-by-country/faq-content-ve')
)

const PageFAQ = props => {
  const { hash } = window.location
  const FICHA_AVISO_PAGE_PATH = '/empleos/(.*)-:idAviso.html*'

  useEffect(() => {
    if (window.document) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }
  }, [])

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        handleSmoothScroll(hash, 120)
      }, 500)
    }
  }, [hash])

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
          <div>
            <PageContent>
              <Meta />
              <PageTitle iconName="icon-light-question-circle" title="Preguntas frecuentes" maxWidth={920} />
              <MainContainer>
                <Row>
                  <Col>
                    <Suspense fallback={<PageLoader />}>
                      {SITE_ID === 'BMAR' && <FaqcontentAr />}
                      {SITE_ID === 'ZJAR' && <FaqcontentZjAr />}
                      {SITE_ID === 'BMCL' && <FaqcontentCl />}
                      {SITE_ID === 'BMEC' && <FaqcontentEc />}
                      {SITE_ID === 'BMMX' && <FaqcontentMx />}
                      {SITE_ID === 'BMPA' && <FaqcontentPa />}
                      {SITE_ID === 'BMPE' && <FaqcontentPe />}
                      {SITE_ID === 'BMVE' && <FaqcontentVe />}
                    </Suspense>
                  </Col>
                </Row>
              </MainContainer>
            </PageContent>
          </div>
        </LayoutAuthHeader>
      </Suspense>
    </>
  )
}

export default PageFAQ
