import React from 'react'
import paymentMPSettings from '../../../../constants/payment-mp-settings'
import { PaymentImage } from './mixins'

const Component = ({ status, product }) => {
  const pageSetting = paymentMPSettings()

  return (
    <PaymentImage
      src={pageSetting.products[product][status].image}
      alt={`${pageSetting.products[product][status].alt}`}
    />
  )
}

export default Component
