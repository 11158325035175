export const CHANGE_PASSWORD_ACCOUNT = 'CHANGE_PASSWORD_ACCOUNT'
export const CHANGE_PASSWORD_ACCOUNT_RESET = 'CHANGE_PASSWORD_ACCOUNT_RESET'
export const CHANGE_PASSWORD_ACCOUNT_SUCCESS = 'CHANGE_PASSWORD_ACCOUNT_SUCCESS'
export const CHANGE_PASSWORD_ACCOUNT_ERROR = 'CHANGE_PASSWORD_ACCOUNT_ERROR'

export interface ChangePassword {
  type: typeof CHANGE_PASSWORD_ACCOUNT
  payload: ChangePasswordAccountType
}

export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_ACCOUNT_SUCCESS
  payload: null
}

export interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ACCOUNT_ERROR
  payload: string | null
}

export interface ChangePasswordReset {
  type: typeof CHANGE_PASSWORD_ACCOUNT_RESET
  payload: null
}

export interface ChangePasswordState {
  statusCode: string | null
  data: ChangePasswordAccountType | null
  state: Status
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export const initialChangePasswordState = {
  statusCode: null,
  data: null,
  state: initialStatus,
}

export interface ChangePasswordAccountType {
  passwordActual: string
  passwordNueva: string
}

export type ChangePasswordActions = ChangePassword | ChangePasswordSuccess | ChangePasswordError | ChangePasswordReset
