import { Request, Get, formatNameToStringId, Put } from '@navent-jobs/config'

export class ErrorPostulaciones extends Error {}

const DEFAULT_PAGE_SIZE = 10

class Postulaciones {
  public getPostulacion = async (idPostulacion, statsTypes, onlyStats) => {
    const request = new Request()
    request.path = `/api/candidates/postulaciones/${idPostulacion}/estadisticas`
    request.method = Get
    request.params = { statsTypes, onlyStats }
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorPostulaciones(`Postulación no encontrada`)
        }
      })
  }

  public getPostulaciones = async ({ page = 0, pageSize = DEFAULT_PAGE_SIZE, filtros }) => {
    const request = new Request()
    let path = `/api/candidates/postulaciones?pageSize=${pageSize}&page=${page}&sort=${filtros.sort} desc&query=${filtros.query}`
    if (filtros.fechaDesde) {
      path += `&fechaDesde=${filtros.fechaDesde}`
    }
    request.path =
      filtros.estado && filtros.estado.length > 0 ? `${path}&estados=${filtros.estado.toString().toUpperCase()}` : path
    request.method = Get
    return request
      .call()
      .then(response => {
        response.content = response.content?.map(postulacion => {
          if (postulacion.areaAviso) {
            postulacion.areaAviso = formatNameToStringId(postulacion.areaAviso)
          }
          if (postulacion.provinciaAviso) {
            postulacion.provinciaAviso = formatNameToStringId(postulacion.provinciaAviso)
          }
          if (postulacion.localidadAviso) {
            postulacion.localidadAviso = formatNameToStringId(postulacion.localidadAviso)
          }
          return postulacion
        })
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorPostulaciones(`Postulaciones no encontradas`)
        }
      })
  }

  public getEstadosSeleccion = async () => {
    const request = new Request()
    const path = '/api/candidates/postulaciones/estados-seleccion'
    request.path = path
    request.method = Get
    return request
      .call()
      .then(response => response)
      .catch(error => {
        throw new ErrorPostulaciones(`Error al obtener los estados de seleccion`)
      })
  }

  public putEstadoSeleccion = async ({ postulacionId, estadoSeleccionado }) => {
    const request = new Request()
    const path = `/api/candidates/postulaciones/${postulacionId}/estados-seleccion`
    request.path = path
    request.method = Put
    request.body = { estadoSeleccionado }
    return request.call().catch(error => {
      throw new ErrorPostulaciones(`Error al guardar el estado de seleccion a la postulacion ${postulacionId}`)
    })
  }
}

const postulaciones = new Postulaciones()
export default postulaciones
