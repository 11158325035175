import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 23px;
`

export const DetailContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 18px;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ isMobile }) => (isMobile ? '22px' : '0px')};
`

export const CustomLink = styled(Link)`
  padding: 0px 40px;
`
