import { status } from '../types/status'
import {
  GET_MOTIVOS_DENUNCIA,
  GET_MOTIVOS_DENUNCIA_ERROR,
  GET_MOTIVOS_DENUNCIA_SUCCESS,
  DenunciaActions,
  DenunciaState,
  initialState,
  POST_DENUNCIA_AVISO,
  POST_DENUNCIA_AVISO_SUCCESS,
  POST_DENUNCIA_AVISO_ERROR,
} from '../types/denuncia'

const reducers = (state: DenunciaState = initialState, action: DenunciaActions): DenunciaState => {
  switch (action.type) {
    case GET_MOTIVOS_DENUNCIA:
      return {
        ...state,
        motivosStates: status.loading,
      }
    case GET_MOTIVOS_DENUNCIA_SUCCESS:
      return {
        ...state,
        motivos: action.payload,
        motivosStates: status.success,
      }
    case GET_MOTIVOS_DENUNCIA_ERROR:
      return {
        ...state,
        motivosStates: status.error,
      }
    case POST_DENUNCIA_AVISO:
      return {
        ...state,
        denunciaAvisoStates: status.loading,
      }
    case POST_DENUNCIA_AVISO_SUCCESS:
      return {
        ...state,
        denunciaAvisoStates: status.success,
      }
    case POST_DENUNCIA_AVISO_ERROR:
      return {
        ...state,
        denunciaAvisoStates: status.error,
      }
    default:
      return state
  }
}

export default reducers
