import { Request, Put, Get } from '@navent-jobs/config'
import { ChangePasswordAccountType } from '../redux/types/applicant/account/changePassword'
import { UpdateEmailAccountType, UpdateEmailResponseType } from '../redux/types/applicant/account/updateEmail'
import { PrivacyType } from '../redux/types/applicant/account/privacy'

export class ErrorApplicant extends Error {}

class Account {
  public async changePasswordAccountRequest(value: ChangePasswordAccountType) {
    const request = new Request()
    request.path = `/api/ajustes/changePassword`
    request.method = Put
    request.body = value
    return request.call().catch(e => {
      throw new ErrorApplicant(e.response.status)
    })
  }

  public async updateEmailRequest(value: UpdateEmailAccountType):Promise<UpdateEmailResponseType> {
    const request = new Request()
    request.path = `/api/ajustes/updateEmail`
    request.method = Put
    request.body = value
    return request.call().catch(e => {
      throw new ErrorApplicant(e.response.status)
    })
  }

  public async getPrivacy() {
    const request = new Request()
    request.path = `/api/ajustes/confidencialidad`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorApplicant(error)
      })
  }

  public async setPrivacy(value: PrivacyType) {
    const request = new Request()
    request.path = `/api/ajustes/confidencialidad`
    request.method = Put
    request.body = value
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }
}

const account = new Account()
export default account
