import { useState, useEffect } from 'react'

export const useSearchKeyword = () => {
  const key = 'search-keyword'
  const [searchKeyword, setSearchKeyword] = useState('')

  const storeSearchKeyword = keyword => {
    if (keyword) {
      // seteamos la keyword para usar en el h1 de SeoWordings y en el input de busqueda
      sessionStorage.setItem(key, keyword)
    }
  }

  useEffect(() => {
    const value = sessionStorage.getItem(key)
    if (value) {
      setSearchKeyword(value)
    }
  }, [sessionStorage.getItem(key)])

  return { searchKeyword, storeSearchKeyword }
}
