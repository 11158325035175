const marketplace = {
  enabled: false,
  banners: {
    enabled: true,
  },
  courses: {
    enabled: true,
  },
}

export default marketplace
