import styled from 'styled-components'

export const ForeignObject = styled.foreignObject`
  width: 54px;
  height: 34px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: 82px;
    height: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    width: 73px;
    height: 34px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    width: 82px;
    height: 24px;
  }
`

export const Legend = styled.span`
  text-align: center;
  font-size: 9px !important;
  line-height: 12px !important;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 12px !important;
    line-height: 16px !important;
  }
`
