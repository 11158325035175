const suggestionsHome = {
  userLogged: {
    title: 'Mejora tu perfil profesional',
    subTitle: 'Te dejamos algunas recomendaciones para que te destaques a la hora de postularte:',
    description1:
      'Completa tu perfil: tómate el tiempo necesario para completar cada campo de manera clara y brindar información de valor sobre tu perfil.',
    description2:
      'Mantén tu información actualizada: si cambiaste de empleo o tus datos de contacto, ¡regístralo! Para los empleadores es esencial que tu perfil esté al día.',
    description3:
      'Suma tus conocimientos y habilidades: además de tus experiencias y estudios, agrega tanto tus conocimientos técnicos como tus cualidades relacionadas al desempeño de tu trabajo.',
    description4:
      'Realiza el test de personalidad: a través de él podrás identificar de forma gratuita tus talentos y virtudes, y mostrárselos a las empresas.',
    cta: 'ir a mi perfil',
  },
  userNotLogged: {
    title: 'Créate una cuenta y encuentra el trabajo que buscas',
    description1: 'Ingresa en la opción Crear cuenta, escribe tus datos y confírmalos.',
    description2:
      'Completa la información principal de tu perfil a través de las preguntas por pasos que te haremos Inmediatamente después de que te registres.',
    description3: 'Postúlate a los trabajos que más te interesen y sigue el proceso de tus postulaciones.',
    description4: 'Recuerda mantener tu información actualizada desde la sección de tu perfil.',
    cta: 'Crear cuenta',
  },
}

export default suggestionsHome
