const saveCompany = {
  empty: {
    title: 'Todavía no guardaste ninguna empresa',
    subtitle: '¡Haz una búsqueda y guarda las empresas que más te interesen!',
    buttonText: 'Buscar empresas',
  },
  cards: {
    info: {
      text: 'Puedes <0>seguir a las empresas y recibir notificaciones o mails</0> con actualizaciones de empleos, sueldos y evaluaciones de las empresas ',
      mail: '<0>Por email:</0> para recibir por correo electrónico.',
      popup: '<0>Emergentes:</0> aparecen de forma automática en tu celular o navegador.',
    },
    alerts: {
      text: 'Para modificar todas las notificaciones puedes ir a <0>Ajustes de notificaciones y alertas</0>',
    },
  },
  button: {
    seguir: 'Seguir',
    siguiendo: 'Siguiendo',
  },
  snackbar: {
    seguir: 'Sigues esta empresa',
    dejarDeSeguir: 'Dejaste de seguir esta empresa',
  },
}

export default saveCompany
