import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ElementsContainer = styled.div<{ theme }>`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 0 16px;
  margin: 64px auto 152px;
  justify-content: center;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    margin-bottom: 258px;
    padding: 0 16px;
    flex-direction: row;
    text-align: left;
  }
`

export const ImageForEmptyState = styled.img<{ theme }>`
  width: 100%;
  max-width: 288px;
  margin: 0 auto 40px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    max-width: 320px;
    margin: 0px 24px 0px 0px;
  }

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    max-width: 320px;
  }
`

export const TextContainer = styled.div`
  @media (min-width: ${props => props.theme.screen.lgMin}) {
    max-width: 400px;
  }
`

export const Subtitle = styled.p<{ theme }>`
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 24, 0.84);
  margin: 24px 0px 0px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
`
export const ComponentContainer = styled('div')`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${props => props.theme.screen.lgMin}) {
    display: flex;
    align-items: left;
    justify-content: left;
  }
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 194px;
`
