export const perfil = {
  actualizarCv: {
    cvActualizado: 'Mi {{cv}} está actualizada',
    ultimaActualizacion: 'Última modificación de la {{cv}}:',
    modal: {
      subTitle: 'Al mantener tu {{cv}} actualizada tendrás más visibilidad frente a las empresas.',
      editarButton: 'Quiero editarla',
      actualizadoButton: 'Está actualizada',
      snackSuccess: 'Tu {{cv}} está actualizada.',
    },
  },
  misPostulaciones: {
    labelLeidos: 'Hv leída',
  },
  disponibilidad: {
    suggestion: {
      text2: 'Que tu {{cv}} sea pública',
    },
  },
}
