import { StatusType, status } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const CHANGE_PASSWORD_SEND_EMAIL = 'CHANGE_PASSWORD_SEND_EMAIL'
export const CHANGE_PASSWORD_SEND_EMAIL_SUCCESS = 'CHANGE_PASSWORD_SEND_EMAIL_SUCCESS'
export const CHANGE_PASSWORD_SEND_EMAIL_ERROR = 'CHANGE_PASSWORD_SEND_EMAIL_ERROR'
export const CHANGE_PASSWORD_SEND_EMAIL_RESET = 'CHANGE_PASSWORD_SEND_EMAIL_RESET'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */
export interface ChangePasswordSendEmailRequestDto {
  applicantMail: string
  fetchMail: string
}

export interface ChangePasswordSendEmailSuccessDto {
  email: string | null
  invalidEmail: boolean
}

export interface ChangePasswordRequestDto {
  token: string
  passwordNueva: string
}

export interface ChangePasswordSuccessDto {
  tokenVencido: boolean
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface ChangePasswordState {
  sendMail: {
    value: ChangePasswordSendEmailSuccessDto | null
    status: StatusType
  }
  changePassword: {
    value: ChangePasswordSuccessDto | null
    status: StatusType
  }
}

export const initialState: ChangePasswordState = {
  sendMail: {
    value: null,
    status: status.initial,
  },
  changePassword: {
    value: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface ChangePasswordSendEmail {
  type: typeof CHANGE_PASSWORD_SEND_EMAIL
  payload: ChangePasswordSendEmailRequestDto
}

export interface ChangePasswordSendEmailSuccess {
  type: typeof CHANGE_PASSWORD_SEND_EMAIL_SUCCESS
  payload: ChangePasswordSendEmailSuccessDto
}

export interface ChangePasswordSendEmailError {
  type: typeof CHANGE_PASSWORD_SEND_EMAIL_ERROR
  payload: null
}

export interface ChangePasswordSendEmailReset {
  type: typeof CHANGE_PASSWORD_SEND_EMAIL_RESET
  payload: null
}

export interface ChangePassword {
  type: typeof CHANGE_PASSWORD
  payload: ChangePasswordRequestDto
}

export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS
  payload: ChangePasswordSuccessDto
}

export interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR
  payload: null
}

export type ChangePasswordActions =
  | ChangePasswordSendEmail
  | ChangePasswordSendEmailSuccess
  | ChangePasswordSendEmailError
  | ChangePasswordSendEmailReset
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordError
