import Script from './index'

export const intercomSettings = data => {
  return `window.intercomSettings = {${data}};`
}

const intercomCode = () => {
  return `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/yb7swf3c';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
}

const intercom = (): typeof Script[] => [
  {
    attributes: [{ name: 'async', value: 'true' }],
    name: 'intercom-script-head',
    parent: 'body',
    location: 'end',
    type: 'script',
    code: intercomCode(),
    lazyLoad: true,
  },
]

export default intercom
