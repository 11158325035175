import { Request, Get, Put, Delete } from '@navent-jobs/config'

export class ErrorApplicant extends Error {}

class Notifications {
  public async getNotifications() {
    const request = new Request()
    request.path = `/api/ajustes/suscripciones`
    request.method = Get
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }

  public async setNotifications(value: number, update: boolean) {
    const request = new Request()
    request.path = `/api/ajustes/suscripciones/${value}`
    request.method = update ? Put : Delete
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }
}

const notification = new Notifications()
export default notification
