const storeLinks = {
  ar: {
    bumeran: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.bumeran',
      ios: 'https://apps.apple.com/ar/app/bumeran/id1294413939',
    },
    zonajobs: {},
  },
  cl: {
    laborum: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.laborum',
      ios: 'https://apps.apple.com/cl/app/id1296298888',
    },
  },
  ec: {
    multitrabajos: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.multitrabajo',
      ios: 'https://apps.apple.com/ec/app/id836157960',
    },
  },
  pa: {
    konzerta: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.konzerta',
      ios: ' https://apps.apple.com/pa/app/konzerta-com-empleos/id836108380',
    },
  },
  mx: {
    bumeran: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.bumeran',
      ios: 'https://apps.apple.com/ar/app/bumeran/id1294413939',
    },
  },
  pe: {
    bumeran: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.bumeran',
      ios: 'https://apps.apple.com/ar/app/bumeran/id1294413939',
    },
  },
  ve: {
    bumeran: {
      android: 'https://play.google.com/store/apps/details?id=com.bumex.android.bumeran',
      ios: 'https://apps.apple.com/ar/app/bumeran/id1294413939',
    },
  },
}

export default storeLinks
