import React, { useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'

// compontens
import SidebarMenu from '../components/SidebarMenu'

// mixins
import { FieldIcon, ToogleSidebarMenu } from './mixins'

const Component = ({ placeholder = '' }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const { colors } = useContext<{ colors }>(ThemeContext)

  return (
    <>
      <ToogleSidebarMenu onClick={() => setMenuOpened(true)}>
        <FieldIcon size="24" color={colors.secondary.normal} name="icon-light-search" />
        {placeholder || '¿Qué trabajo estás buscando?'}
      </ToogleSidebarMenu>

      <SidebarMenu menuOpened={menuOpened} setMenuOpened={setMenuOpened} />
    </>
  )
}

export default Component
