import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'

import { Heading, CustomText } from './mixins'

const Component = ({ applicantEmail }) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading>
        <Text type="title" size="sm" fontWeight="semibold" variant="secondary" as="h2">
          {t('Te enviamos un email a ')}
        </Text>
      </Heading>

      <CustomText>{applicantEmail}</CustomText>
    </>
  )
}

export default Component
