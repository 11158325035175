import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const ComponentContainer = styled('div')`
  margin-bottom: 40px;
`

export const CustomLink = styled(Link)`
  line-height: 18px;
  padding: 0;
`
