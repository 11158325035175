import styled from 'styled-components'

export const CustomText = styled('p')`
  margin: 0;
`

export const Container = styled('div')`
  padding-left: 0px;

  @media (min-width: ${({ theme }) => theme.screen.xsMin}) {
    padding-left: 10px;
  }
`
