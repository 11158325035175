import styled from 'styled-components'
import Card from '../../../../../../components/card'

export const AvisoCard = styled(Card)`
  margin-bottom: 12px;
`

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
`
