import styled, { createGlobalStyle } from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Background = createGlobalStyle`

  ${({ neutro, theme }) =>
    neutro
      ? `
  body {
    background-color:  ${theme.colors.grey0};
    padding-top: 0px;
  }
  `
      : ''}
`

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
export const LogoDeveloppedBy = styled.img``

export const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`
export const MainWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.screen.mdMax}) {
    max-width: auto;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 424px;
  }
`

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`

export const FormGroup = styled.div`
  margin-bottom: 12px;
`

export const SubmitButton = styled(Button)`
  max-width: 194px;
  margin: 0 auto;
`

export const MessageError = styled.span`
  color: red;
  font-size: 12px;
`

export const LinkEmpresasWrapper = styled.div`
  margin: 20px auto 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
