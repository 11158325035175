import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const CustomForm = styled.div`
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-color: white;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px;
  }
`

export const GeneralReview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 12px;
  }
`

export const FormRow = styled.div`
  margin-bottom: 16px;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
`

export const ConditionsLabel = styled.span`
  position: relative;
`

export const FullContainer = styled.div`
  width: 100%;
`

export const ExampleContainer = styled.li`
  display: flex;
  align-items: center;
  align-content: flex-start;
  padding: 0px;
  text-decoration: none;
  word-break: break-word;
  margin-top: 4px;
`

export const CaretIcon = styled(Icon)`
  display: flex;
  align-self: flex-start;
  margin: 0px 4px 0px 0px;
`

export const ExampleTitle = styled.div`
  margin-bottom: 10px;
`

export const SuggestionText = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 12px;
  }
`
