/* eslint-disable max-len */
// PERU
export const seoWordings = {
  // 36
  root: {
    title: `Empleos en {{countryName}} | Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo en {{countryName}}, incluyendo empleos en {{filtersToApplyByKey.provincia.facets.0.name}} y en {{filtersToApplyByKey.provincia.facets.1.name}}. ¡Postúlate en {{siteName}} {{countryName}}!`,
    h1: `ofertas de empleo en {{countryName}}`,
  },
  // 2
  home: {
    title: `Trabajos - Empleos | {{siteName}} {{countryName}}`,
    description: `La bolsa de trabajo más grande de {{countryName}}. Busca empleos y ofertas de trabajo ideales para ti. ¡Encuentra tu próximo trabajo en {{siteName}}!`,
    h1: `Trabajos en {{countryName}}`,
  },
  // 3
  area: {
    title: `Trabajos de {{filtersByKey.area.name}} - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperándote en {{siteName}} {{countryName}}. Tambien tenemos ofertas de trabajo en {{filtersToApplyByKey.subarea.facets.0.name}}. ¡Encuentra tu empleo!`,
    h1: `empleos en {{filtersByKey.area.name}}`,
  },
  // 4
  subarea: {
    title: `Ofertas de trabajo en {{filtersByKey.subarea.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `¿Buscas trabajos en {{filtersByKey.subarea.name}}? En {{siteName}} contamos con más de {{totalSearched}} vacantes de trabajo. ¡Tenemos el empleo que estás buscando!`,
    h1: `empleos en {{filtersByKey.subarea.name}}`,
  },
  // 5
  nivel_laboral: {
    title: `Trabajos - {{filtersByKey.nivel_laboral.name}} - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Estás buscando empleo de {{filtersByKey.nivel_laboral.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} encontrados en {{countryName}}`,
  },
  // 6
  tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.tipo_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}}`,
  },
  // 7
  dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} - Empleos - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{siteName}}. Consigue empleo de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.nivel_laboral.facets.0.name}} en la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}}`,
  },
  // 8
  apto_discapacitado: {
    title: `Empleos para postulantes con discapacidad - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Tenemos {{totalSearched}} trabajos para Postulantes con Discapacidad. ¡Encuentra tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para Postulantes con Discapacidad`,
  },
  // 9
  query: {
    title: `{{filtersByKey.query.name}}: Empleos - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Tenemos {{totalSearched}} trabajos para la búsqueda: "{{filtersByKey.query.name}}" en {{siteName}} {{countryName}}. Revisa todas las ofertas y ¡Encuentra tu próximo empleo!`,
    h1: `empleos para la búsqueda "{{filtersByKey.query.name}}"`,
  },
  // 10
  ficha_aviso: {
    title: `{{title}} - {{mes}} {{anio}} | {{siteName}} {{countryName}}`,
    description: `Postúlate en la oferta de {título} en {empresa} y consigue tu trabajo ideal. Conoce el sueldo promedio de este puesto y postúlate en {{siteName}} {{countryName}}.`,
    h1: `{{title}}`,
  },
  // 12
  provincia: {
    title: `Trabajo en {{filtersByKey.provincia.name}}, {{countryName}} - Empleo - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo en {{filtersByKey.provincia.name}}, incluyendo empleos en {{filtersToApplyByKey.localidad.facets.0.name}} y en {{filtersToApplyByKey.localidad.facets.1.name}}. ¡Postúlate en {{siteName}} {{countryName}}!`,
    h1: `empleos encontrados en {{filtersByKey.provincia.name}}`,
  },
  // 13
  localidad: {
    title: `Vacantes de trabajo en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `¿Buscas trabajo en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}? Tenemos {{totalSearched}} ofertas de empleo en {{siteName}} para ti, incluyendo ofertas en {{filtersToApplyByKey.area.facets.0.name}}.`,
    h1: `empleos en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 14
  provincia_area: {
    title: `Trabajos de {{filtersByKey.area.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperándote en {{filtersByKey.provincia.name}}.  Hay ofertas en {{filtersToApplyByKey.subarea.facets.0.name}} y en {{filtersToApplyByKey.subarea.facets.1.name}}. ¡Encuentra tu empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos en {{filtersByKey.area.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 15
  provincia_subarea: {
    title: `Ofertas de trabajo en {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscas trabajos de {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}}? En {{siteName}} contamos con más de {{totalSearched}} vacantes de trabajo que estás buscando. ¡Postúlate Ya!`,
    h1: `empleos en {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 16
  provincia_nivel_laboral: {
    title: `Trabajos - {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Buscas empleo de {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.provincia.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} encontrados en {{filtersByKey.provincia.name}}`,
  },
  // 17
  provincia_dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} - {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.provincia.name}}. Consigue empleo en {{filtersToApplyByKey.area.facets.0.name}} y en {{filtersToApplyByKey.area.facets.1.name}} en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 18
  provincia_tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo de {{filtersByKey.tipo_trabajo.name}} están esperándote en {{filtersByKey.provincia.name}}. ¡Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 19
  provincia_apto_discapacitado: {
    title: `Empleos para postulantes con discapacidad en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} trabajos para Postulantes con Discapacidad en {{filtersByKey.provincia.name}}. También contamos con trabajos de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.tipo_trabajo.facets.1.name}}. ¡Encuentra tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para Postulantes con Discapacidad en {{filtersByKey.provincia.name}}`,
  },
  // 20
  provincia_query: {
    title: `{{filtersByKey.query.name}}: Empleos en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} trabajos para la búsqueda: "{{filtersByKey.query.name}}" en {{filtersByKey.provincia.name}}. ¡Encuentra tu próximo empleo en {{siteName}}!`,
    h1: `empleos para la búsqueda "{{filtersByKey.query.name}}" en {{filtersByKey.provincia.name}}`,
  },
  // 21
  localidad_area: {
    title: `Trabajos de {{filtersByKey.area.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperandote en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}.  Hay ofertas en {{filtersToApplyByKey.subarea.facets.0.name}} y en {{filtersToApplyByKey.subarea.facets.1.name}}. ¡Encuentra tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos en {{filtersByKey.area.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 22
  localidad_subarea: {
    title: `Ofertas de trabajo en {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscas trabajos en {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}? En {{siteName}} contamos con más de {{totalSearched}} vacantes de trabajo para lo que buscas. ¿A qué esperas para entrar y postularte? ¡Empieza a trabajar ya! `,
    h1: `empleos en {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 23
  localidad_nivel_laboral: {
    title: `Trabajos - {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Estás buscando empleo de {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} encontrados en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 24
  localidad_dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} - {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. Consigue empleo en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 25
  localidad_tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.tipo_trabajo.name}} te esperan en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. ¡Postúlate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 26
  localidad_apto_discapacitado: {
    title: `Empleos para postulantes con discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} trabajos para Postulantes con Discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. También contamos con trabajos de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.tipo_trabajo.facets.1.name}}. ¡Encuentra tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para Postulantes con Discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 27
  localidad_query: {
    title: `{{filtersByKey.query.name}}: Empleos en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} trabajos para la búsqueda: "{{filtersByKey.query.name}}" en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. Revisa en todas las ofertas que tenemos y...¡Encuentra tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para la búsqueda "{{filtersByKey.query.name}}" en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 28
  modalidad_trabajo: {
    title: `Empleos: {{filtersByKey.modalidad_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.modalidad_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.modalidad_trabajo.name}}`,
  },
  // 29
  internacional: {
    title: `Empleos: {{filtersByKey.modalidad_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.modalidad_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos esparcidos por el mundo`,
  },

  default: {
    title: `Empleos: {{variablesOrdenadasPorPrioridad}} - Página {{pageNumber}} | {{siteName}}`,
    description:
      '¿Buscas trabajo? Tenemos más de {{totalSearched}} ofertas de trabajo para ti en {{siteName}}. ¡Encuentra los mejores empleos ya!',
    h1: 'empleos para {{variablesOrdenadasPorPrioridad}}',
  },
}
