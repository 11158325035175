import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Page = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey0};
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const FormGroup = styled('div')`
  margin-bottom: 24px;

  &:nth-child(2) {
    margin-bottom: 48px;
  }
`

export const SubmitButton = styled(Button)`
  max-width: 194px;
  margin: 24px auto 0;
`

export const MessageError = styled('p')`
  color: red;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
`
