import { Children, isValidElement, cloneElement, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import services from '../../services'
import { getApplicantSummary } from '../../redux/actions/applicant/applicant'

const Component = props => {
  const dispatch = useDispatch()
  const parsedUrl = new URL(window.location.href)
  const rTokenAutologin = parsedUrl.searchParams.get('rtoken_auto')
  const tokenAutologin = parsedUrl.searchParams.get('token_auto')
  const [autologinflag, setAutologinflag] = useState(false)

  // token_auto: Tiene acceso total
  // rtoken_auto: Tiene acceso parcial (restricted)
  // Caso 1) (Si viene con rtoken_auto && ya hay un usuario logueado) => no se hace nada
  // Caso 2) (Si viene con rtoken_auto && no hay un usuario logueado) => se lo loguea como restricted
  // Caso 3) (Si viene con token_auto && ya hay un usuario logueado) => lo desloguea y lo loguea con el nuevo (no importa si es el mismo usuario)
  // Caso 4) (Si viene con token_auto && no hay un usuario logueado) => lo loguea
  useEffect(() => {
    if (rTokenAutologin) {
      const isLogged = services.auth.loginCookieExist()
      if (!isLogged) {
        services.auth.autologin(rTokenAutologin, true).then(result => setAutologinflag(result))
      }
    } else if (tokenAutologin) {
      services.auth.autologin(tokenAutologin, false).then(result => setAutologinflag(result))
    }
  }, [rTokenAutologin, tokenAutologin])

  useEffect(() => {
    if (autologinflag) {
      dispatch(getApplicantSummary())
      /**
       * El atulogin suele poseer un token restringido es decir que el usuario solo podra loguearse y postularse,
       * no podra ver su CV y por ello el action getApplicant es comentado.
       * El action getApplicant busca informacion del cv del usuario.
       * En el caso hipotetico de necesitar un autologin publico esto debera reverse.
       * dispatch(getApplicant())
       */
    }
  }, [autologinflag])

  return autologinflag
    ? Children.map(props.children, child => {
        if (isValidElement(child)) {
          return cloneElement<any>(child, { autologin: true })
        }
        return child
      })
    : props.children
}

export default Component
