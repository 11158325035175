import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Link } from '@navent-jobs/ui-kit/components/link'

const getMobileOptIcon = (theme, noBold) => `
  i:before {
    background-color: ${noBold ? theme.colors.grey24 : theme.colors.grey48} !important;
  }

  i:hover {
    color: ${noBold ? theme.colors.grey24 : theme.colors.grey48} !important;
  }
`

export const OptionContainer = styled(Link)`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: ${({ isMobile }) => (isMobile ? 0 : '8px 0px')};
  ${({ theme, noBold, isMobile }) => isMobile && getMobileOptIcon(theme, noBold)}
`

export const OptionTitle = styled.div`
  display: flex;
`

export const OptionIcon = styled(Icon)`
  margin-right: 8px;
`

export const OptionLabel = styled.span`
  font-weight: 400;
  ${({ theme, isMobile }) => isMobile && `color: ${theme.colors.grey48}`};
`

export const OptionKeyword = styled('span')`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`

export const selectStyles = theme => {
  return `
    .basic-single {
      position: static!important;
      
      .select__control{
        .select__value-container{
          padding: 0;
          line-height: 32px;
          font-size: 16px;
        }
      }

      .select__menu{
        left: 0px;
        border: 1px solid ${theme.colors.grey24};
        top: calc( 100% + 4px );
      }

      .select__indicators {
        i:before {
          background-color: ${theme.colors.secondary.normal};
        }
      }
    }

    #autocomplete-search-companies > .select__menu {
      .select__menu-list {
        .select__option {
          color: ${theme.colors.grey60} !important;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;          
        }
      }
    }

    #autocomplete-search-companies > .select__control {
      .select__value-container {
        .select__input {
          input {
            font-family: ${theme.fonts.families.base};
            color: ${theme.colors.secondary.normal} !important;
            font-weight: 600;
          }
        }
      }
    }
  `
}
