import {
  RESET_ACTIVE_AVISO,
  SET_ACTIVE_AVISO,
  SET_ACTIVE_AVISO_SUCCESS,
  SET_ACTIVE_AVISO_ERROR,
  ActiveAvisoState,
  ActiveAvisoActions,
  initialState,
  SET_BUSQUEDAS_RELACIONADAS,
  SET_BUSQUEDAS_RELACIONADAS_SUCCESS,
  SET_BUSQUEDAS_RELACIONADAS_ERROR,
} from '../types/active-aviso'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: ActiveAvisoActions): ActiveAvisoState => {
  switch (action.type) {
    case RESET_ACTIVE_AVISO:
      return {
        ...state,
        activeAvisoId: null,
        aviso: null,
        avisosSugeridos: null
      }
    case SET_ACTIVE_AVISO:
      return {
        ...state,
        activeAvisoId: action.payload.id,
        aviso: null,
        avisosSugeridos: null,
        activeAvisoStates: {
          loading: true,
          success: false,
          error: false
        }
      }
    case SET_ACTIVE_AVISO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        activeAvisoStates: {
          loading: false,
          success: true,
          error: false
        }
      }
    case SET_ACTIVE_AVISO_ERROR:
      return {
        ...state,
        aviso: null,
        avisosSugeridos: null,
        activeAvisoStates: {
          loading: false,
          success: false,
          error: action.payload,
        }
      }
    case SET_BUSQUEDAS_RELACIONADAS:
      return {
        ...state,
        busquedasRelacionadas: null,
        busquedasRelacionadasStates: {
          loading: true,
          success: false,
          error: false
        }
      }
    case SET_BUSQUEDAS_RELACIONADAS_SUCCESS:
      return {
        ...state,
        busquedasRelacionadas: action.payload,
        busquedasRelacionadasStates: {
          loading: false,
          success: true,
          error: false
        }
      }
    case SET_BUSQUEDAS_RELACIONADAS_ERROR:
      return {
        ...state,
        busquedasRelacionadas: null,
        busquedasRelacionadasStates: {
          loading: false,
          success: false,
          error: true
        }
      }
    default:
      return state
  }
}

export default reducers
