import styled from 'styled-components'

export const Spacer = styled.div`
  padding: 40px 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 120px 0;
  }
`

export const Card = styled.div`
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.colors.grey16};
  background: ${({ theme }) => theme.colors.grey0};
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 0;
  }
`

export const InfoContainer = styled.div`
  padding: 40px 10px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 40px 40px 20px;
  }
`

export const CustomImage = styled.div`
  width: 100%;
  height: 141px;
  object-fit: ${({ empty }) => (empty ? 'cover' : 'initial')};
  background-image: url(${({ src }) => src});
  background-size: cover;
  border-radius: 18px 18px 0 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: 232px;
  }
`

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 64px;
  }
`

export const SubtitleContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 8px;
  }
`
