import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

// style
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Row, Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import {
  Title,
  Img,
  Subtitle,
  Description,
  Page,
  ColContainer,
  ConditionsWrapper,
  DescriptionWrapper,
  DescriptionSubtitle,
} from './mixins'

import regularMode from '../../../../assets/images/product-landings/nulingacourse/regularMode.svg'
import intensiveMode from '../../../../assets/images/product-landings/nulingacourse/intensiveMode.svg'

const Component = () => {
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)

  return (
    <Page>
      <MainContainer noGutter>
        <Col noGutter lg={10} offset={{ lg: 1 }}>
          <Row>
            <Col>
              <Title>{t('productLanding.nulingaCourse.time.title')}</Title>
            </Col>
          </Row>
          <Row key="row">
            <Col lg={6}>
              <ColContainer>
                <Subtitle>
                  Modo <strong>Regular</strong>
                </Subtitle>
                <Img src={regularMode} />
              </ColContainer>
            </Col>
            <Col lg={6}>
              <ColContainer>
                <Subtitle>
                  Modo <strong>Intensivo</strong>
                </Subtitle>
                <Img src={intensiveMode} />
              </ColContainer>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ConditionsWrapper>
                <DescriptionSubtitle>{t('productLanding.nulingaCourse.time.subtitle')}</DescriptionSubtitle>
                <DescriptionWrapper>
                  <Icon name="icon-light-checkmark" color={colors.secondary.normal} size="24" />
                  <Description>{t('productLanding.nulingaCourse.time.infoText1')}</Description>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Icon name="icon-light-checkmark" color={colors.secondary.normal} size="24" />
                  <Description>{t('productLanding.nulingaCourse.time.infoText2')}</Description>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Icon name="icon-light-checkmark" color={colors.secondary.normal} size="24" />
                  <Description>{t('productLanding.nulingaCourse.time.infoText3')}</Description>
                </DescriptionWrapper>
              </ConditionsWrapper>
            </Col>
          </Row>
        </Col>
      </MainContainer>
    </Page>
  )
}

export default Component
