import React from 'react'
import { useTranslation } from 'react-i18next'

// ui-kit
import Image from '@navent-jobs/ui-kit/components/Image'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'

// mixins
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import {
  Card,
  CardLogo,
  CardContent,
  CardFooter,
  Price,
  CustomLink,
  CustomTitle,
  Body,
  ProductAcquired,
  Tag,
  CardOverlay,
  CustomHyperlink,
} from './mixins'

// constants
import { SITE_ID } from '../../../../constants'
import features from '../../../../constants/features-per-country'
import { DataLayers } from '../../../../constants/gtm-events-marketplace'

const Component = ({
  logo,
  title,
  body,
  price,
  href,
  alt,
  hrefText,
  name,
  acquired,
  acquiredText,
  acquiredHref,
  alternativeAcquired,
  tag,
  isLoading = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation()

  const moneySign = t(`currencyMask.symbol`) || '$'
  const showPrice = features[SITE_ID || 'BMAR'].marketplace?.showPrice
  const showTag = acquired && tag

  const CardWrapper = ({ children }) => {
    return acquired ? (
      <Card>
        <CustomHyperlink href={acquiredHref} target="_self">
          {children}
        </CustomHyperlink>
      </Card>
    ) : (
      <Card>
        <div onClick={() => onClick()}>
          <CustomHyperlink onClick={() => DataLayers.clickCard(name)} href={href} target="_self">
            {children}
          </CustomHyperlink>
        </div>
      </Card>
    )
  }

  return (
    <CardWrapper>
      {showTag && <Tag>{tag}</Tag>}
      <CardLogo>
        <Image src={logo} width="auto" height="auto" alt={alt} />
      </CardLogo>

      <CardContent>
        <CustomTitle>
          <Text size="md" fontWeight="semibold" variant="secondary" as="h4">
            {title}
          </Text>
        </CustomTitle>
        <Body>{body}</Body>
      </CardContent>

      <CardFooter showPrice={showPrice} acquired={acquired}>
        {acquired ? (
          <>
            <ProductAcquired>{alternativeAcquired || 'Ya adquiriste este producto'}</ProductAcquired>
            <CustomLink linkoptions={{ variant: 'primary', type: 'link', size: 'small' }}>
              {acquiredText}
              <Icon name="icon-light-caret-right" size="16" />
            </CustomLink>
          </>
        ) : (
          <>
            {showPrice && (
              <Price>
                {price && moneySign}
                {price}
              </Price>
            )}
            <CustomLink linkoptions={{ variant: 'primary', type: 'link', size: 'small' }}>
              {hrefText}
              <Icon name="icon-light-caret-right" size="16" />
            </CustomLink>
          </>
        )}
      </CardFooter>
      {isLoading && (
        <CardOverlay>
          <Loader />
        </CardOverlay>
      )}
    </CardWrapper>
  )
}

export default Component
