export const GET_PAYMENT_INVENTORY = 'GET_PAYMENT_INVENTORY'
export const GET_PAYMENT_INVENTORY_SUCCESS = 'GET_PAYMENT_INVENTORY_SUCCESS'
export const GET_PAYMENT_INVENTORY_ERROR = 'GET_PAYMENT_INVENTORY_ERROR'

export interface GetPaymentInventoryDto {
  nonStockableItems: {
    idCountry: string
    dueDate: string
    sku: string
  }[]
  stockableItems: {
    dueDate: string
    idCountry: string
    quantity: number | null
    sku: string
  }[]
}

export interface GetPaymentInventory {
  type: typeof GET_PAYMENT_INVENTORY
  payload: null
}
export interface GetPaymentInventorySuccess {
  type: typeof GET_PAYMENT_INVENTORY_SUCCESS
  payload: GetPaymentInventoryDto
}
export interface GetPaymentInventoryError {
  type: typeof GET_PAYMENT_INVENTORY_ERROR
  payload: null
}

export interface PaymentInventoryState {
  nonStockableItems: {
    idCountry: string
    dueDate: string
    sku: string
  }[]
  stockableItems: {
    dueDate: string
    idCountry: string
    quantity: number | null
    sku: string
  }[]
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: PaymentInventoryState = {
  nonStockableItems: [
    {
      idCountry: '',
      dueDate: '',
      sku: '',
    },
  ],
  stockableItems: [
    {
      dueDate: '',
      idCountry: '',
      quantity: null,
      sku: '',
    },
  ],
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type PaymentInventoryActions = GetPaymentInventory | GetPaymentInventorySuccess | GetPaymentInventoryError
