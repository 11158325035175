import { TagManager } from '@navent-jobs/config'

export const DataLayer = {
  handlerBannerPdaTraking: (bannerSection, portalLocation?) => {
    /**
     * Debe ejecutarse cuando el usuario visualiza/interactua cada una de las secciones de BannerPda.
     */

    const BannerSections = {
      modalDni: {
        label: 'Impresion popup Carga tu DNI para continuar',
      },
      pagoExitoso: {
        label: 'Pago exitoso',
      },
      pagoError: {
        label: 'Pago erroneo',
      },
      obtenerInformeCompleto: {
        label: 'CTA Obtener resultado completo',
      },
      descargarInformeCompleto: {
        label: 'CTA Descargar resultado completo',
      },
      conocerMas: {
        label: 'CTA Conoce mas',
      },
    }

    const portalLocations = {
      homeCurriculum: ' - Mi CV Banner lateral',
      inicioPda: ' - Test personalidad gratuito completado',
      resultadoPda: ' - Test personalidad gratuito completado',
      modalPda: ' - Modal leer Test personalidad completo',
    }

    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test Personalidad',
      eventAction: 'Test Pago',
      eventLabel: `${BannerSections[bannerSection].label}${portalLocation ? portalLocations[portalLocation] : ''}`,
    })
  },
}
