/* eslint-disable prettier/prettier */
import { Request, Get, Post } from '@navent-jobs/config'
import { isEmpty } from 'lodash'
import features from '../constants/features-per-country'
import { SITE_ID } from '../constants'

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled

export class ErrorApplicant extends Error {}

interface Feature {
  title: string
  detail: string
  img: string
  buttonText: string
  actionButton: string
}

/**
 * Indica cual es el minimo de avisos que debe haber
 * en el Area para ser mostrado en el carousel
 */
const MIN_AVISOS = 30

interface LandingsProps {
  filterId: string
  facetId: string
  title: string
  quantity: number
}

class Home {
  public async GetJobPostingsInfo() {
    const request = new Request()
    request.path = `api/avisos/filters`
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Areas del carousel no encontradas`)
        }

        const areaFilter = response.filters?.find(filter => filter.type === 'area')

        const areaCarrousel = {
          countryAvisoCount: response.total,
          content: areaFilter.facets
            .filter(facet => facet.quantity > MIN_AVISOS)
            .map(facet => ({ areaId: facet.id, title: facet.name, quantity: facet.quantity })),
        }

        const landings: LandingsProps[] = []

        const nivelLaboralFilter = response.filters?.find(filter => filter.type === 'nivel_laboral')
        const nivelLaboralAltaGerencia = nivelLaboralFilter?.facets?.find(
          f => f.id === 'gerencia-alta-gerencia-direccion'
        )

        if (nivelLaboralAltaGerencia) {
          const altaGerencia = {
            filterId: nivelLaboralFilter.type,
            facetId: nivelLaboralAltaGerencia.id,
            title: nivelLaboralAltaGerencia.name,
            quantity: nivelLaboralAltaGerencia.quantity,
          }

          landings.push(altaGerencia)
        }

        const nivelLaboralJunior = nivelLaboralFilter?.facets?.find(f => f.id === 'junior')
        if (nivelLaboralJunior) {
          const jovenesProfesionales = {
            filterId: nivelLaboralFilter.type,
            facetId: nivelLaboralJunior.id,
            title: nivelLaboralJunior.name,
            quantity: nivelLaboralJunior.quantity,
          }

          landings.push(jovenesProfesionales)
        }

        return {
          areasCarousel: areaCarrousel,
          landingButtons: landings,
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Areas del carousel no encontradas`)
        }
      })
  }

  public async GetNewFeature() {
    const request = new Request()
    request.path = `api/new-feature`
    request.method = Get
    return request
      .call()
      .then((response: Feature[]) => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Nuevas caracteristicas no encontradas`)
        }

        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Nuevas caracteristicas no encontradas`)
        }
      })
  }

  public async getTotalAvisos() {
    const request = new Request()
    request.path = `api/avisos/total`
    request.method = Get
    return request
      .call()
      .then(response => response.total)
      .catch(() => {
        throw new ErrorApplicant(`Error al obtener el total de avisos`)
      })
  }

  public async getJobPostingsHome(filters) {
    const request = new Request()
    request.path = searchAvisosV2Enabled ? 'api/avisos/searchHomeV2' : 'api/avisos/searchHome'
    request.method = Post
    request.body = filters
    return request
      .call()
      .then(response => response)
      .catch(() => {
        throw new ErrorApplicant(`Error al obtener los avisos de la home`)
      })
  }
}

const home = new Home()
export default home
