import {
  EmpresasSeguidasState,
  GET_LISTADO_EMPRESAS_SEGUIDAS,
  GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR,
  GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS,
  initialState,
  ListadoEmpresasSeguidasActions,
} from '../types/save-company'
import { status } from '../types/status'

const reducers = (
  state: EmpresasSeguidasState = initialState,
  action: ListadoEmpresasSeguidasActions
): EmpresasSeguidasState => {
  switch (action.type) {
    case GET_LISTADO_EMPRESAS_SEGUIDAS:
      return {
        ...state,
        states: status.loading,
      }
    case GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS:
      return {
        ...state,
        empresasSeguidas: action.payload.empresasSeguidas,
        pagination: action.payload.pagination,
        states: status.success,
      }
    case GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR:
      return {
        ...state,
        states: status.error,
      }
    default:
      return state
  }
}

export default reducers
