import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const MainWrapper = styled.div`
  width: 100%;
  max-width: 435px;
  padding: 0px 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding: 0px;
  }
`

export const Title = styled.h3`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
`

export const ImgMiGuru = styled.img`
  width: 100%;
  max-width: 120px;
  margin-bottom: 20px;
  border-radius: 8px 8px 0px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 160px;
    margin-bottom: 24px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 420px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    justify-content: space-around;
    flex-direction: initial;
  }
`

export const CustomButton = styled(Button)`
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: 100%;
    white-space: nowrap;
    max-width: 188px;
    margin-bottom: 0px;
  }
`

export const Body = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 24px;

    &:last-child {
      display: inherit;
    }
  }
`
