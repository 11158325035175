import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { GET_CAREER_SITE, GET_CAREER_SITE_ERROR, GET_CAREER_SITE_SUCCESS } from '../types/career-site'

function* getCareerSite({ payload }) {
  try {
    const result = yield call(services.careerSite.getCareerSite, payload)
    yield put({ type: GET_CAREER_SITE_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CAREER_SITE_ERROR })
  }
}

/**
 * Watchers
 */
export default function* careerSite() {
  yield takeLatest<any>(GET_CAREER_SITE, getCareerSite)
}
