import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { SITE_ID } from '../../../../constants'
import features from '../../../../constants/features-per-country'
import { ApplicantState } from '../../../../redux/types/applicant/commons'
import service from '../../../../services/payment-mp-service'
import { Content, LinkCustom } from './mixins'
import { PaymentMPExternalId } from '../../../../constants/payment-mp-external-id'

const ButtonPremium = ({ applicant }) => {
  const isUserPremium = applicant && applicant.datosPersonales && applicant.datosPersonales.esPremium
  const userPremiumEnabled = features[SITE_ID || 'BMAR']?.userPremium?.enabled

  const [loading, setLoading] = useState(false)
  const [showButtonPremium, setShowButtonPremium] = useState(false)

  useEffect(() => {
    if (applicant) {
      setShowButtonPremium(userPremiumEnabled)
    }
  }, [applicant])

  const onClickLink = async () => {
    setLoading(true)
    await service.getMPLink({ productId: 57, externalId: PaymentMPExternalId.PREMIUM })
    setLoading(false)
  }

  if (!showButtonPremium || isUserPremium) {
    return null
  }

  return (
    <Content>
      <LinkCustom onClick={() => onClickLink()}>{loading ? <Loader /> : 'Premium'}</LinkCustom>
    </Content>
  )
}

const states = ({ applicantStore }: { applicantStore: ApplicantState }) => ({
  applicant: applicantStore.applicant,
})

export default connect(states)(ButtonPremium)
