import React from 'react'

// Tools
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Components
import { Col, Row } from '@navent-jobs/ui-kit/components/grid'

// Store
import { deleteAlertAction } from '../../../../redux/actions/alerts-v2'

// Styles
import { CustomerButton, CustomMainContainer } from './mixins'
import { hideModal } from '../../../../redux/actions/modal'
import { DeleteAlertType } from '../../../../redux/types/alert-v2'

interface ModalFooterRemoveProps {
  idAlert: DeleteAlertType
  loading: boolean
}

const ModalFooterRemove = ({ idAlert, loading }: ModalFooterRemoveProps) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()

  const handleRemove = () => dispatch(deleteAlertAction(idAlert))

  const handleCancel = () => dispatch(hideModal())

  const orderButtonsMobile = () => (
    <CustomMainContainer>
      <Row>
        <Col md={6}>
          <CustomerButton buttonoptions={{ variant: 'primary' }} onClick={handleRemove} disabled={false}>
            {t('alertas.modalEliminar.eliminar')}
          </CustomerButton>
        </Col>
        <Col md={6}>
          <CustomerButton buttonoptions={{ variant: 'primary', type: 'link' }} onClick={handleCancel} disabled={false}>
            {t('alertas.modalEliminar.cancelar')}
          </CustomerButton>
        </Col>
      </Row>
    </CustomMainContainer>
  )

  if (isMobile) return orderButtonsMobile()

  return (
    <CustomMainContainer>
      <Row>
        <Col>
          <CustomerButton buttonoptions={{ variant: 'primary', type: 'link' }} onClick={handleCancel} disabled={false}>
            {t('alertas.modalEliminar.cancelar')}
          </CustomerButton>
        </Col>
        <Col>
          <CustomerButton buttonoptions={{ variant: 'primary' }} onClick={handleRemove} disabled={loading}>
            {t('alertas.modalEliminar.eliminar')}
          </CustomerButton>
        </Col>
      </Row>
    </CustomMainContainer>
  )
}
const states = ({ removeAlertStore }) => ({
  loading: removeAlertStore.status.loading,
})

export default connect(states)(ModalFooterRemove)
