import { ObjetivoLaboralType } from '../commons'

export const UPDATE_OBJETIVO_LABORAL = 'UPDATE_OBJETIVO_LABORAL'
export const UPDATE_OBJETIVO_LABORAL_SUCCESS = 'UPDATE_OBJETIVO_LABORAL_SUCCESS'
export const UPDATE_OBJETIVO_LABORAL_ERROR = 'UPDATE_OBJETIVO_LABORAL_ERROR'
export const UPDATE_OBJETIVO_LABORAL_RESET = 'UPDATE_OBJETIVO_LABORAL_RESET'
export const DELETE_OBJETIVO_LABORAL = 'DELETE_OBJETIVO_LABORAL'
export const DELETE_OBJETIVO_LABORAL_SUCCESS = 'DELETE_OBJETIVO_LABORAL_SUCCESS'
export const DELETE_OBJETIVO_LABORAL_ERROR = 'DELETE_OBJETIVO_LABORAL_ERROR'
export const DELETE_OBJETIVO_LABORAL_RESET = 'DELETE_OBJETIVO_LABORAL_RESET'

// Update objetivo laboral
export interface UpdateObjetivoLaboral {
  type: typeof UPDATE_OBJETIVO_LABORAL
  payload: ObjetivoLaboralType
}
export interface UpdateObjetivoLaboralSuccess {
  type: typeof UPDATE_OBJETIVO_LABORAL_SUCCESS
  payload: null
}
export interface UpdateObjetivoLaboralError {
  type: typeof UPDATE_OBJETIVO_LABORAL_ERROR
  payload: null
}
export interface UpdateObjetivoLaboralReset {
  type: typeof UPDATE_OBJETIVO_LABORAL_RESET
  payload: null
}

// Delete objetivo laboral
export interface DeleteObjetivoLaboral {
  type: typeof DELETE_OBJETIVO_LABORAL
  payload: null
}
export interface DeleteObjetivoLaboralSuccess {
  type: typeof DELETE_OBJETIVO_LABORAL_SUCCESS
  payload: null
}
export interface DeleteObjetivoLaboralError {
  type: typeof DELETE_OBJETIVO_LABORAL_ERROR
  payload: null
}
export interface DeleteObjetivoLaboralReset {
  type: typeof DELETE_OBJETIVO_LABORAL_RESET
  payload: null
}
