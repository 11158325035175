import {
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_RESET,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_ERROR,
  initialState,
  EmailActions,
  EmailState,
} from '../types/email'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state = initialState, action: EmailActions): EmailState => {
  switch (action.type) {
    case VALIDATE_EMAIL:
      return {
        ...state,
        response: null,
        loading: true,
        success: false,
        error: false,
      }
    case VALIDATE_EMAIL_RESET:
      return {
        ...state,
        response: null,
        loading: false,
        success: false,
        error: false,
      }
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        response: action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case VALIDATE_EMAIL_ERROR:
      return {
        ...state,
        response: null,
        loading: false,
        success: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducers
