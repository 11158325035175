import React from 'react'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useTranslation } from 'react-i18next'
import SuccessImgSrc from '../../../../../assets/images/exito.svg'
import { Container, SidebarContentWrapper, SubTitle, SuccessImg, Title, CustomButton } from '../mixins'

interface ModalQrSuccessProps {
  nombreEmpresa?: string
  visible: boolean
  onClose: () => void
}

interface ContentProps {
  Empresa?: string
  imgSrc: string
  onClose: () => void
}

const Content = ({ Empresa, imgSrc, onClose }: ContentProps) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  return (
    <Container>
      <SuccessImg src={imgSrc} alt="Success qr image" />

      <Title>
        ¡{Empresa} {t('companySummary.qrModal.success.title')}!
      </Title>

      <SubTitle>{t('companySummary.qrModal.success.subTitle')}</SubTitle>

      <CustomButton
        onClick={onClose}
        buttonoptions={{
          variant: 'primary',
          size: 'medium',
          type: 'fill',
          block: isMobile,
        }}
      >
        {t('companySummary.qrModal.success.cta')}
      </CustomButton>
    </Container>
  )
}

const ModalQrSuccess = ({ nombreEmpresa, visible, onClose }: ModalQrSuccessProps) => {
  const { isMobile, isDesktop, isTabletLandscape, isTabletPortrait } = useWindowSize()
  return (
    <>
      {(isMobile || isTabletPortrait) && (
        <Sidebar position="left" closeType="close" visible={visible} handleClose={onClose}>
          <SidebarContentWrapper>
            <Content imgSrc={SuccessImgSrc} Empresa={nombreEmpresa} onClose={onClose} />
          </SidebarContentWrapper>
        </Sidebar>
      )}

      {(isDesktop || isTabletLandscape) && (
        <Modal width={400} visible={visible} onClose={onClose}>
          <Content imgSrc={SuccessImgSrc} Empresa={nombreEmpresa} onClose={onClose} />
        </Modal>
      )}
    </>
  )
}

export default ModalQrSuccess
