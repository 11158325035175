import { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { getCookieData, setCookieData } from '../../utils/cookies'
import { registrarActividadAction } from '../../redux/actions/actividad'
import { ActividadState } from '../../redux/types/actividad'
import { ApplicantState } from '../../redux/types/applicant/commons'

interface RegistroActividadProps {
  applicantId: number | null
  registrarActividad: ActividadState['registrarActividad']
}

const RegistroActividad = ({ applicantId, registrarActividad }: RegistroActividadProps) => {
  const dispatch = useDispatch()

  // Effect - Levantamos la cookie y llama al registro de ser necesario
  useEffect(() => {
    if (applicantId) {
      const idRegistrado = getCookieData('registro_actividad', document.cookie)
      if (!idRegistrado || idRegistrado !== applicantId.toString()) {
        dispatch(registrarActividadAction())
      }
    }
  }, [applicantId])

  // Effect - En caso de exito guardamos la cookie x 24hs
  useEffect(() => {
    if (applicantId && registrarActividad.status.success) {
      setCookieData('registro_actividad', applicantId.toString(), 86400)
    }
  }, [registrarActividad, applicantId])

  return null
}

const states = ({
  applicantStore,
  actividadStore,
}: {
  applicantStore: ApplicantState
  actividadStore: ActividadState
}) => ({
  applicantId: applicantStore.applicant?.id ?? null,
  registrarActividad: actividadStore.registrarActividad,
})

export default connect(states)(RegistroActividad)
