import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    padding-top: 70px;
  }
`

export const Page = styled.div`
  position: relative;
  z-index: 10;
`

export const PageContent = styled.div`
  height: calc(100vh - 72px);
  overflow: auto;
`
