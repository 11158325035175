import { TagManager } from '@navent-jobs/config'

export const CrossSiteDataLayers = {
  header: from => {
    /**
     * El objetivo es conocer el uso que le dan los usuarios a los botones del Header.
     *
     * Debe dispararse cuando el usuario hace click en alguno de los botones del Header.
     */

    return new Promise((eventCallback) => {
      TagManager.Push({
        event: 'select_content',
        content_type: 'Menu Header/Lateral',
        item_id: from,
        eventCallback,
      })
    })
  },
  navbarMobile: from => {
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Navbar',
      item_id: from,
    })
  },
}
