import { Request, Get, Put } from '@navent-jobs/config'
import { ConfirmReferenceType, UpdateReferenceStatusType } from '../redux/types/confirm-reference'

export class ErrorConfirmReference extends Error {}

class ConfirmReference {
  public async getReferenceToConfirm({ referenciaId, postulanteId }): Promise<ConfirmReferenceType> {
    try {
      const request = new Request()
      request.method = Get
      request.path = `/api/referencia/${referenciaId}/postulante/${postulanteId}`
      const resp = await request.call()

      return resp
    } catch (e) {
      throw new ErrorConfirmReference(`Error al obtener la referencia`)
    }
  }

  public async updateReferenceStatus(status: UpdateReferenceStatusType) {
    try {
      const request = new Request()
      request.method = Put
      request.path = `/api/referencia/${status.referenciaId}/postulante/${status.postulanteId}`
      request.body = {
        estado: status.estado,
        telefonoPrefijo: status.telefonoPrefijo,
        telefonoNumero: status.telefonoNumero,
      }
      await request.call()
    } catch (e) {
      throw new ErrorConfirmReference(`Error al confirmar/rechazar la referencia`)
    }
  }
}

const confirmReference = new ConfirmReference()

export default confirmReference
