import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Page = styled('div')`
  background: #ff5f44;
  padding: 22px 16px;
`

export const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row;
  }
`

export const TextWrapper = styled('div')`
  font-size: 16px;
  line-height: 20px;
  color: white;
`

export const CustomLink = styled(Link)`
  width: 100%;
  height: 48px;
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 0;
    min-width: 200px;
    width: auto;
  }
`
