export const PaymentMPProductId = {
  PREMIUM: 57,
  PDA: 1003470,
  NULINGA: 1003518,
  MIGURU: 1003520,
  DOCTORCV: 1003532
}

export const ecommerceProductId = {
  '1': 'premium',
  '2': 'testDePersonalidad',
  '3': 'nulinga',
  '4': 'miguru',
  '5': 'doctorcv'
}
