import { Request, Get } from '@navent-jobs/config'

export class ErrorAreaAds extends Error {}

class AreaAds {
  public async getAreaAds() {
    const request = new Request()
    request.path = `/api/marketing/listado`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorAreaAds(`Ads no encontrados`)
        }
      })
  }
}

const areaAds = new AreaAds()
export default areaAds
