export const productLanding = {
  nulinga: {
    home: {
      title: 'Validá tu nivel de Inglés con Nulinga y resaltá tu perfil',
      description:
        'Completá el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
    },
    description: {
      firstRow: {
        thirdColumn: {
          description:
            'Podés hacer el test por un costo accesible y realizar el pago fácilmente a través de Mercado pago.',
        },
      },
    },
    product: {
      description:
        'Completá el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
    },
  },
  pda: {
    home: {
      title: 'Resaltá tus talentos frente a las empresas con PDA',
      description: 'Realizá el Test de PDA y conocé tus fortalezas para resaltar tu CV al postularte.',
      secondaryButton: 'Conocé más',
    },
    product: {
      description: 'Resaltá tus fortalezas para encontrar el empleo que deseas.',
    },
  },
}
