import { TagManager } from '@navent-jobs/config'

export const DataLayersCoderhouse = {
  clickCard: (from, logged) => {
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Beneficio CoderHouse',
      eventAction: `Click al card CoderHouse-${logged ? 'logueado' : 'NO logueado'}`,
      eventLabel: `Acceso desde-${from}`,
    })
  },
  printModal: () => {
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Beneficio CoderHouse',
      eventAction: 'Impresion Modal',
    })
  },
  interaccion: from => {
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Beneficio CoderHouse',
      eventAction: 'Click modal',
      eventLabel: from,
    })
  },
}
