export const ACTUALIZAR_FECHA_CV = 'ACTUALIZAR_FECHA_CV'
export const ACTUALIZAR_FECHA_CV_SUCCESS = 'ACTUALIZAR_FECHA_CV_SUCCESS'
export const ACTUALIZAR_FECHA_CV_ERROR = 'ACTUALIZAR_FECHA_CV_ERROR'
export const ACTUALIZAR_FECHA_CV_RESET = 'ACTUALIZAR_FECHA_CV_RESET'

export interface ActualizarFechaCV {
  type: typeof ACTUALIZAR_FECHA_CV
  payload: null
}

export interface ActualizarFechaCVSuccess {
  type: typeof ACTUALIZAR_FECHA_CV_SUCCESS
  payload: null
}

export interface ActualizarFechaCVError {
  type: typeof ACTUALIZAR_FECHA_CV_ERROR
}

export interface ActualizarFechaCVReset {
  type: typeof ACTUALIZAR_FECHA_CV_RESET
  payload: null
}
