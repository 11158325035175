import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { makeURL } from '@navent-jobs/interlinking'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Collapse } from '@navent-jobs/ui-kit/components/collapse'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import {
  Toggle,
  CardFooterContainer,
  LinkMessages,
  ItemGroup,
  Label,
  IconCustom,
  OffSetCol,
  LinkAvisos,
  EstadoWrapper,
} from './mixins'

// utils
import { daysOfPublication } from '../../../../../../utils/calculate-days-publication'
import { DataLayersMisPostulaciones } from '../../../../../../constants/gtm-events-mis-avisos'
import { IS_ZONA_JOBS } from '../../../../../../constants'
import EstadoPostulacion from './EstadoPostulacion'

const Component = ({ postulacion }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()

  const [hasCollapsed, setHasCollapsed] = useState(true)

  const {
    id,
    cantidadComentarios,
    preferenciaSalarial,
    estado,
    fechaPublicacionAviso,
    fechaPostulacion,
    areaAviso,
    provinciaAviso,
    localidadAviso,
  } = postulacion

  const idPostulacion = id
  const fechaPublicacion = fechaPublicacionAviso
  const totalComments = cantidadComentarios === null ? '0' : cantidadComentarios
  const hasPreferenciaSalarial = preferenciaSalarial === null ? 'No especificado' : preferenciaSalarial
  const avisoTipoPostingNormal = postulacion.tipoDePostingId === 1
  const showPreferenciaSalarial = avisoTipoPostingNormal

  const handleCollapsed = () => {
    setHasCollapsed(!hasCollapsed)
  }

  const handleLinkMessage = () => {
    const hasComments = totalComments !== '0'
    const linkMensaje = `/postulantes/mensajes/postulacion/${idPostulacion}`

    if (estado === 'finalizada') {
      return (
        <div onClick={() => DataLayersMisPostulaciones.avisosSimilares()}>
          <LinkAvisos
            href={makeURL({
              provincia: provinciaAviso,
              localidad: localidadAviso,
              area: areaAviso,
            })}
            linkoptions={{ target: isDesktop ? '_blank' : '_self', variant: 'primary' }}
          >
            {t('postulaciones.card.similares')}
            <Icon name="icon-light-caret-right" size="16" color={colors.primary.normal} />
          </LinkAvisos>
        </div>
      )
    }

    if (IS_ZONA_JOBS) {
      return null
    }

    return (
      <LinkMessages
        href={linkMensaje}
        linkoptions={{ target: '_blank', variant: 'gray', size: 'small', type: 'link' }}
        icon={{ name: 'icon-light-message-square', size: '24', color: 'rgba(0,0,24,0.6)', position: 'left' }}
        disabled={!hasComments}
        hasComments={hasComments}
      />
    )
  }

  return (
    <>
      {!isDesktop && (
        <>
          <Toggle>
            {handleLinkMessage()}
            <div onClick={() => handleCollapsed()}>
              <Icon
                name={`${hasCollapsed ? 'icon-light-caret-down' : 'icon-light-caret-up'}`}
                size="18"
                color={colors.grey72}
              />
            </div>
          </Toggle>
        </>
      )}

      <Collapse disabled={isDesktop} collapsed={hasCollapsed}>
        <CardFooterContainer hasCollapsed={!hasCollapsed}>
          <OffSetCol>
            {isDesktop && (
              <EstadoWrapper estado={estado}>
                <EstadoPostulacion estado={estado} totalComments={totalComments} />
              </EstadoWrapper>
            )}
            {showPreferenciaSalarial && (
              <ItemGroup>
                <Label>
                  <IconCustom name="icon-light-money" color="rgba(0,0,24,0.6);" /> {t('postulaciones.card.salario')}
                </Label>
                {hasPreferenciaSalarial}
              </ItemGroup>
            )}

            <ItemGroup>
              <Label>
                <IconCustom name="icon-light-file-text" color="rgba(0,0,24,0.6);" /> Fecha de publicación:
              </Label>
              {daysOfPublication({ fechaPublicacion, section: 'mis-postulaciones' })}
            </ItemGroup>

            <ItemGroup>
              <Label>
                <IconCustom name="icon-light-checkbox-checked" color="rgba(0,0,24,0.6);" /> Fecha de postulación:
              </Label>
              {fechaPostulacion}
            </ItemGroup>
          </OffSetCol>

          {isDesktop && handleLinkMessage()}
        </CardFooterContainer>
      </Collapse>
    </>
  )
}

export default Component
