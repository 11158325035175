import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ChangePasswordSuccess from './change-password-success'
import ChangePasswordRequest from './change-password-request'
import ChangePasswordExpired from './change-password-expired'

const ChangePassword = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`} render={props => <ChangePasswordRequest {...props} />} />
      <Route exact path={`${path}/exito`} render={props => <ChangePasswordSuccess {...props} />} />
      <Route exact path={`${path}/enlace-vencido`} render={props => <ChangePasswordExpired {...props} />} />
    </Switch>
  )
}

export default ChangePassword
