import React from 'react'

import Pagination from '@navent-jobs/ui-kit/components/pagination-fluid'

import Card from '../../../../../components/card'
import EmptyState from './EmptyState'

import { CardContainer } from './mixins'

const AvisosSection = ({ cantidadAvisos, avisos, page, totalPages, pageHandler, queryParams }) => {
  return (
    <>
      {cantidadAvisos > 0 ? (
        <>
          {avisos.content.map((aviso, index) => (
            <CardContainer key={aviso.id}>
              <Card aviso={aviso} index={index} disableLink={false} ubicacion="Micrositio de empresa" />
            </CardContainer>
          ))}
          <Pagination
            totalPages={totalPages}
            currentPage={Number(page) + 1}
            currentUrl={window.location.pathname}
            states={{ loading: false }}
            goToPage={pageHandler}
            queryParams={queryParams}
          />
        </>
      ) : (
        <EmptyState />
      )}
    </>
  )
}

export default AvisosSection
