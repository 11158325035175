const modalCrearAlerta = {
  'Crear alerta de nuevos trabajos': 'Crear alerta de nuevos trabajos',
  'Recibí por email los avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda':
    'Recibí por email los avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda.',
  'Ya tenés una alerta creada con las mismas características.':
    'Ya tenés una alerta creada con las mismas características.',
  'Podés gestionarla desde Alertas.': 'Podés gestionarla desde Alertas.',
  'Error por limite de alertas': 'Ya creaste tus 10 alertas disponibles. Para agregar una nueva, eliminá una anterior.',
  'Validacion Mail Alerta': 'Ingresá tu email.',
  'Podés modificar la frecuencia de envío o eliminar la alerta desde Notificaciones':
    'Podés modificar la frecuencia de envío o eliminar la alerta desde Notificaciones',
  'Podés elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones':
    'Podés elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones',
  'Activa tu cuenta y recibe por email las nuevas ofertas de tu búsqueda':
    'Activá tu cuenta y recibí por email las nuevas ofertas de tu búsqueda.',
  'No pudimos crear tu alerta, intenta nuevamente': 'No pudimos crear tu alerta, intentá nuevamente.',
}

export default modalCrearAlerta
