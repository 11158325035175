import React from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

import { Anchors, LinkCustom } from './mixins'

const Component = ({ children, visibleId, hasLogo, buscaActivamente }) => {
  const { isMobile, isTabletPortrait, isTabletLandscape } = useWindowSize()

  const scroll = item => {
    const windowPosition = window.pageYOffset
    const targetId = item?.props?.href.substring(1)
    const element = document.getElementById(targetId) as HTMLElement
    const header = document.getElementById('sticky-container-feria')?.getBoundingClientRect()
    const anchor = document.getElementById('navbar-header')?.getBoundingClientRect()

    if (element) {
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + windowPosition - (header?.height || 0) - (anchor?.height || 0)

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Anchors>
      {children.map(item => {
        return (
          item && (
            <LinkCustom
              href={item.props.disabled ? '#' : item.props.href}
              onClick={e => {
                e.preventDefault()
                scroll(item)
              }}
              withIcon={item.props.withIcon}
              isActive={visibleId === item.props.anchorId}
              disabled={item.props.disabled}
              isMobileOrTablet={isMobile || isTabletPortrait || isTabletLandscape}
            >
              {item.props.children}
            </LinkCustom>
          )
        )
      })}
    </Anchors>
  )
}

export default Component
