import {
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR,
  initialState,
  ActivationEmailAction,
  ActivationEmailState,
} from '../types/activation-email'

const reducers = (state: ActivationEmailState = initialState, action: ActivationEmailAction): ActivationEmailState => {
  switch (action.type) {
    case SEND_ACTIVATION_EMAIL:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case SEND_ACTIVATION_EMAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SEND_ACTIVATION_EMAIL_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
