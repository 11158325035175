import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useOperativeSystem } from '@navent-jobs/ui-kit/hooks/operative-system'
import Text from '@navent-jobs/ui-kit/components/text'

// images
import backgroundCTA from '../../../assets/images/cta-download-app-background.svg'
import logoApp from '../../../assets/images/cta-logo-app.svg'

// mixins
import { CTAComponent, ImageAppLogo, Heading, CTAHeader, CTALinks, LinkToStore } from './mixins'

// constants
import { NOMBRE_SITIO, SUFIJO_PAIS } from '../../../constants'

// store
import { setComponentSettings } from '../../../redux/actions/cta-download-app'

const CtaMobile = ({ storeLinks }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentOS } = useOperativeSystem()
  const { isMobile, isTabletPortrait } = useWindowSize()
  const { isMobileOS } = useOperativeSystem()

  const handleClickButton = () => {
    dispatch(setComponentSettings({ enable: { mobile: false, desktop: false } }))
    sessionStorage.setItem('declineApp', 'true')
  }

  if ((!isMobile || !isTabletPortrait) && !isMobileOS) {
    return null
  }

  return ReactDOM.createPortal(
    <CTAComponent background={backgroundCTA}>
      <CTAHeader>
        <ImageAppLogo src={logoApp} alt="logo-app" />
        <Heading>
          <Text size="md" fontWeight="semibold" variant="secondary" as="h4">
            {t('ctaDownloadApp.mobile.title')}
          </Text>
        </Heading>
      </CTAHeader>

      <CTALinks>
        <LinkToStore
          onClick={() => handleClickButton()}
          linkoptions={{ variant: 'primary', type: 'outline', size: 'small', block: true }}
        >
          {t('ctaDownloadApp.mobile.button.dismin')}
        </LinkToStore>
        <LinkToStore
          href={storeLinks[SUFIJO_PAIS][NOMBRE_SITIO.toLowerCase()][currentOS]}
          linkoptions={{ variant: 'primary', type: 'fill', size: 'small', block: true }}
          native={false}
          target="_blank"
        >
          {t('ctaDownloadApp.mobile.button.accept')}
        </LinkToStore>
      </CTALinks>
    </CTAComponent>,
    document.getElementById('alerts') as HTMLElement
  )
}

export default CtaMobile
