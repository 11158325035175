import {
  GetListadoEmpresasSeguidas,
  GetListadoEmpresasSeguidasRequestDto,
  GET_LISTADO_EMPRESAS_SEGUIDAS,
} from '../types/save-company'

export const getEmpresasSeguidas = (value: GetListadoEmpresasSeguidasRequestDto): GetListadoEmpresasSeguidas => {
  return {
    type: GET_LISTADO_EMPRESAS_SEGUIDAS,
    payload: value,
  }
}
