import styled from 'styled-components'

export const SortEmpresasContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 12px;
  }
`
