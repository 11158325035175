import styled from 'styled-components'

export const PreviewWrapper = styled('div')`
  margin: -72px 0 60px 0;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
`

export const IframeWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin: -150px 0 60px 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 0;
  }
`

export const Iframe = styled('iframe')`
  border: none;
  width: 100%;
  height: 100%;
`
