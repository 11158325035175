import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  padding-bottom: '56.25%';
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`
