import React, { useEffect } from 'react'
import Pagination from '@navent-jobs/ui-kit/components/pagination-fluid'

// repositories
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// mixins
import { EmpresaCard, CenterContainer, CustomContainer } from './mixins'

// components
import CardPlaceholderMobile from '../../../../../listado-empresas/components/listado/components/placeholder/card/CardPlaceholderMobile'
import CardPlaceholderTablet from '../../../../../listado-empresas/components/listado/components/placeholder/card/CardPlaceholderTablet'
import CardPlaceholder from '../../../../../listado-empresas/components/listado/components/placeholder/card/CardPlaceholder'
import { getCompanyURL } from '../../../../../../hooks/get-company-url'
import CardComponent from '../../../../../../components/card-company/extended'

const TOTAL_CARDS_PLACEHOLDER = 4

const showPlaceholders = (key, isMobile, isTabletPortrait) => {
  if (isMobile) {
    return <CardPlaceholderMobile key={key} />
  }

  if (isTabletPortrait) {
    return <CardPlaceholderTablet key={key} />
  }

  return <CardPlaceholder key={key} />
}

const ListadoEmpresasSeguidas = ({ empresasSeguidas, pagination, loading, currentPage, queryParams }) => {
  const { isMobile, isTabletPortrait } = useWindowSize()

  useEffect(() => {
    if (empresasSeguidas && document.documentElement.scrollTo) {
      document.documentElement.scrollTo(0, 0)
    }
  }, [empresasSeguidas, document])

  if (loading) {
    return (
      <>
        {[...Array(TOTAL_CARDS_PLACEHOLDER)].map(i => {
          return showPlaceholders(i, isMobile, isTabletPortrait)
        })}
      </>
    )
  }

  return (
    <CustomContainer>
      {empresasSeguidas &&
        empresasSeguidas.length > 0 &&
        empresasSeguidas.map(empresa => {
          const dataUrl = {
            cantidadAvisos: empresa?.jobPostingsQuantity,
            denominacion: empresa?.name,
            id: empresa?.id,
          }
          const settingsCompanyURL = getCompanyURL({ company: dataUrl })
          return (
            <EmpresaCard key={`empresa-${empresa?.id}`}>
              <CardComponent
                company={{ ...empresa, following: true }}
                settingsCompanyURL={settingsCompanyURL}
                showSeguirEmpresa
              />
            </EmpresaCard>
          )
        })}

      <CenterContainer>
        <Pagination
          {...pagination}
          currentPage={Number(currentPage) + 1}
          currentUrl={window.location.pathname}
          states={{ loading }}
          queryParams={queryParams}
        />
      </CenterContainer>
    </CustomContainer>
  )
}

export default ListadoEmpresasSeguidas
