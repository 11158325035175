import { status } from '../types/status'
import {
  GET_RECOMENDADOS,
  GET_RECOMENDADOS_ERROR,
  GET_RECOMENDADOS_RESET,
  GET_RECOMENDADOS_SUCCESS,
  GET_RECOMENDADOS_LISTADO,
  GET_RECOMENDADOS_LISTADO_SUCCESS,
  GET_RECOMENDADOS_LISTADO_ERROR,
  RecomendadosAction,
  RecomendadosState,
  initialState,
  SET_NOT_INTERESTED,
  SET_NOT_INTERESTED_SUCCESS,
  SET_NOT_INTERESTED_ERROR,
  SET_NOT_INTERESTED_RESET,
  GET_MOTIVOS_NO_INTERESADO,
  GET_MOTIVOS_NO_INTERESADO_SUCCESS,
  GET_MOTIVOS_NO_INTERESADO_ERROR,
} from '../types/recomendados'

const reducers = (state: RecomendadosState = initialState, action: RecomendadosAction): RecomendadosState => {
  switch (action.type) {
    case GET_RECOMENDADOS:
    case GET_RECOMENDADOS_LISTADO:
      return {
        ...state,
        status: status.loading,
      }
    case GET_RECOMENDADOS_SUCCESS:
    case GET_RECOMENDADOS_LISTADO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: status.success,
      }
    case GET_RECOMENDADOS_ERROR:
    case GET_RECOMENDADOS_LISTADO_ERROR:
      return {
        ...state,
        status: status.error,
      }
    case GET_RECOMENDADOS_RESET:
      return initialState
    case GET_MOTIVOS_NO_INTERESADO:
      return {
        ...state,
        motivosNoInteresadoStatus: status.loading,
      }
    case GET_MOTIVOS_NO_INTERESADO_SUCCESS:
      return {
        ...state,
        motivosNoInteresado: action.payload,
        motivosNoInteresadoStatus: status.success,
      }
    case GET_MOTIVOS_NO_INTERESADO_ERROR:
      return {
        ...state,
        motivosNoInteresadoStatus: status.error,
      }
    case SET_NOT_INTERESTED:
      return {
        ...state,
        notInterestedStatus: status.loading,
      }
    case SET_NOT_INTERESTED_SUCCESS:
      return {
        ...state,
        notInterestedId: action.payload,
        notInterestedStatus: status.success,
      }
    case SET_NOT_INTERESTED_ERROR:
      return {
        ...state,
        notInterestedId: action.payload,
        notInterestedStatus: status.error,
      }
    case SET_NOT_INTERESTED_RESET:
      return {
        ...state,
        notInterestedStatus: status.initial,
      }
    default:
      return state
  }
}

export default reducers
