import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'

// libs
import { numberTropicalizer } from '@navent-jobs/config'

// mixins
import { EmpleosTotalText, Total } from './mixins'

// constants
import { NOMBRE_PAIS } from '../../../../constants'

interface EmpleosTotalProps {
  h1?: string | null
  preh1?: string | null
  totalEmpresas: number
  loading: boolean
  cantidadEmpresas: boolean
  filtersApplied: any
}

const CantidadEmpresas = ({
  totalEmpresas,
  h1,
  preh1,
  loading,
  cantidadEmpresas,
  filtersApplied,
}: EmpleosTotalProps) => {
  const industry = filtersApplied.find(f => f.id === 'industry')
  const province = filtersApplied.find(f => f.id === 'province')
  const { t } = useTranslation()

  if (!cantidadEmpresas) {
    return null
  }

  return (
    <EmpleosTotalText>
      <Text size="sm" fontWeight="semibold" as="h1">
        {preh1 ? `${preh1} ` : undefined}
        <Total>{!loading && numberTropicalizer(totalEmpresas)} </Total>
        {loading
          ? 'Buscando empresas...'
          : t('listadoEmpresas.counter.text', {
              industria: industry !== undefined ? `de ${industry?.value}` : '',
              ubicacion: province ? province?.value : NOMBRE_PAIS,
            })}
      </Text>
    </EmpleosTotalText>
  )
}

const states = ({ listadoEmpresasStore }) => {
  const { totalEmpresas, loading, cantidadEmpresas, seoWordings, filtersApplied } = listadoEmpresasStore
  const { h1, preh1 } = seoWordings

  return {
    totalEmpresas,
    loading,
    cantidadEmpresas,
    h1,
    preh1,
    filtersApplied,
  }
}

export default connect(states)(CantidadEmpresas)
