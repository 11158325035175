import React from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL } from '../../../../constants'

const Meta = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Preguntas frecuentes</title>
      <link rel="canonical" href={BASE_URL} />
    </Helmet>
  )
}

export default Meta
