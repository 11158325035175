export const testPersonalidad = {
  stepInstructionsTitle: '¡Te damos la bienvenida al test de personalidad!',
  stepInstructionsDescription: [
    'Este análisis fue diseñado para identificar los estilos de comportamiento de las personas.',
    'No contiene respuestas correctas o incorrectas, ya que no existen perfiles buenos o malos, simplemente diferentes.',
  ],
  stepInstructionsRules: [
    'No hay un límite de tiempo determinado.',
    'Es importante responder con sinceridad y en un ambiente tranquilo, sin interrupciones.',
    'Lee con atención las instrucciones antes de iniciar cada sección.',
  ],
  stepOneDescription: [
    'Lee el siguiente listado y selecciona todas las palabras que usarían',
    ' otras personas ',
    'para describirte.',
    'Elegí al menos 19.',
  ],
  stepOneTitle: 'La gente dice que soy una persona:',
  stepTwoDescription: [
    'Lee el siguiente listado y selecciona todas las palabras que',
    ' tú ',
    'usarías para describirte.',
    'Elegí al menos 19.',
  ],
  stepTwoTitle: 'Realmente soy una persona:',
  stepThreeDescription:
    'Ahora, descríbete utilizando tus propias palabras. Puedes ocupar todo el espacio que necesites.',
  stepThreePlaceholder: 'Escribe una autodescripción',
  stepResultTitle: '¡Ya completaste el test de personalidad!',
  stepResultDescription:
    'El resultado quedó guardado en tu {{cv}}, desde donde puedes decidir si quieres que lo vean o no las empresas.',
  stepResultLinkDescription: 'Ir a Mi {{cv}}',
  modalExitTitle: '¿Querés salir del test?',
  modalExitDescription:
    'Si sales, se borrará todo lo que hiciste hasta ahora y tendrás que empezar de nuevo la próxima vez.',
  modalValidationStep: [
    'Tenés que seleccionar al menos',
    ' 19 palabras ',
    'para continuar, porque así se obtendrá un perfil más específico.',
  ],
  stepConectionLostTitle:
    'No pudimos conectar con la página del test para enviar tus respuestas y obtener un resultado.',
  stepConectionLostDescription: '¡Inténtalo nuevamente!',
  stepConectionLostSendResult: 'Reenviar mis respuestas',
  stepConectionLostLinkDescription: 'Ir a Mi {{cv}}',
  stepConectionLostSkipTestDescription: 'Si sales, perderás las respuestas y tendrás que volver a hacer el test.',
  stepInvalidResultTitle: 'No pudimos obtener un resultado con las respuestas que nos diste.',
  stepInvalidResultDescription: '¡Inténtalo nuevamente!',
  stepInvalidResultRestart: 'Rehacer el test',
  stepInvalidResultLinkDescription: 'Ir a Mi {{cv}}',
  bannerPda: {
    incentivo: 'Realiza el test de personalidad y resalta tus talentos frente a las empresas.',
    incentivoLink: 'Hacer el test',
    conocerMas: 'Conoce más',
    obtenerResultado: {
      descripcion:
        '¿Quieres conocer en detalle tus aptitudes? Adquiere el informe completo de tu test de personalidad.',
      ctaObtener: 'Obtener informe completo',
    },
    descargar: {
      descripcion: 'Tienes disponible el informe completo de tu test de personalidad',
      ctaDescargar: 'Descargar informe completo',
    },
    modal: {
      title: 'Agrega tu número de documento en el {{cv}}',
      description: 'Necesitamos ese dato para efectuar la compra. Una vez agregado, vuelve a intentarlo.',
      ctaContinuar: 'Aceptar',
    },
  },
}
