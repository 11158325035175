import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const SWITCH_DISPONIBLE_INMEDIATO = 'SWITCH_DISPONIBLE_INMEDIATO'
export const SWITCH_DISPONIBLE_INMEDIATO_SUCCESS = 'SWITCH_DISPONIBLE_INMEDIATO_SUCCESS'
export const SWITCH_DISPONIBLE_INMEDIATO_ERROR = 'SWITCH_DISPONIBLE_INMEDIATO_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

// switch
export interface SwitchDisponibleInmediatoType {
  disponible: boolean
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface DisponibleInmediatoState {
  switchDisponibleInmediato: {
    value: SwitchDisponibleInmediatoType | null
    status: StatusType
  }
}

export const initialState: DisponibleInmediatoState = {
  switchDisponibleInmediato: {
    value: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */

// switch
export interface SwitchDisponibleInmediato {
  type: typeof SWITCH_DISPONIBLE_INMEDIATO
  payload: SwitchDisponibleInmediatoType
}

export interface SwitchDisponibleInmediatoSuccess {
  type: typeof SWITCH_DISPONIBLE_INMEDIATO_SUCCESS
  payload: null
}
export interface SwitchDisponibleInmediatoError {
  type: typeof SWITCH_DISPONIBLE_INMEDIATO_ERROR
  payload: null
}

export type DisponibleInmediatoActions =
  | SwitchDisponibleInmediato
  | SwitchDisponibleInmediatoSuccess
  | SwitchDisponibleInmediatoError
