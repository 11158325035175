export const EDIT_ALERTS = 'EDIT_ALERTS'
export const EDIT_ALERTS_RESET = 'EDIT_ALERTS_RESET'
export const EDIT_ALERTS_SUCCESS = 'EDIT_ALERTS_SUCCESS'
export const EDIT_ALERTS_ERROR = 'EDIT_ALERTS_ERROR'

export interface EditAlertsType {
  frecuenciaNotificacionId: number | null
  ciudad: string | null
  areaId: number | null
  query: string | null
  localizacion: {} | null
  paisId: number | null
  salarioMinimo: number | null
  tipoTrabajoId: number | null
  subAreaId: number[] | null
  modalidadTrabajoId: number | null
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export interface EditAlertsState {
  data: EditAlertsType | null
  statusEdit: Status
}

export const initialEditAlertsState = {
  data: null,
  statusEdit: initialStatus,
}

export interface EditAlerts {
  type: typeof EDIT_ALERTS
  payload: EditAlertsType
  id: number | null
}

export interface EditAlertsSuccess {
  type: typeof EDIT_ALERTS_SUCCESS
  payload: EditAlertsType
}

export interface EditAlertsError {
  type: typeof EDIT_ALERTS_ERROR
  payload: string | null
}

export interface EditAlertsReset {
  type: typeof EDIT_ALERTS_RESET
  payload: null
}

export type EditAlertsActions = EditAlerts | EditAlertsSuccess | EditAlertsError | EditAlertsReset
