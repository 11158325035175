/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useOperativeSystem } from '@navent-jobs/ui-kit/hooks/operative-system'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import Image from '@navent-jobs/ui-kit/components/Image'
import CtaDownloadApp from '../CtaDownloadApp'
import { IS_ZONA_JOBS, ID_PAIS, NOMBRE_SITIO, SITE_ID } from '../../constants'
import { FooterDataLayers as dataLayers } from '../../constants/gtm-events-footer'

import {
  FooterComponent,
  SocialNetworks,
  Form,
  FormInput,
  SocialNetworkLink,
  LinksLegalesContainer,
  LinksLegales,
  SelectContainer,
  SelectCountries,
  LinkLegal,
  SpanLegal,
  FooterBottomContainer,
  CopyrightContainer,
  Copyright,
  DataFiscal,
  DataFiscalImage,
  LinkJobint,
  OptionContainer,
  OptionFlag,
  OptionLabel,
} from './mixins'

import logoJobint from '../../assets/images/jobint.svg'
import dataFiscalImg from '../../assets/images/data-fiscal.svg'
import facebook from '../../assets/images/social-networks/facebook.svg'
import twitter from '../../assets/images/social-networks/x-twitter.svg'
import instagram from '../../assets/images/social-networks/instagram.svg'
import youtube from '../../assets/images/social-networks/youtube.svg'
import linkedin from '../../assets/images/social-networks/linkedin.svg'
import argentinaFlag from '../../assets/images/flags/argentina.svg'
import chileFlag from '../../assets/images/flags/chile.svg'
import ecuadorFlag from '../../assets/images/flags/ecuador.svg'
import mexicoFlag from '../../assets/images/flags/mexico.svg'
import panamaFlag from '../../assets/images/flags/panama.svg'
import peruFlag from '../../assets/images/flags/peru.svg'
import venezuelaFlag from '../../assets/images/flags/venezuela.svg'

interface ComponentState {
  areas: { countryAvisoCount: number; content: [{ areaId: string; title: string; quantity: number }] }
  isMobile: boolean
}

export interface RefsProps {
  current: { offsetWidth: number }[]
}

/**
 * noFollow es una prop para que Google no siga el link.
 * Si es un link normal, debería ser false para que sí lo siga.
 */
const linksLegales = {
  argentina: {
    bumeran: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: true },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: true },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: true },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: true },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: true },
      { link: `/politica-gestion-calidad`, label: 'Política de Gestión de Calidad', noFollow: true },
    ],
    zonajobs: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: false },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: false },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: false },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: false },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: false },
      { link: `/politica-gestion-calidad`, label: 'Política de Gestión de Calidad', noFollow: false },
    ],
  },
  chile: {
    laborum: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: false },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: false },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: false },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: false },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: false },
    ],
  },
  ecuador: {
    multitrabajos: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones de uso', noFollow: false },
      {
        link: `/politica-de-privacidad`,
        label: 'Política de protección de datos personales y privacidad',
        noFollow: false,
      },
      {
        link: `/condiciones-de-contratacion`,
        label: 'Términos y condiciones generales de contratación',
        noFollow: false,
      },
      {
        link: `/politica-de-cookies`,
        label: 'Política de cookies',
        noFollow: false,
      },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: false },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: false },
    ],
  },
  mexico: {
    bumeran: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: true },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: true },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: true },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: true },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: true },
    ],
  },
  panama: {
    konzerta: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: false },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: false },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: false },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: false },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: false },
    ],
  },
  peru: {
    bumeran: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: true },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: true },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: true },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: true },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: true },
      {
        link: 'https://librodereclamaciones.com.pe/ingresar.php',
        label: '',
        noFollow: true,
        native: true,
        content: (
          <Form name="ingreso" method="post" action="https://librodereclamaciones.com.pe/ingresar.php" target="_blank">
            <FormInput type="hidden" name="usuario" value="bumer_ope1" />
            <FormInput type="hidden" name="token" value="9195502742d844b18fd879ecc5c33856" />
            <FormInput type="submit" name="enviar" value="Libro de reclamaciones" />
          </Form>
        ),
      },
    ],
  },
  venezuela: {
    bumeran: [
      { link: `/terminos-y-condiciones`, label: 'Términos y Condiciones', noFollow: false },
      { link: `/politica-de-privacidad`, label: 'Política de Privacidad', noFollow: false },
      { link: `/condiciones-de-contratacion`, label: 'Condiciones de contratación', noFollow: false },
      { link: `/postulantes/preguntas-frecuentes`, label: 'Preguntas frecuentes', noFollow: false },
      { link: `/sitemaps/`, label: 'Ofertas de Empleo', noFollow: false },
    ],
  },
}

const linksDataFiscal = {
  argentina: {
    bumeran: [
      {
        link: `https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=Iv1YrfwD5YPQV6kMGMPaYQ,,`,
        image: dataFiscalImg,
      },
    ],
    zonajobs: [
      {
        link: `https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=rCEAaA3hEEpqB4O13KuuOQ,,`,
        image: dataFiscalImg,
      },
    ],
  },
  chile: {
    laborum: [],
  },
  ecuador: {
    multitrabajos: [],
  },
  mexico: {
    bumeran: [],
  },
  panama: {
    konzerta: [],
  },
  peru: {
    bumeran: [],
  },
  venezuela: {
    bumeran: [],
  },
}

const socialNetworksLogos = {
  facebook,
  twitter,
  instagram,
  youtube,
  linkedin,
}

const idPaises = {
  1: 'argentina',
  1007: 'chile',
  9: 'ecuador',
  18: 'mexico',
  20: 'panama',
  11: 'peru',
  13: 'venezuela',
}

const socialNetworks = {
  argentina: {
    bumeran: [
      { name: 'facebook', link: 'https://www.facebook.com/bumerancom/' },
      { name: 'twitter', link: 'https://twitter.com/bumeran' },
      { name: 'instagram', link: 'https://www.instagram.com/bumeranarg/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/bumeran.com/' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UC3fIXQKvWcYwCd8f0J7aiSQ' },
    ],
    zonajobs: [
      { name: 'facebook', link: 'https://www.facebook.com/zonajobscom/' },
      { name: 'twitter', link: 'https://twitter.com/ZonaJobs' },
      { name: 'instagram', link: 'https://www.instagram.com/zonajobs/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/zonajobs/' },
      { name: 'youtube', link: 'https://www.youtube.com/user/ZonaJobsEmpleos' },
    ],
  },
  chile: {
    laborum: [
      { name: 'facebook', link: 'https://www.facebook.com/Laborum.Chile/' },
      { name: 'twitter', link: 'https://twitter.com/LaborumChile' },
      { name: 'instagram', link: 'https://www.instagram.com/laborum/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/laborum' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UCO9mQyNF9s_PS1eW7ndPBeg' },
    ],
  },
  ecuador: {
    multitrabajos: [
      { name: 'facebook', link: 'https://www.facebook.com/multitrabajos/' },
      { name: 'twitter', link: 'https://twitter.com/multitrabajos?lang=es' },
      { name: 'instagram', link: 'https://www.instagram.com/multitrabajos/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/multitrabajos.com' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UC3otdPkcr5OmPzNtX1ilL2g' },
    ],
  },
  mexico: {
    bumeran: [
      { name: 'facebook', link: 'https://www.facebook.com/bumeranmexico/' },
      { name: 'twitter', link: 'https://twitter.com/bumeranmx' },
      { name: 'instagram', link: 'https://www.instagram.com/bumeranmexico/?hl=es-la' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/bumeran-mx/' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UC-MCR9RacWc8spT7Ta5SGMg' },
    ],
  },
  panama: {
    konzerta: [
      { name: 'facebook', link: 'https://www.facebook.com/konzertacom/' },
      { name: 'instagram', link: 'https://www.instagram.com/KonzertaPanama/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/konzerta/' },
      { name: 'youtube', link: 'https://www.youtube.com/channel/UCt4mYZClo_IZg_PO7gJfROw?view_as=subscriber' },
    ],
  },
  peru: {
    bumeran: [
      { name: 'facebook', link: 'https://www.facebook.com/Bumeran.comPeru/' },
      { name: 'linkedin', link: 'https://www.linkedin.com/company/bumeranperu/' },
    ],
  },
  venezuela: {
    bumeran: [],
  },
}

const countries = [
  {
    id: 1,
    hostnameSuffix: '.bumeran.com.ar',
    name: 'Argentina',
    flag: argentinaFlag,
    alt: 'Argentina Flag',
  },
  {
    id: 1007,
    hostnameSuffix: '.laborum.cl',
    name: 'Chile',
    flag: chileFlag,
    alt: 'Chile Flag',
  },
  {
    id: 9,
    hostnameSuffix: '.multitrabajos.com',
    name: 'Ecuador',
    flag: ecuadorFlag,
    alt: 'Ecuador Flag',
  },
  {
    id: 18,
    hostnameSuffix: '.bumeran.com.mx',
    name: 'México',
    flag: mexicoFlag,
    alt: 'México Flag',
  },
  {
    id: 20,
    hostnameSuffix: '.konzerta.com',
    name: 'Panamá',
    flag: panamaFlag,
    alt: 'Panamá Flag',
  },
  {
    id: 11,
    hostnameSuffix: '.bumeran.com.pe',
    name: 'Perú',
    flag: peruFlag,
    alt: 'Perú Flag',
  },
  {
    id: 13,
    hostnameSuffix: '.bumeran.com.ve',
    name: 'Venezuela',
    flag: venezuelaFlag,
    alt: 'Venezuela Flag',
  },
]

const getSocialNetworks = (idPais, nombreSitio) => {
  return socialNetworks[idPaises[idPais]][nombreSitio.toLowerCase()]
}

const getLinksLegales = (idPais, nombreSitio) => {
  return linksLegales[idPaises[idPais]][nombreSitio.toLowerCase()]
}

const getLinksDataFiscal = (idPais, nombreSitio) => {
  return linksDataFiscal[idPaises[idPais]][nombreSitio.toLowerCase()]
}

const Option = ({ label, flag, alt }) => {
  return (
    <OptionContainer>
      <OptionFlag width="39px" height="24px" src={flag} alt={alt} />
      <OptionLabel>{label}</OptionLabel>
    </OptionContainer>
  )
}

const getSiteEnviroment = idPais => {
  const currentCountry = countries.find(country => country.id === idPais)
  const requestedHostname: string = window.location.hostname
  // default value AR: somethig goes wrong or maybe hostaname == localhost?
  let siteEnviroment = 'local'
  if (currentCountry) {
    const siteSuffix: string = currentCountry.hostnameSuffix
    if (requestedHostname.endsWith(siteSuffix)) {
      // Get the "site enviroment" part of hostname example www in www.bumeran.com.ar
      siteEnviroment = requestedHostname.slice(0, -siteSuffix.length)
    }
  }
  return siteEnviroment
}

const getSiteUrl = (hostnameSuffix, siteEnviroment) => {
  const requestedProtocol = window.location.protocol
  const requestedPort = window.location.port
  let portFragment
  // Avoid include default ports
  if (
    (requestedProtocol === 'http' && requestedPort === '80') ||
    (requestedProtocol === 'https' && requestedPort === '443')
  ) {
    portFragment = ''
  } else {
    portFragment = `:${requestedPort}`
  }
  return `${requestedProtocol}//${siteEnviroment}${hostnameSuffix}${portFragment}`
}

export const Footer = ({ showBottomNavBar }: { showBottomNavBar?: boolean }) => {
  const defaultOption = countries.find(country => country.id === ID_PAIS)
  const siteEnviroment = getSiteEnviroment(ID_PAIS)

  return (
    <FooterComponent>
      <ContainerFluid noGutter>
        {SITE_ID !== 'ZJAR' && (
          <Row noGutter>
            <Col noGutter>
              <CtaDownloadApp type="desktop" />
            </Col>
          </Row>
        )}

        {getSocialNetworks(ID_PAIS, NOMBRE_SITIO).length > 0 && (
          <Row noGutter>
            <Col noGutter>
              <SocialNetworks>
                {getSocialNetworks(ID_PAIS, NOMBRE_SITIO).map(socialNetwork => {
                  return (
                    <SocialNetworkLink
                      rel="nofollow"
                      href={`${socialNetwork.link}`}
                      key={`${NOMBRE_SITIO.toLowerCase()}-${socialNetwork.name}`}
                      onClick={() => {
                        const nameCapitalized = `${socialNetwork.name
                          .charAt(0)
                          .toUpperCase()}${socialNetwork.name.slice(1)}`
                        dataLayers.redesSociales(nameCapitalized)
                      }}
                      target="_blank"
                    >
                      <Image
                        width="32px"
                        height="32px"
                        alt={`logo-${socialNetwork.name}`}
                        className={`logo-${socialNetwork.name}`}
                        src={socialNetworksLogos[socialNetwork.name]}
                      />
                    </SocialNetworkLink>
                  )
                })}
              </SocialNetworks>
            </Col>
          </Row>
        )}

        <Row noGutter>
          <Col noGutter>
            <LinksLegalesContainer>
              <Row noGutter>
                <Col noGutter>
                  <LinksLegales>
                    {getLinksLegales(ID_PAIS, NOMBRE_SITIO).map(item => {
                      return (
                        <SpanLegal key={`footer-link-${item.label.toLocaleLowerCase().replace(' ', '-')}`}>
                          <LinkLegal
                            href={item.link}
                            rel={item.noFollow ? 'nofollow' : null}
                            onClick={item.onClick}
                            linkoptions={{ variant: 'gray', target: '_blank' }}
                            {...(!item.noFollow && { href: item.link })}
                          >
                            {item.content}
                            <Text size="md" fontWeight="semibold" variant="gray60" as="h4">
                              {item.label}
                            </Text>
                          </LinkLegal>
                        </SpanLegal>
                      )
                    })}
                  </LinksLegales>
                </Col>
              </Row>
            </LinksLegalesContainer>
          </Col>
        </Row>

        <Row noGutter>
          <Col noGutter>
            <FooterBottomContainer showBottomNavBar={showBottomNavBar}>
              <SelectContainer>
                {!IS_ZONA_JOBS && (
                  <SelectCountries
                    name="footer-countries"
                    options={countries.map(country => {
                      return {
                        label: <Option label={country.name} flag={country.flag} alt={country.alt} />,
                        value: getSiteUrl(country.hostnameSuffix, siteEnviroment),
                      }
                    })}
                    onChange={(name, selectedOption) => window.location.assign(selectedOption.value)}
                    value={
                      defaultOption && {
                        label: <Option label={defaultOption.name} flag={defaultOption.flag} alt={defaultOption.alt} />,
                        value: getSiteUrl(defaultOption.hostnameSuffix, siteEnviroment),
                      }
                    }
                    fieldOptions={{ variant: 'lighten' }}
                    reactSelectOptions={{
                      isClearable: false,
                      menuPlacement: 'top',
                      isSearchable: false,
                    }}
                  />
                )}
              </SelectContainer>

              <CopyrightContainer>
                {getLinksDataFiscal(ID_PAIS, NOMBRE_SITIO).map(dataFiscal => {
                  return (
                    <DataFiscal key={`data-fiscal-${NOMBRE_SITIO.toLocaleLowerCase().replace(' ', '-')}`}>
                      <LinkLegal href={`${dataFiscal.link}`} target="_F960AFIPInfo">
                        <DataFiscalImage width="60px" height="80px" alt="Data fiscal" src={`${dataFiscal.image}`} />
                      </LinkLegal>
                    </DataFiscal>
                  )
                })}

                <Copyright>
                  <LinkJobint href="http://www.jobint.com">
                    <Image width="95px" height="44px" src={logoJobint} alt="Jobint" />
                  </LinkJobint>

                  <span>&copy; Copyright 1999-{new Date().getFullYear()} Jobint</span>
                </Copyright>
              </CopyrightContainer>
            </FooterBottomContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </FooterComponent>
  )
}

export default Footer
