import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Button } from '@navent-jobs/ui-kit/components/button'
import styled from 'styled-components'

export const ModalComponent = styled(Modal)``

export const ModalBodyComponent = styled.div``

export const ModalBodyContainer = styled.div`
  text-align: center;
  height: 170px;
  margin-bottom: 32px;
`

export const TitleModal = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const CustomerButton = styled(Button)`
  width: 200px;
  height: 48px;
  font-weight: 600;
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    width: 100%;
  }
`

export const CustomMainContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  display: flex;
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    display: block;
  }
`
