import styled from 'styled-components'

export const Heading = styled('div')`
  margin-bottom: 12px;
  text-align: center;
`

export const CustomText = styled('p')`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 600;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
  }
`
