import { UpdateSkillsType, DeleteSkillType, ReplaceSkillsType } from '../commons'

export const UPDATE_SKILLS = 'UPDATE_SKILLS'
export const UPDATE_SKILLS_SUCCESS = 'UPDATE_SKILLS_SUCCESS'
export const UPDATE_SKILLS_ERROR = 'UPDATE_SKILLS_ERROR'
export const UPDATE_SKILLS_RESET = 'UPDATE_SKILLS_RESET'

export const REPLACE_SKILLS = 'REPLACE_SKILLS'
export const REPLACE_SKILLS_SUCCESS = 'REPLACE_SKILLS_SUCCESS'
export const REPLACE_SKILLS_ERROR = 'REPLACE_SKILLS_ERROR'
export const REPLACE_SKILLS_RESET = 'REPLACE_SKILLS_RESET'

export const DELETE_SKILLS = 'DELETE_SKILLS'
export const DELETE_SKILLS_SUCCESS = 'DELETE_SKILLS_SUCCESS'
export const DELETE_SKILLS_ERROR = 'DELETE_SKILLS_ERROR'
export const DELETE_SKILLS_RESET = 'DELETE_SKILLS_RESET'

// Delete skills
export interface DeleteSkills {
  type: typeof DELETE_SKILLS
  payload: DeleteSkillType
}
export interface DeleteSkillsSuccess {
  type: typeof DELETE_SKILLS_SUCCESS
  payload: null
}
export interface DeleteSkillsError {
  type: typeof DELETE_SKILLS_ERROR
  payload: null
}
export interface DeleteSkillsReset {
  type: typeof DELETE_SKILLS_RESET
  payload: null
}

// Update skills
export interface UpdateSkills {
  type: typeof UPDATE_SKILLS
  payload: UpdateSkillsType
}
export interface UpdateSkillsSuccess {
  type: typeof UPDATE_SKILLS_SUCCESS
  payload: null
}
export interface UpdateSkillsError {
  type: typeof UPDATE_SKILLS_ERROR
  payload: null
}
export interface UpdateSkillsReset {
  type: typeof UPDATE_SKILLS_RESET
  payload: null
}

// Replace skills
export interface ReplaceSkills {
  type: typeof REPLACE_SKILLS
  payload: ReplaceSkillsType
}
export interface ReplaceSkillsSuccess {
  type: typeof REPLACE_SKILLS_SUCCESS
  payload: null
}
export interface ReplaceSkillsError {
  type: typeof REPLACE_SKILLS_ERROR
  payload: null
}
export interface ReplaceSkillsReset {
  type: typeof REPLACE_SKILLS_RESET
  payload: null
}
