import styled from 'styled-components'

export const FooterContainer = styled('div')`
  margin-top: 12px;
`

export const Description = styled('p')`
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey84};
  margin: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
