import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/marketing'
import {
  Campaign,
  CompanyLogos,
  GetCampaign,
  GetCampaignError,
  GetCampaignSuccess,
  GetCompanyLogos,
  GetCompanyLogosError,
  GetCompanyLogosSuccess,
  GET_CAMPAIGN,
  GET_CAMPAIGN_ERROR,
  GET_CAMPAIGN_SUCCESS,
  GET_COMPANY_LOGOS,
  GET_COMPANY_LOGOS_ERROR,
  GET_COMPANY_LOGOS_SUCCESS,
} from '../types/marketing'

function* getCampaign() {
  try {
    const result: Campaign = yield call(service.getCampaign)
    yield put<GetCampaignSuccess>({ type: GET_CAMPAIGN_SUCCESS, payload: result })
  } catch {
    yield put<GetCampaignError>({ type: GET_CAMPAIGN_ERROR, payload: null })
  }
}

function* getCompanyLogos() {
  try {
    const result: CompanyLogos[] = yield call(service.getCompanyLogos)
    yield put<GetCompanyLogosSuccess>({ type: GET_COMPANY_LOGOS_SUCCESS, payload: result })
  } catch {
    yield put<GetCompanyLogosError>({ type: GET_COMPANY_LOGOS_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* notification() {
  yield takeLatest<GetCampaign>(GET_CAMPAIGN, getCampaign)
  yield takeLatest<GetCompanyLogos>(GET_COMPANY_LOGOS, getCompanyLogos)
}
