import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { MainContainer } from '@navent-jobs/ui-kit/components/grid'

export const HeaderPage = styled.div`
  width: 100%;
  height: 56px;
  background-color: white;
  margin-top: -56px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 999;
`

export const HeaderContainer = styled.div`
  height: 100%;
  max-width: 1440px;
  padding-right: 12px;
  padding-left: 12px;
  display: flex;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding-right: 32px;
    padding-left: 32px;
  }
`

export const ComponentContainer = styled(MainContainer)`
  min-height: 100px;
`

export const CustomBack = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0px;
  border: 0;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-top: 16px;
  }
`

export const Label = styled('div')`
  font-size: 14px;
  margin-left: 6px;
`

export const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  padding: 12px 0 22px 0;
`
