import React, { useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useOperativeSystem } from '@navent-jobs/ui-kit/hooks/operative-system'

// components
import SidebarMenu from '../../../../search-bar/components/SidebarMenu'

// mixins
import { BottomNav, BottomNavItem, FloatingButton, MenuItem, ProfileImg } from './mixins'

// constants
import avatarDefault from '../../../../../constants/avatar-default'
import { CrossSiteDataLayers } from '../../../../../constants/gtm-events-cross-site'

// redux
import { setFilterApplied } from '../../../../../redux/actions/listado-empresas'
import { IS_ZONA_JOBS } from '../../../../../constants'

interface MenuOption {
  id: number
  title?: string
  icon: string
  iconSize: string
  activeIcon: string
  href?: string
  onClick?: Function
  match?: string[]
  exactMatch?: boolean
  requireLogin?: boolean
  imgUrl?: string
}

const BottomNavBar = ({ applicant }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { isIos } = useOperativeSystem()
  const [menuOpened, setMenuOpened] = useState(false)
  const [current, setCurrent] = useState({})

  const menu: MenuOption[] = [
    {
      id: 1,
      title: t('navbar.home'),
      icon: 'icon-light-home',
      iconSize: '20',
      activeIcon: 'icon-bold-home',
      onClick: () => CrossSiteDataLayers.navbarMobile('Home'),
      href: '/',
    },
    {
      id: 2,
      title: t('navbar.favorites'),
      icon: 'icon-light-heart',
      iconSize: '20',
      activeIcon: 'icon-bold-heart',
      onClick: () => CrossSiteDataLayers.navbarMobile('Avisos Guardados'),
      href: '/postulantes/avisos-guardados',
      requireLogin: true,
    },
    {
      id: 3,
      icon: 'icon-light-search',
      iconSize: '24',
      activeIcon: 'icon-bold-search',
      onClick: () => {
        CrossSiteDataLayers.navbarMobile('Busqueda')
        setMenuOpened(true)
      },
      match: ['/empleos'],
    },
    {
      id: 4,
      title: t('navbar.applications'),
      icon: 'icon-light-checkmark-circle',
      iconSize: '20',
      activeIcon: 'icon-bold-checkmark-circle-1',
      onClick: () => CrossSiteDataLayers.navbarMobile('Mis Postulaciones'),
      href: '/postulantes/postulaciones',
      requireLogin: true,
    },
    {
      id: 5,
      title: t('navbar.profile'),
      icon: 'icon-light-person',
      iconSize: '20',
      activeIcon: 'icon-bold-user',
      onClick: () => CrossSiteDataLayers.navbarMobile('Curriculum'),
      href: IS_ZONA_JOBS ? '/postulantes/perfil' : '/postulantes/curriculum',
      requireLogin: true,
      imgUrl: applicant && (applicant.fotoURL || avatarDefault),
      match: ['/candidatos/curriculum', '/postulantes/curriculum'],
      exactMatch: true,
    },
  ]

  const handleOnClick = item => {
    item.onClick && item.onClick()
    if (item.href && item.href !== location.pathname) {
      // Timeout para darle tiempo al ripple effect
      setTimeout(() => {
        const redirect = item.requireLogin && !applicant ? `/login?returnTo=${item.href}` : item.href
        setCurrent(item)
        dispatch(setFilterApplied([]))
        history.push(redirect)
        window.scrollTo(0, 0)
      }, 250)
    }
  }

  const ButtonWrapper = ({ title, children }) => {
    return title ? <>{children}</> : <FloatingButton>{children}</FloatingButton>
  }

  useEffect(() => {
    const urlFound = menu.find(
      item =>
        item.href === location.pathname ||
        (item.match && location.pathname.match(`${item.match.join('|')}${item.exactMatch ? '$' : ''}`))
    )
    if (urlFound) {
      setCurrent(urlFound.id)
    }
  }, [])

  return (
    <BottomNav isIos={isIos}>
      <SidebarMenu menuOpened={menuOpened} setMenuOpened={setMenuOpened} />

      {menu.map(item => {
        const isActive = current === item.id
        return (
          <BottomNavItem key={'nav-item-' + item.id} onClick={() => handleOnClick(item)} ripple={!item.onClick}>
            <ButtonWrapper title={item.title}>
              {item.imgUrl ? (
                <ProfileImg active={isActive} src={item.imgUrl} width={item.iconSize} height={item.iconSize} />
              ) : isActive ? (
                <Icon name={item.activeIcon} size={item.iconSize} color={colors.secondary.normal} />
              ) : (
                <Icon name={item.icon} size={item.iconSize} color={colors.grey48} />
              )}
              {item.title && <MenuItem active={isActive}>{item.title}</MenuItem>}
            </ButtonWrapper>
          </BottomNavItem>
        )
      })}
    </BottomNav>
  )
}

const state = ({ applicantStore }) => {
  return {
    applicant: applicantStore.applicant,
  }
}

export default connect(state)(BottomNavBar)
