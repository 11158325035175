import {
  CareerSiteActions,
  CareerSiteState,
  GET_CAREER_SITE,
  GET_CAREER_SITE_ERROR,
  GET_CAREER_SITE_SUCCESS,
  initialState,
} from '../types/career-site'
import { status } from '../types/status'

const reducers = (state: CareerSiteState = initialState, action: CareerSiteActions): CareerSiteState => {
  switch (action.type) {
    case GET_CAREER_SITE:
      return {
        ...state,
        careerSiteStates: status.loading,
      }
    case GET_CAREER_SITE_SUCCESS:
      return {
        ...state,
        careerSiteStates: status.success,
        careerSite: action.payload,
      }
    case GET_CAREER_SITE_ERROR:
      return {
        ...state,
        careerSiteStates: status.error,
      }
    default:
      return state
  }
}
export default reducers
