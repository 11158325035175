import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

const Metas = ({ careerSiteAccount }) => {
  const { t } = useTranslation()
  const pageTitle = t(`seoWordings.sitioEmpleo.title`, { name: careerSiteAccount })

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSiteAccount: careerSiteStore.careerSite?.account,
})

export default connect(states)(Metas)
