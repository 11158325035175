import React, { useEffect } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Select } from '@navent-jobs/ui-kit/components/select'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { StaticOption } from '../../../../../../../../redux/types/statics'
import { CustomText, Container } from './mixins'

interface DocumentoFieldProps {
  control: Control<object>
  options?: StaticOption[]
  handlerOptions: () => void
  errors?: any
}

export const DocumentoField = ({ control, options, handlerOptions, errors }: DocumentoFieldProps) => {
  const { t } = useTranslation()

  // Obtenemos los estaticos de ser necesario
  useEffect(() => {
    if (!options) {
      handlerOptions()
    }
  }, [])

  return (
    <Row noGutter>
      <Row noGutter xs={12}>
        <CustomText>{t('curriculum.datosPersonales.form.tipoDocTitle')}</CustomText>
      </Row>
      <div style={{ width: '100%' }}>
        <Row noGutter xs={12}>
          <Col noGutter xs={4}>
            {/* Tipo de documento */}
            <Select
              options={options ?? []}
              name="tipoDocumento"
              control={control}
              rules={{ required: true }}
              placeholder={t('curriculum.datosPersonales.form.tipoDocPlaceholder')}
              reactSelectOptions={{ isClearable: false, isSearchable: false }}
              errors={errors}
            />
          </Col>
          <Col noGutter>
            <Container>
              {/* Numero de documento */}
              <Controller
                name="numeroDocumento"
                control={control}
                rules={{ required: { value: true, message: '' } }}
                render={({ onChange, value }) => (
                  <Input
                    id="numeroDocumento"
                    name="numeroDocumento"
                    type="text"
                    rules={{ required: true }}
                    value={value}
                    onChange={onChange}
                    placeholder={t('curriculum.datosPersonales.form.numeroDocumentoPlaceholder')}
                    errors={{ ...errors }}
                  />
                )}
              />
            </Container>
          </Col>
        </Row>
      </div>
    </Row>
  )
}
