export const review = {
  section: '¿Trabajas o trabajaste en esta empresa?',
  info: 'Estos campos son obligatorios',
  title: '¿Qué calificación merece {{nameCompany}}?',
  claim: 'Califica esta empresa en los siguientes aspectos según tu experiencia personal',
  recomend: '¿Recomendarías trabajar en esta empresa?',
  terms: 'Al enviar esta calificación aceptas que tu opinión es fidedigna en base a tu experiencia personal',
  disclaimer: 'Tu calificación sobre la empresa será anónima',
  next: 'Continuar',
  send: 'Enviar',
  back: 'Volver',
  error: 'Hubo un error, vuelve a intentarlo.',
  optionType: {
    employee: 'Trabajo o trabajé',
    applicant: 'No trabajé',
  },
  comments: {
    applicantTitle: 'Tu calificación',
    recomendation: 'Recomienda esta empresa',
    noRecommendation: 'No recomienda esta empresa',
    disclaimer: 'Solo es visible para ti',
    options: {
      1: 'Quiero trabajar en esta empresa',
      2: 'Conozco personas que trabajaron aquí',
      3: 'Escuché comentarios sobre esta empresa',
      4: 'Participé de un proceso de selección',
    },
  },
  access: {
    section: 'Calificaciones y opiniones de {{nameCompany}}',
    title: '¿Cuál es tu opinión sobre esta empresa?',
    titleEmpty: 'Esta empresa todavía no tiene calificaciones para mostrar',
    titleReviewed: 'Todavía no hay información suficiente para mostrar calificaciones de esta empresa.',
    claim: 'Te invitamos a compartir tu opinión sobre esta empresa',
    button: 'Calificar empresa',
    buttonReviewed: 'Ya calificaste esta empresa',
    noReviews: 'Sin calificación',
  },
  confirmation: {
    title: 'Recibimos tu calificación sobre {{nameCompany}}',
    claim:
      'Gracias por enviar tu evaluación, esta información ayudará a los demás postulantes a conocer más sobre esta empresa.',
    warn: 'Estamos procesando tu evaluación. Es posible que tarde en mostrarse.',
    button: 'Listo',
  },
  comment: {
    section: 'Deja tu opinión',
    titleExample: 'Ej: Buen ambiente laboral',
    titleLabel: 'Título ',
    titleHelperText: 'Resume tu experiencia en un título claro',
    commentLabel: 'Comparte tu experiencia ',
    commentExample: 'Se vive un buen ambiente laboral y esto hace que como equipo trabajemos contentos...',
    commentHelperText: 'Cuentanos cómo es trabajar en esta empresa',
    exampleTitle: 'Cuéntanos sobre:',
    exampleOne: 'La cultura de la empresa',
    exampleTwo: 'Los líderes',
    exampleThree: 'Equipos de trabajo',
    exampleFour: 'Beneficios',
    exampleFive: 'Ventajas y/o desventajas del trabajo',
    suggestion:
      'Al compartir un comentario te pedimos que no uses un lenguaje ofensivo o agresivo, y no escribas tu información personal.',
  },
  result: {
    generalReviewTitle: 'Calificación general',
    categoryReviewTitle: 'Calificación por categoría',
    reviewBasedOn: 'Basado en {{quantity}} opiniones',
    buttonReview: 'Da tu opinión',
    recommendsPercentage: 'El <0>{{percentage}}%</0> de profesionales recomiendan trabajar en esta empresa',
    wantsToWork: '{{quantity}} personas quieren trabajar aquí',
    wantsToWorkPopover: '{{quantity}} personas quieren trabajar en esta empresa',
  },
  companies: {
    section: 'Califica una empresa',
    title: 'Elige una empresa en la que hayas trabajado y cuéntanos tu experiencia',
    input: '¿Qué empresa estás buscando?',
    alreadyReviewed: 'Ya calificaste a esa empresa. Te invitamos a calificar otra.',
    noResults: 'No se encontraron resultados',
  },
}
