const curriculum = {
  marketplace: {
    enabled: true,
    mobileView: true,
    benefits: {
      enabled: false,
    },
  },
}

export default curriculum
