import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../commons/Card'
import SectionWrapper from '../commons/SectionWrapper'
import { Main, IndustryContainer, DataContainer, DataLabel, DataValue, ReadMoreCustom } from './mixins'
import ComplementaryInfoPlaceholder from './placeholder'

interface ComplementaryInfoProps {
  company?
  isLoading?: boolean
  showDescription?: boolean
  children?: ReactElement
}

const ComplementaryInfo = ({ company, isLoading, children, showDescription = false }: ComplementaryInfoProps) => {
  const { t } = useTranslation()
  const industry = company?.industria?.nombre
  const provincia = company?.provincia?.nombre
  const ciudad = company?.ciudad
  const location = provincia || ciudad
  const employeesCount = company?.rangoEmpleados
  const { isTabletLandscape, isDesktop } = useScreenSize()
  const isLandscapeOrDesktop = isTabletLandscape || isDesktop

  return (
    <Main>
      {isLoading ? (
        <ComplementaryInfoPlaceholder showDescription={showDescription} />
      ) : (
        <>
          {industry || location || employeesCount ? (
            <>
              <SectionWrapper>
                <Card>
                  {industry && (
                    <IndustryContainer>
                      <DataLabel>{t('companySummary.complementaryInfo.industryLabel')} </DataLabel>
                      <DataValue>{industry}</DataValue>
                    </IndustryContainer>
                  )}

                  {location && (
                    <DataContainer>
                      <DataLabel>{t('companySummary.complementaryInfo.locationLabel')} </DataLabel>
                      <DataValue>
                        {ciudad}
                        {ciudad && provincia && ', '}
                        {provincia}
                      </DataValue>
                    </DataContainer>
                  )}

                  {employeesCount && (
                    <DataContainer>
                      <DataLabel>{t('companySummary.complementaryInfo.employeesCountLabel')} </DataLabel>
                      <DataValue>{`${employeesCount} empleados`}</DataValue>
                    </DataContainer>
                  )}

                  {isLandscapeOrDesktop && showDescription && company?.descripcion && (
                    <SectionWrapper>
                      <ReadMoreCustom
                        id="descriptionCompanyMobile"
                        numberOfLines={6}
                        readMore={<Icon name="icon-light-arrow-ios-down" />}
                        readLess={<Icon name="icon-light-arrow-ios-up" />}
                        variant="primary"
                        position="center"
                      >
                        {company?.descripcion}
                      </ReadMoreCustom>
                    </SectionWrapper>
                  )}
                </Card>
                {children}
                {!isLandscapeOrDesktop && showDescription && company?.descripcion && (
                  <SectionWrapper>
                    <Card>
                      <ReadMoreCustom
                        id="descriptionCompanyMobile"
                        numberOfLines={6}
                        readMore={<Icon name="icon-light-arrow-ios-down" />}
                        readLess={<Icon name="icon-light-arrow-ios-up" />}
                        variant="primary"
                        position="center"
                      >
                        {company?.descripcion}
                      </ReadMoreCustom>
                    </Card>
                  </SectionWrapper>
                )}
              </SectionWrapper>
            </>
          ) : (
            children
          )}
        </>
      )}
    </Main>
  )
}
export default ComplementaryInfo
