const googleAds = {
  list: {
    enabled: false,
  },
  pagination: {
    enabled: false,
  },
  ficha: {
    enabled: false,
  },
}

export default googleAds
