import {
  AreaAdsActions,
  AreaAdsState,
  GET_AREA_ADS,
  GET_AREA_ADS_ERROR,
  GET_AREA_ADS_SUCCESS,
  initialState,
} from '../types/area-ads'
import { status } from '../types/status'

const reducers = (state: AreaAdsState = initialState, action: AreaAdsActions): AreaAdsState => {
  switch (action.type) {
    case GET_AREA_ADS:
      return {
        ...state,
        areaAdsStates: status.loading,
      }
    case GET_AREA_ADS_ERROR:
      return {
        ...state,
        areaAdsStates: status.error,
      }
    case GET_AREA_ADS_SUCCESS:
      return {
        ...state,
        areaAdsStates: status.success,
        areaAds: action.payload,
      }
    default:
      return state
  }
}
export default reducers
