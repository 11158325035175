const sortEmpresas = {
  enabled: false,
  noFollow: false,
  initValue: 'calificacion',
  items: [
    {
      key: 'calificacion',
      to: '?calificacion=true',
      label: 'Calificación',
    },
    {
      key: 'empleos',
      to: '?empleos=true',
      label: 'Empleos',
    },
  ],
}

export default sortEmpresas
