import React, { ReactElement } from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import {
  TitleWrapper,
  CustomModalTitle,
  SidebarTitle,
  SidebarShadow,
  SidebarContent,
  SidebarReminder,
  SidebarFooter,
  ModalReminderText,
  ModalContent,
} from './mixins'

interface ModalTitleProps {
  title?: string | ReactElement
  reminder?: string
}

const ModalTitle = ({ title, reminder }: ModalTitleProps) => {
  if (!title && !reminder) {
    return null
  }

  return (
    <TitleWrapper>
      {title && <CustomModalTitle>{title}</CustomModalTitle>}
      {reminder && <ModalReminderText>{reminder}</ModalReminderText>}
    </TitleWrapper>
  )
}

interface ModalSidebarProps {
  title?: string | ReactElement
  reminder?: string
  visible: boolean
  width: number
  onClose: () => void
  children: React.ReactNode
  footer: ReactElement
}

export const ModalSidebar = ({ title, reminder, visible, width, onClose, children, footer }: ModalSidebarProps) => {
  const { isMobile } = useWindowSize()

  return isMobile ? (
    <Sidebar position="left" closeType="back" visible={visible} handleClose={onClose}>
      <SidebarShadow />

      {title && <SidebarTitle>{title}</SidebarTitle>}

      <SidebarContent>
        {reminder && <SidebarReminder>{reminder}</SidebarReminder>}
        {children}
      </SidebarContent>

      <SidebarFooter>{footer}</SidebarFooter>
    </Sidebar>
  ) : (
    <Modal
      skin="with-form"
      width={width}
      visible={visible}
      onClose={onClose}
      title={<ModalTitle title={title} reminder={reminder} />}
      footer={footer}
    >
      <ModalContent>{children}</ModalContent>
    </Modal>
  )
}
