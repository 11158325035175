import React from 'react'
import { useTranslation } from 'react-i18next'

// mixins
import { Anchors, LinkCustom } from './mixins'

const Component = ({ tabs, visibleId, setVisibleId }) => {
  const { t } = useTranslation()

  return (
    <Anchors>
      {tabs.map(item => {
        return (
          item && (
            <LinkCustom
              href={`#${item.key}`}
              onClick={e => {
                e.preventDefault()
                setVisibleId(item.key)
              }}
              isActive={visibleId === item.key}
            >
              {t(item.label)}
            </LinkCustom>
          )
        )
      })}
    </Anchors>
  )
}

export default Component
