import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { IconCustom } from './mixins'
import { CrossSiteDataLayers } from '../../gtm-events-cross-site'

interface MenuItemIconProps {
  iconName: string
}

const MenuItemIcon = ({ iconName }: MenuItemIconProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  return <IconCustom name={iconName} size="16" color={colors.grey60} />
}

export const dropdownSitesItems = {
  BMAR: [],
  ZJAR: [],
  BMCL: [
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      before: <MenuItemIcon iconName="icon-light-graduation-cap" />,
      onClick: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      before: <MenuItemIcon iconName="icon-light-briefcase" />,
      onClick: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
    {
      href: '/empleos-discapacitados-apto.html?landing-empleos-inclusivos=true',
      content: 'Empleos inclusivos',
      key: 'empleos-inclusivos',
      before: <MenuItemIcon iconName="icon-light-discapacity" />,
      onClick: () => CrossSiteDataLayers.header('Empleos inclusivos'),
    },
    {
      href: '/empleos-part-time.html?landing-empleos-part-time=true',
      content: 'Empleos Part-time',
      key: 'empleos-part-time',
      before: <MenuItemIcon iconName="icon-light-clock" />,
      onClick: () => CrossSiteDataLayers.header('Empleos part-time'),
    },
    {
      href: '/empleos-practica-profesional.html?landing-practicas-profesionales=true',
      content: 'Prácticas profesionales',
      key: 'practicas-profesionales',
      before: <MenuItemIcon iconName="icon-light-briefcase" />,
      onClick: () => CrossSiteDataLayers.header('Practicas profesionales'),
    },
  ],
  BMEC: [],
  BMMX: [],
  BMPA: [],
  BMPE: [],
  BMVE: [],
}
