import { merge } from 'lodash'
import all from '../all'
import userPremium from './user-premium'
import pdaPago from './pda-pago'
import curriculum from './curriculum'
import marketplace from './marketplace'
import products from './products'
import cursos from './cursos'
import sortEmpresas from './sortEmpresas'
import gptw from './gptw'
import avisosFake from './avisos-fake'

const bmar = {
  ...all,
  userPremium: { ...all.userPremium, ...userPremium },
  pdaPago,
  curriculum: merge({}, all.curriculum, curriculum),
  marketplace: merge({}, all.marketplace, marketplace),
  products: merge({}, all.products, products),
  cursos: merge({}, all.cursos, cursos),
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  gptw: merge({}, all.gptw, gptw),
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default bmar
