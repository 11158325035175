import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { Link } from '@navent-jobs/ui-kit/components/link'

// mixins
import { ButtonContainer, LinkCustom } from './mixins'

// store
import {
  setFilterSettings,
  setFiltersDefault,
  setFiltersDeleteAll,
  setFiltersHide,
} from '../../../../redux/actions/filters'
import { ads, facetBanner, setActiveLanding } from '../../../../redux/actions/listado-avisos'

// constants
import { SITE_ID } from '../../../../constants'
import { DataLayers } from '../../../../constants/gtm-events-button-jovenes-profesionales'
import settingsBanner from '../../../../constants/banner-landings'

const actionJovenesProfesionalesButton = dispatch => {
  dispatch(setFilterSettings())
  dispatch(setActiveLanding('jovenes-profesionales'))
  dispatch(
    setFiltersDefault({
      name: 'nivel_laboral',
      option: {
        label: 'Junior',
        value: 'junior',
      },
    })
  )
  dispatch(setFiltersDeleteAll(false))
  dispatch(setFiltersHide({ type: 'nivel_laboral' }))
  dispatch(
    facetBanner({
      enabled: settingsBanner[SITE_ID || 'BMAR']?.jovenesProfesionales?.enabled,
      title: settingsBanner[SITE_ID || 'BMAR']?.jovenesProfesionales?.title,
      bannerImage: settingsBanner[SITE_ID || 'BMAR']?.jovenesProfesionales?.imageListado,
    })
  )
  dispatch(ads(false))
}

export const LinkJovenesProfesionales = () => {
  const dispatch = useDispatch()

  return (
    <ButtonContainer>
      <LinkCustom onClick={() => actionJovenesProfesionalesButton(dispatch)}>Jóvenes profesionales</LinkCustom>
    </ButtonContainer>
  )
}

const ButtonJovenesProfesionales = ({ filtersSettings }) => {
  const { pathname } = window.location

  const onClickHandler = () => {
    DataLayers.JovenesProfesionalesOnClick(pathname)
  }

  useEffect(() => {
    if (filtersSettings === null) {
      DataLayers.JovenesProfesionalesOnLoad(pathname)
    }
  }, [filtersSettings])

  return (
    <ButtonContainer onClick={onClickHandler}>
      <Link
        href="/empleos-seniority-junior.html?landing-jovenes-profesionales=true"
        linkoptions={{
          type: 'fill',
          variant: 'secondary',
          native: false,
        }}
      >
        Jóvenes profesionales
      </Link>
    </ButtonContainer>
  )
}

const states = ({ filtersStore }) => ({
  filtersSettings: filtersStore.filtersSettings,
})

export default connect(states)(ButtonJovenesProfesionales)
