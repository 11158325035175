import styled from 'styled-components'

export const StepContent = styled.div`
  display: flex;
  height: 8px;
`

export const StepItem = styled.div`
  background-color: ${({ complete, theme }) =>
    complete ? `${theme.colors.tertiary.lighten}` : `${theme.colors.tertiary.ultraLighten}`};
  transition: all 1s ease-in-out;
  flex: 1 1 auto;
`
