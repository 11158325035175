import React from 'react'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import CardPlaceholder from '../../card/CardPlaceholder'
import Content from './components/content'

// mixins
import { CardComponentWrapper, LinkCustom } from './mixins'

const CARD_HEIGHT = 252

const CardCompany = props => {
  const { isMobile } = useWindowSize()
  const { company, loading, settingsCompanyURL, sameCardHeight } = props
  const {
    companyId,
    logo,
    name,
    jobPostingsQuantity,
    industry,
    province,
    reviewAverage,
    gptw,
    following,
    activeSearch,
  } = company

  if (loading) {
    return <CardPlaceholder />
  }

  return (
    <CardComponentWrapper height={sameCardHeight ? CARD_HEIGHT : undefined}>
      <LinkCustom
        href={settingsCompanyURL.href ? settingsCompanyURL.href : settingsCompanyURL.link}
        linkoptions={{ native: false, variant: 'black', target: isMobile ? '_self' : '_blank' }}
      >
        <Content
          companyId={companyId}
          logo={logo}
          name={name}
          jobPostingsQuantity={jobPostingsQuantity}
          industry={industry}
          location={province}
          reviewAverage={reviewAverage}
          gptw={gptw}
          following={following}
          activeSearch={activeSearch}
        />
      </LinkCustom>
    </CardComponentWrapper>
  )
}

export default CardCompany
