import styled from 'styled-components'

export const ContainerLoader = styled.div`
  text-align: center;
`
export const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

export const AlertTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    margin-bottom: 12px;
  }
  margin-bottom: 14px;

  color: ${({ theme }) => theme.colors.secondary.normal};
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    margin-bottom: 12px;
  }
  margin-bottom: 14px;
`

export const AlertSection = styled.div``
export const AlertDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  max-width: 566px;
  margin-bottom: 12px;
  strong {
    margin-right: 4px;
  }
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    max-width: 224px;
  }
`
export const TextContainer = styled.div``
export const AlertDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey8};
  margin: 0px 0px 12px;
`

export const ActionEdit = styled.div`
  cursor: pointer;
  margin-right: 10px;
`

export const ActionRemove = styled.div`
  cursor: pointer;
`

export const TitleAlertContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IconsContainer = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
`
