import { status } from '../types/status'
import {
  ConfirmReferenceActions,
  ConfirmReferenceState,
  CONFIRM_REFERENCE,
  CONFIRM_REFERENCE_ERROR,
  CONFIRM_REFERENCE_SUCCESS,
  initialState,
  UPDATE_REFERENCE_STATUS,
  UPDATE_REFERENCE_STATUS_ERROR,
  UPDATE_REFERENCE_STATUS_SUCCESS,
} from '../types/confirm-reference'

const reducers = (
  state: ConfirmReferenceState = initialState,
  action: ConfirmReferenceActions
): ConfirmReferenceState => {
  switch (action.type) {
    case CONFIRM_REFERENCE:
      return {
        ...state,
        getReference: {
          ...state.getReference,
          status: status.loading,
        },
      }
    case CONFIRM_REFERENCE_SUCCESS:
      return {
        ...state,
        getReference: {
          ...state.getReference,
          value: action.payload,
          status: status.success,
        },
      }
    case CONFIRM_REFERENCE_ERROR:
      return {
        ...state,
        getReference: {
          ...state.getReference,
          status: status.error,
        },
      }

    case UPDATE_REFERENCE_STATUS:
      return {
        ...state,
        update: {
          ...state.update,
          status: status.loading,
        },
      }
    case UPDATE_REFERENCE_STATUS_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          status: status.success,
        },
      }
    case UPDATE_REFERENCE_STATUS_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          status: status.error,
        },
      }
    default:
      return state
  }
}

export default reducers
