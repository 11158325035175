import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { GET_BREADCRUMBS, GET_BREADCRUMBS_SUCCESS, GET_BREADCRUMBS_ERROR } from '../types/breadcrumbs'

function* getBreadcrumbs({ payload }) {
  try {
    const links = yield call(services.breadcrumbs.createBreadcrumbLinks, payload)
    const json = yield call(services.breadcrumbs.makeBreadcrumbsSEOJSON, links)
    yield put({ type: GET_BREADCRUMBS_SUCCESS, payload: { links, json } })
  } catch (error) {
    yield put({ type: GET_BREADCRUMBS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_BREADCRUMBS, getBreadcrumbs)
}
