import { URLData } from '@navent-jobs/interlinking'

export interface KeyWord extends URLData {
  keyword?: string
}

interface History extends KeyWord {
  url?: string
}
export class SearchCompaniesStack {
  private static instance: SearchCompaniesStack

  private storeKey = 'searchCompaniesHistory'

  private historyLength

  private queue: string[] = []

  private constructor({ length = 5 }: { length?: number } = {}) {
    const storedString = localStorage.getItem(this.storeKey)
    if (storedString) this.queue = JSON.parse(storedString as any)
    this.historyLength = length
  }

  public static getInstance(): SearchCompaniesStack {
    if (!SearchCompaniesStack.instance) {
      SearchCompaniesStack.instance = new SearchCompaniesStack()
    }
    return SearchCompaniesStack.instance
  }

  public push(search: KeyWord) {
    const lastSearch = {
      keyword: search.keyword,
      busqueda: search.busqueda,
    }

    if (search && search.busqueda)
      this.queue = Array.from(new Set([JSON.stringify({ ...lastSearch }), ...this.queue])).splice(0, this.historyLength)
    localStorage.setItem(this.storeKey, JSON.stringify(this.queue))
  }

  // TODO: eliminar a futuro, este método se utiliza para evitar problemas de compatibilidad con la información almacenada en el localStorage
  private isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  public getHistory(): History[] {
    return this.queue.filter((e) => this.isJson(e)).map((e) => JSON.parse(e))
  }
}
