import React, { useEffect } from 'react'
import Pagination from '@navent-jobs/ui-kit/components/pagination-fluid'

// repositories
import { makeFichaAvisoURL } from '@navent-jobs/interlinking'

// mixins
import { AvisoCard, CenterContainer } from './mixins'

// components
import ListadoAvisosPlaceholder from '../../../../../listado-avisos/components/card/ListadoAvisosPlaceholder'

const CONFIDENCIAL = 'Confidencial'
const TOTAL_CARDS_PLACEHOLDER = 4

const ListadoAvisos = ({ avisos, pagination, loading, currentPage, queryParams }) => {
  const onClickAviso = (event, url) => {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      window.open(url, '_blank')
    }
  }

  useEffect(() => {
    if (avisos && document.documentElement.scrollTo) {
      document.documentElement.scrollTo(0, 0)
    }
  }, [avisos, document])

  if (loading) {
    return <ListadoAvisosPlaceholder total={TOTAL_CARDS_PLACEHOLDER} />
  }

  return (
    <>
      {avisos &&
        avisos.map((aviso, index) => {
          return (
            <>
              <AvisoCard
                key={aviso.id}
                aviso={aviso}
                index={index}
                ubicacion="Mis avisos guardados"
                disableLink={false}
                onClick={({ event }) =>
                  onClickAviso(
                    event,
                    makeFichaAvisoURL({
                      ...aviso,
                      empresa: { denominacion: aviso.empresa, confidencial: aviso.empresa === CONFIDENCIAL },
                    })
                  )
                }
                link={makeFichaAvisoURL({
                  ...aviso,
                  empresa: { denominacion: aviso.empresa, confidencial: aviso.empresa === CONFIDENCIAL },
                })}
              />
            </>
          )
        })}

      <CenterContainer>
        <Pagination
          {...pagination}
          currentPage={Number(currentPage) + 1}
          currentUrl={window.location.pathname}
          states={{ loading }}
          queryParams={queryParams}
        />
      </CenterContainer>
    </>
  )
}

export default ListadoAvisos
