import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const ComponentContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 39px;
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 194px;
`

export const CustomLink = styled(Link)`
  font-weight: 600;
`
