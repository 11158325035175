import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  width: 100%;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.grey60};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`
