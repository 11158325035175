import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'

export const InputContainer = styled.div`
  padding-right: 0;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding-right: 16px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    padding-right: 0;
  }
`

export const CustomInput = styled(Input)`
  width: 100%;
`

export const CustomButton = styled(Button)`
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top: 0;
  }
`

export const CustomContainer = styled(ContainerFluid)`
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-top: 0;
  }
`
