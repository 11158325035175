import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_APPLICANT_REVIEW,
  GET_APPLICANT_REVIEW_SUCCESS,
  GET_APPLICANT_REVIEW_ERROR,
  SEND_REVIEW,
  SEND_REVIEW_SUCCESS,
  SEND_REVIEW_ERROR,
  SEND_REVIEW_NO_EMPLOYEE,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_ERROR,
  GET_REVIEWS_COMPANY,
  GET_REVIEWS_COMPANY_SUCCESS,
  GET_REVIEWS_COMPANY_ERROR,
} from '../types/review'

function* getApplicantReview({ payload }) {
  try {
    const result = yield call(services.review.getReviews, payload)
    const applicantReview = result
      ? {
          createdAt: result.createdAt,
          rating: result.ratings.find(r => r.id === 1)?.score,
          answer: result.questionAnswers?.find(r => r.questionId === 1)?.optionId,
          recommends: result.recommends,
          comment: result.comment
            ? {
                title: result.comment.title,
                description: result.comment.description,
              }
            : null,
        }
      : null
    yield put({ type: GET_APPLICANT_REVIEW_SUCCESS, payload: applicantReview })
  } catch (error) {
    yield put({ type: GET_APPLICANT_REVIEW_ERROR, error })
  }
}

function* sendReview({ payload }) {
  try {
    yield call(services.review.sendReview, payload)
    yield put({ type: SEND_REVIEW_SUCCESS, payload: null })
    const applicantReview = {
      createdAt: new Date(),
      rating: payload.body.scoresByCategory.find(r => r.id === 1)?.score,
      recommends: payload.body.recommends,
    }
    yield put({ type: GET_APPLICANT_REVIEW_SUCCESS, payload: applicantReview })
  } catch (error) {
    yield put({ type: SEND_REVIEW_ERROR, error })
  }
}

function* sendReviewNoEmployee({ payload }) {
  try {
    yield call(services.review.sendReviewNoEmployee, payload)
    yield put({ type: SEND_REVIEW_SUCCESS, payload: null })
    const applicantReview = {
      createdAt: new Date(),
      rating: payload.body.scoresByCategory.find(r => r.id === 1)?.score,
    }
    yield put({ type: GET_APPLICANT_REVIEW_SUCCESS, payload: applicantReview })
  } catch (error) {
    yield put({ type: SEND_REVIEW_ERROR, error })
  }
}

function* getCategories() {
  try {
    const result = yield call(services.review.getCategories)
    yield put({ type: GET_CATEGORIES_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CATEGORIES_ERROR, error })
  }
}

function* getQuestions({ payload }) {
  try {
    const result = yield call(services.review.getQuestions, payload)
    yield put({ type: GET_QUESTIONS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_QUESTIONS_ERROR, error })
  }
}

function* getReviewsCompany({ payload }) {
  try {
    const result = yield call(services.review.getReviewsCompany, payload)
    yield put({ type: GET_REVIEWS_COMPANY_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_REVIEWS_COMPANY_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* review() {
  yield takeLatest<any>(GET_APPLICANT_REVIEW, getApplicantReview)
  yield takeLatest<any>(SEND_REVIEW, sendReview)
  yield takeLatest<any>(SEND_REVIEW_NO_EMPLOYEE, sendReviewNoEmployee)
  yield takeLatest<any>(GET_CATEGORIES, getCategories)
  yield takeLatest<any>(GET_QUESTIONS, getQuestions)
  yield takeLatest<any>(GET_REVIEWS_COMPANY, getReviewsCompany)
}
