import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LOCATION_DEFAULT } from '@navent-jobs/config'

// components
import { Button } from '@navent-jobs/ui-kit/components/button'
import { useSearchBar } from '../searchbarLogic'
import KeywordSelect from '../components/KeywordSelect'
import LocationSelect from '../components/LocationSelect'

// mixins
import {
  SearchbarContainer,
  KeywordFieldContainer,
  LocationFieldContainer,
  ButtonContainer,
  FieldIcon,
  Form,
} from './mixins'

// constants
import { DataLayers } from '../../../constants/gtm-events-site-search'

const Component = ({ recentSearches: recentSearchesService, availableLocations, onLocations, optionsOnTop }) => {
  const [isActive, setActive, setKeyword, setLocation, shotSearch, locations, recentSearches, location] = useSearchBar(
    availableLocations,
    recentSearchesService
  )
  const { t } = useTranslation()
  const [keywordSearch, setKeywordSearch] = useState<{ keyword?: string; label: string; value: string } | undefined>(
    undefined
  )
  const [manualChange, setManualChange] = useState(false)

  const handleMetric = (searchTerm, locationTerm, recentSearch) => {
    DataLayers.siteSearch(searchTerm, locationTerm, '/', recentSearch)
  }

  const handleSearch = () => {
    shotSearch()
    handleMetric(keywordSearch, location, false)
  }

  useEffect(() => {
    if (manualChange) {
      setManualChange(false)
      handleSearch()
    }
  }, [manualChange])

  return (
    <SearchbarContainer>
      <Form isActive={isActive}>
        <KeywordFieldContainer>
          <FieldIcon size="24" color="#0A26EE" name="icon-light-search" />
          <KeywordSelect
            options={recentSearches}
            handlerOnFocus={setActive}
            handlerOnBlur={setActive}
            handlerOnChange={setKeyword}
            handlerOnEnter={handleSearch}
            showOptions
            optionsOnTop={optionsOnTop}
            keyword={keywordSearch}
            setKeyword={setKeywordSearch}
            handleMetric={handleMetric}
          />
        </KeywordFieldContainer>

        <LocationFieldContainer>
          <FieldIcon size="24" color="#0A26EE" name="icon-light-location-pin" />
          <LocationSelect
            options={locations}
            handlerOnFocus={onLocations}
            handlerOnBlur={setActive}
            handlerOnChange={value => {
              setLocation(value)
              setManualChange(true)
            }}
            defaultValue={LOCATION_DEFAULT}
            value={location}
          />
        </LocationFieldContainer>

        <ButtonContainer>
          <Button
            id="buscarTrabajo"
            buttonoptions={{ variant: 'primary', block: true }}
            // buttonoptions={{ variant: 'primary', size: 'small', block: true }}
            type="button"
            onClick={handleSearch}
          >
            {t('Buscar trabajo')}
          </Button>
        </ButtonContainer>
      </Form>
    </SearchbarContainer>
  )
}

export default Component
