import styled from 'styled-components'

export const EmpleosTotalText = styled('div')`
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 50%;
    * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`

export const Total = styled('span')`
  color: ${({ theme }) => theme.colors.primary.normal};
`
