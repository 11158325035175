export const alertas = {
  success: {
    textAjustes: 'Podés gestionar tus alertas desde Ajustes.',
  },
  form: {
    description:
      'Recibí por email los nuevos avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda.',
    nombrePlaceholder: 'Escribí un nombre',
    frecuenciaPlaceholder: 'Elegí una opción',
    errorDuplicada: 'Ya tenés una alerta creada con las mismas características. Podés gestionarla desde ',
    errorLimite: 'Ya creaste tus 5 alertas disponibles. Para agregar una nueva, eliminá una anterior desde ',
  },
  panel: {
    emptyStateTitle: 'No tenés alertas de empleo guardadas',
    emptyStateDescription:
      'Para recibir ofertas de trabajo por email, creá una alerta después de realizar una búsqueda.',
  },
  modalEliminar: {
    title: '¿Querés eliminar esta alerta?',
  },
}
