import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const GET_ESTADO_CIVIL = 'GET_ESTADO_CIVIL'
export const GET_ESTADO_CIVIL_SUCCESS = 'GET_ESTADO_CIVIL_SUCCESS'
export const GET_ESTADO_CIVIL_ERROR = 'GET_ESTADO_CIVIL_ERROR'

export const GET_PAISES = 'GET_PAISES'
export const GET_PAISES_SUCCESS = 'GET_PAISES_SUCCESS'
export const GET_PAISES_ERROR = 'GET_PAISES_ERROR'

export const GET_TIPO_DOCUMENTO = 'GET_TIPO_DOCUMENTO'
export const GET_TIPO_DOCUMENTO_SUCCESS = 'GET_TIPO_DOCUMENTO_SUCCESS'
export const GET_TIPO_DOCUMENTO_ERROR = 'GET_TIPO_DOCUMENTO_ERROR'

export const GET_GENEROS = 'GET_GENEROS'
export const GET_GENEROS_SUCCESS = 'GET_GENEROS_SUCCESS'
export const GET_GENEROS_ERROR = 'GET_GENEROS_ERROR'

export const GET_TIPOS_LICENCIAS = 'GET_TIPOS_LICENCIAS'
export const GET_TIPOS_LICENCIAS_SUCCESS = 'GET_TIPOS_LICENCIAS_SUCCESS'
export const GET_TIPOS_LICENCIAS_ERROR = 'GET_TIPOS_LICENCIAS_ERROR'

export const GET_PROVINCIAS = 'GET_PROVINCIAS'
export const GET_PROVINCIAS_SUCCESS = 'GET_PROVINCIAS_SUCCESS'
export const GET_PROVINCIAS_ERROR = 'GET_PROVINCIAS_ERROR'

export const GET_LOCALIDADES = 'GET_LOCALIDADES'
export const GET_LOCALIDADES_SUCCESS = 'GET_LOCALIDADES_SUCCESS'
export const GET_LOCALIDADES_ERROR = 'GET_LOCALIDADES_ERROR'

export const GET_INSTITUCIONES_EDUCATIVAS = 'GET_INSTITUCIONES_EDUCATIVAS'
export const GET_INSTITUCIONES_EDUCATIVAS_SUCCESS = 'GET_INSTITUCIONES_EDUCATIVAS_SUCCESS'
export const GET_INSTITUCIONES_EDUCATIVAS_ERROR = 'GET_INSTITUCIONES_EDUCATIVAS_ERROR'

export const GET_TIPOS_ESTUDIO = 'GET_TIPOS_ESTUDIO'
export const GET_TIPOS_ESTUDIO_SUCCESS = 'GET_TIPOS_ESTUDIO_SUCCESS'
export const GET_TIPOS_ESTUDIO_ERROR = 'GET_TIPOS_ESTUDIO_ERROR'

export const GET_AREAS_ESTUDIO = 'GET_AREAS_ESTUDIO'
export const GET_AREAS_ESTUDIO_SUCCESS = 'GET_AREAS_ESTUDIO_SUCCESS'
export const GET_AREAS_ESTUDIO_ERROR = 'GET_AREAS_ESTUDIO_ERROR'

export const GET_ESTADOS_ESTUDIO = 'GET_ESTADOS_ESTUDIO'
export const GET_ESTADOS_ESTUDIO_SUCCESS = 'GET_ESTADOS_ESTUDIO_SUCCESS'
export const GET_ESTADOS_ESTUDIO_ERROR = 'GET_ESTADOS_ESTUDIO_ERROR'

export const GET_IDIOMAS = 'GET_IDIOMAS'
export const GET_IDIOMAS_SUCCESS = 'GET_IDIOMAS_SUCCESS'
export const GET_IDIOMAS_ERROR = 'GET_IDIOMAS_ERROR'

export const GET_NIVELES_IDIOMA = 'GET_NIVELES_IDIOMA'
export const GET_NIVELES_IDIOMA_SUCCESS = 'GET_NIVELES_IDIOMA_SUCCESS'
export const GET_NIVELES_IDIOMA_ERROR = 'GET_NIVELES_IDIOMA_ERROR'

export const GET_AREAS = 'GET_AREAS'
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS'
export const GET_AREAS_ERROR = 'GET_AREAS_ERROR'

export const GET_SUBAREAS = 'GET_SUBAREAS'
export const GET_SUBAREAS_SUCCESS = 'GET_SUBAREAS_SUCCESS'
export const GET_SUBAREAS_ERROR = 'GET_SUBAREAS_ERROR'

export const GET_INDUSTRIAS = 'GET_INDUSTRIAS'
export const GET_INDUSTRIAS_SUCCESS = 'GET_INDUSTRIAS_SUCCESS'
export const GET_INDUSTRIAS_ERROR = 'GET_INDUSTRIAS_ERROR'

export const GET_NIVEL_PUESTO = 'GET_NIVEL_PUESTO'
export const GET_NIVEL_PUESTO_SUCCESS = 'GET_NIVEL_PUESTO_SUCCESS'
export const GET_NIVEL_PUESTO_ERROR = 'GET_NIVEL_PUESTO_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

export interface StaticOption {
  value: number
  label: string
}

export interface GeneroOption {
  value: string
  label: string
}

export interface LicenciaDto {
  id: number
  denominacion: string
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */

export interface StaticState {
  tiposLicencias: LicenciaDto[] | []
  estadoCivil?: StaticOption[]
  paises?: StaticOption[]
  provincias?: { [idPais: number]: StaticOption[] }
  localidades?: { [idProvincia: number]: StaticOption[] }
  tipoDocumento?: StaticOption[]
  generos?: GeneroOption[]
  institucionesEducativas?: StaticOption[]
  tiposEstudio?: StaticOption[]
  areasEstudio?: StaticOption[]
  estadosEstudio?: StaticOption[]
  idiomas?: StaticOption[]
  nivelesIdioma: {
    items: StaticOption[] | null
    status: StatusType
  }
  areas?: StaticOption[]
  subareas?: StaticOption[]
  industrias?: StaticOption[]
  nivelesPuesto?: StaticOption[]
  loading: boolean
  error: boolean
}

export const initialState: StaticState = {
  loading: false,
  error: false,
  nivelesIdioma: {
    items: null,
    status: status.initial,
  },
  tiposLicencias: [],
}

export interface GetEstadoCivil {
  type: typeof GET_ESTADO_CIVIL
  payload: null
}

export interface GetEstadoCivilSuccess {
  type: typeof GET_ESTADO_CIVIL_SUCCESS
  payload: StaticOption[]
}

export interface GetEstadoCivilError {
  type: typeof GET_ESTADO_CIVIL_ERROR
  payload: null
}

export interface GetPaises {
  type: typeof GET_PAISES
  payload: null
}

export interface GetPaisesSuccess {
  type: typeof GET_PAISES_SUCCESS
  payload: StaticOption[]
}

export interface GetPaisesError {
  type: typeof GET_PAISES_ERROR
  payload: null
}

export interface GetTipoDocumento {
  type: typeof GET_TIPO_DOCUMENTO
  payload: null
}

export interface GetTipoDocumentoSuccess {
  type: typeof GET_TIPO_DOCUMENTO_SUCCESS
  payload: StaticOption[]
}

export interface GetTipoDocumentoError {
  type: typeof GET_TIPO_DOCUMENTO_ERROR
  payload: null
}

export interface GetGeneros {
  type: typeof GET_GENEROS
  payload: null
}

export interface GetGenerosSuccess {
  type: typeof GET_GENEROS_SUCCESS
  payload: GeneroOption[]
}

export interface GetGenerosError {
  type: typeof GET_GENEROS_ERROR
  payload: null
}

export interface GetTiposLicencias {
  type: typeof GET_TIPOS_LICENCIAS
  payload: null
}

export interface GetTiposLicenciasSuccess {
  type: typeof GET_TIPOS_LICENCIAS_SUCCESS
  payload: LicenciaDto[]
}

export interface GetTiposLicenciasError {
  type: typeof GET_TIPOS_LICENCIAS_ERROR
  payload: null
}

export interface GetProvincias {
  type: typeof GET_PROVINCIAS
  payload: number
}

export interface GetProvinciasSuccess {
  type: typeof GET_PROVINCIAS_SUCCESS
  payload: { [idPais: number]: StaticOption[] }
}

export interface GetProvinciasError {
  type: typeof GET_PROVINCIAS_ERROR
  payload: null
}

export interface GetLocalidades {
  type: typeof GET_LOCALIDADES
  payload: number
}

export interface GetLocalidadesSuccess {
  type: typeof GET_LOCALIDADES_SUCCESS
  payload: { [idProvincia: number]: StaticOption[] }
}

export interface GetLocalidadesError {
  type: typeof GET_LOCALIDADES_ERROR
  payload: null
}

export interface GetInstitucionesEducativas {
  type: typeof GET_INSTITUCIONES_EDUCATIVAS
  payload: string
}

export interface GetInstitucionesEducativasSuccess {
  type: typeof GET_INSTITUCIONES_EDUCATIVAS_SUCCESS
  payload: StaticOption[]
}

export interface GetInstitucionesEducativasError {
  type: typeof GET_INSTITUCIONES_EDUCATIVAS_ERROR
  payload: null
}

export interface GetTiposEstudio {
  type: typeof GET_TIPOS_ESTUDIO
  payload: string
}

export interface GetTiposEstudioSuccess {
  type: typeof GET_TIPOS_ESTUDIO_SUCCESS
  payload: StaticOption[]
}

export interface GetTiposEstudioError {
  type: typeof GET_TIPOS_ESTUDIO_ERROR
  payload: null
}

export interface GetAreasEstudio {
  type: typeof GET_AREAS_ESTUDIO
  payload: null
}

export interface GetAreasEstudioSuccess {
  type: typeof GET_AREAS_ESTUDIO_SUCCESS
  payload: StaticOption[]
}

export interface GetAreasEstudioError {
  type: typeof GET_AREAS_ESTUDIO_ERROR
  payload: null
}

export interface GetEstadosEstudio {
  type: typeof GET_ESTADOS_ESTUDIO
  payload: null
}

export interface GetEstadosEstudioSuccess {
  type: typeof GET_ESTADOS_ESTUDIO_SUCCESS
  payload: StaticOption[]
}

export interface GetEstadosEstudioError {
  type: typeof GET_ESTADOS_ESTUDIO_ERROR
  payload: null
}

export interface GetIdiomas {
  type: typeof GET_IDIOMAS
  payload: null
}

export interface GetIdiomasSuccess {
  type: typeof GET_IDIOMAS_SUCCESS
  payload: StaticOption[]
}

export interface GetIdiomasError {
  type: typeof GET_IDIOMAS_ERROR
  payload: null
}

export interface GetNivelesIdioma {
  type: typeof GET_NIVELES_IDIOMA
  payload: null
}

export interface GetNivelesIdiomaSuccess {
  type: typeof GET_NIVELES_IDIOMA_SUCCESS
  payload: StaticOption[]
}

export interface GetNivelesIdiomaError {
  type: typeof GET_NIVELES_IDIOMA_ERROR
  payload: null
}

export interface GetAreas {
  type: typeof GET_AREAS
  payload: number
}

export interface GetAreasSuccess {
  type: typeof GET_AREAS_SUCCESS
  payload: StaticOption[]
}

export interface GetAreasError {
  type: typeof GET_AREAS_ERROR
  payload: null
}

export interface GetSubareas {
  type: typeof GET_SUBAREAS
  payload: number
}

export interface GetSubareasSuccess {
  type: typeof GET_SUBAREAS_SUCCESS
  payload: StaticOption[]
}

export interface GetSubareasError {
  type: typeof GET_SUBAREAS_ERROR
  payload: null
}

export interface GetIndustrias {
  type: typeof GET_INDUSTRIAS
  payload: null
}

export interface GetIndustriasSuccess {
  type: typeof GET_INDUSTRIAS_SUCCESS
  payload: StaticOption[]
}

export interface GetIndustriasError {
  type: typeof GET_INDUSTRIAS_ERROR
  payload: null
}

export interface GetNivelPuesto {
  type: typeof GET_NIVEL_PUESTO
  payload: null
}

export interface GetNivelPuestoSuccess {
  type: typeof GET_NIVEL_PUESTO_SUCCESS
  payload: StaticOption[]
}

export interface GetNivelPuestoError {
  type: typeof GET_NIVEL_PUESTO_ERROR
  payload: null
}

export type StaticActions =
  | GetEstadoCivil
  | GetEstadoCivilSuccess
  | GetEstadoCivilError
  | GetPaises
  | GetPaisesSuccess
  | GetPaisesError
  | GetTipoDocumento
  | GetTipoDocumentoSuccess
  | GetTipoDocumentoError
  | GetGeneros
  | GetGenerosSuccess
  | GetGenerosError
  | GetTiposLicencias
  | GetTiposLicenciasSuccess
  | GetTiposLicenciasError
  | GetProvincias
  | GetProvinciasSuccess
  | GetProvinciasError
  | GetLocalidades
  | GetLocalidadesSuccess
  | GetLocalidadesError
  | GetInstitucionesEducativas
  | GetInstitucionesEducativasSuccess
  | GetInstitucionesEducativasError
  | GetTiposEstudio
  | GetTiposEstudioSuccess
  | GetTiposEstudioError
  | GetAreasEstudio
  | GetAreasEstudioSuccess
  | GetAreasEstudioError
  | GetEstadosEstudio
  | GetEstadosEstudioSuccess
  | GetEstadosEstudioError
  | GetIdiomas
  | GetIdiomasSuccess
  | GetIdiomasError
  | GetAreas
  | GetAreasSuccess
  | GetAreasError
  | GetSubareas
  | GetSubareasSuccess
  | GetSubareasError
  | GetIndustrias
  | GetIndustriasSuccess
  | GetIndustriasError
  | GetNivelPuesto
  | GetNivelPuestoSuccess
  | GetNivelPuestoError
  | GetNivelesIdioma
  | GetNivelesIdiomaSuccess
  | GetNivelesIdiomaError
