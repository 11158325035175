import { Col } from '@navent-jobs/ui-kit/components/grid'
import styled from 'styled-components'

export const Page = styled.div`
  margin: -56px 0px 24px;

  @media (min-width: 1024px) {
    margin: 0px 0px 40px;
  }
`

export const BumGrayLogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey4};
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding-right: 24px;
  }
`

export const BumGrayLogo = styled.img``

export const StickyContainerHeader = styled.div`
  position: sticky;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['body-bg']};
  z-index: 15;
  top: 0;
  border-radius: 8px;

  @media (min-width: 1024px) {
    top: 56px;
  }
  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    top: 104px;
  }
`

export const ColCustom = styled(Col)`
  margin-bottom: 0px;
`

export const CvAnonimoDescription = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  line-height: 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  margin: 16px auto 0px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    align-items: center;
  }
`

export const IconWrapper = styled.span`
  margin: 1px 5px 0px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 0px 5px 0px 0px;
  }
`

// maniobra para evitar el contenido de ancho fijo lo cual rompe el maquetado
export const MinisitioContainer = styled.div`
  * {
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  max-height: 300px;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Img = styled.img`
  width: 100%;
  height: auto;
`

export const VideoSpacer = styled.div`
  margin-top: 16px;
`

export const Spacer = styled(VideoSpacer)`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 16px;
  }
`

export const StickyContainerWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 15;
  top: 56px; // 56 de header

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    top: 104px; // 104 de header
  }
`
