/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const LISTADO_MENSAJES = 'LISTADO_MENSAJES'
export const LISTADO_MENSAJES_SUCCESS = 'LISTADO_MENSAJES_SUCCESS'
export const LISTADO_MENSAJES_ERROR = 'LISTADO_MENSAJES_ERROR'
export const LISTADO_MENSAJES_RESET = 'LISTADO_MENSAJES_RESET'

export const LEER_CHAT = 'LEER_CHAT'
export const LEER_CHAT_SUCCESS = 'LEER_CHAT_SUCCESS'
export const LEER_CHAT_ERROR = 'LEER_CHAT_ERROR'
export const LEER_CHAT_RESET = 'LEER_CHAT_RESET'

export const ENVIAR_MENSAJE = 'ENVIAR_MENSAJE'
export const ENVIAR_MENSAJE_SUCCESS = 'ENVIAR_MENSAJE_SUCCESS'
export const ENVIAR_MENSAJE_ERROR = 'ENVIAR_MENSAJE_ERROR'

export const MARCAR_LEIDO = 'MARCAR_LEIDO'
export const MARCAR_LEIDO_SUCCESS = 'MARCAR_LEIDO_SUCCESS'
export const MARCAR_LEIDO_ERROR = 'MARCAR_LEIDO_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */
export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

type TipoMensaje = 'postulacion' | 'directo'

export interface ListadoItemType {
  id: number
  tipo: TipoMensaje
  postulacionId: number | null
  tituloAviso: string | null
  nombreEmpresa: string
  logoEmpresa: string | null
  leido: boolean
}

export interface ComentarioType {
  nombre: string
  avatarURL: string | null
  mensaje: string
  fecha: string
  fechaCompleta: string
  fechaFormateada: string | null
  hora: string
  tipo: 'COMPANY' | 'APPLICANT'
}

export interface ChatType {
  id: number
  tipo: TipoMensaje
  idMensaje: number
  idAviso: number | null
  tituloAviso: string | null
  nombreEmpresa: string
  logoEmpresa: string | null
  comentarios: ComentarioType[]
  leido: boolean
}

export interface LeerChatType {
  id: number
  tipo: TipoMensaje
}

export interface EnviarMensajeType {
  id: number
  tipo: TipoMensaje
  mensaje: string
}

export interface MarcarLeidoType {
  id: number
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface MessagesState {
  listado: {
    value: ListadoItemType[] | null
    status: Status
  }
  chat: {
    value: ChatType | null
    status: Status
  }
  envio: {
    status: Status
  }
  marca: {
    status: Status
  }
}

export const statusState = {
  initial: {
    success: false,
    error: false,
    loading: false,
  },
  success: {
    success: true,
    error: false,
    loading: false,
  },
  error: {
    success: false,
    error: true,
    loading: false,
  },
  loading: {
    success: false,
    error: false,
    loading: true,
  },
}

export const initialState: MessagesState = {
  listado: {
    value: null,
    status: statusState.initial,
  },
  chat: {
    value: null,
    status: statusState.initial,
  },
  envio: {
    status: statusState.initial,
  },
  marca: {
    status: statusState.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface ListadoMensajes {
  type: typeof LISTADO_MENSAJES
  payload: null
}

export interface ListadoMensajesSuccess {
  type: typeof LISTADO_MENSAJES_SUCCESS
  payload: ListadoItemType[]
}

export interface ListadoMensajesError {
  type: typeof LISTADO_MENSAJES_ERROR
  payload: null
}

export interface ListadoMensajesReset {
  type: typeof LISTADO_MENSAJES_RESET
  payload: null
}

export interface LeerChat {
  type: typeof LEER_CHAT
  payload: LeerChatType
}

export interface LeerChatSuccess {
  type: typeof LEER_CHAT_SUCCESS
  payload: ChatType
}

export interface LeerChatError {
  type: typeof LEER_CHAT_ERROR
  payload: null
}

export interface LeerChatReset {
  type: typeof LEER_CHAT_RESET
  payload: null
}

export interface EnviarMensaje {
  type: typeof ENVIAR_MENSAJE
  payload: EnviarMensajeType
}

export interface EnviarMensajeSuccess {
  type: typeof ENVIAR_MENSAJE_SUCCESS
  payload: null
}

export interface EnviarMensajeError {
  type: typeof ENVIAR_MENSAJE_ERROR
  payload: null
}

export interface MarcarLeido {
  type: typeof MARCAR_LEIDO
  payload: MarcarLeidoType
}

export interface MarcarLeidoSuccess {
  type: typeof MARCAR_LEIDO_SUCCESS
  payload: MarcarLeidoType
}

export interface MarcarLeidoError {
  type: typeof MARCAR_LEIDO_ERROR
  payload: null
}

export type MessagesActions =
  | ListadoMensajes
  | ListadoMensajesSuccess
  | ListadoMensajesError
  | ListadoMensajesReset
  | LeerChat
  | LeerChatSuccess
  | LeerChatError
  | LeerChatReset
  | EnviarMensaje
  | EnviarMensajeSuccess
  | EnviarMensajeError
  | MarcarLeido
  | MarcarLeidoSuccess
  | MarcarLeidoError
