import React from 'react'
import { useHistory } from 'react-router-dom'

import { ComponentContainer, CustomGoToSignInButton } from './mixins'

const Component = () => {
  const history = useHistory()

  return (
    <ComponentContainer>
      <CustomGoToSignInButton
        onClick={() => {
          history.replace('/login')
        }}
        buttonoptions={{
          native: false,
          size: 'medium',
          type: 'fill',
          variant: 'primary',
        }}
      >
        Ingresar
      </CustomGoToSignInButton>
    </ComponentContainer>
  )
}

export default Component
