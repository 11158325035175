import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { NOMBRE_SITIO } from '../../../../constants'

const Metas = () => {
  const { t } = useTranslation()
  const wordingData = {
    siteName: NOMBRE_SITIO,
  }
  const pageTitle = t(`seoWordings.product_landing.miguru.title`, wordingData)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default Metas
