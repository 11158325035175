import React from 'react'
import ContentLoader from 'react-content-loader'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

const SkeletonDesktop = props => (
  <ContentLoader
    speed={2}
    width={636}
    height={386}
    viewBox="0 0 636 386"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* titulo */}
    <rect x="0" y="0" rx="4" ry="4" width="159" height="24" />

    {/* helper */}
    <rect x="0" y="40" rx="2" ry="2" width="235" height="12" />
    <rect x="0" y="71" rx="2" ry="2" width="363" height="12" />

    {/* subtitulo */}
    <rect x="0" y="131" rx="2" ry="2" width="200" height="20" />

    {/* suscripcion */}
    <rect x="0" y="170" rx="2" ry="2" width="300" height="20" />
    <rect x="calc(100% - 50px)" y="170" rx="15" ry="15" width="40" height="24" />

    {/* suscripcion */}
    <rect x="0" y="218" rx="2" ry="2" width="300" height="20" />
    <rect x="65%" y="218" rx="15" ry="15" width="40" height="24" />
    <rect x="calc(100% - 50px)" y="218" rx="15" ry="15" width="40" height="24" />

    {/* suscripcion */}
    <rect x="0" y="266" rx="2" ry="2" width="300" height="20" />
    <rect x="calc(100% - 50px)" y="266" rx="15" ry="15" width="40" height="24" />

    {/* subtitulo */}
    <rect x="0" y="314" rx="2" ry="2" width="200" height="20" />

    {/* suscripcion */}
    <rect x="0" y="362" rx="2" ry="2" width="300" height="20" />
    <rect x="calc(100% - 50px)" y="362" rx="15" ry="15" width="40" height="24" />
  </ContentLoader>
)

const SkeletonMobile = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={565}
    viewBox="0 0 100% 565"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* titulo */}
    <rect x="0" y="0" rx="4" ry="4" width="159" height="24" />

    {/* helper */}
    <rect x="0" y="40" rx="2" ry="2" width="100%" height="12" />
    <rect x="0" y="71" rx="2" ry="2" width="80%" height="12" />

    <rect x="0" y="86" rx="2" ry="2" width="100%" height="12" />
    <rect x="0" y="100" rx="2" ry="2" width="80%" height="12" />

    {/* subtitulo */}
    <rect x="0" y="131" rx="2" ry="2" width="200" height="20" />

    {/* suscripcion */}
    <rect x="0" y="170" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="200" rx="2" ry="2" width="20%" height="20" />
    <rect x="65" y="240" rx="15" ry="15" width="40" height="24" />

    {/* suscripcion */}
    <rect x="0" y="284" rx="2" ry="2" width="100%" height="20" />
    <rect x="calc(100% - 50px)" y="314" rx="15" ry="15" width="40" height="24" />
    <rect x="65" y="315" rx="15" ry="15" width="40" height="24" />

    {/* suscripcion */}
    <rect x="0" y="363" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="363" rx="2" ry="2" width="20%" height="20" />
    <rect x="65" y="394" rx="15" ry="15" width="40" height="24" />

    {/* subtitulo */}
    <rect x="0" y="442" rx="2" ry="2" width="200" height="20" />

    {/* suscripcion */}
    <rect x="0" y="475" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="510" rx="2" ry="2" width="20%" height="20" />
    <rect x="65" y="538" rx="15" ry="15" width="40" height="24" />
  </ContentLoader>
)

const NotificationsSkeleton = () => {
  const { isMobile } = useWindowSize()
  return isMobile ? <SkeletonMobile /> : <SkeletonDesktop />
}

export default NotificationsSkeleton
