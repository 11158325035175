export const currencyMask = {
  symbol: '$',
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: false,
  decimalSymbol: ',',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  icon: 'icon-light-money',
  code: 'CLP',
}
