import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

// ui-kit
import { Container, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Checkbox } from '@navent-jobs/ui-kit/components/checkbox'

// components
import Image from './components/image'
import PaymentTitle from './components/title'
import PaymentStateSubtitle from './components/subtitle'
import ProductInfo from './components/info'
import Metas from '../../components/metas/payment-mp'
import CopyEmail from './components/copy-email'

// mixins
import { PageContent, CustomButton, CustomDiv } from './mixins'

// constants
import { DataLayer } from '../../constants/pda/banner/gtm-events-bannerPda'
import { ecommerceProductId } from '../../constants/payment-mp-product-id'
import paymentMPSettings from '../../constants/payment-mp-settings'
import { DataLayersLanguageTest } from '../../constants/gtm-events-language-test'
import { DataLayerAnalysisCv } from '../../constants/gtm-events-cv-analysis'

// store
import { notificarPagoExitoso } from '../../redux/actions/payment-mp'

// components lazy load
const LayoutAuthHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-auth-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const PaymentMP = props => {
  const [checkNulinga, setCheckNulinga] = useState(false)
  const { status, operationId } = useParams()
  const query = useQuery()
  const dispatch = useDispatch()
  const pageSetting = paymentMPSettings()

  // si no se encuentra externalId, por defecto se las settings de Test de Personalidad
  const externalId = query.get('externalId') ?? '2'
  const ecommerceProduct = ecommerceProductId[externalId]
  const productSetting = pageSetting.products[ecommerceProduct][status]
  const { checkLabel, emailCopyTitle } = productSetting
  const dissableButton = checkLabel && !checkNulinga

  const handleLinkSuccessOrError = (msg: string) => {
    if (externalId === '4') {
      DataLayerAnalysisCv.goToCv(msg)
    }
    if (externalId === '5') {
      DataLayerAnalysisCv.goToCv(msg, 'Doctor CV')
    }
  }

  const onPressLink = () => {
    if (status === 'success') {
      if (checkNulinga && externalId === '3') {
        DataLayersLanguageTest.goToTest('CTA Ir al test')
      }
      handleLinkSuccessOrError('Pago exitoso-CTA Ir al CV')
    } else if (status === 'failure') {
      handleLinkSuccessOrError('Pago rechazado-CTA Volver al CV')
    }

    window.open(productSetting.buttonRef, '_blank')
  }

  const handleSuccessOrFailure = (traking: string, paymentMsg: string) => {
    if (externalId === '2') {
      DataLayer.handlerBannerPdaTraking(traking)
    } else if (externalId === '3') {
      DataLayersLanguageTest.completedPayment(paymentMsg)
    } else if (externalId === '4') {
      DataLayerAnalysisCv.paymentResult(paymentMsg)
    } else if (externalId === '5') {
      DataLayerAnalysisCv.paymentResult(paymentMsg, 'Doctor CV')
    }
  }

  useEffect(() => {
    if (status === 'success') {
      dispatch(notificarPagoExitoso(operationId, externalId))
      handleSuccessOrFailure('pagoExitoso', 'Pago exitoso')
    } else if (status === 'failure') {
      handleSuccessOrFailure('pagoError', 'Pago rechazado')
    }
  }, [])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutAuthHeader {...props}>
        <Metas />
        <Container>
          <Row>
            <Col>
              <PageContent>
                <Image status={status} product={ecommerceProduct} />
                <PaymentTitle status={status} product={ecommerceProduct} />
                <PaymentStateSubtitle status={status} product={ecommerceProduct} emailCopyTitle={emailCopyTitle} />

                {productSetting.info && <ProductInfo status={status} product={ecommerceProduct} />}

                {emailCopyTitle && <CopyEmail title={emailCopyTitle} />}

                {checkLabel && (
                  <CustomDiv>
                    <Checkbox
                      id="productCheck"
                      name="productCheck"
                      label={checkLabel}
                      onChange={() => setCheckNulinga(!checkNulinga)}
                      checked={checkNulinga}
                      fieldOptions={{
                        size: 'small',
                      }}
                    />
                  </CustomDiv>
                )}

                <CustomButton
                  onClick={() => onPressLink()}
                  disabled={dissableButton}
                  buttonoptions={{
                    size: 'medium',
                    variant: `${dissableButton ? 'gray' : 'primary'}`,
                    type: 'fill',
                  }}
                >
                  {`${productSetting.buttonTitle}`}
                </CustomButton>
              </PageContent>
            </Col>
          </Row>
        </Container>
      </LayoutAuthHeader>
    </Suspense>
  )
}

export default PaymentMP
