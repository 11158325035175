import React, { useState } from 'react'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Page,
  ImgCustom,
  ImgLogo,
  Title,
  Description,
  CustomDiv,
  MetricDiv,
  ContainerButtons,
  CustomButton,
  CustomLink,
  PreTitle,
  TitleContainer,
} from './mixins'
import { productLandingTranslations } from '../../constants'
import { PaymentMPProductSKU } from '../../../../constants/payment-mp-product-sku'
import { userLoggedIn } from '../../../../utils/cookies'
import { PopOverComponent } from '@navent-jobs/ui-kit/components/pop-over'

interface HomeInterface {
  backgroundImage?: React.Component
  logoImage: React.Component
  logoStyles?: any
  title: string
  description: string
  secondaryButtonText?: string
  secondaryMetric?: () => void
  primaryButtonText: string
  primaryMetric?: () => void
  homeImage: React.Component
  primaryAction?: any
  primaryLoading?: boolean
  secondaryAction?: any
  scrollRef?: string
  componentToScroll?: string
  preTitle: string
  popOver?: {
    enabled: boolean
    id: string
    content: any
    imgSrc: any
  }
  showImageMobile?: boolean
}

const Component = ({
  mpOptions = { primaryAction: () => { }, primaryLoading: false, secondaryAction: () => { } },
  productName,
  accessor = 'home',
  availability,
  availabilityStates,
}) => {
  const {
    backgroundImage,
    logoImage,
    logoStyles,
    title,
    description,
    secondaryButtonText,
    secondaryMetric,
    primaryButtonText,
    primaryMetric,
    homeImage,
    scrollRef,
    componentToScroll,
    preTitle,
    popOver,
    showImageMobile,
  }: HomeInterface = productLandingTranslations()[productName][accessor]
  const [showPopover, setShowPopover] = useState(true)
  const { isTabletLandscape, isDesktop } = useWindowSize()
  const history = useHistory()
  const isPdaProduct = productName === 'pda'
  const isUserLoggedIn = userLoggedIn()

  const pdaProductAvailability =
    availabilityStates.success && isPdaProduct && availability?.find(p => p.sku === PaymentMPProductSKU.PDA)

  const primaryButtonHandler = () => {
    primaryMetric && primaryMetric()

    if (mpOptions?.primaryAction) {
      mpOptions.primaryAction()
    }
  }

  const secondaryButtonHandler = () => {
    secondaryMetric && secondaryMetric()

    if (mpOptions?.secondaryAction) {
      mpOptions.secondaryAction()
    }
  }

  const displayButtons = () => {
    if (availabilityStates.loading && isPdaProduct) {
      return (
        <CustomButton buttonoptions={{ variant: 'primary', size: 'medium' }} hasTwoButtons={secondaryButtonText}>
          <Loader />
        </CustomButton>
      )
    }

    if (
      (!isUserLoggedIn && isPdaProduct) ||
      (pdaProductAvailability && pdaProductAvailability?.code === 'PDA_GRATIS')
    ) {
      return (
        <CustomButton
          buttonoptions={{ variant: 'primary', size: 'medium' }}
          hasTwoButtons={secondaryButtonText}
          onClick={() => {
            history.push('/test-de-personalidad')
            window.scrollTo(0, 0)
          }}
          isLoading={mpOptions.primaryLoading}
        >
          {'Hacer el test gratis'}
        </CustomButton>
      )
    }

    if (primaryButtonText)
      return (
        <CustomButton
          buttonoptions={{ variant: 'primary', size: 'medium' }}
          hasTwoButtons={secondaryButtonText}
          onClick={primaryButtonHandler}
          isLoading={mpOptions.primaryLoading}
        >
          {primaryButtonText}
        </CustomButton>
      )

    return null
  }

  const PopoverWrapper = ({ children }) =>
    popOver?.enabled && !availabilityStates.loading && !isPdaProduct ? (
      <PopOverComponent
        id={popOver.id || 'custom-popOver'}
        visible={showPopover}
        onClose={() => setShowPopover(false)}
        content={popOver.content || null}
        imgSrc={popOver.imgSrc || null}
        arrowProps={!isDesktop && !isTabletLandscape ? 'lowerCenter' : 'rightBottom'}
        fullwidth={!isDesktop && !isTabletLandscape}
      >
        {children}
      </PopOverComponent>
    ) : (
      <>{children}</>
    )

  return (
    <Page urlImg={backgroundImage} id={scrollRef || 'HOME'}>
      <MainContainer>
        <Row>
          <Col lg={6} xl={4} offset={{ xl: 1 }}>
            <CustomDiv>
              {logoImage && <ImgLogo src={logoImage} logoStyles={logoStyles} />}

              <TitleContainer>
                {preTitle && <PreTitle>{preTitle}</PreTitle>}
                {title && <Title>{title}</Title>}
              </TitleContainer>

              <Description>{description}</Description>

              <PopoverWrapper>
                <ContainerButtons>
                  {secondaryButtonText && (
                    <MetricDiv onClick={secondaryButtonHandler}>
                      <CustomLink
                        href={componentToScroll || '#BECAUSE'}
                        linkoptions={{ variant: 'primary', type: 'outline', size: 'medium', anchor: true }}
                      >
                        {secondaryButtonText}
                      </CustomLink>
                    </MetricDiv>
                  )}

                  {displayButtons()}
                </ContainerButtons>
              </PopoverWrapper>
            </CustomDiv>
          </Col>

          {(isTabletLandscape || isDesktop || showImageMobile) && (
            <Col lg={6} xl={5} offset={{ xl: 1 }}>
              <ImgCustom src={homeImage} />
            </Col>
          )}
        </Row>
      </MainContainer>
    </Page>
  )
}

const states = ({ productsStore }) => ({
  productsStatus: productsStore?.productsStatus,
  availability: productsStore?.productAvailability?.availability,
  availabilityStates: productsStore?.productAvailability?.states,
})

export default connect(states)(Component)
