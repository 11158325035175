import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/cv-parser'
import {
  CV_PARSER,
  CV_PARSER_ERROR,
  CV_PARSER_SUCCESS,
  CvParser,
  CvParserError,
  CvParserSuccess,
} from '../types/cv-parser'

function* cvParserUploadFile({ payload }: CvParser) {
  try {
    const response = yield call(service.cvParserUploadFile, payload)
    yield put<CvParserSuccess>({ type: CV_PARSER_SUCCESS, payload: response })
  } catch {
    yield put<CvParserError>({ type: CV_PARSER_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* cvParser() {
  yield takeLatest<CvParser>(CV_PARSER, cvParserUploadFile)
}
