import React from 'react'
import { connect } from 'react-redux'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { ButtonContainer, Container, CustomButton, CustomImage, Header } from './mixins'
import Text from '@navent-jobs/ui-kit/components/text'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Placeholder from '../placeholders'
import logos from '../../../../../constants/logos'
import { SITE_ID } from '../../../../../constants'

const Component = ({ careerSite, careerSiteStates }) => {
  const { isMobile } = useScreenSize()

  const Logo = () => {
    if (careerSiteStates?.loading) {
      return <Placeholder />
    }
    return careerSite?.header?.enterpriseLogo ? (
      <CustomImage src={careerSite?.header?.enterpriseLogo} />
    ) : (
      <Link href={'/'}>
        <CustomImage {...logos[SITE_ID ?? 'BMAR']} />
      </Link>
    )
  }

  return (
    <Header id="header-bar-hiring">
      <MainContainer>
        <Row>
          <Col>
            <Container>
              <Logo />

              {careerSite?.account && (
                <CustomButton
                  buttonoptions={{ type: 'fill', variant: 'black', size: 'small' }}
                  onClick={() => {
                    window.open(`https://${careerSite?.account}.hiringroom.com/jobs`, '_blank')
                  }}
                >
                  <ButtonContainer>
                    <Icon name="icon-light-browser" size="20" />
                    {!isMobile && (
                      <Text size="sm" fontWeight="bold" variant="gray0">
                        Portal de empleos
                      </Text>
                    )}
                  </ButtonContainer>
                </CustomButton>
              )}
            </Container>
          </Col>
        </Row>
      </MainContainer>
    </Header>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
  careerSiteStates: careerSiteStore.careerSiteStates,
})

export default connect(states)(Component)
