import { Request, Get, Post } from '@navent-jobs/config'
import { ProductAvailability } from '../redux/types/products'
import { loginRestringidoCookieExist } from '../utils/cookies'

export class ErrorProducts extends Error {}

const vendorId = 8

class Products {
  public getProduct = async (skuProduct: string) => {
    const request = new Request()
    request.path = `/api/candidates/product-info?skus=${skuProduct}&vendorId=${vendorId}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorProducts(`Producto no encontrado`)
        }
      })
  }

  public getProducts = async (skuProducts: string[]) => {
    const request = new Request()
    request.path = `/api/candidates/product-info?skus=${skuProducts.toString()}&vendorId=${vendorId}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorProducts(`Producto no encontrado`)
        }
      })
  }

  public sendFormData = async ({ payload }) => {
    const request = new Request()
    request.path = `/api/application/add-product`
    request.body = payload
    request.method = Post
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorProducts(`Products error`)
        }
      })
  }

  public getProductStatus = async () => {
    const rtoken = loginRestringidoCookieExist()
    if (rtoken) {
      return undefined
    }
    const request = new Request()
    request.path = `/api/candidates/products-status`
    request.method = Get
    return request.call().catch(error => {
      if (error.response.status === 404) {
        throw new ErrorProducts(`Productos no encontrados`)
      }
    })
  }

  public getProductAvailability = async (sku: string): Promise<ProductAvailability[]> => {
    const request = new Request()
    request.path = `/api/candidates/products-availability?sku=${sku}`
    request.method = Get
    return request.call().catch(error => {
      if (error.response.status === 404) {
        throw new ErrorProducts(`Producto no encontrado`)
      }
    })
  }
}

const products = new Products()
export default products
