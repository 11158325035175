import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const CustomButton = styled(Button)<{ theme }>`
  margin-bottom: 6px;

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    max-width: 200px;
    margin: 0px 12px;
  }
`

export const Content = styled.div``

export const LinkCustom = styled.span``
