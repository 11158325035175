import {
  GET_LINK_PAGO,
  NOTIFICAR_PAGO_EXITOSO,
  GetLinkPago,
  NotificarPagoExitoso,
  GET_LINK_PAGO_RESET,
  GetLinkPagoReset,
  ProductItem,
  GetLinkPagoV2,
  GET_LINK_PAGOV2,
} from '../types/payment-mp'

export const getLinkPago = (refName: string, productId: number, externalId: string): GetLinkPago => {
  return {
    type: GET_LINK_PAGO,
    payload: { refName, productId, externalId },
  }
}

export const getLinkPagoV2 = (refName: string, products: ProductItem[], externalId: string): GetLinkPagoV2 => {
  return {
    type: GET_LINK_PAGOV2,
    payload: { refName, products, externalId },
  }
}

export const getLinkPagoReset = (): GetLinkPagoReset => {
  return {
    type: GET_LINK_PAGO_RESET,
    payload: null,
  }
}

export const notificarPagoExitoso = (operationId: number, externalId: string): NotificarPagoExitoso => {
  return {
    type: NOTIFICAR_PAGO_EXITOSO,
    payload: { operationId, externalId },
  }
}
