import React from 'react'
import { useTranslation } from 'react-i18next'
import imageSrc from '../../../../../../assets/images/commons/black-canvas.svg'
import imageSrcZj from '../../../../../../assets/images/commons/black-canvas-zj.svg'

import { Main, EmptyStateImg, EmptyStateMessage } from './mixins'
import { IS_ZONA_JOBS } from '../../../../../../constants'

const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <Main>
      <EmptyStateImg src={IS_ZONA_JOBS ? imageSrcZj : imageSrc} />
      <EmptyStateMessage>{t('companySummary.publications.emptyStateDescription')}</EmptyStateMessage>
    </Main>
  )
}

export default EmptyState
