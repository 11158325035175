const products = {
  nulinga: {
    curriculumMarketplace: false,
    landing: false,
  },
  nulingaCourse: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
}

export default products
