import React from 'react'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Title, Img, Subtitle, Description, Page, ColContainer, Information, CustomContainer } from './mixins'
import { productLandingTranslations } from '../../constants'

interface DescriptionInterface {
  titleFirstPart?: string
  backgroundColor?: string
  rows: {
    firstColumn: {
      image: React.Component
      title: string
      description: string
    }
    secondColumn: {
      image: React.Component
      title: string
      description: string
    }
  }[]
  scrollRef?: string
  information?: string
}

const Component = ({ productName, accessor = 'description' }) => {
  const { titleFirstPart, rows, scrollRef, backgroundColor, information }: DescriptionInterface =
    productLandingTranslations()[productName][accessor]

  return (
    <Page id={scrollRef || 'DESCRIPTION'} backgroundColor={backgroundColor}>
      <CustomContainer noGutter>
        <Row>
          <Col>
            <Title>{titleFirstPart}</Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Information>{information}</Information>
          </Col>
        </Row>
        {rows &&
          rows.map(row => {
            return (
              <Row key="row">
                <Col lg={4} offset={{ lg: 2 }}>
                  <ColContainer>
                    <Img src={row.firstColumn.image} />
                    <Subtitle>{row.firstColumn.title}</Subtitle>
                    <Description>{row.firstColumn.description}</Description>
                  </ColContainer>
                </Col>
                <Col lg={4}>
                  <ColContainer>
                    <Img src={row.secondColumn.image} />
                    <Subtitle>{row.secondColumn.title}</Subtitle>
                    <Description>{row.secondColumn.description}</Description>
                  </ColContainer>
                </Col>
              </Row>
            )
          })}
      </CustomContainer>
    </Page>
  )
}

export default Component
