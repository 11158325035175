import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { connect } from 'react-redux'
import { ThemeContext } from 'styled-components'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 9999,
}

const Confetti = ({ confettiStore }) => {
  const refAnimationInstance = useRef(null)
  const [intervalId, setIntervalId] = useState()
  const { colors } = useContext<{ colors }>(ThemeContext)

  function getAnimationSettings(angle, originX) {
    return {
      particleCount: 3,
      angle,
      spread: 55,
      origin: { x: originX },
      colors: [colors.primary.normal, colors.secondary.normal, colors.tertiary.normal],
    }
  }

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance
  }, [])

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0))
      refAnimationInstance.current(getAnimationSettings(120, 1))
    }
  }, [])

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 16))
    }
  }, [nextTickAnimation, intervalId])

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId)
    setIntervalId(null)
  }, [intervalId])

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (confettiStore.on) {
      startAnimation()
    } else if (confettiStore.on === false) {
      pauseAnimation()
    }
  }, [confettiStore])

  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  )
}

const state = ({ confettiStore }) => ({ confettiStore })

export default connect(state)(Confetti)