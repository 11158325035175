import React, { useEffect, useState } from 'react'
import { Col } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useFilters } from '@navent-jobs/ui-kit/hooks/filters'
import { AcordeonFilter } from './AcordeonFilter'
import { SelectFilter } from './SelectFilter'
import { ColWithCenterAlign, RemoveAllFilters } from './mixins'
import features from '../../../constants/features-per-country'
import { SITE_ID } from '../../../constants'

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled

export const FilterBar = ({
  urlService,
  routerMatch,
  allFilters,
  allAppliedFilters,
  filterSettings,
  deleteAll,
  defaultFilters,
}) => {
  const { filters, performFiltering } = useFilters({
    allFilters,
    allAppliedFilters,
    urlService,
    routerMatch,
    filterSettings,
    defaultFilters,
    searchAvisosV2Enabled,
  })
  const [dynamicFilters, setDynamicFilters] = useState(filters)

  const moveElementsIntoCollapseFilter = () => {
    setDynamicFilters(
      filters.map(filter => {
        return { ...filter, fixed: filter.fixedUntil !== null ? window.innerWidth > filter.fixedUntil : filter.fixed }
      })
    )
  }

  useEffect(() => {
    moveElementsIntoCollapseFilter()
    window.addEventListener('resize', moveElementsIntoCollapseFilter)
    return () => {
      window.removeEventListener('resize', moveElementsIntoCollapseFilter)
    }
  }, [filters, moveElementsIntoCollapseFilter])

  if (!dynamicFilters) return null

  return (
    <>
      {dynamicFilters &&
        dynamicFilters.map(filter => {
          return (
            filter.fixed && (
              <Col key={`filter-${filter.type}`}>
                <SelectFilter filter={filter} onSelect={performFiltering} />
              </Col>
            )
          )
        })}

      <Col>
        <AcordeonFilter filters={dynamicFilters.filter(filter => !filter.fixed)} onSelect={performFiltering} />
      </Col>

      {deleteAll && (
        <ColWithCenterAlign>
          <RemoveAllFilters onClick={() => performFiltering('all', null)}>
            <Icon size="20" name="icon-light-trash-2" color="#0A26EE" />
          </RemoveAllFilters>
        </ColWithCenterAlign>
      )}
    </>
  )
}
