import { Request, Get } from '@navent-jobs/config'
import { TemplateCv } from '../redux/types/applicant/descarga-curriculum'

export class ErrorCurriculum extends Error {}

class Curriculum {
  public async downloadCurriculum(templateType: TemplateCv) {
    const request = new Request()
    request.path = `/api/curriculum/pdf?templateType=${templateType.templateCv}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response.link
      })
      .catch(error => {
        throw new ErrorCurriculum(`No se pude descargar el cv`)
      })
  }
}

const curriculum = new Curriculum()
export default curriculum
