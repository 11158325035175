import React from 'react'

// mixins
import { Anchors, ContainerAnchorBar, LinkCustom } from './mixins'

interface TabsInterface {
  tabs: {
    value: any
    label: string
  }[]
  activeTab: any
  setActiveTab: Function
}

const Tabs = ({ tabs, activeTab, setActiveTab }: TabsInterface) => {
  if (!tabs || tabs.length === 0) {
    return null
  }

  return (
    <ContainerAnchorBar id="anchorBarComponent">
      <Anchors noGutter>
        {tabs.map(tab => (
          <LinkCustom
            onClick={e => {
              e.preventDefault()
              setActiveTab(tab.value)
            }}
            isActive={activeTab === tab.value}
          >
            {tab.label}
          </LinkCustom>
        ))}
      </Anchors>
    </ContainerAnchorBar>
  )
}

export default Tabs
