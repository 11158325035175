import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ComponentContainer = styled('div')`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`

export const CustomGoToSignInButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`
