const notificationAlerts = {
  alerts: {
    emptyStateTitle: 'Aún no tenés alertas guardadas.',
    emptyStateDescription: 'Para recibir ofertas de trabajo por email, creá alertas después de realizar una búsqueda.',
  },
  modalRemove: {
    title: '¿Querés eliminar esta alerta?',
  },
  notifications: {
    especiales: {
      description: 'Envío de avisos en los que tenés mayores posibilidades.',
    },
  },
}

export default notificationAlerts
