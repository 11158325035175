export const products = {
  header: {
    title: 'Los productos y servicios que necesitas para potenciar tu Perfil',
    cta: 'Ver Productos',
  },
  products: {
    title: '{{sitio}} te ofrece las herramientas para que puedas potenciar tu CV y facilitar tu búsqueda laboral.',
    coderhouse: {
      banner: {
        title: 'Potencia tu talento con Coderhouse',
        claim: 'Aprovecha un 20% de descuento en todos los cursos y carreras.',
        cta: 'Conoce más',
      },
    },
    review: {
      bannerTitle: 'Califica una empresa y comparte tu experiencia',
      bannerTitleMobile: 'Califica una empresa y comparte tu experiencia con otros postulantes',
      claim: 'Tu opinión ayudará a los demás postulantes a conocer más sobre las empresas',
      cta: 'Calificar una empresa',
    },
    marketplace: {
      bannerTitle: 'Resalta tu {{cv}} y mejora tu búsqueda laboral',
      claim: 'Conoce las herramientas que {{portal}} te ofrece para facilitar tu búqueda de empleo.',
      cta: 'Conocer más',
    },
    nulingaCursos: {
      title: 'Curso de inglés con Nulinga',
      claim:
        'Capacítate en inglés con todas las comodidades que necesitas: clases personalizadas en vivo, cursado 100% online, y valores preferenciales.',
      cta: 'Conoce más',
    },
  },
  banner: {
    title: 'Conoce nuestros nuevos beneficios',
  },
  form: {
    card: {
      title: 'Suma tu producto a {{sitio}}',
      subtitle:
        'Si tienes un producto o servicio que pueda ayudar a nuestros postulantes a facilitar su búsqueda de empleo y quieres tener visibilidad en nuestra plataforma,',
      subtitleBold: '¡Te invitamos a dejar tu contacto!',
      button: 'Suma tu producto',
    },
    modal: {
      title: 'Suma tu producto',
      requieredFields: 'Estos campos son obligatorios',
      label: {
        nameSurname: 'Nombre y apellido',
        nameCompany: 'Empresa o producto',
        nameSite: 'Sitio web',
        email: 'Correo electrónico',
        message: 'Tu mensaje',
      },
      placeholder: {
        nameSurname: 'Ingresa tu nombre y apellido',
        nameCompany: 'Ingresa tu nombre de empresa o producto',
        nameSite: 'Ingresa tu sitio web',
        email: 'Ingresa tu correo',
        message: 'Ingresa tu respuesta...',
      },
      rules: {
        isFilled: 'Este campo es obligatorio.',
        email: 'Ingresa un email válido',
      },
      buttons: {
        enviar: 'Enviar',
        cancelar: 'Cancelar',
      },
    },
    snackbar: {
      success: '¡Ya enviamos tu mensaje!',
      error: 'Ha ocurrido un error, vuelve a intentarlo',
    },
  },
  modal: {
    document: {
      title: 'Agrega tu número de documento',
      description: 'Necesitamos ese dato para efectuar la compra.',
      continue: 'Aceptar',
    },
    coderhouse: {
      title: 'Potencia tu talento con Coderhouse',
      description: `Usa el código de descuento en cualquier curso o carrera de <b>Coderhouse</b> y potencia tu perfil.`,
      label: 'Copiar el código',
      url: 'https://www.coderhouse.com/alianzas/bumeran?utm_source=bumeran&utm_medium=alianzas&utm_campaign=argentina',
      code: 'BUMERANCOD',
      buttons: {
        success: 'Ir a Coderhouse',
        cancel: 'Cancelar',
      },
      legal:
        'Válido desde el 23/09/22 hasta el 30/09/23 23:59 inclusive. Acumulable con otras promociones. Válido para todas las carreras y/o cursos brindados por CODERHOUSE, excepto Servicios de Desarrollo Profesional, Membresías y Workshops. La promoción consiste en una bonificación del 20% sobre el precio de la CoderBeca. El código de descuento puede usarse ilimitadamente, pero sólo una vez por carrera y/o curso. Válido en Latinoamérica. CODERHOUSE S.R.L. – El Salvador 5218, Piso 3 Of. 308 – CABA – CP: 1414 – CUIT: 30-71452874-9.',
    },
  },
}
