import React from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { ContainerComponent, Section, Title, Wrapper, Label, RowContainer, Divider } from './mixins'
import Loader from './loader'

interface ComponentProps {
  statistic: {
    key: string
    title: string
    label: string
    icon: {
      color: string
      name: string
      size: string
    }
  }
  loading: boolean
  isLast?: boolean
}

const Component = ({ statistic, loading, isLast }: ComponentProps) => {
  const { isMobile } = useWindowSize()

  if (loading) {
    return <Loader isMobile={isMobile} />
  }

  return (
    <>
      <ContainerComponent isLast={isLast}>
        <Section key={statistic.key}>
          <Wrapper>
            <Icon name={statistic.icon.name} size={statistic.icon.size} color={statistic.icon.color} />
            <RowContainer>
              <Title>{statistic.title}</Title>
              <Label>{statistic.label}</Label>
            </RowContainer>
          </Wrapper>
        </Section>
      </ContainerComponent>
      <Divider isLast={isLast} />
    </>
  )
}

export default Component
