export const modalPreguntas = {
  title: 'Contanos más de vos',
  subtitle: 'Completá estas preguntas para terminar tu postulación',
  placeholder: {
    input: 'Ingresá tu respuesta',
  },
  validation: {
    textArea: 'Completá este campo.',
    radioButton: 'Elegí una opción',
  },
}
