import React from 'react'
import { Col, ContainerFluid, Row } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import { Container, FooterComponent } from './mixins'
import logosHeader from '../../../../../constants/logos'
import { SITE_ID } from '../../../../../constants'

const Component = () => {
  const logo = logosHeader[SITE_ID ?? 'BMAR']
  return (
    <FooterComponent>
      <ContainerFluid noGutter>
        <Row noGutter>
          <Col noGutter>
            <Container>
              <Text size="sm">Desarrollado por</Text>
              <img {...logo} />
            </Container>
          </Col>
        </Row>
      </ContainerFluid>
    </FooterComponent>
  )
}

export default Component
