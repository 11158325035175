import bmar from './BMAR'
import zjar from './ZJAR'
import bmcl from './BMCL'
import bmec from './BMEC'
import bmmx from './BMMX'
import bmpa from './BMPA'
import bmpe from './BMPE'
import bmve from './BMVE'

const features = {
  BMAR: bmar,
  ZJAR: zjar,
  BMCL: bmcl,
  BMEC: bmec,
  BMMX: bmmx,
  BMPA: bmpa,
  BMPE: bmpe,
  BMVE: bmve,
}

export default features
