import {
  GET_SEO_FOOTER,
  GET_SEO_FOOTER_FILTERED,
  GET_SEO_FOOTER_SUCCESS,
  GET_SEO_FOOTER_ERROR,
  SeoFooterActions,
  SeoFooterState,
  initialState,
} from '../types/seo-footer'

const reducer = (state: any = initialState, action: SeoFooterActions): SeoFooterState => {
  switch (action.type) {
    case GET_SEO_FOOTER:
      return {
        ...state,
        links: null,
        loading: true,
        error: false,
      }
    case GET_SEO_FOOTER_FILTERED:
      return {
        ...state,
        links: null,
        loading: true,
        error: false,
      }
    case GET_SEO_FOOTER_SUCCESS:
      return {
        ...state,
        links: [...action.payload],
        loading: false,
        error: false,
        success: true,
      }
    case GET_SEO_FOOTER_ERROR:
      return {
        ...state,
        links: null,
        loading: false,
        error: true,
        success: false,
      }
    default:
      return state
  }
}

export default reducer
