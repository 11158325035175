const filtersSettings = {
  'filters-settings': {
    salario: {
      label: 'Sueldo mensual',
    },
    provincia: {
      label: 'Estado',
    },
    localidad: {
      label: 'Municipio',
    },
  },
}

export default filtersSettings
