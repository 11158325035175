import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const CheckGroup = styled.div`
  margin-bottom: 18px;
`

export const CustomLink = styled(Link)`
  line-height: 18px;
  display: inline;
  padding: 0px 0px 0px 3px;
`
export const Reminder = styled.span`
  &:before {
    content: ' *';
    color: red;
  }
`
