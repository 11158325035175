import React, { useContext, useEffect, useState } from 'react'
// Tools
import { dateUtils } from '@navent-jobs/config'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// Component
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { DataLayer } from '../../constants/gtm-events-notifications'
import { IS_ZONA_JOBS } from '../../constants'

// Redux / actions
import { NotificationList, Status, UnreadNotification } from '../../redux/types/notifications'
import {
  getApplicantNotifications,
  getApplicantUnreadNotifications,
  marckAsReadNotifications,
} from '../../redux/actions/notifications'

// Styles
import {
  CustomLink,
  DropdownNotification,
  IconWrapper,
  LinkWrapper,
  LoaderWrapper,
  NotificationBullet,
  NotificationContent,
  NotificationDate,
  NotificationDescription,
  NotificationDropdownHeaderContainer,
  NotificationDropdownHeaderTitle,
  NotificationImg,
  NotificationsWrapper,
  NotificationTitle,
  NotificationToggle,
  WithoutNotificationsImg,
  WithoutNotificationsText,
} from './mixins'

import sinImagenBm from '../../assets/images/notifications/sin-imagen-notificaciones.svg'
import sinImagenZj from '../../assets/images/notifications/sin-imagen-notificaciones-zj.svg'
import withoutNotificationsImgBM from '../../assets/images/notifications/no-notifications.svg'
import withoutNotificationsImgZJ from '../../assets/images/notifications/no-notifications-zj.svg'

const sinImagen = IS_ZONA_JOBS ? sinImagenZj : sinImagenBm
const withoutNotificationsImg = IS_ZONA_JOBS ? withoutNotificationsImgZJ : withoutNotificationsImgBM

interface NotificationsProps {
  newNotifications: {
    data: UnreadNotification | null
    status: Status
  }

  notificationList: {
    data: NotificationList[]
    status: Status
  }
}

interface DropdownNotificationsProps {
  logo: string | null
  title: string
  texto: string | null
  fecha: string
  label: string | null
  link: string | null
  newTab: boolean
}

interface NotificationDropdownHeaderProps {
  hasNotifications: boolean
  isMobile: boolean
  isLoading: boolean
  isSuccess: boolean
}

const LinkPageNotifications = () => {
  return (
    <LinkWrapper>
      <CustomLink
        href="/postulantes/notificaciones-y-alertas"
        linkoptions={{
          type: 'link',
          variant: 'black',
          target: '_blank',
          size: 'small',
        }}
        icon={{ name: 'icon-light-settings', position: 'left', color: 'black' }}
      >
        Notificaciones y alertas
      </CustomLink>
    </LinkWrapper>
  )
}

const NumberOfNotification = unread => {
  const { unread: numberProvider } = unread
  const [numberShown, setNumberShown] = useState<string>('')

  useEffect(() => {
    if (unread) {
      if (numberProvider > 9) {
        setNumberShown('+9')
      } else {
        setNumberShown(numberProvider.toString())
      }
    }
  }, [unread])

  return <NotificationBullet>{numberShown}</NotificationBullet>
}

const NotificationDropdownHeader = ({ hasNotifications, isLoading, isSuccess }: NotificationDropdownHeaderProps) => {
  const { t } = useTranslation()
  return (
    <NotificationDropdownHeaderContainer hasNotifications={hasNotifications}>
      <NotificationDropdownHeaderTitle>Notificaciones</NotificationDropdownHeaderTitle>

      {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

      {!hasNotifications && isSuccess && (
        <>
          <WithoutNotificationsImg alt="Sin notifiaciones" src={withoutNotificationsImg} />
          <WithoutNotificationsText>{t('No tienes notificaciones nuevas')}</WithoutNotificationsText>
        </>
      )}
    </NotificationDropdownHeaderContainer>
  )
}

const DropdownNotifications = ({ logo, link, newTab, title, texto, fecha, label }: DropdownNotificationsProps) => {
  const history = useHistory()

  const clickHandler = e => {
    e.preventDefault()
    DataLayer.handlerNotificationsTraking(label)
    if (link) {
      if (newTab) {
        const ref = window.open(link, '_blank') as Window
        ref.focus()
      } else {
        history.push(link)
      }
    }
  }

  return (
    <NotificationsWrapper {...(label && { onClick: clickHandler })}>
      {logo && <NotificationImg src={logo} />}
      <NotificationContent>
        <NotificationTitle>{title}</NotificationTitle>
        {texto && <NotificationDescription>{texto}</NotificationDescription>}
        {fecha && <NotificationDate>{fecha}</NotificationDate>}
      </NotificationContent>
    </NotificationsWrapper>
  )
}

const getFormatedNotifications = (unformatedItems: NotificationList[]) => {
  return unformatedItems.map(item => {
    const logo = item.icon || sinImagen
    const { title } = item
    const texto = item.text
    const dateFormated = new Date(item.date)
    const fecha = dateUtils.getNumberOfDays(dateFormated)
    const link = item.url
    const newTab = item.external || item.newTab
    const { label } = item

    return {
      content: (
        <DropdownNotifications
          logo={logo}
          link={link}
          newTab={newTab}
          title={title}
          texto={texto}
          fecha={fecha}
          label={label}
        />
      ),
    }
  })
}

const NotificationsComponent = ({ newNotifications, notificationList }: NotificationsProps) => {
  const { isDesktop, isTabletLandscape } = useWindowSize()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const dispatch = useDispatch()
  // State para controlar apertura y cierre del dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false)
  // State adiciona para evitar disparar multiples trakings al abrir Dropdown
  const [trackOpenDropdown, setTrackOpenDropdown] = useState(false)

  const onClickHandler = () => {
    setDropdownOpen(prevState => {
      const newState = !prevState
      if (newState && !trackOpenDropdown) {
        DataLayer.handlerOpenDropDownTraking()
        setTrackOpenDropdown(true)
      } else if (!newState) {
        setTrackOpenDropdown(false)
      }
      return newState
    })
  }
  // Consultamos si tenemos notificaciones sin leer
  useEffect(() => {
    dispatch(getApplicantUnreadNotifications())
  }, [])

  // Al abrir dropdown consultamos notificaciones
  useEffect(() => {
    if (dropdownOpen && !notificationList.status.success) {
      dispatch(getApplicantNotifications())
    }
  }, [dropdownOpen, notificationList.status.success])

  // cuando visualizamos la notificaciones. las marcamos como leidas
  useEffect(() => {
    if (notificationList.status.success) {
      dispatch(marckAsReadNotifications())
    }
  }, [notificationList.status.success])

  return (
    <DropdownNotification
      onClick={() => {
        onClickHandler()
      }}
      visible={dropdownOpen}
      maxHeight={isDesktop || isTabletLandscape ? '306px' : '600px'}
      items={notificationList.data ? getFormatedNotifications(notificationList.data) : []}
      position="right"
      key="notifications"
      dropdownFooter={<LinkPageNotifications />}
      dropdownHeader={
        <NotificationDropdownHeader
          isSuccess={notificationList.status.success}
          hasNotifications={notificationList.data.length > 0}
          isMobile={!isDesktop}
          isLoading={notificationList.status.loading}
        />
      }
    >
      <NotificationToggle>
        <IconWrapper>
          <Icon name="icon-light-notification-on" size="20" color={colors.grey100} />
          {!!newNotifications && !notificationList.status.success && <NumberOfNotification unread={newNotifications} />}
        </IconWrapper>
      </NotificationToggle>
    </DropdownNotification>
  )
}

const states = ({ notificationsStore }) => {
  return {
    newNotifications: notificationsStore.newNotifications.count,
    notificationList: notificationsStore.notificationList,
  }
}

export default connect(states)(NotificationsComponent)
