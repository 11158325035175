const facets = {
  provincia: 'Provincia',
  localidad: 'Localidad',
  area: 'Área',
  subarea: 'Subárea',
  tipo_trabajo: 'Tipo de trabajo',
  apto_discapacitado: 'Apto discapacitado',
  dias_fecha_publicacion: 'Fecha de publicación',
  distancia: 'Distancia desde mi ubicación',
  salario: 'Salario mensual',
  nivel_laboral: 'Nivel laboral',
}

export default facets
