export const review = {
  claim: 'Calificá esta empresa en los siguientes aspectos según tu experiencia personal',
  comments: {
    disclaimer: 'Solo es visible para vos',
  },
  comment: {
    section: 'Dejá tu opinión',
    titleHelperText: 'Resumí tu experiencia en un título claro',
    commentHelperText: 'Contanos cómo es trabajar en esta empresa',
    commentLabel: 'Compartí tu experiencia ',
    exampleTitle: 'Contanos sobre:',
  },
  companies: {
    title: 'Elegí una empresa en la que hayas trabajado y contanos tu experiencia',
  },
}
