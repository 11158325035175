import React from 'react'

// import library
import { useTranslation } from 'react-i18next'
import EliminarCuentaForm from './Form'
import { portalsBySiteId } from '../../../../constants/portalesBySiteId'
import { SITE_ID } from '../../../../constants'
import { SkeletonEliminarCuenta } from '../common/skeleton/DeleteAccount'

// import assets
import { Divider, RequeridData, SubTitle, Title } from '../../mixins'
import { Content, ContentIreversibleAction, Header, IconIrreversibleAction, TextIrreversibleAction } from './mixins'

interface EliminarCuentadProps {
  isLogged: boolean
}

const EliminarCuenta = ({ isLogged }: EliminarCuentadProps) => {
  const { t } = useTranslation()

  if (isLogged === null) {
    return <SkeletonEliminarCuenta />
  }

  return (
    <Content>
      <Header>
        <Title>{t('account.deleteAccount.title')}</Title>
        <Divider />
        <SubTitle>
          {t('account.deleteAccount.subtitle', { nombre_portal: t(portalsBySiteId[SITE_ID || 'BMAR']) })}
        </SubTitle>
        <ContentIreversibleAction>
          <IconIrreversibleAction name="icon-light-alert-triangle" color="#EC0000" size="20" />
          <TextIrreversibleAction>Esta acción es irreversible.</TextIrreversibleAction>
        </ContentIreversibleAction>
        <RequeridData>{t('account.deleteAccount.requeridData')}</RequeridData>
        <EliminarCuentaForm />
      </Header>
    </Content>
  )
}

export default EliminarCuenta
