import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'

import { Heading } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return (
    <Heading>
      <Text type="title" size="sm" fontWeight="semibold" variant="secondary" as="h2">
        {t('¿Olvidaste tu contraseña?')}
      </Text>
    </Heading>
  )
}

export default Component
