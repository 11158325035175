import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DataLayers } from '../../../../constants/gtm-events-marketplace'

// components
import ModalAddProduct from './modal-add-product'

// mixins
import {
  MainForm,
  CardForm,
  CardFlex,
  InfoContainer,
  Title,
  Subtitle,
  ImageContainer,
  ImageCustom,
  ButtonCustom,
  Section,
} from './mixins'

// images
import ImageFormProducts from '../../../../assets/images/products/form-products.svg'
import backgroundWaves from '../../../../assets/images/background-waves.svg'

// constants
import { NOMBRE_SITIO } from '../../../../constants'

// redux
import { showModal } from '../../../../redux/actions/modal'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Select, Autocomplete } from '@navent-jobs/ui-kit/components/select'

const FormSection = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleModal = () => {
    DataLayers.clickFormulario()
    dispatch(showModal('modal-add-product'))
  }

  return (
    <Section bkg={backgroundWaves} id="form">
      <MainForm>
        <CardForm>
          <CardFlex>
            <InfoContainer>
              <Title>{t('products.form.card.title', { sitio: NOMBRE_SITIO })}</Title>
              <Subtitle>{t('products.form.card.subtitle')}</Subtitle>
              <Subtitle bold>{t('products.form.card.subtitleBold')}</Subtitle>
            </InfoContainer>

            <ImageContainer>
              <ImageCustom src={ImageFormProducts} alt="image-form-products" />
            </ImageContainer>
          </CardFlex>

          <ButtonCustom onClick={() => handleModal()} buttonoptions={{ variant: 'primary', type: 'fill' }}>
            {t('products.form.card.button')}
          </ButtonCustom>
        </CardForm>
      </MainForm>

      <ModalAddProduct width={732} name="modal-add-product" />
    </Section>
  )
}

export default FormSection
