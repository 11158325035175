import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { SiteConfig } from '@navent-jobs/config'

import ar from './locales/es-AR'
import cl from './locales/es-CL'
import ec from './locales/es-EC'
import pa from './locales/es-PA'
import mx from './locales/es-MX'
import pe from './locales/es-PE'
import ve from './locales/es-VE'
import zj from './locales/es-ZJAR'

const SITE_ID = window.SITE_ID ?? process.env.REACT_APP_SITE_ID
const whitelist = ['es', 'es-AR', 'es-CL', 'es-EC', 'es-MX', 'es-PA', 'es-PE', 'es-VE', 'es-ZJAR']
const language = SiteConfig[SITE_ID].lng || 'es-AR'
const resources = {
  'es-AR': { translation: ar },
  'es-CL': { translation: cl },
  'es-EC': { translation: ec },
  'es-PA': { translation: pa },
  'es-MX': { translation: mx },
  'es-PE': { translation: pe },
  'es-VE': { translation: ve },
  'es-ZJAR': { translation: zj },
}

const getResorce = () => {
  const obj = {}
  obj[language] = resources[language]
  return obj
}

/**
 * Proxima iteracion cargar solo el idioma que utilizaremos
 */

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es-AR',
    debug: false,
    lng: language,
    whitelist,
    resources: getResorce(),
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'bold') return value.bold()
        return value
      },
    },
  })

export default i18n
