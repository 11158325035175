import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Loader } from '@navent-jobs/ui-kit/components/loader'

// mixins
import { LoaderWrapper } from './mixins'

// redux
import { getCategories, getApplicantReview, getQuestions, sendReviewNoEmployee } from '../../../../redux/actions/review'
import { showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'

// utils
import { getCompanyURL } from '../../../../hooks/get-company-url'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'

// components
import Categorias from '../categorias'
import Preguntas from '../preguntas'
import { QuestionType } from '../../../../constants/question-type'
import { StepButtons } from '../../../../components/StepButtons'

interface ScoresByCategory {
  id: number
  score: number
}

interface OptionByQuestion {
  id: number
  optionId: number
}

export const GENERAL_CATEGORY = 'Experiencia general'

const saveReview = (data, categories, successHandler) => {
  const scoresByCategory: ScoresByCategory[] = []
  let validationFailed = false

  Object.keys(data).forEach(d => {
    const category = categories.find(c => c.name === d)
    if (!data[d]) {
      validationFailed = true
      return
    }
    if (category) {
      scoresByCategory.push({ id: category.id, score: data[d] })
    }
  })

  if (!validationFailed && data.recommended) {
    successHandler({
      scoresByCategory,
      recommends: data.recommended === '1',
    })
  }
}

const getReviewNoEmployee = (data, questions) => {
  const scoresByCategory: ScoresByCategory[] = [{ id: 1, score: data[GENERAL_CATEGORY] }]
  const optionsByQuestion: OptionByQuestion[] = []

  Object.keys(data).forEach(d => {
    const question = questions.find(c => c.description === d)
    if (question && question.questionTypeId === QuestionType.OPCION_MULTIPLE) {
      optionsByQuestion.push({ id: question.id, optionId: data[d] })
    }
  })

  return {
    scoresByCategory,
    optionsByQuestion,
  }
}

const hideContent = (experienciaGeneral, questions, isEmployee) => {
  if (isEmployee === null) {
    return true
  } else if (isEmployee) {
    return !experienciaGeneral
  } else {
    return !questions || questions?.length < 1
  }
}

const loadFormData = (isEmployee, categories, questions, dispatch) => {
  if (isEmployee !== null) {
    if (!categories.length && isEmployee) {
      dispatch(getCategories())
    }

    if (!questions?.length && !isEmployee) {
      dispatch(getQuestions(false))
    }
  }
}

const Component = ({
  isEmployee,
  successHandler,
  nameCompany,
  companyId,
  applicantReview,
  categories,
  categoriesStates,
  questions,
  questionsStates,
  sendReviewStates,
  errorState,
  reviewData,
  loadingState,
  showBack,
  backHandler,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const dataUrl = {
    cantidadAvisos: 0,
    denominacion: nameCompany,
    id: companyId,
  }
  const settingsCompanyURL: any = getCompanyURL({ company: dataUrl })
  const showLoading = loadingState || applicantReview || categoriesStates.loading || questionsStates.loading

  const backProps = showBack
    ? {
        secondaryButton: {
          label: t('review.back'),
          action: () => backHandler(DataLayersReviews.stepClick('calificacion', 'Volver', isEmployee)),
        },
      }
    : {}

  const experienciaGeneral = categories?.slice(0)[0]

  useEffect(() => {
    loadFormData(isEmployee, categories, questions, dispatch)
  }, [isEmployee])

  useEffect(() => {
    // Validar si ya califico cuando entra por url
    if (applicantReview === null) {
      dispatch(getApplicantReview(companyId))
    }
  }, [])

  useEffect(() => {
    // Validar si ya tiene una calificacion navegar a la ficha de la empresa
    if ((applicantReview || errorState) && settingsCompanyURL?.link) {
      history.push(settingsCompanyURL.link)
    }
  }, [applicantReview, errorState])

  useEffect(() => {
    if (!applicantReview && isEmployee !== null) {
      DataLayersReviews.reviewCompanyPrint(isEmployee)
    }
  }, [isEmployee])

  useEffect(() => {
    if (sendReviewStates.success) {
      successHandler()
    }
    if (sendReviewStates.error) {
      dispatch(showSnackbarError(t('review.error')))
    }
  }, [sendReviewStates])

  if (showLoading || hideContent(experienciaGeneral, questions, isEmployee))
    return (
      <>
        {showLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        <StepButtons
          buttonsOptions={{
            primaryButton: {
              label: t('review.next'),
              action: () => {},
            },
            ...backProps,
          }}
        />
      </>
    )

  return (
    <>
      {isEmployee ? (
        <Categorias
          categories={categories}
          reviewData={reviewData}
          secondaryButton={backProps}
          submitHandler={data => {
            saveReview(data, categories, successHandler)
            DataLayersReviews.stepClick('calificacion', 'Continuar', isEmployee)
          }}
        />
      ) : (
        <Preguntas
          questions={questions}
          backProps={backProps}
          loading={sendReviewStates.loading}
          submitHandler={data => {
            dispatch(
              sendReviewNoEmployee({
                companyId,
                body: {
                  ...getReviewNoEmployee(data, questions),
                },
              })
            )
            DataLayersReviews.stepClick('calificacion', 'Enviar', isEmployee)
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ reviewStore }) => ({
  applicantReview: reviewStore.applicantReview,
  loadingState: reviewStore.states.loading,
  errorState: reviewStore.states.error,
  categories: reviewStore.categories,
  categoriesStates: reviewStore.categoriesStates,
  questions: reviewStore.questions,
  questionsStates: reviewStore.questionsStates,
  sendReviewStates: reviewStore.sendReviewStates,
})

export default connect(mapStateToProps)(Component)
