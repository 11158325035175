export const mensajes = {
  title: 'Mensajes',
  sendMessageError: 'Hubo un error, vuelve a intentarlo.',
  placeholder: {
    input: 'Escribe tu mensaje',
  },
  emptyState: {
    mainPageTitle: 'Todavía no tienes ningún mensaje',
    mainPageSubTitle: 'Cuando una empresa se contacte contigo, los mensajes aparecerán en esta pantalla.',
    channel: {
      title: 'Selecciona una conversación para ver los mensajes o enviar uno nuevo',
    },
  },
  labels: {
    iconBBD: 'Búsqueda en base',
    chatTitle: 'Mensajes con',
    chatBBD: 'Esta empresa te encontró por búsqueda en base',
    ctaSend: 'Enviar',
  },
  error: 'Hubo un error, vuelve a intentarlo.',
}
