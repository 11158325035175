import React, { useState } from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

const LoaderContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
  background: white;
  margin: 0 0 15px;
`
export const SearchbarPlaceholder = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={120}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="16" y="16" rx="4" ry="4" width="24" height="24" />
        <rect x="48" y="18" rx="4" ry="4" width="185" height="20" />
        <rect x="16" y="56" rx="8" ry="8" width={wrapperWidth * 0.9} height="48" />
      </ContentLoader>
    </LoaderContainer>
  )
}
