import React, { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// ui-kit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// mixins
import {
  Page,
  Card,
  ImgProduct,
  ImgLogo,
  PriceText,
  CustomContainer,
  Text,
  CustomButton,
  ButtonText,
  PriceButtonWrapper,
  PriceContainer,
  TaxText,
} from './mixins'

// redux
import { getProduct } from '../../../../redux/actions/products'

// constants
import { productLandingTranslations } from '../../constants'
import { PaymentMPProductSKU } from '../../../../constants/payment-mp-product-sku'
import { userLoggedIn } from '../../../../utils/cookies'

interface ProductInterface {
  productName: string
  backgroundColor?: string
  productImage: React.Component
  logoImage: React.Component
  description: string
  buttonText: string
  scrollRef?: string
  metric?: () => void
}

const Component = ({
  mpOptions = { primaryAction: () => { }, primaryLoading: false },
  product,
  productName: productNameForTranslations,
  accessor = 'product',
  availability,
  availabilityStates,
}) => {
  const {
    productName,
    backgroundColor,
    productImage,
    logoImage,
    description,
    buttonText,
    scrollRef,
    metric,
  }: ProductInterface = productLandingTranslations()[productNameForTranslations][accessor]

  const { isMobile, isTabletPortrait } = useWindowSize()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const isPdaProduct = productName === PaymentMPProductSKU.PDA
  const isUserLoggedIn = userLoggedIn()

  const moneySymbol = t(`currencyMask.symbol`) || '$'

  const pdaProductAvailability =
    availabilityStates.success && isPdaProduct && availability?.find(p => p.sku === productName)

  useEffect(() => {
    dispatch(getProduct(productName))
  }, [])

  const clickHandler = () => {
    metric && metric()
    mpOptions.primaryAction()
  }

  const displayButtons = () => {
    if (availabilityStates.loading && isPdaProduct) {
      return (
        <CustomButton buttonoptions={{ variant: 'primary', size: isMobile || isTabletPortrait ? 'small' : 'medium' }}>
          <Loader />
        </CustomButton>
      )
    }

    if (
      (!isUserLoggedIn && isPdaProduct) ||
      (pdaProductAvailability && pdaProductAvailability?.code === 'PDA_GRATIS')
    ) {
      return (
        <CustomButton
          onClick={() => {
            history.push('/test-de-personalidad')
            window.scrollTo(0, 0)
          }}
          buttonoptions={{ variant: 'primary', size: isMobile || isTabletPortrait ? 'small' : 'medium' }}
          isLoading={mpOptions.primaryLoading}
        >
          <ButtonText>Hacer el test gratis</ButtonText>
        </CustomButton>
      )
    }

    return (
      <CustomButton
        onClick={clickHandler}
        buttonoptions={{ variant: 'primary', size: isMobile || isTabletPortrait ? 'small' : 'medium' }}
        isLoading={mpOptions.primaryLoading}
      >
        <ButtonText>{buttonText}</ButtonText>
      </CustomButton>
    )
  }

  return (
    <Page backgroundColor={backgroundColor} id={scrollRef || 'PRODUCT'}>
      <MainContainer>
        <Row>
          <Col xl={8} offset={{ xl: 2 }}>
            <Card>
              <ImgProduct src={productImage} />
              <CustomContainer>
                <div>
                  <ImgLogo src={logoImage} />
                  <Text>{description}</Text>
                </div>
                <PriceButtonWrapper>
                  <PriceContainer>
                    {product?.unitPrice && (
                      <>
                        <PriceText>
                          {moneySymbol}
                          {product?.unitPrice}
                        </PriceText>
                        <TaxText>+ impuestos</TaxText>
                      </>
                    )}
                  </PriceContainer>
                  {displayButtons()}
                </PriceButtonWrapper>
              </CustomContainer>
            </Card>
          </Col>
        </Row>
      </MainContainer>
    </Page>
  )
}

const states = ({ productsStore }) => ({
  product: productsStore?.producto,
  availability: productsStore?.productAvailability?.availability,
  availabilityStates: productsStore?.productAvailability?.states,
})

export default connect(states)(Component)
