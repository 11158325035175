export const modalPreguntas = {
  title: 'Cuentanos más de ti',
  subtitle: 'Completa estas preguntas para terminar tu postulación',
  placeholder: {
    input: 'Ingresa tu respuesta',
  },
  validation: {
    textArea: 'Completa este campo.',
    radioButton: 'Elige una opción.',
  },
  submitButton: 'Responder',
}
