import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Loader } from '@navent-jobs/ui-kit/components/loader'

// mixins
import { PreguntasContainer, Title, Subtitle, TitleWrapper, TitleContent } from './mixins'
import { DataLayersPostPostulacion } from '../../../../../../../../constants/gtm-events-post-postulacion'

interface PreguntasProps {
  isLoading: boolean
  hasAvisoFinalizado: boolean
  onClickSendAnswers: (idAviso: number, idPostulacion: number) => void
  postulacion: {
    avisoId: number
    id: number
    tienePreguntasPendientes: boolean
  }
  activeAvisoStates: {
    loading: boolean
  }
  sectionCard: string
}

const Component = ({
  isLoading,
  hasAvisoFinalizado,
  onClickSendAnswers,
  postulacion,
  activeAvisoStates,
  sectionCard,
}: PreguntasProps) => {
  const { t } = useTranslation()

  const [isLoadingButton, setLoadingButton] = useState(false)
  const { avisoId, id, tienePreguntasPendientes } = postulacion
  const { pathname } = useLocation()

  const onClickHandler = () => {
    if (!isLoading) {
      DataLayersPostPostulacion.responderPreguntas(pathname)
      onClickSendAnswers(avisoId, id)
      setLoadingButton(true)
    }
  }

  useEffect(() => {
    if (!activeAvisoStates?.loading) {
      setLoadingButton(activeAvisoStates?.loading)
    }
  }, [activeAvisoStates?.loading])

  /**
   * Si la postulación no tiene preguntas pendientes o el aviso esta finalizado, no renderizamos componente preguntas
   */
  if (!tienePreguntasPendientes || hasAvisoFinalizado || sectionCard !== 'mis-postulaciones') {
    return null
  }

  return (
    <PreguntasContainer onClick={onClickHandler} isLoading={isLoadingButton}>
      {isLoadingButton ? (
        <Loader />
      ) : (
        <>
          <TitleWrapper>
            <TitleContent>
              <Icon color="#EC0000" name="icon-light-info" size="16" />
              <Title>{t('postulaciones.preguntas.title')}</Title>
            </TitleContent>
            <Icon name="icon-light-caret-right" size="16" />
          </TitleWrapper>
          <Subtitle>{t('postulaciones.preguntas.subtitle')}</Subtitle>
        </>
      )}
    </PreguntasContainer>
  )
}

export default Component
