export default {
  academicReference: {
    description2: 'Si querés aparecer como una referencia académica de su {{cv}}, confirmá tus datos:',

    confirmed: {
      description: 'De esta manera, colaborás en su búsqueda de trabajo.',
    },
  },

  jobReference: {
    description2: 'Si querés aparecer como una referencia laboral de su {{cv}}, confirmá tus datos:',
    confirmed: {
      description: 'De esta manera, colaboras en su búsqueda de trabajo',
    },
  },

  form: {
    label: {
      telefonoError: 'Ingresá un teléfono válido',
    },
    placeholder: {
      name: 'Ingresá el Nombre',
      lastName: 'Ingresá el Apellido',
      email: 'Ingresá elemail',
    },
  },

  title: 'Referencia',
  ctaConfirm: 'Confirmar',
  ctaReject: 'Rechazar',
}
