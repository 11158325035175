import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { EmptySearchText, SidebarMenuFacet } from './mixins'

const enabledFilterTypes = ['area', 'provincia', 'localidad']

const FiltersWithSearch = ({ searchQueryFacet, setSearchQueryFacet, filterSelected, handleFacetSelected }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { t } = useTranslation()

  const facetsSelectedFiltered = useMemo(() => {
    return filterSelected?.facets?.filter(
      p =>
        !searchQueryFacet ||
        p.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/´/g, '')
          .includes(searchQueryFacet.toLowerCase()) ||
        p.name.toLowerCase().includes(searchQueryFacet.toLocaleLowerCase())
    )
  }, [filterSelected, searchQueryFacet])

  const placeholderString = facet => {
    switch (facet?.type) {
      case 'area':
        return t('filtros.placeholderSearchFacet.area')
      case 'provincia':
        return t('filtros.placeholderSearchFacet.provincia')
      case 'localidad':
        return t('filtros.placeholderSearchFacet.localidad')
      default:
        return ''
    }
  }

  return (
    <>
      {enabledFilterTypes.includes(filterSelected?.type) && (
        <Input
          id="search-facets"
          name="search-facets"
          type="text"
          value={searchQueryFacet}
          onChange={e => {
            const { value } = e.target
            setSearchQueryFacet(value)
          }}
          placeholder={t('filtros.placeholderSearchFacet.text', { facet: placeholderString(filterSelected) })}
          fieldOptions={{
            variant: 'darken',
            size: 'medium',
            before: <Icon name="icon-light-search" color={colors.secondary.normal} size="24" />,
          }}
        />
      )}
      {filterSelected && filterSelected.facets && facetsSelectedFiltered.length > 0 ? (
        facetsSelectedFiltered.map(facet => {
          return (
            <SidebarMenuFacet
              key={`${facet.idSemantico || facet.id}`}
              onClick={() => handleFacetSelected(filterSelected, { facet })}
            >
              {facet.name} <span>({facet.quantity})</span>
            </SidebarMenuFacet>
          )
        })
      ) : (
        <EmptySearchText>No se encontraron resultados</EmptySearchText>
      )}
    </>
  )
}

export default FiltersWithSearch
