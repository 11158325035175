import { Request, Post, Get, Delete, Put } from '@navent-jobs/config'
import {
  CreateAlertResType,
  CreateAlertType,
  EditAlertType,
  GetAlertType,
  StaticOption,
  SwitchAlertType,
} from '../redux/types/alert-v2'

export class ErrorAlert extends Error {}

class Alerts {
  public createAlert(alert: CreateAlertType): Promise<CreateAlertResType> {
    const path = '/api/alertas'
    const request = new Request()
    request.method = Post
    request.path = path
    request.body = alert
    return request
      .call()
      .then(resp => ({ ...resp, idFrecuencia: alert.idFrecuencia }))
      .catch(() => {
        throw new ErrorAlert('Error al crear la alerta')
      })
  }

  public getAlert(): Promise<GetAlertType> {
    const path = '/api/alertas'
    const request = new Request()
    request.method = Get
    request.path = path

    return request.call().catch(error => {
      throw new ErrorAlert(`Error obteniendo alertas, ${error}`)
    })
  }

  public async GetAlertasFrecuencia(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/alertasFrecuencia`
    request.method = Get
    return request.call().then(response => {
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async deleteAlert(alertaId) {
    const request = new Request()
    request.path = `/api/alertas/${alertaId}`
    request.method = Delete
    return request.call().catch(error => {
      throw new ErrorAlert(`Error eliminando alerta, ${error}`)
    })
  }

  public async editAlert(alert: EditAlertType) {
    const request = new Request()
    request.path = `/api/alertas/${alert.id}`
    request.method = Put
    request.body = {
      nombre: alert.nombre,
      idFrecuencia: alert.idFrecuencia,
    }

    return request.call().catch(error => {
      throw new ErrorAlert(`Error editando alerta, ${error}`)
    })
  }

  public async switchAlert(alert: SwitchAlertType) {
    const request = new Request()
    request.path = `/api/alertas/${alert.id}/status?status=${alert.status}`
    request.method = Put

    return request.call().catch(error => {
      throw new ErrorAlert(`Error editando alerta, ${error}`)
    })
  }
}

const messages = new Alerts()

export default messages
