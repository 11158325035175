import styled from 'styled-components'

export const SectionTitle = styled.div`
  margin-bottom: 16px;

  ${({ theme, mobileView }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      margin-bottom: 24px;
    }
  `}
`

export const PlaceholderContainer = styled('div')`
  margin-top: 24px;
`
export const Wrapper = styled.div`
  margin-top: ${({ noMarginTop }) => (noMarginTop ? 0 : '16px')};

  ${({ theme, mobileView, noMarginTop }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      margin-top: ${noMarginTop ? 0 : '32px'};
    }
  `}
`
