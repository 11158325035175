import { ConfettiActions, ConfettiState, SET_CONFETTI, initialState } from '../types/confetti'

const reducers = (state = initialState, action: ConfettiActions): ConfettiState => {
  if (action.type === SET_CONFETTI) {
    return {
      on: !state.on,
    }
  } else {
    return state
  }
}

export default reducers