import { Request, Get } from '@navent-jobs/config'

export class ErrorPromedioSalarial extends Error {}

class PromedioSalarial {
  public getPromediosSalariales = async () => {
    const request = new Request()
    request.path = `/api/salarios`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorPromedioSalarial(error)
      })
  }

  public getPromedioSalarialArea = async area => {
    const request = new Request()
    request.path = `/api/salarios?areaIdSemantico=${area}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response && response.length > 0 ? response[0] : response
      })
      .catch(error => {
        throw new ErrorPromedioSalarial(error)
      })
  }

  public calculateSalary = async salarioBruto => {
    const request = new Request()
    request.path = `/api/salarios/neto?salarioBruto=${salarioBruto}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorPromedioSalarial(error)
      })
  }
}

const promedioSalarial = new PromedioSalarial()
export default promedioSalarial
