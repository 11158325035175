export const getUrlParamByName = (name, url = window.location.href) => {
  const paramName = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  const indexNumber = 2

  if (!results) {
    return null
  }

  if (!results[indexNumber]) {
    return ''
  }
  return decodeURIComponent(results[indexNumber].replace(/\+/g, ' '))
}
