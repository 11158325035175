import styled from 'styled-components'

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    justify-content: flex-end;
  }
`
