import React from 'react'
import { useHistory } from 'react-router-dom'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { CardContentWrapper, CardContainer, Title, InfoContainer, CustomLink, CustomButton } from './mixins'
import { makeURL } from '@navent-jobs/interlinking'
import DetailPromedioSalarial from '../detail'

interface CardPromedioSalarialProps {
  promedioSalarial: {
    idSemantico: string
    nombre: string
    promedio: number
    cantidad: number
  }
  onClick?: Function | null
  iconBtn?: boolean
}

const CardPromedioSalarial = ({ promedioSalarial, onClick, iconBtn }: CardPromedioSalarialProps) => {
  const history = useHistory()
  const { isMobile, isTabletPortrait } = useWindowSize()
  const isMobileOrTablet = isMobile || isTabletPortrait

  const handleOnClick = () => {
    if (!iconBtn) {
      window.open(
        makeURL({
          subarea: promedioSalarial.idSemantico,
        }),
        '_blank'
      )
    } else if (onClick) {
      onClick(promedioSalarial)
    } else {
      history.push(`/salarios/${promedioSalarial.idSemantico}`)
    }
  }

  const ActionButton = () => {
    return iconBtn ? (
      <CustomButton>
        <Icon name="icon-light-arrow-ios-right" size="24" />
      </CustomButton>
    ) : (
      <CustomLink>Ver empleos</CustomLink>
    )
  }

  if (!promedioSalarial) return null

  return (
    <CardContentWrapper onClick={handleOnClick}>
      <CardContainer>
        <Title iconBtn={iconBtn}>{promedioSalarial.nombre}</Title>

        {!isMobileOrTablet ? (
          <InfoContainer iconBtn={iconBtn}>
            <DetailPromedioSalarial promedio={promedioSalarial.promedio} cantidad={promedioSalarial.cantidad} />
            <ActionButton />
          </InfoContainer>
        ) : (
          <ActionButton />
        )}
      </CardContainer>
    </CardContentWrapper>
  )
}

export default CardPromedioSalarial
