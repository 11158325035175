import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_LISTADO_EMPRESAS_SEGUIDAS,
  GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR,
  GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS,
} from '../types/save-company'

function* obtenerListadoEmpresasSeguidas({ payload }) {
  try {
    const result = yield call(services.empresasSeguidasService.getEmpresasSeguidas, payload)
    yield put({ type: GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* empresasSeguidas() {
  yield takeLatest<any>(GET_LISTADO_EMPRESAS_SEGUIDAS, obtenerListadoEmpresasSeguidas)
}
