export const products = {
  products: {
    lablab: {
      title: 'Evalúa y mejora tu CV con Miguru!',
      body: 'Realiza el análisis de tu curriculum y recibe un informe detallado con recomendaciones personalizadas para mejorar tu CV y posicionarte mejor en las búsquedas laborales.',
      cta: 'Conoce más',
      acquiredText: 'Lo quiero',
      discountTag: '¡20% de descuento!',
      discountLegend: '¡20% OFF en tu próximo informe!',
      preview: {
        lable: 'Compra el informe completo y revisa las recomendaciones para mejorar tu CV. ',
        cta: 'Comprar ahora',
        impuestos: '+imp.',
      },
    },
    nulinga: {
      title: 'Valida tu nivel de Inglés con Nulinga',
      bannerTitle: 'Valida tu nivel de Inglés y resalta tu perfil',
      body: 'Completa el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
      claim: 'Certifica tu nivel de inglés con un rápido cuestionario',
      cta: 'Conoce más',
      acquiredText: 'Ir a mi CV',
    },
  },
  modal: {
    coderhouse: {
      url: 'https://www.coderhouse.com/alianzas/laborum?utm_source=bumeran&utm_medium=alianzas&utm_campaign=chile',
      code: 'LABORUMCOD',
    },
  },
}
