export const facetsName = {
  // FACET TYPE
  tipo_trabajo: {
    // FACET_ID: "FACET NAME TRANSLATED"
    pasantia: 'Práctica Profesional',
  },
  url: {
    'tipo-trabajo': {
      pasantia: 'practica-profesional',
    },
  },
  'url-vuelta': {
    'tipo-trabajo': {
      'practica-profesional': 'pasantia',
    },
  },
}
