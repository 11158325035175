const errorPages = {
  '404': {
    title: 'No encontramos la página que buscas',
    subtitle: 'Cambió de identidad, se movió de lugar o dejó de existir.',
  },
  '500': {
    title: 'Tuvimos un pequeño inconveniente interno',
    subtitle: 'Pero, no te preocupes, ya pusimos manos a la obra.',
  },
  '503': {
    title: 'Hubo un pequeño inconveniente',
    subtitle: 'Pero, ¡no te vayas! Ya estamos lo estamos resolviendo.',
  },
  '503-cloudfare': {
    title: 'Hubo un pequeño inconveniente',
    subtitle: '¿Y si pruebas en unos minutos?',
  },
}

export default errorPages
