import { Request, Get, Post } from '@navent-jobs/config'

export class ErrorAnalysisCv extends Error {}

class AnalysisCv {
  public async GetAnalysisCv() {
    const request = new Request()
    request.path = `/api/curriculum/analysis`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorAnalysisCv(`Análisis de CV no encontrado`)
        }
      })
  }

  public async PostAnalysisCv() {
    const request = new Request()
    request.path = `/api/curriculum/analysis`
    request.method = Post
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorAnalysisCv(`Análisis de CV no encontrado`)
        }
      })
  }

  public async PostAnalysisCvPreview() {
    const request = new Request()
    request.path = `/api/curriculum/analysis/preview`
    request.method = Post
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorAnalysisCv(`Preview de análisis de CV no encontrado`)
        }
      })
  }
}

const nulinga = new AnalysisCv()
export default nulinga
