import {
  GET_AVISOS,
  GET_AVISOS_SUCCESS,
  GET_AVISOS_ERROR,
  CLEAN_AVISOS,
  ListadoAvisosState,
  ListadoAvisosActions,
  initialState,
  GET_WORDINGS,
  GET_WORDINGS_SUCCESS,
  GET_WORDINGS_ERROR,
  CANTIDAD_EMPLEOS,
  ADS,
  FACET_BANNER,
  RESET_LISTADO_AVISOS_STORE,
  SET_ACTIVE_LANDING,
  GET_AVISOS_V2,
  GET_AVISOS_SUCCESS_V2,
  GET_AVISOS_ERROR_V2,
  CLEAN_AVISOS_V2,
} from '../types/listado-avisos'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: ListadoAvisosActions): ListadoAvisosState => {
  switch (action.type) {
    case GET_AVISOS:
    case GET_AVISOS_V2:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    case GET_AVISOS_SUCCESS:
    case GET_AVISOS_SUCCESS_V2:
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case GET_AVISOS_ERROR:
    case GET_AVISOS_ERROR_V2:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      }
    case CLEAN_AVISOS:
    case CLEAN_AVISOS_V2:
      return {
        ...state,
        avisos: null,
        loading: true,
      }
    case GET_WORDINGS:
      return {
        ...state,
        seoWordings: '',
      }
    case GET_WORDINGS_SUCCESS:
      return {
        ...state,
        seoWordings: action.payload,
      }
    case GET_WORDINGS_ERROR:
      return {
        ...state,
        seoWordings: '',
      }
    case CANTIDAD_EMPLEOS:
      return {
        ...state,
        cantidadEmpleos: action.payload,
      }
    case ADS:
      return {
        ...state,
        ads: action.payload,
      }
    case FACET_BANNER:
      return {
        ...state,
        facetBanner: action.payload,
      }
    case RESET_LISTADO_AVISOS_STORE:
      return {
        ...state,
        loading: false,
        error: false,
        ads: true,
        cantidadEmpleos: true,
        facetBanner: {
          enabled: false,
        },
      }
    case SET_ACTIVE_LANDING:
      return {
        ...state,
        activeLanding: action.payload,
      }
    default:
      return state
  }
}

export default reducers
