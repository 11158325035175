import { LOGOUT, LOGOUT_RESET, Logout, LogoutReset } from '../types/logout'

export const logoutAction = (): Logout => {
  return {
    type: LOGOUT,
    payload: null,
  }
}

export const logoutResetAction = (): LogoutReset => {
  return {
    type: LOGOUT_RESET,
    payload: null,
  }
}
