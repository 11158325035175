import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const MyLoader = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={150}
        viewBox={`0 0 ${wrapperWidth} 150`}
        backgroundColor="#f3f3f3"
        foregroundColor="#d4d4d4"
        {...props}
      >
        <rect x="12" y="24" rx="4" ry="4" width={wrapperWidth / 2} height="18" />
        <rect x="12" y="70" rx="4" ry="4" width={wrapperWidth / 5} height="12" />
        <rect x="12" y="92" rx="4" ry="4" width={wrapperWidth / 4 + 25} height="18" />
        <rect x="12" y="118" rx="4" ry="4" width={wrapperWidth / 4} height="12" />
      </ContentLoader>
    </LoaderContainer>
  )
}
export default MyLoader
