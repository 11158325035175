import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

// ui-kit
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Checkbox } from '@navent-jobs/ui-kit/components/checkbox'
import { Textarea } from '@navent-jobs/ui-kit/components/textarea'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import Text from '@navent-jobs/ui-kit/components/text'

// mixins
import { ModalContainer, ModalTitle, ModalSelection, ButtonContainer, ModalCheck, LoaderContainer } from './mixins'

// redux
import { getMotivosNoInteresado, setNotInterested } from '../../../redux/actions/recomendados'
import { showSnackbarError } from '../../../redux/actions/applicant/curriculum/snackbar'

const CancelButton = ({ onClose }) => (
  <Button
    onClick={onClose}
    buttonoptions={{
      variant: 'primary',
      size: 'medium',
      type: 'link',
    }}
  >
    Cancelar
  </Button>
)

const isCheckboxSelected = opt => {
  return opt && typeof opt === 'number'
}

const isValidText = text => {
  return text && typeof text === 'string' && !isEmpty(text?.trim())
}

const getSelectedIds = values => {
  return values.idMotivos.filter(isCheckboxSelected)
}

const ModalNotInterested = ({
  avisoId,
  visible,
  setVisible,
  onClick,
  motivos,
  motivosLoading,
  notInterestedStatus,
  notInterestedId,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isMobile } = useScreenSize()
  const fieldSize = isMobile ? 'small' : 'medium'
  const [isValid, setIsValid] = useState(false)

  const { control, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      idMotivos: [],
      otroMotivo: null,
    },
  })

  const validate = value => {
    // Si hay al menos un checkbox seleccionado o se completa el textarea el formulario es valido
    if (isCheckboxSelected(value) || isValidText(value)) {
      setIsValid(true)
    } else {
      const values = getValues()
      setIsValid(isValidText(values.otroMotivo) || getSelectedIds(values).length > 0)
    }

    return true
  }

  const onSubmit = values => {
    if (values && isValid) {
      dispatch(
        setNotInterested({ avisoId, idMotivos: getSelectedIds(values), otroMotivo: values.otroMotivo?.trim() || null })
      )
    }
  }

  const onClose = () => {
    setIsValid(false)
    setVisible(false)
  }

  useEffect(() => {
    if (avisoId !== notInterestedId) {
      return
    }

    if (notInterestedStatus.success) {
      onClick()
    }
    if (notInterestedStatus.error) {
      dispatch(showSnackbarError('Ocurrió un error inesperado'))
    }
  }, [notInterestedStatus])

  useEffect(() => {
    if (visible && !motivos && !motivosLoading) {
      dispatch(getMotivosNoInteresado())
    }
  }, [visible])

  return (
    <Modal width={560} visible={visible} onClose={onClose}>
      <form>
        <ModalContainer>
          <ModalTitle>
            <Text type="title" size="xs" fontWeight="semibold" variant="secondary">
              {t('perfil.recomendados.modal.noInteresado.titulo')}
            </Text>
          </ModalTitle>

          {motivosLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <>
              <ModalSelection>
                <Text size="md">{t('perfil.recomendados.modal.noInteresado.subtitulo')}</Text>

                {motivos &&
                  motivos.map((item, index) => {
                    const { id } = item
                    return (
                      <ModalCheck key={id}>
                        <Controller
                          name={`idMotivos.${index}`}
                          control={control}
                          rules={{
                            validate: { validate },
                          }}
                          render={({ value, onChange }) => (
                            <Checkbox
                              id={id}
                              name={id}
                              label={item.descripcion}
                              onChange={e => onChange(e.target.checked ? id : undefined)}
                              checked={value}
                              fieldOptions={{
                                size: fieldSize,
                              }}
                            />
                          )}
                        />
                      </ModalCheck>
                    )
                  })}

                <Controller
                  name="otroMotivo"
                  control={control}
                  rules={{
                    validate: { validate },
                  }}
                  render={({ value, onChange }) => (
                    <Textarea
                      id="otroMotivo"
                      name="otroMotivo"
                      value={value}
                      onChange={onChange}
                      placeholder={t('perfil.recomendados.modal.noInteresado.otrosPlaceholder')}
                      maxLength={1000}
                      fieldOptions={{
                        label: <Text size="md">{t('perfil.recomendados.modal.noInteresado.otros')}</Text>,
                        size: fieldSize,
                        variant: 'darken',
                        minHeight: 93,
                        allowOnlyText: true,
                      }}
                      noBottomMargin
                    />
                  )}
                />
              </ModalSelection>

              <ButtonContainer>
                {!isMobile && <CancelButton onClose={onClose} />}
                <Button
                  onClick={e => {
                    e.preventDefault()
                    handleSubmit(onSubmit)()
                  }}
                  buttonoptions={{
                    variant: 'primary',
                    size: 'medium',
                    type: 'fill',
                  }}
                  isLoading={notInterestedStatus.loading}
                  disabled={!isValid}
                >
                  Aceptar
                </Button>
                {isMobile && <CancelButton onClose={onClose} />}
              </ButtonContainer>
            </>
          )}
        </ModalContainer>
      </form>
    </Modal>
  )
}

const states = ({ recomendadosStore }) => ({
  motivos: recomendadosStore.motivosNoInteresado,
  motivosLoading: recomendadosStore.motivosNoInteresadoStatus.loading,
  notInterestedStatus: recomendadosStore.notInterestedStatus,
  notInterestedId: recomendadosStore.notInterestedId,
})

export default connect(states)(ModalNotInterested)
