import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  VerEstadisticasOnClick: pathname => {
    let category, label

    if (pathname === 'postulaciones') {
      category = 'Mis Postulaciones'
      label = 'Icono estadisticas del aviso'
    } else {
      category = 'Aviso'
      label = 'CTA Ver estadisticas del aviso'
    }

    /**
     * El objetivo es conocer el uso que le dan los usuarios al boton de Busqueda por Beneficios.
     *
     * Debe dispararse cuando el usuario hace click en el boton de Busqueda por Beneficios.
     */

    TagManager.Push({
      event: 'trackEvent',
      content_group: category,
      content_type: 'Ir a estadisticas del aviso',
      eventLabel: label,
    })
  },
}
