import { status, StatusType } from './status'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const LOGOUT_RESET = 'LOGOUT_RESET'

export interface Logout {
  type: typeof LOGOUT
  payload: null
}

export interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS
  payload: null
}

export interface LogoutError {
  type: typeof LOGOUT_ERROR
  payload: null
}

export interface LogoutReset {
  type: typeof LOGOUT_RESET
  payload: null
}

export interface LogoutState {
  status: StatusType
}

export const initialState: LogoutState = {
  status: status.initial,
}

export type LogoutActions = Logout | LogoutSuccess | LogoutError | LogoutReset
