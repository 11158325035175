import { post, get, Get, Request, formatNameToStringId } from '@navent-jobs/config'
import { isEmpty } from 'lodash'
import i18n from 'i18next'
import { makeURL } from '../utils/search-avisos-v2'
import { PostularQrInput, PostularQrOutput } from '../redux/types/postulacion-qr'
import { getCookieData } from '../utils/cookies'
import { GetRecomendadosParams, GetRecomendadosResponse } from '../redux/types/recomendados'
import features from '../constants/features-per-country'
import { SITE_ID } from '../constants'

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled

interface PostularResponse {
  estado: string
  erroresFiltros: any
  id: number
}

export class ErrorDenuncia extends Error {}

// TODO Sacar esto y poner el translate correspondiente
const translateBffResponseToFront = bffFacetType => {
  const map = new Map()
  map.set('localidad', 'localizacion')
  map.set('subarea', 'subArea')
  map.set('tipo_trabajo', 'tipoTrabajo')
  map.set('modalidad_trabajo', 'modalidadTrabajo')
  map.set('empresa_id', 'empresa')
  map.set('nivel_laboral', 'nivelLaboral')

  return map.get(bffFacetType)
}

// TODO Sacar esto y poner el translate correspondiente
const translateFrontToFilterRequest = frontData => {
  const map = new Map()
  map.set('localizacion', 'localidad')
  map.set('subArea', 'subarea')
  map.set('tipoTrabajo', 'tipo_trabajo')
  map.set('modalidadTrabajo', 'modalidad_trabajo')

  return map.get(frontData)
}

const translateTipoTrabajo = (facetTranslations, aviso) => {
  if (
    facetTranslations &&
    facetTranslations.tipo_trabajo &&
    facetTranslations.tipo_trabajo[aviso.tipoTrabajo.stringId]
  ) {
    // eslint-disable-next-line no-param-reassign
    aviso.tipoTrabajo.nombre = facetTranslations.tipo_trabajo[aviso.tipoTrabajo.stringId]
  }
}

const translateArea = (facetTranslations, aviso) => {
  if (facetTranslations && facetTranslations.area && facetTranslations.area[aviso.area.stringId]) {
    aviso.area.nombre = facetTranslations.area[aviso.area.stringId]
  }
}

const translateSubarea = (facetTranslations, aviso) => {
  if (facetTranslations && facetTranslations.subarea && facetTranslations.subarea[aviso.subArea.stringId]) {
    aviso.subArea.nombre = facetTranslations.subarea[aviso.subArea.stringId]
  }
}

const traslateAvisoData = aviso => {
  if (!aviso || !aviso.tipoTrabajo) return
  const facetTranslations: any = i18n.t('facetsName', { returnObjects: true })
  // because if no translation facetTraslations = string
  if (!(facetTranslations instanceof Object)) return
  translateTipoTrabajo(facetTranslations, aviso)
  translateArea(facetTranslations, aviso)
  translateSubarea(facetTranslations, aviso)
}

const translateFilterId = (data: { name: string; value: any }) => {
  if (searchAvisosV2Enabled && (data.value.idSemantico || data.value.localidadIdSemantico)) {
    return data.value.localidadIdSemantico || data.value.idSemantico
  }
  return data.value.localidadStringId
    ? `${data.value.localidadStringId}-${data.value.provinciaStringId}`
    : undefined || data.value.stringId || (data.value.id ? data.value.id.toString() : undefined)
}

class FichaAvisoService {
  public async denunciarAviso(data) {
    return post(`/api/candidates/aviso/${data.idAviso}/denunciar`, data.denuncia)
  }

  public async getFicha(avisoId: Promise<any>) {
    const request = new Request()
    request.path = `api/candidates/fichaAvisoNormalizada/${avisoId}`
    request.method = Get
    request.headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' }
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorDenuncia(`Aviso no encontrado`)
        }
        traslateAvisoData(response.aviso)
        const { localizacion, tipoTrabajo, subArea, modalidadTrabajo, empresa, nivelLaboral } = response.aviso

        const datas = [
          // eslint-disable-next-line object-shorthand
          { name: 'localidad', value: localizacion },
          // eslint-disable-next-line object-shorthand
          { name: 'tipo_trabajo', value: tipoTrabajo },
          // eslint-disable-next-line object-shorthand
          { name: 'subarea', value: subArea },
          // eslint-disable-next-line object-shorthand
          { name: 'modalidad_trabajo', value: modalidadTrabajo },
          // eslint-disable-next-line object-shorthand
          { name: 'empresa_id', value: empresa },
        ]
        if (nivelLaboral) {
          // eslint-disable-next-line object-shorthand
          datas.push({ name: 'nivel_laboral', value: nivelLaboral })
        }

        const filterRequest = datas.map(data => ({
          type: data.name,
          id: translateFilterId(data),
        }))
        const requestJson = JSON.stringify(filterRequest)
        const facetsUrl = searchAvisosV2Enabled ? 'api/avisos/facetsV2' : 'api/avisos/facets'
        return get(`${facetsUrl}?q=${requestJson}`, { sendCookies: true })
          .then(facetsResponse => {
            facetsResponse.forEach(facet => {
              // eslint-disable-next-line no-param-reassign
              response.aviso[translateBffResponseToFront(facet.type)].cantidadAvisos = facet.quantity
            })
            return response
          })
          .catch(e => {
            console.error(`Error al consultas todas las facetas desde ficha aviso. Error : ${e}`)
            return response
          })
      })
      .catch(error => {
        return error
      })
  }

  public postPostulacionAviso = async ({
    id: idAviso,
    salarioBrutoPretendido,
    actualizarSalario,
    feria = false,
    respuestas,
    preguntasObligatorias = false,
  }: {
    id: string
    salarioBrutoPretendido: string
    actualizarSalario: boolean
    feria?: boolean
    respuestas?: {}[]
    preguntasObligatorias: boolean
  }): Promise<PostularResponse> => {
    const optionalData: any = {}
    const FERIA_ORIGIN_ID = 'feria'

    // Vemos si el registro es x una UTM Campaign
    let utmOrigen = getCookieData('utm_campaign', document.cookie)

    if (!utmOrigen) {
      // Obtenemos el origen de recomendados, solo si no hay UTM campaign
      const query = new URLSearchParams(window.location.search)
      utmOrigen = query.get('ia_origen')
    }

    if (feria) {
      optionalData.origenPostulacion = FERIA_ORIGIN_ID
    }
    if (respuestas) {
      optionalData.respuestas = respuestas
    }

    const response = await post(`/api/candidates/aviso/${idAviso}/postularV2`, {
      salarioPretendido: salarioBrutoPretendido || null,
      actualizarSalario,
      utmOrigen,
      preguntasObligatorias,
      feria,
      ...optionalData,
    })

    return response
  }

  public envioRespuestas(payload) {
    return post(`api/candidates/aviso/${payload.idPostulacion}/respuestas`, payload.respuestas)
  }

  public getBusquedasRelacionadas(aviso) {
    traslateAvisoData(aviso)
    const busquedasStaticas = [
      {
        titulo: `Trabajos en ${aviso.localizacion.provinciaDetalle}`,
        link: makeURL({
          provincia: searchAvisosV2Enabled
            ? aviso.localizacion.provinciaIdSemantico
            : aviso.localizacion.provinciaStringId,
        }),
      },
      {
        titulo: `Trabajos en ${aviso.localizacion.localidadDetalle}`,
        link: makeURL({
          provincia: searchAvisosV2Enabled
            ? aviso.localizacion.provinciaIdSemantico
            : aviso.localizacion.provinciaStringId,
          localidad: searchAvisosV2Enabled
            ? aviso.localizacion.localidadIdSemantico
            : aviso.localizacion.localidadStringId,
        }),
      },
      {
        titulo: `Trabajos en ${aviso.area.nombre}`,
        link: makeURL({ area: searchAvisosV2Enabled ? aviso.area.idSemantico : aviso.area.stringId }),
      },
      {
        titulo: `Trabajos en ${aviso.subArea.nombre}`,
        link: makeURL({ subarea: searchAvisosV2Enabled ? aviso.subArea.idSemantico : aviso.subArea.stringId }),
      },
    ]

    const busquedasDinamicas =
      aviso && aviso.busquedasRelacionadas
        ? aviso.busquedasRelacionadas.map(busqueda => {
            return {
              titulo: busqueda.titulo,
              link: makeURL({ busqueda: formatNameToStringId(busqueda.titulo) }),
            }
          })
        : []

    return [...busquedasStaticas, ...busquedasDinamicas]
  }

  public async getAvisosPostPostulacion(avisoId, limit) {
    return get(`/api/candidates/getRecommendedOrSimilar?avisoId=${avisoId}&limit=${limit}`)
  }

  public async getAvisosRecomendados({
    avisoId,
    limit,
    source,
  }: GetRecomendadosParams): Promise<GetRecomendadosResponse> {
    return get('/api/candidates/aviso/recomendados', { params: { avisoId, limit, source } })
  }

  public postularQr = async ({ idAviso }: PostularQrInput): Promise<PostularQrOutput> => {
    const response = await post(`/api/candidates/aviso/${idAviso}/postular`, { origenPostulacion: 'qr' })
    return {
      estado: response.estado,
      motivoEspera: response.motivoEspera,
    }
  }
}

const fichaAvisoService = new FichaAvisoService()
export default fichaAvisoService
