import { put, call, takeLatest } from 'redux-saga/effects'
import { IS_ZONA_JOBS } from '../../constants'
import services from '../../services/save-job-posting'
import { GET_RECOMENDADOS_LISTADO } from '../types/recomendados'
import {
  SET_SAVE_JOB_POSTING,
  SET_SAVE_JOB_POSTING_SUCCESS,
  SET_SAVE_JOB_POSTING_ERROR,
  DELETE_SAVE_JOB_POSTING,
  DELETE_SAVE_JOB_POSTING_SUCCESS,
  DELETE_SAVE_JOB_POSTING_ERROR,
  GET_AVISOS_GUARDADOS,
  GET_AVISOS_GUARDADOS_SUCCESS,
  GET_AVISOS_GUARDADOS_ERROR,
  SetSaveJobPosting,
  DeleteSaveJobPosting,
  REFRESH_AVISOS_PERFIL,
} from '../types/save-job-posting'

function* getAvisosGuardados({ payload }) {
  try {
    const result = yield call(services.getAvisosGuardados, payload)

    yield put({ type: GET_AVISOS_GUARDADOS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_AVISOS_GUARDADOS_ERROR, error })
  }
}

function* saveJobPosting({ payload }: SetSaveJobPosting) {
  try {
    const result = yield call(services.setSaveJobPosting, { payload })
    yield put({ type: SET_SAVE_JOB_POSTING_SUCCESS, payload: result })
    yield put({ type: REFRESH_AVISOS_PERFIL, recomendados: true })
  } catch (error) {
    yield put({ type: SET_SAVE_JOB_POSTING_ERROR, error })
  }
}

function* deleteSaveJobPosting({ payload }: DeleteSaveJobPosting) {
  try {
    const result = yield call(services.removeSaveJobPosting, { payload })
    yield put({ type: DELETE_SAVE_JOB_POSTING_SUCCESS, payload: result })
    yield put({ type: REFRESH_AVISOS_PERFIL, recomendados: true })
  } catch (error) {
    yield put({ type: DELETE_SAVE_JOB_POSTING_ERROR, error })
  }
}

function* refreshAvisosPerfil({ recomendados }) {
  if (
    (IS_ZONA_JOBS && window.location.pathname.includes('/postulantes/perfil')) ||
    (!IS_ZONA_JOBS && window.location.pathname === '/')
  ) {
    if (recomendados) {
      yield put({ type: GET_RECOMENDADOS_LISTADO, payload: { pageSize: 5 } })
    }
    yield put({ type: GET_AVISOS_GUARDADOS, payload: { pageSize: 1 } })
  }
}

/**
 * Watchers
 */
export default function* component() {
  yield takeLatest(SET_SAVE_JOB_POSTING, saveJobPosting)
  yield takeLatest(DELETE_SAVE_JOB_POSTING, deleteSaveJobPosting)
  yield takeLatest<any>(GET_AVISOS_GUARDADOS, getAvisosGuardados)
  yield takeLatest<any>(REFRESH_AVISOS_PERFIL, refreshAvisosPerfil)
}
