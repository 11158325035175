import styled from 'styled-components'

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: ${({ addMarginTop }) => (addMarginTop ? '16px' : 0)};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    text-align: inherit;
  }
`
