import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import { Title, Description, Page } from './mixins'

const Component = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <MainContainer noGutter>
        <Row>
          <Col lg={10} offset={{ lg: 1 }}>
            <Title>{t('productLanding.nulingaCourse.share.title')}</Title>
            <Description>{t('productLanding.nulingaCourse.share.description', { cv: t('cv') })}</Description>
          </Col>
        </Row>
      </MainContainer>
    </Page>
  )
}

export default Component
