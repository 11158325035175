import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'

// components
import HeaderPlaceholder from './placeholder'
import SocialShare from '../../../../components/SocialShare'
import CompanyRating from '../../../../components/card-company/rating'
import LinkGptw from '../../../../components/Gptw/link'
import logoGPTW from '../../../../assets/images/calificar-empresas/GPTW_Logo.svg'
import BusquedaActivaChip from '../../../../components/busqueda-activa-chip'

import {
  MainWrapper,
  HeaderContainer,
  CompanyLogoWrapper,
  CompanyLogo,
  Company,
  ShareWrapperButton,
  CompanyContainer,
  Review,
  RatingContainer,
  LinkCustom,
  Spacer,
  RatingText,
  CompanyLogoGptwWrapper,
  BusquedaActivaContainer,
  ImagesContainer,
} from './mixins'

import { userLoggedIn } from '../../../../utils/cookies'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'
import AnchorBar from '../AnchorBar'
import SeguirButton from '../seguir-button'

const seguirEmpresaEnabled = features[SITE_ID || 'BMAR'].seguirEmpresa.enabled

interface HeaderProps {
  company?: {
    denominacion?
    logoURL?
    id?
    gptwUrl?
    busquedaActiva?: boolean
    productoLookAndFeel?
  }
  shareData: {
    shareUrl: string | ''
    shareTitle: string | ''
    shareHelperDescription: string | ''
  }
  isLoading: boolean
  rating?: number
  applicantReview: boolean
  loadingReviews: boolean
  visibleId: string
  isNavResized: boolean
  top: number | null
}

const Header = ({
  company,
  isLoading,
  shareData,
  rating,
  applicantReview,
  loadingReviews,
  visibleId,
  isNavResized,
  top,
}: HeaderProps) => {
  const { t } = useTranslation()
  const isUserLogged = userLoggedIn()
  const reviewEnabled = features[SITE_ID || 'BMAR'].reviews.enabled
  const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled

  const { isMobile } = useWindowSize()

  const isMobileOrTabletPortrait = window.innerWidth < 992

  const showSocialShare = !isMobileOrTabletPortrait

  const url = shareData?.shareUrl
  const title = shareData?.shareTitle
  const description = shareData?.shareHelperDescription
  const showGptw = gptwEnabled && company?.gptwUrl

  const handleOnClick = () => {
    DataLayersReviews.goToReview(window.location.pathname)
  }

  useEffect(() => {
    const scrollFunction = () => {
      const header = document.getElementById('sticky-container-feria')?.getBoundingClientRect().top || 0
      const el = document.getElementById('company-logo-feria')
      const imageContainer = document.getElementById('image-container')
      const gptwLogo = document.getElementById('gptw-logo')
      if (el && top !== null && company?.productoLookAndFeel) {
        const topOfScreen = header + top - (isMobileOrTabletPortrait ? 56 : 104) <= 0
        // 104 es la medida del header desktop y 56 del header mobile
        el.style.top = topOfScreen ? '0' : `${top}px`
        el.style.width = topOfScreen || isMobileOrTabletPortrait ? `134px` : `166px`
        el.style.minWidth = topOfScreen || isMobileOrTabletPortrait ? `134px` : `166px`
        el.style.height = topOfScreen ? `85px` : `100%`
        if (imageContainer) {
          imageContainer.style.marginTop = topOfScreen && isMobileOrTabletPortrait ? '20px' : '0'
          imageContainer.style.marginBottom = topOfScreen && isMobileOrTabletPortrait ? '10px' : '0'
        }
        if (gptwLogo) {
          gptwLogo.style.top = topOfScreen ? '0' : `${top}px`
          gptwLogo.style.minWidth =
            topOfScreen || isMobileOrTabletPortrait ? (!isMobileOrTabletPortrait ? '48px' : `53px`) : `35px`
          gptwLogo.style.width = topOfScreen || isMobileOrTabletPortrait ? `48px` : `61px`
        }
      }
    }

    scrollFunction()
    window.addEventListener('scroll', scrollFunction, true)

    return () => {
      window.removeEventListener('scroll', scrollFunction, true)
    }
  }, [top, isMobileOrTabletPortrait])

  const Rating = () => {
    if (!reviewEnabled || loadingReviews) return null

    return (
      <Review>
        {rating ? (
          <RatingContainer>
            <CompanyRating reviewAverage={rating} singleStar={isMobile} />
          </RatingContainer>
        ) : (
          <RatingText>{t('review.access.noReviews')}</RatingText>
        )}

        <Spacer>-</Spacer>

        {!applicantReview && company?.id ? (
          <div onClick={handleOnClick}>
            <LinkCustom
              href={
                isUserLogged
                  ? `/perfiles/${company.id}/calificacion`
                  : `/login?returnTo=/perfiles/${company.id}/calificacion`
              }
              linkoptions={{ variant: 'primary', type: 'link', size: 'small' }}
            >
              {t('review.access.button')}
            </LinkCustom>
          </div>
        ) : (
          <RatingText>{t('review.access.buttonReviewed')}</RatingText>
        )}
      </Review>
    )
  }

  if (isLoading || top === null) {
    return (
      <MainWrapper>
        <HeaderPlaceholder />
      </MainWrapper>
    )
  }

  return (
    <MainWrapper>
      <MainContainer>
        <Row>
          <Col>
            <div>
              <HeaderContainer lookandfeel={!!company?.productoLookAndFeel} companyHasLogo={company?.logoURL}>
                {(company?.logoURL || showGptw) && (
                  <ImagesContainer id="image-container" lookandfeel={!!company?.productoLookAndFeel}>
                    {company?.logoURL && (
                      <CompanyLogoWrapper
                        id="company-logo-feria"
                        lookandfeel={!!company?.productoLookAndFeel}
                        top={`${top}px`}
                      >
                        <CompanyLogo src={company?.logoURL} />
                      </CompanyLogoWrapper>
                    )}

                    {showGptw && (
                      <CompanyLogoGptwWrapper id="gptw-logo" top={`${top}px`}>
                        <img width="100%" height="100%" src={logoGPTW} alt="logo GPTW" />
                      </CompanyLogoGptwWrapper>
                    )}
                  </ImagesContainer>
                )}

                <CompanyContainer hasLogo={company?.logoURL || showGptw}>
                  <Company>{company?.denominacion}</Company>
                  <BusquedaActivaContainer addMargin={!showGptw}>
                    {showGptw ? <LinkGptw url={company?.gptwUrl} name={company?.denominacion} /> : <Rating />}
                    {company?.busquedaActiva && <BusquedaActivaChip busquedaActiva={company?.busquedaActiva} />}
                  </BusquedaActivaContainer>
                </CompanyContainer>

                {/* Cta que habilita interfaz para compartir */}
                {(seguirEmpresaEnabled || showSocialShare) && (
                  <ShareWrapperButton>
                    {seguirEmpresaEnabled && !isMobileOrTabletPortrait && (
                      <SeguirButton empresaId={company?.id} isLoading={isLoading} />
                    )}
                    {showSocialShare && (
                      <SocialShare
                        shareUrl={url}
                        shareTitle={title}
                        shareHelperDescription={description}
                        modalTitle={t('share.modal.perfilEmpresa.title')}
                      />
                    )}
                  </ShareWrapperButton>
                )}
              </HeaderContainer>
              {isMobileOrTabletPortrait && (
                <AnchorBar isLoading={isLoading} visibleId={visibleId} isNavResized={isNavResized} company={company} />
              )}
            </div>
          </Col>
        </Row>
      </MainContainer>
    </MainWrapper>
  )
}

export default Header
