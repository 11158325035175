import { TagManager } from '@navent-jobs/config'

export const DataLayersMisPostulaciones = {
  pageView: () => {
    TagManager.Push({
      event: 'trackContentGroup',
      content_group: 'Mis Postulaciones',
    })
  },
  avisosSimilares: () => {
    TagManager.Push({
      event: 'select_content',
      content_type: 'Listado de mis postulaciones',
      item_id: 'CTA Ver avisos similares',
    })
  },
}
