import styled from 'styled-components'

export const Company = styled('h3')`
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 24, 0.6);
  margin: 0px 3px 0px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    font-size: 12px;
    line-height: 16px;
  }
`
