const signUp = {
  title: 'Creá tu cuenta y encontrá tu empleo ideal',
  ingresaNombre: 'Ingresá tu nombre',
  ingresaApellido: 'Ingresá tu apellido',
  ingresaEmail: 'Ingresá tu email',
  ingresaContrasena: 'Ingresá tu contraseña',
  'Ingresá un número': 'Ingresá un número',
  conCuenta: '¿Ya tenés cuenta?',
  ingresa: 'Ingresá como candidato',
  linkEmpresas: 'Registrate como empresa',
  'Ingresá al menos 6 caracteres': 'Ingresá al menos 6 caracteres',
  'Ingresá un nombre válido': 'Ingresá un nombre válido',
  'Ingresá un apellido válido': 'Ingresá un apellido válido',
  'Ingresá un email válido': 'Ingresá un email válido',
  'Ingresá una contraseña válida': 'Ingresá una contraseña válida',

  documento: {
    tipo: {
      placeholder: 'Tipo',
      label: 'Tipo de documento',
    },
    numero: {
      placeholder: 'Número',
    },
  },
  telefono: {
    placeholder: 'Número',
    label: 'Teléfono celular',
    format: 'Ingresá un teléfono válido.',
  },
}

export default signUp
