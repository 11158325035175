import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { GET_AREA_ADS, GET_AREA_ADS_ERROR, GET_AREA_ADS_SUCCESS } from '../types/area-ads'

function* getAreaAds() {
  try {
    const result = yield call(services.areaAds.getAreaAds)
    yield put({ type: GET_AREA_ADS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_AREA_ADS_ERROR })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_AREA_ADS, getAreaAds)
}
