export const GET_PRIVACY = 'GET_PRIVACY'
export const GET_PRIVACY_RESET = 'GET_PRIVACY_RESET'
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCES'
export const GET_PRIVACY_ERROR = 'GET_PRIVACY_ERROR'

export const SET_PRIVACY = 'SET_PRIVACY'
export const SET_PRIVACY_RESET = 'SET_PRIVACY_RESET'
export const SET_PRIVACY_SUCCESS = 'SET_PRIVACY_SUCCESS'
export const SET_PRIVACY_ERROR = 'SET_PRIVACY_ERROR'

export interface GetPrivacy {
  type: typeof GET_PRIVACY
  payload: PrivacyType | null
}
export interface GetPrivacySuccess {
  type: typeof GET_PRIVACY_SUCCESS
  payload: PrivacyType | null
}

export interface GetPrivacyReset {
  type: typeof GET_PRIVACY_RESET
  payload: null
}

export interface GetPrivacyError {
  type: typeof GET_PRIVACY_ERROR
  payload: boolean
}

export interface SetPrivacy {
  type: typeof SET_PRIVACY
  payload: PrivacyType
}

export interface SetPrivacyError {
  type: typeof SET_PRIVACY_ERROR
  payload: boolean
}

export interface SetPrivacyReset {
  type: typeof SET_PRIVACY_RESET
  payload: null
}

export interface SetPrivacySuccess {
  type: typeof SET_PRIVACY_SUCCESS
  payload: PrivacyType
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export interface PrivacyState {
  privacy: PrivacyType | null
  getStates: Status
  setStates: Status
}

export interface PrivacyType {
  tipo: string
}

export const initialPrivacyState = {
  privacy: null,
  getStates: initialStatus,
  setStates: initialStatus,
}

export type PrivacyActions =
  | GetPrivacy
  | SetPrivacy
  | GetPrivacyError
  | GetPrivacyReset
  | GetPrivacySuccess
  | SetPrivacyError
  | SetPrivacySuccess
  | SetPrivacyReset
