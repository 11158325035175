import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  DownloadCurriculum,
  DOWNLOAD_CURRICULUM,
  DOWNLOAD_CURRICULUM_ERROR,
  DOWNLOAD_CURRICULUM_SUCCESS,
} from '../types/applicant/descarga-curriculum'

function* downloadCurriculum({ payload }: DownloadCurriculum) {
  try {
    const result = yield call(services.curriculum.downloadCurriculum, payload)
    yield put({ type: DOWNLOAD_CURRICULUM_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: DOWNLOAD_CURRICULUM_ERROR, payload: error.message })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest(DOWNLOAD_CURRICULUM, downloadCurriculum)
}
