import React from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Container } from '@navent-jobs/ui-kit/components/grid'

// images
import backgroundLogo from '../../../../assets/images/background-jobint-logo.svg'
import imgPersona1 from '../../../../assets/images/products/marketplace-persona-1.svg'
import imgPersona2 from '../../../../assets/images/products/marketplace-persona-2.svg'

// mixins
import { Title, TitleContainer, CustomImg, Section, CustomLink, CustomRow, ImageCol, TitleCol } from './mixins'

const Component = () => {
  const { isTabletLandscape, isDesktop } = useWindowSize()
  const { t } = useTranslation()

  return (
    <Section bkg={backgroundLogo} color="#ffffff" id="header">
      <Container>
        <CustomRow>
          <ImageCol lg={5} noGutter>
            {isTabletLandscape || isDesktop ? (
              <CustomImg src={imgPersona1} alt="persona" width="auto" height="auto" />
            ) : (
              <CustomImg src={imgPersona2} alt="persona" width="auto" height="auto" />
            )}
          </ImageCol>

          <TitleCol offset={{ lg: 1 }} lg={6}>
            <TitleContainer>
              <Title>{t('products.header.title')}</Title>
              <CustomLink
                href="#products"
                linkoptions={{ variant: 'primary', type: 'fill', size: 'medium', anchor: true }}
              >
                Ver productos
              </CustomLink>
            </TitleContainer>
          </TitleCol>
        </CustomRow>
      </Container>
    </Section>
  )
}

export default Component
