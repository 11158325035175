import styled from 'styled-components'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'

export const Form = styled.form`
  padding: 16px 16px 80px;
  min-height: 248px;
  height: 100%;
`

const left = `left: 0;`
const right = `right: 0;`

export const SidebarMenu = styled(Sidebar)`
  width: 100vw;
  height: 100vh;
  background-color: #ffe600;
  position: fixed;
  top: 0;
  ${props => (props.position === 'right' ? left : right)}
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
`

export const SidebarTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 16px;
`
