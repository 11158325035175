const changePassword = {
  'Ingresa al link del email y elige una nueva contraseña': 'Ingresá al link del email y elegí una nueva contraseña.',
  'Cambiá tu contraseña': 'Cambiá tu contraseña',
  'Elegí una nueva contraseña para ingresar a tu cuenta.': 'Elegí una nueva contraseña para ingresar a tu cuenta.',
  'Ingresá la contraseña': 'Ingresá la contraseña',
  'Repetí la contraseña': 'Repetí la contraseña',
  'Ingresá al menos 6 caracteres': 'Ingresá al menos 6 caracteres.',
  'Ingresa tu email y te enviaremos los pasos para elegir una nueva':
    'Ingresá tu email o usuario y te enviaremos los pasos para elegir una nueva.',
  'Ingresa tu email': 'Ingresá tu email o usuario ',
  'Ingresa un email válido': 'Ingresá un email válido.',
}

export default changePassword
