import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'
import Card from '../postulaciones/components/card'

export const PageContent = styled('div')`
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-top: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-top: 0px;
  }
`

export const HeaderPage = styled('div')`
  width: 100%;
  height: 56px;
  background-color: white;
  margin-top: -56px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 999;
`

export const HeaderContainer = styled('div')`
  height: 100%;
  max-width: 1440px;
  padding-right: 12px;
  padding-left: 12px;
  display: flex;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding-right: 32px;
    padding-left: 32px;
  }
`

export const CardCustom = styled(Card)`
  background: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 24px;
  }
`

export const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0px;
  border: 0;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin: 22px 0px;
  }
`

export const Label = styled('div')`
  font-size: 16px;
  margin-left: 6px;
`

export const ContainerGraphs = styled('div')`
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 23px;
`

export const GraphWrapper = styled('div')`
  padding: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 16px 24px ${({ plusHeight }) => (plusHeight ? `${16 + plusHeight}px` : '16px')} 24px;
  }
`

export const GraphContainer = styled('div')`
  position: relative;
  height: ${({ height, loading }) => (loading ? '100%' : `${height}px`)};
  width: 100%;

  // fix para centrar el grafico
  ${({ layout }) =>
    layout === 'horizontal' &&
    `
    & > div {
      & > div {
        & > svg {
          margin-top: -30px;
          overflow: visible;
        }
      }
    }
  `}

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 0;
  }
`

export const Info = styled.div`
  display: flex;
  gap: 6px;
  margin: 6px 0;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    position: absolute;
    right: 44px;
    bottom: 100px;
    width: 160px;
    margin: 0;
  }
`

export const InfoExpe = styled.div`
  display: flex;
  gap: 6px;
`

export const InfoText = styled.div`
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey60};
`

export const Title = styled.h1`
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 32px;
  }
`

export const TitleContainer = styled.div`
  margin: 19px 14px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 32px 26px 10px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey24};
  width: calc(100% - 32px);
  margin: auto;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: calc(100% - 52px);
  }
`

export const EstadoPostulacionContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 15px;
  }
`
