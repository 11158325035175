import { useTranslation } from 'react-i18next'

// constants
import { SITE_ID } from '../../../constants'
import features from '../../../constants/features-per-country'

// images
import NulingaLogo from '../../../assets/images/products/products-logos/nulinga.svg'

// eslint-disable-next-line prettier/prettier
const showNulingaCourses = features[SITE_ID || 'BMAR']?.products?.nulingaCourse?.marketplace

export function useCourses() {
  const { t } = useTranslation()

  const coursesMarketplace = [
    {
      enabled: showNulingaCourses,
      key: 'nulinga-curso',
      logo: NulingaLogo,
      title: t('products.products.nulingaCursos.title'),
      body: t('products.products.nulingaCursos.claim'),
      href: '/postulantes/productos/curso-nulinga',
      alt: 'Nulinga curso',
      hrefText: t('products.products.nulingaCursos.cta'),
      isLoading: false,
    },
  ]

  return coursesMarketplace.filter(course => course.enabled)
}
