const postulaciones = {
  empty: {
    title: 'Por el momento no encontramos postulaciones para este filtro',
    subtitle: '¡Realiza una búsqueda y postúlate a tu empleo ideal!',
    buttonSubmit: 'Buscar trabajos',
  },
  estados: {
    todos: 'Todos',
    activos: 'Avisos activos',
    enviado: 'CV enviado',
    leido: 'CV leído',
    enproceso: 'En proceso de selección',
    finalizado: 'Avisos finalizados',
    contactado: 'Contactado',
  },
  card: {
    salario: 'Sueldo pretendido: ',
    similares: 'Ver avisos similares',
  },
  search: {
    placeholder: 'Puesto, empresa o palabra clave',
    button: 'Buscar postulaciones',
  },
  statistics: {
    aviso: {
      title: 'Estadísticas del aviso',
      subtitle: 'Los datos corresponden a los postulados al aviso',
      verEstadisticas: 'Ver estadísticas',
    },
    me: 'Yo',
    button: {
      back: 'Ir a mis postulaciones',
    },
    recommended: {
      title: 'Estos avisos de empleo te pueden interesar:',
    },
    experience: {
      warning:
        'Los datos corresponden a los postulados que tengan experiencia en el área <0>{{area}}</0> solicitada en la oferta de empleo',
    },
  },
  preguntas: {
    title: 'Tienes preguntas sin responder',
    subtitle: 'Contesta las preguntas para terminar tu postulación',
  },
  seleccion_estado: {
    modal: {
      titulo: '¿Cómo te fue en el proceso de selección de {{tituloAviso}}?',
      pregunta: 'Selecciona el estado del proceso que mejor se ajuste en tu postulación',
      aceptar: 'Aceptar',
      cancelar: 'Cancelar',
    },
    estados: {
      NO_CONTACTADO: 'No me han contactado',
      CONTACTADO: 'Me llamaron para una entrevista',
      ENTREVISTADO: 'Me entrevistaron',
      SELECCIONADO: 'Quedé seleccionado',
      NO_SELECCIONADO: 'No quedé seleccionado',
    },
    actualizarEstado: 'Actualiza tu estado',
    info: ' (Si lo seleccionas no puedes cambiar el estado)',
  },
}

export default postulaciones
