import React from 'react'
import { Title, LinkAviso } from './mixins'

const MAX_CHARS_TITLE = 70

const Component = ({ id, refTo, onClick, titulo, estado, tipoDePostingId }) => {
  const hasAvisoFinalizado = estado === 'finalizada'
  const avisoTipoPostingNormal = tipoDePostingId === 1

  return (
    <Title buttons={false}>
      <LinkAviso
        onClick={event => {
          if (onClick && !hasAvisoFinalizado && avisoTipoPostingNormal) {
            onClick({ event, id: id || null })
          }
        }}
        href={refTo}
        linkoptions={{
          target: '_blank',
          type: 'link',
          variant: hasAvisoFinalizado ? 'black' : 'secondary',
        }}
        disabled={hasAvisoFinalizado || !avisoTipoPostingNormal}
      >
        {titulo.length > MAX_CHARS_TITLE ? `${titulo.substring(0, MAX_CHARS_TITLE)}...` : titulo}
      </LinkAviso>
    </Title>
  )
}

export default Component
