import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  siteSearch: (keyword, location, pathname, isFromSelect: boolean) => {
    /**
     * El objetivo es saber qué, cuándo y desde qué sección del sitio, el usuario realiza una búsqueda
     * Debe dispararse cuando el usuario hace click en la Lupa o presiona enter para realizar la búsqueda.
     */

    /**
     * Keyword y/o lugar de trabajo buscado
     */
    const keywordSearched = keyword && keyword.label !== '' ? keyword.label : 'N/A'
    const locationSearched = location && location.label !== '' ? location.label : 'Todo el país'

    /**
     * Page donde se realizó la búsqueda
     */
    let page
    if (pathname === '/404') {
      page = '404'
    } else if (pathname === '/') {
      page = 'Home'
    } else if (pathname.includes('/empleos-')) {
      page = 'Listado'
    } else if (pathname.includes('/empleos/')) {
      page = 'Aviso'
    } else {
      page = 'Otro'
    }

    /**
     * Determinar si es por seleccionar una de las opciones de búsquedas recientes o si se escribió una búsqueda nueva.
     * Si viene del "Select" entonces es "Reciente", en caso contrario viene del "Input" y es "Simple"
     */
    const searchType = isFromSelect ? 'Reciente' : 'Simple'
    return TagManager.Push({
      event: 'eventSearch',
      termino: keywordSearched,
      lugar: locationSearched,
      ubicacion: page,
      tipoBusqueda: searchType,
    })
  },
}
