import {
  FeriaActions,
  GET_FICHA_EMPRESA_FERIA,
  GET_FICHA_EMPRESA_FERIA_SUCCESS,
  GET_FICHA_EMPRESA_FERIA_ERROR,
  FeriaState,
  initialState,
  POST_LISTADO_EMPRESAS_FERIA,
  POST_LISTADO_EMPRESAS_FERIA_SUCCESS,
  POST_LISTADO_EMPRESAS_FERIA_ERROR,
  GET_FERIA,
  GET_FERIA_ERROR,
  GET_FERIA_SUCCESS,
  GET_CONFERENCIAS_EMPRESA,
  GET_CONFERENCIAS_EMPRESA_ERROR,
  GET_CONFERENCIAS_EMPRESA_SUCCESS,
  GET_CONFERENCIAS,
  GET_CONFERENCIAS_ERROR,
  GET_CONFERENCIAS_SUCCESS,
  GET_VIDEOS_CONFERENCIA,
  GET_VIDEOS_CONFERENCIA_ERROR,
  GET_VIDEOS_CONFERENCIA_SUCCESS,
} from '../types/feria'
import { status } from '../types/status'

const reducers = (state = initialState, action: FeriaActions): FeriaState => {
  switch (action.type) {
    case GET_FICHA_EMPRESA_FERIA:
      return {
        ...state,
        fichaEmpresaStates: status.loading,
      }
    case GET_FICHA_EMPRESA_FERIA_SUCCESS:
      return {
        ...state,
        fichaEmpresa: action.payload,
        fichaEmpresaStates: status.success,
      }
    case GET_FICHA_EMPRESA_FERIA_ERROR:
      return {
        ...state,
        fichaEmpresaStates: status.error,
      }
    case POST_LISTADO_EMPRESAS_FERIA:
      return {
        ...state,
        listadoEmpresasStates: status.loading,
      }
    case POST_LISTADO_EMPRESAS_FERIA_SUCCESS:
      return {
        ...state,
        listadoEmpresas: action.payload,
        listadoEmpresasStates: status.success,
      }
    case POST_LISTADO_EMPRESAS_FERIA_ERROR:
      return {
        ...state,
        listadoEmpresasStates: status.error,
      }
    case GET_FERIA:
      return {
        ...state,
        feriaStates: status.loading,
      }
    case GET_FERIA_ERROR:
      return {
        ...state,
        feriaStates: status.error,
      }
    case GET_FERIA_SUCCESS:
      return {
        ...state,
        feriaStates: status.success,
        feria: action.payload,
      }
    case GET_CONFERENCIAS:
      return {
        ...state,
        conferenciasStates: status.loading,
      }
    case GET_CONFERENCIAS_ERROR:
      return {
        ...state,
        conferenciasStates: status.error,
      }
    case GET_CONFERENCIAS_SUCCESS:
      return {
        ...state,
        conferenciasStates: status.success,
        conferencias: action.payload,
      }
    case GET_CONFERENCIAS_EMPRESA:
      return {
        ...state,
        conferenciasEmpresaStates: status.loading,
      }
    case GET_CONFERENCIAS_EMPRESA_ERROR:
      return {
        ...state,
        conferenciasEmpresaStates: status.error,
      }
    case GET_CONFERENCIAS_EMPRESA_SUCCESS:
      return {
        ...state,
        conferenciasEmpresaStates: status.success,
        conferenciasEmpresa: action.payload,
      }
    case GET_VIDEOS_CONFERENCIA:
      return {
        ...state,
        videosConferenciaStates: status.loading,
      }
    case GET_VIDEOS_CONFERENCIA_ERROR:
      return {
        ...state,
        videosConferenciaStates: status.error,
      }
    case GET_VIDEOS_CONFERENCIA_SUCCESS:
      return {
        ...state,
        videosConferenciaStates: status.success,
        videosConferencia: action.payload,
      }
    default:
      return state
  }
}

export default reducers
