export const TIENE_CV = 'TIENE_CV'
export const TIENE_CV_SUCCESS = 'TIENE_CV_SUCCESS'
export const TIENE_CV_ERROR = 'TIENE_CV_ERROR'
export const TIENE_CV_RESET = 'TIENE_CV_RESET'

export interface TieneCV {
  type: typeof TIENE_CV
  payload: null
}

export interface TieneCVSuccess {
  type: typeof TIENE_CV_SUCCESS
  payload: null
}

export interface TieneCVError {
  type: typeof TIENE_CV_ERROR
}

export interface TieneCVReset {
  type: typeof TIENE_CV_RESET
  payload: null
}
