import React, { lazy, Suspense } from 'react'

// ui-kit & mixins
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Page } from './mixins'

// components
import Heading from './components/Heading'
import GoToSignIn from './components/GoToSignIn'
import Image from './components/Image'
import Metas from '../../../components/metas/change-password'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../../components/PageLoader'))

const ChangePasswordSuccess = props => {
  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader showBackButton={false} {...props}>
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col xl={4} offset={{ xl: 2 }} lg={{ size: 6, order: 2 }}>
                <Image />
              </Col>

              <Col lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading />
                <GoToSignIn />
              </Col>
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

export default ChangePasswordSuccess
