import { Request, Post } from '@navent-jobs/config/dist/utils/rest-client'
import { OperationCreate } from '../redux/types/payment-mp'

export class ErrorPaymentMP extends Error {}

const vendorId = 8
const quantity = 1

class PaymentMPService {
  public getMPLink = async ({ productId, externalId }) => {
    const data: OperationCreate = { vendorId, items: [{ productId, quantity }], externalId }
    const request = new Request()
    request.method = Post
    request.path = `/api/candidates/mercadopagolink`
    request.body = data
    return request
      .call()
      .then(res => res.metadata.paylink)
      .catch(() => {
        throw new ErrorPaymentMP(`No se pudo crear la operación - productId: ${productId} - externalId: ${externalId}`)
      })
  }

  public getMPLinkV2 = async ({ products, externalId }) => {
    const data: OperationCreate = { vendorId, items: products, externalId }
    const request = new Request()
    request.method = Post
    request.path = `/api/candidates/mercadopagolinkV2`
    request.body = data
    return request
      .call()
      .then(res => res.metadata.paylink)
      .catch(() => {
        throw new ErrorPaymentMP(`No se pudo crear la operación para los productos ${Array(products).toString()}`)
      })
  }

  public notificarPagoExitoso = ({ operationId, externalId }) => {
    const request = new Request()
    request.method = Post
    request.path = `/api/candidates/notificarPagoExitoso/${operationId}/${externalId}`
    return request.call().catch(() => {
      throw new ErrorPaymentMP(`No se pudo borrar la informacion del postulante`)
    })
  }
}

export default new PaymentMPService()
