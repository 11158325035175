/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// mixins
import { CustomLink } from './mixins'

// banners
import ProductDefault from './banner'

// constans
import { SITE_ID } from '../../constants'
import settingsBanner, { settingsBannerProducts } from '../../constants/products/product-banners'
import { DataLayersProductBanner } from '../../constants/gtm-events-products-banners'
import { DataLayersReviews } from '../../constants/gtm-events-reviews'
import { userLoggedIn } from '../../utils/cookies'

interface Typer {
  colSizeXl: number
  verticalSize: string
  selectedProduct?: string
  action?: Function
}

// Se pasa el producto que se quiere mostrar o si no se pasa se toma el habilitado para el pais
const ProductBanner = ({ colSizeXl, selectedProduct, verticalSize, action }: Typer) => {
  const { pathname } = useLocation()

  const productSetting = selectedProduct ? settingsBannerProducts[selectedProduct] : settingsBanner[SITE_ID || 'BMAR']
  const { requireLogin, href, hrefSelf } = productSetting
  const hrefLink = requireLogin && !userLoggedIn() ? `/login?returnTo=${href}` : href

  const bannerEnabled = selectedProduct
    ? !!settingsBannerProducts[selectedProduct]
    : settingsBanner[SITE_ID || 'BMAR']?.enabled

  const clickDataLayer = () => {
    switch (productSetting?.product) {
      case 'Calificaciones':
        DataLayersReviews.goToReviewFromBanner()
        break
      case 'Coderhouse':
        break
      default:
        DataLayersProductBanner.click(pathname, productSetting?.product)
    }
  }

  if (!bannerEnabled) {
    return null
  }

  return (
    <CustomLink
      href={hrefLink}
      target={hrefSelf ? '_self' : '_blank'}
      onClick={() => {
        if (action) action()
        clickDataLayer()
      }}
    >
      <ProductDefault colSizeXl={colSizeXl} verticalSize={verticalSize} productSetting={productSetting} />
    </CustomLink>
  )
}
export default ProductBanner
