import React, { useEffect } from 'react'
// tools
import { useTranslation } from 'react-i18next'
import { numberTropicalizer, Countries } from '@navent-jobs/config'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { NOMBRE_PAIS, ID_PAIS } from '../../../../constants'

// components
import SearchbarSection from '../searchbar-section'
import bkgSrc from '../../../../assets/images/home/background/bg-bumeran.webp'

// mixins
import { Title, MarktingHeaderImg, MarketingWrapper, LinkContainer, BackgroundCoverage, SerchWrapper } from './mixins'
import { HomePageState } from '../../../../redux/types/home-page'
import { getTotalAvisos } from '../../../../redux/actions/home-page'
import { MarketingState } from '../../../../redux/types/marketing'
import { getCampaignAction } from '../../../../redux/actions/marketing'

// avoid magic numbers
const col12 = 12
const col5 = 5
const col7 = 7

interface HomeHeaderProps {
  totalAvisos: HomePageState['totalAvisos']
  campaign: MarketingState['campaign']
}

const geTotalHelper = (idPais, total: number) => {
  const tot = idPais === Countries.CL.id ? total * 6 : total
  return numberTropicalizer(tot)
}

const HomeHeader = ({ totalAvisos, campaign }: HomeHeaderProps) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()
  const imageType = isMobile ? 'mobile.webp' : 'desktop.webp'
  const image = campaign.value ? `${campaign.value.image}-${imageType}` : null

  useEffect(() => {
    dispatch(getTotalAvisos())
    if (campaign.value === null) {
      dispatch(getCampaignAction())
    }
  }, [])

  return (
    <BackgroundCoverage bkg={bkgSrc}>
      <MainContainer>
        <Row>
          <Col xs={col12} lg={col5}>
            <SerchWrapper>
              <Title>
                {t('homeHeader.title.part1')}
                <strong>
                  {totalAvisos.status.success && totalAvisos.value && geTotalHelper(ID_PAIS, totalAvisos.value)}
                </strong>
                {t('homeHeader.title.part2')} {NOMBRE_PAIS}
              </Title>
              <SearchbarSection optionsOnTop />
            </SerchWrapper>
          </Col>

          <Col xs={col12} lg={col7} noGutter={isMobile}>
            <MarketingWrapper>
              <MarktingHeaderImg src={image} alt="" />
              {campaign.value?.link && (
                <LinkContainer>
                  <Link
                    href={campaign.value.link}
                    linkoptions={{
                      native: true,
                      size: 'medium',
                      type: 'link',
                      variant: 'primary',
                      target: campaign.value.external || campaign.value.newTab ? '_blank' : '_self',
                    }}
                    icon={{ name: 'icon-light-caret-right', position: 'right', color: '#E90066' }}
                  >
                    {t('homeHeader.marketingLink')}
                  </Link>
                </LinkContainer>
              )}
            </MarketingWrapper>
          </Col>
        </Row>
      </MainContainer>
    </BackgroundCoverage>
  )
}

const state = ({
  homePageStore,
  marketingStore,
}: {
  homePageStore: HomePageState
  marketingStore: MarketingState
}) => ({
  totalAvisos: homePageStore.totalAvisos,
  campaign: marketingStore.campaign,
})

export default connect(state)(HomeHeader)
