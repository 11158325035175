import {
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  LocationsActions,
  LocationsState,
  initialState,
} from '../types/locations'

const reducers = (state: any = initialState, action: LocationsActions): LocationsState => {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        locations: [],
        opened: false,
        loading: true,
      }
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: [...action.payload],
        opened: true,
        loading: false,
        error: false,
      }
    case GET_LOCATIONS_ERROR:
      return {
        ...state,
        locations: [],
        opened: false,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducers
