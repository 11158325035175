import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { GET_PAYMENT_INVENTORY } from '../types/applicant/inventory'
import {
  GET_ANALYSISCV,
  GET_ANALYSISCV_SUCCESS,
  GET_ANALYSISCV_ERROR,
  POST_ANALYSISCV,
  POST_ANALYSISCV_SUCCESS,
  POST_ANALYSISCV_ERROR,
  POST_ANALYSISCV_PREVIEW,
  POST_ANALYSISCV_PREVIEW_SUCCESS,
  POST_ANALYSISCV_PREVIEW_ERROR,
  POST_ANALYSISCV_PREVIEW_RESET,
} from '../types/analysis-cv'

function* getAnalysisCv() {
  try {
    const result = yield call(services.analysisCv.GetAnalysisCv)
    yield put({ type: GET_ANALYSISCV_SUCCESS, payload: result.link })
  } catch (error) {
    yield put({ type: GET_ANALYSISCV_ERROR, error })
  }
}

function* postAnalysisCv() {
  try {
    const result = yield call(services.analysisCv.PostAnalysisCv)
    yield put({ type: POST_ANALYSISCV_SUCCESS, payload: result.link })
    yield put({ type: GET_PAYMENT_INVENTORY })
  } catch (error) {
    yield put({ type: POST_ANALYSISCV_ERROR, error })
  }
}

function* postAnalysisCvPreview() {
  try {
    const result = yield call(services.analysisCv.PostAnalysisCvPreview)
    yield put({ type: POST_ANALYSISCV_PREVIEW_SUCCESS, payload: result.link })
    yield put({ type: POST_ANALYSISCV_PREVIEW_RESET })
  } catch (error) {
    yield put({ type: POST_ANALYSISCV_PREVIEW_ERROR, payload: error })
  }
}

/**
 * Watchers
 */
export default function* analysisCv() {
  yield takeLatest(GET_ANALYSISCV, getAnalysisCv)
  yield takeLatest(POST_ANALYSISCV, postAnalysisCv)
  yield takeLatest(POST_ANALYSISCV_PREVIEW, postAnalysisCvPreview)
}
