import React from 'react'
import ContentLoader from 'react-content-loader'
import { PlaceholderWrapper } from './mixins'

// Button:
const ButtonPlaceholder = () => (
  <ContentLoader speed={2} width="100%" height={48} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <rect x="0" y="0" rx="8" ry="8" width="100%" height="48" />
  </ContentLoader>
)

const SeguirButtonPlaceholder = () => {
  return (
    <PlaceholderWrapper>
      <ButtonPlaceholder />
    </PlaceholderWrapper>
  )
}

export default SeguirButtonPlaceholder
