import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Image from '@navent-jobs/ui-kit/components/Image'

import { Li, LinkCustom } from './mixins'

// get component image or icon
const Iconography = ({ item, primaryColor }) => {
  if (item.img) {
    return <Image src={item.img} alt={item.alt} width="14" height="14" />
  }

  if (item.icon) {
    return <Icon name={item.icon} size="14" color={item.iconPrimary ? primaryColor : 'gray'} />
  }

  return null
}

// get component react element
const Element = ({ item }) => {
  if (typeof item?.content !== 'string') {
    return item.content
  }

  return (
    <LinkCustom
      href={item.href}
      rel={item.rel}
      linkoptions={{
        size: 'small',
        variant: 'black',
        type: 'link',
        native: false,
        target: '_blank',
      }}
    >
      {item.content}
    </LinkCustom>
  )
}

const Item = ({ item }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  let settings = {}

  if (item.metrics) {
    settings = {
      onClick: () => item.metrics(),
    }
  }

  return (
    <Li key={`navbar-item-${item.key}`} {...settings}>
      <Iconography item={item} primaryColor={colors.primary.normal} />
      <Element item={item} />
    </Li>
  )
}

export default Item
