import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphPlaceholder = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={377}
    viewBox="0 0 100% 377"
    backgroundcolor="#f3f3f3"
    foregroundcolor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
    <rect x="0" y="44" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 44 h 38 v 20 H 36 z" />
    <rect x="0" y="98" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 98 h 78 v 20 H 36 z" />
    <rect x="0" y="152" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 152 h 108 v 20 H 36 z" />
    <rect x="0" y="206" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 206 h 128 v 20 H 36 z" />
    <rect x="0" y="260" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 260 h 191 v 20 H 36 z" />
    <rect x="0" y="314" rx="4" ry="4" width="20" height="20" />
    <path d="M 36 314 h 256 v 20 H 36 z" />
    <rect x="48" y="353" rx="4" ry="4" width="200" height="24" />
  </ContentLoader>
)

export default GraphPlaceholder
