import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { NOMBRE_PAIS } from '../../../constants'

const Metas = () => {
  const { t } = useTranslation()
  const wordingData = {
    countryName: NOMBRE_PAIS,
  }
  const pageTitle = t(`seoWordings.promedio_salarial_subarea.title`, wordingData)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default Metas
