import { merge } from 'lodash'
import es from '../es'
import modalActivarCuenta from './modal-activar-cuenta'
import { modalPreguntas as modalPreguntasEs } from '../es/modal-preguntas'
import { modalPreguntas as modalPreguntasAr } from './modal-preguntas'
import modalCrearAlerta from './modal-crear-alerta'
import modalCompartirAviso from './modal-compartir-aviso'
import metatagsListado from './metatags-listado'
import errorPages from './error-pages'
import fichaAviso from '../es/ficha-aviso'
import fichaAvisoAr from './ficha-aviso'
import fichaAvisoEmptyState from './ficha-aviso-empty-state'
import header from './header'
import busquedaEncontrada from './busqueda-encontrada'
import busquedaNoEncontrada from './busqueda-no-encontrada'
import filtersSettings from './filters-settings'
import { currencyMask } from './currency-mask'
import { seoWordings as seoWordingsAr } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import crearAlerta from './crear-alerta'
import PermanentSearchDialog from './permanent-search'
import ctaDownloadAppEs from '../es/cta-download-app'
import ctaDownloadAppAr from './cta-download-app'
import { testPersonalidad } from './test-personalidad'
import curriculumEs from '../es/curriculum'
import curriculumAr from './curriculum'
import signInEs from '../es/sign-in'
import signInAr from './sign-in'
import signUpEs from '../es/sign-up'
import signUpAr from './sign-up'
import changePassword from './change-password'
import resendEmail from './resend-email'
import changePasswordSuccess from './change-password-success'
import accountAr from './account'
import accountEs from '../es/account'
import notificationAlertsEs from '../es/notification-alerts'
import notificationAlertsAr from './notification-alerts'
import socialShare from './social-share'
import productsTab from './products-tab'
import companySummaryEs from '../es/company-summary'
import companySummaryAr from './company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesAr from './postulaciones'
import confirmReferenceEs from '../es/confirm-reference'
import confirmReferenceAr from './confirm-reference'
import { nulinga as nulingaAr } from './nulinga'
import { nulinga as nulingaEs } from '../es/nulinga'
import { saveJobPosting } from './save-job-posting'
import { saveJobPosting as saveJobPostingEs } from '../es/save-job-posting'
import { mensajes as mensajesEs } from '../es/mensajes'
import { mensajes as mensajesAr } from './mensajes'
import { alertas as alertasEs } from '../es/alertas'
import { alertas as alertasAr } from './alertas'
import { postulacionesStatistics as postulacionesStatisticsAr } from './postulaciones-statistics'
import { postulacionesStatistics as postulacionesStatisticsEs } from '../es/postulaciones-statistics'
import { productLanding as productLandingEs } from '../es/product-landing'
import { productLanding as productLandingAr } from './product-landing'
import { homeHeader as homeHeaderEs } from '../es/home-header'
import { homeHeader as homeHeaderAr } from './home-header'

import searchBarCompaniesEs from '../es/search-bar-companies'
import searchBarCompaniesAr from './search-bar-companies'
import { products } from './products'
import { review as reviewEs } from '../es/review'
import { review as reviewAr } from './review'
import filters from './filters'
import filtersEs from '../es/filters'
import modalDenuncia from './modal-denuncia'
import modalDenunciaEs from '../es/modal-denuncia'
import saveCompany from './save-company'
import saveCompanyEs from '../es/save-company'
import avisosRecomendadosIA from './avisos-recomendados-ia'
import avisosRecomendadosIAEs from '../es/avisos-recomendados-ia'
import { perfil } from './perfil'
import { perfil as perfilEs } from '../es/perfil'
import bannerConsejos from './banner-consejos'
import bannerConsejosEs from '../es/banner-consejos'

const ar = {
  ...es,
  cv: 'CV',
  ...modalActivarCuenta,
  modalPreguntas: merge({}, modalPreguntasEs, modalPreguntasAr),
  modalDenuncia: merge({}, modalDenunciaEs, modalDenuncia),
  ...modalCrearAlerta,
  ...modalCompartirAviso,
  ...metatagsListado,
  errorPages,
  fichaAviso: merge({}, fichaAviso, fichaAvisoAr),
  ...fichaAvisoEmptyState,
  ...header,
  ...busquedaEncontrada,
  ...busquedaNoEncontrada,
  ...filtersSettings,
  currencyMask,
  ...crearAlerta,
  ctaDownloadApp: merge({}, ctaDownloadAppEs, ctaDownloadAppAr),
  'Postulantes Title': 'Empleos en Argentina: trabajo y ofertas de empleo - Bumeran 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Argentina en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Bumeran.',
  Buscar: 'Buscar AR',
  'Ir al inicio': 'Ir al inicio AR',
  Login: 'Go To Login Page (TRASLATED)',
  Puesto: 'Puesto',
  Lugar: 'Lugar',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  'Este aviso finalizó': 'Este aviso finalizó',
  'Pero, no te preocupes, mirá todos los avisos similares que tenemos para vos.':
    'Pero, no te preocupes, mirá todos los avisos similares que tenemos para vos.',
  'Ver más avisos': 'Ver más avisos',
  Publicado: 'Publicado',
  Salario: 'Salario',
  'Tipo de puesto': 'Tipo de puesto',
  Área: 'Área',
  'Video institucional': 'Video institucional',
  'Campo requerido': 'Campo requerido',
  Cancelar: 'Cancelar',
  portal: {
    currency: '$',
    'currency-format': 'XXX.XXX.XXX,XX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - bumeran.com.ar',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en bumeran Empleos.',
  facets: {
    ...es.facets,
  },
  seoWordings: merge({}, seoWordingsEs, seoWordingsAr),
  products: merge({}, es.products, products),
  ...PermanentSearchDialog,
  ...testPersonalidad,
  curriculum: merge({}, curriculumEs, curriculumAr),
  signIn: merge({}, signInEs, signInAr),
  signUp: merge({}, signUpEs, signUpAr),
  ...changePassword,
  ...resendEmail,
  ...changePasswordSuccess,
  account: merge({}, accountEs, accountAr),
  notificationAlerts: merge({}, notificationAlertsEs, notificationAlertsAr),
  ...socialShare,
  ...productsTab,
  companySummary: merge({}, companySummaryEs, companySummaryAr),
  confirmReference: merge({}, confirmReferenceEs, confirmReferenceAr),
  postulaciones: merge({}, postulacionesEs, postulacionesAr),
  nulinga: merge({}, nulingaEs, nulingaAr),
  saveJobPosting: merge({}, saveJobPostingEs, saveJobPosting),
  mensajes: merge({}, mensajesEs, mensajesAr),
  postulacionesStatistics: merge({}, postulacionesStatisticsEs, postulacionesStatisticsAr),
  productLanding: merge({}, productLandingEs, productLandingAr),
  searchBarCompanies: merge({}, searchBarCompaniesEs, searchBarCompaniesAr),
  alertas: merge({}, alertasEs, alertasAr),
  review: merge({}, reviewEs, reviewAr),
  homeHeader: merge({}, homeHeaderEs, homeHeaderAr),
  ...merge({}, { ...filtersEs }, { ...filters }),
  saveCompany: merge({}, saveCompanyEs, saveCompany),
  avisosRecomendadosIA: merge({}, avisosRecomendadosIAEs, avisosRecomendadosIA),
  perfil: merge({}, perfilEs, perfil),
  bannerConsejos: merge({}, bannerConsejosEs, bannerConsejos),
}

export default ar
