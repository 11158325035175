import { START_ACTION, STOP_ACTION, REFRESH_ACTION_START, REFRESH_ACTION_STOP, initialState } from '../types/ui'

const reducers = (state: any = initialState, { type, payload }) => {
  const { loader } = state
  const { actions, refreshing } = loader
  switch (type) {
    case START_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: [...actions, payload.action],
        },
      }
    case STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter((action: { name: string }) => action.name !== payload.name),
        },
      }
    case REFRESH_ACTION_START:
      return {
        ...state,
        loader: {
          ...loader,
          refreshing: [...refreshing, payload.refreshAction],
        },
      }
    case REFRESH_ACTION_STOP:
      return {
        ...state,
        loader: {
          ...loader,
          refreshing: refreshing.filter(refresh => refresh !== payload.refreshAction),
        },
      }
    default:
      return state
  }
}

export default reducers
