import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomLink } from './mixins'

const Component = () => {
  const { t } = useTranslation()
  return (
    <ComponentContainer>
      <CustomLink
        href="/postulantes/recuperar-contrasena"
        id="recuperoPassword"
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('signIn.olvideContrasena')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
