import { get, Put, Request } from '@navent-jobs/config'
import {
  GetRecomendadosListadoParams,
  GetRecomendadosResponse,
  SetNotInterestedParams,
} from '../redux/types/recomendados'

export class AvisosRecomendadosError extends Error {}

class AvisosRecomendadosService {
  public async getAvisosRecomendadosListado({
    limit,
    source,
  }: GetRecomendadosListadoParams): Promise<GetRecomendadosResponse> {
    return get('/api/candidates/avisos/recomendados', { params: { limit, source } })
  }

  public async getMotivosNoInteresado(): Promise<any> {
    return get('/api/candidates/avisos/no-interesado/motivos')
  }

  public async setNotInterestedService(payload: SetNotInterestedParams) {
    const request = new Request()
    request.method = Put
    request.path = `/api/candidates/avisos/no-interesado`
    request.body = payload

    return request.call().catch(() => {
      throw new AvisosRecomendadosError('Error al no interesarte un aviso')
    })
  }
}

const avisosRecomendadosService = new AvisosRecomendadosService()
export default avisosRecomendadosService
