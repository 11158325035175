import { status } from '../types/status'

import {
  GET_PROMEDIOS_SALARIALES,
  GET_PROMEDIOS_SALARIALES_SUCCESS,
  GET_PROMEDIOS_SALARIALES_ERROR,
  GET_PROMEDIO_SALARIAL_AREA,
  GET_PROMEDIO_SALARIAL_AREA_SUCCESS,
  GET_PROMEDIO_SALARIAL_AREA_ERROR,
  PromedioSalarialActions,
  initialState,
  PromedioSalarialState,
  CALCULAR_SALARIO_NETO,
  CALCULAR_SALARIO_NETO_SUCCESS,
  CALCULAR_SALARIO_NETO_ERROR,
} from '../types/promedioSalarial'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: PromedioSalarialActions): PromedioSalarialState => {
  switch (action.type) {
    case GET_PROMEDIOS_SALARIALES:
      return {
        ...state,
        statesPromedios: status.loading,
      }
    case GET_PROMEDIOS_SALARIALES_SUCCESS:
      return {
        ...state,
        promedios: action.payload,
        statesPromedios: status.success,
      }
    case GET_PROMEDIOS_SALARIALES_ERROR:
      return {
        ...state,
        statesPromedios: status.error,
      }
    case GET_PROMEDIO_SALARIAL_AREA:
      return {
        ...state,
        statesPromedioArea: status.loading,
      }
    case GET_PROMEDIO_SALARIAL_AREA_SUCCESS:
      return {
        ...state,
        promedioArea: action.payload,
        statesPromedioArea: status.success,
      }
    case GET_PROMEDIO_SALARIAL_AREA_ERROR:
      return {
        ...state,
        statesPromedioArea: status.error,
      }
    case CALCULAR_SALARIO_NETO:
      return {
        ...state,
        calculadora: {
          ...state.calculadora,
          states: status.loading,
        },
      }
    case CALCULAR_SALARIO_NETO_SUCCESS:
      return {
        ...state,
        calculadora: {
          states: status.success,
          salario: action.payload,
        },
      }
    case CALCULAR_SALARIO_NETO_ERROR:
      return {
        ...state,
        calculadora: {
          states: status.error,
          salario: null,
        },
      }
    default:
      return state
  }
}

export default reducers
