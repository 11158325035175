import { Request, Get, formatNameToStringId, LOCATION_DEFAULT } from '@navent-jobs/config'
import { isEmpty } from 'lodash'
import { ID_PAIS, SITE_ID } from '../constants'
import features from '../constants/features-per-country'

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
const CAPITAL_FEDERAL = searchAvisosV2Enabled
  ? 'argentina|buenos-aires/capital-federal'
  : 'buenos-aires/capital-federal'

export class ErrorLocation extends Error {}

function sortLocationByLabel(a, b) {
  if (a.label > b.label) return 1
  if (b.label > a.label) return -1
  return 0
}
class Locations {
  public async GetLocations() {
    const request = new Request()
    request.path = `/api/provincias/${ID_PAIS}`
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorLocation(`Ubicaciones no encontradas`)
        }

        const locations = response
          .map(location => {
            return {
              value: searchAvisosV2Enabled ? location.idSemantico : formatNameToStringId(location.nombre),
              label: location.nombre,
            }
          })
          .sort(sortLocationByLabel)

        /**
         * Si nos encontramos en Argentina (ID_PAIS = 1)
         * Sumamos "Capital Federal"
         */
        if (ID_PAIS === 1) {
          locations.unshift({
            value: CAPITAL_FEDERAL,
            label: 'Capital Federal',
          })
        }

        /**
         * Sumamos la opción "Todo el país"
         */
        locations.unshift(LOCATION_DEFAULT)

        return locations
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorLocation(`Ubicaciones no encontradas`)
        }
      })
  }
}

const locations = new Locations()
export default locations
