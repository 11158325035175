import React from 'react'
import { connect } from 'react-redux'
import CardCompany from '../../../../../../components/card-company/extended'
import { CardContainer } from './mixins'
import { getCompanyURL } from '../../../../../../hooks/get-company-url'
import { DataLayerListadoEmpresas } from '../../../../../../constants/gtm-events-listado-empresas'

const CardCompanyComponent = ({ company, companiesRecommendedStates, isUserLoggedIn }) => {
  const dataUrl = {
    cantidadAvisos: company?.jobPostingsQuantity,
    denominacion: company?.name,
    id: company?.companyId,
  }

  const settingsCompanyURL = getCompanyURL({ company: dataUrl })

  const handleOnClick = event => {
    if (!companiesRecommendedStates.success) {
      if (!(event.ctrlKey || event.metaKey)) {
        DataLayerListadoEmpresas.goToCompany(company?.name)
      }
    } else {
      DataLayerListadoEmpresas.goToRecommendedCompany(isUserLoggedIn)
    }
  }

  return (
    <CardContainer onClick={e => handleOnClick(e)}>
      <CardCompany
        company={{ ...company, id: company.companyId }}
        settingsCompanyURL={settingsCompanyURL}
        showSeguirEmpresa
      />
    </CardContainer>
  )
}

const states = ({ listadoEmpresasStore }) => ({
  companiesRecommendedStates: listadoEmpresasStore.companiesRecommendedStates,
})

export default connect(states)(CardCompanyComponent)
