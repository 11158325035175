import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'

export const ComponentContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    align-items: center;
  }
`

export const CustomImage = styled(Image)`
  width: 140px;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    width: 312px;
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    width: 414px;
  }
`
