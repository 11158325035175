import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphPlaceholder = props => {
  const { isMobile } = props
  const height = isMobile ? '52' : '64'

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={height}
      viewBox={`0 0 100% ${height}`}
      backgroundcolor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
      style={{ paddingLeft: isMobile ? 16 : 24, marginBottom: isMobile ? 12 : 24, marginTop: 24 }}
    >
      <rect x="0" y={isMobile ? '0' : '10'} rx="4" ry="4" width="48" height="48" />
      <rect x="60" y="0" rx="4" ry="4" width="201" height="20" />
      <rect x="60" y="32" rx="4" ry="4" width="225" height="32" />
    </ContentLoader>
  )
}

export default GraphPlaceholder
