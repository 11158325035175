import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubtitleText, StrongText, CardNotEnded } from './mixins'

const NotEndedCard = () => {
  const { t } = useTranslation()

  return (
    <CardNotEnded>
      <SubtitleText>{t('saveJobPosting.cards.notEnded.text')}</SubtitleText>
      <StrongText>{t('saveJobPosting.cards.notEnded.strongText')}</StrongText>
    </CardNotEnded>
  )
}

export default NotEndedCard
