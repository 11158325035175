export const GET_ALERTS_SETTINGS = 'GET_ALERTS_SETTINGS'
export const GET_ALERTS_SETTINGS_RESET = 'GET_ALERTS_SETTINGS_RESET'
export const GET_ALERTS_SETTINGS_SUCCESS = 'GET_ALERTS_SETTINGS_SUCCESS'
export const GET_ALERTS_SETTINGS_ERROR = 'GET_ALERTS_SETTINGS_ERROR'

export interface AlertsType {
  criterios: string[]
  frecuenciaNotificacion: { nombre: string; id: number }
  id: number
  query: string | null
  localizacion: {} | null
  pais: { id: number; nombre: string; isoCode: string } | null
  salarioMinimo: number | null
  tipoTrabajo: { id: number; nombre: string } | null
  modalidadTrabajo: { id: number; nombre: string } | null
  subAreas: { id: number; nombre: string }[] | null
  ciudad: string | null
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export interface AlertsState {
  data: AlertsType[] | null
  status: Status
}

export const initialAlertsState = {
  data: null,
  status: initialStatus,
}

export interface AlertsSettings {
  type: typeof GET_ALERTS_SETTINGS
  payload: AlertsType[] | null
}

export interface AlertsSettingsSuccess {
  type: typeof GET_ALERTS_SETTINGS_SUCCESS
  payload: AlertsType[] | null
}

export interface AlertsSettingsError {
  type: typeof GET_ALERTS_SETTINGS_ERROR
  payload: string | null
}

export interface AlertsSettingsReset {
  type: typeof GET_ALERTS_SETTINGS_RESET
  payload: null
}

export type AlertsSettingsActions = AlertsSettings | AlertsSettingsSuccess | AlertsSettingsError | AlertsSettingsReset
