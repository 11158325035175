import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholderTablet = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const { withDescription } = props
  const height = withDescription ? 231 : 181
  const viewBox = withDescription ? 201 : 151

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth * 0.98}
        height={height}
        viewBox={`0 0 ${wrapperWidth} ${viewBox}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="16" y="1" rx="4" ry="4" width="68" height="44" />
        <rect x="92" y="15" rx="4" ry="4" width="166" height="16" />
        <rect x="16" y="57" rx="4" ry="4" width="20" height="20" />
        <rect x="16" y="85" rx="4" ry="4" width="20" height="20" />
        <rect x="16" y="113" rx="4" ry="4" width="20" height="20" />
        <rect x="44" y="59" rx="4" ry="4" width="152" height="16" />
        <rect x="44" y="87" rx="4" ry="4" width="152" height="16" />
        <rect x="44" y="115" rx="4" ry="4" width="152" height="16" />
        {withDescription && (
          <>
            <rect x="16" y="145" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="167" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="189" rx="2" ry="2" width="296" height="12" />
          </>
        )}
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholderTablet
