const filters = {
  filtros: {
    placeholderSearchFacet: {
      provincia: 'un Estado',
      localidad: 'un Municipio',
    },
  },
}

export default filters
