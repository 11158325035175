import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { randomizeArray } from '../../utils/random-util'
import {
  GET_FICHA_EMPRESA_FERIA,
  GET_FICHA_EMPRESA_FERIA_SUCCESS,
  GET_FICHA_EMPRESA_FERIA_ERROR,
  POST_LISTADO_EMPRESAS_FERIA_SUCCESS,
  POST_LISTADO_EMPRESAS_FERIA_ERROR,
  POST_LISTADO_EMPRESAS_FERIA,
  GET_FERIA,
  GET_FERIA_SUCCESS,
  GET_FERIA_ERROR,
  GET_CONFERENCIAS_EMPRESA_SUCCESS,
  GET_CONFERENCIAS_EMPRESA_ERROR,
  GET_CONFERENCIAS_EMPRESA,
  GET_CONFERENCIAS,
  GET_CONFERENCIAS_SUCCESS,
  GET_CONFERENCIAS_ERROR,
  GET_VIDEOS_CONFERENCIA,
  GET_VIDEOS_CONFERENCIA_SUCCESS,
  GET_VIDEOS_CONFERENCIA_ERROR,
} from '../types/feria'

const TOTAL_EMPRESAS_PER_PAGE = 9

function* getFichaEmpresaFeria({ payload }) {
  try {
    const result = yield call(services.feriaService.getFichaEmpresa, payload)
    yield put({ type: GET_FICHA_EMPRESA_FERIA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_FICHA_EMPRESA_FERIA_ERROR, error })
  }
}

function* getListadoEmpresas({ payload }) {
  try {
    const result = yield call(services.feriaService.getListadoEmpresas, {
      ...payload,
      pageSize: TOTAL_EMPRESAS_PER_PAGE,
    })
    const companies = result && result.content.length > 0 ? result.content : null

    const filters = result ? result.filters : null

    const filtersApplied = result ? result.filtersApplied : null

    const pagination = {
      currentPage: result ? result.number + 1 : 1,
      totalPages: result.total > TOTAL_EMPRESAS_PER_PAGE ? Math.ceil(result.total / result.size) : 0,
      elementsPerPage: result ? result.size : TOTAL_EMPRESAS_PER_PAGE,
      totalElements: result ? result.total : 0,
    }

    const listadoEmpresas = {
      companies,
      filters,
      filtersApplied,
      pagination,
    }

    yield put({ type: POST_LISTADO_EMPRESAS_FERIA_SUCCESS, payload: listadoEmpresas })
  } catch (error) {
    yield put({ type: POST_LISTADO_EMPRESAS_FERIA_ERROR, error })
  }
}

function* getFeria({ payload }) {
  try {
    const result = yield call(services.feriaService.getFeria, payload.slug)
    if (result?.imagenes?.sponsors) {
      randomizeArray(result?.imagenes?.sponsors)
    }
    yield put({ type: GET_FERIA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_FERIA_ERROR, error: true })
  }
}

function* getConferencias({ payload }) {
  try {
    const result = yield call(services.feriaService.getConferencias, payload.idFeria)
    yield put({ type: GET_CONFERENCIAS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CONFERENCIAS_ERROR, error: true })
  }
}

function* getConferenciasEmpresa({ payload }) {
  try {
    const result = yield call(services.feriaService.getConferenciasEmpresa, payload)
    yield put({ type: GET_CONFERENCIAS_EMPRESA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CONFERENCIAS_EMPRESA_ERROR, error: true })
  }
}

function* getVideosConferencia({ payload }) {
  try {
    const result = yield call(services.feriaService.getVideosConferencia, payload.idFeria)
    yield put({ type: GET_VIDEOS_CONFERENCIA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_VIDEOS_CONFERENCIA_ERROR, error: true })
  }
}

/**
 * Watchers
 */
export default function* feria() {
  yield takeLatest<any>(GET_FICHA_EMPRESA_FERIA, getFichaEmpresaFeria)
  yield takeLatest<any>(POST_LISTADO_EMPRESAS_FERIA, getListadoEmpresas)
  yield takeLatest<any>(GET_FERIA, getFeria)
  yield takeLatest<any>(GET_CONFERENCIAS, getConferencias)
  yield takeLatest<any>(GET_CONFERENCIAS_EMPRESA, getConferenciasEmpresa)
  yield takeLatest<any>(GET_VIDEOS_CONFERENCIA, getVideosConferencia)
}
