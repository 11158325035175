import { SHOW_MODAL, HIDE_MODAL, ModalActions, ModalState, initialState } from '../types/modal'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      }
    case HIDE_MODAL:
      return {
        ...state,
        activeModal: '',
      }
    default:
      return state
  }
}

export default reducers
