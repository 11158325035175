import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'

// mixins
import { LoaderContainer, PlaceholderData } from './mixins'

const PlaceholderProps = {
  isDesktop: {
    caption: 88,
    description: 182,
    cards: 4,
  },
  isTabletPortrait: {
    title: 233,
    caption: 114,
    description: 182,
    cards: 1,
  },
  isTabletLandscape: {
    title: 118,
    caption: 58,
    description: 134,
    cards: 4,
  },
  isMobile: {
    title: 175,
    caption: 98,
    description: 134,
    cards: 1,
  },
}

export const SectionCarrouselPlaceholder = ({ device = 'isDesktop' }) => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const props = PlaceholderProps[device]
  const nameWidth = props.title || (wrapperWidth / props.cards) * 0.55
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      {Array.from({ length: props.cards }, (_, _k) => (
        <PlaceholderData>
          <ContentLoader
            speed={2}
            width="100%"
            height={164}
            viewBox={`0 0 100% 164`}
            backgroundColor="#f3f3f3"
            foregroundColor="#d4d4d4"
          >
            <rect x="16" y="16" rx="4" ry="4" width="68" height="44" />
            <rect x="92" y="16" rx="4" ry="4" width={nameWidth} height="16" />
            <rect x="92" y="44" rx="4" ry="4" width={props.caption} height="16" />

            <rect x="16" y="72" rx="4" ry="4" width="20" height="20" />
            <rect x="46" y="72" rx="4" ry="4" width={props.description} height="16" />
            <rect x="16" y="102" rx="4" ry="4" width="20" height="20" />
            <rect x="46" y="102" rx="4" ry="4" width={props.description} height="16" />
            <rect x="16" y="130" rx="4" ry="4" width="20" height="20" />
            <rect x="46" y="130" rx="4" ry="4" width={props.description} height="16" />
          </ContentLoader>
        </PlaceholderData>
      ))}
    </LoaderContainer>
  )
}
