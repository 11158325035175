/* eslint-disable prettier/prettier */
import React from 'react'
import { useTranslation } from 'react-i18next'

// mixins
import { Title, Description } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return (
    <>
      <Title>{t('productLanding.miguru.popover.title')}</Title>
      <Description>{t('productLanding.miguru.popover.description', { cv: t('cv') })}</Description>
    </>
  )
}

export default Component
