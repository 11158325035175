import { levels, mockGraphTheme } from '../constants'
import useGraphColors from './useGraphColors'

const useGraphProps = () => {
  const { colorEducation } = useGraphColors()

  return (size, BarLegend): any => ({
    enableGridX: true,
    enableGridY: false,
    layers: ['grid', 'axes', 'bars', 'markers', BarLegend],
    margin: {
      top: mockGraphTheme[size].margin.bottom,
      right: mockGraphTheme[size].margin.right,
      bottom: mockGraphTheme[size].margin.bottom,
      left: mockGraphTheme[size].margin.left,
    },
    axisLeft: {
      tickSize: 9,
    },
    axisBottom: {
      tickValues: mockGraphTheme[size].axisBottom.tickValues,
    },
    legends: [
      {
        dataFrom: levels,
        data: levels.map((id, index) => ({
          color: colorEducation[index],
          id: levels[index],
          label: id,
        })),
        anchor: mockGraphTheme[size].legends.anchor,
        direction: mockGraphTheme[size].legends.direction,
        justify: false,
        translateX: mockGraphTheme[size].legends.translateX,
        translateY: mockGraphTheme[size].legends.translateY,
        itemsSpacing: mockGraphTheme[size].legends.itemsSpacing,
        itemColor: 'rgba(0, 0, 24, 0.6)',
        itemWidth: mockGraphTheme[size].legends.itemWidth,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    theme: {
      labels: {
        text: {
          fontWeight: 'bold',
        },
      },
      axis: {
        ticks: {
          line: {
            stroke: '#E4E9F2',
            strokeWidth: 1,
          },
        },
      },
    },
  })
}

export default useGraphProps
