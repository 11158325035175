import styled from 'styled-components'

export const Estado = styled('div')`
  font-size: 12px;
  color: ${({ estado, theme }) => (estado === 'finalizada' ? 'white' : theme.colors.extra.normal)};
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
