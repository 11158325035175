import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { SET_FILTERS, SET_FILTERS_ERROR, SET_FILTERS_SUCCESS } from '../types/filters'
import {
  GET_AVISOS,
  GET_AVISOS_SUCCESS,
  GET_AVISOS_ERROR,
  CLEAN_AVISOS,
  GET_WORDINGS,
  GET_WORDINGS_SUCCESS,
  GET_WORDINGS_ERROR,
  GET_AVISOS_V2,
  CLEAN_AVISOS_V2,
  GET_AVISOS_ERROR_V2,
  GET_AVISOS_SUCCESS_V2,
  GetAvisosResponse,
} from '../types/listado-avisos'

const TOTAL_AVISOS_PER_PAGE = 20

function* getAvisosV2({ payload }) {
  yield put({ type: CLEAN_AVISOS_V2 })

  try {
    const result: GetAvisosResponse = yield call(services.listadoAvisos.GetAvisosV2, payload)
    const pagination = {
      currentPage: result ? result.number + 1 : 1,
      totalPages: result.total > TOTAL_AVISOS_PER_PAGE ? Math.ceil(result.total / result.size) : 0,
      elementsPerPage: result ? result.size : 20,
      totalElements: result ? result.total : 0,
    }

    const urlData = result && result.urlData ? result.urlData : null

    const filters = result ? result.filters : null

    const filtersApplied = result && result.filtersApplied ? result.filtersApplied : null

    const avisos = result && result.content.length > 0 ? result.content : null

    const avisosSuperdestacados =
      result && result.homeList && result.homeList.content.length > 0 ? result.homeList.content : null

    const avisosSponsoreados =
      result && result.listadoSponsor
        ? {
            empresa: {
              logo: result.listadoSponsor.logoURL || '',
              nombre: result.listadoSponsor.denominacion || '',
              id: result.listadoSponsor.idEmpresa || null,
            },
            avisos: result.listadoSponsor.content || null,
          }
        : null

    const totals = {
      totalAvisos: result ? result.total : 0,
      totalAvisosSuperdestacados: result && result.homeList ? result.homeList.content.length : 0,
      grandTotal: result ? result.total : 0,
    }

    const listadoAvisos = {
      pagination,
      avisos,
      avisosSuperdestacados,
      avisosSponsoreados,
      ...totals,
    }

    yield put({ type: GET_AVISOS_SUCCESS_V2, payload: listadoAvisos })
    yield put({ type: GET_WORDINGS, payload: result })
    yield put({
      type: SET_FILTERS,
      payload: {
        filters,
        filtersApplied,
        urlData,
      },
    })
    !avisos && !avisosSuperdestacados && !avisosSponsoreados && (yield put({ type: GET_AVISOS_ERROR_V2, error: true }))
  } catch (error) {
    yield put({ type: GET_AVISOS_ERROR_V2, error })
  }
}

function* getAvisos({ payload }) {
  yield put({ type: CLEAN_AVISOS })

  try {
    const result = yield call(services.listadoAvisos.GetAvisos, payload)
    const pagination = {
      currentPage: result ? result.number + 1 : 1,
      totalPages: result.total > TOTAL_AVISOS_PER_PAGE ? Math.ceil(result.total / result.size) : 0,
      elementsPerPage: result ? result.size : 20,
      totalElements: result ? result.total : 0,
    }

    const urlData = result && result.urlData ? result.urlData : null

    const filters = result ? result.filters : null

    const filtersApplied = result && result.filtersApplied ? result.filtersApplied : null

    const avisos = result && result.content.length > 0 ? result.content : null

    const avisosSuperdestacados =
      result && result.homeList && result.homeList.content.length > 0 ? result.homeList.content : null

    const avisosSponsoreados =
      result && result.listadoSponsor
        ? {
            empresa: {
              logo: result.listadoSponsor.logoURL || '',
              nombre: result.listadoSponsor.denominacion || '',
              id: result.listadoSponsor.idEmpresa || null,
            },
            avisos: result.listadoSponsor.content || null,
          }
        : null

    const totals = {
      totalAvisos: result ? result.total : 0,
      totalAvisosSuperdestacados: result && result.homeList ? result.homeList.content.length : 0,
      grandTotal: result ? result.totalSearched : 0,
    }

    const listadoAvisos = {
      pagination,
      avisos,
      avisosSuperdestacados,
      avisosSponsoreados,
      ...totals,
    }

    yield put({ type: GET_AVISOS_SUCCESS, payload: listadoAvisos })
    yield put({ type: GET_WORDINGS, payload: result })
    yield put({
      type: SET_FILTERS,
      payload: {
        filters,
        filtersApplied,
        urlData,
      },
    })
    !avisos && !avisosSuperdestacados && !avisosSponsoreados && (yield put({ type: GET_AVISOS_ERROR, error: true }))
  } catch (error) {
    yield put({ type: GET_AVISOS_ERROR, error })
  }
}

function* getFilters() {
  try {
    yield put({ type: SET_FILTERS_SUCCESS })
  } catch (error) {
    yield put({ type: SET_FILTERS_ERROR, error })
  }
}

function* getSeoWordings({ payload }) {
  try {
    const result = yield call(services.seoWording.CreateSeoWordings, payload)
    yield put({ type: GET_WORDINGS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_WORDINGS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_AVISOS, getAvisos)
  yield takeLatest<any>(GET_AVISOS_V2, getAvisosV2)
  yield takeLatest<any>(GET_WORDINGS, getSeoWordings)
  yield takeLatest(SET_FILTERS, getFilters)
}
