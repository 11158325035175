import { Request, Put, Delete, Get } from '@navent-jobs/config'

export class ErrorAvisosGuardados extends Error {}

const DEFAULT_PAGE_SIZE = 6

class AvisosGuardadosService {
  public getAvisosGuardados = async ({ page = 0, pageSize = DEFAULT_PAGE_SIZE }) => {
    const request = new Request()
    request.path = `/api/candidates/fav-job-posting?pageSize=${pageSize}&page=${page}`
    request.method = Get
    return request
      .call()
      .then(response => {
        const pagination = {
          currentPage: response ? response.number + 1 : 1,
          totalPages: response.total > pageSize ? Math.ceil(response.total / response.size) : 0,
          elementsPerPage: response ? response.size : 6,
          totalElements: response ? response.total : 0,
        }

        const avisos = response && response.content.length > 0 ? response.content : null

        const totalAvisos = response ? response.total : 0

        return {
          pagination,
          avisos,
          totalAvisos,
        }
      })
      .catch(error => {
        throw new ErrorAvisosGuardados(error)
      })
  }

  public setSaveJobPosting = async ({ payload }) => {
    const request = new Request()
    request.path = `/api/candidates/fav-job-posting/${payload.id}`
    request.method = Put
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorAvisosGuardados(error)
      })
  }

  public removeSaveJobPosting = async ({ payload }) => {
    const request = new Request()
    request.path = `/api/candidates/fav-job-posting/${payload.id}`
    request.method = Delete
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorAvisosGuardados(error)
      })
  }
}

const avisosGuardadosService = new AvisosGuardadosService()
export default avisosGuardadosService
