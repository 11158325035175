/* import gtm from './gtm-script' */
/* import getSiteControl from './get-site-control-script' */
import intercom from './intercom-script'

export interface Script {
  attributes?: { name: string; value: string }[]
  name?: string
  parent: string
  location: 'start' | 'end'
  type: string
  code: string
  lazyLoad: boolean
}

const scripts = [
  /* gtm( process.env.SITE_ID ), */
  /* getSiteControl(process.env.SITE_ID), */
  ...intercom(),
]

export default scripts
