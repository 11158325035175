import { GeolocationType } from '../../../../services/geolocation-service'

export const UPDATE_DATOS_PERSONALES_ONBORDING = 'UPDATE_DATOS_PERSONALES_ONBORDING'
export const UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS = 'UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS'
export const UPDATE_DATOS_PERSONALES_ONBORDING_ERROR = 'UPDATE_DATOS_PERSONALES_ONBORDING_ERROR'

export interface UpdateDatosPersonalesOnbordingType {
  paisResidenciaId: number
  fechaNacimiento: string
  genero: string
  geolocation: GeolocationType
}
