import { Request, Post } from '@navent-jobs/config'
import { DeleteAccountType } from '../redux/types/applicant/account/deleteAccount'

export class ErrorApplicant extends Error {}

class DeleteAccount {
  public async postDeleteAccount(value: DeleteAccountType) {
    const request = new Request()
    request.path = `api/ajustes/delete-cuenta`
    request.method = Post
    request.body = value.data
    return request.call().catch(error => {
      throw new Error(error.response.status)
    })
  }
}
const deleteaccount = new DeleteAccount()
export default deleteaccount
