import React from 'react'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import {
  Page,
  Title,
  Description,
  TableWrapper,
  BoldText,
  HeaderTable,
  LevelTable,
  PrimaryHeader,
  SecondaryHeader,
  DataTable,
  RowTable,
} from './mixins'
import back from '../../../../assets/images/background-waves.svg'

const makeTableDesktopLandscape = () => {
  return (
    <TableWrapper>
      <HeaderTable>
        <tr>
          <PrimaryHeader align="center">MCER</PrimaryHeader>
        </tr>
        <tr>
          <SecondaryHeader align="center">Inglés</SecondaryHeader>
        </tr>
      </HeaderTable>
      <LevelTable>
        <tr>
          <DataTable align="center">A1</DataTable>
          <DataTable align="center">A2</DataTable>
          <DataTable align="center">B1</DataTable>
          <DataTable align="center">B2</DataTable>
          <DataTable align="center">C1</DataTable>
          <DataTable align="center">C2</DataTable>
        </tr>
        <RowTable>
          <DataTable align="center">Beginner</DataTable>
          <DataTable align="center">Elementary</DataTable>
          <DataTable align="center">Pre Intermediate</DataTable>
          <DataTable align="center">Intermediate</DataTable>
          <DataTable align="center">Upper Intermediate</DataTable>
          <DataTable align="center">Advanced</DataTable>
        </RowTable>
      </LevelTable>
    </TableWrapper>
  )
}

const makeTableMobilePortrait = () => {
  return (
    <TableWrapper>
      <LevelTable>
        <tr>
          <PrimaryHeader isMobilePortrait align="center">
            MCER
          </PrimaryHeader>
          <SecondaryHeader align="center">Inglés</SecondaryHeader>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            A1
          </DataTable>
          <DataTable align="center">Beginner</DataTable>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            A2
          </DataTable>
          <DataTable align="center">Elementary</DataTable>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            B1
          </DataTable>
          <DataTable align="center">Pre Intermediate</DataTable>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            B2
          </DataTable>
          <DataTable align="center">Intermediate</DataTable>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            C1
          </DataTable>
          <DataTable align="center">Upper Intermediate</DataTable>
        </tr>
        <tr>
          <DataTable left isMobilePortrait align="center">
            C2
          </DataTable>
          <DataTable align="center">Advanced</DataTable>
        </tr>
      </LevelTable>
    </TableWrapper>
  )
}

const Component = () => {
  const { isDesktop, isTabletLandscape } = useWindowSize()

  return (
    <Page urlImg={back}>
      <MainContainer noGutter>
        <Row noGutter>
          <Col noGutter lg={6} offset={{ lg: 3 }}>
            <Title>Equivalencia de Niveles</Title>
          </Col>
        </Row>
        <Row noGutter>
          <Col noGutter lg={8} offset={{ lg: 2 }}>
            <Description>
              El test está basado en el <BoldText>Marco Común Europeo de Referencia (MCER)</BoldText>, que estandariza
              internacionalmente cada competencia lingüística requerida por nivel.
            </Description>
          </Col>
        </Row>
        <Row noGutter>
          <Col noGutter lg={10} offset={{ lg: 1 }}>
            {isDesktop || isTabletLandscape ? makeTableDesktopLandscape() : makeTableMobilePortrait()}
          </Col>
        </Row>
      </MainContainer>
    </Page>
  )
}

export default Component
