import React, { Suspense, useLayoutEffect } from 'react'
// Tools
import { connect } from 'react-redux'

// Components
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import Meta from './components/Meta'
import LayoutAuthHeader from '../../components/layouts/layout-auth-header'
import { PrivateRoute } from '../../components/private-route'
import PageLoader from '../../components/PageLoader'
import PageTitle from '../../components/PageTitle'
import PageSectionCard from '../../components/PageSectionCard'
import Privacy from './components/PrivacyContent'
import EliminarCuenta from './components/EliminarCuenta'
import PasswordChange from './components/PasswordChangeContent'
import UpdateEmail from './components/UpdateEmail'
import { handleSmoothScroll } from '../../hooks/handle-smooth-scroll'

// Styles
import { PageContent } from './mixins'

const MyAccount = props => {
  const { hash } = window.location
  const { applicant } = props

  useLayoutEffect(() => {
    if (hash) {
      setTimeout(() => {
        handleSmoothScroll(hash, 120)
      }, 1500)
    }
  }, [hash])

  return (
    <PrivateRoute>
      <Suspense fallback={<PageLoader />}>
        <LayoutAuthHeader {...props}>
          <PageContent>
            <Meta />
            <PageTitle iconName="icon-light-settings-2" title="Mi cuenta" maxWidth={684} />
            <MainContainer>
              <Row>
                <Col>
                  <PageSectionCard>
                    <Privacy />
                  </PageSectionCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageSectionCard>
                    <PasswordChange isLogged={!!applicant} />
                    <UpdateEmail isLogged={!!applicant} userEmail={applicant?.email} />
                    <EliminarCuenta isLogged={!!applicant} />
                  </PageSectionCard>
                </Col>
              </Row>
            </MainContainer>
          </PageContent>
        </LayoutAuthHeader>
      </Suspense>
    </PrivateRoute>
  )
}

const states = ({ applicantStore }) => {
  const { applicant } = applicantStore
  return {
    applicant,
  }
}

export default connect(states)(MyAccount)
