import styled from 'styled-components'

export const Text = styled.p`
  margin: 12px 0;
  padding-left: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, color }) => theme.colors[color] || color};
`
