import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const CustomButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 21px;
  min-width: 125px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 24px;
  }
`

export const IconContainer = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

export const IconCustom = styled(Icon)`
  @media (min-width: 1024px) {
    ${({ hasChildren }) => hasChildren && 'margin-right: 13px;'}
  }
`

export const CustomIconButton = styled(Button)`
  border: 0;
`
