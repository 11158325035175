import { status } from '../types/status'
import {
  EDITAR_SUSCRIPCION,
  EDITAR_SUSCRIPCION_ERROR,
  EDITAR_SUSCRIPCION_RESET,
  EDITAR_SUSCRIPCION_SUCCESS,
  GET_SUSCRIPCION,
  GET_SUSCRIPCION_ERROR,
  GET_SUSCRIPCION_SUCCESS,
  SuscripcionActions,
  SuscripcionState,
  initialState,
} from '../types/suscripcion'

const reducers = (state: SuscripcionState = initialState, action: SuscripcionActions): SuscripcionState => {
  switch (action.type) {
    case GET_SUSCRIPCION:
      return {
        ...state,
        getSuscripcion: {
          ...state.getSuscripcion,
          status: status.loading,
        },
      }
    case GET_SUSCRIPCION_SUCCESS:
      return {
        ...state,
        getSuscripcion: {
          ...state.getSuscripcion,
          items: action.payload,
          status: status.success,
        },
      }
    case GET_SUSCRIPCION_ERROR:
      return {
        ...state,
        getSuscripcion: {
          ...state.getSuscripcion,
          status: status.error,
        },
      }
    case EDITAR_SUSCRIPCION:
      return {
        ...state,
        editSuscripcion: {
          ...state.editSuscripcion,
          status: status.loading,
        },
      }
    case EDITAR_SUSCRIPCION_SUCCESS:
      return {
        ...state,
        editSuscripcion: {
          ...state.editSuscripcion,
          status: status.success,
        },
      }
    case EDITAR_SUSCRIPCION_ERROR:
      return {
        ...state,
        editSuscripcion: {
          ...state.editSuscripcion,
          status: status.error,
        },
      }
    case EDITAR_SUSCRIPCION_RESET:
      return {
        ...state,
        editSuscripcion: {
          ...state.editSuscripcion,
          status: status.initial,
        },
      }
    default:
      return state
  }
}

export default reducers
