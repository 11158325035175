/* eslint-disable max-len */
const avisosFake = {
  aviso: {
    localizacion: 'Panamá, Panamá',
    idPais: 20,
  },
  banners: {
    10: {
      titulo: 'Operario hombre sector construcción',
      detalle:
        'Vacantes para personas con experiencia en el sector y gran fuerza física, capaces de manipular objetos pesados de manera autónoma. Candidatas mujeres abstenerse.',
    },
  },
}

export default avisosFake
