const marketplace = {
  enabled: false,
  showPrice: false,
  bannerSection: false,
  banners: {
    enabled: false,
  },
  courses: {
    enabled: false,
  },
}

export default marketplace
