import { ReferenciaLaboral, UpdateExperienciaType, PrimerEmpleoType, ReplaceExperienciasType } from '../commons'

export const PRIMER_EMPLEO = 'PRIMER_EMPLEO'
export const PRIMER_EMPLEO_SUCCESS = 'PRIMER_EMPLEO_SUCCESS'
export const PRIMER_EMPLEO_ERROR = 'PRIMER_EMPLEO_ERROR'
export const PRIMER_EMPLEO_RESET = 'PRIMER_EMPLEO_RESET'

export const DELETE_EXPERIENCIA = 'DELETE_EXPERIENCIA'
export const DELETE_EXPERIENCIA_SUCCESS = 'DELETE_EXPERIENCIA_SUCCESS'
export const DELETE_EXPERIENCIA_ERROR = 'DELETE_EXPERIENCIA_ERROR'
export const DELETE_EXPERIENCIA_RESET = 'DELETE_EXPERIENCIA_RESET'

export const DELETE_REFERENCIA_EXPERIENCIA = 'DELETE_REFERENCIA_EXPERIENCIA'
export const DELETE_REFERENCIA_EXPERIENCIA_SUCCESS = 'DELETE_REFERENCIA_EXPERIENCIA_SUCCESS'
export const DELETE_REFERENCIA_EXPERIENCIA_ERROR = 'DELETE_REFERENCIA_EXPERIENCIA_ERROR'
export const DELETE_REFERENCIA_EXPERIENCIA_RESET = 'DELETE_REFERENCIA_EXPERIENCIA_RESET'

export const UPDATE_EXPERIENCIA = 'UPDATE_EXPERIENCIA'
export const UPDATE_EXPERIENCIA_RESET = 'UPDATE_EXPERIENCIA_RESET'
export const UPDATE_EXPERIENCIA_SUCCESS = 'UPDATE_EXPERIENCIA_SUCCESS'
export const UPDATE_EXPERIENCIA_ERROR = 'UPDATE_EXPERIENCIA_ERROR'

export const UPDATE_EXPERIENCIAS = 'UPDATE_EXPERIENCIAS'
export const UPDATE_EXPERIENCIAS_RESET = 'UPDATE_EXPERIENCIAS_RESET'
export const UPDATE_EXPERIENCIAS_SUCCESS = 'UPDATE_EXPERIENCIAS_SUCCESS'
export const UPDATE_EXPERIENCIAS_ERROR = 'UPDATE_EXPERIENCIAS_ERROR'

export const UPDATE_REFERENCIA_LABORAL = 'UPDATE_REFERENCIA_LABORAL'
export const UPDATE_REFERENCIA_LABORAL_SUCCESS = 'UPDATE_REFERENCIA_LABORAL_SUCCESS'
export const UPDATE_REFERENCIA_LABORAL_ERROR = 'UPDATE_REFERENCIA_LABORAL_ERROR'
export const UPDATE_REFERENCIA_LABORAL_RESET = 'UPDATE_REFERENCIA_LABORAL_RESET'

// Update experiencias laborales
export interface UpdateExperiencias {
  type: typeof UPDATE_EXPERIENCIAS
  payload: ReplaceExperienciasType
}
export interface UpdateExperienciasReset {
  type: typeof UPDATE_EXPERIENCIAS_RESET
  payload: null
}
export interface UpdateExperienciasSuccess {
  type: typeof UPDATE_EXPERIENCIAS_SUCCESS
  payload: null
}
export interface UpdateExperienciasError {
  type: typeof UPDATE_EXPERIENCIAS_ERROR
  payload: null
}

// Update experiencia laboral
export interface UpdateExperiencia {
  type: typeof UPDATE_EXPERIENCIA
  payload: UpdateExperienciaType
}
export interface UpdateExperienciaReset {
  type: typeof UPDATE_EXPERIENCIA_RESET
  payload: null
}
export interface UpdateExperienciaSuccess {
  type: typeof UPDATE_EXPERIENCIA_SUCCESS
  payload: null
}
export interface UpdateExperienciaError {
  type: typeof UPDATE_EXPERIENCIA_ERROR
  payload: null
}

// Delete Experiencia
export interface DeleteExperiencia {
  type: typeof DELETE_EXPERIENCIA
  payload: string
}
export interface DeleteExperienciaSuccess {
  type: typeof DELETE_EXPERIENCIA_SUCCESS
  payload: null
}
export interface DeleteExperienciaError {
  type: typeof DELETE_EXPERIENCIA_ERROR
  payload: null
}
export interface DeleteExperienciaReset {
  type: typeof DELETE_EXPERIENCIA_RESET
  payload: null
}

// Delete referencia Experiencia
export interface DeleteReferenciaExperiencia {
  type: typeof DELETE_REFERENCIA_EXPERIENCIA
  payload: string
}
export interface DeleteReferenciaExperienciaSuccess {
  type: typeof DELETE_REFERENCIA_EXPERIENCIA_SUCCESS
  payload: null
}
export interface DeleteReferenciaExperienciaError {
  type: typeof DELETE_REFERENCIA_EXPERIENCIA_ERROR
  payload: null
}

export interface DeleteReferenciaExperienciaReset {
  type: typeof DELETE_REFERENCIA_EXPERIENCIA_RESET
  payload: null
}

// Update referencia laboral
export interface UpdateReferenciaLaboral {
  type: typeof UPDATE_REFERENCIA_LABORAL
  payload: ReferenciaLaboral
}
export interface UpdateReferenciaLaboralSuccess {
  type: typeof UPDATE_REFERENCIA_LABORAL_SUCCESS
  payload: null
}
export interface UpdateReferenciaLaboralError {
  type: typeof UPDATE_REFERENCIA_LABORAL_ERROR
  payload: null
}
export interface UpdateReferenciaLaboralReset {
  type: typeof UPDATE_REFERENCIA_LABORAL_RESET
  payload: null
}

// Primer empleo
export interface PrimerEmpleoReset {
  type: typeof PRIMER_EMPLEO_RESET
  payload: null
}
export interface PrimerEmpleo {
  type: typeof PRIMER_EMPLEO
  payload: PrimerEmpleoType
}
export interface PrimerEmpleoSuccess {
  type: typeof PRIMER_EMPLEO_SUCCESS
  payload: null
}
export interface PrimerEmpleoError {
  type: typeof PRIMER_EMPLEO_ERROR
  payload: null
}
