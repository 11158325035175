import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS'
export const GET_CAMPAIGN_ERROR = 'GET_CAMPAIGN_ERROR'

export const GET_COMPANY_LOGOS = 'GET_COMPANY_LOGOS'
export const GET_COMPANY_LOGOS_SUCCESS = 'GET_COMPANY_LOGOS_SUCCESS'
export const GET_COMPANY_LOGOS_ERROR = 'GET_COMPANY_LOGOS_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */
export interface Campaign {
  key: string
  image: string
  link: string | null
  external: boolean
  newTab: boolean
}

export interface CompanyLogos {
  companyId: number
  keyword: string
  image: string
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface MarketingState {
  campaign: {
    value: Campaign | null
    status: StatusType
  }
  companyLogos: {
    value: CompanyLogos[] | null
    status: StatusType
  }
}

export const initialState: MarketingState = {
  campaign: {
    value: null,
    status: status.initial,
  },
  companyLogos: {
    value: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface GetCampaign {
  type: typeof GET_CAMPAIGN
  payload: null
}

export interface GetCampaignSuccess {
  type: typeof GET_CAMPAIGN_SUCCESS
  payload: Campaign
}

export interface GetCampaignError {
  type: typeof GET_CAMPAIGN_ERROR
  payload: null
}

export interface GetCompanyLogos {
  type: typeof GET_COMPANY_LOGOS
  payload: null
}

export interface GetCompanyLogosSuccess {
  type: typeof GET_COMPANY_LOGOS_SUCCESS
  payload: CompanyLogos[]
}

export interface GetCompanyLogosError {
  type: typeof GET_COMPANY_LOGOS_ERROR
  payload: null
}

export type MarketingActions =
  | GetCampaign
  | GetCampaignSuccess
  | GetCampaignError
  | GetCompanyLogos
  | GetCompanyLogosSuccess
  | GetCompanyLogosError
