import React from 'react'

import { LookAndFeelWrapper, LookAndFeelImage, DefaultLookAndFeelContainer } from './mixins'

const Component = ({ productoLookAndFeel, defaultImg = null }) => {
  if (!defaultImg && !productoLookAndFeel) {
    return null
  }

  return (
    <LookAndFeelWrapper id="look-and-feel">
      {!productoLookAndFeel ? (
        <DefaultLookAndFeelContainer src={defaultImg} />
      ) : (
        <LookAndFeelImage productoLookAndFeel={productoLookAndFeel} />
      )}
    </LookAndFeelWrapper>
  )
}

export default Component
