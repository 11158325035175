import { makeCompanyURL, minAvisosEnFacetas } from '@navent-jobs/interlinking'

const CONFIDENCIAL = 'Confidencial'

interface Args {
  company: {
    cantidadAvisos: number
    confidencial?: boolean
    denominacion: string
    id: number
  }
}

export const getCompanyURL = ({ company }: Args) => {
  let settingsURL = {}

  /**
   * minAvisosEnFacet: indica la cantidad de avisos mínimos necesarios que debe tener la empresa para que se muestre su URL.
   * En caso que no se cumpla dicha cantidad, la URL de la empresa no se muestra.
   * src: https://github.com/JobIntTech/jobs-portal-libraries-interlinking/blob/master/src/config/seo-interlinking.ts
   *
   * noFollow convierte <link> en un <span> para que google no trackee estas empresas.
   * se utiliza el attr "data-link" en el <span data-link="company-url"> para que se pueda ingresar al perfil de empresa.
   */
  const noFollow = company?.cantidadAvisos < minAvisosEnFacetas

  // data necesaria para crear la URL de la empresa: https://github.com/JobIntTech/jobs-portal-libraries-interlinking/blob/master/src/url/Url.ts
  const dataURL = {
    confidencial: company?.confidencial || false,
    denominacion: company?.confidencial ? CONFIDENCIAL : company?.denominacion,
    id: company?.id !== undefined ? company?.id : null,
  }

  // obtenemos URL de la empresa con interlinking para crear las settings.
  const companyURL: string = makeCompanyURL(dataURL)

  if (companyURL && company?.id !== null) {
    if (noFollow) {
      settingsURL = {
        noFollow: true,
        link: companyURL,
      }
    } else {
      settingsURL = {
        href: companyURL,
      }
    }
  }

  return settingsURL
}
