import React from 'react'
import { Company } from './mixins'

const Component = ({ confidencial, empresa }) => {
  if (confidencial) {
    return <Company>Confidencial</Company>
  }

  return <Company>{empresa}</Company>
}

export default Component
