import { status, StatusType } from './status'

export const GET_PROMEDIOS_SALARIALES = 'GET_PROMEDIOS_SALARIALES'
export const GET_PROMEDIOS_SALARIALES_SUCCESS = 'GET_PROMEDIOS_SALARIALES_SUCCESS'
export const GET_PROMEDIOS_SALARIALES_ERROR = 'GET_PROMEDIOS_SALARIALES_ERROR'

export const GET_PROMEDIO_SALARIAL_AREA = 'GET_PROMEDIO_SALARIAL_AREA'
export const GET_PROMEDIO_SALARIAL_AREA_SUCCESS = 'GET_PROMEDIO_SALARIAL_AREA_SUCCESS'
export const GET_PROMEDIO_SALARIAL_AREA_ERROR = 'GET_PROMEDIO_SALARIAL_AREA_ERROR'

export const CALCULAR_SALARIO_NETO = 'CALCULAR_SALARIO_NETO'
export const CALCULAR_SALARIO_NETO_SUCCESS = 'CALCULAR_SALARIO_NETO_SUCCESS'
export const CALCULAR_SALARIO_NETO_ERROR = 'CALCULAR_SALARIO_NETO_ERROR'

export interface PromedioSalarial {
  idSemantico: string
  nombre: string
  promedio: number
  cantidad: number
  minimo: number
  maximo: number
  fechaModificacion: string
  subareas?: PromedioSalarial[]
}

export interface ImpuestoSalario {
  nombre: string
  porcentaje: number
  monto: number
}

export interface SalarioNetoCalculado {
  salarioBruto: number
  salarioNeto: number
  impuestos: ImpuestoSalario[]
}

export interface GetPromediosSalariales {
  type: typeof GET_PROMEDIOS_SALARIALES
  payload: null
}
export interface GetPromediosSalarialesSuccess {
  type: typeof GET_PROMEDIOS_SALARIALES_SUCCESS
  payload: PromedioSalarial[]
}

export interface GetPromediosSalarialesError {
  type: typeof GET_PROMEDIOS_SALARIALES_ERROR
  payload: boolean
}

export interface GetPromedioSalarialArea {
  type: typeof GET_PROMEDIO_SALARIAL_AREA
  payload: string
}
export interface GetPromedioSalarialAreaSuccess {
  type: typeof GET_PROMEDIO_SALARIAL_AREA_SUCCESS
  payload: PromedioSalarial
}

export interface GetPromedioSalarialAreaError {
  type: typeof GET_PROMEDIO_SALARIAL_AREA_ERROR
  payload: boolean
}

export interface CalcularSalarioNeto {
  type: typeof CALCULAR_SALARIO_NETO
  payload: number
}
export interface CalcularSalarioNetoSuccess {
  type: typeof CALCULAR_SALARIO_NETO_SUCCESS
  payload: SalarioNetoCalculado
}

export interface CalcularSalarioNetoError {
  type: typeof CALCULAR_SALARIO_NETO_ERROR
  payload: boolean
}

export interface PromedioSalarialState {
  statesPromedios: StatusType
  statesPromedioArea: StatusType
  promedios: PromedioSalarial[] | null
  promedioArea: PromedioSalarial | null
  calculadora: {
    states: StatusType
    salario: SalarioNetoCalculado | null
  }
}

export const initialState: PromedioSalarialState = {
  statesPromedios: status.initial,
  statesPromedioArea: status.initial,
  promedios: null,
  promedioArea: null,
  calculadora: {
    states: status.initial,
    salario: null,
  },
}

export type PromedioSalarialActions =
  | GetPromediosSalariales
  | GetPromediosSalarialesSuccess
  | GetPromediosSalarialesError
  | GetPromedioSalarialArea
  | GetPromedioSalarialAreaSuccess
  | GetPromedioSalarialAreaError
  | CalcularSalarioNeto
  | CalcularSalarioNetoError
  | CalcularSalarioNetoSuccess
