import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  SET_ACTIVE_AVISO,
  SET_ACTIVE_AVISO_SUCCESS,
  SET_ACTIVE_AVISO_ERROR,
  RESET_ACTIVE_AVISO,
  SET_BUSQUEDAS_RELACIONADAS,
  SET_BUSQUEDAS_RELACIONADAS_SUCCESS,
  SET_BUSQUEDAS_RELACIONADAS_ERROR,
} from '../types/active-aviso'

function* setActiveAviso({ payload }) {
  try {
    const result = yield call(services.fichaAviso.getFicha, payload.id)
    const statusCode = result?.response?.status >= 400

    if (statusCode) {
      yield put({ type: SET_ACTIVE_AVISO_ERROR, payload: result.response.status })
    } else {
      result.aviso = { ...result.aviso, ...payload }
      yield put({ type: SET_ACTIVE_AVISO_SUCCESS, payload: { ...result } })
    }
  } catch (error) {
    yield put({ type: SET_ACTIVE_AVISO_ERROR, error })
  }
}

function* resetActiveAviso() {
  try {
    yield put({ type: SET_ACTIVE_AVISO_SUCCESS, payload: null })
  } catch (error) {
    yield put({ type: SET_ACTIVE_AVISO_ERROR, error })
  }
}

function* setBusquedasRelacionadas({ payload }) {
  try {
    const result = yield call(services.fichaAviso.getBusquedasRelacionadas, payload)
    yield put({ type: SET_BUSQUEDAS_RELACIONADAS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: SET_BUSQUEDAS_RELACIONADAS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SET_ACTIVE_AVISO, setActiveAviso)
  yield takeLatest<any>(RESET_ACTIVE_AVISO, resetActiveAviso)
  yield takeLatest<any>(SET_BUSQUEDAS_RELACIONADAS, setBusquedasRelacionadas)
}
