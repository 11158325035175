import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

interface GraphColorsMap {
  colorEducation: string[]
  colorAges: string[]
  colorYearsExp: string[]
}

const useGraphColors = (): GraphColorsMap => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const colorEducation = [colors.secondary.darken, colors.secondary.lighten, colors.tertiary.normal]
  const colorAges = [colors.tertiary.normal]
  const colorYearsExp = [colors.tertiary.normal]

  return {
    colorEducation,
    colorAges,
    colorYearsExp,
  }
}

export default useGraphColors
