import React from 'react'
import ContentLoader from 'react-content-loader'

const Skeleton = props => {
  return (
    <ContentLoader
      speed={2}
      width="274"
      height="44"
      viewBox="0 0 274 44"
      backgroundcolor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="198" cy="22" r="16" />
      <circle cx="252" cy="22" r="22" />
    </ContentLoader>
  )
}

export default Skeleton
