import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    padding-top: 56px;

    @media (min-width: 1200px) {
      padding-top: ${({ singleHeader }) => singleHeader ? '72px' : '104px'};
    }
  }`

export const Page = styled.div`
  position: relative;
  z-index: 10;
`

export const PageContent = styled.div`
  height: calc(100vh - 72px);
  overflow: auto;
`
