import { Aviso } from './listado-avisos'

export const RESET_ACTIVE_AVISO = 'RESET_ACTIVE_AVISO'

export const SET_ACTIVE_AVISO = 'SET_ACTIVE_AVISO'
export const SET_ACTIVE_AVISO_SUCCESS = 'SET_ACTIVE_AVISO_SUCCESS'
export const SET_ACTIVE_AVISO_ERROR = 'SET_ACTIVE_AVISO_ERROR'

export const SET_BUSQUEDAS_RELACIONADAS = 'SET_BUSQUEDAS_RELACIONADAS'
export const SET_BUSQUEDAS_RELACIONADAS_SUCCESS = 'SET_BUSQUEDAS_RELACIONADAS_SUCCESS'
export const SET_BUSQUEDAS_RELACIONADAS_ERROR = 'SET_BUSQUEDAS_RELACIONADAS_ERROR'

export interface ActiveAviso extends Aviso {
  listPosition?: number | undefined
}

export interface BusquedasRelacionada {
  titulo: string
  link: string
}

export interface ResetActiveAviso {
  type: typeof RESET_ACTIVE_AVISO
  payload: null
}

export interface SetActiveAviso {
  type: typeof SET_ACTIVE_AVISO
  payload: {
    id: number
    listPosition?: number
  }
}

export interface SetActiveAvisoSuccess {
  type: typeof SET_ACTIVE_AVISO_SUCCESS
  payload: ActiveAviso
}

export interface SetActiveAvisoError {
  type: typeof SET_ACTIVE_AVISO_ERROR
  payload: null
}

export interface SetBusquedasRelacionadas {
  type: typeof SET_BUSQUEDAS_RELACIONADAS
  payload: ActiveAviso
}

export interface SetBusquedasRelacionadasSuccess {
  type: typeof SET_BUSQUEDAS_RELACIONADAS_SUCCESS
  payload: BusquedasRelacionada[]
}

export interface SetBusquedasRelacionadasError {
  type: typeof SET_BUSQUEDAS_RELACIONADAS_ERROR
  payload: null
}

export interface ActiveAvisoState {
  activeAvisoId: number | null
  aviso: ActiveAviso | null
  busquedasRelacionadas: BusquedasRelacionada[] | null
  activeAvisoStates: {
    loading: boolean
    success: boolean
    error: boolean | { error: string, statusCode: number }
  }
  busquedasRelacionadasStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: ActiveAvisoState = {
  activeAvisoId: null,
  aviso: null,
  busquedasRelacionadas: null,
  activeAvisoStates: {
    loading: false,
    success: false,
    error: false
  },
  busquedasRelacionadasStates: {
    loading: false,
    success: false,
    error: false
  }
}

export type ActiveAvisoActions =
  | ResetActiveAviso
  | SetActiveAviso
  | SetActiveAvisoSuccess
  | SetActiveAvisoError
  | SetBusquedasRelacionadas
  | SetBusquedasRelacionadasSuccess
  | SetBusquedasRelacionadasError
