import React, { useEffect } from 'react'
// Tools
import { Trans } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import FiltersSection from './filters'

// Components
import Avisos from './Avisos'
import PublicationPlaceholder from './placeholder'

// Redux
import { showSnackbar } from '../../../../redux/actions/applicant/curriculum/snackbar'

// Styles
import { Main, DescriptiveHeader, VacanciesMessage, TotalText, VacanciesMessageContainer } from './mixins'

interface PublicationsProps {
  avisos?
  company?
  page: number
  totalPages: number
  pageHandler: (page: number) => void
  successLoad: boolean
  queryParams: URLSearchParams
  saveJobPostingStates: {
    success: boolean
    error: boolean
    loading: boolean
  }
  totalJobPostings?
  filters
}

const Publications = ({
  avisos,
  company,
  page,
  totalPages,
  pageHandler,
  successLoad,
  queryParams,
  saveJobPostingStates,
  totalJobPostings,
  filters,
}: PublicationsProps) => {
  const { isMobile, isTabletPortrait } = useWindowSize()
  const dispatch = useDispatch()

  const nombreEmpresa = company?.denominacion
  const cantidadAvisos = avisos?.content.length

  const filtros = avisos?.filters

  useEffect(() => {
    if (saveJobPostingStates?.success) {
      dispatch(showSnackbar('Guardaste este aviso', 'icon-bold-heart'))
    }
  }, [saveJobPostingStates?.success])

  return (
    <Main>
      {!(isMobile || isTabletPortrait) && <FiltersSection filtros={filtros} filtersApplied={filters} />}
      <DescriptiveHeader>
        <VacanciesMessageContainer>
          {nombreEmpresa && (
            <VacanciesMessage>
              <Trans
                i18nKey="companySummary.publications.vacanciesDescription"
                values={{ cant: totalJobPostings, nombreEmpresa }}
                components={[<TotalText></TotalText>]}
              />
            </VacanciesMessage>
          )}

          {(isMobile || isTabletPortrait) && <FiltersSection filtros={filtros} filtersApplied={filters} />}
        </VacanciesMessageContainer>
      </DescriptiveHeader>

      {successLoad ? (
        <Avisos
          avisos={avisos}
          cantidadAvisos={cantidadAvisos}
          page={page}
          totalPages={totalPages}
          pageHandler={pageHandler}
          queryParams={queryParams}
        />
      ) : (
        <PublicationPlaceholder />
      )}
    </Main>
  )
}

const states = ({ saveJobPostingStore, companySummaryStore }) => ({
  saveJobPostingStates: saveJobPostingStore && saveJobPostingStore.setSaveJobPosting.states,
  totalJobPostings: companySummaryStore.listadoAvisos?.totalSearched,
  filters: companySummaryStore?.filters,
})

export default connect(states)(Publications)
