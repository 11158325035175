import { Aviso } from './listado-avisos'

export const GET_AVISOS_GUARDADOS = 'GET_AVISOS_GUARDADOS'
export const GET_AVISOS_GUARDADOS_SUCCESS = 'GET_AVISOS_GUARDADOS_SUCCESS'
export const GET_AVISOS_GUARDADOS_ERROR = 'GET_AVISOS_GUARDADOS_ERROR'

export const SET_SAVE_JOB_POSTING = 'SET_SAVE_JOB_POSTING'
export const SET_SAVE_JOB_POSTING_SUCCESS = 'SET_SAVE_JOB_POSTING_SUCCESS'
export const SET_SAVE_JOB_POSTING_ERROR = 'SET_SAVE_JOB_POSTING_ERROR'
export const SET_SAVE_JOB_POSTING_RESET = 'SET_SAVE_JOB_POSTING_RESET'

export const DELETE_SAVE_JOB_POSTING = 'DELETE_SAVE_JOB_POSTING'
export const DELETE_SAVE_JOB_POSTING_SUCCESS = 'DELETE_SAVE_JOB_POSTING_SUCCESS'
export const DELETE_SAVE_JOB_POSTING_ERROR = 'DELETE_SAVE_JOB_POSTING_ERROR'
export const DELETE_SAVE_JOB_POSTING_RESET = 'DELETE_SAVE_JOB_POSTING_RESET'

export const REFRESH_AVISOS_PERFIL = 'REFRESH_AVISOS_PERFIL'

interface Pagination {
  currentPage: number
  totalPages: number
  elementsPerPage: number
  totalElements: number
}

export interface GetAvisosGuardadosParams {
  page: number
  pageSize?: number
}

export interface GetAvisosGuardados {
  type: typeof GET_AVISOS_GUARDADOS
  payload: GetAvisosGuardadosParams
}

export interface GetAvisosGuardadosSuccess {
  type: typeof GET_AVISOS_GUARDADOS_SUCCESS
  payload: {
    pagination: Pagination
    avisos: Aviso[]
  }
}

export interface GetAvisosGuardadosError {
  type: typeof GET_AVISOS_GUARDADOS_ERROR
  payload: boolean
}

export interface SaveJobPostingRequestDto {
  id: number | string
  savePosting: boolean
}

export interface SetSaveJobPosting {
  type: typeof SET_SAVE_JOB_POSTING
  payload: SaveJobPostingRequestDto
}

export interface SetSaveJobPostingSuccess {
  type: typeof SET_SAVE_JOB_POSTING_SUCCESS
  payload: null
}

export interface SetSaveJobPostingError {
  type: typeof SET_SAVE_JOB_POSTING_ERROR
  payload: boolean
}

export interface SetSaveJobPostingReset {
  type: typeof SET_SAVE_JOB_POSTING_RESET
}

export interface DeleteSaveJobPosting {
  type: typeof DELETE_SAVE_JOB_POSTING
  payload: SaveJobPostingRequestDto
}

export interface DeleteSaveJobPostingSuccess {
  type: typeof DELETE_SAVE_JOB_POSTING_SUCCESS
  payload: null
}

export interface DeleteSaveJobPostingError {
  type: typeof DELETE_SAVE_JOB_POSTING_ERROR
  payload: boolean
}

export interface DeleteSaveJobPostingReset {
  type: typeof DELETE_SAVE_JOB_POSTING_RESET
  payload: null
}

export interface SaveJobPostingState {
  setSaveJobPosting: {
    saved: {} | null
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
  deleteSaveJobPosting: {
    saved: {} | null
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
  loading: boolean
  error: boolean
  avisos: Aviso[] | null
  pagination: Pagination
}

export const initialState: SaveJobPostingState = {
  setSaveJobPosting: {
    saved: {},
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteSaveJobPosting: {
    saved: {},
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  loading: false,
  error: false,
  avisos: null,
  pagination: {
    currentPage: 0,
    totalPages: 0,
    elementsPerPage: 10,
    totalElements: 0,
  },
}

export type SaveJobPostingAction =
  | SetSaveJobPosting
  | SetSaveJobPostingSuccess
  | SetSaveJobPostingError
  | SetSaveJobPostingReset
  | DeleteSaveJobPosting
  | DeleteSaveJobPostingSuccess
  | DeleteSaveJobPostingError
  | DeleteSaveJobPostingReset
  | GetAvisosGuardados
  | GetAvisosGuardadosSuccess
  | GetAvisosGuardadosError
