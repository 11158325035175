import styled from 'styled-components'

export const Page = styled.div`
  margin-top: 32px;
  padding: 24px 16px 32px;
  background: ${({ theme }) => theme.colors.grey0};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 0;
    padding: 80px 16px;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 32px;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 44px;
    text-align: center;
  }
`

export const Img = styled.img``

export const Subtitle = styled.p`
  margin: 0 auto 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey84};
`
export const DescriptionSubtitle = styled(Subtitle)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const Description = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &&:last-child {
    margin-bottom: 0;
  }
`

export const ColContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 43px;
  }
`

export const ConditionsWrapper = styled.div`
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    justify-content: center;
    align-items: center;
  }
`
