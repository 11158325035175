const curriculum = {
  marketplace: {
    enabled: false,
    products: {
      enabled: false,
    },
    benefits: {
      enabled: false,
    },
  },
  expoFeria: {
    enabled: false,
  },
}

export default curriculum
