import { colorPalletForPructs } from '../../../../constants/products/color-pallet-for-products'
import i18n from '../../../../i18n'

export const getLabel = (bar, myKey) => {
  if (bar.indexValue === myKey.indexValue && (!myKey.id || bar.id === myKey.id)) {
    return i18n.t('postulaciones.statistics.me')
  }
  return ''
}

export const getLabelColor = (bar, colors) => {
  if (colors.tertiary.normal === bar.color) {
    return colors.grey100
  }
  return colors.grey0
}

export const getGraphDef = (color, colors) => {
  return [
    {
      id: 'trama',
      type: 'patternLines',
      background: 'inherit',
      color: color[0] === colors.tertiary.normal ? 'white' : colorPalletForPructs.bumeran.infinit,
      rotation: -45,
      lineWidth: 2,
      spacing: 10,
    },
  ]
}

export const getGraphFill = myKey => {
  return [
    {
      match: item => item.key === `${myKey.id}.${myKey.indexValue}`,
      id: 'trama',
    },
  ]
}
