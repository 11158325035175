import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Page = styled.div`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.secondary.normal};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey0};
  margin: 64px auto 16px;
  border-radius: 24px;
  padding: 36px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row;
    margin: 120px auto 64px;
  }
`

export const ImgLogo = styled.img`
  height: 46px;
  align-self: flex-start;
`

export const ImgProduct = styled.img`
  width: 100%;
  max-width: 296px;
  object-fit: cover;
  margin: -80px auto 28px auto;
  border-radius: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    width: 320px;
    height: 320px;
    max-width: none;
    margin: -80px 0 0 0;
  }
`

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding-left: 32px;
  }
`

export const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey84};
  margin-top: 24px;
  margin-bottom: 48px;
`

export const PriceButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CustomButton = styled(Button)`
  min-width: 160px;
  width: fit-content;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    min-width: 200px;
  }

  &:last-child {
    margin-left: 24px;
  }
`

export const ButtonText = styled.span`
  padding: 1px 0px 1px 0px;
`

export const PriceText = styled.text`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey72};
  margin: 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TaxText = styled.p`
  color: ${({ theme }) => theme.colors.grey48};
  margin: 0;
  font-size: 12px;
  line-height: 18px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 14px;
    line-height: 24px;
  }
`
