import { Link } from '@navent-jobs/ui-kit/components/link'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;
  background-color: white;
  border-radius: 8px;

  ${({ mobileView, isFichaAviso, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      padding: ${isFichaAviso ? '12px' : '32px'};
    }
  
    @media (min-width: ${theme.screen.lgMin}) {
      padding: 32px;
      align-items: ${isFichaAviso ? 'left' : 'center'};
      justify-content: ${isFichaAviso ? 'flex-start' : 'center'};
    }
  `}
`

export const ImgCustom = styled.img`
  align-self: center;
  height: 100%;
  max-height: 198px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ mobileView, theme, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      align-items: ${isFichaAviso ? 'center' : 'flex-start'};
      text-align: ${isFichaAviso ? 'center' : 'left'};
    }
  
    @media (min-width: ${theme.screen.lgMin}) {
      align-items: flex-start;
      text-align: left;
    }
  `}
`

export const Title = styled.div`
  margin-bottom: 16px;

  ${({ mobileView, theme, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.lgMin}) {
      margin: ${isFichaAviso ? '0 0 20px 0' : '0 0 16px'};
    }
  `}
`

export const Claim = styled.div`
  margin-bottom: 16px;

  ${({ mobileView, theme, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.lgMin}) {
      margin-bottom: ${isFichaAviso ? '20px' : '16px'};
    }
  `}
`

export const CustomLink = styled(Link)`
  width: fit-content;
  min-width: 170px;
`
