import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphPlaceholder = props => {
  const windowWidth = window.innerWidth < 576
  const widthTop = windowWidth ? '32' : '56'
  const widthBotton = windowWidth ? '24' : '44'

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={240}
      viewBox="0 0 100% 240"
      backgroundcolor="#f3f3f3"
      foregroundcolor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
      <rect x="0" y="44" rx="4" ry="4" width="20" height="20" />
      <rect x="0" y="124" rx="4" ry="4" width="20" height="20" />
      <rect x="0" y="84" rx="4" ry="4" width="20" height="20" />
      <rect x="0" y="164" rx="4" ry="4" width="20" height="20" />
      <rect x="0" y="204" rx="4" ry="4" width="20" height="20" />

      <rect x="9%" y="224" rx="4" ry="4" width={widthTop} height="16" />
      <rect x="10%" y="184" rx="4" ry="4" width={widthBotton} height="32" />
      <rect x="29%" y="224" rx="4" ry="4" width={widthTop} height="16" />
      <rect x="30%" y="152" rx="4" ry="4" width={widthBotton} height="64" />
      <rect x="49%" y="224" rx="4" ry="4" width={widthTop} height="16" />
      <rect x="50%" y="122" rx="4" ry="4" width={widthBotton} height="96" />
      <rect x="69%" y="224" rx="4" ry="4" width={widthTop} height="16" />
      <rect x="70%" y="89" rx="4" ry="4" width={widthBotton} height="128" />
      <rect x="89%" y="223" rx="4" ry="4" width={widthTop} height="16" />
      <rect x="90%" y="57" rx="4" ry="4" width={widthBotton} height="160" />
    </ContentLoader>
  )
}

export default GraphPlaceholder
