export const saveJobPosting = {
  empty: {
    title: 'Todavía no guardaste ningún empleo',
    subtitle: '¡Haz una búsqueda y guarda las ofertas de empleo que más te interesen!',
    buttonText: 'Buscar empleos',
  },
  cards: {
    postulaciones: {
      text: 'Si te postulas a un empleo favorito, vas a poder ver su estado y darle seguimiento desde',
      strongText: 'Mis Postulaciones',
    },
    notEnded: {
      text: 'Es posible que no veas todas las ofertas en Mis empleos favoritos.',
      strongText: 'Solo mostraremos las ofertas que no hayan finalizado.',
    },
  },
}
