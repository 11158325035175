import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholder = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const widthWithMargin = wrapperWidth * 0.95
  const secondColX = widthWithMargin * 0.64
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={116}
        viewBox="0 0 100% 116"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 0 0 h 6 v 116 H 0 z" />
        <rect x="24" y="16" rx="4" ry="4" width="68" height="44" />
        <path d={`M ${widthWithMargin * 0.62} 16 h 1 v 84 h -1 z`} />
        <rect x={widthWithMargin * 0.9 + 32} y="16" rx="4" ry="4" width={widthWithMargin * 0.1} height="20" />
        <rect x="104" y="22" rx="2" ry="2" width={widthWithMargin * 0.5 - 100} height="8" />
        <rect x={secondColX + 28} y="22" rx="2" ry="2" width="174" height="8" />
        <rect x={secondColX} y="16" rx="4" ry="4" width="20" height="20" />
        <rect x={secondColX + 28} y="54" rx="2" ry="2" width="89" height="8" />
        <rect x={secondColX + 28} y="86" rx="2" ry="2" width="132" height="8" />
        <rect x={secondColX} y="48" rx="4" ry="4" width="20" height="20" />
        <rect x={secondColX} y="80" rx="4" ry="4" width="20" height="20" />
        <rect x="104" y="46" rx="2" ry="2" width="157" height="8" />
        <rect x="24" y="69" rx="2" ry="2" width={widthWithMargin * 0.55} height="8" />
        <rect x="24" y="88" rx="2" ry="2" width={widthWithMargin * 0.5} height="8" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholder
