import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { swiperSettings } from './settings'

// swiper styles
import 'swiper/swiper-bundle.min.css'

// components
import CardCompany from '../card-company'

// mixins
import { CarouselContainer, SwiperCustom } from './mixins'

const Carousel = ({ companies, loading, isRecommended }) => {
  // reorder list
  companies.sort((a, b) => {
    return a.position - b.position
  })

  const totalSliders = companies.length

  if (totalSliders === 0) {
    return null
  }

  return (
    <CarouselContainer>
      <SwiperCustom {...swiperSettings(totalSliders)}>
        {companies &&
          companies.map(company => {
            return (
              <SwiperSlide key={`company-${company.companyId}`}>
                <CardCompany company={company} loading={loading} isRecommended={isRecommended} />
              </SwiperSlide>
            )
          })}
      </SwiperCustom>
    </CarouselContainer>
  )
}

export default Carousel
