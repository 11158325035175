import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// style
import { PreviewWrapper, IframeWrapper, Iframe } from './mixins'

// components
import Actions from '../../components/product-landing/components/actions'
import StickyBuyer from '../../components/stickyBuyer'
import MercadoPagoWrapper from '../../components/product-landing/components/mercado-pago-wrapper'

// constants
import { PaymentMPProductSKU } from '../../constants/payment-mp-product-sku'

const Component = ({ productName, history }) => {
  const historyHook = useHistory()
  const url = history?.location?.state?.url
  const productSku = PaymentMPProductSKU[productName]
  const returnPathname = history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))

  useEffect(() => {
    if (!url) {
      historyHook.push(returnPathname)
    }
  }, [url])

  return (
    <>
      <Actions productName={productName} />
      <PreviewWrapper>
        <IframeWrapper>
          <Iframe src={url} title="preview" />
        </IframeWrapper>
      </PreviewWrapper>
      <MercadoPagoWrapper productName={productName}>
        <StickyBuyer productSku={productSku} />
      </MercadoPagoWrapper>
    </>
  )
}

export default Component
