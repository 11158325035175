import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useHistory } from 'react-router-dom'
import { Content, Img, Title, Description, CustomButton } from './mixins'
import emptyImgBm from '../../../../../../assets/images/no-tenes-alertas.svg'
import emptyImgZj from '../../../../../../assets/images/no-tenes-alertas-zj.svg'
import { SITE_ID } from '../../../../../../constants'

const emptyImg = SITE_ID === 'ZJAR' ? emptyImgZj : emptyImgBm

interface EmptyStateProps {
  title: string
  description: string
}

const EmptyState = ({ title, description }: EmptyStateProps) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const history = useHistory()
  return (
    <Content>
      <Img src={emptyImg} width={isMobile ? '200px' : '220px'} height={isMobile ? '176px' : '194px'} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <CustomButton
        type="button"
        onClick={() => {
          history.push('/empleos.html')
        }}
        buttonoptions={{ variant: 'primary', size: 'medium', block: true }}
      >
        {t('alertas.panel.emptyStateCta')}
      </CustomButton>
    </Content>
  )
}

export default EmptyState
