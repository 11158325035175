/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Autocomplete } from '@navent-jobs/ui-kit/components/select'

// components
import OptionLabels from '../components/CompanyOptionLabel'

// hooks
import { getUrlParamByName } from '../../../hooks/get-url-param'
import { shotCompanySearch } from '../searchbarCompanyLogic'
import { DataLayerListadoEmpresas } from '../../../constants/gtm-events-listado-empresas'
import companyList from '../../../services/listado-empresas'
import { SearchCompaniesStack } from '../../../services/search-companies-stack'

// redux
import { getCompanies } from '../../../redux/actions/listado-empresas'

interface CompanySearchBarProps {
  selected: { label: string; value: string } | undefined
  setSelected: Function
  location: any
  placeholder?: string
  before?: ReactElement
  handleSubmit?: Function
  filtersApplied?: any
  showRecentSearch?: boolean
}

const CompanySearchBar = ({
  selected,
  setSelected,
  location,
  placeholder,
  before,
  handleSubmit,
  filtersApplied,
  showRecentSearch = true,
}: CompanySearchBarProps) => {
  const minQueryLength = 3
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const searchHistory = SearchCompaniesStack?.getInstance().getHistory()
  const lastSearches =
    searchHistory
      .filter(s => !!s.keyword)
      .map((s: any) => {
        return { label: s.keyword, value: s.keyword }
      }) ?? []

  const ubicacionParam = getUrlParamByName('ubicacion')
  const ubicacion = ubicacionParam === null ? [] : [Number(ubicacionParam)]
  const industriaParam = getUrlParamByName('industria')
  const industria = industriaParam === null ? [] : [Number(industriaParam)]
  const queryParams = new URLSearchParams(location.search)
  const queryApplied = filtersApplied?.find(f => f.id === 'query')?.value || ''

  const getRecentSearchOption = option => <OptionLabels option={option.value} noBold hideCaret />
  const recentSearchProps =
    showRecentSearch && !selected?.value
      ? {
          defaultOptions: lastSearches,
          formatOptionLabel: getRecentSearchOption,
        }
      : null

  useEffect(() => {
    if (queryApplied) {
      setSelected({ label: queryApplied, value: queryApplied })
    } else {
      setSelected(undefined)
    }
  }, [queryApplied])

  const handleCompanySelection = value => {
    shotCompanySearch(value)
    const valueTrimmed = value?.trim()
    if (valueTrimmed === '') {
      queryParams.delete('query')
    } else {
      queryParams.delete('query')
      queryParams.append('query', valueTrimmed)
    }

    queryParams.delete('page')

    history.push({
      search: queryParams.toString(),
    })

    DataLayerListadoEmpresas.search(valueTrimmed)

    dispatch(
      getCompanies({
        page: 0,
        filtros: {
          query: valueTrimmed,
          industries: industria,
          provinces: ubicacion,
          sortByRating: !queryParams.get('empleos'),
        },
      })
    )
  }

  const handlerGetCompanies = (value: string) => {
    return companyList.getCompaniesSuggestion(value).then(resp => {
      return resp.map(company => {
        return {
          label: company.name,
          value: company.name,
        }
      })
    })
  }

  return (
    <Autocomplete
      name="search-companies"
      asyncOptions={handlerGetCompanies}
      value={selected}
      onChange={(_, company) => {
        if (!company) {
          setSelected(undefined)
        } else {
          const { value } = company
          setSelected({ label: value, value })
          if (handleSubmit) {
            handleSubmit(value)
          } else {
            handleCompanySelection(value)
          }
        }
      }}
      onInputChange={(_, value) => setSelected(value ? { label: value, value } : undefined)}
      placeholder={placeholder || t('searchBarCompanies.placeholder')}
      fieldOptions={{
        variant: 'darken',
        size: 'medium',
        before: before,
      }}
      minQueryLength={minQueryLength}
      isCreatable
      isClearable
      isEditable
      maxMenuHeight={200}
      blurOnSelect
      createLabel="Otra: "
      rules={{ required: true }}
      noOptionsMsg={t('searchBarCompanies.autocomplete', { caracteres: minQueryLength })}
      {...recentSearchProps}
    />
  )
}

const mapStateToProps = ({ listadoEmpresasStore }) => ({
  filtersApplied: listadoEmpresasStore.filtersApplied,
})

export default connect(mapStateToProps)(CompanySearchBar)
