import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from '../../mixins'

const MyLoader = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={145}
        viewBox={`0 0 ${wrapperWidth} 145`}
        backgroundColor="#f3f3f3"
        foregroundColor="#d4d4d4"
        {...props}
      >
        <path d={`M 16 88 h ${wrapperWidth - 32} v 1 H 16 z`} />
        <rect x="16" y="16" rx="8" ry="8" width="90" height="56" />
        <rect x="122" y="22" rx="4" ry="4" width="309" height="16" />
        <rect x="122" y="50" rx="4" ry="4" width="231" height="16" />
        <rect x="48" y="109" rx="4" ry="4" width="220" height="16" />
        <rect
          x={0.242923 * (wrapperWidth - 40)}
          y="109"
          rx="4"
          ry="4"
          width={0.224727 * (wrapperWidth - 40)}
          height="16"
        />
        <rect
          x={(0.242923 + 0.224727) * (wrapperWidth - 40) + 56}
          y="109"
          rx="4"
          ry="4"
          width={0.180404 * (wrapperWidth - 40)}
          height="16"
        />
        <rect x="16" y="105" rx="4" ry="4" width="24" height="24" />
        <rect x={0.21804 * (wrapperWidth - 40)} y="105" rx="4" ry="4" width="24" height="24" />
        <rect x={(0.21804 + 0.224727) * (wrapperWidth - 40) + 24 + 32} y="105" rx="4" ry="4" width="24" height="24" />
        <rect
          x={(0.21804 + 0.224727 + 0.180404 + 0.29) * (wrapperWidth - 40) + 24 + 32 + 24 + 32}
          y="105"
          rx="4"
          ry="4"
          width="24"
          height="24"
        />
        <rect
          x={0.56143 * (wrapperWidth - 40)}
          y="20"
          rx="8"
          ry="8"
          width={0.124416 * (wrapperWidth - 40)}
          height="48"
        />
        <rect
          x={(0.56143 + 0.124416) * (wrapperWidth - 40) + 4}
          y="20"
          rx="8"
          ry="8"
          width={0.129082 * (wrapperWidth - 40)}
          height="48"
        />
        <rect
          x={(0.56143 + 0.124416 + 0.129082) * (wrapperWidth - 40) + 4 + 4}
          y="20"
          rx="8"
          ry="8"
          width={0.19129 * (wrapperWidth - 40)}
          height="48"
        />
      </ContentLoader>
    </LoaderContainer>
  )
}
export default MyLoader
