import { Request, Get, Post } from '@navent-jobs/config'

export class ErrorDenuncia extends Error {}

class DenunciaService {
  public getMotivosDenuncia = async () => {
    const request = new Request()
    request.path = `/api/candidates/aviso/motivosDenuncia`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(() => {
        throw new ErrorDenuncia(`No se pudieron obtener los motivos de denuncia`)
      })
  }

  public denunciar = async payload => {
    const request = new Request()
    request.path = `/api/candidates/aviso/denunciarV2`
    request.method = Post
    request.body = payload
    return request.call().catch(() => {
      throw new ErrorDenuncia(`No se pudo denunciar el aviso`)
    })
  }
}

const denuncia = new DenunciaService()
export default denuncia
