/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useEffect } from 'react'
import { Location } from '../../hooks/locations-by-country'

interface RecentSearches {
  keyword: string
  busqueda: string
  url: string
}

interface SearchBarState {
  locationsList: Location[]
  recentSearches: RecentSearches[]
  RecentSearchesService: []
  keyword: string
  location: Location | undefined
  active: boolean
  field: string
  url: string
}

const initialSearchBarState: SearchBarState = {
  locationsList: [],
  recentSearches: [],
  RecentSearchesService: [],
  keyword: '',
  location: { value: '', label: '' },
  active: false,
  field: '',
  url: '',
}

interface SetRecentSearchesServiceAction {
  type: 'recentsearches-service'
  RecentSearchesService
}

interface SetRecentSearchesAction {
  type: 'recentsearches'
  recentSearches: RecentSearches[]
}

interface SetLocationsListAction {
  type: 'locations'
  locationsList: Location[]
}

interface SetKeywordAction {
  type: 'keyword'
  keyword: string
}

interface SetLocationAction {
  type: 'location'
  location: Location | undefined
}

interface FocusAction {
  type: 'focus'
  field: string
}

interface DoneAction {
  type: 'done'
}

type SearchBarAction =
  | SetRecentSearchesServiceAction
  | SetRecentSearchesAction
  | SetLocationsListAction
  | SetKeywordAction
  | SetLocationAction
  | FocusAction
  | DoneAction

const SearchBarReducer = (state: SearchBarState, action: SearchBarAction): SearchBarState => {
  switch (action.type) {
    case 'recentsearches-service':
      return {
        ...state,
        RecentSearchesService: action.RecentSearchesService,
      }
    case 'recentsearches':
      return {
        ...state,
        recentSearches: action.recentSearches,
      }
    case 'locations':
      return {
        ...state,
        locationsList: action.locationsList,
      }
    case 'keyword':
      return {
        ...state,
        keyword: action.keyword,
      }
    case 'location':
      return {
        ...state,
        location: action.location,
      }
    case 'focus':
      return {
        ...state,
        active: !state.active,
        field: action.field,
      }
    case 'done':
      return {
        ...state,
        active: false,
      }
    default:
      return state
  }
}

export const useSearchBar = (
  availablelocations,
  RecentSearchesService
): [
  boolean,
  (param: string) => void,
  (param: string) => void,
  (param: Location | undefined) => void,
  () => void,
  Location[],
  RecentSearches[],
  Location | undefined,
  string,
  string
] => {
  const [state, dispatch] = useReducer(SearchBarReducer, initialSearchBarState)

  const saveRecentSearches = () => {
    const recentSearches = RecentSearchesService?.getInstance()?.getHistory()
    dispatch({ type: 'recentsearches', recentSearches })
  }

  useEffect(() => {
    dispatch({ type: 'locations', locationsList: availablelocations })
  }, [availablelocations])

  useEffect(() => {
    saveRecentSearches()
  }, [RecentSearchesService, localStorage.getItem('recentSearchs')])

  const executeSearch = (keyword, location) => {
    const filters: any = {}
    if (location?.value !== undefined && location?.value !== '') {
      filters.provincia = location
    }
    if (keyword?.keyword !== undefined && keyword?.keyword.trim() && keyword?.value !== undefined) {
      filters.busqueda = {value: keyword.value, label: keyword.value}
    }

    const redirectURL =
      (filters?.provincia !== undefined || filters?.busqueda !== undefined) &&
      RecentSearchesService?.getInstance()?.pushFilters(filters) || '/empleos.html'

    saveRecentSearches()
    dispatch({ type: 'recentsearches-service', RecentSearchesService })
    // seteamos la keyword para usar en el h1, no lo hacemos en el state pq se refresca la page en el search
    keyword.label && sessionStorage.setItem('search-keyword', keyword.label)

    window.location.href = redirectURL
  }

  return [
    state.active,
    (param) => dispatch({ type: 'focus', field: param }),
    (param) => dispatch({ type: 'keyword', keyword: param }),
    (param) => dispatch({ type: 'location', location: param }),
    () => executeSearch(state.keyword, state.location),
    state.locationsList,
    state.recentSearches,
    state.location,
    state.field,
    state.keyword,
  ]
}
