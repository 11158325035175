import React, { useContext } from 'react'
import { ResponsiveBar, BarSvgProps, TooltipProp } from '@nivo/bar'
import { useTheme } from '@nivo/core'
import { ThemeContext } from 'styled-components'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import VerticalLoader from './loader-vertical'
import HorizontalLoader from './loader-horizontal'

// mixins
import { ForeignObject, Legend } from './mixins'

// utils
import { getLabel, getLabelColor, getGraphDef, getGraphFill } from '../utils'

// constants
import features from '../../../../../constants/features-per-country'
import { SITE_ID } from '../../../../../constants'

const posicionPostulante = features[SITE_ID || 'BMAR']?.estadisticas?.posicionPostulante

const CustomTick = tick => {
  const theme = useTheme()
  const { isMobile, isTabletPortrait } = useWindowSize()
  let xTranslateReduced = isMobile || isTabletPortrait ? 13 : 28

  if (typeof tick.value === 'number') {
    xTranslateReduced = 5
  }

  return (
    <g transform={`translate(${tick.x - xTranslateReduced},${tick.y})`}>
      <ForeignObject
        width={isMobile || isTabletPortrait ? '33px' : '57px'}
        height={isMobile || isTabletPortrait ? '34px' : '25px'}
      >
        <Legend
          style={{
            ...theme.axis.ticks.text,
            color: theme.axis.ticks.text.fill,
          }}
        >
          {tick.value}
        </Legend>
      </ForeignObject>
    </g>
  )
}

const defaultProps: Partial<BarSvgProps> = {
  enableGridX: false,
  enableGridY: true,
  isInteractive: true,
  padding: 0.3,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickRotation: 0,
    renderTick: CustomTick,
  },
  axisLeft: {
    tickValues: 5,
    tickRotation: 0,
  },
  margin: { top: 10, right: 24, bottom: 38, left: 37 },
  theme: {
    background: '#FFFFFF',
    textColor: '#858591',
    fontSize: 12,
    fontFamily: 'Hind Vadodara',
    axis: {
      domain: {
        line: {
          stroke: '#777777',
          strokeWidth: 0,
        },
      },
      ticks: {
        text: {
          lineHeight: '16px',
          display: 'inline-flex',
        },
        line: {
          stroke: '#cecece',
          strokeWidth: 0,
        },
      },
    },
    grid: {
      line: {
        stroke: '#e4e9f2',
        strokeWidth: 1,
      },
    },
    tooltip: {
      container: {
        border: '1px solid #000018D6',
        borderRadius: '4px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
      },
    },
    labels: {
      text: {
        fontWeight: 'bold',
      },
    },
  },
}

interface BarGraphProps extends Partial<Omit<BarSvgProps, 'legends'>> {
  data: []
  loading: boolean
  keys: string[]
  indexBy: string
  layout?: 'vertical' | 'horizontal'
  color: string[]
  hoverColor?: string
  tooltip?: TooltipProp
  legends?
  myKey: {
    id: string
    indexValue: string
  }
}

const Component = ({
  data,
  loading,
  keys,
  indexBy,
  layout,
  color,
  hoverColor,
  tooltip,
  myKey,
  ...otherProps
}: BarGraphProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const showPosition = posicionPostulante && myKey?.indexValue
  const graphDef = getGraphDef(color, colors)
  const graphFill = getGraphFill(myKey)

  if (loading) {
    if (layout === 'horizontal') {
      return <HorizontalLoader />
    }

    return <VerticalLoader />
  }

  return (
    <ResponsiveBar
      {...(tooltip && { tooltip: item => tooltip(item) })}
      {...defaultProps}
      data={data}
      layout={layout || 'vertical'}
      colors={color}
      label={bar => (showPosition ? getLabel(bar, myKey) : '')}
      labelTextColor={bar => getLabelColor(bar, colors)}
      keys={keys}
      indexBy={indexBy}
      indexScale={{ type: 'band', round: true }}
      {...(hoverColor && {
        onMouseEnter: (_data, event) => {
          if (hoverColor) event.currentTarget.style.fill = hoverColor
        },
        onMouseLeave: (_data, event) => {
          if (hoverColor) event.currentTarget.style.fill = color[0]
        },
      })}
      {...(showPosition && { defs: graphDef, fill: graphFill })}
      {...otherProps}
    />
  )
}

export default Component
