import React, { useState } from 'react'

// mixins
import { SearchbarContainer } from './mixins'

const Component = ({ children, setVisibleId }) => {
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(0)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return

    const distance = touchStart - touchEnd
    if (distance > minSwipeDistance) {
      // left
      setVisibleId('tab-empresa')
    }
    if (distance < -minSwipeDistance) {
      // right
      setVisibleId('tab-aviso')
    }
  }

  return (
    <SearchbarContainer onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      {children}
    </SearchbarContainer>
  )
}

export default Component
