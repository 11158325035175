import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

// Avisos:
const Vertical1 = () => (
  <ContentLoader speed={2} width={10} height={123} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <path d="M 0 1 h 6 v 123 H 0 z" />
  </ContentLoader>
)

const AvisoSection1 = () => (
  <ContentLoader speed={2} width="100%" height={80} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <rect x="0" y="1" rx="4" ry="4" width="68" height="44" />
    <rect x="0" y="57" rx="2" ry="2" width="90%" height="8" />
    <rect x="0" y="73" rx="2" ry="2" width="85%" height="8" />
    <rect x="80" y="6" rx="2" ry="2" width="80%" height="8" />
    <rect x="80" y="31" rx="2" ry="2" width="40%" height="8" />
  </ContentLoader>
)

const AvisoSection2 = () => (
  <ContentLoader speed={2} width="100%" height={90} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <path d="M 0 1 h 1 v 84 h -1 z" />
    <rect x="20" y="1" rx="4" ry="4" width="20" height="20" />
    <rect x="20" y="33" rx="4" ry="4" width="20" height="20" />
    <rect x="20" y="65" rx="4" ry="4" width="20" height="20" />
    <rect x="50" y="7" rx="2" ry="2" width="100%" height="8" />
    <rect x="50" y="39" rx="2" ry="2" width="89%" height="8" />
    <rect x="50" y="71" rx="2" ry="2" width="60%" height="8" />
  </ContentLoader>
)

const AvisoSection3 = () => (
  <ContentLoader speed={2} width="100%" height={80} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <rect x="40%" y="60" rx="4" ry="4" width="60%" height="20" />
  </ContentLoader>
)

const AvisoMainWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`
const VerticalWrapper = styled.div`
  position: absolute;
  top: 0;
  botom: 0;
  left: 10;
`
const AvisoSection1Wrapper = styled.div`
  display: flex;
  width: 60%;
`
const AvisoSection2Wrapper = styled.div`
  display: flex;
  width: 25%;
`
const AvisoSection3Wrapper = styled.div`
  display: flex;
  width: 15%;
`
const WrapperAviso = styled.div`
  padding: 16px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const SkeletonAviso = () => {
  return (
    <AvisoMainWrapper>
      <VerticalWrapper>
        <Vertical1 />
      </VerticalWrapper>
      <WrapperAviso>
        <AvisoSection1Wrapper>
          <AvisoSection1 />
        </AvisoSection1Wrapper>
        <AvisoSection2Wrapper>
          <AvisoSection2 />
        </AvisoSection2Wrapper>
        <AvisoSection3Wrapper>
          <AvisoSection3 />
        </AvisoSection3Wrapper>
      </WrapperAviso>
    </AvisoMainWrapper>
  )
}

const PublicationPlaceholder = () => {
  return (
    <>
      <SkeletonAviso />
      <SkeletonAviso />
      <SkeletonAviso />
      <SkeletonAviso />
      <SkeletonAviso />
    </>
  )
}

export default PublicationPlaceholder
