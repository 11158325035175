/* eslint-disable max-len */
// ARGENTINA
export const seoWordings = {
  // 36
  root: {
    title: `Empleos en {{countryName}} | Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo en {{countryName}}, incluyendo empleos en {{filtersToApplyByKey.provincia.facets.0.name}} y en {{filtersToApplyByKey.provincia.facets.1.name}}. ¡Postulate en {{siteName}} {{countryName}}!`,
    h1: `ofertas de empleo en {{countryName}}`,
  },
  // 2
  home: {
    title: `Empleos - Bolsa de trabajo | {{siteName}}`,
    description: `La bolsa de trabajo más grande de {{countryName}}. Buscá empleos y ofertas de trabajo ideales para vos ¡Encontrá tu próximo empleo en {{siteName}}!`,
    h1: `Empleos en {{countryName}}`,
  },
  // 3
  area: {
    title: `Empleos de {{filtersByKey.area.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperándote en {{siteName}} {{countryName}}. También tenemos ofertas de trabajo en {{filtersToApplyByKey.subarea.facets.0.name}}. ¡Encontrá tu empleo!`,
    h1: `empleos en {{filtersByKey.area.name}}`,
  },
  // 4
  subarea: {
    title: `Trabajo de {{filtersByKey.subarea.name}} - Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscás trabajo en {{filtersByKey.subarea.name}}? En Bumeran contamos con más de {{totalSearched}} vacantes de trabajo. Tenemos el empleo que estás buscando!`,
    h1: `ofertas de trabajo de {{filtersByKey.subarea.name}}`,
  },
  // 5
  nivel_laboral: {
    title: `Bolsa de trabajo - {{filtersByKey.nivel_laboral.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Estás buscando empleo de {{filtersByKey.nivel_laboral.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} en {{countryName}}`,
  },
  // 6
  tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.tipo_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}}`,
  },
  // 7
  dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} - Empleos - Página {{pageNumber}} | {{siteName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{siteName}}. Conseguí empleo de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.nivel_laboral.facets.0.name}} en la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}}`,
  },
  // 8
  apto_discapacitado: {
    title: `Ofertas de trabajo con discapacidad - Página {{pageNumber}} | {{siteName}} {{countryName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo para Postulantes con Discapacidad. ¡Encontrá tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para Postulantes con Discapacidad`,
  },
  // 9
  query: {
    title: `Trabajo de {{filtersByKey.query.name}} | Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} vacantes de trabajo para "{{filtersByKey.query.name}}" en {{siteName}} {{countryName}}. Revisá todas las ofertas y ¡Encontrá tu próximo empleo!`,
    h1: `ofertas de trabajo para "{{filtersByKey.query.name}}"`,
  },
  // 10
  ficha_aviso: {
    title: `{{title}} - {{mes}} {{anio}} | {{siteName}}`,
    description: `Postulate en la oferta de {{title}}{{company}} y conseguí tu empleo ideal. Conocé el sueldo promedio de este puesto y postulate en {{siteName}} {{countryName}}.`,
    h1: `{{title}}`,
  },

  // 12
  provincia: {
    title: `Empleos en {{filtersByKey.provincia.name}} | Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo en {{filtersByKey.provincia.name}}, incluyendo empleos en {{filtersToApplyByKey.localidad.facets.0.name}} y en {{filtersToApplyByKey.localidad.facets.1.name}}. ¡Postulate en {{siteName}} {{countryName}}!`,
    h1: `ofertas de empleo en {{filtersByKey.provincia.name}}`,
  },
  // 13
  localidad: {
    title: `Empleos en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} | Ofertas de Trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscás empleo en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}? Tenemos {{totalSearched}} ofertas de empleo en {{siteName}} para vos, incluyendo ofertas en {{filtersToApplyByKey.area.facets.0.name}}.`,
    h1: `ofertas de empleo en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 14
  provincia_area: {
    title: `Empleos de {{filtersByKey.area.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperándote en {{filtersByKey.provincia.name}}. Hay ofertas en {{filtersToApplyByKey.subarea.facets.0.name}} y en {{filtersToApplyByKey.subarea.facets.1.name}}. ¡Encontrá tu empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos de {{filtersByKey.area.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 15
  provincia_subarea: {
    title: `Trabajo de {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscás trabajos de {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}}? En {{siteName}} contamos con más de {{totalSearched}} vacantes de trabajo que estás buscando. ¡Postulate Ya!`,
    h1: `ofertas de trabajo de {{filtersByKey.subarea.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 16
  provincia_nivel_laboral: {
    title: `Bolsa de trabajo - {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Estás buscando empleo {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.provincia.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} encontrados en {{filtersByKey.provincia.name}}`,
  },
  // 17
  provincia_dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.provincia.name}}. Conseguí empleo en {{filtersToApplyByKey.area.facets.0.name}} y en {{filtersToApplyByKey.area.facets.1.name}} en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}} encontrados en {{filtersByKey.provincia.name}}`,
  },
  // 18
  provincia_tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.provincia.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo de {{filtersByKey.tipo_trabajo.name}} están esperándote en {{filtersByKey.provincia.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.provincia.name}}`,
  },
  // 19
  provincia_apto_discapacitado: {
    title: `Empleos para postulantes con discapacidad en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo para Postulantes con Discapacidad en {{filtersByKey.provincia.name}}. También contamos con trabajos de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.tipo_trabajo.facets.1.name}}. ¡Encontrá tu próximo empleo en {{siteName}} México!`,
    h1: `empleos para Postulantes con Discapacidad en {{filtersByKey.provincia.name}}`,
  },
  // 20
  provincia_query: {
    title: `{{filtersByKey.query.name}}: Empleos en {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} vacantes de trabajo para la búsqueda: "{{filtersByKey.query.name}}" en {{filtersByKey.provincia.name}}. ¡Encontrá tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para la búsqueda "{{filtersByKey.query.name}}" en {{filtersByKey.provincia.name}}`,
  },
  // 21
  localidad_area: {
    title: `Empleos de {{filtersByKey.area.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} trabajos de {{filtersByKey.area.name}} están esperándote en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. Hay ofertas en {{filtersToApplyByKey.subarea.facets.0.name}} y en {{filtersToApplyByKey.subarea.facets.1.name}}. ¡Encontrá tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos en {{filtersByKey.area.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 22
  localidad_subarea: {
    title: `Trabajo de {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `¿Buscás trabajos en {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}? En {{siteName}} contamos con más de {{totalSearched}} vacantes de trabajo para lo que buscás. ¿Qué esperás para entrar y postularte? ¡Empezá a trabajar ya!.`,
    h1: `ofertas de trabajo de {{filtersByKey.subarea.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 23
  localidad_nivel_laboral: {
    title: `Bolsa de trabajo - {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Estás buscando empleo de {{filtersByKey.nivel_laboral.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} y nosotros tenemos {{totalSearched}} ofertas de trabajo para ofrecerte. Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.nivel_laboral.name}} encontrados en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 24
  localidad_dias_fecha_publicacion: {
    title: `Ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Hay {{totalSearched}} ofertas de trabajo {{filtersByKey.dias_fecha_publicacion.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. Conseguí empleo en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}.`,
    h1: `empleos {{filtersByKey.dias_fecha_publicacion.name}} encontrados en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 25
  localidad_tipo_trabajo: {
    title: `Empleos: {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.tipo_trabajo.name}} están esperándote en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.tipo_trabajo.name}} en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 26
  localidad_apto_discapacitado: {
    title: `Empleos para postulantes con discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} ofertas de trabajo para Postulantes con Discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. También contamos con trabajos de {{filtersToApplyByKey.tipo_trabajo.facets.0.name}} y de {{filtersToApplyByKey.tipo_trabajo.facets.1.name}}. ¡Encontrá tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para Postulantes con Discapacidad en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 27
  localidad_query: {
    title: `{{filtersByKey.query.name}}: Empleos en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}} - Página {{pageNumber}} | {{siteName}}`,
    description: `Tenemos {{totalSearched}} vacantes de trabajo para la búsqueda "{{filtersByKey.query.name}}" en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}. Revisá en todas las ofertas que tenemos y...¡Encontrá tu próximo empleo en {{siteName}} {{countryName}}!`,
    h1: `empleos para la búsqueda "{{filtersByKey.query.name}}" en {{filtersByKey.localidad.name}}, {{filtersByKey.provincia.name}}`,
  },
  // 28
  modalidad_trabajo: {
    title: `Empleos: {{filtersByKey.modalidad_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.modalidad_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos {{filtersByKey.modalidad_trabajo.name}}`,
  },
  // 29
  internacional: {
    title: `Empleos: {{filtersByKey.modalidad_trabajo.name}} - Bolsa de trabajo - Página {{pageNumber}} | {{siteName}}`,
    description: `{{totalSearched}} ofertas de trabajo {{filtersByKey.modalidad_trabajo.name}} están esperándote, incluyendo ofertas en {{filtersToApplyByKey.provincia.facets.0.name}}. ¡Postulate en {{siteName}}, la bolsa de trabajo más grande de {{countryName}}!`,
    h1: `empleos esparcidos por el mundo`,
  },

  default: {
    title: `Empleos en {{variablesOrdenadasPorPrioridad}} - Página {{pageNumber}} | {{siteName}}`,
    description:
      '¿Buscás trabajo? Tenemos más de {{totalSearched}} ofertas de trabajo para vos en {{siteName}} {{countryName}}. Encontrá los mejores empleos ya!',
    h1: 'empleos para {{variablesOrdenadasPorPrioridad}}',
  },
}
