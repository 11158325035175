import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholderMobile = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const { withDescription } = props
  const height = withDescription ? 245 : 160

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth * 0.93}
        height={height}
        viewBox={`0 0 ${wrapperWidth} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="16" y="16" rx="4" ry="4" width="68" height="44" />
        <rect x="92" y="30" rx="4" ry="4" width="166" height="16" />
        <rect x="16" y="72" rx="4" ry="4" width="20" height="20" />
        <rect x="16" y="100" rx="4" ry="4" width="20" height="20" />
        <rect x="16" y="128" rx="4" ry="4" width="20" height="20" />
        <rect x="44" y="74" rx="4" ry="4" width="152" height="16" />
        <rect x="44" y="102" rx="4" ry="4" width="152" height="16" />
        <rect x="44" y="130" rx="4" ry="4" width="152" height="16" />
        {withDescription && (
          <>
            <rect x="16" y="160" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="182" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="204" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="226" rx="2" ry="2" width="137" height="12" />
          </>
        )}
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholderMobile
