export const productLanding = {
  nulinga: {
    home: {
      title: 'Valida tu nivel de Inglés con Nulinga y resalta tu perfil',
      description:
        'Completa el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
      secondaryButton: 'Conocé más',
      primaryButtonText: 'Hacer el test',
    },
    because: {
      title: '¿Por qué hacer el test?',
      answer1: {
        answer: 'Te permitirá conocer tu nivel en el idioma para aplicar a las vacantes que se adapten a tu perfil.',
      },
      answer2: {
        answer:
          'Podrás mostrar tu nivel del idioma a las empresas para destacar tu postulación en las búsquedas de Bumeran si así lo deseas.',
      },
      answer3: {
        answer:
          'Resaltarás tu perfil al contar con la validación de Nulinga en tu CV y aumentarás las chances de que las empresas te contacten.',
      },
    },
    description: {
      titleFirstPart: '¿Cómo es el test de ',
      titleSecondPart: '?',
      firstRow: {
        firstColumn: {
          title: 'Rápido',
          description:
            'El test es un cuestionario con preguntas de selección múltiple que te llevará menos de 20 minutos.',
        },
        secondColumn: {
          title: 'Confiable',
          description:
            'Los profesionales de Nulinga analizarán tu resultado y lo obtendrás en 48 horas en la sección de idiomas de tu CV de Bumeran.',
        },
        thirdColumn: {
          title: 'Accesible',
          description:
            'Puedes hacer el test por un costo accesible y realizar el pago fácilmente a través de Mercado pago.',
        },
      },
    },
    product: {
      description:
        'Completa el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
      buttonText: 'Hacer el test',
    },
  },
  miguru: {
    home: {
      title: 'Mejora tu {{cv}} y consigue más entrevistas',
      description:
        'Utilizamos tecnología avanzada con inteligencia artificial para recomendarte mejoras sobre tu {{cv}} y optimizar tu perfil.',
      secondaryButtonText: 'Ver resultado preliminar',
      primaryButtonText: 'Analiza tu {{cv}}',
    },
    because: {
      title: '¿Qué es y por qué usarlo?',
      description:
        'MiGuru es una herramienta que lee y analiza tu currículum en pocos segundos utilizando inteligencia artificial, entregándote un informe completo con recomendaciones personalizadas para que puedas mejorarlo.',
      answerTitle: 'Obtener este informe te permitirá:',
      answer1: {
        answer: 'Conocer los aspectos mejorables de tu {{cv}}.',
      },
      answer2: {
        answer: 'Obtener sugerencias para corregir sus debilidades.',
      },
      answer3: {
        answer: 'Lograr que tu {{cv}} destaque, aumentando tus posibilidades de una entrevista.',
      },
    },
    highlight: {
      textA: 'Puedes ',
      bold: 'ver un resultado preliminar ',
      textB:
        'de cómo va tu CV. Para ver las sugerencias de mejoras detalladas, te recomendamos comprar el informe completo.',
      buttonText: 'Ver resultado preliminar',
    },
    description: {
      titleFirstPart: '¿Cómo usar ',
      titleSecondPart: '?',
      firstRow: {
        firstColumn: {
          title: 'Completa tu {{cv}}',
          description: 'Completa tu Currículum en Laborum. Cuanto más completo esté, mejor va a ser el resultado.',
        },
        secondColumn: {
          title: 'Ingresa a MiGuru',
          description: 'Desde tu {{cv}}, ingresa a MiGuru y realiza el pago a través de Mercado Pago.',
        },
        thirdColumn: {
          title: '¡Y listo!',
          description: 'Descarga tu informe y úsalo para mejorar tu {{cv}}.',
        },
      },
    },
    data: {
      titleFirstPart: '¿En qué consiste el informe de ',
      titleSecondPart: '?',
      description:
        'El informe se divide en diferentes secciones, las cuales explican en detalle qué es lo que se mide, lo que pudimos analizar de tu {{cv}} y las recomendaciones de cómo mejorarlo:',
      answerTitle: 'Obtener este informe te permitirá:',
      answer1: {
        title: 'Estructura:',
        answer: 'Revisamos que tengas las secciones fundamentales con las que debe contar un {{cv}} y sea consistente.',
      },
      answer2: {
        title: 'Calidad:',
        answer: 'Revisamos que no cometas ningún error ortográfico, que no uses palabras repetidas, entre otros.',
      },
      answer3: {
        title: 'Experiencia:',
        answer:
          'Validamos que tus experiencias sean relevantes y recientes, que tengan una calidad de escritura adecuada, entre otros.',
      },
      infoText: 'Puedes ver un resultado preliminar de cómo viene tu CV haciendo ',
      infoTextLink: 'click aquí',
      infoTextSecondPart:
        ', y para ver las sugerencias de mejoras detalladas, te recomendamos comprar el informe completo.',
    },
    product: {
      description: 'Mejora tu {{cv}} y consigue más entrevistas.',
      buttonText: 'Analiza tu {{cv}}',
    },
    popover: {
      title: '¡Aprovecha el 20% OFF!',
      description: 'A partir de tu segunda compra para comprobar las mejoras a tu {{cv}}.',
    },
  },
  doctorcv: {
    home: {
      title: 'Obtén un {{cv}} GANADOR',
      preTitle: 'Destácate del resto.',
      description: 'Descubre los errores de tu CV y corrígelos antes de postularte. No pierdas oportunidades.',
      secondaryButtonText: 'Conoce más',
      primaryButtonText: 'Revisa tu {{cv}}',
    },
    because: {
      title: '¿Qué es Doctor CV?',
      description:
        'Doctor CV es una herramienta que evalúa tu currículum de forma automática, brindándote un informe detallado para que puedas mejorarlo.',
      answerTitle:
        'Sabemos que el 80% de los Currículum Vitae es descartado por errores básicos en su redacción, pero gracias al diagnóstico de Doctor CV:',
      answer1: {
        answer: 'Conocerás las técnicas más modernas en la redacción de {{cv}}.',
      },
      answer2: {
        answer:
          'Descubrirás los errores que estás cometiendo en tu Curriculum Vitae y te mostraremos cómo corregirlos.',
      },
      answer3: {
        answer: 'Conseguirás un documento de alto impacto que te distinguirá del resto de postulantes.',
      },
    },
    description: {
      titleFirstPart: '¿Cómo es el diagnóstico ',
      titleSecondPart: '?',
      firstRow: {
        firstColumn: {
          title: 'Inmediato',
          description:
            'En cuestión de segundos revisamos tu {{cv}} y obtendrás un informe detallado con recomendaciones.',
        },
        secondColumn: {
          title: 'Personalizado',
          description:
            'Te daremos recomendaciones totalmente personalizadas sobre el contenido analizado para que puedas mejorarlo.',
        },
        thirdColumn: {
          title: 'Accesible',
          description:
            'Puedes hacer el diagnóstico por un costo accesible y realizar el pago fácilmente a través de Mercado pago.',
        },
      },
    },
    data: {
      titleFirstPart: 'Nuestro diagnóstico',
      description: 'En el informe de tu {{cv}} encontrarás:',
      answer1: {
        answer:
          'Una revisión sobre la estructura y elementos que se espera encontrar en el documento ya que le permite a un reclutador encontrar la información con facilidad.',
      },
      answer2: {
        answer:
          'Un diagnóstico sobre el impacto y la relevancia de tus experiencias laborales, consejos específicos para redactarlas y no pasar desapercibido.',
      },
      answer3: {
        answer:
          'Recomendaciones sobre tus principales habilidades junto con un exclusivo asistente de redacción de logros laborales para saber resaltarlos en tu {{cv}}.',
      },
      infoText: 'Tu {{cv}} recibirá una nota de calificación general. Te recomendamos',
      infoTextBold: ' volver a hacer el Diagnóstico ',
      infoTextSecondPart: 'hasta que alcances un valor aprobatorio de, por lo menos, 75%.',
    },
    product: {
      description: 'Mejora tus posibilidades de conseguir tu próxima entrevista laboral.',
      buttonText: 'Revisa tu {{cv}}',
    },
  },
  pda: {
    home: {
      title: 'Resalta tus talentos frente a las empresas con PDA',
      description: 'Realiza el Test de PDA y conoce tus fortalezas para resaltar tu {{cv}} al postularte.',
      secondaryButton: 'Conoce más',
      primaryButtonText: 'Comprar informe completo',
    },
    because: {
      title: '¿Qué es PDA Assessment?',
      description:
        'PDA es una evaluación psicométrica en línea que analiza y describe el perfil conductual de las personas entregandote un reporte detallado que te permitirá:',
      answer1: {
        answer: 'Conocer tus fortalezas y habilidades para resaltarlas en tu {{cv}}.',
      },
      answer2: {
        answer: 'Identificar los puestos que más se adaptan a tu perfil.',
      },
      answer3: {
        answer: 'Trabajar sobre áreas de oportunidad para potenciar tus habilidades.',
      },
    },
    description: {
      titleFirstPart: '¿Por qué hacer el test de PDA?',
      firstRow: {
        firstColumn: {
          title: 'Confiable y válido',
          description: 'Está validado científicamente con más de un 90% de confiabilidad.',
        },
        secondColumn: {
          title: 'Ágil e intuitivo',
          description:
            'En tan solo 10 minutos se completa el test y se obtienen los resultados fáciles de interpretar.',
        },
        thirdColumn: {
          title: 'Accesible',
          description:
            'Puedes hacer el test gratis y luego acceder al informe completo comprando a traves de Mercado pago.',
        },
      },
    },
    data: {
      titleFirstPart: '¿Cómo es el test de PDA Assessment?',
      description:
        'Al realizar el test te entregamos un resultado preliminar sobre tu perfil y luego tienes la oportunidad de comprar el reporte detallado en donde encontrarás:',
      answer1: {
        answer: 'Una descripción exhaustiva de los aspectos de tu personalidad que puedes resaltar en tu empleo.',
      },
      answer2: {
        answer: 'Recomendaciones personalizadas para que puedas desplegar todo tu potencial.',
      },
      answer3: {
        answer: 'Una evaluación sobre tu perfil en los aspectos más solicitados en el ámbito laboral.',
      },
    },
    product: {
      description: 'Resalta tus fortalezas para encontrar el empleo que deseas.',
      buttonText: 'Comprar informe completo',
    },
  },
  nulingaCourse: {
    home: {
      title: 'Mejora tu nivel de inglés 100% online',
      description:
        'Eleva a una próxima escala tus conocimientos aprendiendo inglés con clases personalizadas en vivo, 100% online, y valores preferenciales.',
      primaryButtonText: 'Registrarme ahora',
    },
    benefits: {
      titleFirstPart: 'Descubre los beneficios de cursar con ',
      titleSecondPart: ' y optimiza tu visibilidad laboral',
      row1: {
        firstColumn: {
          title: 'Docentes particulares',
          description:
            'Aprende a través de clases remotas en vivo, con una persona docente que te acompañará y guiará durante todo tu cursado.',
        },
        secondColumn: {
          title: 'Clases individuales o grupos reducidos',
          description:
            'Cursa con un plan individual -regular o intensivo- o grupal, con un máx. de 4 estudiantes por aula.',
        },
      },
      row2: {
        firstColumn: {
          title: 'Contenido exclusivo y actualizado',
          description:
            'Aprende idiomas mediante un plan de estudios diseñado para satisfacer tus necesidades: contenido multimedia, relevante y orientado a negocios.',
        },
        secondColumn: {
          title: 'Fácil gestión',
          description:
            'Gestiona todo lo que necesitas desde la plataforma: accede a tus clases y al material de estudio, cancela y reprogramá clases, solicitá cambios en tu cursada, y administra tu suscripción.',
        },
      },
    },
    because: {
      titleFirstPart: '¿Cómo es tomar clases con ',
      titleSecondPart: ' ?',
      answer1: {
        title: 'Vives un encuentro exclusivo: ',
        answer:
          'Todas las clases comienzan con un espacio para que despejes tus dudas y converses sobre los temas que más te interesan.',
      },
      answer2: {
        title: 'Aprendes con material multimedia: ',
        answer:
          'El material cuenta con variedad de imágenes, videos, audios y textos, para que estudiar un idioma sea más divertido y eficiente.',
      },
      answer3: {
        title: 'Todo tu contenido dentro de la plataforma: ',
        answer:
          'Material de clase y ejercitación 100% online. ¡No necesitarás usar cuadernos ni descargar archivos! Responde, comenta y resalta en tu libro digital.',
      },
    },
    data: {
      titleFirstPart: '¿Cómo está organizado el plan de estudios?',
      translationText:
        '<0>Nulinga</0> cuenta con <0>6 niveles de estudio</0>, más 1 nivel Pre Inicial en caso de ser necesario, basados en el <0>Marco Común Europeo de Referencia (MCER)</0>.',
      answerTitle: 'Cada nivel está compuesto por 3 módulos y cada módulo por 16 lecciones.',
      infoText: 'A partir de una breve nivelación inicial te asignaremos el nivel por el que comenzarás a estudiar.',
    },
    time: {
      title: '¿Cuánto tiempo lleva completar cada módulo y nivel de estudio?',
      regularMode: 'Modo Regular',
      intensiveMode: 'Modo Intensivo',
      subtitle: 'La finalización exitosa de un nivel completo de inglés dependerá de:',
      infoText1: 'Tu nivel de regularidad de asistencias',
      infoText2: 'Cumplimiento de tareas y material asignado de estudio',
      infoText3: 'Aprobación de las evaluaciones planteadas durante el cursado',
    },
    share: {
      title: '¡Comparte tus logros!',
      description:
        'Al finalizar exitosamente tu nivel de aprendizaje, tendrás disponible un certificado emitido por Nulinga para compartir en tus redes y sumar a tu {{cv}}.',
    },
    description: {
      title: 'Planes Disponibles para Aprender Inglés',
      firstRow: {
        firstColumn: {
          title: 'Grupal',
          description1: '1 hora de clase por semana',
          description2: '1 hora de ejercicios en la plataforma',
          description3: 'Hasta 4 estudiantes por grupo',
        },
        secondColumn: {
          title: 'Individual',
          description1: '1 hora de clase por semana',
          description2: '1 hora de ejercicios en la plataforma',
          description3: 'Clase privada (1:1)',
        },
        thirdColumn: {
          title: 'Intensivo',
          description1: '2 horas de clase por semana',
          description2: '2 horas de ejercicios en la plataforma',
          description3: 'Clase privada (1:1)',
        },
      },
    },
    product: {
      description:
        'Regístrate ahora y un profesional de Nulinga se contactará contigo para avanzar con la inscripción.',
      buttonText: 'Registrarme ahora',
    },
    contact: {
      titleFirstPart: '¿Quieres empezar a cursar pero todavía tienes preguntas?',
      answer1: {
        title: 'Línea de WhatsApp: ',
        answerLink: 'Escríbenos a través de WhatsApp',
        answerText: ' para conversar con nuestros asesores en línea.',
      },
      answer2: {
        title: 'Soporte online: ',
        answerText1: 'Ingresa a ',
        answerLink: 'nulinga.com',
        answerText2: ' para consultarnos tus dudas a través de nuestro chat, o escribenos por mail a info@nulinga.com.',
      },
    },
  },
  salario: {
    home: {
      title: 'Sueldo neto y sueldo bruto: ¿Conocés sus diferencias?',
    },
    description: {
      title: '¿Bruto o neto?',
      information:
        'Es importante conocer la diferencia entre los términos neto y bruto a la hora de empezar a trabajar. Al momento de la contratación, las empresas suelen expresar el sueldo en términos brutos. Por este motivo, es necesario conocer cuales son las deducciones a las que se está sujeto por ley, para así tener en claro la cantidad de dinero en mano que se percibirá cada mes.',
      rows: {
        firstRow: {
          firstColumn: {
            title: 'Sueldo bruto',
            description:
              'Es la cantidad total de dinero que se paga sin tener en cuenta las deducciones generalizadas fijadas por ley.',
          },
          secondColumn: {
            title: 'Sueldo neto',
            description:
              'Es el dinero que se deposita en la cuenta a fin de mes una vez aplicadas las deducciones de ley, aquel que el trabajador realmente percibe.',
          },
        },
      },
    },
    because: {
      title: 'Deducciones',
      text: '<0>Las retenciones al sueldo bruto, denominadas deducciones de haberes o deducciones de ley, son las siguientes:</0>',
      answers: {
        title1: 'Jubilación:',
        answer1:
          'Se deduce un 11% del sueldo como aporte jubilatorio. Es una deducción destinada a generar un fondo de ahorro para cuando decidas jubilarte. El dinero es retenido por el Sistema Integrado Previsional Argentino (SIPA) que es el sistema jubilatorio que rige actualmente en Argentina.',
        title2: 'Obra Social:',
        answer2:
          'Se descuenta un 3% en concepto del descuento que se realiza por servicios de salud, para que puedas tener acceso a asistencia médica en caso de accidente o enfermedad. La empresa lo retiene y, luego, deposita este monto en la AFIP que destina un porcentaje de este a la Administración Nacional del Seguro de Salud.',
        title3: 'Descuento INSSJP:',
        answer3:
          'Se trata de una deducción del 3% destinada al Instituto Nacional de Seguridad Social para Jubilados y Pensionados (INSSJP), más conocido como PAMI. Provee atención médica integral a adultos mayores.',
        title4: 'Cuota sindical:',
        answer4:
          'Dependiendo de si la empresa está sujeta a un convenio o el trabajador a un sindicato, se deduce entre un 2% y un 4% que se destinan a cada agrupación sindical o gremio. No es obligatoria.',
      },
    },
  },
}
