import i18n from 'i18next'
import { URLData } from '@navent-jobs/interlinking'

/**
 * Convierte un string dado a una forma 'Title Case'
 * @example
 * toTitleCase('hOla QUE Tal'); // returns "Hola Que Tal"
 */
const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      if (word.length > 0) {
        return word.replace(word[0], word[0].toUpperCase())
      }
      return word
    })
    .join(' ')
}

const getDiasPublicacionFromString = (stringDays: string): number => {
  const stringLow = stringDays
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  const re = /\d+/g
  const match = stringLow.match(re)
  let daysNumber
  if (match) {
    daysNumber = parseInt(match[0], 10)
  }
  if (!daysNumber && stringLow.search('ayer') !== -1) {
    daysNumber = 1
  }
  if (!daysNumber && stringLow.search('hoy') !== -1) {
    daysNumber = 0
  }
  if (!daysNumber && stringLow.search('ultimo') !== -1) {
    daysNumber = 1
  }
  if (!daysNumber && stringLow.search('ultima') !== -1) {
    daysNumber = 1
  }
  if (!daysNumber && daysNumber !== 0) {
    daysNumber = -1
  }
  if (stringLow.search('dia') !== -1) {
    return daysNumber
  }
  if (stringLow.search('mes') !== -1) {
    return daysNumber * 30
  }
  if (stringLow.search('semana') !== -1) {
    return daysNumber * 7
  }
  return daysNumber
}

const makeProvincia = (provincia?: string): string => {
  if (!provincia) return ''
  return `en ${provincia}`
}

const makeLocalidad = (provincia?: string, localidad?: string): string => {
  if (!provincia || !localidad) return ''
  return `en ${localidad}, ${provincia}`
}

const makeSubarea = (subarea?: string): string => {
  if (!subarea) return ''
  return `de ${subarea}`
}

const makeArea = (area?: string): string => {
  if (!area) return ''
  return `de ${area}`
}

const makeTipoTrabajo = (tipoTrabajo?: string): string => {
  if (!tipoTrabajo) return ''
  return `${tipoTrabajo}`
}

const makeFechaPublicacion = (fechaPublicacion?: string): string => {
  if (!fechaPublicacion) return ''

  const fechaPublicacionNumero = getDiasPublicacionFromString(fechaPublicacion)
  if (fechaPublicacionNumero === -1) return ''

  if (fechaPublicacionNumero === 0) {
    return `publicados hoy`
  }
  if (fechaPublicacionNumero === 1) {
    return `publicados desde ayer`
  }
  return `publicados hace ${fechaPublicacionNumero} días`
}

const makeNivelLaboral = (nivelLaboral?: string): string => {
  if (!nivelLaboral) return ''
  return `${nivelLaboral.replace(/\//g, 'o')}`
}

const makeModalidadTrabajo = (modalidadTrabajo?: string): string => {
  if (!modalidadTrabajo) return ''
  return `${modalidadTrabajo}`
}

const makeBusqueda = (busqueda?: string): string => {
  if (!busqueda) return ''
  return `de ${toTitleCase(busqueda.replace(/-/g, ' '))}`
}

export const makeTitleListado = (filtros: URLData): string => {
  // Casos muy especificos
  if (Object.keys(filtros).length === 0) {
    return i18n.t(`Listado Pais Title`)
  }
  if (filtros.area && Object.keys(filtros).length === 1) {
    return i18n.t(`Listado Area Title`, { area: filtros.area })
  }

  // Primera parte del titulo (antes del guión)
  const INICIO1 = 'Trabajos'

  const firstPart = `${INICIO1}
    ${makeBusqueda(filtros.busqueda)}
    ${makeNivelLaboral(filtros.nivel_laboral)}
    ${makeFechaPublicacion(filtros.dias_fecha_publicacion)}
    ${makeTipoTrabajo(filtros.tipo_trabajo)}
    ${makeModalidadTrabajo(filtros.modalidad_trabajo)}
    ${filtros.subarea ? makeSubarea(filtros.subarea) : makeArea(filtros.area)}
    ${filtros.localidad ? makeLocalidad(filtros.provincia, filtros.localidad) : makeProvincia(filtros.provincia)}`

  // Segunda parte del titulo (después del guión)
  const INICIO2 = 'Empleos'
  const FIN = i18n.t('Listado Title Sufijo')

  const secondPart = `${INICIO2}
    ${makeFechaPublicacion(filtros.dias_fecha_publicacion)}
    ${makeTipoTrabajo(filtros.tipo_trabajo)}
    ${filtros.subarea ? makeSubarea(filtros.subarea) : makeArea(filtros.area)}
    ${FIN}`

  const title = `${firstPart} - ${secondPart}`
  const finalTitle = title.replace(/\s+/g, ' ') // Elimino espacios extras
  return finalTitle
}

const makeSubareaDescription = (subarea?: string): string => {
  if (!subarea) return ''
  return `${subarea}`
}

const makeAreaDescription = (area?: string): string => {
  if (!area) return ''
  return `${area}`
}

const makeProvinciaDescription = (provincia?: string): string => {
  if (!provincia) return ''
  return `${provincia}`
}

const makeLocalidadDescription = (provincia?: string, localidad?: string): string => {
  if (!provincia || !localidad) return ''
  return `${localidad}, ${provincia}`
}

const makeBusquedaDescription = (busqueda?: string): string => {
  if (!busqueda) return ''
  return `${toTitleCase(busqueda.replace(/-/g, ' '))}`
}

export const makeMetaDescriptionListado = (filtros: URLData, cantidadAvisos: number): string => {
  const filtroConstantes = {
    BUSQUEDA: 'busqueda',
    PROVINCIA: 'provincia',
    AREA: 'area',
    SUBAREA: 'subarea',
    TIPO_TRABAJO: 'tipo_trabajo',
    MODALIDAD_TRABAJO: 'modalidad_trabajo',
  }

  const tags = Object.keys(filtros)
  let variante
  if (
    tags.includes(filtroConstantes.BUSQUEDA) &&
    tags.includes(filtroConstantes.PROVINCIA) &&
    tags.includes(filtroConstantes.TIPO_TRABAJO) &&
    tags.includes(filtroConstantes.MODALIDAD_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 19
  } else if (
    tags.includes(filtroConstantes.BUSQUEDA) &&
    tags.includes(filtroConstantes.PROVINCIA) &&
    tags.includes(filtroConstantes.MODALIDAD_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 18
  } else if (
    tags.includes(filtroConstantes.MODALIDAD_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) ||
      tags.includes(filtroConstantes.SUBAREA) ||
      tags.includes(filtroConstantes.PROVINCIA))
  ) {
    variante = 17
  } else if (
    tags.includes(filtroConstantes.PROVINCIA) &&
    tags.includes(filtroConstantes.MODALIDAD_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 16
  } else if (tags.includes(filtroConstantes.BUSQUEDA) && tags.includes(filtroConstantes.MODALIDAD_TRABAJO)) {
    variante = 15
  } else if (tags.includes(filtroConstantes.TIPO_TRABAJO) && tags.includes(filtroConstantes.MODALIDAD_TRABAJO)) {
    variante = 14
  } else if (tags.includes(filtroConstantes.MODALIDAD_TRABAJO)) {
    variante = 13
  } else if (
    tags.includes(filtroConstantes.BUSQUEDA) &&
    tags.includes(filtroConstantes.PROVINCIA) &&
    tags.includes(filtroConstantes.TIPO_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 11
  } else if (
    tags.includes(filtroConstantes.BUSQUEDA) &&
    tags.includes(filtroConstantes.PROVINCIA) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 10
  } else if (tags.includes(filtroConstantes.BUSQUEDA) && tags.includes(filtroConstantes.TIPO_TRABAJO)) {
    variante = 9
  } else if (tags.includes(filtroConstantes.BUSQUEDA) && tags.includes(filtroConstantes.PROVINCIA)) {
    variante = 8
  } else if (
    tags.includes(filtroConstantes.BUSQUEDA) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 12
  } else if (tags.includes(filtroConstantes.BUSQUEDA)) {
    variante = 7
  } else if (
    tags.includes(filtroConstantes.PROVINCIA) &&
    tags.includes(filtroConstantes.TIPO_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 5
  } else if (
    tags.includes(filtroConstantes.TIPO_TRABAJO) &&
    (tags.includes(filtroConstantes.AREA) ||
      tags.includes(filtroConstantes.SUBAREA) ||
      tags.includes(filtroConstantes.PROVINCIA))
  ) {
    variante = 4
  } else if (tags.includes(filtroConstantes.TIPO_TRABAJO)) {
    variante = 3
  } else if (
    tags.includes(filtroConstantes.PROVINCIA) &&
    (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA))
  ) {
    variante = 6
  } else if (tags.includes(filtroConstantes.AREA) || tags.includes(filtroConstantes.SUBAREA)) {
    variante = 2
  } else if (tags.includes(filtroConstantes.PROVINCIA)) {
    variante = 1
  }

  const filtrosFormateados = {
    cantidadAvisos,
    seniority: makeNivelLaboral(filtros.nivel_laboral),
    modalidad: makeModalidadTrabajo(filtros.modalidad_trabajo),
    fecha: makeFechaPublicacion(filtros.dias_fecha_publicacion),
    area: filtros.subarea ? makeSubareaDescription(filtros.subarea) : makeAreaDescription(filtros.area),
    busqueda: makeBusquedaDescription(filtros.busqueda),
    ubicacion: filtros.localidad
      ? makeLocalidadDescription(filtros.provincia, filtros.localidad)
      : makeProvinciaDescription(filtros.provincia),
    tipoTrabajo: makeTipoTrabajo(filtros.tipo_trabajo),
  }

  let description
  if (variante) {
    description = i18n.t(`Listado Meta Description Variante ${variante}`, filtrosFormateados)
  } else {
    description = i18n.t(`Listado Meta Description`, filtrosFormateados)
  }

  return description
}