import { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { logoutAction, logoutResetAction } from '../../redux/actions/logout'
import { StatusType } from '../../redux/types/status'

interface LogoutProps {
  status: StatusType
}

const Logout = ({ status }: LogoutProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutAction())
  }, [])

  useEffect(() => {
    if (status.success) {
      dispatch(logoutResetAction())
      window.location.replace('/')
    }
  }, [status])

  return null
}

const state = ({ logoutStore }) => {
  return {
    status: logoutStore.status,
  }
}

export default connect(state)(Logout)
