import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Header from './components/header'
import SectionOne from './components/section-one'
import SectionTwo from './components/section-two'
import SectionThree from './components/section-three'
import Contact from './components/contact'
import { getCareerSite } from '../../../redux/actions/career-site'
import PageLoader from '../../../components/PageLoader'
import Metas from './components/metas'
import Vacancies from './components/vacancies'

const CareerSite = ({ match, careerSiteStates }) => {
  const { alias } = match.params

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getCareerSite(alias))
  }, [])

  useEffect(() => {
    if (careerSiteStates.error) {
      history.push('/error/404')
    }
  }, [careerSiteStates.error])

  if (careerSiteStates.loading || careerSiteStates.error) {
    return <PageLoader />
  }

  return (
    <>
      <Metas />
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Vacancies />
      <Contact />
    </>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSiteStates: careerSiteStore.careerSiteStates,
})

export default connect(states)(CareerSite)
