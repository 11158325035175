export const GET_LINK_PAGO = 'GET_LINK_PAGO'
export const GET_LINK_PAGOV2 = 'GET_LINK_PAGOV2'
export const GET_LINK_PAGO_SUCCESS = 'GET_LINK_PAGO_SUCCESS'
export const GET_LINK_PAGO_ERROR = 'GET_LINK_PAGO_ERROR'
export const GET_LINK_PAGO_RESET = 'GET_LINK_PAGO_RESET'

export const NOTIFICAR_PAGO_EXITOSO = 'NOTIFICAR_PAGO_EXITOSO'
export const NOTIFICAR_PAGO_EXITOSO_SUCCESS = 'NOTIFICAR_PAGO_EXITOSO_SUCCESS'
export const NOTIFICAR_PAGO_EXITOSO_ERROR = 'NOTIFICAR_PAGO_EXITOSO_ERROR'

export interface ProductItem {
  productId: number
  quantity?: number
}

export interface OperationCreate {
  items: [ProductItem]
  vendorId: number
  externalId: string
}

export interface OperationStatus {
  success: boolean
  loading: boolean
  error: boolean
}

export const defaultOperationStatus: OperationStatus = {
  success: false,
  loading: false,
  error: false,
}

export interface PaymentMPState {
  linkPago: string | null
  refName: string | null
  getLinkPagoStatus: OperationStatus
  notificarPagoExitosoStatus: OperationStatus
}

export const initialState: PaymentMPState = {
  linkPago: null,
  refName: null,
  getLinkPagoStatus: defaultOperationStatus,
  notificarPagoExitosoStatus: defaultOperationStatus,
}

export interface GetLinkPago {
  type: typeof GET_LINK_PAGO
  payload: {
    refName: string
    productId: number
    externalId: string
  }
}

export interface GetLinkPagoV2 {
  type: typeof GET_LINK_PAGOV2
  payload: {
    refName: string
    products: ProductItem[]
    externalId: string
  }
}

export interface GetLinkPagoSuccess {
  type: typeof GET_LINK_PAGO_SUCCESS
  payload: string
}

export interface GetLinkPagoError {
  type: typeof GET_LINK_PAGO_ERROR
  payload: null
}

export interface GetLinkPagoReset {
  type: typeof GET_LINK_PAGO_RESET
  payload: null
}

export interface NotificarPagoExitoso {
  type: typeof NOTIFICAR_PAGO_EXITOSO
  payload: {
    operationId: number
    externalId: string
  }
}

export interface NotificarPagoExitosoSuccess {
  type: typeof NOTIFICAR_PAGO_EXITOSO_SUCCESS
  payload: null
}

export interface NotificarPagoExitosoError {
  type: typeof NOTIFICAR_PAGO_EXITOSO_ERROR
  payload: null
}

export type PaymentMPActions =
  | GetLinkPago
  | GetLinkPagoSuccess
  | GetLinkPagoError
  | GetLinkPagoReset
  | NotificarPagoExitoso
  | NotificarPagoExitosoSuccess
  | NotificarPagoExitosoError
  | GetLinkPagoV2
