import { put, call, takeLatest, select } from 'redux-saga/effects'
import services from '../../services/ficha-aviso-service'
import { GET_POSTULACION_SUCCESS } from '../types/postulaciones'
import {
  POST_TO_APPLY,
  POST_TO_APPLY_SUCCESS,
  POST_TO_APPLY_ERROR,
  PostToApply,
  SEND_ANSWERS,
  SEND_ANSWERS_SUCCESS,
  SEND_ANSWERS_ERROR,
  SendAnswers,
} from '../types/postular-bar'

const getPostulacionStore = state => state.postulacionesStore

/**
 * changePostulacionState: Se cambia el estado de tienePreguntasPendientes para ocultar el componente Preguntas, en Mis Postulaciones
 * @param idPostulacion
 */
function* changePostulacionState(idPostulacion: number) {
  const postulacionStore = yield select(getPostulacionStore)

  if (postulacionStore.postulaciones && postulacionStore.postulaciones.length > 0) {
    const postulacionSeleccionada = postulacionStore.postulaciones.find(p => p.id === idPostulacion)
    const index = postulacionStore.postulaciones.indexOf(p => p.id === idPostulacion)

    postulacionSeleccionada.tienePreguntasPendientes = false
    postulacionStore.postulaciones[index] = postulacionSeleccionada

    yield put({ type: GET_POSTULACION_SUCCESS, payload: { postulaciones: postulacionStore.postulaciones } })
  }
}

function* postToApply({ payload }: PostToApply) {
  try {
    const result = yield call(services.postPostulacionAviso, payload)
    yield put({ type: POST_TO_APPLY_SUCCESS, payload: { ...result, avisoId: +payload.id } })
    if (result.id && payload.respuestas) {
      yield put({ type: SEND_ANSWERS_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: POST_TO_APPLY_ERROR, error })
  }
}

function* sendAnswers({ payload }: SendAnswers) {
  try {
    const result = yield call(services.envioRespuestas, payload)

    yield put({ type: SEND_ANSWERS_SUCCESS, payload: result })
    yield changePostulacionState(payload.idPostulacion)
  } catch (error) {
    yield put({ type: SEND_ANSWERS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* postularBar() {
  yield takeLatest(POST_TO_APPLY, postToApply)
  yield takeLatest(SEND_ANSWERS, sendAnswers)
}
