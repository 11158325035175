import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services/ficha-aviso-service'
import {
  PostularQr,
  PostularQrError,
  PostularQrOutput,
  PostularQrSuccess,
  POSTULAR_QR,
  POSTULAR_QR_ERROR,
  POSTULAR_QR_SUCCESS,
} from '../types/postulacion-qr'

function* postularQr({ payload }: PostularQr) {
  try {
    const result: PostularQrOutput = yield call(services.postularQr, payload)
    yield put<PostularQrSuccess>({ type: POSTULAR_QR_SUCCESS, payload: result })
  } catch (error) {
    yield put<PostularQrError>({ type: POSTULAR_QR_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* postulacionQrSagas() {
  yield takeLatest(POSTULAR_QR, postularQr)
}
