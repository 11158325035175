export const GET_AREA_ADS = 'GET_AREA_ADS'
export const GET_AREA_ADS_SUCCESS = 'GET_AREA_ADS_SUCCESS'
export const GET_AREA_ADS_ERROR = 'GET_AREA_ADS_ERROR'

export interface AreaAd {
  area: number
  image: string
  key: string
  link: string
  external?: boolean
  newTab?: boolean
}

export interface GetAreaAds {
  type: typeof GET_AREA_ADS
}

export interface GetAreaAdsSuccess {
  type: typeof GET_AREA_ADS_SUCCESS
  payload: AreaAd[]
}

export interface GetAreaAdsError {
  type: typeof GET_AREA_ADS_ERROR
}

export interface AreaAdsState {
  areaAds: AreaAd[] | null
  areaAdsStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: AreaAdsState = {
  areaAds: null,
  areaAdsStates: {
    loading: false,
    success: false,
    error: false,
  },
}

export type AreaAdsActions = GetAreaAds | GetAreaAdsError | GetAreaAdsSuccess
