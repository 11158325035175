import {
  SET_FILTERS_SETTINGS,
  SET_FILTERS_DEFAULT,
  SET_FILTERS_DELETE_ALL,
  SET_FILTERS_HIDE,
  SetFiltersSettings,
  SetFiltersDefault,
  SetFiltersDeleteAll,
  SetFiltersHide,
} from '../types/filters'

export const setFilterSettings = (): SetFiltersSettings => {
  return {
    type: SET_FILTERS_SETTINGS,
    payload: null,
  }
}

export const setFiltersDefault = (value: any): SetFiltersDefault => {
  return {
    type: SET_FILTERS_DEFAULT,
    payload: value,
  }
}

export const setFiltersDeleteAll = (value: boolean): SetFiltersDeleteAll => {
  return {
    type: SET_FILTERS_DELETE_ALL,
    payload: value,
  }
}

export const setFiltersHide = (value: any): SetFiltersHide => {
  return {
    type: SET_FILTERS_HIDE,
    payload: value,
  }
}
