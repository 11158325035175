import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SiteConfig } from '@navent-jobs/config'
import Text from '@navent-jobs/ui-kit/components/text'

// mixins
import { InfoCol, DataRow, DataIcon } from '../../mixins'
import { DataInfo, DataInfoContainer, MatchLabel } from './mixins'

// constants
import { SITE_ID } from '../../../../constants'
import { planConfig } from '../../constants/planConfig'
import features from '../../../../constants/features-per-country'

interface ComponentProps {
  avisoCrossPais: number
  localizacion: string
  modalidadTrabajo?: string
  tipoAviso: string
  tipoTrabajo?: string
  avisoMatch: boolean
  mobileFormat?: boolean
}

const Component = ({
  avisoCrossPais,
  localizacion,
  modalidadTrabajo,
  tipoAviso,
  tipoTrabajo,
  avisoMatch,
  mobileFormat,
}: ComponentProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { t } = useTranslation()
  const siteConfig = SiteConfig[SITE_ID]
  const ID_PAIS = siteConfig && siteConfig.pais && siteConfig.pais.id

  // conditionals to render cv match
  const cvMatchEnabled = features[SITE_ID || 'BMAR'].card.cvMatch
  const showCvMatch = cvMatchEnabled && avisoMatch && planConfig[tipoAviso].showMatch

  return (
    <DataInfoContainer mobileFormat={mobileFormat}>
      <InfoCol mobileFormat={mobileFormat}>
        <DataRow mobileFormat={mobileFormat}>
          {avisoCrossPais !== null && avisoCrossPais !== ID_PAIS ? (
            <DataIcon name="icon-light-globe" size="20" color={colors.grey84} />
          ) : (
            <DataIcon name="icon-light-location-pin" size="20" color={colors.grey84} />
          )}
          <DataInfo>
            <Text size="sm" as="h3">
              {localizacion}
            </Text>
          </DataInfo>
        </DataRow>

        {(modalidadTrabajo || tipoTrabajo) && (
          <DataRow mobileFormat={mobileFormat}>
            <DataIcon name="icon-light-office" size="20" color={colors.grey84} />
            <DataInfo>
              <Text size="sm" as="h3">
                {modalidadTrabajo || tipoTrabajo}
              </Text>
            </DataInfo>
          </DataRow>
        )}

        {showCvMatch && (
          <DataRow mobileFormat={mobileFormat}>
            <DataIcon name="icon-light-person-checked" size="20" color={colors.extra.normal} />
            <DataInfo>
              <MatchLabel>{t('card.match.label', { cv: t('cv') })}</MatchLabel>
            </DataInfo>
          </DataRow>
        )}
      </InfoCol>
    </DataInfoContainer>
  )
}

export default Component
