export const getRandomInt = (min: number, max: number): number => {
  const minI = Math.ceil(min)
  const maxI = Math.floor(max)
  return Math.floor(Math.random() * (maxI - minI) + minI)
}

// Algoritmo Fisher-Yates (muta el array pero es mas eficiente)
export const randomizeArray = <T>(array: T[]): void => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    // eslint-disable-next-line no-param-reassign
    array[i] = array[j]
    // eslint-disable-next-line no-param-reassign
    array[j] = temp
  }
}
