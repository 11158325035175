import styled from 'styled-components'
import { Col } from '@navent-jobs/ui-kit/components/grid'
import SubmitButton from '../../../../components/SubmitButton'

export const Content = styled.div`
  margin-top: 40px;
`

export const Header = styled.div`
  width: 100%;
`
export const WarningWrapper = styled.p`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`
export const ChageWarning = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.danger.normal};

  margin: 0px 0px 0px 9px;
`

export const ChageBlueWarning = styled(ChageWarning)`
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const FormGroup = styled('div')`
  margin-bottom: 4px;
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`

export const RequiredTitle = styled.p`
  width: 286px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey60};
  &::after {
    content: ' *';
    color: ${({ theme }) => theme.colors.danger.normal};
  }
`
export const RightCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding-left: 12px;
  }
`
export const LeftCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding-right: 12px;
  }
`

export const MessageError = styled('span')`
  color: ${({ theme }) => theme.colors.danger.normal};
  font-weight: 300;
  font-size: 12px;
`

export const CustomSubmitButton = styled(SubmitButton)`
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    max-width: 100%;
  }
`
export const CustomLabel = styled.span`
  font-size: 14px;
`
