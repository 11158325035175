import React from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { TextWrapper, Page, CustomLink, Wrapper } from './mixins'
import { productLandingTranslations } from '../../constants'

interface HighlightInterface {
  textA: string
  bold?: string
  textB?: string
  scrollRef?: string
  componentToScroll?: string
  buttonText?: string
  infoTextAction?: () => void
  infoTextFunction?: Function
  metric?: () => void
}

const Component = ({ action = { primaryAction: () => {} }, productName, accessor = 'highlight' }) => {
  const { textA, bold, textB, scrollRef, infoTextAction, infoTextFunction, buttonText, metric }: HighlightInterface =
    productLandingTranslations()[productName][accessor]

  const dispatch = useDispatch()

  const onClick = () => {
    metric && metric()
    if (infoTextAction) {
      dispatch(infoTextAction())
    } else if (action?.primaryAction) {
      action.primaryAction()
    } else if (infoTextFunction) {
      infoTextFunction()
    }
  }

  return (
    <Page id={scrollRef || 'HIGHLIGHT'}>
      <Container noGutter>
        <Row>
          <Wrapper>
            <Col lg={6} offset={{ lg: 2 }}>
              <TextWrapper>
                {textA}
                <strong>{bold}</strong>
                {textB}
              </TextWrapper>
            </Col>
            <Col>
              <div onClick={onClick}>
                <CustomLink linkoptions={{ variant: 'primary', type: 'outline', size: 'medium', anchor: true }}>
                  {buttonText}
                </CustomLink>
              </div>
            </Col>
          </Wrapper>
        </Row>
      </Container>
    </Page>
  )
}

export default Component
