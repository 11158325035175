import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ComponentContainer = styled.div`
  background-color: white;
`

export const FlexBox = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonBack = styled(Button)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  i:before,
  &:hover > i:before,
  &:active > i:before,
  &:focus:not(:active) > i:before {
    color: ${({ theme }) => theme.colors.grey84} !important;
    background-color: ${({ theme }) => theme.colors.grey84} !important;
  }
`
