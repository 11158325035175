import { isEmpty } from 'lodash'
import i18n from 'i18next'
import { URLData } from '@navent-jobs/interlinking'

const generateBusqueda = filtros => {
  // Publicacion, seniority y modalidad son los unicos 3 filtros que se aplican en la url despues de la palabra clave
  // Entonces me fijo los filtros que hay antes que ellos, y eso es la palabra clave.
  let busqueda = filtros
  if (busqueda.includes('-publicacion') && busqueda.split('-publicacion')[0] !== '') {
    busqueda = filtros.split('-publicacion')[0]
  } else if (busqueda.includes('-seniority') && busqueda.split('-seniority')[0] !== '') {
    busqueda = filtros.split('-seniority')[0]
  } else if (busqueda.includes('-modalidad') && busqueda.split('-modalidad')[0] !== '') {
    busqueda = filtros.split('-modalidad')[0]
  }
  return busqueda
}

const tagsTranslationMap = {
  toPath: {
    area: 'area',
    subarea: 'subarea',
    tipo_trabajo: 'tipo-trabajo',
    apto_discapacitado: 'discapacitados',
    distancia: 'distancia',
    salario: 'salario',
    nivel_laboral: 'seniority',
    dias_fecha_publicacion: 'publicacion',
    provincia: 'provincia',
    localidad: 'localidad',
    busqueda: 'busqueda',
    modalidad_trabajo: 'modalidad',
  },
  toBFFId: {
    area: 'area',
    subarea: 'subarea',
    'tipo-trabajo': 'tipo_trabajo',
    discapacitados: 'apto_discapacitado',
    distancia: 'distancia',
    salario: 'salario',
    seniority: 'nivel_laboral',
    publicacion: 'dias_fecha_publicacion',
    provincia: 'provincia',
    localidad: 'localidad',
    busqueda: 'busqueda',
    modalidad: 'modalidad_trabajo',
  },
}

const filterMap = {
  tipo_trabajo: {
    'full-time': 'full-time',
    'part-time': 'part-time',
    pasantia: 'pasantia',
    'por-horas': 'por-horas',
    temporario: 'temporario',
    'por-contrato': 'por-contrato',
    teletrabajo: 'teletrabajo',
    'primer-empleo': 'primer-empleo',
    'fines-de-semana': 'fines-de-semana',
    voluntario: 'voluntario',
  },
}

export const parseURLGrails = (match?: {
  patch: string
  params: { idAviso: string; provincia: string; localidad: string; '0': string; filtros; masFiltros; empleo }
  url: string
}): URLData => {//NOSONAR
  const empleo = match?.params.empleo
  let filtros = match?.params.filtros
  let masFiltros = match?.params.masFiltros
  const filtrosToReturn: { [key: string]: any } = {
    area: [],
    subarea: [],
    // tipo_trabajo, // done [-tiempo-medio, -full-time-joan, -trabajo-temporal]
    apto_discapacitado: [],
    dias_fecha_publicacion: [], // sacar los dias
    distancia: [],
    // salario: [], // *-*
    nivel_laboral: [],
    modalidad_trabajo: [],
  }

  // Agrego provincia y localidad sacadas del match
  if (match) {
    if (match.params.provincia) {
      filtrosToReturn.provincia = [match.params.provincia]
      if (match.params.localidad) {
        filtrosToReturn.localidad = [match.params.localidad]
      }
    }
  }

  // No tengo en cuenta la pagina en la url (se obtiene en packages/postulantes/src/pages/listado-avisos/components/listado-avisos/index.tsx)
  if (filtros) {
    if (filtros.includes('-pagina') && filtros.split('-pagina')[0] !== '') {
      filtros = filtros.replace(`-pagina${filtros.split('-pagina')[1]}`, '')
    }
  }
  if (masFiltros) {
    if (masFiltros.includes('-pagina') && masFiltros.split('-pagina')[0] !== '') {
      masFiltros = masFiltros.replace(`-pagina${masFiltros.split('-pagina')[1]}`, '')
    }
  }

  // Obtengo la palabra clave de la url y la saco de los filtros
  let busqueda
  if (empleo === 'trabajo' && filtros) {
    busqueda = generateBusqueda(filtros)
    filtros = filtros.replace(busqueda, '')
  }

  if (masFiltros) {
    busqueda = generateBusqueda(masFiltros)
    masFiltros = masFiltros.replace(busqueda, '')
  }

  // Reemplaza los guiones de la palabra clave por espacios
  // eslint-disable-next-line prefer-const
  if (busqueda) {
    busqueda = busqueda?.slice(1).split('-').join(' ')
  }

  // Uno ambos filtros
  let params = filtros && masFiltros ? `${filtros}${masFiltros}` : filtros || masFiltros || ''

  // Tipo de trabajo spliter
  const tipoTrabajoArray = Object.keys(filterMap.tipo_trabajo)
  const tipoTrabajoTrad = tipoTrabajoArray.map(e => i18n.t(`facetsName.url.tipo-trabajo.${e}`))

  let tipoTrabajo
  tipoTrabajoTrad.forEach(element => {
    // El check es porque existe un filtro primer-empleo tanto para tipo de trabajo
    // como para nivel laboral (seniority)
    if (
      element === 'primer-empleo' &&
      params.includes('seniority-primer-empleo') &&
      !params.match(/primer-empleo(.*)seniority-primer-empleo/)
    ) {
      return
    }

    const elementRe = new RegExp(`-${element}(.*)`)
    const matchElementFiltros = params.match(elementRe)
    if (matchElementFiltros) {
      tipoTrabajo = element
      params = params.split(elementRe).join('')
    }
  })

  // Salario splitter
  const salarioRx = /-\$(\d+|\*)-\$(\d+|\*)/
  const salarioMatch = salarioRx.exec(params)
  if (salarioMatch) {
    filtrosToReturn.salario = [`${salarioMatch[1]}-${salarioMatch[2]}`]
    params = params.replace(salarioRx, '')
  }

  // Resto de filtros
  const splitParams = empleo === 'trabajo' ? params.split('/oferta-de-trabajo') : params.split('/oferta-de-empleo')

  // Si no tiene ningun filtro ademas de los ya buscados, solo mando los encontrados
  if (!splitParams[0]) {
    const toReturn: any = {}
    if (busqueda) toReturn.busqueda = busqueda
    if (tipoTrabajo) toReturn.tipo_trabajo = i18n.t(`facetsName.url-vuelta.tipo-trabajo.${tipoTrabajo}`)
    if (filtrosToReturn.salario) toReturn.salario = filtrosToReturn.salario.join('-')
    if (filtrosToReturn.provincia) toReturn.provincia = filtrosToReturn.provincia.join('-')
    if (filtrosToReturn.localidad) toReturn.localidad = filtrosToReturn.localidad.join('-')
    return toReturn
  }

  // Si tiene mas filtros, los sigo parseando
  const splitedURL = splitParams[0].split('-')
  // Primero busco los tags
  let lastKey
  splitedURL.forEach(currentWord => {
    const tCurrentWord = tagsTranslationMap.toBFFId[currentWord] ? tagsTranslationMap.toBFFId[currentWord] : currentWord
    if (filtrosToReturn[tCurrentWord]) {
      lastKey = tCurrentWord
    } else if (lastKey) {
      filtrosToReturn[lastKey].push(tCurrentWord)
    }
  })

  if (busqueda) filtrosToReturn.busqueda = [busqueda]
  // eslint-disable-next-line @typescript-eslint/camelcase
  if (tipoTrabajo) filtrosToReturn.tipo_trabajo = [tipoTrabajo]

  // Ahora busco los filtros
  const keys = Object.keys(filtrosToReturn)
  keys.forEach(key => {
    if (isEmpty(filtrosToReturn[key])) {
      delete filtrosToReturn[key]
    } else {
      let filtroDestraducido
      const filtroConTraduccion = filtrosToReturn[key].join('-')

      // Destraducimos antes de devolver
      if (key === 'apto_discapacitado') {
        filtroDestraducido = i18n.t(`facetsName.url-vuelta.discapacitados.${filtroConTraduccion}`)
      } else if (key === 'area' || key === 'subarea' || key === 'tipo_trabajo' || key === 'distancia') {
        const keySinGuionBajo = key.replace(/_/g, '-')
        filtroDestraducido = i18n.t(`facetsName.url-vuelta.${keySinGuionBajo}.${filtroConTraduccion}`)
      } else {
        filtroDestraducido = filtroConTraduccion
      }

      filtrosToReturn[key] = filtroDestraducido
    }
  })

  return filtrosToReturn
}
