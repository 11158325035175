const modalCrearAlerta = {
  'Crear alerta de nuevos trabajos': 'Crear alerta de nuevos empleos',
  'Recibí por email los avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda':
    'Recibe por email los avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda.',
  'Ya tenés una alerta creada con las mismas características.':
    'Ya tienes una alerta creada con las mismas características.',
  'Podés gestionarla desde Alertas.': 'Puedes gestionarla desde Alertas.',
  'Error por limite de alertas': 'Ya creaste tus 10 alertas disponibles. Para agregar una nueva, elimina una anterior.',
  '¿Cuándo te los enviamos?': '¿Cuándo te los enviamos?',
  'Cuando se publican': 'Cuando se publican',
  'Todos los días': 'Todos los días',
  'Todas las semanas': 'Todas las semanas',
  'Todos los meses': 'Todos los meses',
  INSTANTANEO: 'Cuando se publican',
  DIARIO: 'Todos los días',
  SEMANAL: 'Todas las semanas',
  MENSUAL: 'Todos los meses',
  'AHORA NO': 'Solo guardar los filtros',
  'Solo guardar los filtros': 'Solo guardar los filtros',
  '¿Cuál es tu email?': '¿Cuál es tu email?',
  'Ya tengo cuenta': 'Ya tengo cuenta en {{nombreSitio}}',
  'Validacion Mail Alerta': 'Ingresa tu email.',
  'Ya guardamos tu alerta': '¡Ya guardamos tu alerta, {{nombre}}!',
  'Cuando se publiquen avisos nuevos con estos filtros, te llegarán por email':
    'Cuando se publiquen avisos nuevos con estos filtros, te llegarán por email.',
  'Podés modificar la frecuencia de envío o eliminar la alerta desde Notificaciones':
    'Puedes modificar la frecuencia de envío o eliminar la alerta desde Notificaciones',
  'Podés elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones':
    'Puedes elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones',
  Cerrar: 'Cerrar',
  'Ir a alertas': 'Ir a alertas',
  'Frecuencia de alerta': '{{frecuenciaDeAlerta}} recibirás por email avisos nuevos con estos filtros.',
  'La búsqueda quedó guardada, pero no recibirás ningún email':
    'La búsqueda quedó guardada, pero no recibirás ningún email.',
  'Puedes elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones':
    'Puedes elegir la opción de recibir las ofertas por email o eliminar la búsqueda desde Notificaciones',
  'Te enviamos un email para que confirmes tu cuenta a': 'Te enviamos un email para que confirmes tu cuenta a: ',
  'Activa tu cuenta y recibe por email las nuevas ofertas de tu búsqueda':
    'Activa tu cuenta y recibe por email las nuevas ofertas de tu búsqueda.',
  'Ya te reenviamos el email de activación': 'Ya te reenviamos el email de activación',
  'No pudimos crear tu alerta, intenta nuevamente': 'No pudimos crear tu alerta, intenta nuevamente.',
}

export default modalCrearAlerta
