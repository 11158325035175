import { put, call, takeLatest, take } from 'redux-saga/effects'
import service from '../../services/alerts-v2'
import {
  CreateAlert,
  CreateAlertError,
  CreateAlertResType,
  CreateAlertSuccess,
  CREATE_ALERT,
  CREATE_ALERT_ERROR,
  CREATE_ALERT_SUCCESS,
  DeleteAlert,
  DELETE_ALERT,
  DELETE_ALERT_ERROR,
  DELETE_ALERT_SUCCESS,
  EditAlert,
  EDIT_ALERT,
  EDIT_ALERT_ERROR,
  EDIT_ALERT_SUCCESS,
  GetAlert,
  GetAlertasFrecuencia,
  GetAlertasFrecuenciaError,
  GetAlertasFrecuenciaSuccess,
  GetAlertError,
  GetAlertSuccess,
  GetAlertType,
  GET_ALERT,
  GET_ALERTAS_FRECUENCIA,
  GET_ALERTAS_FRECUENCIA_ERROR,
  GET_ALERTAS_FRECUENCIA_SUCCESS,
  GET_ALERT_ERROR,
  GET_ALERT_SUCCESS,
  StaticOption,
  SwitchAlert,
  SWITCH_ALERT,
  SWITCH_ALERT_ERROR,
  SWITCH_ALERT_SUCCESS,
} from '../types/alert-v2'

function* getAlertasFrecuencia() {
  try {
    const response: StaticOption[] = yield call(service.GetAlertasFrecuencia)
    yield put<GetAlertasFrecuenciaSuccess>({ type: GET_ALERTAS_FRECUENCIA_SUCCESS, payload: response })
  } catch (error) {
    yield put<GetAlertasFrecuenciaError>({ type: GET_ALERTAS_FRECUENCIA_ERROR, payload: null })
  }
}

function* createAlert({ payload }: CreateAlert) {
  try {
    const resp: CreateAlertResType = yield call(service.createAlert, payload)
    yield put<CreateAlertSuccess>({ type: CREATE_ALERT_SUCCESS, payload: resp })
  } catch {
    yield put<CreateAlertError>({ type: CREATE_ALERT_ERROR, payload: null })
  }
}

function* getAlert() {
  try {
    const resp: GetAlertType[] = yield call(service.getAlert)
    yield put<GetAlertSuccess>({ type: GET_ALERT_SUCCESS, payload: resp })
  } catch {
    yield put<GetAlertError>({ type: GET_ALERT_ERROR, payload: null })
  }
}

function* deleteAlert({ payload }: DeleteAlert) {
  try {
    yield call(service.deleteAlert, payload)
    yield put({ type: GET_ALERT, payload: null })
    yield put({ type: DELETE_ALERT_SUCCESS, payload: null })
  } catch (error) {
    yield put({ type: DELETE_ALERT_ERROR, payload: null })
  }
}

function* editAlert({ payload }: EditAlert) {
  try {
    const resp: EditAlert = yield call(service.editAlert, payload)
    yield put({ type: GET_ALERT, payload: null })
    yield put({ type: EDIT_ALERT_SUCCESS, payload: resp })
  } catch (error) {
    yield put({ type: EDIT_ALERT_ERROR, payload: null })
  }
}

function* switchAlert({ payload }: SwitchAlert) {
  try {
    const resp: SwitchAlert = yield call(service.switchAlert, payload)
    yield put({ type: SWITCH_ALERT_SUCCESS, payload: resp })
  } catch (error) {
    yield put({ type: SWITCH_ALERT_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* alertas() {
  yield takeLatest<GetAlertasFrecuencia>(GET_ALERTAS_FRECUENCIA, getAlertasFrecuencia)
  yield takeLatest<CreateAlert>(CREATE_ALERT, createAlert)
  yield takeLatest<GetAlert>(GET_ALERT, getAlert)
  yield takeLatest<DeleteAlert>(DELETE_ALERT, deleteAlert)
  yield takeLatest<EditAlert>(EDIT_ALERT, editAlert)
  yield takeLatest<SwitchAlert>(SWITCH_ALERT, switchAlert)
}
