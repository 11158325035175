import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// utils
import { goToMP } from '../../../../utils/mercado-pago'

const MercadoPagoWrapper = ({
  action = { primaryAction: () => {} },
  productName,
  getLinkPagoStatus,
  children,
  disponibilidad,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loadingMPLink, setLoadingMPLink] = useState(false)

  const callAction = () => {
    goToMP({ setLoadingMPLink, dispatch, history, productName, disponibilidad })
  }

  useEffect(() => {
    if (getLinkPagoStatus) {
      setLoadingMPLink(getLinkPagoStatus.loading)
    }
  }, [getLinkPagoStatus])

  return (
    <>
      {React.cloneElement(children, {
        mpOptions: {
          primaryAction: callAction,
          primaryLoading: loadingMPLink,
          secondaryAction: action.primaryAction,
        },
      })}
    </>
  )
}

const states = ({ paymentMPStore, productsStore }) => ({
  getLinkPagoStatus: paymentMPStore.getLinkPagoStatus,
  linkPago: paymentMPStore.linkPago,
  disponibilidad: productsStore?.productAvailability?.availability,
})

export default connect(states)(MercadoPagoWrapper)
