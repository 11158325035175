import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FilterScreen } from '../../../../../../components/filter'
import { DataLayerListadoEmpresas } from '../../../../../../constants/gtm-events-listado-empresas'
import { setFilterApplied } from '../../../../../../redux/actions/listado-empresas'

const FiltersMobile = ({ location, filters, filtersApplied, filtersSettings, deleteAll }) => {
  const [filterSelected, setFilterSelected] = useState()
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const type = {
    query: 'query',
    industry: 'industria',
    province: 'ubicacion',
  }

  const selectFilter = filter => {
    if (filter.type === filterSelected?.type) {
      setFilterSelected(undefined)
    } else {
      setFilterSelected(filter)
    }
  }

  const metric = (filter, label) => {
    DataLayerListadoEmpresas.filter(filter.type, label)
  }

  // delete filters applied
  const handleResetFiltersApplied = () => {
    dispatch(setFilterApplied([]))
    history.replace({
      search: '',
    })
    setFilterSelected(undefined)
  }

  // delete filters
  const handleRemoveFilterSelected = item => {
    if (item.id === 'busqueda') {
      item.id = 'query'
    }
    const filterRewrite = filtersApplied.filter(filter => filter.id !== item.id)
    dispatch(setFilterApplied(filterRewrite))
    queryParams.delete('page')
    queryParams.delete(type[item.id])
    history.replace({
      search: queryParams.toString(),
    })
    setFilterSelected(undefined)
  }

  // set filters in the store
  const handleFilterApplied = (filterType, facet) => {
    const result = [{ type: filterType, facets: facet }]
    dispatch(setFilterApplied(filtersApplied.concat(result)))
  }

  // set filters in the store and render into list
  const handleFacetSeleted = (filterType, facet) => {
    if (facet) {
      handleFilterApplied(filterType, facet)
      queryParams.delete('page')
      queryParams.append(type[filterType], facet.value)
      history.push({
        search: queryParams.toString(),
      })
    } else {
      handleRemoveFilterSelected({ id: filterType })
    }
  }

  return (
    <FilterScreen
      filters={filtersSettings?.filters?.map(fs => ({
        ...fs,
        facets: filters?.find(f => f.type === fs.type)?.facets,
        optionSelected: filtersApplied?.find(fa => fa.id === fs.type)
          ? [filtersApplied?.find(fa => fa.id === fs.type)]
          : false,
      }))}
      filtersApplied={filtersApplied}
      performFiltering={handleRemoveFilterSelected}
      selectFilter={selectFilter}
      filterSelected={filterSelected}
      metric={metric}
      onSearch={handleFacetSeleted}
      toggle={undefined}
      onRemoveAll={handleResetFiltersApplied}
      deleteAll={deleteAll}
    />
  )
}

export default FiltersMobile
