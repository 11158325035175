import styled from 'styled-components'

export const SnackbarText = styled('span')`
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    flex-grow: 0;
  }
  @media (max-width: ${props => props.theme.screen.xsMin}) {
    font-size: 12px;
  }
`
