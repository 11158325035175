import { FilterApplied } from './filters'

export const GET_BREADCRUMBS = 'GET_BREADCRUMBS'
export const GET_BREADCRUMBS_SUCCESS = 'GET_BREADCRUMBS_SUCCESS'
export const GET_BREADCRUMBS_ERROR = 'GET_BREADCRUMBS_ERROR'

export interface Breadcrumb {
  links: {
    title: string
    link: string
  }[]
  json: string
}

export interface BreadcrumbsState {
  breadcrumbs: Breadcrumb | null
  loading: boolean
  error: boolean
}

export const initialState: BreadcrumbsState = {
  breadcrumbs: null,
  loading: false,
  error: false,
}

export interface GetBreadcrumbs {
  type: typeof GET_BREADCRUMBS
  payload: FilterApplied[]
}

export interface GetBreadcrumbsSuccess {
  type: typeof GET_BREADCRUMBS_SUCCESS
  payload: Breadcrumb
}

export interface GetBreadcrumbsError {
  type: typeof GET_BREADCRUMBS_ERROR
  payload: null
}

export type BreadcrumbsActions = GetBreadcrumbs | GetBreadcrumbsSuccess | GetBreadcrumbsError
