// images background button home
import puestosEjecutivosBtn from '../assets/images/landings/btn/puestos-ejecutivos.webp'
import jovenesProfesionalesBtn from '../assets/images/landings/btn/jovenes-profesionales.webp'

// images background portada listado
import puestosEjecutivosBanner from '../assets/images/landings/banner/puestos-ejecutivos.webp'
import jovenesProfesionalesBanner from '../assets/images/landings/banner/jovenes-profesionales.webp'
import empleosInclusivosBanner from '../assets/images/landings/banner/empleos-inclusivos.webp'
import partTimeBanner from '../assets/images/landings/banner/part-time.webp'
import practicasProfesionalesBanner from '../assets/images/landings/banner/practicas-profesionales.webp'

// const titles
const titlePuestosEjecutivos = 'Puestos ejecutivos y directivos'
const titleJovenesProfesionales = 'Jóvenes profesionales'
const titleEmpleosInclusivos = 'Empleos inclusivos'
const titlePartTime = 'Empleos Part-time'
const titlePracticaProfesional = 'Prácticas profesionales'

const settingsBanner = {
  BMAR: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
  BMCL: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
    empleosInclusivos: {
      enabled: true,
      imageListado: empleosInclusivosBanner,
      href: '/empleos-discapacitados-apto.html?landing-empleos-inclusivos=true',
      title: titleEmpleosInclusivos,
    },
    partTime: {
      enabled: true,
      imageListado: partTimeBanner,
      href: '/empleos-part-time.html?landing-empleos-part-time=true',
      title: titlePartTime,
    },
    practicasProfesionales: {
      enabled: true,
      imageListado: practicasProfesionalesBanner,
      href: '/empleos-practica-profesional.html?landing-practicas-profesionales=true',
      title: titlePracticaProfesional,
    },
  },
  BMEC: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
  BMMX: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
  BMPA: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
  BMPE: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
  BMVE: {
    puestosEjecutivos: {
      enabled: true,
      imageHome: puestosEjecutivosBtn,
      imageListado: puestosEjecutivosBanner,
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      title: titlePuestosEjecutivos,
    },
    jovenesProfesionales: {
      enabled: true,
      imageHome: jovenesProfesionalesBtn,
      imageListado: jovenesProfesionalesBanner,
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      title: titleJovenesProfesionales,
    },
  },
}

export default settingsBanner
