export const productLanding = {
  nulinga: {
    home: {
      title: 'Validá tu nivel de Inglés con Nulinga y resaltá tu perfil',
      description:
        'Completá el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
    },
    description: {
      firstRow: {
        thirdColumn: {
          description:
            'Podés hacer el test por un costo accesible y realizar el pago fácilmente a través de Mercado pago.',
        },
      },
    },
    product: {
      description:
        'Completá el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
    },
  },
  pda: {
    home: {
      title: 'Resaltá tus talentos frente a las empresas con PDA',
      description: 'Realizá el Test de PDA y conocé tus fortalezas para resaltar tu CV al postularte.',
      secondaryButton: 'Conocé más',
    },
    product: {
      description: 'Resaltá tus fortalezas para encontrar el empleo que deseas.',
    },
  },
  nulingaCourse: {
    home: {
      title: 'Mejorá tu nivel de inglés 100% online',
      description:
        'Elevá a una próxima escala tus conocimientos aprendiendo inglés con clases personalizadas en vivo, 100% online, y valores preferenciales.',
    },
    benefits: {
      titleFirstPart: 'Descubrí los beneficios de cursar con ',
      titleSecondPart: ' y optimizá tu visibilidad laboral',
      row1: {
        firstColumn: {
          description:
            'Aprendé a través de clases remotas en vivo, con una persona docente que te acompañará y guiará durante todo tu cursado.',
        },
        secondColumn: {
          description:
            'Cursá con un plan individual -regular o intensivo- o grupal, con un máx. de 4 estudiantes por aula.',
        },
      },
      row2: {
        firstColumn: {
          description:
            'Aprendé idiomas mediante un plan de estudios diseñado para satisfacer tus necesidades: contenido multimedia, relevante y orientado a negocios.',
        },
        secondColumn: {
          description:
            'Gestioná todo lo que necesitas desde la plataforma: accedé a tus clases y al material de estudio, cancelá y reprogramá clases, solicitá cambios en tu cursada, y administrá tu suscripción.',
        },
      },
    },
    because: {
      answer1: {
        title: 'Vivís un encuentro exclusivo: ',
      },
      answer2: {
        title: 'Aprendés con material multimedia: ',
      },
    },
    share: {
      title: '¡Compartí tus logros!',
    },
    product: {
      description:
        'Registrate ahora y un profesional de Nulinga se contactará con vos para avanzar con la inscripción.',
    },
    contact: {
      titleFirstPart: '¿Querés empezar a cursar pero todavía tenés preguntas?',
      answer1: {
        answerLink: 'Escribinos a través de WhatsApp',
      },
      answer2: {
        title: 'Soporte online: ',
        answerText1: 'Ingresá a ',
        answerText2: ' para consultarnos tus dudas a través de nuestro chat, o escribinos por mail a info@nulinga.com.',
      },
    },
  },
}
