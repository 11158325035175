import React from 'react'
import { Checkbox } from '@navent-jobs/ui-kit/components/checkbox'
import { useTranslation } from 'react-i18next'

// mixins
import { CheckGroup, CustomLink } from '../mixins'
import { NOMBRE_SITIO } from '../../../../../constants'

interface DataProtectionPolicyProps {
  onCheckProtectionPolicy: (e) => void
}

const Text = () => {
  const { t } = useTranslation()
  return (
    <>
      {t('signUp.politicaDatosPersonales')}
      <CustomLink
        target="_blank"
        href="/politica-de-privacidad"
        linkoptions={{
          native: false,
          size: 'small',
          type: 'link',
          variant: 'primary',
        }}
      >
        Política de protección de datos personales y privacidad
      </CustomLink>{' '}
      de {NOMBRE_SITIO}.
    </>
  )
}

const DataProtectionPolicy = ({ onCheckProtectionPolicy }: DataProtectionPolicyProps) => {
  return (
    <CheckGroup>
      <Checkbox
        id="check-terminos-y-condiciones"
        name="check-terminos-y-condiciones"
        label={<Text />}
        onChange={e => onCheckProtectionPolicy(e.currentTarget.checked)}
        fieldOptions={{
          size: 'small',
        }}
      />
    </CheckGroup>
  )
}

export default DataProtectionPolicy
