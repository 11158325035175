import { StatusType, status } from './status'

export const GET_FICHA_EMPRESA_FERIA = 'GET_FICHA_EMPRESA_FERIA'
export const GET_FICHA_EMPRESA_FERIA_SUCCESS = 'GET_FICHA_EMPRESA_FERIA_SUCCESS'
export const GET_FICHA_EMPRESA_FERIA_ERROR = 'GET_FICHA_EMPRESA_FERIA_ERROR'

export const POST_LISTADO_EMPRESAS_FERIA = 'POST_LISTADO_EMPRESAS_FERIA'
export const POST_LISTADO_EMPRESAS_FERIA_SUCCESS = 'POST_LISTADO_EMPRESAS_FERIA_SUCCESS'
export const POST_LISTADO_EMPRESAS_FERIA_ERROR = 'POST_LISTADO_EMPRESAS_FERIA_ERROR'

export const GET_FERIA = 'GET_FERIA'
export const GET_FERIA_SUCCESS = 'GET_FERIA_SUCCESS'
export const GET_FERIA_ERROR = 'GET_FERIA_ERROR'

export const GET_CONFERENCIAS = 'GET_CONFERENCIAS'
export const GET_CONFERENCIAS_SUCCESS = 'GET_CONFERENCIAS_SUCCESS'
export const GET_CONFERENCIAS_ERROR = 'GET_CONFERENCIAS_ERROR'

export const GET_CONFERENCIAS_EMPRESA = 'GET_CONFERENCIAS_EMPRESA'
export const GET_CONFERENCIAS_EMPRESA_SUCCESS = 'GET_CONFERENCIAS_EMPRESA_SUCCESS'
export const GET_CONFERENCIAS_EMPRESA_ERROR = 'GET_CONFERENCIAS_EMPRESA_ERROR'

export const GET_VIDEOS_CONFERENCIA = 'GET_VIDEOS_CONFERENCIA'
export const GET_VIDEOS_CONFERENCIA_SUCCESS = 'GET_VIDEOS_CONFERENCIA_SUCCESS'
export const GET_VIDEOS_CONFERENCIA_ERROR = 'GET_VIDEOS_CONFERENCIA_ERROR'

export interface ListadoEmpresasFeriaReq {
  page: number
  filtros: {
    query: string
    provinces: number[]
    industries: number[]
    sortByRating: boolean
    sort: {
      strategy: string
      seed: string
    }
  }
  idFeria: number
}

interface EmpresaDto {
  companyId: number
  description: string
  employeeCount: number
  following: boolean
  gptw: boolean
  gptwUrl: string
  industry: string
  jobPostingsQuantity: number
  logo: string
  name: string
  province: string
  reviewAverage: number
}

interface FacetDto {
  id: string
  name: string
  quantity: number
}

interface FacetAppliedDto {
  id: string
  value: string
}

interface ListadoEmpresasDto {
  companies: EmpresaDto[]
  filters: {
    facets: FacetDto[]
    type: string
  }[]
  filtersApplied: FacetAppliedDto[]
  pagination: {
    currentPage: number
    totalPages: number
    elementsPerPage: number
    totalElements: number
  }
}

export interface GetFeriaRequestDto {
  slug: string
}

export enum TipoFeria {
  PAIS = 'PAIS',
  UNIVERSIDAD = 'UNIVERSIDAD',
}

export interface FeriaDto {
  id: number
  nombre: string
  slug: string
  idPais: number
  fechaInicio: string
  fechaFin: string
  tipo: TipoFeria
  empresas: {
    id: number
    nombre: string
    logo: string
    idTipo: number
    tipo: string
  }[]
  imagenes: {
    logoHeader: string
    logoHome: string
    homeBanner: string
    conferenciaBanner: string
    sponsors: string[]
  }
  wordings: {
    encabezadoTitulo: string | null
    encabezadoDescripcion: string | null
    informacionCantAvisos: string | null
    informacionLabelAvisos: string | null
    informacionCantEmpresas: string | null
    videoConferenciaTitulo: string | null
    videoConferenciaDescripcion: string | null
  }
}

export interface GetFichaEmpresaFeriaRequestDto {
  idFeria: number
  idEmpresa: number
}

export interface AvisoFeriaDto {
  id: number
  idArea: number
  nombreArea: string
  postulado: boolean
}

export interface FichaEmpresaFeriaDto {
  id: number
  ciudad: string
  denominacion: string
  descripcion: string
  industria: {
    id: number
    nombre: string
  } | null
  logoURL: string
  provincia: {
    id: number
    nombre: string
  } | null
  videoUrl: string | null
  rangoEmpleados: string | null
  avisosFeria: AvisoFeriaDto[]
}

export interface ConferenciaEmpresaDto {
  id: number
  idEmpresa: number
  titulo: string
  descripcion: string
  disertante: string
  link: string
  linkRewatch: string
  fechaInicio: string
  logo: string
}

export interface GetConferenciaEmpresaRequestDto {
  idFeria: number
  idEmpresa: number
}

export interface VideoConferenciaDto {
  id: number
  titulo: string
  link: string
}

export interface GetFichaEmpresaFeria {
  type: typeof GET_FICHA_EMPRESA_FERIA
  payload: GetFichaEmpresaFeriaRequestDto
}

export interface GetFichaEmpresaFeriaSuccess {
  type: typeof GET_FICHA_EMPRESA_FERIA_SUCCESS
  payload
}

export interface GetFichaEmpresaFeriaError {
  type: typeof GET_FICHA_EMPRESA_FERIA_ERROR
  payload: boolean
}

export interface PostListadoEmpresasFeria {
  type: typeof POST_LISTADO_EMPRESAS_FERIA
  payload: ListadoEmpresasFeriaReq
}

export interface PostListadoEmpresasFeriaSuccess {
  type: typeof POST_LISTADO_EMPRESAS_FERIA_SUCCESS
  payload: ListadoEmpresasDto
}

export interface PostListadoEmpresasFeriaError {
  type: typeof POST_LISTADO_EMPRESAS_FERIA_ERROR
  payload: boolean
}

export interface GetFeria {
  type: typeof GET_FERIA
  payload: GetFeriaRequestDto
}

export interface GetFeriaSuccess {
  type: typeof GET_FERIA_SUCCESS
  payload: FeriaDto
}

export interface GetFeriaError {
  type: typeof GET_FERIA_ERROR
  payload: boolean
}

export interface GetConferencias {
  type: typeof GET_CONFERENCIAS
  payload: number
}

export interface GetConferenciasSuccess {
  type: typeof GET_CONFERENCIAS_SUCCESS
  payload: ConferenciaEmpresaDto[]
}

export interface GetConferenciasError {
  type: typeof GET_CONFERENCIAS_ERROR
  payload: boolean
}

export interface GetConferenciasEmpresa {
  type: typeof GET_CONFERENCIAS_EMPRESA
  payload: GetConferenciaEmpresaRequestDto
}

export interface GetConferenciasEmpresaSuccess {
  type: typeof GET_CONFERENCIAS_EMPRESA_SUCCESS
  payload: ConferenciaEmpresaDto[]
}

export interface GetConferenciasEmpresaError {
  type: typeof GET_CONFERENCIAS_EMPRESA_ERROR
  payload: boolean
}

export interface GetVideosConferencia {
  type: typeof GET_VIDEOS_CONFERENCIA
  payload: number
}

export interface GetVideosConferenciaSuccess {
  type: typeof GET_VIDEOS_CONFERENCIA_SUCCESS
  payload: VideoConferenciaDto[]
}

export interface GetVideosConferenciaError {
  type: typeof GET_VIDEOS_CONFERENCIA_ERROR
  payload: boolean
}

export interface FeriaState {
  fichaEmpresa: FichaEmpresaFeriaDto | null
  fichaEmpresaStates: StatusType
  listadoEmpresas: ListadoEmpresasDto | null
  listadoEmpresasStates: StatusType
  feria: FeriaDto | null
  feriaStates: StatusType
  conferencias: ConferenciaEmpresaDto[]
  conferenciasStates: StatusType
  conferenciasEmpresa: ConferenciaEmpresaDto[]
  conferenciasEmpresaStates: StatusType
  videosConferencia: VideoConferenciaDto[]
  videosConferenciaStates: StatusType
}

export const initialState: FeriaState = {
  fichaEmpresa: null,
  fichaEmpresaStates: status.initial,
  listadoEmpresas: null,
  listadoEmpresasStates: status.initial,
  feria: null,
  feriaStates: status.initial,
  conferencias: [],
  conferenciasStates: status.initial,
  conferenciasEmpresa: [],
  conferenciasEmpresaStates: status.initial,
  videosConferencia: [],
  videosConferenciaStates: status.initial,
}

export type FeriaActions =
  | GetFichaEmpresaFeria
  | GetFichaEmpresaFeriaSuccess
  | GetFichaEmpresaFeriaError
  | PostListadoEmpresasFeria
  | PostListadoEmpresasFeriaError
  | PostListadoEmpresasFeriaSuccess
  | GetFeria
  | GetFeriaError
  | GetFeriaSuccess
  | GetConferencias
  | GetConferenciasError
  | GetConferenciasSuccess
  | GetConferenciasEmpresa
  | GetConferenciasEmpresaError
  | GetConferenciasEmpresaSuccess
  | GetVideosConferencia
  | GetVideosConferenciaError
  | GetVideosConferenciaSuccess
