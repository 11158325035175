import { dateUtils } from '@navent-jobs/config'

interface DaysOfPublicationProps {
  fechaPublicacion: string
  fechaHoraPublicacion?: string
  section?: string
}

export const daysOfPublication = ({ fechaPublicacion, fechaHoraPublicacion, section }: DaysOfPublicationProps) => {
  // retorna día en number (1,2,3)
  const cantidadDeDiasAlterno = dateUtils.getTiempoPublicado(fechaPublicacion)
  const hasMyApplications = section === 'mis-postulaciones'

  if (cantidadDeDiasAlterno === 0) {
    if (hasMyApplications) {
      return 'Hoy'
    }
    if (fechaHoraPublicacion) {
      const horasOMinutos = dateUtils.getHoursOrMinutesText(fechaHoraPublicacion)
      return `Publicado hace ${horasOMinutos}`
    }
    return 'Publicado Hoy'
  }

  if (cantidadDeDiasAlterno === 1) {
    return `${hasMyApplications ? 'Ayer' : 'Publicado ayer'}`
  }

  if (cantidadDeDiasAlterno >= 15 && cantidadDeDiasAlterno <= 29) {
    return `${hasMyApplications ? 'Más de 15 días' : 'Publicado hace más de 15 días'}`
  }

  if (cantidadDeDiasAlterno >= 30) {
    return `${hasMyApplications ? 'Más de 30 días' : 'Publicado hace más de 30 días'}`
  }

  const textDayOfPublication = hasMyApplications
    ? `Hace ${cantidadDeDiasAlterno} días`
    : `Publicado hace ${cantidadDeDiasAlterno} días`

  return textDayOfPublication
}
