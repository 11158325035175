export const saveJobPosting = {
  empty: {
    subtitle: '¡Hacé una búsqueda y guardá las ofertas de empleo que más te interesen!',
  },
  cards: {
    postulaciones: {
      text: 'Si te postulás a un empleo favorito, vas a poder ver su estado y darle seguimiento desde',
    },
  },
}
