const listadoEmpresas = {
  counter: {
    text: 'empresas {{industria}} encontradas en {{ubicacion}}',
  },
  recommended: {
    title: 'No encontramos lo que estás buscando',
    loggedLabel: '¡Pero las siguientes empresas tienen avisos que pueden interesarte!',
    notLoggedLabel: '¡Mira todas las empresas que necesitan personal hoy!',
    subLabel: {
      part1: 'Inicia sesión',
      part2: ' o ',
      part3: 'Regístrate',
      part4: ' para ver mejores sugerencias',
    },
  },
  carousel: {
    title: 'Empresas más buscadas',
    recommendedTitle: 'Empresas recomendadas para ti',
  },
}

export default listadoEmpresas
