import React, { useEffect, useState } from 'react'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import Item from './Item'

// mixins
import { ContainerComponent, AccessLanding, AccessItems, Ul } from './mixins'

// constants
import { accessLandingItems } from '../../constants/header/access-bar/accessLandingItems'
import { accessItems } from '../../constants/header/access-bar/accessItems'
import { SITE_ID } from '../../constants'

const getAccessLandingItems = () => {
  return accessLandingItems[SITE_ID || 'BMAR']
}

const getAccessItems = () => {
  return accessItems[SITE_ID || 'BMAR']
}

interface ItemsAccessProps {
  href?: string
  content: string | React.ReactElement
  key: string
  icon?: string
  img?: string
  alt?: string
  visibleIn: string
  metrics?: () => void
}

const Component = () => {
  const { isMobile, isTabletPortrait } = useWindowSize()

  const [itemsLanding, setItemsLanding] = useState<ItemsAccessProps[]>([])
  const [itemsAccess, setItemsAccess] = useState<ItemsAccessProps[]>([])

  const device = isMobile || isTabletPortrait ? 'mobile' : 'desktop'

  useEffect(() => {
    setItemsLanding(getAccessLandingItems())
    setItemsAccess(getAccessItems())
  }, [])

  return (
    <ContainerComponent>
      {itemsLanding !== undefined && (
        <AccessLanding>
          <Ul>
            {itemsLanding &&
              itemsLanding.map((item: ItemsAccessProps) => {
                return item.visibleIn.includes(device) && <Item item={item} />
              })}
          </Ul>
        </AccessLanding>
      )}

      {!isMobile && !isTabletPortrait && itemsAccess !== undefined && (
        <AccessItems>
          <Ul>
            {itemsAccess &&
              itemsAccess.map((item: ItemsAccessProps) => {
                return item.visibleIn.includes(device) && <Item item={item} />
              })}
          </Ul>
        </AccessItems>
      )}
    </ContainerComponent>
  )
}

export default Component
