import {
  initialUpdateEmailState,
  UpdateEmailActions,
  UpdateEmailState,
  UPDATE_EMAIL_ACCOUNT,
  UPDATE_EMAIL_ACCOUNT_ERROR,
  UPDATE_EMAIL_ACCOUNT_SUCCESS,
} from '../../types/applicant/account/updateEmail'

const reducers = (state: UpdateEmailState = initialUpdateEmailState, action: UpdateEmailActions): UpdateEmailState => {
  switch (action.type) {
    case UPDATE_EMAIL_ACCOUNT:
      return {
        ...state,
        data: null,
        state: { loading: true, error: false, success: false },
      }
    case UPDATE_EMAIL_ACCOUNT_ERROR:
      return {
        ...state,
        state: { loading: false, error: true, success: false },
      }
      case UPDATE_EMAIL_ACCOUNT_SUCCESS:
        return {
          ...state,
          statusCode: action.payload.statusCode,
        state: { loading: false, error: false, success: true },
      }
    default:
      return state
  }
}

export default reducers
