import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholder = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const { withDescription } = props
  const height = withDescription ? 162 : 100

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth * 0.98}
        height={height}
        viewBox={`0 0 ${wrapperWidth} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="16" y="16" rx="4" ry="4" width="95" height="60" />
        <rect x="123" y="22" rx="4" ry="4" width="95" height="20" />
        <rect x="123" y="52" rx="4" ry="4" width="16" height="16" />
        <rect x="146" y="52" rx="4" ry="4" width="100" height="16" />
        <rect x={wrapperWidth * 0.575} y="52" rx="4" ry="4" width="20" height="20" />
        <rect x={wrapperWidth * 0.575} y="20" rx="4" ry="4" width="20" height="20" />
        <rect x={wrapperWidth * 0.575 + 28} y="54" rx="4" ry="4" width="152" height="16" />
        <rect x={wrapperWidth * 0.575 + 28} y="22" rx="4" ry="4" width="100" height="16" />
        {withDescription && (
          <>
            <rect x="16" y="92" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="114" rx="2" ry="2" width={wrapperWidth} height="12" />
            <rect x="16" y="136" rx="2" ry="2" width="432" height="12" />
          </>
        )}
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholder
