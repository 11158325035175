export const GET_LANGUAGE_TEST_RESULT = 'GET_LANGUAGE_TEST_RESULT'
export const GET_LANGUAGE_TEST_RESULT_SUCCESS = 'GET_LANGUAGE_TEST_RESULT_SUCCESS'
export const GET_LANGUAGE_TEST_RESULT_ERROR = 'GET_LANGUAGE_TEST_RESULT_ERROR'

export const SET_VISIBLE_RESULT = 'SET_VISIBLE_RESULT'
export const SET_VISIBLE_RESULT_SUCCESS = 'SET_VISIBLE_RESULT_SUCCESS'
export const SET_VISIBLE_RESULT_ERROR = 'SET_VISIBLE_RESULT_ERROR'

export interface GetLanguageTestResultDto {
  level: string
  status: string
  visible: boolean
  completedAt: string
}

export interface GetLanguageTest {
  provider: string
  language: string
}

export interface SetLanguageTestVisibility {
  value: boolean
  provider: string
  language: string
}

export interface SetVisibleResultDto {
  visible: boolean
}

export interface GetLanguageTestResult {
  type: typeof GET_LANGUAGE_TEST_RESULT
  payload: GetLanguageTest
}
export interface GetLanguageTestResultSuccess {
  type: typeof GET_LANGUAGE_TEST_RESULT_SUCCESS
  payload: GetLanguageTestResultDto
}
export interface GetLanguageTestResultError {
  type: typeof GET_LANGUAGE_TEST_RESULT_ERROR
  payload: null
}

export interface SetVisibleResult {
  type: typeof SET_VISIBLE_RESULT
  payload: SetLanguageTestVisibility
}
export interface SetVisibleResultSuccess {
  type: typeof SET_VISIBLE_RESULT_SUCCESS
  payload: SetVisibleResultDto
}
export interface SetVisibleResultError {
  type: typeof SET_VISIBLE_RESULT_ERROR
  payload: null
}

export interface LanguageTestState {
  level: string
  status: string
  visible: boolean | null
  completedAt: string
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: LanguageTestState = {
  level: '',
  status: '',
  visible: null,
  completedAt: '',
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type LanguageTestActions =
  | SetVisibleResult
  | SetVisibleResultSuccess
  | SetVisibleResultError
  | GetLanguageTestResult
  | GetLanguageTestResultSuccess
  | GetLanguageTestResultError
