import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/disponible-inmediato'
import {
  SWITCH_DISPONIBLE_INMEDIATO,
  SWITCH_DISPONIBLE_INMEDIATO_ERROR,
  SWITCH_DISPONIBLE_INMEDIATO_SUCCESS,
  SwitchDisponibleInmediato,
} from '../types/disponible-inmediato'

function* switchDisponibleInmediato({ payload }: SwitchDisponibleInmediato) {
  try {
    const resp: SwitchDisponibleInmediato = yield call(service.postDisponibleInmediato, payload)
    yield put({ type: SWITCH_DISPONIBLE_INMEDIATO_SUCCESS, payload: resp })
  } catch (error) {
    yield put({ type: SWITCH_DISPONIBLE_INMEDIATO_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* disponibleInmediato() {
  yield takeLatest<SwitchDisponibleInmediato>(SWITCH_DISPONIBLE_INMEDIATO, switchDisponibleInmediato)
}
