import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// style
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import {
  Background,
  ContentWrapper,
  PriceButtonWrapper,
  PriceContainer,
  PriceText,
  TaxText,
  Label,
  CustomButton,
  ButtonText,
} from './mixins'

// actions
import { getProduct } from '../../redux/actions/products'
import { DataLayerAnalysisCv } from '../../constants/gtm-events-cv-analysis'

const Component = ({
  productSku,
  mpOptions = {
    primaryAction: () => {},
    primaryLoading: false,
  },
  product,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const clickHandler = () => {
    DataLayerAnalysisCv.miguruPreviewCompra()
    mpOptions.primaryAction()
  }

  useEffect(() => {
    dispatch(getProduct(productSku))
  }, [])

  return (
    <Background>
      <ContentWrapper>
        <Label>{t('products.products.lablab.preview.lable')}</Label>
        <PriceButtonWrapper>
          <PriceContainer>
            <PriceText>
              {t(`currencyMask.symbol`)}
              {product?.unitPrice}
            </PriceText>
            <TaxText>{t('products.products.lablab.preview.impuestos')}</TaxText>
          </PriceContainer>
          <CustomButton onClick={clickHandler} buttonoptions={{ variant: 'primary', size: 'small' }}>
            {mpOptions.primaryLoading ? (
              <Loader />
            ) : (
              <ButtonText>{t('products.products.lablab.preview.cta')}</ButtonText>
            )}
          </CustomButton>
        </PriceButtonWrapper>
      </ContentWrapper>
    </Background>
  )
}

const states = ({ productsStore }) => ({
  product: productsStore?.producto,
})

export default connect(states)(Component)
