import { status } from '../types/status'
import {
  GET_APPLICANT_REVIEW,
  GET_APPLICANT_REVIEW_ERROR,
  GET_APPLICANT_REVIEW_SUCCESS,
  initialState,
  ReviewActions,
  ReviewState,
  SEND_REVIEW_ERROR,
  SEND_REVIEW_SUCCESS,
  SEND_REVIEW,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_ERROR,
  GET_REVIEWS_COMPANY,
  GET_REVIEWS_COMPANY_SUCCESS,
  GET_REVIEWS_COMPANY_ERROR,
  SEND_REVIEW_NO_EMPLOYEE,
} from '../types/review'

const reducers = (state: ReviewState = initialState, action: ReviewActions): ReviewState => {
  switch (action.type) {
    case GET_APPLICANT_REVIEW:
      return {
        ...state,
        states: status.loading,
      }
    case GET_APPLICANT_REVIEW_SUCCESS:
      return {
        ...state,
        applicantReview: action.payload,
        states: status.success,
      }
    case GET_APPLICANT_REVIEW_ERROR:
      return {
        ...state,
        states: status.error,
      }
    case SEND_REVIEW:
    case SEND_REVIEW_NO_EMPLOYEE:
      return {
        ...state,
        sendReviewStates: status.loading,
      }
    case SEND_REVIEW_SUCCESS:
      return {
        ...state,
        sendReviewStates: status.success,
      }
    case SEND_REVIEW_ERROR:
      return {
        ...state,
        sendReviewStates: status.error,
      }
    case GET_CATEGORIES:
      return {
        ...state,
        categoriesStates: status.loading,
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoriesStates: status.success,
      }
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        categoriesStates: status.error,
      }
    case GET_QUESTIONS:
      return {
        ...state,
        questionsStates: status.loading,
      }
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
        questionsStates: status.success,
      }
    case GET_QUESTIONS_ERROR:
      return {
        ...state,
        questionsStates: status.error,
      }
    case GET_REVIEWS_COMPANY:
      return {
        ...state,
        reviewsCompanyStates: status.loading,
      }
    case GET_REVIEWS_COMPANY_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        reviewsCompanyStates: status.success,
      }
    case GET_REVIEWS_COMPANY_ERROR:
      return {
        ...state,
        reviewsCompanyStates: status.error,
      }
    default:
      return state
  }
}

export default reducers
