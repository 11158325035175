export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export interface ShowModal {
  type: typeof SHOW_MODAL
  payload: string
}

export interface HideModal {
  type: typeof HIDE_MODAL
  payload: null
}

export interface ModalState {
  activeModal: string | null
}

export const initialState: ModalState = {
  activeModal: null,
}

export type ModalActions = ShowModal | HideModal
