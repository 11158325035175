import {
  GET_COMPANIES,
  GET_CARROUSEL_COMPANIES,
  GET_COMPANIES_RECOMMENDED,
  SET_FILTERS_APPLIED,
  SET_FILTERS_SETTINGS_COMPANIES,
  GetCompanies,
  GetCarrouselCompanies,
  GetCompaniesParams,
  FiltersAppliedType,
  SetFiltersApplied,
  SetFiltersSettingsCompanies,
  GetCompaniesRecommended,
} from '../types/listado-empresas'

export const getCompanies = (value: GetCompaniesParams): GetCompanies => {
  return {
    type: GET_COMPANIES,
    payload: value,
  }
}

export const getCarrouselCompanies = (): GetCarrouselCompanies => {
  return {
    type: GET_CARROUSEL_COMPANIES,
    payload: null,
  }
}

export const getCompaniesRecommended = (pageSize?: number): GetCompaniesRecommended => {
  return {
    type: GET_COMPANIES_RECOMMENDED,
    payload: { pageSize },
  }
}

export const setFilterSettingsCompanies = (): SetFiltersSettingsCompanies => {
  return {
    type: SET_FILTERS_SETTINGS_COMPANIES,
    payload: null,
  }
}

export const setFilterApplied = (value: FiltersAppliedType[]): SetFiltersApplied => {
  return {
    type: SET_FILTERS_APPLIED,
    payload: value,
  }
}
