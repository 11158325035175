import {
  GET_PRIVACY,
  GetPrivacy,
  SET_PRIVACY,
  SetPrivacy,
  SET_PRIVACY_RESET,
  SetPrivacyReset,
  PrivacyType,
} from '../../../types/applicant/account/privacy'

export const getPrivacy = (): GetPrivacy => {
  return {
    type: GET_PRIVACY,
    payload: null,
  }
}

export const setPrivacy = (data: PrivacyType): SetPrivacy => {
  return {
    type: SET_PRIVACY,
    payload: data,
  }
}

export const setPrivacyReset = (): SetPrivacyReset => {
  return {
    type: SET_PRIVACY_RESET,
    payload: null,
  }
}
