import React, { useEffect, useState } from 'react'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import Pagination from '@navent-jobs/ui-kit/components/pagination-fluid'

// utils
import { userLoggedIn } from '../../../../utils/cookies'

// components
import ListadoSinResultados from './components/empty-state'
import ListCompaniesEmptyState from '../../../../components/empty-state'
import CardCompany from './components/card-company'
import CardPlaceholder from './components/placeholder/card/CardPlaceholder'
import CardPlaceholderMobile from './components/placeholder/card/CardPlaceholderMobile'
import CardPlaceholderTablet from './components/placeholder/card/CardPlaceholderTablet'
import ProductBanner from '../../../../components/product-banner'
import RecommendedLabel from './components/recommended-label'

// mixins
import { DataLayerListadoEmpresas } from '../../../../constants/gtm-events-listado-empresas'

// constants
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'

const TOTAL_CARDS_PLACEHOLDER = 20

// features
const productBannersFeature = features[SITE_ID || 'BMAR'].reviews?.banner?.enabled

const showPlaceholders = (key, isMobile, isTabletPortrait, isTabletLandscape) => {
  if (isMobile) {
    return <CardPlaceholderMobile key={key} withDescription />
  }

  if (isTabletPortrait || isTabletLandscape) {
    return <CardPlaceholderTablet key={key} withDescription />
  }

  return <CardPlaceholder key={key} withDescription />
}

const showBanner = (index, length) => productBannersFeature && (length === 1 ? index === 0 : index === 1)

const ListadoEmpresas = ({ page, companies, companiesRecommended, pagination, loading, error, parentRoute }) => {
  const { isMobile, isTabletPortrait, isTabletLandscape } = useWindowSize()
  const [datalayerTrigger, setDatalayerTrigger] = useState(true)
  const isUserLoggedIn = userLoggedIn()

  const queryParams = new URLSearchParams(parentRoute.location.search)

  const showEmptyState = error || (!loading && !companies)
  const showDefaultEmptyState = showEmptyState && companiesRecommended?.length < 1

  useEffect(() => {
    if (companies && document) {
      document.documentElement.scrollTo(0, 0)
    }
  }, [companies])

  useEffect(() => {
    if (!loading && showEmptyState && datalayerTrigger) {
      DataLayerListadoEmpresas.searchWithoutResult(isUserLoggedIn)
      setDatalayerTrigger(false)
    }
  }, [showEmptyState])

  if (error || showDefaultEmptyState) {
    return <ListadoSinResultados />
  }

  if (loading) {
    return (
      <>
        {[...Array(TOTAL_CARDS_PLACEHOLDER)].map(i => {
          return showPlaceholders(i, isMobile, isTabletPortrait, isTabletLandscape)
        })}
      </>
    )
  }

  return (
    <>
      {showEmptyState ? (
        <>
          <ListCompaniesEmptyState />
          <RecommendedLabel isUserLoggedIn={isUserLoggedIn} />

          {companiesRecommended &&
            companiesRecommended.map(company => {
              return <CardCompany key={company.companyId} company={company} isUserLoggedIn={isUserLoggedIn} />
            })}
        </>
      ) : (
        <>
          {companies &&
            companies.map((company, index) => {
              return (
                <>
                  <CardCompany key={company.companyId} company={company} isUserLoggedIn={isUserLoggedIn} />
                  {showBanner(index, companies.length) && (
                    <ProductBanner
                      selectedProduct={'REVIEW'}
                      colSizeXl={isTabletPortrait || isTabletLandscape ? 6 : 9}
                      verticalSize="sm"
                    />
                  )}
                </>
              )
            })}

          <Pagination
            {...pagination}
            currentPage={page + 1}
            currentUrl={window.location.pathname}
            states={{ loading }}
            queryParams={queryParams}
          />
        </>
      )}
    </>
  )
}

export default ListadoEmpresas
