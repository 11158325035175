import React from 'react'

// Components
import { Toggle } from '@navent-jobs/ui-kit/components/toggle'
import { ToggleWrapper, ToggleLabel } from './mixins'

interface ToogleSuscriptionProps {
  suscriptionInfo
  suscriptionType: string
  onToogle: (status) => void
  state: boolean
}

const ToogleSuscription = ({ suscriptionInfo, suscriptionType, state, onToogle }: ToogleSuscriptionProps) => {
  return (
    <ToggleWrapper>
      <ToggleLabel>{suscriptionType === 'email' ? 'Por email' : 'Emergente'} </ToggleLabel>
      <Toggle
        disabled={!suscriptionInfo?.idCategoria}
        isChecked={state}
        onChange={e => onToogle(e.target.checked)}
        size="medium"
      />
    </ToggleWrapper>
  )
}

export default ToogleSuscription
