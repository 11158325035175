const ctaDownloadApp = {
  mobile: {
    title: '¡Ahora buscar trabajo es más fácil!',
    button: {
      dismin: 'Seguir en la web',
      accept: 'Entrar a la app',
      sidebar: 'Descarga la app en tu celular',
    },
  },
  desktop: {
    title: '¡Descarga la app en tu celular!',
  },
  store: {
    android: {
      name: 'Google Play',
      alt: 'Ir Google Play',
    },
    ios: {
      name: 'App Store',
      alt: 'Ir App Store',
    },
    huawei: {
      name: 'App Gallery',
      alt: 'Ir App Gallery',
    },
  },
}

export default ctaDownloadApp
