import { status, StatusType } from './status'

export const CONFIRM_REFERENCE = 'CONFIRM_REFERENCE'
export const CONFIRM_REFERENCE_SUCCESS = 'CONFIRM_REFERENCE_SUCCESS'
export const CONFIRM_REFERENCE_ERROR = 'CONFIRM_REFERENCE_ERROR'

export const UPDATE_REFERENCE_STATUS = 'UPDATE_REFERENCE_STATUS'
export const UPDATE_REFERENCE_STATUS_SUCCESS = 'UPDATE_REFERENCE_STATUS_SUCCESS'
export const UPDATE_REFERENCE_STATUS_ERROR = 'UPDATE_REFERENCE_STATUS_ERROR'

export interface ConfirmReferenceType {
  estado: string
  puesto: string
  institucion: string
  detalle: string
  relacion: string
  tipo: string
  referente: {
    nombre: string
    apellido: string
    email: string
    telefono: {
      prefijo: string
      numero: string
    }
  }
  referido: {
    nombre: string
    apellido: string
  }
}

// GET REFERENCE
export interface ConfirmReference {
  type: typeof CONFIRM_REFERENCE
  payload: {
    referenciaId: number
    postulanteId: number
  }
}

export interface ConfirmReferenceSuccess {
  type: typeof CONFIRM_REFERENCE_SUCCESS
  payload: ConfirmReferenceType | null
}

export interface ConfirmReferenceError {
  type: typeof CONFIRM_REFERENCE_ERROR
  payload: null
}

export interface ConfirmReferenceState {
  getReference: {
    value: ConfirmReferenceType | null
    status: StatusType
  }
  update: {
    status: StatusType
  }
}

export const initialState: ConfirmReferenceState = {
  getReference: {
    value: null,
    status: status.initial,
  },
  update: {
    status: status.initial,
  },
}

//  UPDATE REFERENCE
export interface UpdateReferenceStatusType {
  referenciaId: number
  postulanteId: number
  estado: string
  telefonoPrefijo: string
  telefonoNumero: string
}

export interface UpdateReferenceStatus {
  type: typeof UPDATE_REFERENCE_STATUS
  payload: UpdateReferenceStatusType
}

export interface UpdateReferenceStatusSuccess {
  type: typeof UPDATE_REFERENCE_STATUS_SUCCESS
  payload: null
}

export interface UpdateReferenceStatusError {
  type: typeof UPDATE_REFERENCE_STATUS_ERROR
  payload: null
}

export type ConfirmReferenceActions =
  | ConfirmReference
  | ConfirmReferenceSuccess
  | ConfirmReferenceError
  | UpdateReferenceStatus
  | UpdateReferenceStatusSuccess
  | UpdateReferenceStatusError
