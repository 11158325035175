import React from 'react'
import { Transition } from 'react-transition-group'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { AnimationFixed } from '@navent-jobs/ui-kit/components/animation'

// mixins
import { SidebarComponent, CloseButton, BackArrow } from './mixins'

const Sidebar = ({ position, children, visible, handleClose, closeType }) => {
  const animation = position === 'left' ? 'fadeInSlideLeft' : 'fadeInSlideRight'
  const { isDesktop, isTabletLandscape } = useWindowSize()
  const isDesktopOrTabletLandscape = isDesktop || isTabletLandscape

  return (
    <Transition in={visible} timeout={300}>
      {state => (
        <AnimationFixed
          name={animation}
          isDesktop={isDesktopOrTabletLandscape}
          position={position}
          className={`animation-${state}`}
          duration=".5"
        >
          <SidebarComponent closeType={closeType}>
            {closeType === 'close' && (
              <CloseButton type="button" onClick={handleClose}>
                <Icon name="icon-light-close" color="rgba(0, 0, 24, 0.84);" size="24" />
              </CloseButton>
            )}

            {(closeType === 'back' || closeType === 'backFilter') && (
              <BackArrow type="button" onClick={handleClose}>
                <Icon name="icon-light-arrow-left" color="rgba(0, 0, 24, 0.84);" size="24" />
              </BackArrow>
            )}

            {children}
          </SidebarComponent>
        </AnimationFixed>
      )}
    </Transition>
  )
}

export default Sidebar
