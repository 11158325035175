import React, { useEffect } from 'react'

// library
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { connect, useDispatch } from 'react-redux'

// components
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Textarea } from '@navent-jobs/ui-kit/components/textarea'

// assets
import { FormGroup, LabelInput } from '../../../mixins'
import { CustomSubmitButton } from '../mixins'
import { deleteAccount } from '../../../../../redux/actions/applicant/account/delete'
import { showSnackbar, showSnackbarError } from '../../../../../redux/actions/applicant/curriculum/snackbar'
import { isFilled } from '../../../../../constants/checkIsFilledInput'
import { logoutAction } from '../../../../../redux/actions/logout'

const MIN_CHARACTER_VALUE = 6

const TIME_AWAIT = 3000

const EliminarCuentaForm = ({ status, invalidPass, statusLogout }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { success, error, loading } = status

  // Inicializamos Hook Form
  const {
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    control,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = dataForm => {
    dispatch(deleteAccount({ data: dataForm }))
  }

  const goHome = () => {
    window.location.href = '/'
  }

  const actionSuccess = () => {
    dispatch(showSnackbar(t('account.deleteAccount.snackbarMessage.success'), 'icon-light-save'))
    reset({ password: '', motivo: '' })
    dispatch(logoutAction())
  }

  const actionInvalidPassword = () => {
    clearErrors('password')
    setError('password', {
      type: 'manual',
      message: t('account.deleteAccount.password.checkPassword'),
    })
  }

  useEffect(() => {
    if (success) {
      actionSuccess()
    } else if (error && invalidPass) {
      actionInvalidPassword()
    } else if (error && !invalidPass) {
      dispatch(showSnackbarError(t('account.deleteAccount.snackbarMessage.error')))
    }
  }, [status])

  useEffect(() => {
    if (statusLogout.success) {
      setTimeout(() => {
        goHome()
      }, TIME_AWAIT)
    } else if (statusLogout.error) {
      dispatch(showSnackbarError(t('account.deleteAccount.snackbarMessage.error')))
    }
  }, [statusLogout])

  const handleOnblur = (name, value) => {
    clearErrors(name)
    if (!isFilled(value)) {
      setError(name, {
        type: 'manual',
        message: t('account.deleteAccount.password.invalidPassword'),
      })
    } else if (value.length < MIN_CHARACTER_VALUE) {
      setError(name, {
        type: 'manual',
        message: t('account.deleteAccount.password.characterValidation'),
      })
    }
  }

  return (
    <form id="form-delete-account" onSubmit={handleSubmit(onSubmit)}>
      <ContainerFluid>
        <Row>
          <Col noGutter lg={6} md={6}>
            <FormGroup>
              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                render={({ onChange, value, name }) => (
                  <Input
                    name="password"
                    id="password"
                    type="password"
                    fieldOptions={{
                      label: <LabelInput>Contraseña</LabelInput>,
                      variant: 'darken',
                      maxLength: 50,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={() => handleOnblur(name, value)}
                    errors={{ ...errors }}
                    placeholder={t('account.deleteAccount.password.placeholder')}
                    rules={{ required: true }}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col noGutter lg={12} md={12}>
            <FormGroup>
              <Controller
                control={control}
                name="motivo"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Textarea
                    name="motivo"
                    value={value}
                    onChange={onChange}
                    id="reason-delete-account"
                    maxLength={1000}
                    fieldOptions={{
                      label: <LabelInput> {t('account.deleteAccount.reason.title')}</LabelInput>,
                      variant: 'darken',
                      allowOnlyText: true,
                    }}
                    placeholder={t('account.deleteAccount.reason.placeholder')}
                    rules={{ required: true }}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col noGutter>
            <CustomSubmitButton
              idForm="form-delete-account"
              isLoading={loading}
              disabled={!formState.isValid || loading}
            >
              {t('account.deleteAccount.buttonDelete')}
            </CustomSubmitButton>
          </Col>
        </Row>
      </ContainerFluid>
    </form>
  )
}

const states = ({ deleteAccountStore, logoutStore }) => {
  const { status, invalidPassword } = deleteAccountStore
  return { status, invalidPass: invalidPassword, statusLogout: logoutStore.status }
}

export default connect(states)(EliminarCuentaForm)
