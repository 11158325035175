import { SalarioType } from '../commons'

export const GET_PREFERENCIA_SALARIAL = 'GET_PREFERENCIA_SALARIAL'
export const GET_PREFERENCIA_SALARIAL_SUCCESS = 'GET_PREFERENCIA_SALARIAL_SUCCESS'
export const GET_PREFERENCIA_SALARIAL_ERROR = 'GET_PREFERENCIA_SALARIAL_ERROR'
export const UPDATE_PREFERENCIA_SALARIAL = 'UPDATE_PREFERENCIA_SALARIAL'
export const UPDATE_PREFERENCIA_SALARIAL_SUCCESS = 'UPDATE_PREFERENCIA_SALARIAL_SUCCESS'
export const UPDATE_PREFERENCIA_SALARIAL_ERROR = 'UPDATE_PREFERENCIA_SALARIAL_ERROR'
export const UPDATE_PREFERENCIA_SALARIAL_RESET = 'UPDATE_PREFERENCIA_SALARIAL_RESET'
export const DELETE_PREFERENCIA_SALARIAL = 'DELETE_PREFERENCIA_SALARIAL'
export const DELETE_PREFERENCIA_SALARIAL_SUCCESS = 'DELETE_PREFERENCIA_SALARIAL_SUCCESS'
export const DELETE_PREFERENCIA_SALARIAL_ERROR = 'DELETE_PREFERENCIA_SALARIAL_ERROR'
export const DELETE_PREFERENCIA_SALARIAL_RESET = 'DELETE_PREFERENCIA_SALARIAL_RESET'

// Get preferencia salarial
interface SalarioPreferencialRes {
  preferenciaDeSalario: number
}

export interface GetPreferenciaSalarial {
  type: typeof GET_PREFERENCIA_SALARIAL
  payload: null
}
export interface GetPreferenciaSalarialSuccess {
  type: typeof GET_PREFERENCIA_SALARIAL_SUCCESS
  payload: SalarioPreferencialRes
}
export interface GetPreferenciaSalarialError {
  type: typeof GET_PREFERENCIA_SALARIAL_ERROR
}

// Update preferencia salarial
export interface UpdatePreferenciaSalarial {
  type: typeof UPDATE_PREFERENCIA_SALARIAL
  payload: SalarioType
}
export interface UpdatePreferenciaSalarialSuccess {
  type: typeof UPDATE_PREFERENCIA_SALARIAL_SUCCESS
  payload: null
}
export interface UpdatePreferenciaSalarialError {
  type: typeof UPDATE_PREFERENCIA_SALARIAL_ERROR
}
export interface UpdatePreferenciaSalarialReset {
  type: typeof UPDATE_PREFERENCIA_SALARIAL_RESET
  payload: null
}

// Delete preferencia salarial
export interface DeletePreferenciaSalarial {
  type: typeof DELETE_PREFERENCIA_SALARIAL
  payload: null
}
export interface DeletePreferenciaSalarialSuccess {
  type: typeof DELETE_PREFERENCIA_SALARIAL_SUCCESS
  payload: null
}
export interface DeletePreferenciaSalarialError {
  type: typeof DELETE_PREFERENCIA_SALARIAL_ERROR
}
export interface DeletePreferenciaSalarialReset {
  type: typeof DELETE_PREFERENCIA_SALARIAL_RESET
  payload: null
}
