export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR'

export interface Location {
  value: string
  label: string
}

export interface LocationsState {
  locations: Location[]
  opened: boolean
  loading: boolean
  error: boolean
}

export const initialState: LocationsState = {
  locations: [],
  opened: false,
  loading: false,
  error: false,
}

export interface GetLocations {
  type: typeof GET_LOCATIONS
  payload: null
}

export interface GetLocationSuccess {
  type: typeof GET_LOCATIONS_SUCCESS
  payload: Location[]
}

export interface GetLocationsError {
  type: typeof GET_LOCATIONS_ERROR
  payload: boolean
}

export type LocationsActions = GetLocations | GetLocationSuccess | GetLocationsError
