const feria = {
  ficha: {
    enviarPostulado: '{{cv}} enviada',
  },
  info: {
    avisos: 'vacantes disponibles',
  },
  description: {
    imageTitle: 'Descubre nuestros tips para encontrar tu próximo trabajo.',
  },
  companies: {
    title: 'Las mejores empresas buscan tu talento.',
  },
}

export default feria
