import React from 'react'
import { ComponentContainer, CustomButton } from './mixins'

interface FormButtonsProps {
  idForm: string
  isLoading: boolean
  disabled: boolean
  children
  id
}

const Component = ({ id, idForm, isLoading, children, ...rest }: FormButtonsProps) => {
  return (
    <ComponentContainer>
      <CustomButton
        id={id}
        type="submit"
        form={idForm}
        buttonoptions={{ variant: 'primary', size: 'medium' }}
        {...rest}
        isLoading={isLoading}
      >
        {children}
      </CustomButton>
    </ComponentContainer>
  )
}

export default Component
