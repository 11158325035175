import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const CardComponentWrapper = styled('div')`
  background: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  }
`

export const LinkCustom = styled(Link)`
  display: block;
  position: relative;
  font-weight: normal;
  text-align: left;
  padding: 0;
  border: none;

  &:hover {
    text-decoration: none;
  }

  &:focus:not(:active) {
    .company-single-star:before {
      background-color: #fab306 !important;
    }
  }

  &:focus,
  &:active,
  &:hover:not(:active) {
    .company-active-searching i:before {
      background-color: ${({ theme }) => theme.colors.extra.normal} !important;
    }
  }
`

export const CardContainer = styled('div')`
  padding: 16px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    padding: 16px 24px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  justify-content: space-between;
`

export const CustomLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  margin-right: 24px;
`

export const SeguirLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.normal};
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
`

export const SeguirEmpresaContainer = styled.div`
  display: flex;
`
