export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Estado',
      localidadTitle: 'Ciudad',
    },
  },
  legales:
    'es responsable por la Base de Datos. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos personalmente o por correo postal a la dirección Real de Mayorazgo 130, Piso 9, Oficina 148, Xoco, Benito Juárez, 0330, Ciudad de México, México.',
  onboarding: {
    steps: {
      finalizar: {
        title: '¡Listo! Ya puedes empezar a postularte',
      },
    },
  },
}
