import styled from 'styled-components'

export const CTAComponent = styled('div')`
  margin: 34px auto 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    margin: 44px auto 10px;
  }
`

export const StoreBadgesContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    flex-direction: row;
  }
`

export const StoreBadgeContainer = styled('div')`
  margin: 12px 0 0;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    margin: 12px 0 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const StoreBadgeLink = styled('a')``
export const StoreBadgeImage = styled('img')``
