import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { Col, ContainerFluid, Row } from '@navent-jobs/ui-kit/components/grid'

// components
import { useTranslation } from 'react-i18next'
import EmptyState from './components/empty-state'
import InfoCards from './components/info-cards'
import ListadoEmpresasSeguidas from './components/listado'

// redux
import { getEmpresasSeguidas } from '../../../../redux/actions/save-company'
import { showSnackbar } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { postDeleteSeguirEmpresaReset } from '../../../../redux/actions/company-summary'

const EmpresasGuardadas = ({
  empresasSeguidas,
  pagination,
  loading,
  companySummaryStore,
  match,
  location,
  resetPageParam,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const pageParam = Number(new URLSearchParams(location.search).get('page'))
  const currentPage = pageParam === 0 ? 0 : pageParam - 1

  const getListadoEmpresasGuardadas = (pageNumber = 0) => {
    dispatch(getEmpresasSeguidas({ page: pageNumber }))
  }

  useEffect(() => {
    getListadoEmpresasGuardadas(Number(currentPage))
  }, [match.url, location.search])

  useEffect(() => {
    if (companySummaryStore?.siguiendoStates?.success && !companySummaryStore?.siguiendo) {
      // Mostrar snack y resetear estado para que no se vuelva a visualizar
      dispatch(showSnackbar(t('saveCompany.snackbar.dejarDeSeguir'), 'icon-light-trash-2'))
      if (currentPage === 0) getListadoEmpresasGuardadas()
      else resetPageParam()
      dispatch(postDeleteSeguirEmpresaReset())
    }
  }, [companySummaryStore?.siguiendoStates])

  return (
    <ContainerFluid>
      <Row>
        <Col noGutter>
          {!empresasSeguidas && !loading ? (
            <EmptyState />
          ) : (
            <ListadoEmpresasSeguidas
              empresasSeguidas={empresasSeguidas}
              pagination={pagination}
              loading={loading}
              currentPage={currentPage}
              queryParams={queryParams}
            />
          )}
        </Col>
      </Row>
      <Row>
        <InfoCards />
      </Row>
    </ContainerFluid>
  )
}

const states = ({ saveCompanyStore, companySummaryStore }) => ({
  empresasSeguidas: saveCompanyStore.empresasSeguidas,
  pagination: saveCompanyStore.pagination,
  loading: saveCompanyStore.states.loading,
  companySummaryStore,
})

export default connect(states)(EmpresasGuardadas)
