import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MainContainer } from '@navent-jobs/ui-kit/components/grid'
import Metas from '../../components/metas/save-job-posting'
import PageTitle from '../../components/PageTitle'
import { ContainerWithMaxWidth } from './mixins'
import AvisosGuardados from './components/save-job-posting'
import EmpresasGuardadas from './components/save-company'
import { TabEnum } from './constants'
import Tabs from '../../components/tabs'

const SavePage = ({ location, match }) => {
  const tabs = [
    {
      value: TabEnum.EMPRESAS,
      label: 'Empresas favoritas',
      hash: '#empresas-favoritas',
    },
    {
      value: TabEnum.AVISOS,
      label: 'Empleos favoritos',
      hash: '#empleos-favoritos',
    },
  ]
  const queryParams = new URLSearchParams(location.search)
  const activeObject = tabs.find(i => i.hash === location.hash)?.value || tabs[0].value
  const history = useHistory()

  const [activeTab, setActiveTab] = useState(activeObject)

  const resetPageParam = () => {
    queryParams.delete('page')
    history.replace({
      search: queryParams.toString(),
    })
  }

  const activateTab = selectedTabValue => {
    setActiveTab(selectedTabValue || tabs[0].value)
    resetPageParam()
  }

  return (
    <>
      <Metas />
      <PageTitle iconName="icon-bold-heart" title="Mis favoritos" maxWidth={870} />

      <MainContainer>
        <ContainerWithMaxWidth>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={activateTab} />
          {activeTab === TabEnum.EMPRESAS ? (
            <EmpresasGuardadas location={location} match={match} resetPageParam={resetPageParam} />
          ) : (
            <AvisosGuardados location={location} match={match} resetPageParam={resetPageParam} />
          )}
        </ContainerWithMaxWidth>
      </MainContainer>
    </>
  )
}

export default SavePage
