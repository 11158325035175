import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  REMOVE_ALERT,
  REMOVE_ALERT_SUCCESS,
  REMOVE_ALERT_ERROR,
  RemoveAlert,
} from '../types/applicant/notifications-alerts/alert-remove'
import {
  GET_ALERTS_SETTINGS,
  GET_ALERTS_SETTINGS_SUCCESS,
  GET_ALERTS_SETTINGS_ERROR,
} from '../types/applicant/notifications-alerts/alerts-settings'

import {
  EDIT_ALERTS,
  EDIT_ALERTS_SUCCESS,
  EDIT_ALERTS_ERROR,
  EditAlerts,
} from '../types/applicant/notifications-alerts/edit-alerts'

function* getAlertsSettings() {
  try {
    const result = yield call(services.alertsSettings.getAlerts)
    yield put({ type: GET_ALERTS_SETTINGS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_ALERTS_SETTINGS_ERROR, error })
  }
}

function* editAlertsSettings(data: EditAlerts) {
  try {
    yield call(services.alertsSettings.editAlerts, data)
    yield put({ type: EDIT_ALERTS_SUCCESS })
  } catch (error) {
    yield put({ type: EDIT_ALERTS_ERROR, error })
  }
}

function* deleteAlert({ payload }: RemoveAlert) {
  try {
    yield call(services.alertsSettings.deleteAlert, payload)
    yield put({ type: REMOVE_ALERT_SUCCESS, payload: null })
  } catch (error) {
    yield put({ type: REMOVE_ALERT_ERROR, payload: null })
  }
}
/**
 * Watchers
 */
export default function* alertsSettings() {
  yield takeLatest(GET_ALERTS_SETTINGS, getAlertsSettings)
  yield takeLatest(EDIT_ALERTS, editAlertsSettings)
  yield takeLatest(REMOVE_ALERT, deleteAlert)
}
