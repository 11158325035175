import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/payment-mp-service'
import {
  GET_LINK_PAGO,
  GET_LINK_PAGO_SUCCESS,
  GET_LINK_PAGO_ERROR,
  NOTIFICAR_PAGO_EXITOSO,
  NOTIFICAR_PAGO_EXITOSO_SUCCESS,
  NOTIFICAR_PAGO_EXITOSO_ERROR,
  NotificarPagoExitoso,
  GetLinkPago,
  GetLinkPagoSuccess,
  GetLinkPagoError,
  NotificarPagoExitosoSuccess,
  NotificarPagoExitosoError,
  GET_LINK_PAGOV2,
  GetLinkPagoV2,
} from '../types/payment-mp'

function* getLinkPago({ payload }: GetLinkPago) {
  try {
    const result = yield call(service.getMPLink, payload)
    yield put<GetLinkPagoSuccess>({ type: GET_LINK_PAGO_SUCCESS, payload: result })
  } catch (error) {
    yield put<GetLinkPagoError>({ type: GET_LINK_PAGO_ERROR, payload: null })
  }
}

function* getLinkPagoV2({ payload }: GetLinkPagoV2) {
  try {
    const result = yield call(service.getMPLinkV2, payload)
    yield put<GetLinkPagoSuccess>({ type: GET_LINK_PAGO_SUCCESS, payload: result })
  } catch (error) {
    yield put<GetLinkPagoError>({ type: GET_LINK_PAGO_ERROR, payload: null })
  }
}

function* notificarPagoExitoso({ payload }: NotificarPagoExitoso) {
  try {
    yield call(service.notificarPagoExitoso, payload)
    yield put<NotificarPagoExitosoSuccess>({ type: NOTIFICAR_PAGO_EXITOSO_SUCCESS, payload: null })
  } catch (error) {
    yield put<NotificarPagoExitosoError>({ type: NOTIFICAR_PAGO_EXITOSO_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* paymentMP() {
  yield takeLatest(GET_LINK_PAGO, getLinkPago)
  yield takeLatest(GET_LINK_PAGOV2, getLinkPagoV2)
  yield takeLatest(NOTIFICAR_PAGO_EXITOSO, notificarPagoExitoso)
}
