const changePassword = {
  'Te enviamos un email a ': 'Te enviamos un email a ',
  'Ingresa al link del email y elige una nueva contraseña': 'Ingresa al link del email y elige una nueva contraseña.',
  '¿No lo recibiste?': '¿No lo recibiste?',
  'Reenviarme el email': 'Reenviarme el email',
  'Ya te reenviamos el email': 'Ya te reenviamos el email',
  'Volver al inicio': 'Volver al inicio',
  'Cambiá tu contraseña': 'Cambia tu contraseña',
  'Elegí una nueva contraseña para ingresar a tu cuenta.': 'Elige una nueva contraseña para ingresar a tu cuenta.',
  'Nueva contraseña': 'Nueva contraseña',
  'Ingresá la contraseña': 'Ingresa la contraseña',
  'Repetir contraseña': 'Repetir contraseña',
  'Repetí la contraseña': 'Repite la contraseña',
  'Ingresá al menos 6 caracteres': 'Ingresa al menos 6 caracteres.',
  'Las contraseñas no coinciden': 'Las contraseñas no coinciden.',
  Aceptar: 'Aceptar',
  'Ingresa tu email y te enviaremos los pasos para elegir una nueva':
    'Ingresa tu email o usuario y te enviaremos los pasos para elegir una nueva.',
  'Ingresa tu email': 'Ingresa tu emailo usuario',
  'Ingresa un email válido': 'Ingresa un email válido.',
}

export default changePassword
