import {
  EDIT_ALERTS,
  EDIT_ALERTS_SUCCESS,
  EDIT_ALERTS_ERROR,
  EDIT_ALERTS_RESET,
  EditAlertsActions,
  EditAlertsState,
  initialEditAlertsState,
} from '../types/applicant/notifications-alerts/edit-alerts'

const reducers = (state: EditAlertsState = initialEditAlertsState, action: EditAlertsActions): EditAlertsState => {
  switch (action.type) {
    case EDIT_ALERTS:
      return {
        ...state,
        statusEdit: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case EDIT_ALERTS_SUCCESS:
      return {
        ...state,
        statusEdit: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case EDIT_ALERTS_ERROR:
      return {
        ...state,
        statusEdit: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case EDIT_ALERTS_RESET:
      return {
        ...state,
        statusEdit: {
          loading: false,
          success: false,
          error: false,
        },
      }
    default:
      return state
  }
}

export default reducers
