import ReadMore from '@navent-jobs/ui-kit/components/ReadMore'
import styled from 'styled-components'

export const Main = styled.div``

export const IndustryContainer = styled.div``

export const DataContainer = styled.div`
  margin-top: 16px;
`
export const DataLabel = styled.strong`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey100};
`

export const DataValue = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey48};
`

export const ReadMoreCustom = styled(ReadMore)``
