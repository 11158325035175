import React from 'react'

// Tools
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Select } from '@navent-jobs/ui-kit/components/select'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { SITE_ID } from '../../../../constants'
import { options } from './utils'
import imgEditAlertBm from '../../../../assets/images/edit-alert.svg'
import imgEditAlertZj from '../../../../assets/images/edit-alert-zj.svg'

// Utils
import { textSanitizer } from '../../../../utils/form-utils'

// Store
import { ModalEditAlertType } from '../../../../redux/types/alert-v2'
import { editAlertAction } from '../../../../redux/actions/alerts-v2'
import { OperationStatus } from '../../../../redux/types/applicant/commons'

// Styles
import {
  ModalBodyContainer,
  TitleModal,
  TitleBodyContainer,
  FooterContent,
  CustomButton,
  SelectWrapper,
  InputWrapper,
} from './mixins'

const imgEditAlert = SITE_ID === 'ZJAR' ? imgEditAlertZj : imgEditAlertBm

interface Props {
  visible: boolean
  alertInfo: ModalEditAlertType
  statusEdit: OperationStatus
  trackingTool: (id) => void
  onClose: () => void
}

const ModalEditAlert = ({ alertInfo, statusEdit, trackingTool, visible, onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // React Hook Form
  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      editarNombre: alertInfo?.nombre,
      editarFrecuencia: {
        value: alertInfo?.idFrecuencia,
        label: alertInfo?.labelFrecuencia,
      },
    },
  })

  const submitHandler = data => {
    const clearNombreAlerta = textSanitizer(data?.editarNombre)

    const bodyData = {
      id: alertInfo.id,
      idFrecuencia: data.editarFrecuencia.value,
      nombre: clearNombreAlerta,
    }
    trackingTool(data.editarFrecuencia.value)
    dispatch(editAlertAction(bodyData))
  }

  return (
    <Modal width={520} visible={visible} onClose={onClose} skin="with-info">
      <ContainerFluid>
        <Row>
          <Col>
            <ModalBodyContainer>
              <form id="form-editar-alerta" onSubmit={handleSubmit(submitHandler)}>
                {/* Titulo */}
                <Row>
                  <Col>
                    <TitleBodyContainer>
                      <img src={imgEditAlert} alt="Editar alerta" />
                      <TitleModal>{t('alertas.modalEditar.title')}</TitleModal>
                    </TitleBodyContainer>
                  </Col>
                </Row>

                {/* Nombre */}
                <Row>
                  <Col>
                    <Controller
                      name="editarNombre"
                      control={control}
                      rules={{
                        required: true,
                        validate: val => {
                          return !!val.trim()
                        },
                      }}
                      render={({ onChange, value }) => (
                        <InputWrapper>
                          <Input
                            id="editarNombre"
                            name="editarNombre"
                            type="text"
                            value={value}
                            rules={{ required: true }}
                            onChange={onChange}
                            placeholder={t('alertas.form.nombrePlaceholder')}
                            fieldOptions={{
                              variant: 'darken',
                              label: t('alertas.modalEditar.labelName'),
                              maxLength: 50,
                            }}
                          />
                        </InputWrapper>
                      )}
                    />
                  </Col>
                </Row>

                {/* Frecuencia */}
                <Row>
                  <Col>
                    <SelectWrapper>
                      <Select
                        name="editarFrecuencia"
                        placeholder={t('alertas.form.frecuenciaPlaceholder')}
                        options={options}
                        control={control}
                        rules={{ required: false }}
                        fieldOptions={{
                          variant: 'darken',
                          label: t('alertas.form.frecuenciaTitle'),
                        }}
                        reactSelectOptions={{ isClearable: false, isSearchable: false }}
                      />
                    </SelectWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FooterContent>
                      <CustomButton
                        buttonoptions={{ variant: 'primary', size: 'medium', type: 'link' }}
                        onClick={() => onClose()}
                      >
                        {t('alertas.modalEditar.ctaCancel')}
                      </CustomButton>

                      <CustomButton
                        buttonoptions={{ variant: 'primary', size: 'medium' }}
                        type="submit"
                        form="form-editar-alerta"
                        idForm="form-editar-alerta"
                        isLoading={statusEdit.loading}
                        disabled={!formState.isValid || statusEdit.loading}
                      >
                        {t('alertas.modalEditar.ctaSave')}
                      </CustomButton>
                    </FooterContent>
                  </Col>
                </Row>
              </form>
            </ModalBodyContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </Modal>
  )
}

const states = ({ modalStore }) => ({
  visible: modalStore.activeModal === 'modal-editar-alerta',
})

export default connect(states)(ModalEditAlert)
