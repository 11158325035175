import {
  CONFIRM_CANDIDATE_ACCOUNT,
  CONFIRM_CANDIDATE_ACCOUNT_SUCCESS,
  CONFIRM_CANDIDATE_ACCOUNT_ERROR,
  initialState,
  ConfirmCandidateActions,
  ConfirmCandidateState,
} from '../types/applicant/confirm-candidate'

const reducers = (
  state: ConfirmCandidateState = initialState,
  action: ConfirmCandidateActions
): ConfirmCandidateState => {
  switch (action.type) {
    case CONFIRM_CANDIDATE_ACCOUNT:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case CONFIRM_CANDIDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case CONFIRM_CANDIDATE_ACCOUNT_ERROR:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
