import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { CustomLabel, SeguirLabel } from '../../mixins'
import {
  dejarDeSeguirEmpresa,
  postDeleteSeguirEmpresaReset,
  seguirEmpresa,
} from '../../../../../redux/actions/company-summary'
import { showSnackbar, showSnackbarError } from '../../../../../redux/actions/applicant/curriculum/snackbar'
import { userLoggedIn } from '../../../../../utils/cookies'

interface SeguirEmpresaProps {
  empresaId: number
  siguiendo: boolean
  companySummaryStore: any
}

const SeguirEmpresa = ({ empresaId, siguiendo, companySummaryStore }: SeguirEmpresaProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isFollowing, setIsFollowing] = useState(siguiendo)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (companySummaryStore?.idEmpresa !== empresaId) {
      return
    }

    if (companySummaryStore?.siguiendoStates?.success) {
      if (isFollowing) {
        dispatch(showSnackbar(t('saveCompany.snackbar.dejarDeSeguir'), 'icon-light-trash-2'))
        setIsFollowing(false)
      } else {
        dispatch(showSnackbar(t('saveCompany.snackbar.seguir'), 'icon-light-heart'))
        setIsFollowing(true)
      }
      dispatch(postDeleteSeguirEmpresaReset())
    }

    if (companySummaryStore?.siguiendoStates?.error) {
      dispatch(showSnackbarError('Ocurrió un error inesperado'))
      dispatch(postDeleteSeguirEmpresaReset())
    }
  }, [companySummaryStore])

  const setLoading = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  const preventDefault = e => {
    e.preventDefault()
  }

  const onDejarDeSeguirEmpresa = e => {
    preventDefault(e)
    dispatch(dejarDeSeguirEmpresa(empresaId))
    setLoading()
  }

  const onSeguirEmpresa = e => {
    preventDefault(e)
    if (!userLoggedIn()) {
      history.push(`/login?returnTo=${window.location.pathname}`)
      window.scrollTo(0, 0)
      return
    }
    dispatch(seguirEmpresa(empresaId))
    setLoading()
  }

  return isFollowing ? (
    <>
      <CustomLabel>{t('saveCompany.button.siguiendo')}</CustomLabel>
      <div onClick={isLoading ? preventDefault : onDejarDeSeguirEmpresa}>
        <Icon name="icon-light-trash-2" size="20" color="#29293D" />
      </div>
    </>
  ) : (
    <SeguirLabel onClick={isLoading ? preventDefault : onSeguirEmpresa}>{t('saveCompany.button.seguir')}</SeguirLabel>
  )
}

const states = ({ companySummaryStore }) => ({
  companySummaryStore,
})

export default connect(states)(SeguirEmpresa)
