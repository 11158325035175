const filters = {
  filtros: {
    placeholderSearchFacet: {
      provincia: 'un Departamento',
      localidad: 'un Distrito',
    },
  },
}

export default filters
