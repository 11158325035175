export const seoFooterData = {
  BMAR: {
    title: 'Potenciá tu búsqueda',
    links: [
      { label: 'Empleos en Argentina', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos part-time', url: '/empleos-part-time.html' },
      { label: 'Pasantias', url: '/empleos-pasantia.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos en GBA Norte', url: '/empleos-busqueda-gba-norte.html' },
      { label: 'Empleos en GBA Sur', url: '/empleos-busqueda-gba-sur.html' },
      { label: 'Empleos en GBA Oeste', url: '/empleos-busqueda-gba-oeste.html' },
      {
        label: 'Empleos de Atencion al Cliente',
        url: '/empleos-busqueda-atencion-al-cliente.html',
      },
    ],
  },
  ZJAR: {
    title: 'Potenciá tu búsqueda',
    links: [
      { label: 'Empleos en Argentina', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos part-time', url: '/empleos-part-time.html' },
      { label: 'Pasantias', url: '/empleos-pasantia.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos en GBA Norte', url: '/empleos-busqueda-gba-norte.html' },
      { label: 'Empleos en GBA Sur', url: '/empleos-busqueda-gba-sur.html' },
      { label: 'Empleos en GBA Oeste', url: '/empleos-busqueda-gba-oeste.html' },
      {
        label: 'Empleos de Atencion al Cliente',
        url: '/empleos-busqueda-atencion-al-cliente.html',
      },
    ],
  },
  BMCL: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en Chile', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos Part-time', url: '/empleos-part-time.html' },
      { label: 'Empleos Por Contrato', url: '/empleos-por-contrato.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos de Abogado', url: '/empleos-busqueda-abogado.html' },
      { label: 'Empleos de Enfermera', url: '/empleos-busqueda-enfermera.html' },
      { label: 'Empleos de Call Center', url: '/empleos-busqueda-call-center.html' },
      { label: 'Empleos de Secretaria', url: '/empleos-busqueda-secretaria.html' },
    ],
  },
  BMEC: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en Ecuador', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos Part-time', url: '/empleos-part-time.html' },
      { label: 'Pasantias', url: '/empleos-pasantia.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos de Chofer', url: '/empleos-busqueda-chofer.html' },
      { label: 'Empleos de Ventas', url: '/empleos-busqueda-ventas.html' },
      { label: 'Empleos de Cajera', url: '/empleos-busqueda-cajera.html' },
      { label: 'Empleos de Cajero', url: '/empleos-busqueda-cajero.html' },
    ],
  },
  BMMX: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en México', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Tiempo Completo', url: '/empleos-tiempo-completo.html' },
      { label: 'Empleos Medio Tiempo', url: '/empleos-medio-tiempo.html' },
      { label: 'Empleos Por Contrato', url: '/empleos-por-contrato.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      {
        label: 'Empleos de Recursos Humanos',
        url: '/empleos-busqueda-recursos-humanos.html',
      },
      { label: 'Empleos de Arquitecto', url: '/empleos-busqueda-arquitecto.html' },
      {
        label: 'Empleos de Recepcionista',
        url: '/empleos-busqueda-recepcionista.html',
      },
      {
        label: 'Empleos de Auxiliar Administrativo',
        url: '/empleos-busqueda-auxiliar-administrativo.html',
      },
    ],
  },
  BMPA: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en Panamá', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos Part-time', url: '/empleos-part-time.html' },
      { label: 'Empleos Temporario', url: '/empleos-temporario.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      {
        label: 'Empleos de Ayudante General',
        url: '/empleos-busqueda-ayudante-general.html',
      },
      { label: 'Empleos de Chofer', url: '/empleos-busqueda-chofer.html' },
      { label: 'Empleos de Cajera', url: '/empleos-busqueda-cajera.html' },
      { label: 'Empleos de Gerente', url: '/empleos-busqueda-gerente.html' },
    ],
  },
  BMPE: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en Perú', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos Part-time', url: '/empleos-part-time.html' },
      { label: 'Pasantias', url: '/empleos-pasantia.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos de Practicante', url: '/empleos-busqueda-practicante.html' },
      {
        label: 'Empleos de Atención al Cliente',
        url: '/empleos-busqueda-atencion-al-cliente.html',
      },
      { label: 'Empleos de Asistente', url: '/empleos-busqueda-asistente.html' },
      { label: 'Empleos de Marketing', url: '/empleos-busqueda-marketing.html' },
    ],
  },
  BMVE: {
    title: 'Potencia tu búsqueda',
    links: [
      { label: 'Empleos en Venezuela', url: '/empleos.html' },
      { label: 'Avisos publicados hoy', url: '/empleos-publicacion-hoy.html' },
      { label: 'Empleos Full-time', url: '/empleos-full-time.html' },
      { label: 'Empleos Part-time', url: '/empleos-part-time.html' },
      { label: 'Empleos por Contrato', url: '/empleos-por-contrato.html' },
      {
        label: 'Senior / Semi-Senior',
        url: '/empleos-seniority-senior-semi-senior.html',
      },
      { label: 'Junior', url: '/empleos-seniority-junior.html' },
      {
        label: 'Jefe / Supervisor / Responsable',
        url: '/empleos-seniority-jefe-supervisor-responsable.html',
      },
      { label: 'Empleos de Chofer', url: '/empleos-busqueda-chofer.html' },
      {
        label: 'Empleos de Recursos Humanos',
        url: '/empleos-busqueda-recursos-humanos.html',
      },
      { label: 'Empleos de Abogado', url: '/empleos-busqueda-abogado.html' },
      { label: 'Empleos de Ventas', url: '/empleos-busqueda-ventas.html' },
    ],
  },
}
