import React, { useEffect, useState } from 'react'
import CtaDownloadApp from '../../CtaDownloadApp'
import Header from '../../HeaderSingle'
import Footer from '../../Footer'
import { GlobalStyles, Page } from './mixins'

const Component = props => {
  const { children, enableFooter = true, enableHeader = true, ...rest } = props
  const { pathname } = window.location
  const [showFooter, setShowFooter] = useState(enableFooter)

  // page donde no tiene que renderizar el footer
  useEffect(() => {
    if (pathname === '/semana') {
      setShowFooter(false)
    }
  }, [pathname])

  return (
    <>
      <GlobalStyles />
      <Page>
        {enableHeader && <Header {...rest} />}

        {children}

        <CtaDownloadApp type="mobile" />

        {showFooter && <Footer />}
      </Page>
    </>
  )
}

export default Component
