import React, { lazy, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'

// components
import Listado from './components/listado'
import CantidadEmpresas from './components/cantidad-empresas'
import Metas from './components/metas'
import { BusquedasRecientes } from './components/busquedas-recientes'
import FiltersSection from './components/filters'
import SearchBar from './components/search-bar'
import SectionCarousel from './components/section-carousel'
import SortEmpresas from './components/sort-empresas'

// mixins
import { MainPage, PageContent, CantidadEmpresasWithFilters, SidebarContainer } from './mixins'

// redux
import {
  getCompanies,
  getCarrouselCompanies,
  getCompaniesRecommended,
  setFilterSettingsCompanies,
} from '../../redux/actions/listado-empresas'

// services
import { makeTitleListado } from '../../services/meta-tags-listado'

// constants
import { IS_ZONA_JOBS, SITE_ID } from '../../constants'
import features from '../../constants/features-per-country'

// utils
import { getUrlParamByName } from '../../hooks/get-url-param'
import { DataLayerListadoEmpresas } from '../../constants/gtm-events-listado-empresas'
import { userLoggedIn } from '../../utils/cookies'

const LayoutAuthHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-auth-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const carrouselCompaniesEnabled = features[SITE_ID || 'BMAR']?.carrouselCompanies?.enabled
const sortEmpresas = features[SITE_ID || 'BMAR']?.sortEmpresas?.enabled

const onLoad = dispatch => {
  if (document) {
    document.title = makeTitleListado({})
    DataLayerListadoEmpresas.isLogged(userLoggedIn())
  }

  dispatch(setFilterSettingsCompanies())
  if (carrouselCompaniesEnabled) {
    dispatch(getCarrouselCompanies())
  }
}

const ListadoEmpresas = props => {
  const dispatch = useDispatch()

  const {
    location,
    match,
    companies,
    carrouselCompanies,
    carrouselRecommended,
    companiesRecommended,
    pagination,
    companiesStates,
    carrouselCompaniesStates,
    companiesRecommendedStates,
  } = props
  const { isMobile, isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize()

  const isMobileOrTabletPortrait = isMobile || isTabletPortrait
  const isDesktopOrTabletLandscape = isDesktop || isTabletLandscape
  const hideSectionCarousel = (isMobileOrTabletPortrait && !isEmpty(props.filtersApplied)) || IS_ZONA_JOBS

  const resultsFound = !(companiesStates.error || (!companiesStates.loading && !companies))
  const resultsLoading = companiesStates.loading || companiesRecommendedStates.loading

  // get params
  const pageParam = getUrlParamByName('page')
  const page = pageParam === null || Number(pageParam) === 0 ? 0 : Number(pageParam) - 1
  const ubicacionParam = getUrlParamByName('ubicacion')
  const ubicacion = ubicacionParam === null ? [] : [Number(ubicacionParam)]
  const industriaParam = getUrlParamByName('industria')
  const industria = industriaParam === null ? [] : [Number(industriaParam)]
  const queryParam = getUrlParamByName('query')
  const query = queryParam === null ? '' : queryParam
  const urlParams = new URLSearchParams(location.search)

  // conditionals show components
  const showCantidadEmpresas = isMobileOrTabletPortrait || (!isMobileOrTabletPortrait && !sortEmpresas)

  useEffect(() => {
    onLoad(dispatch)
  }, [])

  useEffect(() => {
    if (!companiesStates.loading) {
      dispatch(
        getCompanies({
          page,
          filtros: {
            query,
            industries: industria,
            provinces: ubicacion,
            sortByRating: !urlParams.get('empleos'),
          },
        })
      )
    }
  }, [match.url, location.search])

  useEffect(() => {
    if (companiesStates.success && !companies && !companiesRecommended) {
      dispatch(getCompaniesRecommended())
    }
  }, [companiesStates.success])

  return (
    <LayoutAuthHeader {...props}>
      <Suspense fallback={<PageLoader />}>
        <Metas />

        <PageContent>
          <MainPage>
            <MainContainer>
              {!hideSectionCarousel && (
                <SectionCarousel
                  companies={carrouselCompanies}
                  feature={carrouselCompaniesEnabled}
                  loading={carrouselCompaniesStates.loading}
                  isRecommended={carrouselRecommended}
                />
              )}

              <Row>
                {resultsFound ? (
                  <Col xs={12} lg={4} xl={3}>
                    <SearchBar loading={resultsLoading} location={location} />

                    {isDesktopOrTabletLandscape && (
                      <SidebarContainer>
                        <FiltersSection {...props} location={location} />
                      </SidebarContainer>
                    )}
                  </Col>
                ) : (
                  <BusquedasRecientes loading={resultsLoading} location={location} />
                )}

                <Col lg={{ size: 8, order: 2 }} xl={9}>
                  {resultsFound && showCantidadEmpresas && (
                    <CantidadEmpresasWithFilters sortEmpresas={sortEmpresas}>
                      <CantidadEmpresas />
                      {isMobileOrTabletPortrait && <FiltersSection {...props} location={location} />}
                    </CantidadEmpresasWithFilters>
                  )}

                  <SortEmpresas hideQuantity={!isMobileOrTabletPortrait} location={location} />

                  <Listado
                    parentRoute={{ location, match }}
                    companies={companies}
                    companiesRecommended={companiesRecommended}
                    pagination={pagination}
                    error={companiesStates.error || (!companies && companiesRecommendedStates.error)}
                    page={page}
                    loading={resultsLoading}
                  />
                </Col>
              </Row>
            </MainContainer>
          </MainPage>
        </PageContent>
      </Suspense>
    </LayoutAuthHeader>
  )
}

const mapStateToProps = ({ listadoEmpresasStore }) => ({
  carrouselCompanies: listadoEmpresasStore.carrouselCompanies,
  carrouselRecommended: listadoEmpresasStore.carrouselRecommended,
  companies: listadoEmpresasStore.companies,
  companiesRecommended: listadoEmpresasStore.companiesRecommended,
  filtersApplied: listadoEmpresasStore.filtersApplied,
  companiesStates: listadoEmpresasStore.companiesStates,
  carrouselCompaniesStates: listadoEmpresasStore.carrouselCompaniesStates,
  companiesRecommendedStates: listadoEmpresasStore.companiesRecommendedStates,
  pagination: listadoEmpresasStore.pagination,
})

export default connect(mapStateToProps)(ListadoEmpresas)
