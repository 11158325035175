import i18n from 'i18next'
import { get, formatNameToStringId } from '@navent-jobs/config'
import {
  formatFilterData as formatFilterDataV1,
  formatFilterDataV2,
  minAvisosEnFacetas,
  separateLocalidadFromProvincia,
} from '@navent-jobs/interlinking'
import { makeURL } from '../utils/search-avisos-v2'
import { seoFooterData } from '../constants/seo-footer-data'
import { SITE_ID } from '../constants'
import { empleosMasBuscados } from '../constants/empleos-mas-buscados'
import features from '../constants/features-per-country'

export interface Link {
  title: string
  links: { url: string; label: string }[]
}

const MIN_AVISOS_EN_FACET = minAvisosEnFacetas

const FILTROS_INCLUIDOS_EN_FOOTER = [
  'nivel_laboral',
  'provincia',
  'localidad',
  'distancia',
  'area',
  'subarea',
  'tipo_trabajo',
]

const empleosMasBuscadosFeature = features[SITE_ID || 'BMAR'].empleosMasBuscados.enabled
const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
const formatFilterData = searchAvisosV2Enabled ? formatFilterDataV2 : formatFilterDataV1

export class ErrorLocation extends Error {}

class SeoFooter {
  public formatLinksForFooter(data) {
    if (!data || !data.filters) return []
    const filtersApplied = formatFilterData(data.filtersApplied)
    Object.keys(filtersApplied).forEach(key => {
      filtersApplied[key] = formatNameToStringId(filtersApplied[key])
    })

    const filters = data.filters.filter(filter => FILTROS_INCLUIDOS_EN_FOOTER.includes(filter.type))
    const links: Link[] = []

    filters.forEach(filter => {
      const { type } = filter
      const link: Link = { title: i18n.t(`facets.${type}`), links: [] }

      filter.facets.forEach(facet => {
        let url

        if (type === 'localidad') {
          const { provincia } = data.urlData
          const localidad = searchAvisosV2Enabled
            ? facet.idSemantico
            : separateLocalidadFromProvincia(provincia, facet.id)
          url = makeURL({ ...filtersApplied, localidad, provincia })
        } else if (type === 'provincia') {
          url = makeURL({ ...filtersApplied, provincia: facet.idSemantico || facet.id })
        } else {
          const { urlData = {} } = data
          const { provincia, localidad } = urlData
          url = makeURL({ ...filtersApplied, [type]: facet.idSemantico || facet.id, localidad, provincia })
        }
        if (facet.quantity >= MIN_AVISOS_EN_FACET) link.links.push({ label: facet.name, url })
      })

      if (link.links.length) links.push(link)
    })

    return links
  }

  public addHardcodedLinks(data) {
    const seoFooter = this.formatLinksForFooter({ ...data })
    seoFooter.push(seoFooterData[SITE_ID || 'BMAR'])
    if (empleosMasBuscadosFeature) {
      seoFooter.push(empleosMasBuscados[SITE_ID || 'BMAR'])
    }
    return seoFooter
  }

  public async getFilters() {
    const endpoint = searchAvisosV2Enabled ? 'api/avisos/filtersV2' : 'api/avisos/filters'
    const data = await get(endpoint)
    data.filters = data.filters.filter(filter => filter.type !== 'tipo_trabajo')
    return data
  }
}

const seoFooter = new SeoFooter()
export default seoFooter
