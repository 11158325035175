import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import RadioButton from '@navent-jobs/ui-kit/components/RadioButton'
import Text from '@navent-jobs/ui-kit/components/text'

// style
import { FlexContainer } from '../../mixins'
import { ReviewsContainer, ReviewLabel, FormRow, Separador, Title, RadioButtonContainer } from './mixins'
import { CustomForm } from '../calificacion/mixins'
import { Asterisco } from '../form-header/mixins'

// components
import StarRating from '../selector'
import { StepButtons } from '../../../../components/StepButtons'
import { QuestionType } from '../../../../constants/question-type'
import { GENERAL_CATEGORY } from '../calificacion'

const Component = ({ questions, backProps, loading, submitHandler }) => {
  const { t } = useTranslation()
  const category = {
    id: 1,
    name: GENERAL_CATEGORY,
  }

  // Form
  const { control, formState, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <MainContainer>
          <Row>
            <Col>
              <FlexContainer>
                <CustomForm>
                  <ReviewsContainer>
                    <FormRow>
                      <ReviewLabel for={category.name}>
                        <Text size="lg" fontWeight="bold">
                          {'¿Cómo calificarías a la empresa?'}
                          <Asterisco>*</Asterisco>
                        </Text>
                      </ReviewLabel>
                      <Controller
                        name={category.name}
                        rules={{ required: true }}
                        control={control}
                        render={({ value, onChange }) => (
                          <StarRating name={category.name} value={value} onChange={onChange} setValue={setValue} />
                        )}
                      />
                    </FormRow>

                    {questions.map(item => {
                      return (
                        <>
                          <Separador />
                          <Title>
                            <Text size="md">{item.description}</Text>
                          </Title>
                          {item.questionTypeId === QuestionType.OPCION_MULTIPLE && item.options && (
                            <Controller
                              name={item.description}
                              rules={{ required: true }}
                              control={control}
                              render={({ onChange, value }) => {
                                return (
                                  <RadioButtonContainer>
                                    <RadioButton
                                      name={item.description}
                                      value={value}
                                      onChange={e => {
                                        onChange(+e.target.value)
                                        setValue(item.description, +e.target.value)
                                      }}
                                      fieldOptions={{
                                        size: 'medium',
                                        direction: 'column',
                                      }}
                                      options={item.options.map(opt => ({ id: opt.id, opcion: opt.option }))}
                                    />
                                  </RadioButtonContainer>
                                )
                              }}
                            />
                          )}
                        </>
                      )
                    })}
                  </ReviewsContainer>
                </CustomForm>
              </FlexContainer>
            </Col>
          </Row>
        </MainContainer>
      </form>

      <StepButtons
        buttonsOptions={{
          primaryButton: {
            isLoading: loading,
            isValid: formState.isValid && formState.isDirty,
            label: t('review.send'),
            action: () => handleSubmit(submitHandler)(),
          },
          ...backProps,
        }}
      />
    </>
  )
}

export default Component
