import React, { lazy, Suspense, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit & mixins
import { useForm } from 'react-hook-form'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Page, FormGroup, Text } from './mixins'

// components
import Heading from './components/Heading'
import FormButton from './components/FormButton'
import Image from './components/Image'
import Metas from '../../components/metas/forget-password'

import { sendEmailChangePassword, sendEmailChangePasswordReset } from '../../redux/actions/change-password'
import { ChangePasswordState } from '../../redux/types/change-password'
import { showSnackbarError } from '../../redux/actions/applicant/curriculum/snackbar'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const ForgetPassword = ({ emailStatus, invalidEmail, emailResponse }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [showColImage, setShowColImage] = useState(false)

  const tabletLandscapeMinWidth = 1024
  const preTabletLandscapeMinWidth = 992
  const TabletLandscapeToDesktop =
    window.outerWidth >= preTabletLandscapeMinWidth && window.outerWidth <= tabletLandscapeMinWidth
  const { isTabletLandscape, isDesktop } = useWindowSize()
  const enabledViewport = isTabletLandscape || isDesktop || TabletLandscapeToDesktop

  // Init React Hook Form
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    register,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const emailRules = {
    required: t('Ingresa un email válido'),
  }

  const onSubmit = data => {
    if (data.applicantEmail && isValid) {
      dispatch(
        sendEmailChangePassword({
          fetchMail: 'change-password',
          applicantMail: data.applicantEmail,
        })
      )
    }
  }

  useEffect(() => {
    setShowColImage(enabledViewport)
  }, [enabledViewport])

  useEffect(() => {
    if (emailStatus.success) {
      if (invalidEmail) {
        setError('applicantEmail', {
          type: 'manual',
          message: t('Ingresa un email válido'),
        })
      } else {
        history.replace('/postulantes/recuperar-contrasena/email-enviado', { user: email, email: emailResponse })
        dispatch(sendEmailChangePasswordReset())
      }
    } else if (emailStatus.error) {
      dispatch(showSnackbarError('Ha ocurrido un error'))
    }
  }, [emailStatus])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader backPage="/login">
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading />
                <form onSubmit={handleSubmit(onSubmit)} id="form-forget-password">
                  <Text>{t('Ingresa tu email y te enviaremos los pasos para elegir una nueva')}</Text>
                  <FormGroup>
                    <Input
                      id="applicantEmail"
                      name="applicantEmail"
                      type="text"
                      onChange={({ target: { value } }) => {
                        setEmail(value)
                      }}
                      rules={register(emailRules) as any}
                      placeholder={t('Ingresa tu email')}
                      fieldOptions={{
                        label: 'Email o usuario',
                        variant: 'darken',
                        maxLength: 50,
                        before: <Icon name="icon-light-person" color="#3D47F5" size="22" />,
                      }}
                      errors={{ ...errors }}
                    />

                    <FormButton
                      isLoading={emailStatus.loading}
                      disabled={!email || !isDirty || !isValid || emailStatus.loading}
                    />
                  </FormGroup>
                </form>
              </Col>

              {showColImage && (
                <Col lg={6} xl={4} offset={{ xl: 2 }}>
                  <Image />
                </Col>
              )}
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

const mapStateToProps = ({ changePasswordStore }: { changePasswordStore: ChangePasswordState }) => {
  return {
    emailStatus: changePasswordStore.sendMail.status,
    emailResponse: changePasswordStore.sendMail.value?.email,
    invalidEmail: changePasswordStore.sendMail.value?.invalidEmail ?? false,
  }
}

export default connect(mapStateToProps)(ForgetPassword)
