import { Request, Get, Post } from '@navent-jobs/config'
import { SendReviewParams } from '../redux/types/review'

export class ErrorReview extends Error {}

class Review {
  public getReviews = async (companyId: number) => {
    const request = new Request()
    request.path = `/api/candidates/companies/${companyId}/reviews`
    request.method = Get
    request.headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' }
    return request
      .call()
      .then(response => {
        return response[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorReview(`Calificacion no encontrada`)
        }
      })
  }

  public sendReview = async (params: SendReviewParams) => {
    const request = new Request()
    request.path = `/api/candidates/companies/${params.companyId}/reviews`
    request.method = Post
    request.body = params.body
    return request.call().catch(() => {
      throw new ErrorReview(`Error al publicar calificacion de un empleado`)
    })
  }

  public sendReviewNoEmployee = async (params: SendReviewParams) => {
    const request = new Request()
    request.path = `/api/candidates/companies/${params.companyId}/reviews/no-employee`
    request.method = Post
    request.body = params.body
    return request.call().catch(() => {
      throw new ErrorReview(`Error al publicar calificacion no siendo empleado`)
    })
  }

  public getCategories = async () => {
    const request = new Request()
    request.path = `/api/candidates/companies/reviews/categories`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorReview(`Categorias no encontradas`)
        }
      })
  }

  public getQuestions = async (isEmployee: boolean) => {
    const request = new Request()
    request.path = `/api/candidates/companies/reviews/questions?isEmployee=${isEmployee}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorReview(`Preguntas no encontradas`)
        }
      })
  }

  public getReviewsCompany = async (companyId: number) => {
    const request = new Request()
    request.path = `/api/candidates/companies/${companyId}/averages`
    request.method = Get
    request.headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' }
    return request
      .call()
      .then(response => {
        return response ? response : null
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorReview(`Calificacion no encontrada`)
        }
      })
  }
}

const review = new Review()
export default review
