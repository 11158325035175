import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

// components
import { Modal } from '@navent-jobs/ui-kit/components/modal'

// mixins
import { MainWrapper, ImgMiGuru, Body, Title, CustomButton, ButtonWrapper } from './mixins'

// images
import miguruImage from '../../../../../../assets/images/miguru/miguru-modal.svg'

// store
import { hideModal } from '../../../../../../redux/actions/modal'

// datalayer
import { DataLayerAnalysisCv } from '../../../../../../constants/gtm-events-cv-analysis'

interface ComponentProps {
  visible: boolean
  productName?: string
}

const Component = ({ visible, productName }: ComponentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const closeModal = () => {
    dispatch(hideModal())
  }

  const onSubmit = () => {
    if (productName === 'DOCTORCV') {
      DataLayerAnalysisCv.acceptUnableToBuy('Doctor CV')
    } else {
      DataLayerAnalysisCv.acceptUnableToBuy()
    }
    dispatch(hideModal())
    history.push('/candidatos/curriculum')
  }

  useEffect(() => {
    if (visible) {
      if (productName === 'DOCTORCV') {
        DataLayerAnalysisCv.loadModal('Completar CV', 'Doctor CV')
      } else {
        DataLayerAnalysisCv.loadModal('Completar CV')
      }
    }
  }, [visible])

  return (
    <Modal width={544} visible={visible} onClose={closeModal}>
      <MainWrapper>
        <ImgMiGuru src={miguruImage} alt="miguru" />

        <Title>{t('curriculum.marketplace.modalCvIncompleto.title')}</Title>

        <Body>
          {t('curriculum.marketplace.modalCvIncompleto.paragrapha')}
          <strong>{t('curriculum.marketplace.modalCvIncompleto.paragraphb')}</strong>
          {t('curriculum.marketplace.modalCvIncompleto.paragraphc')}
        </Body>

        <ButtonWrapper>
          <CustomButton
            buttonoptions={{ variant: 'primary', size: 'medium', type: 'fill', block: true }}
            onClick={onSubmit}
          >
            {t('curriculum.marketplace.modalCvIncompleto.buttonAccept')}
          </CustomButton>
        </ButtonWrapper>
      </MainWrapper>
    </Modal>
  )
}

const states = ({ modalStore }) => ({
  visible: modalStore.activeModal === 'modal-analysis-cv-unable-to-purchase',
})

export default connect(states)(Component)
