/* eslint-disable prettier/prettier */
import React from 'react'

// ui-kit
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'

// mixins
import {
  ProductBannerComponent,
  Div,
  Flexbox,
  ImageCustom,
  FlexboxTitle,
  Title,
  Claim,
  FlexboxButtons,
  LinkCustom,
  Cta,
  IconCustom,
  Container,
} from './mixins'

// images
import LogoBumeranBkgGrey from '../../../assets/images/product-banners/logo-bumeran-bkg-grey.svg'
import LogoBumeranBkgColor from '../../../assets/images/product-banners/logo-bumeran-bkg-color.svg'

// constans
import { SettingsBannerProducts } from '../../../constants/products/product-banners'
import { IS_ZONA_JOBS } from '../../../constants'

interface Typer {
  colSizeXl?: number
  verticalSize?: string
  productSetting: SettingsBannerProducts
}

const ProductBanner = ({ colSizeXl, verticalSize, productSetting }: Typer) => {
  const { isMobile } = useScreenSize()
  const { logo, alt, bumeranColor, title, titleMobile, claim, cta, background } = productSetting
  const divBackground = bumeranColor ? LogoBumeranBkgColor : LogoBumeranBkgGrey

  return (
    <ProductBannerComponent colSizeXl={colSizeXl} verticalSize={verticalSize} bkg={background}>
      <Div
        colSizeXl={colSizeXl}
        blendmode={bumeranColor ? '' : 'luminosity'}
        bkg={IS_ZONA_JOBS ? null : divBackground}
      />

      <Container colSizeXl={colSizeXl}>
        <Flexbox colSizeXl={colSizeXl} verticalSize={verticalSize}>
          {logo && (
            <ImageCustom
              colSizeXl={colSizeXl}
              verticalSize={verticalSize}
              src={logo}
              width="auto"
              height="32"
              alt={alt}
            />
          )}
          <FlexboxTitle colSizeXl={colSizeXl} withLogo={logo}>
            <Title colSizeXl={colSizeXl} verticalSize={verticalSize}>
              {isMobile && titleMobile ? titleMobile : title}
            </Title>
            <Claim colSizeXl={colSizeXl} verticalSize={verticalSize}>
              {claim}
            </Claim>
          </FlexboxTitle>
        </Flexbox>

        <FlexboxButtons colSizeXl={colSizeXl} verticalSize={verticalSize}>
          <LinkCustom
            linkoptions={{ size: 'small', type: 'outline' }}
            colSizeXl={colSizeXl}
            verticalSize={verticalSize}
          >
            <IconCustom sizeCol={colSizeXl} name="icon-light-arrow-right" size="22" color="#E90066" />
            <Cta colSizeXl={colSizeXl}>{cta}</Cta>
          </LinkCustom>
        </FlexboxButtons>
      </Container>
    </ProductBannerComponent>
  )
}

export default ProductBanner
