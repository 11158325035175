import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from '../../mixins'

const MyLoader = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={299}
        viewBox={`0 0 ${wrapperWidth} 299`}
        backgroundColor="#f3f3f3"
        foregroundColor="#d4d4d4"
        {...props}
      >
        <rect x="16" y="16" rx="4" ry="4" width="115" height="16" />
        <path d={`M 16 242 h ${wrapperWidth - 32} v 1 H 16 z`} />
        <rect x="16" y="42" rx="8" ry="8" width="90" height="56" />
        <rect x="114" y="48" rx="4" ry="4" width="309" height="16" />
        <rect x="114" y="76" rx="4" ry="4" width="231" height="16" />
        <rect x="16" y="114" rx="8" ry="8" width={wrapperWidth - 40} height="48" />
        <rect x="16" y="178" rx="8" ry="8" width={wrapperWidth - 40} height="48" />
        <rect x="16" y="265" rx="4" ry="4" width="20" height="20" />
        <rect x={`${wrapperWidth - 32}`} y="265" rx="4" ry="4" width="20" height="20" />
      </ContentLoader>
    </LoaderContainer>
  )
}
export default MyLoader
