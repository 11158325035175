import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomButton, CustomDisabledButton, Text, CustomLink } from './mixins'

interface FormButtonsProps {
  onSave: () => void
  isLoading: boolean
  sent: boolean
}

const Component = ({ onSave, isLoading, sent }: FormButtonsProps) => {
  const { t } = useTranslation()

  return (
    <ComponentContainer>
      <Text>{t('¿No lo recibiste?')}</Text>

      {sent ? (
        <CustomDisabledButton buttonoptions={{ size: 'medium', native: false }}>
          {t('Ya te reenviamos el email')}
        </CustomDisabledButton>
      ) : (
        <CustomButton
          disabled={isLoading}
          onClick={onSave}
          buttonoptions={{
            size: 'medium',
            variant: 'primary',
            type: 'outline',
            native: false,
          }}
          isLoading={isLoading}
        >
          {t('Reenviarme el email')}
        </CustomButton>
      )}
      <CustomLink
        href="/login"
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('Volver al inicio')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
