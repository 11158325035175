import { TagManager } from '@navent-jobs/config'

export const DataLayersPostPostulacion = {
  postLeadImpresion: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Aviso',
      eventAction: 'Modal PostLead',
      eventLabel: 'Impresion',
    })
  },
  postLeadClick: click => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Aviso',
      eventAction: 'Modal PostLead',
      eventLabel: `Click modal - ${click}`,
    })
  },
  relacionadosImpresion: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Aviso',
      eventAction: 'Modal - Avisos relacionados',
      eventLabel: 'Impresion',
    })
  },
  relacionadosClick: click => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Avisos recomendados',
      eventAction: 'Modal - Avisos relacionados',
      eventLabel: `Click modal - ${click}`,
      content_group: 'Aviso',
    })
  },
  responderPreguntas: path => {
    const from = path.includes('/postulantes/postulaciones') ? 'Mis postulaciones' : 'Aviso'
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: from,
      eventAction: 'Responder preguntas pendientes',
      eventLabel: `Click - Card preguntas pendientes`,
    })
  },
}
