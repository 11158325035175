import styled from 'styled-components'

export const Main = styled.div`
  margin: 16px 0px 0px;
`

export const DescriptiveHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-top: 16px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    justify-content: space-between;
    flex-direction: row;
  }
`

const MessageBasis = styled.h2`
  margin: 0px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  line-height: 18px;
  width: 100%;
`

export const VacanciesMessage = styled(MessageBasis)`
  font-size: 18px;
  font-weight: 600;
  white-space: pre-wrap;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    justify-content: flex-start;
  }
`

export const VacanciesMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const TotalText = styled.span`
  align-items: center;
  margin: 0;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.normal};
`
