const fichaAviso = {
  postularBar: {
    sueldoPretendido: 'Salario pretendido',
    ingresaSueldoPretendido: 'Ingresa tu salario bruto pretendido',
  },
  calculadoraSalario: {
    modal: {
      impuestos: ' el seguro social, seguro educativo y el impuesto sobre la renta.',
    },
  },
}

export default fichaAviso
