import { Request, Post, Get, Delete } from '@navent-jobs/config'
import features from '../constants/features-per-country'
import { SITE_ID } from '../constants'

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled

export class ErrorCompanySymmary extends Error {}

interface Filter {
  type: string
  facets: {
    id: string
    idSemantico?: string
  }
}

interface AvisosEmpresaRequest {
  empresaId: number
  page: number
  pageSize: number
  filters: Filter[]
}

class CompanySummary {
  public async getAvisosEmpresa({ empresaId, page, pageSize, filters }: AvisosEmpresaRequest) {
    const filtros = filters.map(filter => ({ id: filter.type, value: filter.facets.idSemantico || filter.facets.id }))

    const request = new Request()
    request.method = Post
    request.path = searchAvisosV2Enabled ? `api/avisos/searchV2` : `api/avisos/searchNormalizado`
    request.body = { empresaId, filtros, tipoDetalle: 'full' }
    request.params = { page, pageSize }

    return request.call().catch(() => {
      throw new ErrorCompanySymmary('Error obteniendo listado de avisos')
    })
  }

  public async getFichaEmpresa(empresaId: number) {
    const request = new Request()
    request.method = Get
    request.path = `/api/postulante/ficha-empresa/${empresaId}`

    return request.call().catch(() => {
      throw new ErrorCompanySymmary('Error obteniendo ficha empresa')
    })
  }

  public async seguirEmpresa(empresaId: number) {
    const request = new Request()
    request.method = Post
    request.path = `/api/postulante/empresa/${empresaId}/siguiendo`

    return request.call().catch(() => {
      throw new ErrorCompanySymmary('Error al seguir empresa')
    })
  }

  public async dejarDeSeguirEmpresa(empresaId: number) {
    const request = new Request()
    request.method = Delete
    request.path = `/api/postulante/empresa/${empresaId}/siguiendo`

    return request.call().catch(() => {
      throw new ErrorCompanySymmary('Error al dejar de seguir empresa')
    })
  }

  public async esSeguidor(empresaId: number) {
    const request = new Request()
    request.method = Get
    request.path = `/api/postulante/empresa/${empresaId}/siguiendo`

    return request.call().catch(() => {
      throw new ErrorCompanySymmary('Error al consultar si el postulante sigue a la empresa')
    })
  }
}

const companySummaryAvisos = new CompanySummary()
export default companySummaryAvisos
