import React from 'react'
import { useTranslation } from 'react-i18next'
import { Estado } from './mixins'

const EstadoPostulacion = ({ estado, totalComments }) => {
  const { t } = useTranslation()
  if (estado === 'recibido') {
    return <Estado>{t('postulaciones.estados.enviado')}</Estado>
  }

  if (estado === 'leido') {
    if (totalComments > 0) {
      return <Estado>{t('postulaciones.estados.contactado')}</Estado>
    }
    return <Estado>{t('postulaciones.estados.leido')}</Estado>
  }

  if (estado === 'finalizada') {
    return <Estado estado={estado}>Aviso Finalizado</Estado>
  }

  return <Estado>{estado}</Estado>
}

export default EstadoPostulacion
