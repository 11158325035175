export const paymentMP = {
  products: {
    nulinga: {
      success: {
        subtitle: '¡Felicitaciones! Ya recibimos tu pago para realizar el test de inglés de ',
        checkLabel: 'Acepto usar el mismo email con el que estoy registrado en Laborum para hacer el test de Inglés',
        buttonRef: 'https://nulinga.com/postulantes/new?token=MrG',
      },
      pending: {
        info: 'Recuerda usar el e-mail registrado en Laborum para hacer el test.',
      },
    },
  },
}
