import React, { Suspense, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { Background, Steps } from './mixins'

import Empresas from './components/empresas'
import Calificacion from './components/calificacion'
import Confirmacion from './components/confirmacion'
import Comentario from './components/comentario'
import Metas from './components/metas'

// redux
import { getFichaEmpresa } from '../../redux/actions/company-summary'
import LayoutSingleHeader from '../../components/layouts/layout-single-header'
import PageLoader from '../../components/PageLoader'
import { StepIndicator } from '../candidato/curriculum/components/Onboarding/StepIndicator/StepIndicator'
import { DataLayersReviews } from '../../constants/gtm-events-reviews'
import FormHeader from './components/form-header'

const allSteps = ['empresas', 'calificacion', 'comentario', 'confirmacion'] as const
const steps = ['calificacion', 'comentario', 'confirmacion'] as const
type Step = typeof allSteps[number]

const Component = ({ fichaEmpresa, errorFichaEmpresa }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const idCompany: number = parseInt(id, 10)

  const [currentStep, setCurrentStep] = useState<Step>('empresas')
  const [isEmployee, setIsEmployee] = useState(null)
  const [reviewData, setReviewData] = useState(null)
  const [commentData, setCommentData] = useState(null)

  const goTo = (step: Step) => {
    window.scrollTo(0, 0)
    setCurrentStep(step)
  }

  const stepColection = {
    empresas: (
      <Empresas
        successHandler={() => {
          goTo('calificacion')
        }}
      />
    ),
    calificacion: (
      <Calificacion
        isEmployee={isEmployee}
        reviewData={reviewData}
        companyId={fichaEmpresa?.id}
        nameCompany={fichaEmpresa?.denominacion}
        successHandler={data => {
          setReviewData(data)
          if (isEmployee) {
            goTo('comentario')
          } else {
            goTo('confirmacion')
          }
        }}
        showBack={currentStep !== 'empresas'}
        backHandler={() => {
          if (idCompany) {
            history.goBack()
          } else {
            setIsEmployee(null)
            setReviewData(null)
            goTo('empresas')
          }
        }}
      />
    ),
    comentario: (
      <Comentario
        commentData={commentData}
        reviewData={reviewData}
        companyId={fichaEmpresa?.id}
        successHandler={() => {
          goTo('confirmacion')
        }}
        backHandler={data => {
          setCommentData(data)
          goTo('calificacion')
        }}
      />
    ),
    confirmacion: <Confirmacion nameCompany={fichaEmpresa?.denominacion} />,
  }

  useEffect(() => {
    if (!Number.isNaN(idCompany)) {
      setCurrentStep('calificacion')
      dispatch(getFichaEmpresa(idCompany))
    }
  }, [idCompany])

  useEffect(() => {
    if (errorFichaEmpresa) {
      history.push('/404')
    }
  }, [errorFichaEmpresa])

  return (
    (fichaEmpresa || !idCompany) && (
      <LayoutSingleHeader
        enableFooter={false}
        action={() => DataLayersReviews.stepClick(currentStep, 'Cancelar', isEmployee)}
        showBackButton
      >
        <Suspense fallback={<PageLoader />}>
          <Background />
          <Metas step={currentStep} />
          <StepIndicator steps={!idCompany ? allSteps : steps} currentStep={currentStep} startOnFirstStep={false} />
          <FormHeader
            step={currentStep}
            nameCompany={currentStep !== 'empresas' ? fichaEmpresa?.denominacion : null}
            isEmployee={isEmployee}
            setIsEmployee={setIsEmployee}
          />
          <Steps>{stepColection[currentStep]}</Steps>
        </Suspense>
      </LayoutSingleHeader>
    )
  )
}

const mapStateToProps = ({ companySummaryStore }) => ({
  fichaEmpresa: companySummaryStore?.fichaEmpresa,
  errorFichaEmpresa: companySummaryStore?.fichaEmpresaStatus.error,
})

export default connect(mapStateToProps)(Component)
