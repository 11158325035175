import React from 'react'
// Tools
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { useTranslation } from 'react-i18next'
// Components
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'
import FailureImgBumSrc from '../../../../../assets/images/man-sending-email.svg'
import FailureImgZonaSrc from '../../../../../assets/images/man-sending-email-zj.svg'
// Constant
import { IS_ZONA_JOBS } from '../../../../../constants'
// Redux
import { ActivationEmailState } from '../../../../../redux/types/activation-email'
// Styles
import { Container, SidebarContentWrapper, SubTitleFailure, FailureImg, Title, Question, CustomButton } from '../mixins'

interface ModalQrValidateProps {
  nombreEmpresa?: string
  visible: boolean
  sended: ActivationEmailState['states']
  onClose: () => void
  onMailSending: () => void
}

interface ContentProps {
  Empresa?: string
  imgSrc: string
  sended: ActivationEmailState['states']
  onSendMail: () => void
}

const Content = ({ Empresa, imgSrc, sended, onSendMail }: ContentProps) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  return (
    <Container>
      <FailureImg src={imgSrc} alt="Failure qr image" />

      <Title>
        ¡{Empresa} {t('companySummary.qrModal.success.title')}!
      </Title>

      <SubTitleFailure>{t('companySummary.qrModal.failure.subTitle')}</SubTitleFailure>

      <Question>{t('companySummary.qrModal.failure.question')}</Question>

      <CustomButton
        onClick={onSendMail}
        buttonoptions={{
          variant: 'primary',
          size: 'medium',
          type: 'fill',
          block: isMobile,
        }}
        disabled={sended.loading || sended.success}
      >
        {sended.success && t('companySummary.qrModal.failure.ctaDisable')}
        {sended.loading && <Loader />}
        {!sended.success && !sended.loading && t('companySummary.qrModal.failure.ctaEnable')}
      </CustomButton>
    </Container>
  )
}

const ModalQrValidate = ({ nombreEmpresa, visible, onClose, sended, onMailSending }: ModalQrValidateProps) => {
  const { isMobile, isDesktop, isTabletLandscape, isTabletPortrait } = useWindowSize()
  return (
    <>
      {(isMobile || isTabletPortrait) && (
        <Sidebar position="left" closeType="close" visible={visible} handleClose={onClose}>
          <SidebarContentWrapper>
            <Content
              imgSrc={IS_ZONA_JOBS ? FailureImgZonaSrc : FailureImgBumSrc}
              Empresa={nombreEmpresa}
              sended={sended}
              onSendMail={onMailSending}
            />
          </SidebarContentWrapper>
        </Sidebar>
      )}

      {(isDesktop || isTabletLandscape) && (
        <Modal width={400} visible={visible} onClose={onClose}>
          <Content
            imgSrc={IS_ZONA_JOBS ? FailureImgZonaSrc : FailureImgBumSrc}
            Empresa={nombreEmpresa}
            sended={sended}
            onSendMail={onMailSending}
          />
        </Modal>
      )}
    </>
  )
}

export default ModalQrValidate
