import { ShowSnackbar, SHOW_SNACKBAR, HideSnackbar, HIDE_SNACKBAR } from '../../../types/applicant/curriculum/snackbar'

export const showSnackbar = (text: string, icon: string): ShowSnackbar => {
  return {
    type: SHOW_SNACKBAR,
    payload: { text, icon, error: false },
  }
}

export const showSnackbarError = (text: string): ShowSnackbar => {
  return {
    type: SHOW_SNACKBAR,
    payload: { text, icon: null, error: true },
  }
}

export const hideSnackbar = (): HideSnackbar => {
  return {
    type: HIDE_SNACKBAR,
    payload: null,
  }
}
