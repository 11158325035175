import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.secondary.lighten} 16.42%,
    ${({ theme }) => theme.colors.primary.lighten} 83.44%
  );
  min-height: 44px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    min-height: 64px;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  border-radius: 8px;
  background: white;
  padding: 16px;
  margin: 16px 0;
  justify-content: ${({ hasChildren }) => hasChildren && 'space-between'};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: column;
    padding: 24px;
    margin: 24px 0;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
    padding: 16px 24px;
    margin: 24px 0;
  }
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  flex-grow: 2;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-left: 8px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Page = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`
