const card = {
  match: {
    label: 'Tu {{cv}} se ajusta a esta oferta',
  },
  vacantes: {
    disponible: 'Vacante disponible',
    disponibles: 'Vacantes disponibles',
  },
}

export default card
