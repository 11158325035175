import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ComponentContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 194px;
`
