import React from 'react'
// Tools
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import moment from 'moment'

// Components
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// Tracking
import { DataLayer } from '../../../../../../constants/gtm-events-alertasv2'

// Utils
import { getFiltersList } from '../../utils'

// Styles
import {
  AlertTitle,
  AlertSection,
  AlertDescription,
  AlertDivider,
  TitleAlertContainer,
  IconsContainer,
  ActionEdit,
  ActionRemove,
  TextContainer,
  IconSection,
} from './mixins'
import { showModal } from '../../../../../../redux/actions/modal'
import { AlertState } from '../../../../../../redux/types/alert-v2'
import ToogleAlert from '../toogle-alert'

interface DisplayAlertProps {
  alertArray: AlertState['getAlert']
  onEdit: (elemento) => void
  onDelete: (id) => void
  onToogle: (status) => void
}

const DisplayAlert = ({ alertArray, onEdit, onDelete, onToogle }: DisplayAlertProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleRemoveAlert = (id: number) => {
    dispatch(showModal('modal-delete-alerta'))
    DataLayer.handlerConfigAlertTraking('eliminarAlerta')
    onDelete(id)
  }

  const handleEditAlert = elemento => {
    dispatch(showModal('modal-editar-alerta'))
    DataLayer.handlerConfigAlertTraking('editarAlerta')
    onEdit({
      id: elemento.id,
      idFrecuencia: elemento.idFrecuencia.id,
      labelFrecuencia: elemento.idFrecuencia.nombre,
      nombre: elemento.nombre,
    })
  }

  if (!alertArray.value) {
    return null
  }
  return (
    <>
      {alertArray.value.map((elemento, index: number) => {
        const { id, keyword, discapacidad, habilitado } = elemento
        const nombreAlerta = elemento.nombre
        const FechaAlerta = moment(elemento.created).format('DD-MM-YYYY')
        const nombreFrecuencia = elemento.idFrecuencia?.nombre
        const nombreArea = elemento.idArea?.nombre
        const nombreSubarea = elemento.idSubarea?.nombre
        const nombreProvincia = elemento.idProvincia?.nombre
        const nombreLocalidad = elemento.idLocalidad?.nombre
        const nombreNivelLaboral = elemento.idNivelLaboral?.nombre
        const nombreModalidadTrabajo = elemento.idModalidadTrabajo?.nombre
        const nombreTipoTrabajo = elemento.idTipoTrabajo?.nombre
        const showFilterSection =
          nombreArea ||
          nombreSubarea ||
          nombreNivelLaboral ||
          nombreModalidadTrabajo ||
          nombreTipoTrabajo ||
          discapacidad

        return (
          <AlertSection key={id}>
            <TitleAlertContainer>
              <TextContainer>
                <AlertTitle>{nombreAlerta}</AlertTitle>
                <AlertDescription>
                  <strong>{t('alertas.panel.label.created')}</strong>
                  {FechaAlerta}
                </AlertDescription>
                {keyword && (
                  <AlertDescription>
                    <strong>{t('alertas.panel.label.keyword')}</strong>
                    {keyword}
                  </AlertDescription>
                )}
                {showFilterSection && (
                  <AlertDescription>
                    <strong>{t('alertas.panel.label.filters')}</strong>
                    {getFiltersList(
                      nombreArea,
                      nombreSubarea,
                      nombreNivelLaboral,
                      nombreModalidadTrabajo,
                      nombreTipoTrabajo,
                      discapacidad
                    )}
                  </AlertDescription>
                )}
                {(nombreProvincia || nombreLocalidad) && (
                  <AlertDescription>
                    <strong>{t('alertas.panel.label.location')}</strong>
                    {nombreProvincia}
                    {nombreLocalidad && `, ${nombreLocalidad}`}
                  </AlertDescription>
                )}
                <AlertDescription>
                  <strong> {t('alertas.panel.label.frecuencie')}</strong>
                  {nombreFrecuencia.toLocaleLowerCase()}
                </AlertDescription>
              </TextContainer>
              <IconSection>
                {habilitado !== null && (
                  <ToogleAlert alertInfo={{ id: elemento.id, status: habilitado }} onToogle={onToogle} />
                )}
                <IconsContainer>
                  <ActionEdit onClick={() => handleEditAlert(elemento)}>
                    <Icon name="icon-light-edit-1" size="24" color="#231F20" />
                  </ActionEdit>
                  <ActionRemove onClick={() => handleRemoveAlert(id)}>
                    <Icon name="icon-light-trash-2" size="24" color="#231F20" />
                  </ActionRemove>
                </IconsContainer>
              </IconSection>
            </TitleAlertContainer>

            {index + 1 !== alertArray.value?.length && <AlertDivider />}
          </AlertSection>
        )
      })}
    </>
  )
}

export default DisplayAlert
