const postulaciones = {
  empty: {
    title: 'Por el momento no encontramos postulaciones para este filtro',
    subtitle: '¡Realizá una búsqueda y postulate a tu empleo ideal!',
    buttonSubmit: 'Buscar trabajos',
  },
  estados: {
    todos: 'Todos',
    activos: 'Avisos activos',
    enviado: 'CV enviado',
    leido: 'CV leído',
    enproceso: 'En proceso de selección',
    finalizado: 'Avisos finalizados',
    contactado: 'Contactado',
  },
  card: {
    salario: 'Sueldo pretendido: ',
  },
  search: {
    placeholder: 'Puesto, empresa o palabra clave',
    button: 'Buscar postulaciones',
  },
  preguntas: {
    title: 'Tenés preguntas sin responder',
    subtitle: 'Contestá las preguntas para terminar tu postulación',
  },
  seleccion_estado: {
    modal: {
      pregunta: 'Seleccioná el estado del proceso que mejor se ajuste en tu postulación',
    },
    estados: {
      NO_CONTACTADO: 'No me contactaron',
    },
    info: ' (Si lo seleccionás no podés cambiar el estado)',
  },
}

export default postulaciones
