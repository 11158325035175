export const GET_APPLICANT_UNREAD_NOTIFICATIONS = 'GET_APPLICANT_UNREAD_NOTIFICATIONS'
export const GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS'
export const GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR = 'GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR'

export const GET_APPLICANT_NOTIFICATIONS = 'GET_APPLICANT_NOTIFICATIONS'
export const GET_APPLICANT_NOTIFICATIONS_SUCCESS = 'GET_APPLICANT_NOTIFICATIONS_SUCCESS'
export const GET_APPLICANT_NOTIFICATIONS_ERROR = 'GET_APPLICANT_NOTIFICATIONS_ERROR'

export const READ_MARK_APPLICANT_NOTIFICATIONS = 'READ_MARK_APPLICANT_NOTIFICATIONS'

export const UPDATE_APPLICANT_FCM_TOKEN = 'UPDATE_APPLICANT_FCM_TOKEN'

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

// NotificationsList
export interface NotificationList {
  title: string
  text: string | null
  icon: string | null
  url: string | null
  showDate: boolean
  date: string
  data: any | null
  label: string | null
  external: boolean
  newTab: boolean
}

export interface GetApplicantNotifications {
  type: typeof GET_APPLICANT_NOTIFICATIONS
  payload: null
}

export interface GetApplicantNotificationsSuccess {
  type: typeof GET_APPLICANT_NOTIFICATIONS_SUCCESS
  payload: NotificationList[]
}
export interface GetApplicantNotificationsError {
  type: typeof GET_APPLICANT_NOTIFICATIONS_ERROR
  payload: null
}

// UnreadNotifications
export interface UnreadNotification {
  count: number
}

// Mark as read
export interface MarkAsReadNotifications {
  type: typeof READ_MARK_APPLICANT_NOTIFICATIONS
  payload: null
}

export interface UpdateFCMToken {
  type: typeof UPDATE_APPLICANT_FCM_TOKEN
  payload: null
}

export interface NotificationState {
  newNotifications: {
    count: UnreadNotification | null
    status: Status
  }

  notificationList: {
    data: NotificationList[]
    status: Status
  }

  markAsReadNotification: MarkAsReadNotifications | null
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export const initialState: NotificationState = {
  newNotifications: {
    count: null,
    status: initialStatus,
  },

  notificationList: {
    data: [],
    status: initialStatus,
  },

  markAsReadNotification: null,
}

export interface GetApplicantUnreadtNotifications {
  type: typeof GET_APPLICANT_UNREAD_NOTIFICATIONS
  payload: null
}

export interface GetApplicantUnreadNotificationsSuccess {
  type: typeof GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS
  payload: UnreadNotification
}
export interface GetApplicantUnreadNotificationsError {
  type: typeof GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR
  payload: null
}

export type NotificationActions =
  | GetApplicantUnreadtNotifications
  | GetApplicantUnreadNotificationsSuccess
  | GetApplicantUnreadNotificationsError
  | GetApplicantNotifications
  | GetApplicantNotificationsSuccess
  | GetApplicantNotificationsError
  | MarkAsReadNotifications
  | UpdateFCMToken
