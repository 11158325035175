import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const ContainerComponent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  &::before {
    content: '';
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.grey8};
    position: absolute;
    left: 0;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    padding-bottom: 10px;
    margin-top: 0px;

    &::before {
      border-top: none;
    }
  }
`

export const AccessLanding = styled('div')``
export const AccessItems = styled('div')``

export const Ul = styled('ul')`
  padding: 0px;
  margin: 20px 0px 58px 0px;
  list-style: none;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    margin: 0px;
  }
`

export const Li = styled('li')`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 12px;

  &:last-child {
    margin-right: 0px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
`

export const LinkCustom = styled(Link)`
  font-weight: normal;
  padding: 0px 0px 0px 8px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey60} !important;
`
