import { AddOrUpdateEstudioType, CertificadoEstudio, ReferenciaEstudio, SinEstudioEstudioType } from '../commons'

export const SIN_ESTUDIOS = 'SIN_ESTUDIOS'
export const SIN_ESTUDIOS_SUCCESS = 'SIN_ESTUDIOS_SUCCESS'
export const SIN_ESTUDIOS_ERROR = 'SIN_ESTUDIOS_ERROR'
export const SIN_ESTUDIOS_RESET = 'SIN_ESTUDIOS_RESET'

export const ADD_OR_UPDATE_ESTUDIO = 'ADD_OR_UPDATE_ESTUDIO'
export const ADD_OR_UPDATE_ESTUDIO_SUCCESS = 'ADD_OR_UPDATE_ESTUDIO_SUCCESS'
export const ADD_OR_UPDATE_ESTUDIO_ERROR = 'ADD_OR_UPDATE_ESTUDIO_ERROR'
export const ADD_OR_UPDATE_ESTUDIO_RESET = 'ADD_OR_UPDATE_ESTUDIO_RESET'

export const ADD_ESTUDIOS = 'ADD_ESTUDIOS'
export const ADD_ESTUDIOS_SUCCESS = 'ADD_ESTUDIOS_SUCCESS'
export const ADD_ESTUDIOS_ERROR = 'ADD_ESTUDIOS_ERROR'
export const ADD_ESTUDIOS_RESET = 'ADD_ESTUDIOS_RESET'

export const DELETE_ESTUDIO = 'DELETE_ESTUDIO'
export const DELETE_ESTUDIO_SUCCESS = 'DELETE_ESTUDIO_SUCCESS'
export const DELETE_ESTUDIO_ERROR = 'DELETE_ESTUDIO_ERROR'
export const DELETE_ESTUDIO_RESET = 'DELETE_ESTUDIO_RESET'

export const DELETE_REFERENCIA_ESTUDIO = 'DELETE_REFERENCIA_ESTUDIO'
export const DELETE_REFERENCIA_ESTUDIO_SUCCESS = 'DELETE_REFERENCIA_ESTUDIO_SUCCESS'
export const DELETE_REFERENCIA_ESTUDIO_ERROR = 'DELETE_REFERENCIA_ESTUDIO_ERROR'
export const DELETE_REFERENCIA_ESTUDIO_RESET = 'DELETE_REFERENCIA_ESTUDIO_RESET'

export const UPDATE_REFERENCIA_ESTUDIO = 'UPDATE_REFERENCIA_ESTUDIO'
export const UPDATE_REFERENCIA_ESTUDIO_SUCCESS = 'UPDATE_REFERENCIA_ESTUDIO_SUCCESS'
export const UPDATE_REFERENCIA_ESTUDIO_ERROR = 'UPDATE_REFERENCIA_ESTUDIO_ERROR'
export const UPDATE_REFERENCIA_ESTUDIO_RESET = 'UPDATE_REFERENCIA_ESTUDIO_RESET'

export const UPDATE_CERTIFICADO_ESTUDIO = 'UPDATE_CERTIFICADO_ESTUDIO'
export const UPDATE_CERTIFICADO_ESTUDIO_SUCCESS = 'UPDATE_CERTIFICADO_ESTUDIO_SUCCESS'
export const UPDATE_CERTIFICADO_ESTUDIO_ERROR = 'UPDATE_CERTIFICADO_ESTUDIO_ERROR'
export const UPDATE_CERTIFICADO_ESTUDIO_RESET = 'UPDATE_CERTIFICADO_ESTUDIO_RESET'

export const DELETE_CERTIFICADO_ESTUDIO = 'DELETE_CERTIFICADO_ESTUDIO'
export const DELETE_CERTIFICADO_ESTUDIO_SUCCESS = 'DELETE_CERTIFICADO_ESTUDIO_SUCCESS'
export const DELETE_CERTIFICADO_ESTUDIO_ERROR = 'DELETE_CERTIFICADO_ESTUDIO_ERROR'
export const DELETE_CERTIFICADO_ESTUDIO_RESET = 'DELETE_CERTIFICADO_ESTUDIO_RESET'

// Sin estudios
export interface SinEstudios {
  type: typeof SIN_ESTUDIOS
  payload: SinEstudioEstudioType
}
export interface SinEstudiosSuccess {
  type: typeof SIN_ESTUDIOS_SUCCESS
  payload: null
}
export interface SinEstudiosError {
  type: typeof SIN_ESTUDIOS_ERROR
}
export interface SinEstudiosReset {
  type: typeof SIN_ESTUDIOS_RESET
  payload: null
}

// Add or update estudio
export interface AddOrUpdateEstudio {
  type: typeof ADD_OR_UPDATE_ESTUDIO
  payload: AddOrUpdateEstudioType
}
export interface AddOrUpdateEstudioSuccess {
  type: typeof ADD_OR_UPDATE_ESTUDIO_SUCCESS
  payload: null
}
export interface AddOrUpdateEstudioError {
  type: typeof ADD_OR_UPDATE_ESTUDIO_ERROR
}
export interface AddOrUpdateEstudioReset {
  type: typeof ADD_OR_UPDATE_ESTUDIO_RESET
  payload: null
}

// Add estudios
export interface AddEstudios {
  type: typeof ADD_ESTUDIOS
  payload: AddOrUpdateEstudioType['estudio'][]
}
export interface AddEstudiosSuccess {
  type: typeof ADD_ESTUDIOS_SUCCESS
  payload: null
}
export interface AddEstudiosError {
  type: typeof ADD_ESTUDIOS_ERROR
}
export interface AddEstudiosReset {
  type: typeof ADD_ESTUDIOS_RESET
  payload: null
}

// Delete estudio
export interface DeleteEstudio {
  type: typeof DELETE_ESTUDIO
  payload: string
}
export interface DeleteEstudioSuccess {
  type: typeof DELETE_ESTUDIO_SUCCESS
  payload: null
}
export interface DeleteEstudioError {
  type: typeof DELETE_ESTUDIO_ERROR
  payload: null
}
export interface DeleteEstudioReset {
  type: typeof DELETE_ESTUDIO_RESET
  payload: null
}

// Delete referencia estudio
export interface DeleteReferenciaEstudio {
  type: typeof DELETE_REFERENCIA_ESTUDIO
  payload: string
}
export interface DeleteReferenciaEstudioSuccess {
  type: typeof DELETE_REFERENCIA_ESTUDIO_SUCCESS
  payload: null
}
export interface DeleteReferenciaEstudioError {
  type: typeof DELETE_REFERENCIA_ESTUDIO_ERROR
  payload: null
}

export interface DeleteReferenciaEstudioReset {
  type: typeof DELETE_REFERENCIA_ESTUDIO_RESET
  payload: null
}

// Update referencia estudio
export interface UpdateReferenciaEstudio {
  type: typeof UPDATE_REFERENCIA_ESTUDIO
  payload: ReferenciaEstudio
}
export interface UpdateReferenciaEstudioSuccess {
  type: typeof UPDATE_REFERENCIA_ESTUDIO_SUCCESS
  payload: null
}
export interface UpdateReferenciaEstudioError {
  type: typeof UPDATE_REFERENCIA_ESTUDIO_ERROR
  payload: null
}
export interface UpdateReferenciaEstudioReset {
  type: typeof UPDATE_REFERENCIA_ESTUDIO_RESET
  payload: null
}

// Update certificado estudio
export interface UpdateCertificadoEstudio {
  type: typeof UPDATE_CERTIFICADO_ESTUDIO
  payload: CertificadoEstudio
}
export interface UpdateCertificadoEstudioSuccess {
  type: typeof UPDATE_CERTIFICADO_ESTUDIO_SUCCESS
  payload: null
}
export interface UpdateCertificadoEstudioError {
  type: typeof UPDATE_CERTIFICADO_ESTUDIO_ERROR
  payload: null
}
export interface UpdateCertificadoEstudioReset {
  type: typeof UPDATE_CERTIFICADO_ESTUDIO_RESET
  payload: null
}

// Delete certificado estudio
export interface DeleteCertificadoEstudio {
  type: typeof DELETE_CERTIFICADO_ESTUDIO
  payload: string
}
export interface DeleteCertificadoEstudioSuccess {
  type: typeof DELETE_CERTIFICADO_ESTUDIO_SUCCESS
  payload: null
}
export interface DeleteCertificadoEstudioError {
  type: typeof DELETE_CERTIFICADO_ESTUDIO_ERROR
  payload: null
}

export interface DeleteCertificadoEstudioReset {
  type: typeof DELETE_CERTIFICADO_ESTUDIO_RESET
  payload: null
}
