export const UPDATE_PDA = 'UPDATE_PDA'
export const UPDATE_PDA_SUCCESS = 'UPDATE_PDA_SUCCESS'
export const UPDATE_PDA_ERROR = 'UPDATE_PDA_ERROR'
export const UPDATE_PDA_RESET = 'UPDATE_PDA_RESET'

export const GET_LINK_REPORTE = 'GET_LINK_REPORTE'
export const GET_LINK_REPORTE_SUCCESS = 'GET_LINK_REPORTE_SUCCESS'
export const GET_LINK_REPORTE_ERROR = 'GET_LINK_REPORTE_ERROR'
export const GET_LINK_REPORTE_RESET = 'GET_LINK_REPORTE_RESET'

// Update pda
export interface UpdatePda {
  type: typeof UPDATE_PDA
  payload: boolean
}
export interface UpdatePdaSuccess {
  type: typeof UPDATE_PDA_SUCCESS
  payload: null
}
export interface UpdatePdaError {
  type: typeof UPDATE_PDA_ERROR
  payload: null
}
export interface UpdatePdaReset {
  type: typeof UPDATE_PDA_RESET
  payload: null
}

// Get link reporte completo
export interface GetLinkReporte {
  type: typeof GET_LINK_REPORTE
  payload: string
}
export interface GetLinkReporteSuccess {
  type: typeof GET_LINK_REPORTE_SUCCESS
  payload: string
}
export interface GetLinkReporteError {
  type: typeof GET_LINK_REPORTE_ERROR
  payload: null
}
export interface GetLinkReporteReset {
  type: typeof GET_LINK_REPORTE_RESET
  payload: null
}
