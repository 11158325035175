import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { VALIDATE_EMAIL, VALIDATE_EMAIL_ERROR, VALIDATE_EMAIL_SUCCESS, ValidateEmail } from '../types/email'

function* validateEmail({ payload: emailUser }: ValidateEmail) {
  try {
    const result = yield call(services.emailValidation.ValidateEmail, emailUser)
    yield put({ type: VALIDATE_EMAIL_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: VALIDATE_EMAIL_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* email() {
  yield takeLatest<ValidateEmail>(VALIDATE_EMAIL, validateEmail)
}
