import {
  GET_RECOMENDADOS,
  GetRecomendados,
  GetRecomendadosParams,
  GET_RECOMENDADOS_LISTADO,
  GetRecomendadosListado,
  GetRecomendadosListadoParams,
  SetNotInterested,
  SetNotInterestedParams,
  SET_NOT_INTERESTED,
  SET_NOT_INTERESTED_RESET,
  GET_MOTIVOS_NO_INTERESADO,
  GetMotivosNoInteresado,
} from '../types/recomendados'

export const getRecomendados = (payload: GetRecomendadosParams): GetRecomendados => {
  return {
    type: GET_RECOMENDADOS,
    payload,
  }
}

export const getRecomendadosListado = (payload: GetRecomendadosListadoParams): GetRecomendadosListado => {
  return {
    type: GET_RECOMENDADOS_LISTADO,
    payload,
  }
}

export const getMotivosNoInteresado = (): GetMotivosNoInteresado => {
  return {
    type: GET_MOTIVOS_NO_INTERESADO,
    payload: null,
  }
}

export const setNotInterested = (payload: SetNotInterestedParams): SetNotInterested => {
  return {
    type: SET_NOT_INTERESTED,
    payload,
  }
}

export const setNotInterestedReset = () => {
  return {
    type: SET_NOT_INTERESTED_RESET,
  }
}
