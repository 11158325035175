import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { selectStyles } from '../utils/mixins'

export const KeywordFieldContainer = styled.div`
  ${({ theme }) => selectStyles(theme)}

  & > div:first-child > div:first-child {
    border: ${({ value, theme }) => `1px solid ${value ? theme.colors.secondary.normal : theme.colors.grey24}`};
  }

  #autocomplete-search-companies > .select__control {
    padding: 0;

    .select__value-container {
      div {
        width: 100% !important;
      }

      .select__input {
        input {
          width: 100% !important;
          opacity: 1 !important;
        }
      }
    }

    .select__single-value {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary.lighten};

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(0, 0, 24, 0.6);
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(0, 0, 24, 0.6);
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(0, 0, 24, 0.6);
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(0, 0, 24, 0.6);
      }
    }
  }
`

export const FieldIcon = styled(Icon)`
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translate(0, -50%);
`

export const RecentSearches = styled.ul`
  padding: 0px;
  margin: 0px;
  margin-top: 24px;
  list-style: none;
`

export const RecentSearchTitlte = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey48};
  margin-bottom: 12px;
`

export const RecentSearchesOption = styled.li`
  padding: 0px;
  margin: 0px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
`
