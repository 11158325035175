export const testPersonalidad = {
  stepInstructionsRules: [
    'No hay un límite de tiempo determinado.',
    'Es importante responder con sinceridad y en un ambiente tranquilo, sin interrupciones.',
    'Leé con atención las instrucciones antes de iniciar cada sección',
  ],
  stepOneDescription: [
    'Leé el siguiente listado y seleccioná todas las palabras que usarían',
    ' otras personas ',
    'para describirte.',
    'Elegí al menos 19.',
  ],
  stepTwoDescription: [
    'Leé el siguiente listado y seleccioná todas las palabras que',
    ' vos ',
    'usarías para describirte.',
    'Elegí al menos 19.',
  ],
  stepThreeDescription:
    'Ahora, describite utilizando tus propias palabras. Podés ocupar todo el espacio que necesites.',
  stepThreePlaceholder: 'Escribí una autodescripción',
  stepResultDescription:
    'El resultado quedó guardado en tu {{cv}}, desde donde podés decidir si querés que lo vean o no las empresas.',
  modalExitDescription:
    'Si salís, se borrará todo lo que hiciste hasta ahora y tendrás que empezar de nuevo la próxima vez.',
  stepConectionLostDescription: '¡Intentalo nuevamente!',
  stepConectionLostSkipTestDescription: 'Si salís, perderás las respuestas y tendrás que volver a hacer el test. ',
  stepInvalidResultDescription: '¡Intentalo nuevamente!',
  bannerPda: {
    incentivo: 'Realizá el test de personalidad y resaltá tus talentos frente a las empresas.',
    incentivoLink: 'Hacer el test',
    conocerMas: 'Conocé más',
    obtenerResultado: {
      descripcion: '¿Querés conocer en detalle tus aptitudes? Adquirí el informe completo de tu test de personalidad.',
      ctaObtener: 'Obtener informe completo',
    },
    descargar: {
      descripcion: 'Tenés disponible el informe completo de tu test de personalidad',
      ctaDescargar: 'Descargar informe completo',
    },
    modal: {
      title: 'Agregá tu número de documento en el {{cv}}',
      description: 'Necesitamos ese dato para efectuar la compra. Una vez agregado, volvé a intentarlo. ',
      ctaContinuar: 'Aceptar',
    },
  },
}
