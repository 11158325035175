import React from 'react'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

import { LinkJovenesProfesionales } from '../../../../components/Header/components/button-jovenes-profesionales'
import { Text } from '../mixins'
import { CrossSiteDataLayers } from '../../../gtm-events-cross-site'

const linksUserMenu = {
  options: (variant, type, target = '_self') => ({
    size: 'small',
    variant,
    type,
    native: false,
    target,
  }),
}

const allPortalsBM = ['BMAR', 'BMCL', 'BMEC', 'BMMX', 'BMPA', 'BMPE', 'BMVE']
const allPortals = [...allPortalsBM, 'ZJAR']

/**
 * Si un item no posee la prop 'href' por consecuencia se renderiza la prop 'content': string || react element
 * Si un item posee 'content' esta prop tiene más jerarquía que 'href'
 */

export const dataLink = {
  signIn: {
    href: '/signin',
    id: 'crearCuenta',
    content: 'Crear cuenta',
    onClick: e => {
      e.target.href = `/signin?returnTo=${window.location.pathname}`
    },
    key: 'crear-cuenta',
    visibleIn: ['mobile', 'desktop'],
    linkoptions: linksUserMenu.options('primary', 'outline'),
    metric: () => CrossSiteDataLayers.header('Singin'),
    portals: allPortals,
    width: '112px',
  },

  login: {
    href: '/login',
    id: 'ingresarNavBar',
    content: 'Ingresar',
    onClick: e => {
      e.target.href = `/login?returnTo=${window.location.pathname}`
    },
    key: 'ingresar',
    visibleIn: ['mobile', 'desktop'],
    linkoptions: linksUserMenu.options('primary', 'fill'),
    metric: () => CrossSiteDataLayers.header('Login'),
    portals: allPortals,
  },

  publicarGratis: {
    href: '/empresas/aviso/publicacion',
    content: 'Publicar gratis',
    key: 'publicar-gratis',
    visibleIn: ['desktop'],
    visibleInPage: ['/'],
    linkoptions: linksUserMenu.options('primary', 'link'),
    metric: () => CrossSiteDataLayers.header('Publicar Gratis'),
    portals: allPortals,
  },

  publicarGratisMobile: {
    href: '/empresas/aviso/publicacion',
    content: <Text>Publicar gratis</Text>,
    before: <Icon name="icon-light-paper-plane" />,
    key: 'publicar-gratis',
    visibleIn: ['mobile'],
    visibleInPage: ['/'],
    linkoptions: linksUserMenu.options('primary', 'link'),
    metric: () => CrossSiteDataLayers.header('Publicar Gratis'),
    portals: allPortals,
  },

  soyEmpresas: {
    href: '/empresas',
    content: 'Cuenta empresa',
    key: 'cuenta-empresa',
    visibleIn: ['mobile', 'desktop'],
    visibleInPage: ['/'],
    linkoptions: linksUserMenu.options('darken', 'link'),
    metric: () => CrossSiteDataLayers.header('Soy empresa'),
    portals: allPortals,
  },

  soyEmpresasMobile: {
    href: '/empresas',
    content: <Text>Cuenta empresa</Text>,
    before: <Icon name="icon-light-office" />,
    key: 'cuenta-empresa',
    visibleIn: ['mobile', 'desktop'],
    visibleInPage: ['/'],
    linkoptions: linksUserMenu.options('gray', 'link'),
    metric: () => CrossSiteDataLayers.header('Publicar Gratis'),
    portals: allPortals,
  },

  jovenesProfesionales: {
    href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
    content: <LinkJovenesProfesionales />,
    key: 'jovenes-profesionales',
    visibleIn: [],
    linkoptions: linksUserMenu.options('secondary', 'link'),
    metric: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    portals: allPortals,
  },
}
