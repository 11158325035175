import {
  GET_PRODUCT,
  GetProduct,
  GET_PRODUCT_AVAILABILITY,
  GetProductAvailability,
  SEND_DATA_FORM,
  SendDataForm,
  SendDataFormRequestDto,
  GET_PRODUCTS_STATUS,
  GET_PRODUCTS,
  GetProducts,
} from '../types/products'

export const getProduct = (value: string): GetProduct => {
  return {
    type: GET_PRODUCT,
    payload: value,
  }
}

export const getProductAvailability = (payload: string): GetProductAvailability => {
  return {
    type: GET_PRODUCT_AVAILABILITY,
    payload,
  }
}

export const sendDataForm = (payload: SendDataFormRequestDto): SendDataForm => {
  return {
    type: SEND_DATA_FORM,
    payload,
  }
}

export const getProductsStatus = () => {
  return {
    type: GET_PRODUCTS_STATUS,
  }
}

export const getProducts = (value: string[]): GetProducts => {
  return {
    type: GET_PRODUCTS,
    payload: value,
  }
}
