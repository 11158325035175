import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_ERROR, SignUp } from '../types/sign-up'

function* signUp({ payload }: SignUp) {
  try {
    const inUseEmail = yield call(services.applicant.emailVerify, payload.email)

    if (!inUseEmail) {
      yield put({ type: SIGN_UP_ERROR, payload: inUseEmail })
    } else {
      const result = yield call(services.auth.register, payload)
      yield put({ type: SIGN_UP_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: SIGN_UP_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest(SIGN_UP, signUp)
}
