import i18n from 'i18next'
import { SITE_ID } from '../../../constants'

const filters = {
  enabled: true,
  defaultFiltersApplied: [],
  deleteAll: true,
  filters: () => [
    {
      type: 'dias_fecha_publicacion',
      label: 'Fecha de publicación',
      order: 10,
      fixed: true,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-calendar',
    },
    {
      type: 'area',
      label: 'Área',
      order: 20,
      fixed: true,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-cube',
      options: {
        menuWidth: 'calc( 100% + 170px )',
      },
    },
    {
      type: 'subarea',
      label: 'Subárea',
      order: 30,
      fixed: true,
      fixedUntil: 1140,
      disabled: false,
      icon: 'icon-light-layers',
    },
    {
      type: 'provincia',
      label: i18n.t('filters-settings.provincia.label'),
      order: 40,
      fixed: true,
      fixedUntil: 1250,
      disabled: false,
      icon: 'icon-light-location-pin',
    },
    {
      type: 'localidad',
      label: i18n.t('filters-settings.localidad.label'),
      order: 50,
      fixed: true,
      fixedUntil: 3000,
      disabled: false,
      icon: 'icon-light-map',
    },
    {
      type: 'barrio',
      label: 'Barrio',
      order: 60,
      fixed: false,
      fixedUntil: null,
      disabled: true,
      icon: '',
    },
    {
      type: 'distancia',
      label: 'Distancia',
      order: 70,
      fixed: false,
      fixedUntil: null,
      disabled: true,
      icon: '',
    },
    {
      type: 'modalidad_trabajo',
      label: 'Modalidad de trabajo',
      order: 80,
      fixed: false,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-office',
    },
    {
      type: 'nivel_laboral',
      label: 'Nivel laboral',
      order: 90,
      fixed: false,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-award',
    },
    {
      type: 'tipo_trabajo',
      label: 'Carga horaria',
      order: 100,
      fixed: false,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-bookmark',
    },
    {
      type: 'salario',
      label: i18n.t('filters-settings.salario.label'),
      order: 110,
      fixed: false,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-money',
      options: {
        prefix: 'portal.currency',
      },
    },
    {
      type: 'conocimiento',
      label: 'Conocimiento',
      order: 120,
      fixed: false,
      fixedUntil: null,
      disabled: true,
      icon: '',
    },
    {
      type: 'empleo_joven',
      label: 'Empleo joven',
      order: 130,
      fixed: false,
      fixedUntil: null,
      disabled: false,
      icon: 'icon-light-graduation-cap',
    },
    {
      type: 'apto_discapacitado',
      label: 'Postulantes con discapacidad',
      order: 140,
      fixed: false,
      fixedUntil: null,
      options: { withCheckbox: true },
      disabled: false,
      icon: 'icon-light-discapacity',
    },
  ],
}

export default filters
