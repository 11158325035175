import React from 'react'

const profesiones = [
  'banista',
  'bano',
  'brunidor',
  'brunidora',
  'lenador',
  'lenadora',
  'disenador',
  'disenadora',
  'diseno',
  'lena',
  'albanil',
  'albanila',
  'ninero',
  'ninera',
  'vina',
  'vinador',
]

const transformString = (str): string => {
  // Funcion que pasa la primer letra del string a Mayuscula
  const string = str.split('-')
  let stringCambiado = str
  string.forEach(s => {
    //  Agrega la ñ
    profesiones.forEach(w => {
      if (s === w) {
        string[string.indexOf(s)] = w.replace('n', 'ñ')
        stringCambiado = string.join(' ')
      }
    })
  })
  return stringCambiado.charAt(0).toUpperCase() + stringCambiado.slice(1)
}

const getFiltersList = (area, subarea, nivelLaboral, modalidadTrabajo, tipoTrabajo, discapacidad) => {
  const filtersList: string[] = []

  const pushArrayFilter = filter => {
    if (filter) {
      filtersList.push(filter)
    }
  }

  pushArrayFilter(area)
  pushArrayFilter(subarea)
  pushArrayFilter(nivelLaboral)
  pushArrayFilter(modalidadTrabajo)
  pushArrayFilter(tipoTrabajo)

  if (discapacidad) {
    filtersList.push(`Postulante con discapacidad`)
  }
  return filtersList.map((filter, i: number) => {
    const key = i + 1
    return (
      filter && (
        <span key={key}>
          {filter}
          {i + 1 < filtersList.length && `,  `}
        </span>
      )
    )
  })
}

export { transformString, getFiltersList }
