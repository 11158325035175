/* eslint-disable global-require */
export const sitePhonePrefix = {
  1: '54',
  9: '593',
  11: '51',
  13: '58',
  18: '52',
  20: '507',
  1007: '56',
}
