import { Link } from '@navent-jobs/ui-kit/components/link'
import styled from 'styled-components'

export const Label = styled('p')`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const SubLabel = styled('p')`
  margin-bottom: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey84};
`

export const CustomLink = styled(Link)`
  font-weight: 600;
  padding: 0;
  border: 0;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary.normal};
`
