import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FilterScreen } from '../../../../../../components/filter'
import { SITE_ID } from '../../../../../../constants'
import settings from '../../../../../../constants/features-per-country'
import { FilterWithSettings } from '../../types'
import { mergeFiltersSettings } from '../../utils'
import { setFilterApplied } from '../../../../../../redux/actions/company-summary'

const FiltersMobile = ({ location, filters, filtersApplied }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [filterSelected, setFilterSelected] = useState<FilterWithSettings>()
  const queryParams = new URLSearchParams(location.search)
  const filtersSettings = {
    ...settings[`${SITE_ID}`].filtersPerfilEmpresa,
    filters: settings[`${SITE_ID}`].filtersPerfilEmpresa.filters(),
  }
  const file = mergeFiltersSettings(filters, filtersApplied, filtersSettings?.filters).map(asd => ({
    ...asd,
    optionSelected: filtersApplied.filter(e => (e.type || e.id) === asd.type)[0]?.facets
      ? [filtersApplied.filter(e => (e.type || e.id) === asd.type)[0].facets]
      : false,
  }))

  // set filters in the store
  const handleFilterApplied = (filterType, facet) => {
    let result

    if (facet) {
      const facetApplied = filters
        .find(item => item.type === filterType)
        ?.facets.find(f => (f.idSemantico || f.id) === facet.value)
      result = filtersApplied?.concat([{ type: filterType, facets: facetApplied }])
    } else if (filterType === 'provincia') {
      result = filtersApplied?.filter(f => f.type !== 'localidad' && f.type !== 'provincia')
    } else {
      result = filtersApplied.filter(f => f.type !== filterType)
    }

    dispatch(setFilterApplied(result))
    setFilterSelected(undefined)
  }

  const selectFilter = filter => {
    if (filter.type === filterSelected?.type) {
      setFilterSelected(undefined)
    } else {
      setFilterSelected(filter)
    }
  }

  const deletePageParam = () => {
    queryParams.delete('page')
    history.replace({
      search: queryParams.toString(),
    })
  }

  // set filters in the store and render into list
  const handleFacetSelected = (filterType, facetSelected) => {
    deletePageParam()
    handleFilterApplied(filterType, facetSelected)
  }

  // delete filters applied
  const handleResetFiltersApplied = () => {
    dispatch(setFilterApplied([]))
    history.replace({
      search: '',
    })
    setFilterSelected(undefined)
  }

  return (
    <FilterScreen
      filters={file}
      filtersApplied={filtersApplied}
      performFiltering={() => {}}
      selectFilter={selectFilter}
      filterSelected={filterSelected}
      metric={undefined}
      onSearch={handleFacetSelected}
      toggle={undefined}
      onRemoveAll={handleResetFiltersApplied}
      deleteAll={filtersApplied.length > 0}
    />
  )
}

export default FiltersMobile
