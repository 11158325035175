/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'

export interface SignUpRequestDto {
  nombre: string
  apellido: string
  email: string
  password: string
  suscripciones: boolean
  documento: string | null
  tipoDocumentoId: number | null
  celularPrefijo: string | null
  celularNumero: string | null
  proteccionDatos: boolean | null
  type: 'google' | 'linkedin' | null
  token: string | null
}

export interface SignUp {
  type: typeof SIGN_UP
  payload: SignUpRequestDto
}

export interface SignUpSuccess {
  type: typeof SIGN_UP_SUCCESS
  payload: null
}

export interface SignUpError {
  type: typeof SIGN_UP_ERROR
  payload: boolean
}

export interface SignUpState {
  data: {} | null
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: SignUpState = {
  data: {} || null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type SignUpActions = SignUp | SignUpSuccess | SignUpError
