import { put, call, takeLatest } from 'redux-saga/effects'
import { SITE_ID } from '../../constants'
import { empleosMasBuscados } from '../../constants/empleos-mas-buscados'
import features from '../../constants/features-per-country'
import services from '../../services'
import {
  GET_SEO_FOOTER,
  GET_SEO_FOOTER_FILTERED,
  GET_SEO_FOOTER_SUCCESS,
  GET_SEO_FOOTER_ERROR,
} from '../types/seo-footer'

const empleosMasBuscadosFeature = features[SITE_ID || 'BMAR'].empleosMasBuscados.enabled

function* getSeoFooterDefaultLinks() {
  try {
    const filters = yield call(services.seoFooter.getFilters)
    const links = services.seoFooter.addHardcodedLinks(filters)
    yield put({ type: GET_SEO_FOOTER_SUCCESS, payload: links })
  } catch (error) {
    yield put({ type: GET_SEO_FOOTER_ERROR, error })
  }
}

function* getSeoFooterFromFilters({ payload }) {
  try {
    const result = yield call(services.seoFooter.formatLinksForFooter, payload)
    if (empleosMasBuscadosFeature) {
      result.push(empleosMasBuscados[SITE_ID || 'BMAR'])
    }
    yield put({ type: GET_SEO_FOOTER_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_SEO_FOOTER_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* locations() {
  yield takeLatest<any>(GET_SEO_FOOTER, getSeoFooterDefaultLinks)
  yield takeLatest<any>(GET_SEO_FOOTER_FILTERED, getSeoFooterFromFilters)
}
