import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const ComponentContainer = styled('div')`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`

export const Text = styled('span')`
  margin-right: 5px;
`

export const CustomLink = styled(Link)`
  font-weight: 600;
  padding: 0;
`
