import React from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

import HorizontalLoaderMobile from './components/mobile'
import HorizontalLoaderTabletPortrait from './components/tablet-portrait'
import HorizontalLoaderTabletLandscape from './components/tablet-landscape'
import HorizontalLoaderDesktop from './components/desktop'

const HorizontalLoader = () => {
  const { isMobile, isTabletPortrait, isTabletLandscape } = useWindowSize()

  if (isMobile) {
    return <HorizontalLoaderMobile />
  }

  if (isTabletPortrait) {
    return <HorizontalLoaderTabletPortrait />
  }

  if (isTabletLandscape) {
    return <HorizontalLoaderTabletLandscape />
  }

  return <HorizontalLoaderDesktop />
}

export default HorizontalLoader
