import { Request, Post } from '@navent-jobs/config'
import { isEmpty } from 'lodash'
import i18n from 'i18next'
import { URLData, parseURL, parseURLV2 } from '@navent-jobs/interlinking'
import { parseURLGrails } from '../utils/parse-url-grails'
import { GetAvisosResponse } from '../redux/types/listado-avisos'
import { FilterApplied, FilterCore } from '../redux/types/filters'

interface Pageable {
  pageSize: number
  page: number
  sort?: string
}

interface FetchAvisos {
  listado?: any
  setLoading?: any
  setListado?: any
  setPage?: any
  sortParams: URLSearchParams
  page?: number
  pageSize?: number
  filtros?: { id: string; value: string }[]
  match?: {
    patch: string
    params: { idAviso: string; provincia: string; localidad: string; '0': string; filtros; masFiltros; empleo }
    url: string
  }
  internacional?: boolean
  setLoadingMore?: any
  afterFetch?: (params: { filters: any; urlData: URLData; filtersApplied: any; homeList: any }) => any
}

export class ErrorListadoAvisos extends Error {}

/**
 * Arma los filtros de la forma que espera el BFF
 */
const armarFiltros = (filtrosParaAplicar: URLData): { id: string; value: string }[] => {
  const filtros: { id: string; value: string }[] = []
  const keys = Object.keys(filtrosParaAplicar)
  keys.forEach(key => {
    const filtro = {
      id: key,
      value: filtrosParaAplicar[key],
    }
    filtros.push(filtro)
  })
  return filtros
}

const formatCurrencyValuesFromResultFilters = listadoFetch => {
  const thousandSeparator = i18n.t(`currencyMask.thousandsSeparatorSymbol`)
  const moneySymbol = i18n.t(`currencyMask.symbol`)

  const replaceCurrencySimbols = name => {
    if (!name) return
    return `${moneySymbol}${name.replace(/\D+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)}`
  }

  const formatCurrencyString = (name: string) => {
    const [lowValue, highValue] = name.split('-')
    if (!highValue) return `${name[0] !== '>' ? 'Menor a ' : 'Mayor a '} ${replaceCurrencySimbols(lowValue)}`
    return `De ${replaceCurrencySimbols(lowValue)} a ${replaceCurrencySimbols(highValue)}`
  }

  const formatAppliedCurrencyFilters = appFilters => {
    if (!appFilters || !appFilters.length) return
    const salaryFilter = appFilters.find(e => e.type === 'salario' && e.id !== '*-*')
    if (!salaryFilter) return
    salaryFilter.name = formatCurrencyString(salaryFilter.name)
  }

  const formatCurrencyFiltersToApply = filtersToApply => {
    if (!filtersToApply || !filtersToApply.length) return
    const salaryFilter = filtersToApply.find(e => e.type === 'salario')
    if (!salaryFilter || !salaryFilter.facets) return
    salaryFilter.facets = salaryFilter.facets.map(e => {
      if (e.id === '*-*') return e
      return {
        ...e,
        name: formatCurrencyString(e.name),
      }
    })
  }

  if (!listadoFetch) return

  const { filters, filtersApplied } = listadoFetch

  formatCurrencyFiltersToApply(filters)
  formatAppliedCurrencyFilters(filtersApplied)
}

/**
 * Traduce los nombres de las facetas que se muestran en la sección de filtros en el listado de avisos
 */
const traslateFacetNames = (fetchResult: { filters: FilterCore[]; filtersApplied: FilterApplied[] }) => {
  const { filters, filtersApplied } = fetchResult
  const facetTranslations: any = i18n.t('facetsName', { returnObjects: true })
  // because if no translation facetTraslations = string
  if (!(facetTranslations instanceof Object)) return

  const traslateFilterToApply = (facetType: string, filter?: FilterCore) => {
    if (!filter || !filter.facets) return
    filter.facets = filter.facets.map(facet => {
      let facetId = facet.idSemantico || facet.id
      if (facetType === 'subarea') {
        facetId = facet.idSemantico?.split('|')[1] || facet.id
      }
      if (!facetTranslations[facetType][facetId]) return facet
      return { ...facet, name: facetTranslations[facetType][facetId] }
    })
  }

  const traslateAppliedFilter = (facetType: string) => {
    if (!filtersApplied || !filtersApplied.length) return
    const facet = filtersApplied.find(e => e.type === facetType)
    if (!facet) return
    let facetId = facet.idSemantico || facet.id
    if (facetType === 'subarea') {
      facetId = facet.idSemantico?.split('|')[1] || facet.id
    }
    if (facetTranslations[facetType][facetId]) facet.name = facetTranslations[facetType][facetId]
  }

  if (filters) {
    Object.keys(facetTranslations).forEach(facetType => {
      const filter = filters.find(f => f.type === facetType)
      traslateFilterToApply(facetType, filter)
      traslateAppliedFilter(facetType)
    })
  }
}

const formatFiltersData = result => {
  formatCurrencyValuesFromResultFilters(result)
  traslateFacetNames(result)
  return result
}

const DEFAULT_PAGE_SIZE = 20

class ListadoAvisos {
  public async GetAvisos({
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    sortParams,
    filtros = [],
    match,
    internacional,
  }: FetchAvisos) {
    let sortBy

    if (sortParams.get('recientes') === 'true') {
      sortBy = 'fechaOnline DESC'
    }
    // Para soportar urls de Grails en React
    const urlData = match?.url.match(/(.*)\/ofertas-de-(.*)\.html/)
      ? parseURLGrails(match)
      : parseURL(window.location.href, match)
    const { busqueda: query, localidad, provincia, ...filtrosParaAplicar } = urlData
    const filtrosTotal = [...filtros, ...armarFiltros(filtrosParaAplicar)]

    const request = new Request()
    request.path = `api/avisos/searchNormalizado?pageSize=${pageSize}&page=${page}${sortBy ? `&sort=${sortBy}` : ``}`
    request.body = {
      filtros: filtrosTotal,
      busquedaExtendida: false,
      query,
      tipoDetalle: 'full',
      withHome: false,
      internacional,
    }
    request.method = Post
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorListadoAvisos(`Avisos no encontrados`)
        }
        const data = formatFiltersData(response)
        return { ...data, urlData }
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorListadoAvisos(`Avisos no encontrados`)
        }
      })
  }

  public async GetAvisosV2({
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    sortParams,
    match,
    internacional,
  }: FetchAvisos): Promise<GetAvisosResponse> {
    // Para soportar urls de Grails en React
    const urlData = match?.url.match(/(.*)\/ofertas-de-(.*)\.html/)
      ? parseURLGrails(match)
      : parseURLV2(window.location.href, match)

    const { busqueda: query, ...filtrosParaAplicar } = urlData
    const filtrosTotal = armarFiltros(filtrosParaAplicar)

    const sortBy = sortParams.get('recientes') === 'true' ? 'RECIENTES' : 'RELEVANTES'

    const params: Pageable = {
      pageSize,
      page,
    }
    if (sortBy) {
      params.sort = sortBy
    }

    const request = new Request()
    request.path = 'api/avisos/searchV2'
    request.params = params
    request.body = {
      filtros: filtrosTotal,
      query,
      internacional,
    }
    request.method = Post
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorListadoAvisos(`Avisos no encontrados`)
        }
        const data = formatFiltersData(response)
        return { ...data, urlData }
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorListadoAvisos(`Avisos no encontrados`)
        }
      })
  }
}

const listadoAvisos = new ListadoAvisos()
export default listadoAvisos
