import { Request, Get, Post } from '@navent-jobs/config'
import moment from 'moment'
import {
  ChatType,
  ComentarioType,
  EnviarMensajeType,
  LeerChatType,
  ListadoItemType,
  MarcarLeidoType,
} from '../redux/types/messages'

export class ErrorMessages extends Error {}

class Messages {
  public async getListadoMensajes(): Promise<ListadoItemType[]> {
    try {
      const request = new Request()
      request.method = Get
      request.path = '/api/mensajes'
      const resp = await request.call()
      return resp.content.map(i => ({
        id: i.id,
        tipo: i.tipo,
        postulacionId: i.tipo === 'postulacion' ? +i.postulacionId : null,
        tituloAviso: i.tipo === 'postulacion' ? i.titulo : null,
        nombreEmpresa: i.nombreEmpresa,
        logoEmpresa: i.logoEmpresaURL,
        leido: i.leido,
      }))
    } catch (e) {
      throw new ErrorMessages(`Error al obtener los mensajes`)
    }
  }

  public async leerChat({ id, tipo }: LeerChatType): Promise<ChatType> {
    try {
      const request = new Request()
      request.method = Get
      request.path = `/api/mensajes/${id}/${tipo}`
      const resp = await request.call()

      // Armamos los comentarios
      const comentarios: ComentarioType[] = resp.comentarios.reduce((previousValue: ComentarioType[], currentValue) => {
        const fechaString = currentValue.fecha.substring(0, 10)
        const fechaPreviaString = previousValue?.[previousValue.length - 1]?.fecha.substring(0, 10)
        const fechaPreviaDistinta = fechaPreviaString !== fechaString

        const fecha = moment(currentValue.fechaCompleta).toDate()
        previousValue.push({
          ...currentValue,
          avatarURL: currentValue.avatarURL,
          tipo: currentValue.tipoUsuario,
          fechaFormateada: fechaPreviaDistinta
            ? fecha.toLocaleDateString('es-es', { year: 'numeric', month: 'long', day: 'numeric' })
            : null,
          hora: fecha.toLocaleTimeString('es-es', { hour: '2-digit', minute: '2-digit' }),
        })
        return previousValue
      }, [])

      return {
        id,
        tipo,
        idMensaje: resp.id,
        idAviso: resp.aviso?.id ?? null,
        nombreEmpresa: resp.empresa.nombre,
        logoEmpresa: resp.empresa.logo,
        tituloAviso: resp.aviso?.titulo ?? null,
        leido: resp.leido,
        comentarios,
      }
    } catch {
      throw new ErrorMessages(`Error al leer el chat`)
    }
  }

  public enviarMensaje({ id, tipo, mensaje }: EnviarMensajeType) {
    const path = tipo === 'postulacion' ? `/api/mensajesPostulaciones/${id}` : `/api/mensajesDirectos/${id}`
    const request = new Request()
    request.method = Post
    request.path = path
    request.body = { mensaje }
    return request.call().catch(() => {
      throw new ErrorMessages(`Error al enviar mensaje`)
    })
  }

  public marcarLeido({ id }: MarcarLeidoType) {
    const request = new Request()
    request.method = Post
    request.path = `/api/mensajes/${id}/leido`
    return request.call().catch(() => {
      throw new ErrorMessages(`Error al marcar el mensaje como leido`)
    })
  }
}

const messages = new Messages()

export default messages
