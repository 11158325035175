import {
  DeleteAccount,
  DELETE_ACCOUNT,
  DeleteAccountType,
  DeleteAccountReset,
  DELETE_ACCOUNT_RESET,
} from '../../../types/applicant/account/deleteAccount'

export const deleteAccount = (data: DeleteAccountType): DeleteAccount => {
  return {
    type: DELETE_ACCOUNT,
    payload: data,
  }
}
export const deleteAccountReset = (): DeleteAccountReset => {
  return {
    type: DELETE_ACCOUNT_RESET,
    payload: null,
  }
}
