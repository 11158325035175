const modalDenuncia = {
  avisoDuplicado: 'Ingresá el enlace',
  textArea: 'Contanos que pasó',
  titles: {
    1: '¿Por qué querés denunciar como <strong>ofensivo y/o discriminatorio</strong> este aviso?',
    2: '¿Por qué querés denunciar como <strong>prácticas fraudulentas</strong> este aviso?',
    3: '¿Por qué sospechás que este aviso contiene <strong>actividades ilegales</strong>?',
    4: '¿Por qué querés denunciar como <strong>impreciso o con datos incorrectos</strong> este aviso?',
    5: '¿Por qué querés denunciar este aviso como <strong>duplicado</strong>?',
    6: '¿Por qué querés denunciar este aviso?',
  },
}

export default modalDenuncia
