import {
  GET_ALERTS_SETTINGS,
  GET_ALERTS_SETTINGS_SUCCESS,
  GET_ALERTS_SETTINGS_ERROR,
  AlertsSettingsActions,
  initialAlertsState,
  AlertsState,
} from '../types/applicant/notifications-alerts/alerts-settings'

const reducers = (state: AlertsState = initialAlertsState, action: AlertsSettingsActions): AlertsState => {
  switch (action.type) {
    case GET_ALERTS_SETTINGS:
      return {
        ...state,
        data: null,
        status: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_ALERTS_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_ALERTS_SETTINGS_ERROR:
      return {
        ...state,
        data: null,
        status: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
