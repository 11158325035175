export const mergeFiltersSettings = (filters, filtersApplied, filterSettings) => {
  return filterSettings
    .map(setting => {
      const filter = filters ? filters.filter(e => (e.type || e.id) === setting.type) : []
      const optionSelected = filtersApplied ? filtersApplied.filter(e => (e.type || e.id) === setting.type) : []

      return {
        ...setting,
        ...filter[0],
        optionSelected: optionSelected.length > 0 ? optionSelected : false,
        showFilter: filter.length > 0 || optionSelected.length > 0 || setting.fixed,
      }
    })
    .filter(e => !e.disabled && e.showFilter)
    .sort((a: { order: number }, b: { order: number }): number => {
      if (a.order > b.order) {
        return 1
      }
      if (a.order < b.order) {
        return -1
      }
      return 0
    })
}
