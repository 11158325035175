import React from 'react'
import { CustomIframe, Spacer, TextContainer } from './mixins'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import { connect } from 'react-redux'

function getVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

const SectionOne = ({ careerSite }) => {
  if (!careerSite?.sectionOne?.visible) {
    return null
  }

  const videoId = getVideoId(careerSite?.sectionOne?.videoLink)

  return (
    <Spacer>
      <MainContainer>
        <Row>
          <Col offset={{ md: 0.5 }} md={5}>
            <TextContainer>
              <Text type="title" size="sm" fontWeight="semibold">
                {careerSite.sectionOne.title}
              </Text>
              <Text size="md">{careerSite.sectionOne.description}</Text>
            </TextContainer>
          </Col>
          <Col offset={{ md: 1 }} md={5}>
            <CustomIframe
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video presentación"
              width={'100%'}
            />
          </Col>
        </Row>
      </MainContainer>
    </Spacer>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
})

export default connect(states)(SectionOne)
