import styled from 'styled-components'

export const Content = styled.div``

export const Header = styled.div`
  width: 100%;
`

export const CustomRadioButton = styled.div`
  margin-left: -2px;
`
export const Description = styled.div`
  margin-top: -20px;
  & > p:first-of-type {
    margin-bottom: 12px;
  }
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    & > p:last-of-type {
      margin-bottom: 8px;
    }
  }
`
