import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Title = styled('h2')`
  margin: 0px;
`

export const LinkAviso = styled(Link)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  display: flex;
  padding: 0px;
  border: none;
  transition: all 0.3s ease-in-out;
  ${({ disabled }) => disabled && 'cursor: default;'}
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`
