import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return (
      <Text>
        {t('Ingresa al link del email y elige una nueva contraseña')}
      </Text>
  )
}

export default Component
