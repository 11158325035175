import styled from 'styled-components'

export const Card = styled('div')`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  width: 100%;
`

export const SubtitleText = styled('p')`
  font-size: 16px;
  line-height: 20px;
  margin: 0 9px;
  color: ${({ theme }) => theme.colors.grey84};
`

export const StrongText = styled('div')`
  display: inline;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.normal};
  text-decoration: underline;
  cursor: pointer;
`
