import { TagManager } from '@navent-jobs/config'
import { actionOrigin } from './commons/actionurl'

interface UserLoggedProps {
  applicantId: string
  method: string
  url: Location
}

export const DataLayer = {
  userLogged: ({ applicantId, method, url }: UserLoggedProps) => {
    // usuario logueado
    return TagManager.Push({
      event: 'login',
      user_id_custom: applicantId,
      method,
      apto_a_postularse: 'Si',
      origin_type: actionOrigin({ url }),
      user_type: 'Postulante',
      isLogged: 'Si',
    })
  },

  pageView: () => {
    // page view
    return TagManager.Push({
      event: 'track_content_group',
      content_group: 'Ingreso',
    })
  },
}
