export const UPDATE_EMAIL_ACCOUNT = 'UPDATE_EMAIL_ACCOUNT'
export const UPDATE_EMAIL_ACCOUNT_SUCCESS = 'UPDATE_EMAIL_ACCOUNT_SUCCESS'
export const UPDATE_EMAIL_ACCOUNT_ERROR = 'UPDATE_EMAIL_ACCOUNT_ERROR'

export interface UpdateEmailResponseType {
  code: 'SUCCESS' | 'INVALID_PASSWORD' |'EMAIL_UNDER_USE'
}


export interface UpdateEmail {
  type: typeof UPDATE_EMAIL_ACCOUNT
  payload: UpdateEmailAccountType
}

export interface UpdateEmailSuccess {
  type: typeof UPDATE_EMAIL_ACCOUNT_SUCCESS
  payload:{statusCode: 'SUCCESS' | 'INVALID_PASSWORD' |'EMAIL_UNDER_USE'}
}

export interface UpdateEmailError {
  type: typeof UPDATE_EMAIL_ACCOUNT_ERROR
  payload: string 
}

export interface UpdateEmailState {
  statusCode: 'SUCCESS' | 'INVALID_PASSWORD'|'EMAIL_UNDER_USE' | null
  data: UpdateEmailAccountType | null
  state: Status
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  loading: false,
  success: false,
  error: false,
}

export const initialUpdateEmailState = {
  statusCode: null,
  data: null,
  state: initialStatus,
}

export interface UpdateEmailAccountType {
  email: string
  password: string
}

export type UpdateEmailActions = UpdateEmail | UpdateEmailSuccess | UpdateEmailError 
