import { GetCampaign, GetCompanyLogos, GET_CAMPAIGN, GET_COMPANY_LOGOS } from '../types/marketing'

export const getCampaignAction = (): GetCampaign => {
  return {
    type: GET_CAMPAIGN,
    payload: null,
  }
}

export const getCompanyLogosAction = (): GetCompanyLogos => {
  return {
    type: GET_COMPANY_LOGOS,
    payload: null,
  }
}
