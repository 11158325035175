export interface StatusType {
  loading: boolean
  success: boolean
  error: boolean
}

const initial: StatusType = {
  loading: false,
  success: false,
  error: false,
}

const loading: StatusType = {
  loading: true,
  success: false,
  error: false,
}

const success: StatusType = {
  loading: false,
  success: true,
  error: false,
}

const error: StatusType = {
  loading: false,
  success: false,
  error: true,
}

export const status = {
  initial,
  loading,
  success,
  error,
}
