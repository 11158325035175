import styled from 'styled-components'

export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.grey84};

  :hover {
    color: ${({ theme }) => theme.colors.grey84};
    text-decoration: none;
  }
`
