import styled from 'styled-components'

export const FooterComponent = styled.footer`
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 80px;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 64px 0 120px;
  }
`
