import {
  GET_PROMEDIOS_SALARIALES,
  GET_PROMEDIO_SALARIAL_AREA,
  GetPromediosSalariales,
  GetPromedioSalarialArea,
  CalcularSalarioNeto,
  CALCULAR_SALARIO_NETO,
} from '../types/promedioSalarial'

export const getPromediosSalariales = (): GetPromediosSalariales => {
  return {
    type: GET_PROMEDIOS_SALARIALES,
    payload: null,
  }
}

export const getPromedioSalarialArea = (area: string): GetPromedioSalarialArea => {
  return {
    type: GET_PROMEDIO_SALARIAL_AREA,
    payload: area,
  }
}

export const calculateSalarioNeto = (salarioBruto: number): CalcularSalarioNeto => {
  return {
    type: CALCULAR_SALARIO_NETO,
    payload: salarioBruto,
  }
}
