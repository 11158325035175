import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const GET_ALERTAS_FRECUENCIA = 'GET_ALERTAS_FRECUENCIA'
export const GET_ALERTAS_FRECUENCIA_SUCCESS = 'GET_ALERTAS_FRECUENCIA_SUCCESS'
export const GET_ALERTAS_FRECUENCIA_ERROR = 'GET_ALERTAS_FRECUENCIA_ERROR'

export const CREATE_ALERT = 'CREATE_ALERT'
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS'
export const CREATE_ALERT_ERROR = 'CREATE_ALERT_ERROR'
export const CREATE_ALERT_RESET = 'CREATE_ALERT_RESET'

export const GET_ALERT = 'GET_ALERT'
export const GET_ALERT_SUCCESS = 'GET_ALERT_SUCCESS'
export const GET_ALERT_ERROR = 'GET_ALERT_ERROR'

export const DELETE_ALERT = 'DELETE_ALERT'
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS'
export const DELETE_ALERT_ERROR = 'DELETE_ALERT_ERROR'
export const DELETE_ALERT_RESET = 'DELETE_ALERT_RESET'

export const EDIT_ALERT = 'EDIT_ALERT'
export const EDIT_ALERT_SUCCESS = 'EDIT_ALERT_SUCCESS'
export const EDIT_ALERT_ERROR = 'EDIT_ALERT_ERROR'
export const EDIT_ALERT_RESET = 'EDIT_ALERT_RESET'

export const SWITCH_ALERT = 'SWITCH_ALERT'
export const SWITCH_ALERT_SUCCESS = 'SWITCH_ALERT_SUCCESS'
export const SWITCH_ALERT_ERROR = 'SWITCH_ALERT_ERROR'
export const SWITCH_ALERT_RESET = 'SWITCH_ALERT_RESET'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

// create
export interface StaticOption {
  value: number
  label: string
}

// create
export interface CreateAlertType {
  nombre: string
  idFrecuencia: number
  keyword: string | null
  area: string | null
  subarea: string | null
  provincia: string | null
  localidad: string | null
  nivelLaboral: string | null
  tipoTrabajo: string | null
  modalidadTrabajo: string | null
  tipoContratacion: string | null
  discapacidad: boolean | null
}

export interface CreateAlertResType {
  idFrecuencia: number
  code: 'SUCCESS' | 'DUPLICATE_ALERT' | 'ALERT_LIMIT'
}

interface AlertItem {
  id: number
  nombre: string
}

//  get
export interface GetAlertType {
  id: number
  nombre: string
  keyword: string | null
  idPostulante: number
  idFrecuencia: AlertItem
  idArea: AlertItem | null
  idSubarea: AlertItem | null
  idPais: number
  idProvincia: AlertItem | null
  idLocalidad: AlertItem | null
  idNivelLaboral: AlertItem
  idModalidadTrabajo: AlertItem | null
  idTipoTrabajo: AlertItem | null
  idTipoContratacion: AlertItem | null
  discapacidad: boolean
  habilitado: boolean | null
  created: string
}

// delete
export interface DeleteAlertType {
  id: number
}

// edit
export interface EditAlertType {
  id: number
  nombre: string
  idFrecuencia: number
}

export interface ModalEditAlertType {
  id: number
  nombre: string
  idFrecuencia: number
  labelFrecuencia: string
}

// switch
export interface SwitchAlertType {
  id: number
  status: boolean
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface AlertState {
  alertasFrecuencia: {
    value: StaticOption[] | null
    status: StatusType
  }
  create: {
    value: CreateAlertResType | null
    status: StatusType
  }
  getAlert: {
    value: GetAlertType[] | null
    status: StatusType
  }
  deleteAlert: {
    value: DeleteAlertType | null
    status: StatusType
  }
  editAlert: {
    value: EditAlertType | null
    status: StatusType
  }
  switchAlert: {
    value: SwitchAlertType | null
    status: StatusType
  }
}

export const initialState: AlertState = {
  alertasFrecuencia: {
    value: null,
    status: status.initial,
  },
  create: {
    value: null,
    status: status.initial,
  },
  getAlert: {
    value: null,
    status: status.initial,
  },
  deleteAlert: {
    value: null,
    status: status.initial,
  },
  editAlert: {
    value: null,
    status: status.initial,
  },
  switchAlert: {
    value: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */

// frecuencia
export interface GetAlertasFrecuencia {
  type: typeof GET_ALERTAS_FRECUENCIA
  payload: null
}

export interface GetAlertasFrecuenciaSuccess {
  type: typeof GET_ALERTAS_FRECUENCIA_SUCCESS
  payload: StaticOption[]
}

export interface GetAlertasFrecuenciaError {
  type: typeof GET_ALERTAS_FRECUENCIA_ERROR
  payload: null
}

// create
export interface CreateAlert {
  type: typeof CREATE_ALERT
  payload: CreateAlertType
}

export interface CreateAlertSuccess {
  type: typeof CREATE_ALERT_SUCCESS
  payload: CreateAlertResType
}

export interface CreateAlertError {
  type: typeof CREATE_ALERT_ERROR
  payload: null
}

export interface CreateAlertReset {
  type: typeof CREATE_ALERT_RESET
  payload: null
}

// get
export interface GetAlert {
  type: typeof GET_ALERT
  payload: null
}

export interface GetAlertSuccess {
  type: typeof GET_ALERT_SUCCESS
  payload: GetAlertType[]
}

export interface GetAlertError {
  type: typeof GET_ALERT_ERROR
  payload: null
}

// delete
export interface DeleteAlert {
  type: typeof DELETE_ALERT
  payload: DeleteAlertType
}
export interface DeleteAlertReset {
  type: typeof DELETE_ALERT_RESET
  payload: null
}
export interface DeleteAlertSuccess {
  type: typeof DELETE_ALERT_SUCCESS
  payload: null
}
export interface DeleteAlertError {
  type: typeof DELETE_ALERT_ERROR
  payload: null
}

// edit
export interface EditAlert {
  type: typeof EDIT_ALERT
  payload: EditAlertType
}
export interface EditAlertReset {
  type: typeof EDIT_ALERT_RESET
  payload: null
}
export interface EditAlertSuccess {
  type: typeof EDIT_ALERT_SUCCESS
  payload: null
}
export interface EditAlertError {
  type: typeof EDIT_ALERT_ERROR
  payload: null
}

// switch
export interface SwitchAlert {
  type: typeof SWITCH_ALERT
  payload: SwitchAlertType
}
export interface SwitchAlertReset {
  type: typeof SWITCH_ALERT_RESET
  payload: null
}
export interface SwitchAlertSuccess {
  type: typeof SWITCH_ALERT_SUCCESS
  payload: null
}
export interface SwitchAlertError {
  type: typeof SWITCH_ALERT_ERROR
  payload: null
}

export type AlertActions =
  | CreateAlert
  | CreateAlertSuccess
  | CreateAlertError
  | CreateAlertReset
  | GetAlertasFrecuencia
  | GetAlertasFrecuenciaSuccess
  | GetAlertasFrecuenciaError
  | GetAlert
  | GetAlertSuccess
  | GetAlertError
  | DeleteAlert
  | DeleteAlertReset
  | DeleteAlertSuccess
  | DeleteAlertError
  | EditAlert
  | EditAlertReset
  | EditAlertSuccess
  | EditAlertError
  | SwitchAlert
  | SwitchAlertReset
  | SwitchAlertSuccess
  | SwitchAlertError
