import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PaymentMPProductSKU } from '../../../../constants/payment-mp-product-sku'
import { userLoggedIn } from '../../../../utils/cookies'

// actions
import { showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { getLinkPagoReset } from '../../../../redux/actions/payment-mp'
import { getProductAvailability, getProductsStatus } from '../../../../redux/actions/products'

// componentes
import ModalWithoutDocumentNumber from '../../../MarketPlace/curriculum/components/modals/without-document-number'
import ModalAnalysisCvUnableToBuy from '../../../MarketPlace/curriculum/components/modals/analysis-cv-unable-to-purchase'

const Component = ({ productName, previewStates, previewUrl, getLinkPagoStatus, linkPago }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const isUserLoggedIn = userLoggedIn()

  const productSku = PaymentMPProductSKU[productName]

  useEffect(() => {
    if (previewStates?.success) {
      previewUrl !== null &&
        history.push({ pathname: '/postulantes/productos/miguru/preview', state: { url: previewUrl } })
    }

    if (previewStates?.error) {
      dispatch(showSnackbarError(t(`curriculum.snackbarNotificationError`)))
    }
  }, [previewStates?.success, previewStates?.error])

  useEffect(() => {
    if (getLinkPagoStatus.success && linkPago) {
      window.location.href = linkPago
      dispatch(getLinkPagoReset())
    } else if (getLinkPagoStatus.error) {
      dispatch(showSnackbarError(t(`curriculum.snackbarNotificationError`)))
      dispatch(getLinkPagoReset())
    }
  }, [getLinkPagoStatus])

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getProductAvailability(productSku))
    }
  }, [])

  useEffect(() => {
    if (isUserLoggedIn && productSku === PaymentMPProductSKU.PDA) {
      dispatch(getProductsStatus())
    }
  }, [isUserLoggedIn])

  return (
    <>
      <ModalWithoutDocumentNumber productName={productName} />
      <ModalAnalysisCvUnableToBuy productName={productName} />
    </>
  )
}

const states = ({ paymentMPStore, analysisCvStore }) => ({
  getLinkPagoStatus: paymentMPStore.getLinkPagoStatus,
  linkPago: paymentMPStore.linkPago,
  previewStates: analysisCvStore?.previewStates,
  previewUrl: analysisCvStore?.previewUrl,
})

export default connect(states)(Component)
