import { put, call, takeLatest } from 'redux-saga/effects'
import fichaAvisoService from '../../services/ficha-aviso-service'
import avisosRecomendadosService from '../../services/avisos-recomendados'

import {
  GET_RECOMENDADOS,
  GET_RECOMENDADOS_ERROR,
  GET_RECOMENDADOS_SUCCESS,
  GET_RECOMENDADOS_LISTADO,
  GET_RECOMENDADOS_LISTADO_ERROR,
  GET_RECOMENDADOS_LISTADO_SUCCESS,
  GetRecomendados,
  GetRecomendadosError,
  GetRecomendadosSuccess,
  GetRecomendadosListado,
  GetRecomendadosListadoError,
  GetRecomendadosListadoSuccess,
  GET_MOTIVOS_NO_INTERESADO,
  GET_MOTIVOS_NO_INTERESADO_SUCCESS,
  GET_MOTIVOS_NO_INTERESADO_ERROR,
  GetMotivosNoInteresadoError,
  GetMotivosNoInteresadoSuccess,
  SET_NOT_INTERESTED,
  SetNotInterestedSuccess,
  SET_NOT_INTERESTED_SUCCESS,
  SetNotInterestedError,
  SET_NOT_INTERESTED_ERROR,
  SetNotInterested,
} from '../types/recomendados'

function* getAvisosRecomendados({ payload }: GetRecomendados) {
  try {
    const result = yield call(fichaAvisoService.getAvisosRecomendados, payload)
    yield put<GetRecomendadosSuccess>({ type: GET_RECOMENDADOS_SUCCESS, payload: result })
  } catch (error) {
    yield put<GetRecomendadosError>({ type: GET_RECOMENDADOS_ERROR, payload: null })
  }
}

function* getAvisosRecomendadosListado({ payload }: GetRecomendadosListado) {
  try {
    const result = yield call(avisosRecomendadosService.getAvisosRecomendadosListado, payload)
    yield put<GetRecomendadosListadoSuccess>({ type: GET_RECOMENDADOS_LISTADO_SUCCESS, payload: result })
  } catch (error) {
    yield put<GetRecomendadosListadoError>({ type: GET_RECOMENDADOS_LISTADO_ERROR, payload: null })
  }
}

function* getMotivosNoInteresado() {
  try {
    const result = yield call(avisosRecomendadosService.getMotivosNoInteresado)
    yield put<GetMotivosNoInteresadoSuccess>({ type: GET_MOTIVOS_NO_INTERESADO_SUCCESS, payload: result })
  } catch (error) {
    yield put<GetMotivosNoInteresadoError>({ type: GET_MOTIVOS_NO_INTERESADO_ERROR, payload: null })
  }
}

function* setNotInterested({ payload }: SetNotInterested) {
  try {
    yield call(avisosRecomendadosService.setNotInterestedService, payload)
    yield put<SetNotInterestedSuccess>({ type: SET_NOT_INTERESTED_SUCCESS, payload: payload.avisoId })
  } catch (error) {
    yield put<SetNotInterestedError>({ type: SET_NOT_INTERESTED_ERROR, payload: payload.avisoId })
  }
}

/**
 * Watchers
 */
export default function* recomendados() {
  yield takeLatest(GET_RECOMENDADOS, getAvisosRecomendados)
  yield takeLatest(GET_RECOMENDADOS_LISTADO, getAvisosRecomendadosListado)
  yield takeLatest(GET_MOTIVOS_NO_INTERESADO, getMotivosNoInteresado)
  yield takeLatest(SET_NOT_INTERESTED, setNotInterested)
}
