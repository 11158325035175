export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Estado',
      localidadTitle: 'Municipio',
    },
  },
  legales:
    'es responsable por la Base de Datos. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos personalmente o por correo postal a la dirección Av. Luis Roche con 3era Transversal, Edificio Torre Nuevo Mundo, piso 4 Local 4, Urb. Altamira, Caracas 1060, Venezuela.',
  preferenciaSalarial: {
    salarioDescription: 'Sueldo bruto pretendido: ',
    form: {
      salarioPlaceholder: 'Sueldo bruto pretendido',
    },
  },
}
