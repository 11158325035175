import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'
import rootSaga from '../sagas'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
// eslint-disable-next-line dot-notation
const composeWithDevTools = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose
export default (preloadedState?: any) => {
  return {
    ...createStore(
      rootReducer(history), // root reducer with router state
      preloadedState,
      composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  }
}
