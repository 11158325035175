// images logos
import LogoNulinga from '../../assets/images/product-banners/logo-nulinga.svg'
import LogoMiGuru from '../../assets/images/product-banners/logo-miguru.svg'
import LogoDoctorCV from '../../assets/images/product-banners/logo-doctorcv.svg'
import LogoCoderhouse from '../../assets/images/product-banners/logo-coderhouse.svg'

// images background
import LogoNulingaBackground from '../../assets/images/product-banners/logo-nulinga-bkg.svg'
import LogoMiGuruBackground from '../../assets/images/product-banners/logo-miguru-bkg.svg'
import LogoDoctorCVBackground from '../../assets/images/product-banners/logo-doctorcv-bkg.svg'
import LogoCoderhouseBackground from '../../assets/images/product-banners/logo-coderhouse-bkg.svg'
import i18n from '../../i18n'

import calificarImg from '../calificar-img'

export interface SettingsBannerProducts {
  product: string
  title: string
  titleMobile?: string
  claim: string
  logo: SVGAElement
  alt: string
  background: SVGAElement
  href?: string
  hrefSelf?: boolean
  cta: string
  bumeranColor?: boolean
  requireLogin?: boolean
}

export const settingsBannerProducts = {
  REVIEW: {
    product: 'Calificaciones',
    title: i18n.t('products.products.review.bannerTitle'),
    titleMobile: i18n.t('products.products.review.bannerTitleMobile'),
    claim: i18n.t('products.products.review.claim'),
    background: calificarImg.banner,
    href: '/perfiles/calificacion',
    hrefSelf: true,
    cta: i18n.t('products.products.review.cta'),
    bumeranColor: true,
    requireLogin: true,
  },
  NULINGA: {
    product: 'Nulinga',
    title: i18n.t('products.products.nulinga.bannerTitle'),
    claim: i18n.t('products.products.nulinga.claim'),
    logo: LogoNulinga,
    alt: 'Logo Producto Nulinga',
    background: LogoNulingaBackground,
    href: '/postulantes/productos/nulinga',
    cta: i18n.t('products.products.nulinga.cta'),
  },
  MIGURU: {
    product: 'MiGuru',
    title: 'Analiza tu CV y entérate cómo potenciarte',
    claim: 'Conocerás tus fortalezas y áreas de mejora en pocos segundos',
    logo: LogoMiGuru,
    alt: 'Logo Producto Mi Guru',
    background: LogoMiGuruBackground,
    href: '/postulantes/productos/miguru',
    cta: 'Conoce más',
  },
  DOCTORCV: {
    product: 'DoctorCv',
    title: 'Evalúa tu curriculum con Doctor CV',
    claim: 'Recibe recomendaciones para mejorar tu CV y destacar en el portal',
    logo: LogoDoctorCV,
    alt: 'Logo Producto Doctor CV',
    background: LogoDoctorCVBackground,
    href: '/postulantes/productos/doctorcv',
    cta: 'Conoce más',
  },
  CODERHOUSE: {
    product: 'Coderhouse',
    title: i18n.t('products.products.coderhouse.banner.title'),
    claim: i18n.t('products.products.coderhouse.banner.claim'),
    logo: LogoCoderhouse,
    alt: 'Logo Coderhouse',
    background: LogoCoderhouseBackground,
    cta: i18n.t('products.products.coderhouse.banner.cta'),
  },
}

const settingsBanner = {
  ZJAR: {
    enabled: false,
  },
  BMAR: {
    enabled: false,
  },
  BMCL: {
    enabled: false,
  },
  BMEC: {
    enabled: false,
  },
  BMMX: {
    enabled: false,
  },
  BMPA: {
    enabled: false,
  },
  BMPE: {
    enabled: false,
  },
  BMVE: {
    enabled: false,
  },
}

export default settingsBanner
