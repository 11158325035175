import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// react-share
import {
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'
import TwitterIcon from '@navent-jobs/ui-kit/assets/img/share/x-twitter.svg'

// ui-kit
import Image from '@navent-jobs/ui-kit/components/Image'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Input as CopyUrlInput } from '@navent-jobs/ui-kit/components/input'
import { Snackbar } from '@navent-jobs/ui-kit/components/snackbar'
import Text from '@navent-jobs/ui-kit/components/text'

// hooks / action
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { showSnackbar } from '../../redux/actions/applicant/curriculum/snackbar'

// mixins
import {
  SocialButtonWrapper,
  InputWrapper,
  StrongLabel,
  CopyButton,
  ShareButtonWrapper,
  ShareButton,
  SnackbarText,
  IconCustom,
  IconContainer,
} from './mixins'

type ShareButtonType = 'WHATSAPP' | 'FACEBOOK' | 'TWITTER' | 'EMAIL' | 'COPY'
export interface SocialShareProps {
  children?: string
  shareUrl: string
  shareTitle: string
  shareHelperDescription: string
  modalTitle?
  label?: string
  onClick?: () => void
  onCopy?: () => void
  onClickShare?: (type: ShareButtonType) => void
}

const SocialShare = ({
  children,
  shareUrl,
  shareTitle,
  shareHelperDescription,
  modalTitle,
  label,
  onClick,
  onClickShare,
}: SocialShareProps) => {
  const { t } = useTranslation()
  const { isMobile, isTabletLandscape, isDesktop } = useWindowSize()
  const [visibleModal, setVisibleModal] = useState('')
  const hideModal = () => setVisibleModal('')
  const dispatch = useDispatch()
  const nav = navigator as any

  const handleCopy = () => {
    nav.clipboard.writeText(shareUrl)
    // setShowSnackbar(true)
    dispatch(showSnackbar(t('share.snackbarCopySuccess'), 'icon-light-copy'))
  }

  // Comportamiento: si es mobile compartimos medio por medios nativos, sino por medio de la libreria react-share

  const handleSheraButton = () => {
    if (isMobile) {
      if (nav.canShare && nav.canShare({ url: shareUrl })) {
        nav
          .share({
            title: shareTitle,
            url: shareUrl,
          })
          .catch()
      }
    } else {
      setVisibleModal('modal-compartir')
    }
    if (onClick) {
      onClick()
    }
  }

  const onClickShareHandler = (type: ShareButtonType) => {
    if (onClickShare) {
      onClickShare(type)
    }
  }

  return (
    <>
      <ShareButton
        onClick={() => handleSheraButton()}
        buttonoptions={{ variant: 'black', color: '#29293D', type: 'link', size: 'small' }}
      >
        <IconContainer>
          <IconCustom name="icon-light-share" size={label ? '16' : '24'} hasChildren={children} />
          {label && (
            <Text size="sm" fontWeight="semibold">
              {label}
            </Text>
          )}
        </IconContainer>
        {(isDesktop || isTabletLandscape) && children}
      </ShareButton>

      <Modal width={584} visible={visibleModal === 'modal-compartir'} onClose={hideModal} title={modalTitle}>
        <>
          <ShareButtonWrapper>
            <SocialButtonWrapper>
              <WhatsappShareButton
                url={shareUrl}
                separator=" --- "
                title={shareHelperDescription}
                windowHeight={584}
                windowWidth={584}
                windowPosition="windowCenter"
                onClick={() => onClickShareHandler('WHATSAPP')}
              >
                <WhatsappIcon round size={42} />
              </WhatsappShareButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <FacebookShareButton
                url={shareUrl}
                windowHeight={584}
                windowWidth={584}
                windowPosition="windowCenter"
                onClick={() => onClickShareHandler('FACEBOOK')}
              >
                <FacebookIcon round size={42} />
              </FacebookShareButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <TwitterShareButton
                url={shareUrl}
                windowHeight={584}
                windowWidth={584}
                windowPosition="windowCenter"
                onClick={() => onClickShareHandler('TWITTER')}
              >
                <Image width="42px" height="42px" alt="logo-twitter" src={TwitterIcon} />
              </TwitterShareButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <EmailShareButton
                url={shareUrl}
                subject={shareTitle}
                onClick={e => {
                  e.preventDefault()
                  onClickShareHandler('EMAIL')
                }}
                separator=" --- "
                openShareDialogOnClick
                windowHeight={584}
                windowWidth={584}
                windowPosition="windowCenter"
                body={shareHelperDescription}
              >
                <EmailIcon round size={42} />
              </EmailShareButton>
            </SocialButtonWrapper>
          </ShareButtonWrapper>

          <InputWrapper>
            <StrongLabel>{t('share.modal.inputLabel')}</StrongLabel>
            <CopyUrlInput
              id="CopyUrlInput"
              name="CopyUrlInput"
              type="text"
              value={shareUrl}
              placeholder={t('share.modal.inputLabel')}
              fieldOptions={{
                variant: 'darken',
                maxLength: 50,
                after: (
                  <CopyButton
                    onClick={() => {
                      handleCopy()
                      onClickShareHandler('COPY')
                    }}
                    buttonoptions={{ variant: 'secondary', color: '#0a26ee', type: 'link', size: 'small' }}
                  >
                    <Icon name="icon-light-copy" color="#0a26ee" size="24" />
                  </CopyButton>
                ),
              }}
            />
          </InputWrapper>
        </>
      </Modal>
    </>
  )
}

export default SocialShare
