import { TagManager } from '@navent-jobs/config'

export const DataLayer = {
  handlerPageViewTraking: userIsLogged => {
    // Page view
    return TagManager.Push({
      content_group: 'Home',
      isLogged: `${userIsLogged ? 'Si' : 'No'}`,
    })
  },
  handlerIrPerfilTraking: () => {
    // Ir a perfil
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Seccion usuarios',
      item_id: 'CTA Ir a mi perfil',
    })
  },
  handlerCrearCuentaTraking: () => {
    // Ir a crear cuenta
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Seccion usuario deslogueados',
      item_id: 'CTA Crear cuenta',
    })
  },
  handlerLogosTraking: (action, keyword, position) => {
    // Ver logo o click
    return TagManager.Push({
      event: `${action === 'view' ? 'promotionImpression' : 'promotionClick'}`,
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: 'Empresas - Home',
              creative: keyword,
              position,
            },
          ],
        },
      },
    })
  },
  handlerLogosCtaTraking: () => {
    // Ir a listado empresas
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Listado de empresas',
      item_id: 'CTA ir a Listado de Empresas',
    })
  },
  handlerClickNewFeatureTraking: feature => {
    // Ir link de new feature
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Seccion Blog y Productos',
      item_id: feature,
    })
  },
}
