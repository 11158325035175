import { status, StatusType } from '../status'
import { FiltersAppliedType } from './company-summary'

export interface CompanySummaryStatus {
  loading: boolean
  success: boolean
  error: boolean
}

export interface ListadoAvisosEmpresa {
  listadoAvisos?: []
}

export interface FichaEmpresa {
  fichaEmpresa?: []
}

export interface CompanySummaryState {
  listadoAvisos: ListadoAvisosEmpresa | null
  listadoAvisosStatus: CompanySummaryStatus
  fichaEmpresa: FichaEmpresa | null
  fichaEmpresaStatus: CompanySummaryStatus
  filterStates: StatusType
  filtersApplied: FiltersAppliedType[] | null
  filters: FiltersAppliedType[] | null
  siguiendoStates: StatusType
  getSiguiendoStates: StatusType
  idEmpresa: number | null
  siguiendo: boolean | null
}

const initalStatus = {
  loading: false,
  success: false,
  error: false,
}

export const initialState: CompanySummaryState = {
  listadoAvisos: null,
  listadoAvisosStatus: initalStatus,
  fichaEmpresa: null,
  fichaEmpresaStatus: initalStatus,
  filterStates: status.initial,
  filtersApplied: [],
  filters: [],
  siguiendoStates: status.initial,
  getSiguiendoStates: status.initial,
  siguiendo: null,
}
