import IconMapaDelEmpleo from '../../../assets/images/icon-mapa-del-empleo.svg'

import { CrossSiteDataLayers } from '../../gtm-events-cross-site'
import i18n from '../../../i18n'
import { DataLayers as PromedioSalarialOnClick } from '../../gtm-events-button-promedio-salarial'

const salario = {
  href: '/salarios',
  content: 'Salarios',
  key: 'salarios',
  icon: i18n.t(`currencyMask.icon`),
  visibleIn: ['mobile', 'desktop'],
  metrics: () => {
    PromedioSalarialOnClick.PromedioSalarialOnClick()
    CrossSiteDataLayers.header('Salarios')
  },
  iconPrimary: true,
}

export const accessItems = {
  BMAR: [
    salario,
    {
      href: 'https://mapadelempleo.fundaciontelefonica.com/arg?_ga=2.225395162.1382093837.1642787986-1271303123.1639425988',
      rel: 'nofollow',
      content: 'Mapa del empleo',
      key: 'mapa-del-empleo',
      img: IconMapaDelEmpleo,
      alt: 'mapa del empleo',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Mapa del Empleo'),
    },
  ],
  ZJAR: [salario],
  BMCL: [salario],
  BMEC: [salario],
  BMMX: [salario],
  BMPA: [salario],
  BMPE: [salario],
  BMVE: [],
}
