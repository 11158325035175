import { Icon } from '@navent-jobs/ui-kit/components/icon'
import styled from 'styled-components'

export const StarContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

export const RatingScore = styled('div')`
  margin-right: 5px;
`

export const StarIcon = styled(Icon)`
  &&&:before {
    background-color: #fab306 !important;
  }
`

export const CustomIcon = styled(Icon)`
  :not(:first-child) {
    margin-left: 4px;
  }
  &&&:before {
    background-color: #fab306 !important;
  }
`
