export const GET_SEO_FOOTER = 'GET_SEO_FOOTER'
export const GET_SEO_FOOTER_FILTERED = 'GET_SEO_FOOTER_FILTERED'
export const GET_SEO_FOOTER_SUCCESS = 'GET_SEO_FOOTER_SUCCESS'
export const GET_SEO_FOOTER_ERROR = 'GET_SEO_FOOTER_ERRO'

interface Facet {
  id: string
  idSemantico: string
  name: string
  quantity: number
}

export interface Filter {
  type: string
  facets: Facet[]
}

interface Link {
  url: string
  label: string
}

export interface SeoFooterColumn {
  title: string
  links: Link[]
}

export interface GetSeoFooter {
  type: typeof GET_SEO_FOOTER
  payload: null
}

export interface GetSeoFooterFiltered {
  type: typeof GET_SEO_FOOTER_FILTERED
  payload: any
}

export interface GetSeoFooterSuccess {
  type: typeof GET_SEO_FOOTER_SUCCESS
  payload: SeoFooterColumn[]
}

export interface GetSeoFooterError {
  type: typeof GET_SEO_FOOTER_ERROR
  payload: boolean
}

export interface SeoFooterState {
  links: SeoFooterColumn[] | null
  loading: boolean
  error: boolean
  success: boolean
}

export const initialState: SeoFooterState = {
  links: null,
  loading: false,
  error: false,
  success: false,
}

export type SeoFooterActions = GetSeoFooter | GetSeoFooterFiltered | GetSeoFooterSuccess | GetSeoFooterError
