import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { LOGOUT_SUCCESS, LOGOUT_ERROR, LOGOUT } from '../types/logout'

function* logout() {
  try {
    yield call(services.auth.logoutUser)
    yield put({ type: LOGOUT_SUCCESS, payload: true })
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, payload: error })
  }
}

/**
 * Watchers
 */

export default function* applicant() {
  yield takeLatest(LOGOUT, logout)
}
