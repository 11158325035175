import React from 'react'
import { ComponentContainer, CustomImage } from './mixins'
import ForgetPasswordBumImage from '../../../../assets/images/forget-password.svg'
import ForgetPasswordZonaImage from '../../../../assets/images/forget-password-zona.svg'
import { IS_ZONA_JOBS } from '../../../../constants'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage src={IS_ZONA_JOBS ? ForgetPasswordZonaImage : ForgetPasswordBumImage} alt="Forget password Image" />
    </ComponentContainer>
  )
}

export default Component
