const ctaDownloadApp = {
  mobile: {
    button: {
      sidebar: 'Descargá la app en tu celular',
    },
  },
  desktop: {
    title: '¡Descargá la app en tu celular!',
  },
}

export default ctaDownloadApp
