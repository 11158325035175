import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { showSnackbar } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { CopyButton, CustomText, WrapperCopyLink } from './mixins'
import { DataLayersLanguageTest } from '../../../../constants/gtm-events-language-test'

const Component = ({ email, title }) => {
  const dispatch = useDispatch()
  const nav = navigator as any

  const handleOnClick = () => {
    nav.clipboard.writeText(email)
    dispatch(showSnackbar('Email copiado', 'icon-light-copy'))
    DataLayersLanguageTest.goToTest('Copiar email')
  }

  return (
    <>
      <WrapperCopyLink>
        <CustomText>{title}</CustomText>
        <Input
          id="emailCopy"
          name="emailCopy"
          type="text"
          value={email}
          fieldOptions={{
            variant: 'darken',
            after: (
              <CopyButton
                onClick={handleOnClick}
                buttonoptions={{ variant: 'secondary', color: '#0a26ee', type: 'link', size: 'small' }}
              >
                <Icon name="icon-light-copy" color="#0a26ee" size="24" />
              </CopyButton>
            ),
          }}
        />
      </WrapperCopyLink>
    </>
  )
}

const states = ({ applicantStore }) => ({
  email: applicantStore?.applicant?.email,
})

export default connect(states)(Component)
