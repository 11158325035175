import { Link } from '@navent-jobs/ui-kit/components/link'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const LinkGptwCustom = styled(Link)`
  padding: 0;
  text-decoration: underline;
`

export const Label = styled.div`
  margin-left: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`
