export const perfil = {
  metaData: {
    metaTitle1: 'Empleos en',
    metaTitle2: 'trabajo y ofertas de empleo -',
    descriptionBuscaEmpleo: 'Busca empleos en',
    descriptionBolsaEmpleo: 'en la bolsa de trabajo más grande del país. ',
    descriptionEncuentraOfertas: 'Encuentra ofertas de empleo y trabajos en las mejores empresas en',
  },
  datosPersonales: {
    primerEmpleo: 'Busco mi primer empleo',
    boton: 'Ir a mi {{cv}}',
    completarCV: 'Completar {{cv}}',
  },
  actualizarCv: {
    ultimaActualizacion: 'Última modificación del {{cv}}:',
    cvDesactualizado: 'Revisa tu {{cv}}',
    cvActualizado: 'Mi {{cv}} está actualizado',
    disponibilidadActualizacion: 'Mi disponibilidad',
    modal: {
      title: '¿Confirmas que tu {{cv}} está al día?',
      subTitle: 'Al mantener tu {{cv}} actualizado tendrás más visibilidad frente a las empresas.',
      editarButton: 'Quiero editarlo',
      actualizadoButton: 'Está actualizado',
      snackSuccess: 'Tu {{cv}} está actualizado.',
      snackError: 'Error, vuelve a intentarlo.',
    },
  },
  privacidadCv: {
    title: 'Tu {{cv}} es {{privacidad}}',
    descriptionpublico:
      'Las empresas pueden ver tu {{cv}} en los avisos a los que te postulaste y en la base de {{portal}}.',
    descriptionprivado: 'Tu {{cv}} es visible únicamente en los avisos a los que te postulaste.',
    publico: 'público',
    privado: 'privado',
  },
  favoritos: {
    titulo: 'Empleos favoritos',
    boton: 'Ver todos',
    empty: 'Guarda empleos para postularte más tarde',
    emptyBoton: 'Ver empleos',
    acceso: 'Empleos favoritos',
  },
  empresasFavoritas: {
    titulo: 'Empresas favoritas',
    boton: 'Ver todas',
    empty: 'Guarda las empresas que más te interesen',
    emptyBoton: 'Ver empresas',
  },
  salarioPretendido: {
    disclaimer: 'Salario comparado con otros postulantes en la misma área.',
    labelPretendido: 'Tu último salario pretendido',
    labelArea: 'Podrías pedir',
    empty: 'Para conocer tu posible salario, postúlate a un aviso',
    emptyBoton: 'Ver empleos',
    acceso: 'Salario pretendido',
  },
  misPostulaciones: {
    labelPostulaciones: 'Postulaciones',
    labelLeidos: 'Cv leído',
    labelPendientes: 'Incompletas',
    labelVisualizaciones: 'Quién vio tu {{cv}}',
    labelEmpresas: 'Empresas',
    labelRecomendaciones: 'Recomendaciones',
    tooltipEmpresas: 'Empresas que encontraron tu {{cv}} en la base de {{nombreSitio}}',
    tooltipRecomendaciones: 'Recomendamos tu {{cv}} a una vacante abierta',
    empty: 'Las empresas aún no revisaron vacantes',
    boton: 'Ir a mis postulaciones',
    botonActividad: 'Ver más',
    acceso: 'Mi actividad',
    tituloPostulaciones: 'Mis postulaciones',
  },
  recomendados: {
    titulo: 'Empleos recomendados por IA',
    empty:
      'Estas recomendaciones las realizamos con Inteligencia Artificial en base a tu actividad, información de tu perfil y tus últimas postulaciones.',
    emptyBoton: 'Ver empleos',
    modal: {
      noInteresado: {
        titulo: '¿Por qué no te interesa este aviso?',
        subtitulo: 'Selecciona una o más respuestas:',
        otros: 'Brindanos más detalle',
        otrosPlaceholder: 'Escribe alguna de las razones o puedes sumar otras nuevas en este comentario',
      },
    },
  },
  empresasRecomendadas: {
    empty:
      'Estas recomendaciones las realizamos con Inteligencia Artificial en base a tu actividad, información de tu perfil y tus últimas postulaciones.',
    emptyBoton: 'Ver empresas',
  },
  disponibilidad: {
    title: 'Informa a las empresas tu disponibilidad',
    suggestion: {
      description: 'Te sugerimos confirmar:',
      text1: 'Tus datos de contacto',
      text2: 'Que tu {{cv}} sea público',
    },
    label: {
      disponibleOfertas: 'Escucho propuestas de empleo',
      disponibleInmediato: 'Puedo empezar de inmediato',
    },
  },
}
