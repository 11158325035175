import React, { useEffect, useState } from 'react'

// Tools
import { connect } from 'react-redux'

// Components
import { Toggle } from '@navent-jobs/ui-kit/components/toggle'

// Styles
import { ToggleWrapper } from './mixins'
import { SwitchAlertType } from '../../../../../../redux/types/alert-v2'
import { StatusType } from '../../../../../../redux/types/status'

interface ToogleAlertProps {
  alertInfo: SwitchAlertType
  onToogle: (status) => void
  statusSwitch: StatusType
}

const ToogleAlert = ({ alertInfo, onToogle, statusSwitch }: ToogleAlertProps) => {
  const [currentState, setCurrentState] = useState(alertInfo.status)
  const [previusState, setPreviusState] = useState(alertInfo.status)

  const handlerEnableAlert = e => {
    setCurrentState(e.target.checked)
    onToogle({ id: alertInfo.id, status: e.target.checked })
  }

  useEffect(() => {
    if (statusSwitch.success) {
      setPreviusState(currentState)
    }
    if (statusSwitch.error) {
      setCurrentState(previusState)
    }
  }, [statusSwitch])

  return (
    <ToggleWrapper>
      <Toggle size="medium" disabled={!alertInfo} isChecked={currentState} onChange={e => handlerEnableAlert(e)} />
    </ToggleWrapper>
  )
}

const states = ({ alertsV2Store }) => {
  return {
    statusSwitch: alertsV2Store.switchAlert.status,
  }
}

export default connect(states)(ToogleAlert)
