import { put, call, takeLatest } from 'redux-saga/effects'
import { IS_ZONA_JOBS } from '../../constants'
import services from '../../services'
import {
  GET_LISTADO_AVISOS_EMPRESA,
  GET_LISTADO_AVISOS_EMPRESA_SUCCESS,
  GET_LISTADO_AVISOS_EMPRESA_ERROR,
  GET_FICHA_EMPRESA,
  GET_FICHA_EMPRESA_SUCCESS,
  GET_FICHA_EMPRESA_ERROR,
  SET_FICHA_EMPRESA_FILTERS_APPLIED,
  SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS,
  SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR,
  POST_SEGUIR_EMPRESA,
  POST_SEGUIR_EMPRESA_SUCCESS,
  POST_SEGUIR_EMPRESA_ERROR,
  DELETE_SEGUIR_EMPRESA,
  DELETE_SEGUIR_EMPRESA_SUCCESS,
  DELETE_SEGUIR_EMPRESA_ERROR,
  GET_SEGUIR_EMPRESA,
  GET_SEGUIR_EMPRESA_SUCCESS,
  GET_SEGUIR_EMPRESA_ERROR,
  REFRESH_EMPRESAS_PERFIL,
} from '../types/company-summary/company-summary'
import { GET_COMPANIES_RECOMMENDED } from '../types/listado-empresas'
import { GET_LISTADO_EMPRESAS_SEGUIDAS } from '../types/save-company'

function* getListadoAvisos({ payload }) {
  try {
    const result = yield call(services.companySummaryAvisos.getAvisosEmpresa, payload)
    yield put({ type: GET_LISTADO_AVISOS_EMPRESA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_LISTADO_AVISOS_EMPRESA_ERROR, error })
  }
}

function* getFichaEmpresa({ payload }) {
  try {
    const result = yield call(services.companySummaryAvisos.getFichaEmpresa, payload.id)
    yield put({ type: GET_FICHA_EMPRESA_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_FICHA_EMPRESA_ERROR, error })
  }
}

function* setFilterApplied({ payload }) {
  try {
    yield put({ type: SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS, payload })
  } catch (error) {
    yield put({ type: SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR, error })
  }
}

function* seguirEmpresa({ payload }) {
  try {
    yield call(services.companySummaryAvisos.seguirEmpresa, payload)
    yield put({ type: POST_SEGUIR_EMPRESA_SUCCESS, payload })
    yield put({ type: REFRESH_EMPRESAS_PERFIL, recomendados: true })
  } catch (error) {
    yield put({ type: POST_SEGUIR_EMPRESA_ERROR, payload })
  }
}

function* dejarDeSeguirEmpresa({ payload }) {
  try {
    yield call(services.companySummaryAvisos.dejarDeSeguirEmpresa, payload)
    yield put({ type: DELETE_SEGUIR_EMPRESA_SUCCESS, payload })
    yield put({ type: REFRESH_EMPRESAS_PERFIL, recomendados: false })
  } catch (error) {
    yield put({ type: DELETE_SEGUIR_EMPRESA_ERROR, payload })
  }
}

function* refreshEmpresasPerfil({ recomendados }) {
  if (
    (IS_ZONA_JOBS && window.location.pathname.includes('/postulantes/perfil')) ||
    (!IS_ZONA_JOBS && window.location.pathname === '/')
  ) {
    if (recomendados) {
      yield put({ type: GET_COMPANIES_RECOMMENDED, payload: { pageSize: 5 } })
    }
    yield put({ type: GET_LISTADO_EMPRESAS_SEGUIDAS, payload: { pageSize: 1 } })
  }
}

function* esSeguidor({ payload }) {
  try {
    const result = yield call(services.companySummaryAvisos.esSeguidor, payload)
    yield put({ type: GET_SEGUIR_EMPRESA_SUCCESS, payload: result.siguiendo })
  } catch (error) {
    yield put({ type: GET_SEGUIR_EMPRESA_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_LISTADO_AVISOS_EMPRESA, getListadoAvisos)
  yield takeLatest<any>(GET_FICHA_EMPRESA, getFichaEmpresa)
  yield takeLatest<any>(SET_FICHA_EMPRESA_FILTERS_APPLIED, setFilterApplied)
  yield takeLatest<any>(POST_SEGUIR_EMPRESA, seguirEmpresa)
  yield takeLatest<any>(DELETE_SEGUIR_EMPRESA, dejarDeSeguirEmpresa)
  yield takeLatest<any>(GET_SEGUIR_EMPRESA, esSeguidor)
  yield takeLatest<any>(REFRESH_EMPRESAS_PERFIL, refreshEmpresasPerfil)
}
