import React, { MouseEventHandler } from 'react'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'
import {
  CardWrapper,
  CloseContainer,
  IconColumn,
  ImageContainer,
  ImageCustom,
  SpaceContainer,
  TextContainer,
} from './mixins'
import { BannerConsejo } from '../../constants/banner-consejos.ts/banners-consejos'

const BannerConsejos = ({
  bannerConsejo,
  action,
  fullWidth = false,
}: {
  bannerConsejo?: BannerConsejo
  action: MouseEventHandler
  fullWidth?: boolean
}) => {
  const { isMobile, isTabletPortrait } = useScreenSize()
  const isMobileOrPortrait = isMobile || isTabletPortrait

  if (!bannerConsejo) {
    return null
  }

  const { title, description, image, icon } = bannerConsejo

  return (
    <CardWrapper>
      <TextContainer>
        <Text size="sm" fontWeight="semibold">
          {title}
        </Text>
        <Text size="sm">{description}</Text>
      </TextContainer>
      <SpaceContainer>
        <ImageContainer fullWidth={fullWidth}>
          {!isMobileOrPortrait && <ImageCustom src={image} fullWidth={fullWidth} />}
          <IconColumn>
            <CloseContainer id="banner-consejo-cerrar" onClick={action}>
              <Icon name="icon-light-close" size="20" color="black" />
            </CloseContainer>
            {isMobileOrPortrait && <ImageCustom src={image} fullWidth={fullWidth} />}
            <Icon name={icon} size="20" color="white" />
          </IconColumn>
        </ImageContainer>
      </SpaceContainer>
    </CardWrapper>
  )
}

export default BannerConsejos
