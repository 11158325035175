import { Button } from '@navent-jobs/ui-kit/components/button'
import styled from 'styled-components'

export const TitleBodyContainer = styled.div`
  text-align: center;
`

export const ModalBodyContainer = styled.div`
  margin-top: -32px;
  padding: 0 2px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    max-height: 520px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2px;
  }
`
export const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-top: 24px;
  }
`
export const FooterContent = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ModalFooterComponent = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    padding: 0px 64px;
  }
`
export const CustomButton = styled(Button)`
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    min-width: 200px;
  }
  @media (max-width: ${({ theme }) => theme.screen.smMin}) {
    border: none;
    margin: 6px 4px;
  }
`

export const InputWrapper = styled.div`
  margin-bottom: 4px;
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    margin-bottom: 12px;
  }
`
export const SelectWrapper = styled.div`
  margin-bottom: 12px;
  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    margin-bottom: 32px;
  }
`
