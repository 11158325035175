import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ComponentContainer = styled('div')`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
`

export const Heading = styled('div')`
  display: flex;
  align-items: center;
`

export const ImageCustom = styled(Image)`
  margin-right: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey60};
  margin: 0;
`

export const ClaimText = styled('div')`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  & > i {
    &:before {
      background-color: ${({ theme }) => theme.colors.primary.normal} !important;
    }
  }
`

export const IconCustom = styled(Icon)`
  margin-right: 5px;
`

export const Text = styled('div')`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary.normal};

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 14px;
  }
`
