import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Autocomplete } from '@navent-jobs/ui-kit/components/select'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'

// redux
import { getApplicantReview } from '../../../../redux/actions/review'
import { getFichaEmpresa } from '../../../../redux/actions/company-summary'
import { showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import companyList from '../../../../services/listado-empresas'

// components
import { StepButtons } from '../../../../components/StepButtons'

// style
import { CustomForm, CustonImg, GeneralTitle, InputContainer, CenterContainer, ErrorTextContainer } from './mixins'
import { Asterisco } from '../form-header/mixins'

import { ContentWrapper, FlexContainer } from '../../mixins'
import { DataLayersReviews } from '../../../../constants/gtm-events-reviews'
import calificarImg from '../../../../constants/calificar-img'

const Component = ({ successHandler, applicantReview, reviewState, companyState }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const minQueryLength = 3
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selected, setSelected] = useState<{ label: string; value: number } | undefined>(undefined)
  const [isValid, setIsValid] = useState(false)
  const [searchText, setSearchText] = useState('')
  const showError = reviewState.success && applicantReview && selected

  const handlerGetCompanies = (value: string) => {
    setSearchText(value)
    return companyList.getCompaniesSuggestion(value, true).then(resp => {
      return resp.map(company => {
        return {
          label: company.name,
          id: company.companyId,
        }
      })
    })
  }

  useEffect(() => {
    if (reviewState.success && !applicantReview && selected) {
      setIsValid(true)
    }

    if (reviewState.error) {
      dispatch(showSnackbarError(t('review.error')))
    }
  }, [reviewState])

  useEffect(() => {
    if (companyState.success && isValid) {
      successHandler()
    }

    if (companyState.error) {
      dispatch(showSnackbarError(t('review.error')))
    }
  }, [companyState])

  useEffect(() => {
    DataLayersReviews.selectCompanyPrint()
  }, [])

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <MainContainer>
          <Row>
            <Col>
              <FlexContainer>
                <ContentWrapper>
                  <CustomForm>
                    <CenterContainer>
                      <CustonImg src={calificarImg.buscarEmpresa} alt="calificar" />
                      <GeneralTitle>
                        <Text size="lg" fontWeight="semibold">
                          {t('review.companies.title')}
                        </Text>
                      </GeneralTitle>
                      <InputContainer>
                        <Text size="md">
                          {t('review.companies.input')}
                          <Asterisco>*</Asterisco>
                        </Text>

                        <Autocomplete
                          name="find-companies"
                          asyncOptions={handlerGetCompanies}
                          value={selected}
                          onChange={(_, company) => {
                            setIsValid(false)
                            if (!company) {
                              setSelected(undefined)
                            } else {
                              const label = company.label
                              const value = company.id
                              setSelected({ label, value })
                              dispatch(getApplicantReview(value))
                            }
                          }}
                          onBlur={() => setSearchText('')}
                          placeholder={t('searchBarCompanies.placeholder')}
                          fieldOptions={{
                            variant: 'darken',
                            size: 'medium',
                          }}
                          minQueryLength={minQueryLength}
                          isClearable
                          maxMenuHeight={200}
                          blurOnSelect
                          rules={{ required: true }}
                          noOptionsMsg={
                            searchText?.length >= minQueryLength
                              ? t('review.companies.noResults', { caracteres: minQueryLength })
                              : t('searchBarCompanies.autocomplete', { caracteres: minQueryLength })
                          }
                        />
                      </InputContainer>
                    </CenterContainer>
                    {showError && (
                      <ErrorTextContainer>
                        <Icon name="icon-light-info" color={colors.grey84} size="20" />
                        <Text size="sm">{t('review.companies.alreadyReviewed')}</Text>
                      </ErrorTextContainer>
                    )}
                  </CustomForm>
                </ContentWrapper>
              </FlexContainer>
            </Col>
          </Row>
        </MainContainer>
      </form>
      <StepButtons
        buttonsOptions={{
          primaryButton: {
            isLoading: reviewState.loading || companyState.loading,
            isValid,
            label: t('review.next'),
            action: () => {
              if (selected) {
                dispatch(getFichaEmpresa(selected.value))
              }
              DataLayersReviews.stepClick('empresas', 'Continuar')
            },
          },
        }}
      />
    </>
  )
}

const mapStateToProps = ({ reviewStore, companySummaryStore }) => ({
  applicantReview: reviewStore.applicantReview,
  reviewState: reviewStore.states,
  companyState: companySummaryStore.fichaEmpresaStatus,
})

export default connect(mapStateToProps)(Component)
