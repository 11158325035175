import styled from 'styled-components'

export const CustomForm = styled.div`
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-color: white;
  min-height: calc(44vh);

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px;
  }
`

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CustonImg = styled.img`
  width: 256px;
  height: 160px;
  margin-bottom: 16px;
`

export const GeneralTitle = styled.div`
  margin: 8px 0 16px;
  text-align: center;
`

export const InputContainer = styled.div`
  width: 100%;
`

export const ErrorTextContainer = styled.span`
  display: flex;
  gap: 4px;
`
