import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholderMobile = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const widthWithMargin = wrapperWidth - 32
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={256}
        viewBox="0 0 100% 100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 0 8 a 8 8 0 18 -8 h 312 a 8 8 0 18 8 v 312 a 8 8 0 1 -8 8 H 8 a 8 8 0 1 -8 -8 V 8 z" />
        <path d="M 0 0 h 6 v 328 H 0 z" />

        <rect x="16" y="12" rx="2" ry="2" width={widthWithMargin} height="12" />
        <rect x="16" y="32" rx="2" ry="2" width={widthWithMargin * 0.5} height="12" />

        <rect x="16" y="58" rx="4" ry="4" width="68" height="44" />
        <rect x="96" y="60" rx="2" ry="2" width={widthWithMargin * 0.6} height="12" />
        <rect x="96" y="80" rx="2" ry="2" width={widthWithMargin * 0.5} height="12" />

        <rect x="16" y="112" rx="2" ry="2" width={widthWithMargin} height="9" />
        <rect x="16" y="130" rx="2" ry="2" width={widthWithMargin} height="9" />

        <rect x="16" y="148" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="152" rx="2" ry="2" width="99" height="8" />
        <rect x="16" y="168" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="172" rx="2" ry="2" width="144" height="8" />
        <rect x="16" y="188" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="192" rx="2" ry="2" width="111" height="8" />
        <rect x="16" y="208" rx="4" ry="4" width="16" height="16" />
        <rect x="36" y="212" rx="2" ry="2" width={widthWithMargin * 0.3} height="8" />
        <rect x={widthWithMargin - 86} y="224" rx="4" ry="4" width="102" height="20" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholderMobile
