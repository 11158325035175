import React from 'react'
import { CustomButton, StickyContent } from './mixins'

interface PrimaryButtonProps {
  label: string
  action: () => void
  isLoading?: boolean
  isValid?: boolean
}

interface SecondaryButtonProps {
  label: string
  action?: () => void
  isValid?: boolean
}
interface StepButtonsProps {
  buttonsOptions: {
    primaryButton: PrimaryButtonProps
    secondaryButton?: SecondaryButtonProps
  }
  typeWidthButtons?: boolean
}

export const StepButtons = ({ buttonsOptions, typeWidthButtons = false }: StepButtonsProps) => {
  const { primaryButton, secondaryButton } = buttonsOptions
  const {
    label: primaryLabel,
    action: primaryAction,
    isLoading: primaryIsLoading,
    isValid: primaryIsValid,
  } = primaryButton

  const { label: secondaryLabel, action: secondaryAction, isValid: secondaryIsValid = true } = secondaryButton || {}

  return (
    <StickyContent>
      {secondaryAction && (
        <CustomButton
          typeWidth={typeWidthButtons}
          onClick={e => {
            e.preventDefault()
            secondaryAction()
          }}
          disabled={!secondaryIsValid || primaryIsLoading}
          buttonoptions={{ variant: 'primary', type: 'link', size: 'medium', block: true }}
        >
          {secondaryLabel}
        </CustomButton>
      )}
      <CustomButton
        typeWidth={typeWidthButtons}
        disabled={!primaryIsValid || primaryIsLoading}
        onClick={e => {
          e.preventDefault()
          primaryAction()
        }}
        buttonoptions={{ variant: 'primary', size: 'medium', block: true }}
        isLoading={primaryIsLoading || false}
      >
        {primaryLabel}
      </CustomButton>
    </StickyContent>
  )
}
