import styled from 'styled-components'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ToogleSidebarMenu = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  box-shadow: none;
  width: auto;
  margin: 0px 4px 0px 6px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 0px 23px 0px 47px;
  }
`

const FilterScreenComponent = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`

export const LayerOneFilterScreenComponent = styled(FilterScreenComponent)`
  overflow: auto;
  ${({ height = 0 }) => `max-height: calc(100% - 151px - ${height}px);`}
`

export const LayerTwoFilterScreenComponent = styled(FilterScreenComponent)`
  overflow: auto;
  padding: 0px 16px;
`

const SidebarTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`

export const LayerOneSidebarTitle = styled(SidebarTitle)`
  padding: 14px 16px;
  height: auto;
`

export const LayerOneSidebarFooter = styled.div`
  padding: 14px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  background-color: white;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grey8};
  }
`

export const SidebarMenu = styled(Sidebar)`
  width: 100%;
  height: 100%;
  background-color: #ffe600;
  position: fixed;
  top: 0;
  ${({ position }) => (position === 'right' ? 'left: 0;' : 'right: 0;')}
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
`

export const RemoveAllFilters = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  &:active,
  &:focus {
    outline: 0;
  }
`

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`

export const CloseButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.normal};
  &:active,
  &:focus {
    outline: 0;
  }
`

const Filter = `
  border: 0px;
  padding: 14px 16px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  &:active, 
  &:focus {
    outline: 0; 
  }
`

export const IdleFilter = styled.button`
  ${Filter}
  color: ${({ theme }) => theme.colors.grey84};
  ${({ styles }) => styles}
`

export const DisabledFilterContainer = styled.div`
  ${Filter}
  color: ${({ theme }) => theme.colors.grey36};
`

export const FilterIcon = styled(Icon)`
  ${({ leftSide }) => leftSide && 'margin-right: 8px;'}
`

export const NewTag = styled.p`
  height: 20px;
  background-color: ${({ theme }) => theme.colors.primary.normal};
  font-family: 'Hind Vadodara';
  color: white;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  padding: 0 10px;
  margin: 0 0 0 8px;
  border-radius: 4px;
`

export const CustomText = styled('p')`
  margin: 0;
  color: ${({ active, theme }) => (active ? theme.colors.primary.normal : theme.colors.grey24)};
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
`

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
`

export const NewFilterRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const SelectedFilter = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin: 0;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

export const FilterBadge = styled.span`
  position: absolute;
  top: 0;
  right: -2px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.primary.lighten};

  font-family: 'Hind Vadodara';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: white;
  border-radius: 50%;
  border: ${({ theme }) => `2px solid ${theme.colors.secondary.ultraLighten}`};

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`
