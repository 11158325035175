import React from 'react'
// Tools
import { useHistory } from 'react-router-dom'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// Components
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import Image from '@navent-jobs/ui-kit/components/Image'
import { Link } from '@navent-jobs/ui-kit/components/link'

// mixins
import { Header, BrandLink, NavBar, Back, BackWording, LinkEmpresasWrapper } from './mixins'

// constants
import { SITE_ID } from '../../constants'
import logos from '../../constants/logos'

interface ComponentProps {
  callback: Function
  productoLookAndFeel: boolean
  showBackButton: boolean
  backButtonVariant: string
  backButtonWording?: string
  action?: Function
  linkEmpresas?: {
    url: string
    label: string
  }
}

/**
 * Componente Header.
 */
const Component = ({
  productoLookAndFeel,
  showBackButton = true,
  backButtonVariant = 'black',
  backButtonWording,
  action,
  linkEmpresas,
}: ComponentProps) => {
  const history = useHistory()
  const { isDesktop, isMobile } = useWindowSize()

  const siteId = SITE_ID || 'BMAR'
  const logo = logos[siteId]
  const sizeButton = isDesktop ? 'medium' : 'small'

  const handleOnClick = () => {
    history.goBack()
    action && action()
  }

  return (
    <Header>
      <MainContainer>
        <Row>
          <Col>
            <NavBar>
              {showBackButton && (
                <Back
                  onClick={handleOnClick}
                  productoLookAndFeel={productoLookAndFeel}
                  buttonoptions={{ variant: backButtonVariant, size: sizeButton, type: 'link' }}
                  icon={{ name: 'icon-light-arrow-left' }}
                >
                  {!isMobile && backButtonWording && <BackWording>{backButtonWording}</BackWording>}
                </Back>
              )}
              <BrandLink href="/">
                <Image {...logo} preload />
              </BrandLink>

              {linkEmpresas && !isMobile && (
                <LinkEmpresasWrapper>
                  <Link href={linkEmpresas.url} linkoptions={{ variant: 'primary', type: 'link', size: 'large' }}>
                    {linkEmpresas.label}
                  </Link>
                </LinkEmpresasWrapper>
              )}
            </NavBar>
          </Col>
        </Row>
      </MainContainer>
    </Header>
  )
}

export default Component
