import features from '../features-per-country'
import { SITE_ID } from '..'

export const rangeMaker = {
  ZJAR: 10000,
  BMAR: 10000,
  BMCL: 100000,
  BMMX: 1000,
  BMPE: 1000,
  BMPA: 100,
  BMEC: 100,
}

export const moneyIconName = {
  ZJAR: 'icon-light-money',
  BMAR: 'icon-light-money',
  BMCL: 'icon-light-money',
  BMMX: 'icon-light-money',
  BMPE: 'icon-light-money-peru',
  BMPA: 'icon-light-money-panama',
  BMEC: 'icon-light-money',
}

export const showSalarioPromedio = features[SITE_ID || 'BMAR']?.estadisticas.salarioPromedio
