import i18n from 'i18next'
import { NOMBRE_SITIO, NOMBRE_PAIS } from '../constants'

export class ErrorSeoWordings extends Error {}

const createFiltersByKey = filters => {
  if (!filters) return {}
  return filters.reduce((acc, filter) => {
    if (!filter.type) {
      acc[filter.id] = { ...filter, type: filter.id, name: filter.value }
      return acc
    }
    acc[filter.type] = filter
    return acc
  }, {})
}

const createVariablesOrdenadasPorPrioridad = (filtersByKey: any) => {
  if (!filtersByKey) return ''
  const variablesOrdenadas: string[] = []
  const area = (filtersByKey.subarea && filtersByKey.subarea.name) || (filtersByKey.area && filtersByKey.area.name)
  if (area) variablesOrdenadas.push(`${area}`)
  if (filtersByKey.nivel_laboral && filtersByKey.nivel_laboral.name)
    variablesOrdenadas.push(filtersByKey.nivel_laboral.name)
  if (filtersByKey.tipo_trabajo && filtersByKey.tipo_trabajo.name)
    variablesOrdenadas.push(filtersByKey.tipo_trabajo.name)
  if (filtersByKey.modalidad_trabajo?.name) {
    variablesOrdenadas.push(filtersByKey.modalidad_trabajo.name)
  }
  let ubicacion = ''
  if (filtersByKey.localidad && filtersByKey.localidad.name) ubicacion += `${filtersByKey.localidad.name}, `
  if (filtersByKey.provincia && filtersByKey.provincia.name) ubicacion += filtersByKey.provincia.name
  if (ubicacion.length) variablesOrdenadas.push(`en ${ubicacion}`)
  return variablesOrdenadas.join(' ')
}

// export const getOption = ({ filtersByKey, filtersApplied }): any => {
//   // Seo Specs Switch:
//   // https://docs.google.com/spreadsheets/d/1wKVlVEzn5IctvGVgIbCYPm889gIJQ8vzGq166xuTmt4/edit#gid=0

//   // >> FILTERS APPLIED TYPES LIST:
//   // localidad
//   // nivel_laboral
//   // area
//   // subarea
//   // provincia
//   // tipo_trabajo
//   // dias_fecha_publicacion
//   // apto_discapacitado
//   // salario
//   // query

//   if (!filtersApplied || !filtersApplied.length) return 'root'

//   if (filtersApplied.length === 1) {
//     // 3
//     if (filtersByKey.area) return 'area'
//     // 5
//     if (filtersByKey.nivel_laboral) return 'nivel_laboral'
//     // 6
//     if (filtersByKey.tipo_trabajo) return 'tipo_trabajo'
//     // 7
//     if (filtersByKey.dias_fecha_publicacion) return 'dias_fecha_publicacion'
//     // 8
//     if (filtersByKey.apto_discapacitado) return 'apto_discapacitado'
//     // 9
//     if (filtersByKey.query) return 'query'
//     // 12
//     if (filtersByKey.provincia) return 'provincia'
//   }

//   if (filtersApplied.length === 2) {
//     // 4
//     if (filtersByKey.area && filtersByKey.subarea) return 'subarea'
//     // povincia + option
//     // 13
//     if (filtersByKey.localidad && filtersByKey.provincia) return 'localidad'
//     // 14
//     if (filtersByKey.provincia && filtersByKey.area) return 'provincia_area'
//     // 16
//     if (filtersByKey.provincia && filtersByKey.nivel_laboral) return 'provincia_nivel_laboral'
//     // 17
//     if (filtersByKey.provincia && filtersByKey.dias_fecha_publicacion) return 'provincia_dias_fecha_publicacion'
//     // 18
//     if (filtersByKey.provincia && filtersByKey.tipo_trabajo) return 'provincia_tipo_trabajo'
//     // 19
//     if (filtersByKey.provincia && filtersByKey.apto_discapacitado) return 'provincia_apto_discapacitado'
//     // 20
//     if (filtersByKey.provincia && filtersByKey.query) return 'provincia_query'
//   }

//   if (filtersApplied.length === 3) {
//     // 15
//     if (filtersByKey.provincia && filtersByKey.subarea) return 'provincia_subarea'
//     // povincia + localidad + option
//     // 21
//     if (filtersByKey.localidad && filtersByKey.area) return 'localidad_area'
//     // 23
//     if (filtersByKey.localidad && filtersByKey.nivel_laboral) return 'localidad_nivel_laboral'
//     // 24
//     if (filtersByKey.localidad && filtersByKey.dias_fecha_publicacion) return 'localidad_dias_fecha_publicacion'
//     // 25
//     if (filtersByKey.localidad && filtersByKey.tipo_trabajo) return 'localidad_tipo_trabajo'
//     // 26
//     if (filtersByKey.localidad && filtersByKey.apto_discapacitado) return 'localidad_apto_discapacitado'
//     // 27
//     if (filtersByKey.localidad && filtersByKey.query) return 'localidad_query'
//   }
//   if (filtersApplied.length === 4) {
//     // 22
//     if (filtersByKey.localidad && filtersByKey.subarea) return 'localidad_subarea'
//   }
//   return 'default'
// }

// export const createSeoWordings = search => {
//   // TODO USE grandTotal data
//   const { filtersApplied, grandTotal, filters: filtersToApply } = search
//   const siteName = NOMBRE_SITIO
//   const countryName = NOMBRE_PAIS

//   const filtersByKey = createFiltersByKey(filtersApplied)
//   const filtersToApplyByKey = createFiltersByKey(filtersToApply)
//   const variablesOrdenadasPorPrioridad = createVariablesOrdenadasPorPrioridad(filtersByKey)
//   const data = {
//     siteName,
//     grandTotal,
//     filtersByKey,
//     filtersToApplyByKey,
//     countryName,
//     variablesOrdenadasPorPrioridad,
//   }
//   // home
//   const option: string = getOption({ filtersByKey, filtersApplied })

//   // ----------BEGIN MOCKED DATA FOR TEST -----------
//   const mockfiltersByKey = {
//     localidad: { name: 'LOCALIDAD' },
//     nivel_laboral: { name: 'NIVEL_LABORAL' },
//     area: { name: 'AREA' },
//     subarea: { name: 'SUBAREA' },
//     provincia: { name: 'PROVINCIA' },
//     tipo_trabajo: { name: 'TIPO_TRABAJO' },
//     dias_fecha_publicacion: { name: 'DIAS_FECHA_PUBLICACION' },
//     apto_discapacitado: { name: 'APTO_DISCAPACITADO' },
//     salario: { name: 'SALARIO' },
//     query: { name: 'BUSQUEDA' },
//   }
//   const mockFiltersToApplyByKey = {
//     area: {
//       type: 'area',
//       facets: [{ name: 'AREA1' }, { name: 'AREA2' }],
//     },
//     subarea: {
//       type: 'subarea',
//       facets: [{ name: 'SUBAREA1' }, { name: 'SUBAREA2' }],
//     },
//     provincia: {
//       type: 'provincia',
//       facets: [{ name: 'PROVINCIA1' }, { name: 'PROVINCIA2' }],
//     },
//     localidad: {
//       type: 'localidad',
//       facets: [{ name: 'LOCALIDAD1' }, { name: 'LOCALIDAD2' }],
//     },
//     nivel_laboral: {
//       type: 'nivel_laboral',
//       facets: [{ name: 'NIVEL_LABORAL1' }, { name: 'NIVEL_LABORAL2' }],
//     },
//     tipo_trabajo: {
//       type: 'tipo_trabajo',
//       facets: [{ name: 'TIPO_TRABAJO1' }, { name: 'TIPO_TRABAJO2' }],
//     },
//   }

//   const mockData = {
//     siteName,
//     grandTotal,
//     filtersByKey: mockfiltersByKey,
//     filtersToApplyByKey: mockFiltersToApplyByKey,
//     countryName,
//     variablesOrdenadasPorPrioridad: 'VARIALBES_ORDENADAS_POR_PRIORIDAD',
//     title: 'TITLE',
//     company: ` en COMPANY`,
//     mes: 'MES',
//     anio: 'AÑO',
//   }

//   //console.log('ALL MOCKED TRANSLATIONS: ', i18n.t(`seoWordings`, { ...mockData, returnObjects: true }))
//   //console.log( 'ALL MOCKED TRANSLATIONS: ', JSON.stringify(i18n.t(`seoWordings`, { ...mockData, returnObjects: true }), null, 2))
//   // ----------END MOCKED DATA FOR TEST -----------

//   const seoData: { h1: string; title: string; description: string; preh1?: string } = i18n.t(`seoWordings.${option}`, {
//     ...data,
//     returnObjects: true,
//   })

//   return {
//     ...seoData,
//     option,
//   }
// }

class SeoWordings {
  public GetOption = ({ filtersByKey, filtersApplied }): any => {
    // Seo Specs Switch:
    // https://docs.google.com/spreadsheets/d/1wKVlVEzn5IctvGVgIbCYPm889gIJQ8vzGq166xuTmt4/edit#gid=0

    // >> FILTERS APPLIED TYPES LIST:
    // localidad
    // nivel_laboral
    // area
    // subarea
    // provincia
    // tipo_trabajo
    // dias_fecha_publicacion
    // apto_discapacitado
    // salario
    // query

    if (!filtersApplied || !filtersApplied.length) return 'root'

    if (filtersApplied.length === 1) {
      // 3
      if (filtersByKey.area) return 'area'
      // 5
      if (filtersByKey.nivel_laboral) return 'nivel_laboral'
      // 6
      if (filtersByKey.tipo_trabajo) return 'tipo_trabajo'
      // 7
      if (filtersByKey.dias_fecha_publicacion) return 'dias_fecha_publicacion'
      // 8
      if (filtersByKey.apto_discapacitado) return 'apto_discapacitado'
      // 9
      if (filtersByKey.query) return 'query'
      // 12
      if (filtersByKey.provincia) return 'provincia'
      if (filtersByKey.modalidad_trabajo) {
        return filtersByKey.modalidad_trabajo.id === 'desde-cualquier-lugar-del-mundo'
          ? 'internacional'
          : 'modalidad_trabajo'
      }
    }

    if (filtersApplied.length === 2) {
      // 4
      if (filtersByKey.area && filtersByKey.subarea) return 'subarea'
      // povincia + option
      // 13
      if (filtersByKey.localidad && filtersByKey.provincia) return 'localidad'
      // 14
      if (filtersByKey.provincia && filtersByKey.area) return 'provincia_area'
      // 16
      if (filtersByKey.provincia && filtersByKey.nivel_laboral) return 'provincia_nivel_laboral'
      // 17
      if (filtersByKey.provincia && filtersByKey.dias_fecha_publicacion) return 'provincia_dias_fecha_publicacion'
      // 18
      if (filtersByKey.provincia && filtersByKey.tipo_trabajo) return 'provincia_tipo_trabajo'
      // 19
      if (filtersByKey.provincia && filtersByKey.apto_discapacitado) return 'provincia_apto_discapacitado'
      // 20
      if (filtersByKey.provincia && filtersByKey.query) return 'provincia_query'
      if (filtersByKey.dias_fecha_publicacion && filtersByKey.query) return 'dias_fecha_publicacion'
      if (filtersByKey.apto_discapacitado && filtersByKey.query) return 'apto_discapacitado'
    }

    if (filtersApplied.length === 3) {
      // 15
      if (filtersByKey.provincia && filtersByKey.subarea) return 'provincia_subarea'
      // povincia + localidad + option
      // 21
      if (filtersByKey.localidad && filtersByKey.area) return 'localidad_area'
      // 23
      if (filtersByKey.localidad && filtersByKey.nivel_laboral) return 'localidad_nivel_laboral'
      // 24
      if (filtersByKey.localidad && filtersByKey.dias_fecha_publicacion) return 'localidad_dias_fecha_publicacion'
      // 25
      if (filtersByKey.localidad && filtersByKey.tipo_trabajo) return 'localidad_tipo_trabajo'
      // 26
      if (filtersByKey.localidad && filtersByKey.apto_discapacitado) return 'localidad_apto_discapacitado'
      // 27
      if (filtersByKey.localidad && filtersByKey.query) return 'localidad_query'
    }
    if (filtersApplied.length === 4) {
      // 22
      if (filtersByKey.localidad && filtersByKey.subarea) return 'localidad_subarea'
    }
    return 'default'
  }

  public CreateSeoWordings = search => {
    // TODO USE grandTotal data
    const { filtersApplied, grandTotal, filters: filtersToApply, number } = search
    const siteName = NOMBRE_SITIO
    const countryName = NOMBRE_PAIS

    const filtersByKey = createFiltersByKey(filtersApplied)
    const filtersToApplyByKey = createFiltersByKey(filtersToApply)
    const variablesOrdenadasPorPrioridad = createVariablesOrdenadasPorPrioridad(filtersByKey)
    const data = {
      siteName,
      grandTotal,
      filtersByKey,
      filtersToApplyByKey,
      countryName,
      variablesOrdenadasPorPrioridad,
      pageNumber: number + 1,
    }
    // home
    const option: string = this.GetOption({ filtersByKey, filtersApplied })

    // ----------BEGIN MOCKED DATA FOR TEST -----------
    // const mockfiltersByKey = {
    //   localidad: { name: 'LOCALIDAD' },
    //   nivel_laboral: { name: 'NIVEL_LABORAL' },
    //   area: { name: 'AREA' },
    //   subarea: { name: 'SUBAREA' },
    //   provincia: { name: 'PROVINCIA' },
    //   tipo_trabajo: { name: 'TIPO_TRABAJO' },
    //   dias_fecha_publicacion: { name: 'DIAS_FECHA_PUBLICACION' },
    //   apto_discapacitado: { name: 'APTO_DISCAPACITADO' },
    //   salario: { name: 'SALARIO' },
    //   query: { name: 'BUSQUEDA' },
    // }
    // const mockFiltersToApplyByKey = {
    //   area: {
    //     type: 'area',
    //     facets: [{ name: 'AREA1' }, { name: 'AREA2' }],
    //   },
    //   subarea: {
    //     type: 'subarea',
    //     facets: [{ name: 'SUBAREA1' }, { name: 'SUBAREA2' }],
    //   },
    //   provincia: {
    //     type: 'provincia',
    //     facets: [{ name: 'PROVINCIA1' }, { name: 'PROVINCIA2' }],
    //   },
    //   localidad: {
    //     type: 'localidad',
    //     facets: [{ name: 'LOCALIDAD1' }, { name: 'LOCALIDAD2' }],
    //   },
    //   nivel_laboral: {
    //     type: 'nivel_laboral',
    //     facets: [{ name: 'NIVEL_LABORAL1' }, { name: 'NIVEL_LABORAL2' }],
    //   },
    //   tipo_trabajo: {
    //     type: 'tipo_trabajo',
    //     facets: [{ name: 'TIPO_TRABAJO1' }, { name: 'TIPO_TRABAJO2' }],
    //   },
    // }

    // const mockData = {
    //   siteName,
    //   grandTotal,
    //   filtersByKey: mockfiltersByKey,
    //   filtersToApplyByKey: mockFiltersToApplyByKey,
    //   countryName,
    //   variablesOrdenadasPorPrioridad: 'VARIALBES_ORDENADAS_POR_PRIORIDAD',
    //   title: 'TITLE',
    //   company: ` en COMPANY`,
    //   mes: 'MES',
    //   anio: 'AÑO',
    // }

    // console.log('ALL MOCKED TRANSLATIONS: ', i18n.t(`seoWordings`, { ...mockData, returnObjects: true }))
    // console.log( 'ALL MOCKED TRANSLATIONS: ', JSON.stringify(i18n.t(`seoWordings`, { ...mockData, returnObjects: true }), null, 2))
    // ----------END MOCKED DATA FOR TEST -----------

    let seoData: { h1: string; title: string; description: string; preh1?: string }
    const searchKeyword = sessionStorage.getItem('search-keyword')
    if (option === 'query' && searchKeyword) {
      seoData = i18n.t(`seoWordings.${option}`, {
        ...data,
        filtersByKey: { query: { name: searchKeyword } },
        returnObjects: true,
      })
    } else {
      seoData = i18n.t(`seoWordings.${option}`, {
        ...data,
        returnObjects: true,
      })
    }

    if (searchKeyword) sessionStorage.removeItem('search-keyword')

    return {
      ...seoData,
      option,
    }
  }
}

const seoWordings = new SeoWordings()
export default seoWordings
