import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_APPLICANT_NOTIFICATIONS,
  GET_APPLICANT_NOTIFICATIONS_ERROR,
  GET_APPLICANT_NOTIFICATIONS_SUCCESS,
  GET_APPLICANT_UNREAD_NOTIFICATIONS,
  GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR,
  GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS,
  READ_MARK_APPLICANT_NOTIFICATIONS,
  UPDATE_APPLICANT_FCM_TOKEN,
} from '../types/notifications'

function* getApplicantUnreadNotifications() {
  try {
    const result = yield call(services.notifications.GetUnreadNotifications)
    yield put({ type: GET_APPLICANT_UNREAD_NOTIFICATIONS_SUCCESS, payload: result.count })
  } catch {
    yield put({ type: GET_APPLICANT_UNREAD_NOTIFICATIONS_ERROR, payload: null })
  }
}

function* getApplicantNotifications() {
  try {
    const notificationList = yield call(services.notifications.GetNotificationsList)
    yield put({ type: GET_APPLICANT_NOTIFICATIONS_SUCCESS, payload: notificationList })
  } catch {
    yield put({ type: GET_APPLICANT_NOTIFICATIONS_ERROR, payload: null })
  }
}

function* marckAsReadNotifications() {
  try {
    yield call(services.notifications.MarkReadaNotificationsList)
    // eslint-disable-next-line no-empty
  } catch {}
}

function* updateTokenFCM() {
  try {
    yield call(services.notifications.updateTokenFCM.bind(services.notifications))
    // eslint-disable-next-line no-empty
  } catch {}
}

/**
 * Watchers
 */
export default function* notification() {
  yield takeLatest<any>(GET_APPLICANT_UNREAD_NOTIFICATIONS, getApplicantUnreadNotifications)
  yield takeLatest<any>(GET_APPLICANT_NOTIFICATIONS, getApplicantNotifications)
  yield takeLatest<any>(READ_MARK_APPLICANT_NOTIFICATIONS, marckAsReadNotifications)
  yield takeLatest<any>(UPDATE_APPLICANT_FCM_TOKEN, updateTokenFCM)
}
