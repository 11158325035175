import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import React from 'react'
import ContentLoader from 'react-content-loader'

const DesktopSkeleton = props => (
  <ContentLoader
    speed={2}
    width={684}
    height={376}
    viewBox="0 0 684 376"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="6" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="51" rx="2" ry="2" width="133" height="16" />
    <rect x="0" y="222" rx="2" ry="2" width="133" height="16" />
    <rect x="614" y="49" rx="2" ry="2" width="20" height="20" />
    <rect x="614" y="220" rx="2" ry="2" width="20" height="20" />
    <rect x="578" y="49" rx="2" ry="2" width="20" height="20" />
    <rect x="578" y="220" rx="2" ry="2" width="20" height="20" />
    <rect x="0" y="87" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="258" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="107" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="278" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="127" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="298" rx="2" ry="2" width="560" height="12" />
    <rect x="0" y="147" rx="2" ry="2" width="305" height="12" />
    <rect x="0" y="318" rx="2" ry="2" width="305" height="12" />
    <rect x="0" y="175" rx="2" ry="2" width="173" height="16" />
    <rect x="0" y="346" rx="2" ry="2" width="173" height="16" />
  </ContentLoader>
)

const MobileSkeleton = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={396}
    viewBox="0 0 100% 396"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="6" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="49" rx="2" ry="2" width="133" height="16" />
    <rect x="0" y="240" rx="2" ry="2" width="133" height="16" />
    <rect x="calc(100% - 29px)" y="47" rx="2" ry="2" width="20" height="20" />
    <rect x="calc(100% - 29px)" y="242" rx="2" ry="2" width="20" height="20" />
    <rect x="calc(100% - 65px)" y="47" rx="2" ry="2" width="20" height="20" />
    <rect x="calc(100% - 65px)" y="242" rx="2" ry="2" width="20" height="20" />
    <rect x="0" y="85" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="276" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="105" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="296" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="125" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="316" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="145" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="336" rx="2" ry="2" width="calc(100% - 3px)" height="12" />
    <rect x="0" y="165" rx="2" ry="2" width="139" height="12" />
    <rect x="0" y="356" rx="2" ry="2" width="139" height="12" />
    <rect x="0" y="189" rx="2" ry="2" width="173" height="16" />
    <rect x="0" y="380" rx="2" ry="2" width="173" height="16" />
  </ContentLoader>
)

export const SkeletonAlerts = () => {
  const { isMobile } = useWindowSize()

  return isMobile ? <MobileSkeleton /> : <DesktopSkeleton />
}
