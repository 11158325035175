import { AddIdiomasType, UpdateIdiomaType } from '../commons'

export const DELETE_IDIOMA = 'DELETE_IDIOMA'
export const DELETE_IDIOMA_SUCCESS = 'DELETE_IDIOMA_SUCCESS'
export const DELETE_IDIOMA_ERROR = 'DELETE_IDIOMA_ERROR'
export const DELETE_IDIOMA_RESET = 'DELETE_IDIOMA_RESET'

export const UPDATE_IDIOMA = 'UPDATE_IDIOMA'
export const UPDATE_IDIOMA_SUCCESS = 'UPDATE_IDIOMA_SUCCESS'
export const UPDATE_IDIOMA_ERROR = 'UPDATE_IDIOMA_ERROR'
export const UPDATE_IDIOMA_RESET = 'UPDATE_IDIOMA_RESET'

export const ADD_IDIOMAS = 'ADD_IDIOMAS'
export const ADD_IDIOMAS_SUCCESS = 'ADD_IDIOMAS_SUCCESS'
export const ADD_IDIOMAS_ERROR = 'ADD_IDIOMAS_ERROR'
export const ADD_IDIOMAS_RESET = 'ADD_IDIOMAS_RESET'

// Delete idioma
export interface DeleteIdioma {
  type: typeof DELETE_IDIOMA
  payload: string
}
export interface DeleteIdiomaSuccess {
  type: typeof DELETE_IDIOMA_SUCCESS
  payload: null
}
export interface DeleteIdiomaError {
  type: typeof DELETE_IDIOMA_ERROR
  payload: null
}
export interface DeleteIdiomaReset {
  type: typeof DELETE_IDIOMA_RESET
  payload: null
}

// Update idioma
export interface UpdateIdioma {
  type: typeof UPDATE_IDIOMA
  payload: UpdateIdiomaType
}
export interface UpdateIdiomaSuccess {
  type: typeof UPDATE_IDIOMA_SUCCESS
  payload: null
}
export interface UpdateIdiomaError {
  type: typeof UPDATE_IDIOMA_ERROR
  payload: null
}
export interface UpdateIdiomaReset {
  type: typeof UPDATE_IDIOMA_RESET
  payload: null
}

// Add idiomas
export interface AddIdiomas {
  type: typeof ADD_IDIOMAS
  payload: AddIdiomasType[]
}
export interface AddIdiomasSuccess {
  type: typeof ADD_IDIOMAS_SUCCESS
  payload: null
}
export interface AddIdiomasError {
  type: typeof ADD_IDIOMAS_ERROR
  payload: null
}
export interface AddIdiomasReset {
  type: typeof ADD_IDIOMAS_RESET
  payload: null
}
