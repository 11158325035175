import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// utils
import { goToMP } from '../../../../utils/mercado-pago'

const CheckStatusWrapper = ({ action, children, productName, disponibilidad }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const callAction = () => {
    goToMP({ action, dispatch, history, productName, disponibilidad })
  }

  return <>{React.cloneElement(children, { action: { primaryAction: callAction } })}</>
}

const states = ({ productsStore }) => ({
  disponibilidad: productsStore?.productAvailability?.availability,
})

export default connect(states)(CheckStatusWrapper)
