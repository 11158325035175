import styled from 'styled-components'
import Image from '@navent-jobs/ui-kit/components/Image'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;
  max-width: 632px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 52px;
    line-height: 64px;
  }
`

export const CustomRow = styled(Row)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row-reverse;
    align-items: center;
  }
`

export const ImageCol = styled(Col)`
  display: flex;
  justify-content: right;
`

export const TitleCol = styled(Col)`
  display: flex;
  justify-content: right;
`

export const CustomLink = styled(Link)`
  max-width: 632px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 160px;
  }
`

export const CustomImg = styled(Image)`
  margin-top: 20px;
  width: 81%;
  max-width: 547px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: none;
    margin: 0;
    width: auto;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 830px;
  width: 100%;
  margin: -25px 4px 20px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    display: block;
    margin: 0px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  margin-left: auto;
  margin-right: auto;
  background: no-repeat 0% 100% url(${({ bkg }) => bkg}), ${({ theme }) => theme.colors.grey0};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    flex-direction: row-reverse;
    align-items: center;
  }
`
