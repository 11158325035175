import { merge } from 'lodash'
import all from '../all'
import curriculum from './curriculum'
import pdaPago from './pda-pago'
import marketplace from './marketplace'
import products from './products'
import userPremium from './user-premium'
import sortEmpresas from './sortEmpresas'
import fichaAviso from './ficha-aviso'
import avisosFake from './avisos-fake'

const bmpe = {
  ...all,
  curriculum: merge({}, all.curriculum, curriculum),
  pdaPago,
  marketplace: merge({}, all.marketplace, marketplace),
  products: merge({}, all.products, products),
  userPremium: { ...all.userPremium, ...userPremium },
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  avisosFake: merge({}, all.avisosFake, avisosFake),
}
export default bmpe
