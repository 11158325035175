import styled from 'styled-components'

export const Section = styled.div`
  background: no-repeat 0% 100% url(${({ bkg }) => bkg}), white;
  padding: 24px 16px 12px;

  div:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 24px;
  }
`

export const Title = styled('p')`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 48px;
  }
`
