import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Snackbar } from '@navent-jobs/ui-kit/components/snackbar'
import { debounce } from 'lodash'
import { hideSnackbar } from '../../redux/actions/applicant/curriculum/snackbar'
import { SnackbarText } from './mixins'

// Snackbar preconfigurada para uso general, trabaja mediante el redux de showSnackbar
// mediante los actions showSnackbar y showSnackbarError
const Component = ({ showSnackbar }) => {
  const dispatch = useDispatch()
  const [remainTime] = useState(() => debounce(() => dispatch(hideSnackbar()), 5000))
  const showSnackbarType = showSnackbar.error ? 'error' : 'notification'
  const snackbarIcon = showSnackbar.error ? 'icon-light-error' : showSnackbar.icon
  const [visible, setVisible] = useState(false)

  // Este useEffect es necesarios para que solo se muestre cuando el compoenente este montado,
  // de lo contrario rompe la pagina cuando te registras con google (No eliminar)
  useEffect(() => {
    setVisible(showSnackbar.visible)
  }, [showSnackbar])

  return (
    <Snackbar
      visible={visible}
      snackbaroptions={{
        type: showSnackbarType,
        icon: snackbarIcon !== null ? snackbarIcon : undefined,
        timer: remainTime,
        animation: 'bounceUpAndDown',
      }}
    >
      <SnackbarText>{showSnackbar.text}</SnackbarText>
    </Snackbar>
  )
}

const states = ({ applicantStore }) => {
  return {
    showSnackbar: applicantStore.showSnackbar,
  }
}
export default connect(states)(Component)
