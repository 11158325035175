import React from 'react'
import { IS_ZONA_JOBS } from '../../../../constants'
import ResendEmailImageBum from '../../../../assets/images/man-sending-email.svg'
import ResendEmailImageZona from '../../../../assets/images/man-sending-email-zj.svg'
import { ComponentContainer, CustomImage } from './mixins'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage src={IS_ZONA_JOBS ? ResendEmailImageZona : ResendEmailImageBum} alt="Resend email Image" />
    </ComponentContainer>
  )
}

export default Component
