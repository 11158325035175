import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  guardarAvisoListado: location => {
    if (
      location &&
      (location.includes('/empleos') || location.includes('/empleos-')) &&
      !location.includes('/empleos/')
    ) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: 'Listado',
        eventAction: 'Favorito',
        eventLabel: `BTN favoriteo`,
      })
    }
  },
  guardarAvisoFicha: location => {
    if (location && location.includes('/empleos/')) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: 'Aviso',
        eventAction: 'Favorito',
        eventLabel: `BTN favoriteo`,
      })
    }
  },
  verListado: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Menu Header',
      eventLabel: `Logueado-Desplegable Mi cuenta_Mis avisos guardados`,
    }),
}
