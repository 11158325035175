import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  SET_VISIBLE_RESULT,
  SET_VISIBLE_RESULT_SUCCESS,
  SET_VISIBLE_RESULT_ERROR,
  GET_LANGUAGE_TEST_RESULT,
  GET_LANGUAGE_TEST_RESULT_SUCCESS,
  GET_LANGUAGE_TEST_RESULT_ERROR,
  SetVisibleResult,
  GetLanguageTestResult,
} from '../types/language-test'

function* getLanguageTestResult({ payload }: GetLanguageTestResult) {
  try {
    const result = yield call(services.languageTest.GetLanguageTestResult, payload.provider, payload.language)
    yield put({ type: GET_LANGUAGE_TEST_RESULT_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_LANGUAGE_TEST_RESULT_ERROR, error })
  }
}

function* setVisibleResult({ payload }: SetVisibleResult) {
  try {
    yield call(services.languageTest.ChangeVisibilityToCompany, payload.value, payload.provider, payload.language)
    yield put({ type: SET_VISIBLE_RESULT_SUCCESS, payload: payload.value })
    yield put({ type: GET_LANGUAGE_TEST_RESULT, payload: { provider: payload.provider, language: payload.language } })
  } catch (error) {
    yield put({ type: SET_VISIBLE_RESULT_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* languageTest() {
  yield takeLatest<any>(GET_LANGUAGE_TEST_RESULT, getLanguageTestResult)
  yield takeLatest<any>(SET_VISIBLE_RESULT, setVisibleResult)
}
