import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// store
import { confirmCandidateAccount } from '../../redux/actions/applicant/confirm-candadite'
import { CandidateAccountConfirmation } from '../../redux/types/applicant/confirm-candidate'

const Component = ({ applicantEmail, confirmCandidateStates }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (applicantEmail) {
      const data: CandidateAccountConfirmation = { email: applicantEmail }
      dispatch(confirmCandidateAccount(data))
    }
  }, [applicantEmail])

  useEffect(() => {
    if (confirmCandidateStates.success) {
      history.push('/empleos.html?activation_success=true')
    }

    if (confirmCandidateStates.error) {
      history.push('/empleos.html?activation_success=false')
    }
  }, [confirmCandidateStates])

  return null
}

const states = ({ applicantStore, confirmCandidateStore }) => {
  const { applicant } = applicantStore
  const { states: confirmCandidateStates } = confirmCandidateStore
  return {
    confirmCandidateStates,
    applicantEmail: applicant?.email,
  }
}

export default connect(states)(Component)
