import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CalificacionesPlaceholderMobile = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const barX = 44
  const barWitdh = wrapperWidth - barX - 36
  const middleX = wrapperWidth / 2
  const lastSquareOffset = barX + 4

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width="100%"
        height={661}
        viewBox="0 0 100% 661"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d={`M ${middleX - 78} 80 c 0 -44.183 35.817 -80 80 -80 s 80 35.817 80 80 H 72 z`} />
        <rect x={middleX - 78} y="92" rx="4" ry="4" width="156" height="24" />
        <rect x={middleX - 78} y="122" rx="4" ry="4" width="156" height="16" />

        <rect x="12" y="160" rx="4" ry="4" width="12" height="12" />
        <rect x="28" y="160" rx="4" ry="4" width="12" height="12" />
        <rect x={barX} y="160" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="160" rx="4" ry="4" width="12" height="12" />

        <rect x="12" y="188" rx="4" ry="4" width="12" height="12" />
        <rect x="28" y="188" rx="4" ry="4" width="12" height="12" />
        <rect x={barX} y="188" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="188" rx="4" ry="4" width="12" height="12" />

        <rect x="12" y="216" rx="4" ry="4" width="12" height="12" />
        <rect x="28" y="216" rx="4" ry="4" width="12" height="12" />
        <rect x={barX} y="216" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="216" rx="4" ry="4" width="12" height="12" />

        <rect x="12" y="244" rx="4" ry="4" width="12" height="12" />
        <rect x="28" y="244" rx="4" ry="4" width="12" height="12" />
        <rect x={barX} y="244" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="244" rx="4" ry="4" width="12" height="12" />

        <rect x="12" y="272" rx="4" ry="4" width="12" height="12" />
        <rect x="28" y="272" rx="4" ry="4" width="12" height="12" />
        <rect x={barX} y="272" rx="4" ry="4" width={barWitdh} height="12" />
        <rect x={barWitdh + lastSquareOffset} y="272" rx="4" ry="4" width="12" height="12" />

        <rect x="12" y="306" rx="4" ry="4" width="101" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="306" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="306" rx="4" ry="4" width="16" height="16" />

        <rect x="12" y="338" rx="4" ry="4" width="140" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="338" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="338" rx="4" ry="4" width="16" height="16" />

        <rect x="12" y="370" rx="4" ry="4" width="124" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="370" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="370" rx="4" ry="4" width="16" height="16" />

        <rect x="12" y="402" rx="4" ry="4" width="116" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="402" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="402" rx="4" ry="4" width="16" height="16" />

        <rect x="12" y="434" rx="4" ry="4" width="84" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="434" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="434" rx="4" ry="4" width="16" height="16" />

        <rect x="12" y="466" rx="4" ry="4" width="176" height="16" />
        <rect x={barWitdh + lastSquareOffset - 30} y="466" rx="4" ry="4" width="24" height="16" />
        <rect x={barWitdh + lastSquareOffset} y="466" rx="4" ry="4" width="16" height="16" />

        <rect x={middleX - 2 - barWitdh / 2} y="504" rx="4" ry="4" width={barWitdh + 4} height="16" />
        <rect x={middleX - barWitdh / 4} y="524" rx="4" ry="4" width={barWitdh / 2} height="16" />

        <path d={`M 12 556 h ${wrapperWidth - 24} v 1 H 12 z`} />

        <rect x={middleX - barWitdh / 2} y="573" rx="4" ry="4" width={barWitdh} height="24" />
        <rect x={middleX - 100} y="613" rx="8" ry="8" width="200" height="48" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CalificacionesPlaceholderMobile
