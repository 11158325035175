import {
  AlertActions,
  AlertState,
  CREATE_ALERT,
  CREATE_ALERT_ERROR,
  CREATE_ALERT_RESET,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT,
  DELETE_ALERT_ERROR,
  DELETE_ALERT_RESET,
  DELETE_ALERT_SUCCESS,
  EDIT_ALERT,
  EDIT_ALERT_ERROR,
  EDIT_ALERT_RESET,
  EDIT_ALERT_SUCCESS,
  GET_ALERT,
  GET_ALERTAS_FRECUENCIA,
  GET_ALERTAS_FRECUENCIA_ERROR,
  GET_ALERTAS_FRECUENCIA_SUCCESS,
  GET_ALERT_ERROR,
  GET_ALERT_SUCCESS,
  initialState,
  SWITCH_ALERT,
  SWITCH_ALERT_ERROR,
  SWITCH_ALERT_RESET,
  SWITCH_ALERT_SUCCESS,
} from '../types/alert-v2'
import { status } from '../types/status'

const reducers = (state: AlertState = initialState, action: AlertActions): AlertState => {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        create: {
          ...state.create,
          status: status.loading,
        },
      }
    case CREATE_ALERT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          value: action.payload,
          status: status.success,
        },
      }
    case CREATE_ALERT_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          status: status.error,
        },
      }
    case CREATE_ALERT_RESET:
      return {
        ...state,
        create: {
          ...state.create,
          value: null,
          status: status.initial,
        },
      }

    case GET_ALERTAS_FRECUENCIA:
      return {
        ...state,
        alertasFrecuencia: {
          ...state.alertasFrecuencia,
          status: status.loading,
        },
      }
    case GET_ALERTAS_FRECUENCIA_SUCCESS:
      return {
        ...state,
        alertasFrecuencia: {
          ...state.alertasFrecuencia,
          value: action.payload,
          status: status.success,
        },
      }
    case GET_ALERTAS_FRECUENCIA_ERROR:
      return {
        ...state,
        alertasFrecuencia: {
          ...state.alertasFrecuencia,
          status: status.error,
        },
      }

    case GET_ALERT:
      return {
        ...state,
        getAlert: {
          ...state.getAlert,
          status: status.loading,
        },
      }
    case GET_ALERT_SUCCESS:
      return {
        ...state,
        getAlert: {
          ...state.getAlert,
          value: action.payload,
          status: status.success,
        },
      }
    case GET_ALERT_ERROR:
      return {
        ...state,
        getAlert: {
          ...state.getAlert,
          status: status.error,
        },
      }

    case DELETE_ALERT:
      return {
        ...state,
        deleteAlert: {
          ...state.deleteAlert,
          status: status.loading,
        },
      }
    case DELETE_ALERT_SUCCESS:
      return {
        ...state,
        deleteAlert: {
          ...state.deleteAlert,
          value: action.payload,
          status: status.success,
        },
      }
    case DELETE_ALERT_ERROR:
      return {
        ...state,
        deleteAlert: {
          ...state.deleteAlert,
          status: status.error,
        },
      }
    case DELETE_ALERT_RESET:
      return {
        ...state,
        deleteAlert: {
          ...state.deleteAlert,
          value: null,
          status: status.initial,
        },
      }

    case EDIT_ALERT:
      return {
        ...state,
        editAlert: {
          ...state.editAlert,
          status: status.loading,
        },
      }
    case EDIT_ALERT_SUCCESS:
      return {
        ...state,
        editAlert: {
          ...state.editAlert,
          value: action.payload,
          status: status.success,
        },
      }
    case EDIT_ALERT_ERROR:
      return {
        ...state,
        editAlert: {
          ...state.editAlert,
          status: status.error,
        },
      }
    case EDIT_ALERT_RESET:
      return {
        ...state,
        editAlert: {
          ...state.editAlert,
          value: null,
          status: status.initial,
        },
      }

    case SWITCH_ALERT:
      return {
        ...state,
        switchAlert: {
          ...state.switchAlert,
          status: status.loading,
        },
      }
    case SWITCH_ALERT_SUCCESS:
      return {
        ...state,
        switchAlert: {
          ...state.switchAlert,
          value: action.payload,
          status: status.success,
        },
      }
    case SWITCH_ALERT_ERROR:
      return {
        ...state,
        switchAlert: {
          ...state.switchAlert,
          status: status.error,
        },
      }
    case SWITCH_ALERT_RESET:
      return {
        ...state,
        switchAlert: {
          ...state.switchAlert,
          value: null,
          status: status.initial,
        },
      }

    default:
      return state
  }
}

export default reducers
