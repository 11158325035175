import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_JOB_POSTINGS_INFO,
  GET_JOB_POSTINGS_INFO_SUCCESS,
  GET_JOB_POSTING_INFO_ERROR,
  GET_NEW_FEATURES,
  GET_NEW_FEATURES_SUCCESS,
  GET_NEW_FEATURES_ERROR,
  GET_TOTAL_AVISOS,
  GET_TOTAL_AVISOS_SUCCESS,
  GET_TOTAL_AVISOS_ERROR,
  GET_JOB_POSTINGS_HOME,
  GET_JOB_POSTINGS_HOME_SUCCESS,
  GET_JOB_POSTING_HOME_ERROR,
} from '../types/home-page'

function* getJobPostingsInfo() {
  try {
    const result = yield call(services.homePage.GetJobPostingsInfo)
    yield put({ type: GET_JOB_POSTINGS_INFO_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_JOB_POSTING_INFO_ERROR, error })
  }
}

function* getNewFeature() {
  try {
    const result = yield call(services.homePage.GetNewFeature)
    yield put({ type: GET_NEW_FEATURES_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_NEW_FEATURES_ERROR, error })
  }
}

function* getTotalAvisos() {
  try {
    const result = yield call(services.homePage.getTotalAvisos)
    yield put({ type: GET_TOTAL_AVISOS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_TOTAL_AVISOS_ERROR, payload: null })
  }
}

function* getJobPostingsHome({ payload }) {
  try {
    const result = yield call(services.homePage.getJobPostingsHome, payload)
    yield put({ type: GET_JOB_POSTINGS_HOME_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_JOB_POSTING_HOME_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_TOTAL_AVISOS, getTotalAvisos)
  yield takeLatest<any>(GET_NEW_FEATURES, getNewFeature)
  yield takeLatest<any>(GET_JOB_POSTINGS_INFO, getJobPostingsInfo)
  yield takeLatest<any>(GET_JOB_POSTINGS_HOME, getJobPostingsHome)
}
