export function getCookieData(key: string, cookieString: string) {
  if (!cookieString) {
    return null
  }

  const data = cookieString.split(`${key}=`)
  if (!data[1]) {
    return null
  }

  const value = data[1].split(';')[0]
  if (value && value.length) {
    return value
  }

  return null
}

export function setCookieData(key: string, value: string, seconds?: number) {
  const expirationDate = seconds ? `Max-Age=${seconds};` : ''
  document.cookie = `${key}=${value}; ${expirationDate} path=/`
}

export function deleteCookieData(key: string) {
  document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export function loginRestringidoCookieExist() {
  return getCookieData('loginRestringido', document.cookie)
}

export function userLoggedIn(): boolean {
  return getCookieData('token_auth_postulante', document.cookie) !== null 
}

