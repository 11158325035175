import React from 'react'
import { BoxLegendSvg } from '@nivo/legends'

const BarLegend = ({ legends, height, width }) => (
  <>
    {legends.slice(0, 1).map(legend => (
      <BoxLegendSvg
        key={JSON.stringify(
          legend.data.map(({ id }) => {
            return id
          })
        )}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
    {legends.slice(1).map(legend => (
      <BoxLegendSvg
        key={JSON.stringify(
          legend.data.map(({ id }) => {
            return id
          })
        )}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
  </>
)

export default BarLegend
