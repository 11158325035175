import { put, call, takeLatest } from 'redux-saga/effects'
import service from '../../services/suscripcion'
import {
  EDITAR_SUSCRIPCION,
  EDITAR_SUSCRIPCION_ERROR,
  EDITAR_SUSCRIPCION_SUCCESS,
  EditarSuscripcion,
  EditarSuscripcionError,
  EditarSuscripcionSuccess,
  GET_SUSCRIPCION,
  GET_SUSCRIPCION_ERROR,
  GET_SUSCRIPCION_SUCCESS,
  GetSuscripcion,
  GetSuscripcionError,
  GetSuscripcionMapType,
  GetSuscripcionSuccess,
} from '../types/suscripcion'

function* getSuscripcion() {
  try {
    const resp: GetSuscripcionMapType = yield call(service.getSuscripcionesMap.bind(service))
    yield put<GetSuscripcionSuccess>({ type: GET_SUSCRIPCION_SUCCESS, payload: resp })
  } catch {
    yield put<GetSuscripcionError>({ type: GET_SUSCRIPCION_ERROR, payload: null })
  }
}

function* editarSuscripcion({ payload }: EditarSuscripcion) {
  try {
    yield call(service.editarSuscripcion, payload)
    yield put<EditarSuscripcionSuccess>({ type: EDITAR_SUSCRIPCION_SUCCESS, payload: null })
  } catch {
    yield put<EditarSuscripcionError>({ type: EDITAR_SUSCRIPCION_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* Suscripcions() {
  yield takeLatest<GetSuscripcion>(GET_SUSCRIPCION, getSuscripcion)
  yield takeLatest<EditarSuscripcion>(EDITAR_SUSCRIPCION, editarSuscripcion)
}
