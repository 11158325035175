import React from 'react'
import paymentMPSettings from '../../../../constants/payment-mp-settings'
import { ParagraphInfo, IconCustom } from './mixins'

const Component = ({ status, product }) => {
  const pageSetting = paymentMPSettings()

  return (
    <ParagraphInfo>
      {(status === 'success' || status === 'pending') && (
        <>
          <IconCustom name="icon-light-info" color="#0A26EE" size="16" />
          {`${pageSetting.products[product][status].info}`}
        </>
      )}
    </ParagraphInfo>
  )
}

export default Component
