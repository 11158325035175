// constants
import { ProductsMP } from '../constants/productsMP'
import { PaymentMPProductId } from '../constants/payment-mp-product-id'
import { PaymentMPExternalId } from '../constants/payment-mp-external-id'
import { PaymentMPProductSKU } from '../constants/payment-mp-product-sku'

// redux
import { showModal } from '../redux/actions/modal'
import { getLinkPago, getLinkPagoV2 } from '../redux/actions/payment-mp'

// utils
import { userLoggedIn, loginRestringidoCookieExist } from './cookies'

export const getMPLinkStockeable = ({ setLoadingMPLink, dispatch, productName }) => {
  setLoadingMPLink(true)
  dispatch(getLinkPagoV2(productName, [ProductsMP[productName]], PaymentMPExternalId[productName]))
}

export const getMPLinkNonStockeable = ({ setLoadingMPLink, dispatch, productName }) => {
  setLoadingMPLink(true)
  dispatch(getLinkPago(productName, PaymentMPProductId[productName], PaymentMPExternalId[productName]))
}

export const getMPLink = ({ setLoadingMPLink, dispatch, productName }) => {
  if (ProductsMP[productName]?.quantity) {
    getMPLinkStockeable({ setLoadingMPLink, dispatch, productName })
  } else {
    getMPLinkNonStockeable({ setLoadingMPLink, dispatch, productName })
  }
}

interface Props {
  action?: () => void
  setLoadingMPLink?: Function
  dispatch: Function
  history: {
    push: Function
  }
  productName: string
  disponibilidad: {
    available: boolean
    code: string | null
    detail: string | null
    sku: string
  }[]
}

export const goToMP = ({ action, dispatch, history, setLoadingMPLink, productName, disponibilidad }: Props) => {
  const loginFull = loginRestringidoCookieExist() === null
  const productSku = PaymentMPProductSKU[productName]
  const productAvailability = disponibilidad?.find(product => product.sku === productSku)

  if (userLoggedIn() && loginFull) {
    if (!productAvailability?.available) {
      switch (productAvailability?.code) {
        case 'DOCUMENTO':
          dispatch(showModal('modal-without-document-number'))
          break
        case 'ESTUDIOS':
        case 'EXPERIENCIAS':
          dispatch(showModal('modal-analysis-cv-unable-to-purchase'))
          break
        default:
          break
      }
    } else {
      action ? dispatch(action()) : getMPLink({ setLoadingMPLink, dispatch, productName })
    }
  } else {
    history.push(`/login?returnTo=${window.location.pathname}`)
    window.scrollTo(0, 0)
  }
}
