import styled from 'styled-components'

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;

  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    justify-content: flex-end;
  }
`
export const ToggleLabel = styled.span`
  color: ${({ theme }) => theme.colors.grey84};
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;
`
