import styled from 'styled-components'
import { Loader } from '@navent-jobs/ui-kit/components/loader'

export const CustomLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 48%;
  margin: -25px 0px 0px -25px;
`

export const ContainerLoader = styled.div`
  min-height: 262px;
`
