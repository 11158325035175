export const alertas = {
  success: {
    title: '¡Tu alerta se guardó con éxito!',
    textEnvioInmediato: 'Cuando se publique un aviso nuevo con estos filtros, lo recibirás por email.',
    textEnvioDiario: 'Si se publican avisos nuevos con estos filtros, te los enviaremos en un email diario.',
    textAjustes: 'Puedes gestionar tus alertas desde Ajustes.',
    buttonListado: 'Volver al listado',
    buttonAjustes: 'Ir a Ajustes',
  },

  form: {
    title: 'Crear alerta de empleo',
    description:
      'Recibe por email los nuevos avisos de trabajo que coincidan con los filtros que aplicaste en esta búsqueda.',
    nombreTitle: 'Nombre de la alerta',
    nombrePlaceholder: 'Escribe un nombre',
    frecuenciaTitle: 'Frecuencia de envío',
    frecuenciaPlaceholder: 'Elige una opción',
    buttonCancelar: 'Cancelar',
    buttonCrear: 'Crear alerta',
    errorDuplicada: 'Ya tienes una alerta creada con las mismas características. Puedes gestionarla desde ',
    errorLimite: 'Ya creaste tus 5 alertas disponibles. Para agregar una nueva, elimina una anterior desde ',
    linkAjustes: 'Ajustes de notificaciones y alertas',
  },

  panel: {
    title: 'Alertas',
    subtitle: 'Las alertas de empleo que hayas creado te llegarán por correo electrónico.',
    emptyStateTitle: 'No tienes alertas de empleo guardadas',
    emptyStateDescription:
      'Para recibir ofertas de trabajo por email, crea una alerta después de realizar una búsqueda.',
    emptyStateCta: 'Buscar empleos',
    label: {
      created: 'Fecha de creación:',
      keyword: 'Palabra clave:',
      filters: 'Filtros:',
      location: 'Ubicación:',
      frecuencie: 'Frecuencia de envío:',
      state: 'Estado:',
      enable: 'Activada',
      disable: 'Desactivada',
    },
  },

  modalEliminar: {
    eliminar: 'Eliminar',
    cancelar: 'Cancelar',
    title: '¿Quieres eliminar esta alerta?',
  },

  modalEditar: {
    title: 'Editar alerta',
    labelName: 'Nombre',
    ctaSave: 'Guardar',
    ctaCancel: 'Cancelar',
  },

  snackbarEliminar: {
    success: 'Alerta eliminada',
    error: 'Ocurrió un error inesperado',
  },

  createButtonTitle: 'Crear alerta de empleo',
  popOverMessage: 'Tu búsqueda tiene que ser más específica para crear una alerta de empleo. ',
}
