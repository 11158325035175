import { merge } from 'lodash'
import es from '../es'
import modalCrearAlerta from './modal-crear-alerta'
import metatagsListado from './metatags-listado'
import searchBar from './search-bar'
import filters from './filters'
import filtersEs from '../es/filters'
import filtersSettings from './filters-settings'
import busquedaEncontrada from './busqueda-encontrada'
import fichaAviso from '../es/ficha-aviso'
import fichaAvisoPa from './ficha-aviso'
import fichaAvisoEmptyState from './ficha-aviso-empty-state'
import { currencyMask } from './currency-mask'
import { seoWordings as seoWordingsPa } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import crearAlerta from './crear-alerta'
import curriculumEs from '../es/curriculum'
import curriculumPa from './curriculum'
import companySummary from '../es/company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesPa from './postulaciones'
import listadoEmpresasEs from '../es/listado-empresas'
import listadoEmpresasPa from './listado-empresas'
import { products as productsEs } from '../es/products'
import { products as productsPa } from './products'
import { nulinga as nulingaEs } from '../es/nulinga'
import { nulinga as nulingaPa } from './nulinga'
import { paymentMP as paymentMPEs } from '../es/payment-mp'
import { paymentMP as paymentMPPa } from './payment-mp'
import { productLanding as productLandingEs } from '../es/product-landing'
import { productLanding as productLandingPa } from './product-landing'
import { perfil as perfilEs } from '../es/perfil'
import { perfil as perfilPa } from './perfil'
import feriaEs from '../es/feria'
import feriaPa from './feria'

const pa = {
  ...es,
  cv: 'HV',
  ...modalCrearAlerta,
  ...metatagsListado,
  ...searchBar,
  ...filtersSettings,
  ...busquedaEncontrada,
  fichaAviso: merge({}, fichaAviso, fichaAvisoPa),
  ...fichaAvisoEmptyState,
  ...crearAlerta,
  currencyMask,
  'Postulantes Title': 'Empleos en Panamá: trabajo y ofertas de empleo - Konzerta 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Panamá en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Konzerta.',
  Buscar: 'Buscar PA',
  'Ir al inicio': 'Ir al inicio PA',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: 'B',
    'currency-format': 'XXX,XXX,XXX.XX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - konzerta.com',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en konzerta Empleos.',
  seoWordings: merge({}, seoWordingsEs, seoWordingsPa),
  curriculum: merge({}, curriculumEs, curriculumPa),
  companySummary,
  postulaciones: merge({}, postulacionesEs, postulacionesPa),
  listadoEmpresas: merge({}, listadoEmpresasEs, listadoEmpresasPa),
  products: merge({}, productsEs, productsPa),
  ...merge({}, { ...filtersEs }, { ...filters }),
  nulinga: merge({}, nulingaEs, nulingaPa),
  paymentMP: merge({}, paymentMPEs, paymentMPPa),
  productLanding: merge({}, productLandingEs, productLandingPa),
  perfil: merge({}, perfilEs, perfilPa),
  feria: merge({}, feriaEs, feriaPa),
}

export default pa
