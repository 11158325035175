import React from 'react'
import { Checkbox } from '@navent-jobs/ui-kit/components/checkbox'
import { useTranslation } from 'react-i18next'
import { SITE_ID } from '../../../../../constants'

// mixins
import { CheckGroup, CustomLink } from '../mixins'

interface AutorizeNewsletterProps {
  setSuscripciones: (e) => void
}

const NOT_BMEC = SITE_ID !== 'BMEC'

const Text = () => {
  const { t } = useTranslation()
  return (
    <>
      {t('signUp.newsAndPromotions')}
      {NOT_BMEC && (
        <CustomLink
          target="_blank"
          href="/politica-de-privacidad#clausula6"
          linkoptions={{
            native: false,
            size: 'small',
            type: 'link',
            variant: 'primary',
          }}
        >
          Novedades y Promociones
        </CustomLink>
      )}
    </>
  )
}

const AutorizeNewsletter = ({ setSuscripciones }: AutorizeNewsletterProps) => {
  return (
    <CheckGroup>
      <Checkbox
        id="newsAndPromotions"
        name="newsAndPromotions"
        label={<Text />}
        onChange={e => setSuscripciones(e.currentTarget.checked)}
        fieldOptions={{
          size: 'small',
        }}
      />
    </CheckGroup>
  )
}

export default AutorizeNewsletter
