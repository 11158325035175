import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// mixins
import { Form, SidebarMenu, SidebarTitle } from './mixins'

// components
import TabSwiper from '../TabSwiper'
import AnchorBar from '../AnchorBar'
import JobPosting from '../JobPosting'
import Company from '../Company'

const Component = ({ menuOpened, setMenuOpened }) => {
  const location = useLocation()
  const companyUrl = '/listado-empresas'
  const tabs = [
    {
      key: 'tab-aviso',
      label: 'Empleos',
      urls: ['/empleos', '/en-'],
      component: <JobPosting setMenuOpened={setMenuOpened} setActive={() => setIsActive(true)} />,
    },
    {
      key: 'tab-empresa',
      label: 'Empresas',
      urls: [companyUrl],
      component: <Company location={location} setMenuOpened={setMenuOpened} companyUrl={companyUrl} />,
    },
  ]

  const [visibleId, setVisibleId] = useState(tabs[0].key)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const active = tabs.find(tab => tab.urls.some(url => location.pathname.startsWith(url)))
    if (active) {
      setVisibleId(active.key)
    }
  }, [])

  if (!visibleId) return null

  return (
    <SidebarMenu closeType="close" position="right" visible={menuOpened} handleClose={() => setMenuOpened(false)}>
      <TabSwiper setVisibleId={setVisibleId}>
        <Form isActive={isActive} height="48px">
          <SidebarTitle>Buscar</SidebarTitle>

          <AnchorBar tabs={tabs} visibleId={visibleId} setVisibleId={setVisibleId} />

          {tabs.find(tab => visibleId === tab.key)?.component}
        </Form>
      </TabSwiper>
    </SidebarMenu>
  )
}

export default Component
