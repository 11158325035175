/* eslint-disable prettier/prettier */
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { makeFichaAvisoURL } from '@navent-jobs/interlinking'

// ui kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// components
import { PrivateRoute } from '../../components/private-route'
import Metas from '../../components/metas/mis-postulaciones'
import CardPlaceholderMobile from '../postulaciones/components/card/components/CardPlaceholderMobile'
import CardPlaceholderTablet from '../postulaciones/components/card/components/CardPlaceholderTablet'
import CardPlaceholderDesktop from '../postulaciones/components/card/components/CardPlaceholderDesktop'
import EmptyState from './components/empty-state'
import BarGraph from './components/graphs/bar'
import GraphYearsExperiencie from './components/graphs/bar-years-experiencie'
import InfoCard from './components/graphs/info'
import GraphSection from './components/graphs/section'
import GraphPie from './components/graphs/pie'
import { TooltipAge, TooltipEducation } from './components/graphs/tooltips'
import BarLegend from './components/graphs/bar-legend'
import SeccionAvisosRecomendados from './components/seccion-avisos-recomendados'

// hooks
import useSettingsStatistics from './hooks/useSettingsStatistics'
import useGraphProps from './hooks/useGraphProps'
import useGraphColors from './hooks/useGraphColors'

// mixins
import {
  PageContent,
  HeaderPage,
  HeaderContainer,
  ContainerGraphs,
  LinkCustom,
  GraphWrapper,
  GraphContainer,
  Label,
  Info,
  InfoText,
  CardCustom,
  InfoExpe,
  Title,
  TitleContainer,
  Divider,
  EstadoPostulacionContainer,
} from './mixins'

// constants
import { keysAges, keysEducation, keysYearsExp } from './constants'

// redux
import {
  getEstadosSeleccion,
  getPostulacion,
  putEstadoSeleccion,
  resetPutEstadoSeleccion,
} from '../../redux/actions/postulaciones'
import ModalSeleccionEstado from '../postulaciones/components/card/components/CardFooter/modal'
import useStatusStatistics from './hooks/useStatusStatistics'

const LayoutAuthHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-auth-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const showPlaceholders = (isMobile, isTabletPortrait, isTabletLandscape) => {
  if (isMobile) {
    return <CardPlaceholderMobile />
  }

  if (isTabletPortrait || isTabletLandscape) {
    return <CardPlaceholderTablet />
  }

  return <CardPlaceholderDesktop />
}

const onClickAviso = (event, url) => {
  if (!(event.ctrlKey || event.metaKey)) {
    event.preventDefault()
    window.open(url, '_blank')
  }
}
const CONFIDENCIAL = 'Confidencial'

const PostulacionesStatistics = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { postulacion, estadisticas, loading, match, error, estadosSeleccion, estadosSeleccionPutStates } = props
  const { isMobile, isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize()

  const size = isMobile ? 'mobile' : 'desktop'
  const { idPostulacion } = match.params

  // Condicional para mostrar empty state
  const showEmptyState =
    !loading &&
    (!estadisticas?.edadPromedioLabel || !estadisticas?.nivelEducativoMasFrecuenteLabel || !estadisticas?.experiencias)

  const { colors } = useContext<{ colors }>(ThemeContext)
  const { colorEducation, colorAges, colorYearsExp } = useGraphColors()

  const graphProps = useGraphProps()
  const statistics = useSettingsStatistics({
    cantidadPostulaciones: estadisticas?.cantidadPostulaciones,
    edadPromedio: estadisticas?.edadPromedioLabel,
    nivelEducativoFrecuente: estadisticas?.nivelEducativoMasFrecuenteLabel,
    salarioPromedio: estadisticas?.salarioPromedio,
    tipoAviso: postulacion?.planDePublicacionId,
  })

  const [plusHeight, setPlusHeight] = useState(0)
  const infoTextHeight = document.getElementById('infoExpeId')?.offsetHeight

  const isConfidencial = postulacion?.empresa === CONFIDENCIAL || postulacion?.empresa === null

  const experience = { id: 'count', indexValue: estadisticas?.postulante?.experiencia }
  const study = { id: estadisticas?.postulante?.estudiosEstado, indexValue: estadisticas?.postulante?.estudios }
  const age = { id: 'count', indexValue: estadisticas?.postulante?.edad }

  const [isLoadingSave, setLoadingSave] = useState(false)
  const [visibleModal, setVisibleModal] = useState('')
  const hideModal = () => setVisibleModal('')
  const [dataOfJobApplicattion, setDataOfJobApplicattion] = useState<{ postulacionId: number; tituloAviso: string }>({
    postulacionId: 0,
    tituloAviso: '',
  })
  const [estadoSeleccionGuardado, setEstadoSeleccionGuardado] = useState(0)
  const statusStatitics = useStatusStatistics({ estados: estadisticas?.estadosPostulacion })

  useEffect(() => {
    dispatch(getPostulacion({ idPostulacion }))
    dispatch(getEstadosSeleccion())
  }, [])

  useEffect(() => {
    if (document) {
      document.documentElement.scrollTo(0, 0)
    }
  }, [document])

  useEffect(() => {
    setPlusHeight(document.getElementById('infoExpeId')?.offsetHeight || 0)
  }, [infoTextHeight, estadisticas])

  const onClickChangeStatus = (postulacionId, tituloAviso) => {
    setDataOfJobApplicattion({ postulacionId, tituloAviso })
    setVisibleModal('modal-seleccion-estado')
  }

  const handleSave = estadoId => {
    setEstadoSeleccionGuardado(+estadoId)
    dispatch(putEstadoSeleccion(dataOfJobApplicattion.postulacionId, estadoId))
  }

  useEffect(() => {
    setLoadingSave(estadosSeleccionPutStates?.loading)
    if (estadosSeleccionPutStates?.success) {
      hideModal()
      /** Cambio a mano el estadoSeleccion de la postulacion a la cual se le cambio el estado para no tener que hacer el pedido entero */
      postulacion.estadoSeleccion = estadosSeleccion?.find(e => e.id === estadoSeleccionGuardado)?.nombre
      setEstadoSeleccionGuardado(0)
      dispatch(resetPutEstadoSeleccion())
    }
  }, [estadosSeleccionPutStates])

  if (error) {
    return <Redirect to="/404" />
  }

  return (
    <PrivateRoute>
      <LayoutAuthHeader {...props}>
        <Suspense fallback={<PageLoader />}>
          {!isDesktop && (
            <HeaderPage>
              <HeaderContainer>
                <LinkCustom
                  href="/postulantes/postulaciones"
                  linkoptions={{ variant: 'black', type: 'link', size: 'small' }}
                >
                  <Icon name="icon-light-arrow-left" size="24" color="#29293D" />
                  <Label>{t('postulaciones.statistics.button.back')}</Label>
                </LinkCustom>
              </HeaderContainer>
            </HeaderPage>
          )}

          <MainContainer>
            <Row>
              <Col>
                <PageContent>
                  <Metas />

                  {isDesktop && (
                    <LinkCustom href="/postulantes/postulaciones" linkoptions={{ variant: 'primary', type: 'link' }}>
                      <Icon name="icon-light-arrow-left" size="24" color={colors.primary.normal} />
                      <Label>{t('postulaciones.statistics.button.back')}</Label>
                    </LinkCustom>
                  )}

                  {loading
                    ? showPlaceholders(isMobile, isTabletPortrait, isTabletLandscape)
                    : postulacion && (
                        <CardCustom
                          id={postulacion.id}
                          postulacion={postulacion}
                          disableLink={false}
                          onClickChangeStatus={onClickChangeStatus}
                          onClick={e =>
                            onClickAviso(
                              e,
                              makeFichaAvisoURL({
                                ...postulacion,
                                empresa: {
                                  denominacion: postulacion.empresa,
                                  confidencial: isConfidencial,
                                },
                                id: postulacion.avisoId,
                              })
                            )
                          }
                          link={makeFichaAvisoURL({
                            ...postulacion,
                            empresa: {
                              denominacion: postulacion.empresa,
                              confidencial: isConfidencial,
                            },
                            id: postulacion.avisoId,
                          })}
                        />
                      )}
                  {showEmptyState ? (
                    <EmptyState />
                  ) : (
                    <ContainerGraphs>
                      {/* Estadisticas de Postulacion */}
                      {statusStatitics && (
                        <>
                          <Row>
                            <Col>
                              <TitleContainer>
                                <Title>Estadísticas de postulación</Title>
                                <InfoExpe>
                                  <Icon name="icon-light-info" color="#00001899" />
                                  <InfoText>Los datos fueron obtenidos por respuestas de otros usuarios.</InfoText>
                                </InfoExpe>
                              </TitleContainer>
                            </Col>
                          </Row>

                          {/* Estado postulacion */}
                          <EstadoPostulacionContainer>
                            <Row>
                              {statusStatitics.map((setting, index) => (
                                <Col lg="2.4" key={setting.key}>
                                  <InfoCard
                                    statistic={setting}
                                    loading={loading}
                                    isLast={index === statusStatitics.length - 1}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </EstadoPostulacionContainer>
                          <Divider />
                        </>
                      )}

                      <Row>
                        <Col>
                          <TitleContainer>
                            <Title>Estadísticas del aviso</Title>
                            <InfoExpe>
                              <Icon name="icon-light-info" color="#00001899" />
                              <InfoText>Los datos corresponden a los postulados al aviso</InfoText>
                            </InfoExpe>
                          </TitleContainer>
                        </Col>
                      </Row>
                      {/* Detalle */}
                      <Row>
                        {statistics &&
                          statistics.map((setting, index) => (
                            <Col lg="4" key={setting.key}>
                              <InfoCard
                                statistic={setting}
                                loading={loading}
                                isLast={index === statistics.length - 1}
                              />
                            </Col>
                          ))}
                      </Row>

                      {/* Años de experiencia | Edad */}
                      <Row>
                        <Col lg="6">
                          <GraphWrapper plusHeight={16}>
                            <GraphSection title="Años de experiencia en el Área" loading={loading}>
                              <GraphContainer loading={loading} height={192}>
                                <GraphYearsExperiencie
                                  data={estadisticas?.experiencias}
                                  keys={keysYearsExp}
                                  indexBy="experienceRange"
                                  color={colorYearsExp}
                                  layout="vertical"
                                  tooltip={TooltipAge}
                                  loading={loading}
                                  myKey={experience}
                                  {...graphProps}
                                />
                              </GraphContainer>
                            </GraphSection>
                            {!loading && (
                              <InfoExpe id="infoExpeId">
                                <Icon name="icon-light-info" color="#00001899" />
                                <InfoText>
                                  <Trans
                                    i18nKey="postulaciones.statistics.experience.warning"
                                    values={{ area: postulacion?.areaAviso }}
                                    components={[<strong key="infoExpeIdStrong"></strong>]}
                                  />
                                </InfoText>
                              </InfoExpe>
                            )}
                          </GraphWrapper>
                        </Col>
                        <Col lg="6">
                          <GraphWrapper plusHeight={plusHeight}>
                            <GraphSection title="Edad" loading={loading}>
                              <GraphContainer loading={loading} height={192}>
                                <BarGraph
                                  data={estadisticas?.edades}
                                  keys={keysAges}
                                  indexBy="age"
                                  color={colorAges}
                                  layout="vertical"
                                  tooltip={TooltipAge}
                                  loading={loading}
                                  myKey={age}
                                  {...graphProps}
                                />
                              </GraphContainer>
                            </GraphSection>
                          </GraphWrapper>
                        </Col>
                      </Row>

                      {/* Nivel educativo */}
                      <Row>
                        <Col lg="12">
                          <GraphWrapper>
                            <GraphSection title="Nivel educativo" loading={loading}>
                              <GraphContainer layout="horizontal" loading={loading} height={260}>
                                <BarGraph
                                  data={estadisticas?.nivelesEducacion}
                                  keys={keysEducation}
                                  indexBy="level"
                                  color={colorEducation}
                                  layout="horizontal"
                                  tooltip={TooltipEducation}
                                  loading={loading}
                                  myKey={study}
                                  {...graphProps(size, BarLegend)}
                                />
                              </GraphContainer>

                              {!loading && (
                                <Info>
                                  <Icon name="icon-light-info" color="#00001899" />
                                  <InfoText>
                                    Los datos corresponden a los postulados que hayan cargado su nivel educativo.
                                  </InfoText>
                                </Info>
                              )}
                            </GraphSection>
                          </GraphWrapper>
                        </Col>
                      </Row>

                      {/* Conocimientos y habilidades */}
                      {estadisticas?.cantidadPostulaciones && estadisticas.habilidades?.length > 0 && (
                        <Row>
                          <Col lg="12">
                            <GraphWrapper>
                              <GraphSection
                                bodyStyles={{
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  paddingTop: 12,
                                }}
                                title="Conocimientos y habilidades destacados entre los postulados"
                                loading={loading}
                              >
                                {estadisticas.habilidades.map(skill => (
                                  <>
                                    <GraphPie
                                      key={`grafico-${skill.name}`}
                                      skill={skill}
                                      total={estadisticas?.cantidadPostulaciones}
                                    />
                                  </>
                                ))}
                              </GraphSection>
                            </GraphWrapper>
                          </Col>
                        </Row>
                      )}
                    </ContainerGraphs>
                  )}

                  <SeccionAvisosRecomendados avisoId={postulacion?.avisoId} />
                  <ModalSeleccionEstado
                    width={600}
                    closeModal={() => {
                      hideModal()
                    }}
                    visible={visibleModal === 'modal-seleccion-estado'}
                    handleSave={handleSave}
                    loading={isLoadingSave}
                    tituloAviso={dataOfJobApplicattion.tituloAviso}
                    opciones={estadosSeleccion}
                  />
                </PageContent>
              </Col>
            </Row>
          </MainContainer>
        </Suspense>
      </LayoutAuthHeader>
    </PrivateRoute>
  )
}

const states = ({ postulacionesStore }) => ({
  postulacion: postulacionesStore.postulacion,
  estadisticas: postulacionesStore.estadisticas,
  loading: postulacionesStore.postulacionStates.loading,
  error: postulacionesStore.postulacionStates.error,
  estadosSeleccion: postulacionesStore.estados_seleccion.estados,
  estadosSeleccionPutStates: postulacionesStore.estados_seleccion.putState,
})

export default connect(states)(PostulacionesStatistics)
