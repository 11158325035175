import {
  POST_ANALYSISCV,
  PostAnalysisCv,
  PostAnalysisCvPreview,
  GET_ANALYSISCV,
  GetAnalysisCv,
  POST_ANALYSISCV_PREVIEW,
} from '../types/analysis-cv'

export const getAnalysisCv = (): GetAnalysisCv => {
  return {
    type: GET_ANALYSISCV,
    payload: null,
  }
}

export const postAnalysisCv = (): PostAnalysisCv => {
  return {
    type: POST_ANALYSISCV,
    payload: null,
  }
}

export const postAnalysisCvPreview = (): PostAnalysisCvPreview => {
  return {
    type: POST_ANALYSISCV_PREVIEW,
    payload: null,
  }
}
