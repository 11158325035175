import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

// components
import { Container } from '@navent-jobs/ui-kit/components/grid'
import Metas from './components/metas'
import HeaderSection from './components/header'
import ProductsSection from './components/products'
import BannerSection from './components/banner-section'
import FormSection from './components/form-section'

// mixins
import { MainPage } from './mixins'

// constants
import { SITE_ID } from '../../constants'
import features from '../../constants/features-per-country'
import { DataLayers } from '../../constants/gtm-events-marketplace'

// redux
import { showSnackbar, showSnackbarError } from '../../redux/actions/applicant/curriculum/snackbar'

const Products = ({ isUserLoggedIn, productsStoreStates }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const showBanner = features[SITE_ID || 'BMAR'].marketplace?.bannerSection

  useEffect(() => {
    if (productsStoreStates?.success) {
      DataLayers.envioFormulario('Exito')
      dispatch(showSnackbar(t('products.form.snackbar.success'), 'icon-light-checkmark-circle'))
    }

    if (productsStoreStates?.error) {
      showSnackbarError(t('products.form.snackbar.error'))
    }
  }, [productsStoreStates?.success, productsStoreStates?.error])

  return (
    <Container noGutter>
      <Metas />
      <MainPage>
        <HeaderSection />
        <ProductsSection isUserLoggedIn={isUserLoggedIn} />
        {showBanner && <BannerSection isUserLoggedIn={isUserLoggedIn} />}
        <FormSection />
      </MainPage>
    </Container>
  )
}

const states = ({ applicantStore, productsStore }) => ({
  isUserLoggedIn: !!applicantStore?.applicant,
  productsStoreStates: productsStore?.SendDataForm?.states,
})

export default connect(states)(Products)
