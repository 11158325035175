import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Button } from '@navent-jobs/ui-kit/components/button'

// mixins
import { FieldIcon, KeywordFieldContainer, RecentSearchTitlte, RecentSearches, RecentSearchesOption } from './mixins'

// compontens
import CompanySearchBar from '../../CompanySearchBar'
import OptionLabels from '../CompanyOptionLabel'

// services
import { SearchCompaniesStack as recentSearchesService } from '../../../../services/search-companies-stack'

// constants
import { DataLayerListadoEmpresas } from '../../../../constants/gtm-events-listado-empresas'

// redux
import { getCompanies } from '../../../../redux/actions/listado-empresas'
import { shotCompanySearch } from '../../searchbarCompanyLogic'

const Component = ({ location, setMenuOpened, companyUrl }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [selected, setSelected] = useState<{ label: string; value: string } | undefined>()

  const handleSubmit = value => {
    shotCompanySearch(value)
    const valueTrimmed = value.trim()
    if (valueTrimmed === '') {
      queryParams.delete('query')
    } else {
      queryParams.delete('query')
      queryParams.append('query', valueTrimmed)
    }

    queryParams.delete('page')
    queryParams.delete('industria')
    queryParams.delete('ubicacion')

    if (location.pathname !== companyUrl) {
      history.push(companyUrl)
    }

    history.push({
      search: queryParams.toString(),
    })

    DataLayerListadoEmpresas.search(valueTrimmed)

    dispatch(
      getCompanies({
        page: 0,
        filtros: {
          query: valueTrimmed,
          industries: null,
          provinces: null,
          sortByRating: false,
        },
      })
    )

    setMenuOpened(false)
  }

  return (
    <div>
      <KeywordFieldContainer value={selected}>
        <CompanySearchBar
          selected={selected}
          setSelected={setSelected}
          location={location}
          placeholder="Buscar por empresa"
          before={<FieldIcon size="24" color={colors.secondary.normal} name="icon-light-search" />}
          handleSubmit={handleSubmit}
          showRecentSearch={false}
        />
      </KeywordFieldContainer>

      <Button
        buttonoptions={{ variant: 'primary', block: true }}
        type="button"
        onClick={() => handleSubmit(selected?.value || '')}
      >
        Buscar empresa
      </Button>

      {recentSearchesService?.getInstance()?.getHistory()?.length > 0 && (
        <RecentSearches>
          <RecentSearchTitlte>Búsquedas recientes</RecentSearchTitlte>
          {recentSearchesService
            .getInstance()
            .getHistory()
            .map(recentSearch => {
              return (
                recentSearch &&
                recentSearch.keyword && (
                  <RecentSearchesOption onClick={() => handleSubmit(recentSearch.keyword)}>
                    <OptionLabels option={recentSearch.busqueda} />
                  </RecentSearchesOption>
                )
              )
            })}
        </RecentSearches>
      )}
    </div>
  )
}

export default Component
