import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphPlaceholder = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={313}
    viewBox="0 0 100% 313"
    backgroundcolor="#f3f3f3"
    foregroundcolor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
    <rect x="0" y="44" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 44 h 72 v 20 H 40 z" />
    <rect x="0" y="84" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 84 h 144 v 20 H 40 z" />
    <rect x="0" y="124" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 124 h 288 v 20 H 40 z" />
    <rect x="0" y="164" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 164 h 576 v 20 H 40 z" />
    <rect x="0" y="204" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 204 h 913.289 v 20 H 40 z" />
    <rect x="0" y="244" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 244 h 1152 v 20 H 40 z" />
    <rect x="512" y="289" rx="4" ry="4" width="200" height="24" />
  </ContentLoader>
)

export default GraphPlaceholder
