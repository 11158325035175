import styled from 'styled-components'

export const Container = styled.div`
  width: 233px;
  height: 240px;
`

export const GraphContainer = styled.div`
  text-align: center;
  position: relative;
  height: 160px;
  margin-bottom: 12px;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  margin: 0;
`

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  // This is important to preserve the chart interactivity
  pointer-events: none;
`

export const LegendContainer = styled.div`
  text-align: center;
  position: relative;
`

export const Legend = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`
