const filtersSettings = {
  'filters-settings': {
    salario: {
      label: 'Sueldo mensual',
    },
    provincia: {
      label: 'Departamento',
    },
    localidad: {
      label: 'Distrito',
    },
  },
}

export default filtersSettings
