export default {
  academicReference: {
    description1: 'te indicó como referencia',
    description2: 'Si quieres aparecer como una referencia académica de su {{cv}}, confirma tus datos:',

    confirmed: {
      title: 'Gracias por aceptar ser una referencia académica de',
      description: 'De esta manera, colaboras en su búsqueda de trabajo.',
    },

    rejected: {
      title: 'No aceptaste la solicitud de referencia académica de',
    },
  },

  jobReference: {
    description1: 'te indicó como su referencia',
    description2: 'Si quieres aparecer como una referencia laboral de su {{cv}}, confirma tus datos:',
    confirmed: {
      title: 'Gracias por aceptar ser una referencia laboral de',
      description: 'De esta manera, colaboras en su búsqueda de trabajo',
    },

    rejected: {
      title: 'No aceptaste la solicitud de referencia laboral de',
    },
  },

  form: {
    label: {
      name: 'Nombre(s)',
      lastName: 'Apellido(s)',
      email: 'Email',
      telefono: 'Teléfono celular',
      telefonoError: 'Ingresa un teléfono válido',
    },
    placeholder: {
      name: 'Ingresa el Nombre',
      lastName: 'Ingresa el Apellido',
      email: 'Ingresa elemail',
      telefono: 'Número',
    },
  },

  snackbarMessage: {
    error: 'Ocurrió un error inesperado.',
  },

  title: 'Referencia',
  ctaConfirm: 'Confirmar',
  ctaReject: 'Rechazar',
  ctaIrHome: 'Ir a ',
}
