const curriculum = {
  marketplace: {
    enabled: false,
    mobileView: false,
    products: {
      enabled: false,
    },
  },
}

export default curriculum
