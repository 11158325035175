import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// components
import CantidadEmpresas from '../cantidad-empresas'
import { DataLayerListadoEmpresas } from '../../../../constants/gtm-events-listado-empresas'
import Sort from '../../../../components/sort'

// mixins
import { SortEmpresasContainer } from './mixins'

// redux
import { getEmpresasSortSettings } from '../../../../redux/actions/sort'

// constants
import { SITE_ID } from '../../../../constants'
import features from '../../../../constants/features-per-country'

const sortEmpresas = features[SITE_ID || 'BMAR']?.sortEmpresas

interface SortEmpresasProps {
  location: RouteComponentProps['location']
  hideQuantity?: boolean
  totalEmpresas: number
}

const SortEmpresas = ({ location, hideQuantity, totalEmpresas }: SortEmpresasProps) => {
  const queryParams = new URLSearchParams(location.search)
  const dispatch = useDispatch()

  useEffect(() => {
    if (sortEmpresas?.enabled) {
      dispatch(getEmpresasSortSettings())
    }
  }, [])

  if (!sortEmpresas?.enabled || totalEmpresas < 1) {
    return null
  }

  return (
    <SortEmpresasContainer>
      {hideQuantity && <CantidadEmpresas />}
      <Sort path={location?.pathname} queryParams={queryParams} dataLayers={DataLayerListadoEmpresas} />
    </SortEmpresasContainer>
  )
}

const mapStateToProps = ({ listadoEmpresasStore }) => ({
  totalEmpresas: listadoEmpresasStore.totalEmpresas,
})

export default connect(mapStateToProps)(SortEmpresas)
