import React from 'react'
import { makeURL } from '@navent-jobs/interlinking'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// mixins
import { CustomLink, DetailContainer, LinkContainer, Title } from './mixins'

// components
import DetailPromedioSalarial from '../detail'

const HeaderPromedioSalarial = ({ promedioSalarial, showButton }) => {
  const { isMobile } = useWindowSize()

  if (!promedioSalarial) return null

  return (
    <DetailContainer>
      <Title>Salario pretendido de {promedioSalarial.nombre}</Title>
      <DetailPromedioSalarial promedio={promedioSalarial.promedio} cantidad={promedioSalarial.cantidad} bigger />

      {showButton && (
        <LinkContainer isMobile={isMobile}>
          <CustomLink
            href={makeURL({
              subarea: promedioSalarial.idSemantico,
            })}
            linkoptions={{
              native: false,
              size: 'medium',
              type: 'fill',
              variant: 'primary',
              target: '_black',
            }}
          >
            Ver empleos
          </CustomLink>
        </LinkContainer>
      )}
    </DetailContainer>
  )
}

export default HeaderPromedioSalarial
