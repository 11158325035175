import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  background-color: #e4e4e4;
  align-self: center;
  border-radius: 4px;
`

export const Filler = styled.div`
  width: ${({ fillPercentage }) => `${fillPercentage}%`};
  height: 12px;
  background-color: #fab306;
  border-radius: 4px;
`

export const IdText = styled.div`
  width: 8px;
  margin-right: 5px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
`

export const IconStar = styled(Icon)`
  align-self: center;
  margin-right: 5px;
`

export const TextContainer = styled.div`
  width: 40px;
  margin-left: 4px;
`

export const Wrapper = styled.div`
  margin-left: 0;
`
