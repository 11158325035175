import styled from 'styled-components'

export const EmpresaCard = styled.div`
  margin-bottom: 12px;
`

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
`

export const CustomContainer = styled.div``
