import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomLink, Text } from './mixins'
import { DataLayer } from '../../../../constants/gtm-events-sign-up'

interface ComponentProps {
  returnTo: string | null
  neutro: boolean
}

const Component = ({ returnTo, neutro }: ComponentProps) => {
  const { t } = useTranslation()

  const generateHref = () => {
    const returnToParam = returnTo ? `?returnTo=${returnTo}` : ''
    const neutroParam = neutro ? `${returnTo ? '&' : '?'}neutro=true` : ''
    return `/login${returnToParam}${neutroParam}`
  }

  const href = generateHref()

  return (
    <ComponentContainer>
      <Text>{t('signUp.conCuenta')}</Text>

      <CustomLink
        href={href}
        onClick={() => DataLayer.linkIngresar()}
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('signUp.ingresa')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
