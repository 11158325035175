import styled from 'styled-components'
import { LinkedIn } from 'react-linkedin-login-oauth2'

export const MainContainer = styled.div`
  height: 64px;
`

export const LinkedInButton = styled(LinkedIn)`
  padding: 0;
  background-color: transparent;
  border: none;
`

export const ButtonImg = styled.img`
  align-self: center;
  width: 100%;
  max-width: 330px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 100%;
  }
`
