const fichaAviso = {
  enabled: true,
  match: false,
  tabs: {
    detail: true,
    company: true,
    avisosRelacionados: true,
  },
  components: {
    createAlert: true,
    salarioPretendido: true,
  },
  postPostulacion: {
    subscriptionCheck: true,
  },
  calculadoraSalario: {
    enabled: false,
  },
}

export default fichaAviso
