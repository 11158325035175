import styled from 'styled-components'
import ReactSelect from 'react-select'

const SelectStyles = theme => `
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  padding: 0;

  /**
    * Estilos en caso que el campo posea contenido o se encuentra activo
    */
  .select__indicators {
    .select__dropdown-indicator {
      transition: all 0.2s ease-in-out;
      transform: rotate(0deg);
    }

    span {
      display: none;
    }

    .select__indicator {
      padding: 0;
    }

    i:before {
      background-color: rgba(0, 0, 24, 0.24);
    }
  }

  & > .select__control,
  & > .select__control:hover {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    min-height: 0;
    line-height: 34px;
    cursor: pointer;

    .select__value-container {
      padding: 0;
      line-height: 34px;

      .select__placeholder {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        color: rgba(0, 0, 24, 0.6);
      }

      .select__single-value {
        margin: 0px;
        font-weight: 600;
        color: ${theme.colors.secondary.lighten};
      }

      .select__input {
        input {
          font-family: ${theme.fonts.families.base};
        }
      }

      & > [class^='css-'] {
        margin: 0;
        padding: 0;
      }
    }

    &.select__control--menu-is-open {
      border: 0;

      .select__indicators {
        .select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }

  & > .select__menu {
    border-radius: 8px;
    background: ${theme.colors.grey4};
    color: rgba(0, 0, 24, 0.6);
    padding: 8px;
    height: 250px;

    .select__menu-list {
      padding: 0px;
      height: 100%;

      .select__option {
        border-radius: 4px;
        font-weight: 600;
        padding: 0 12px;
        line-height: 36px;
        font-size: 14px;
        cursor: pointer;

        &.select__option--is-selected,
        &.select__option--is-focused {
          background: ${theme.colors.grey0};
        }

        &.select__option--is-focused {
          color: rgba(0, 0, 24, 0.6);
        }

        &.select__option--is-selected {
          color: ${theme.colors.secondary.normal};
        }
      }
    }
  }
`

export const SelectField = styled(ReactSelect)`
  ${({ theme }) => SelectStyles(theme)}
`
