import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Link } from '@navent-jobs/ui-kit/components/link'

export const Toggle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;

  & > i,
  & > i:hover {
    right: 0px;
  }
`

export const CardFooterContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: ${({ hasCollapsed }) => (hasCollapsed ? '12px' : '0px')};
`
const notificationDot = `
  &::after{
    content:'';
    position: absolute;
    background-color: #F13D89;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    right: 2px;
    top: 0px;
    border: 2px solid white;
  }
`

export const LinkMessages = styled(Link)`
  font-weight: normal;
  line-height: 20px;
  padding-left: 27px;
  ${({ disabled }) => disabled && 'cursor: default;'}
  ${({ hasComments }) => hasComments && notificationDot}
`

export const ItemGroup = styled('div')`
  display: flex;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0px;

    &:last-child {
      margin-right: 0px;
    }
  }
`

export const Label = styled('div')`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 5px;
`

export const IconCustom = styled(Icon)`
  margin-right: 10px;
`

export const OffSetCol = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    flex-direction: row;
  }
`

export const LinkAvisos = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

export const EstadoWrapper = styled('div')`
  background-color: ${({ estado, theme }) => (estado === 'finalizada' ? '#474759' : theme.colors.extra.ultraLighten)};
  border-radius: 8px;
  padding: 6px 15px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-right: 24px;
  }
`
