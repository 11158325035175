import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services/mail-service'
import {
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR,
  SendActivationEmail,
} from '../types/activation-email'

function* sendActivationEmail({ payload }: SendActivationEmail) {
  try {
    const result = yield call(services.sendMail, payload)
    yield put({ type: SEND_ACTIVATION_EMAIL_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: SEND_ACTIVATION_EMAIL_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* activationEmail() {
  yield takeLatest(SEND_ACTIVATION_EMAIL, sendActivationEmail)
}
