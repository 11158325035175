const products = {
  pda: {
    marketplace: true,
    curriculumMarketplace: true,
    landing: false,
  },
  nulinga: {
    marketplace: true,
    curriculumMarketplace: false,
    curriculumLanguage: true,
    landing: false,
  },
  nulingaCourse: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  salario: {
    landing: true,
  },
}

export default products
