import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// ui-kit
import { Row, Col, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Textarea } from '@navent-jobs/ui-kit/components/textarea'

// mixins
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { CustomButton, Label, WrapperTextarea } from './mixins'

// redux
import { sendDataForm } from '../../../../../redux/actions/products'
import { hideModal } from '../../../../../redux/actions/modal'
import { ModalSidebar } from '../../../../../components/ModalSidebar'
import { DataLayers } from '../../../../../constants/gtm-events-marketplace'

interface ModalAddProductProps {
  width: number
  name: string
  activeModal: string
  loading: boolean
}

interface ModalFooterProps {
  isFormValid: boolean
  isLoading: boolean
  closeModal?: () => void
  showStickyFormButtons: boolean
  t: any
}

interface ButtonSendFormData {
  isFormValid: boolean
  isLoading: boolean
  t: any
}

const ButtonSendFormData = ({ isFormValid, isLoading, t }: ButtonSendFormData) => {
  return (
    <CustomButton
      id="send-info-add-product"
      type="submit"
      form="form-add-product"
      disabled={!isFormValid || isLoading}
      buttonoptions={{ variant: 'primary' }}
      onClick={() => DataLayers.envioFormulario('Intencion')}
      isLoading={isLoading}
    >
      {t('products.form.modal.buttons.enviar')}
    </CustomButton>
  )
}

const FormButtons = ({ isFormValid, isLoading, closeModal, showStickyFormButtons, t }: ModalFooterProps) => {
  return (
    <>
      {!showStickyFormButtons ? (
        <ButtonSendFormData isFormValid={isFormValid} isLoading={isLoading} t={t} />
      ) : (
        <ContainerFluid>
          <Row>
            <Col md={{ size: 6, order: 2 }}>
              <ButtonSendFormData isFormValid={isFormValid} isLoading={isLoading} t={t} />
            </Col>

            <Col md={{ size: 4, order: 1 }} offset={{ md: 2 }}>
              <CustomButton onClick={closeModal} buttonoptions={{ variant: 'primary', type: 'link' }}>
                {t('products.form.modal.buttons.cancelar')}
              </CustomButton>
            </Col>
          </Row>
        </ContainerFluid>
      )}
    </>
  )
}

export const ModalAddProduct = ({ name, activeModal, width, loading }: ModalAddProductProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize()

  // get values inputs
  const [nameSurname, setNameSurname] = useState('')
  const [nameCompany, setNameCompany] = useState('')
  const [nameSite, setNameSite] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  const visible = activeModal === name
  const showStickyFormButtons = isTabletPortrait || isTabletLandscape || isDesktop

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const checkIsFilledName = value => {
    const isFilled = value.replace(/\s/g, '') !== ''
    if (!isFilled) {
      return t('products.form.modal.rules.isFilled')
    }
    return isFilled
  }

  const fieldRules = {
    required: t('products.form.modal.rules.isFilled'),
    validate: {
      checkIsFilledName,
    },
  }

  const emailRules = {
    required: t('products.form.modal.rules.email'),
    pattern: {
      value: mailformat,
      message: t('products.form.modal.rules.email'),
    },
  }

  const dataForm = {
    nameSurname,
    nameCompany,
    nameSite,
    email,
    message,
  }

  const handleCloseModal = () => {
    dispatch(hideModal())
  }

  const handleAddProduct = () => {
    dispatch(sendDataForm(dataForm))
    handleCloseModal()
  }

  return (
    <ModalSidebar
      width={width}
      visible={visible}
      onClose={handleCloseModal}
      title={t('products.form.modal.title')}
      reminder={t('products.form.modal.requieredFields')}
      footer={
        <FormButtons
          isFormValid={isValid}
          isLoading={loading}
          closeModal={handleCloseModal}
          showStickyFormButtons={showStickyFormButtons}
          t={t}
        />
      }
    >
      <form id="form-add-product" onSubmit={handleSubmit(handleAddProduct)}>
        <Row>
          <Col md={6}>
            <Input
              id="nameSurname"
              name="nameSurname"
              type="text"
              rules={register(fieldRules) as any}
              placeholder={t('products.form.modal.placeholder.nameSurname')}
              onChange={e => {
                setNameSurname(e.target.value)
              }}
              fieldOptions={{
                label: <Label>{t('products.form.modal.label.nameSurname')}</Label>,
                variant: 'darken',
                maxLength: 50,
              }}
              errors={{ ...errors }}
            />
          </Col>

          <Col md={6}>
            <Input
              id="nameCompany"
              name="nameCompany"
              type="text"
              rules={register(fieldRules) as any}
              placeholder={t('products.form.modal.placeholder.nameCompany')}
              onChange={e => {
                setNameCompany(e.target.value)
              }}
              fieldOptions={{
                label: <Label>{t('products.form.modal.label.nameCompany')}</Label>,
                variant: 'darken',
                maxLength: 50,
              }}
              errors={{ ...errors }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Input
              id="nameSite"
              name="nameSite"
              type="text"
              rules={register(fieldRules) as any}
              placeholder={t('products.form.modal.placeholder.nameSite')}
              onChange={e => {
                setNameSite(e.target.value)
              }}
              fieldOptions={{
                label: <Label>{t('products.form.modal.label.nameSite')}</Label>,
                variant: 'darken',
                maxLength: 50,
              }}
              errors={{ ...errors }}
            />
          </Col>

          <Col md={6}>
            <Input
              id="userEmail"
              name="userEmail"
              type="email"
              rules={register(emailRules) as any}
              placeholder={t('products.form.modal.placeholder.email')}
              onChange={e => {
                setEmail(e.target.value)
              }}
              fieldOptions={{
                label: <Label>{t('products.form.modal.label.email')}</Label>,
                variant: 'darken',
                maxLength: 50,
              }}
              errors={{ ...errors }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <WrapperTextarea>
              <Textarea
                id="textarea-mensaje"
                name="description"
                rules={register(fieldRules) as any}
                onChange={e => {
                  setMessage(e.target.value)
                }}
                placeholder={t('products.form.modal.placeholder.message')}
                fieldOptions={{
                  label: <Label>{t('products.form.modal.label.message')}</Label>,
                  variant: 'darken',
                  allowOnlyText: true,
                }}
                errors={{ ...errors }}
              />
            </WrapperTextarea>
          </Col>
        </Row>
      </form>
    </ModalSidebar>
  )
}

const states = ({ modalStore, productsStore }) => ({
  activeModal: modalStore.activeModal,
  loading: productsStore.SendDataForm.states.loading,
})

export default connect(states)(ModalAddProduct)
