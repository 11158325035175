import styled from 'styled-components'

import { Button } from '@navent-jobs/ui-kit/components/button'

export const Img = styled.img``

export const Content = styled.div`
  text-align: center;
  align-items: center;
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 12px;
`

export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  padding-top: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 24px;
  }
`

export const CustomButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 20px;
  
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top 24px;
    margin-bottom: 8px;
    max-width: 200px;
  }
`

export const Description = styled.p`
  font-size: 16px;
`
