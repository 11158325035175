const suggestionsHome = {
  userLogged: {
    title: 'Mejorá tu perfil profesional',
    description1:
      'Completá tu perfil: tomate el tiempo necesario para completar cada campo de manera clara y brindar información de valor sobre tu perfil.',
    description2:
      'Mantené tu información actualizada: si cambiaste de empleo o tus datos de contacto, ¡registralo! Para los empleadores es esencial que tu perfil esté al día. ',
    description3:
      'Sumá tus conocimientos y habilidades: además de tus experiencias y estudios, agregá tanto tus conocimientos técnicos como tus cualidades relacionadas al desempeño de tu trabajo.',
    description4:
      'Realizá el test de personalidad: a través de él podrás identificar de forma gratuita tus talentos y virtudes, y mostrárselos a las empresas.',
    cta: 'ir a mi perfil',
  },
  userNotLogged: {
    title: 'Create una cuenta y encontrá el trabajo que buscás',
    description1: 'Ingresá en la opción Crear cuenta, escribí tus datos y confirmalos.',
    description2:
      'Completá la información principal de tu perfil a través de las preguntas por pasos que te haremos Inmediatamente después de que te registres.',
    description3: 'Postulate a los trabajos que más te interesen y seguí el proceso de tus postulaciones.',
    description4: 'Recordá mantener tu información actualizada desde la sección de tu perfil',
  },
}

export default suggestionsHome
