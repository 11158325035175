import { TagManager } from '@navent-jobs/config'

export const DataLayer = {
  handleModalView: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Activa tu cuenta',
      eventLabel: 'Impresion Modal',
    })
  },

  handleModalClose: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Activa tu cuenta',
      eventLabel: 'Cerrar Modal',
    })
  },

  handleSendEmail: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Activa tu cuenta',
      eventLabel: 'CTA Reenviarme el mail',
    })
  },
}
