import styled from 'styled-components'

export const ContainerAnchorBar = styled.div`
  width: 100%;
  max-width: 1038px;
  background-color: ${({ theme }) => theme.colors.secondary.ultraLighten};
`

export const AnchorbarSpacer = styled.div`
  width: 104%;
  height: 24px;
  padding-left: 2%;
  margin-left: -2%;
  background-color: ${({ theme }) => theme.colors.secondary.ultraLighten};
`

export const Link = styled.a``

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
`

export const PlaceholderHeader = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px;
  margin-bottom: 20px;
`
export const PlaceholderBody = styled.div`
  background: white;
  margin-top: 20px;
  border-radius: 8px;
  padding-top: 10px;
`
