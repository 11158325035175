import { SiteConfig, SiteIds } from '@navent-jobs/config'
// import { newRelicCodes } from './new-relic'
// import { sitesGTMCodes } from './gtmcodes'

const SITE_ID = window.SITE_ID ?? process.env.REACT_APP_SITE_ID

if (!SITE_ID) {
  throw new Error('You must provide an env variable for SITE_ID')
}

// const GTM_ID = sitesGTMCodes[SITE_ID] && sitesGTMCodes[SITE_ID].GTM
// if (!GTM_ID) Error(`No GMT for site "${SITE_ID}"`)

// const GTM_TID = sitesGTMCodes[SITE_ID] && sitesGTMCodes[SITE_ID].UA
// if (!GTM_TID) Error(`No Tracking code for site "${SITE_ID}"`)

// const NR_LICENSE_KEY = newRelicCodes[SITE_ID] && newRelicCodes[SITE_ID].licenseKey
// const NR_LICENSE_KEY = NR_KEY
// if (!NR_LICENSE_KEY) Error(`No New Relic licenseKey for site "${SITE_ID}"`)

// const NR_APPLICATION_ID = newRelicCodes[SITE_ID] && newRelicCodes[SITE_ID].applicationID
// NR_APPLICATION_ID = NR_APP_ID
// if (!NR_APPLICATION_ID) Error(`No New Relic applicationID for site "${SITE_ID}"`)
const OG_ID_BY_SITE = {
  Bumeran: 150410907376,
  Multitrabajos: 315939446596,
  Laborum: 241632691842,
  Konzerta: 131671066932219,
}

const siteConfig = SiteConfig[SITE_ID]

// **Constantes** que se deducen de SITE_ID al hacer build
const IS_ZONA_JOBS = SITE_ID === SiteIds.ZJAR
const NOMBRE_SITIO = siteConfig.nombre
const ID_PAIS = siteConfig.pais.id
const NOMBRE_PAIS = siteConfig.pais.nombre
const SUFIJO_PAIS = siteConfig.pais.sufijo
const BASE_URL = `${window.SEO_PROTOCOL || window.location.protocol}//${window.SEO_HOST || window.location.host}`

export {
  SiteIds,
  SITE_ID,
  NOMBRE_SITIO,
  IS_ZONA_JOBS,
  ID_PAIS,
  NOMBRE_PAIS,
  SUFIJO_PAIS,
  BASE_URL,
  OG_ID_BY_SITE,
  // GTM_ID,
  // GTM_TID
}
