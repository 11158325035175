import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// ui-kit
import { MainContainer } from '@navent-jobs/ui-kit/components/grid'

// components
import PageTitle from '../../components/PageTitle'
import Metas from '../../components/metas/promedio-salarial'
import ListadoPromedioSalarial from './components/list'

// redux
import { getPromediosSalariales } from '../../redux/actions/promedioSalarial'

const PromedioSalarial = ({ error, loading, promedios }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPromediosSalariales())
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Metas />
      <PageTitle iconName={t(`currencyMask.icon`)} title="Salario pretendido" maxWidth={1392}></PageTitle>

      <MainContainer>
        <ListadoPromedioSalarial error={error} loading={loading} promedios={promedios} iconBtn />
      </MainContainer>
    </>
  )
}

const states = ({ promedioSalarialStore }) => ({
  error: promedioSalarialStore.statesPromedios.error,
  loading: promedioSalarialStore.statesPromedios.loading,
  promedios: promedioSalarialStore.promedios,
})

export default connect(states)(PromedioSalarial)
