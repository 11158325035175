import {
  initialState,
  PostularQrAction,
  PostularQrState,
  POSTULAR_QR,
  POSTULAR_QR_ERROR,
  POSTULAR_QR_RESET,
  POSTULAR_QR_SUCCESS,
} from '../types/postulacion-qr'

const reducers = (state: PostularQrState = initialState, action: PostularQrAction): PostularQrState => {
  switch (action.type) {
    case POSTULAR_QR:
      return {
        ...state,
        status: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case POSTULAR_QR_SUCCESS:
      return {
        ...state,
        output: action.payload,
        status: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case POSTULAR_QR_ERROR:
      return {
        ...state,
        status: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case POSTULAR_QR_RESET:
      return initialState
    default:
      return state
  }
}

export default reducers
