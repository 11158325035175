import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// libs
import { makeFichaAvisoURL } from '@navent-jobs/interlinking'

// ui-kit
import Pagination from '@navent-jobs/ui-kit/components/pagination-fluid'

// components
import ModalPreguntas from '../../../ficha-aviso/modals/modal-preguntas'

// mixins
import { PostulacionesCard } from './mixins'

// redux
import { sendAnswers, sendAnswersReset } from '../../../../redux/actions/postular-bar'
import { resetActiveAviso, setActiveAviso } from '../../../../redux/actions/active-aviso'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { IS_ZONA_JOBS } from '../../../../constants'
import ModalSeleccionEstado from '../card/components/CardFooter/modal'
import { putEstadoSeleccion, resetPutEstadoSeleccion } from '../../../../redux/actions/postulaciones'

const CONFIDENCIAL = 'Confidencial'

interface ListadoPostulacionesProps {
  postulaciones: any[]
  pagination: {
    currentPage: number
    elementPerPage: number
    totalElements: number
    totalPages: number
  }
  loading: boolean
  currentPage: number
  queryParams: URLSearchParams
  activeAviso: any
  sendAnswersStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
  activeAvisoStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
  estadosSeleccion: {
    id: number
    nombre: string
    descripcion: string
  }[]
  estadosSeleccionPutStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

const ListadoPostulaciones = ({
  postulaciones,
  pagination,
  loading,
  currentPage,
  queryParams,
  activeAviso,
  sendAnswersStates,
  activeAvisoStates,
  estadosSeleccion,
  estadosSeleccionPutStates,
}: ListadoPostulacionesProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { aviso } = activeAviso

  const [visibleModal, setVisibleModal] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isLoadingAnswers, setLoadingAnswers] = useState(false)
  const [idPostulacion, setIdPostulacion] = useState()
  const hideModal = () => setVisibleModal('')
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [dataOfJobApplicattion, setDataOfJobApplicattion] = useState<{ postulacionId: number; tituloAviso: string }>({
    postulacionId: 0,
    tituloAviso: '',
  })
  const [estadoSeleccionGuardado, setEstadoSeleccionGuardado] = useState(0)

  const onClickAviso = (event, url) => {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      window.open(url, '_blank')
    }
  }

  const onClickSendAnswers = (avisoId, id) => {
    setLoading(true)
    setIdPostulacion(id)
    dispatch(setActiveAviso({ id: avisoId }))
  }

  const onClickChangeStatus = (postulacionId, tituloAviso) => {
    setDataOfJobApplicattion({ postulacionId, tituloAviso })
    setVisibleModal('modal-seleccion-estado')
  }

  const handleSendAnswers = async ({ respuestas }) => {
    if (idPostulacion && respuestas) {
      dispatch(sendAnswers({ idPostulacion, respuestas }))
    }
  }

  const handleSave = estadoId => {
    setEstadoSeleccionGuardado(+estadoId)
    dispatch(putEstadoSeleccion(dataOfJobApplicattion.postulacionId, estadoId))
  }

  useEffect(() => {
    postulaciones && document.documentElement.scrollTo(0, 0)
  }, [postulaciones])

  /**
   * useEffect () => Mostrar modal preguntas o snackbar error
   */
  useEffect(() => {
    if (activeAviso?.aviso) {
      setVisibleModal('modal-preguntas-aviso')
    }

    if (activeAvisoStates?.error) {
      showSnackbarError(t('curriculum.snackbarNotificationError'))
    }

    setLoading(activeAvisoStates?.loading)
  }, [activeAviso, activeAvisoStates])

  /**
   * useEffect () => Envio de preguntas
   */
  useEffect(() => {
    if (sendAnswersStates?.success) {
      hideModal()
      dispatch(resetActiveAviso())
      dispatch(sendAnswersReset())
      dispatch(showSnackbar('Enviamos tus respuestas', 'icon-light-checkmark-circle-1'))
    }

    if (sendAnswersStates?.error) {
      dispatch(sendAnswersReset())
      dispatch(showSnackbarError(t('curriculum.snackbarNotificationError')))
    }

    setLoadingAnswers(sendAnswersStates?.loading)
  }, [sendAnswersStates])

  useEffect(() => {
    setLoadingSave(estadosSeleccionPutStates?.loading)
    if (estadosSeleccionPutStates?.success) {
      hideModal()
      /** Cambio a mano el estadoSeleccion de la postulacion a la cual se le cambio el estado para no tener que hacer el pedido entero de postulaciones */
      const postulacionACambiar = postulaciones.find(
        postulacion => postulacion.id === dataOfJobApplicattion.postulacionId
      )
      if (postulacionACambiar) {
        postulacionACambiar.estadoSeleccion = estadosSeleccion?.find(e => e.id === estadoSeleccionGuardado)?.nombre
      }
      setEstadoSeleccionGuardado(0)
      dispatch(resetPutEstadoSeleccion())
    }
  }, [estadosSeleccionPutStates])

  return (
    <>
      {postulaciones &&
        postulaciones.map(postulacion => {
          return (
            <PostulacionesCard
              key={postulacion.id}
              sectionCard="mis-postulaciones"
              activeAvisoStates={activeAvisoStates}
              postulacion={postulacion}
              isLoading={isLoading}
              disableLink={false}
              onClickSendAnswers={onClickSendAnswers}
              onClickChangeStatus={onClickChangeStatus}
              onClick={({ event }) => {
                if (IS_ZONA_JOBS) {
                  if (!(event.ctrlKey || event.metaKey)) {
                    event.preventDefault()
                    window.open(`/empleos/${postulacion.avisoId}.html`, '_blank')
                  }
                } else {
                  onClickAviso(
                    event,
                    makeFichaAvisoURL({
                      ...postulacion,
                      empresa: {
                        denominacion: postulacion.empresa,
                        confidencial: postulacion.empresa === CONFIDENCIAL || postulacion.empresa === null,
                      },
                      id: postulacion.avisoId,
                    })
                  )
                }
              }}
              link={
                IS_ZONA_JOBS
                  ? `/empleos/${postulacion.avisoId}.html`
                  : makeFichaAvisoURL({
                      ...postulacion,
                      empresa: {
                        denominacion: postulacion.empresa,
                        confidencial: postulacion.empresa === CONFIDENCIAL || postulacion.empresa === null,
                      },
                      id: postulacion.avisoId,
                    })
              }
            />
          )
        })}

      <Pagination
        {...pagination}
        currentPage={Number(currentPage) + 1}
        currentUrl={window.location.pathname}
        states={{ loading }}
        queryParams={queryParams}
      />

      <ModalPreguntas
        width={800}
        closeModal={() => {
          hideModal()
          dispatch(resetActiveAviso())
        }}
        visible={visibleModal === 'modal-preguntas-aviso'}
        preguntas={aviso?.preguntas}
        sendAnswers={handleSendAnswers}
        loading={isLoadingAnswers}
        title={aviso?.titulo}
        preguntasObligatorias={false}
      />

      <ModalSeleccionEstado
        width={600}
        closeModal={() => {
          hideModal()
        }}
        visible={visibleModal === 'modal-seleccion-estado'}
        handleSave={handleSave}
        loading={isLoadingSave}
        tituloAviso={dataOfJobApplicattion.tituloAviso}
        opciones={estadosSeleccion}
      />
    </>
  )
}

const states = ({ postulacionesStore, activeAvisoStore, postularBarStore }) => ({
  postulaciones: postulacionesStore.postulaciones,
  pagination: postulacionesStore.pagination,
  loading: postulacionesStore.loading,
  error: postulacionesStore.error,
  activeAviso: activeAvisoStore,
  activeAvisoStates: activeAvisoStore.activeAvisoStates,
  sendAnswersStates: postularBarStore?.SendAnswers?.states,
  estadosSeleccion: postulacionesStore.estados_seleccion.estados,
  estadosSeleccionPutStates: postulacionesStore.estados_seleccion.putState,
})

export default connect(states)(ListadoPostulaciones)
