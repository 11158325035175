export const productLanding = {
  nulinga: {
    because: {
      answer2: {
        answer:
          'Podrás mostrar tu nivel del idioma a las empresas para destacar tu postulación en las búsquedas de Konzerta si así lo deseas.',
      },
      answer3: {
        answer:
          'Resaltarás tu perfil al contar con la validación de Nulinga en tu HV y aumentarás las chances de que las empresas te contacten.',
      },
    },
    description: {
      firstRow: {
        secondColumn: {
          description:
            'Los profesionales de Nulinga analizarán tu resultado y lo obtendrás en 48 horas en la sección de idiomas de tu HV de Konzerta.',
        },
        thirdColumn: {
          description: 'Puedes hacer el test por un costo accesible y realizar el pago fácilmente a través de dLocal.',
        },
      },
    },
  },
}
