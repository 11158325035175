const postulaciones = {
  empty: {
    title: 'Por el momento no encontramos postulaciones para este filtro',
    subtitle: '¡Realiza una búsqueda y postúlate a tu empleo ideal!',
    buttonSubmit: 'Buscar trabajos',
  },
  estados: {
    todos: 'Todos',
    activos: 'Avisos activos',
    enviado: 'CV enviado',
    leido: 'CV leído',
    enproceso: 'En proceso de selección',
    finalizado: 'Avisos finalizados',
    contactado: 'Contactado',
  },
  card: {
    salario: 'Sueldo pretendido: ',
  },
  search: {
    placeholder: 'Puesto, empresa o palabra clave',
    button: 'Buscar postulaciones',
  },
}

export default postulaciones
