import React from 'react'
import i18n from 'i18next'
import backgroundImage from '../../../assets/images/background-jobint-logo.svg'

import Home from '../components/home'
import Because from '../components/because'
import Description from '../components/description'
import Data from '../nulinga/data'
import Product from '../components/product'
import MercadoPagoWrapper from '../components/mercado-pago-wrapper'
import CheckStatusWrapper from '../components/check-status-wrapper'
import Highlight from '../components/highlight'
import Actions from '../components/actions'

// NULINGA
import MetasNulinga from '../../metas/product-landings/nulinga'
import nulingaLogo from '../../../assets/images/product-landings/nulinga/nulinga-logo.svg'
import nulingaHome from '../../../assets/images/product-landings/nulinga/nulinga-home.svg'
import nulingaBecause from '../../../assets/images/product-landings/nulinga/nulinga-because.svg'
import nulingaQuick from '../../../assets/images/product-landings/nulinga/nulinga-quick.svg'
import nulingaTrustable from '../../../assets/images/product-landings/nulinga/nulinga-trustable.svg'
import nulingaAccesible from '../../../assets/images/product-landings/nulinga/nulinga-accesible.svg'
import nulingaProduct from '../../../assets/images/product-landings/nulinga/nulinga-product.svg'

// MIGURU
import miguruLogo from '../../../assets/images/products/products-logos/miguru.svg'
import miguruHome from '../../../assets/images/product-landings/miguru/home.png'
import miguruBecause from '../../../assets/images/product-landings/miguru/because.svg'
import miguruName from '../../../assets/images/product-landings/miguru/name.svg'
import miguruCompleteCv from '../../../assets/images/product-landings/miguru/completeCv.svg'
import miguruEnterSite from '../../../assets/images/product-landings/miguru/enterSite.svg'
import miguruReady from '../../../assets/images/product-landings/miguru/ready.svg'
import miguruProduct from '../../../assets/images/product-landings/miguru/product.svg'
import miguruData from '../../../assets/images/product-landings/miguru/data.svg'
import { postAnalysisCvPreview } from '../../../redux/actions/analysis-cv'
import MetasMiguru from '../../metas/product-landings/miguru'
import MiguruPopOverImg from '../../../assets/images/product-landings/discount.svg'
import MiguruPopOverContent from '../miguru/popover'

// DOCTORCV
import doctorcvLogo from '../../../assets/images/products/products-logos/doctorCv.svg'
import doctorcvHome from '../../../assets/images/product-landings/doctorcv/home.webp'
import doctorcvBecause from '../../../assets/images/product-landings/doctorcv/because.webp'
import doctorcvImmediate from '../../../assets/images/product-landings/doctorcv/immediate.svg'
import doctorcvCustomizable from '../../../assets/images/product-landings/doctorcv/customizable.svg'
import doctorcvAccessible from '../../../assets/images/product-landings/doctorcv/accessible.svg'
import doctorcvData from '../../../assets/images/product-landings/doctorcv/data.webp'
import doctorcvProduct from '../../../assets/images/product-landings/doctorcv/product.webp'
import MetasDoctorcv from '../../metas/product-landings/doctorcv'

// PDA
import pdaLogo from '../../../assets/images/products/products-logos/pda.svg'
import pdaHome from '../../../assets/images/product-landings/pda/home.webp'
import pdaBecause from '../../../assets/images/product-landings/pda/because.webp'
import pdaTrustable from '../../../assets/images/product-landings/pda/trustable.svg'
import pdaQuick from '../../../assets/images/product-landings/pda/quick.svg'
import pdaAccessible from '../../../assets/images/product-landings/pda/accessible.svg'
import pdaData from '../../../assets/images/product-landings/pda/data.webp'
import pdaProduct from '../../../assets/images/product-landings/pda/product.webp'
import MetasPda from '../../metas/product-landings/pda'

// NULINGA COURSE
import nulingaCourseLogoBumeran from '../../../assets/images/products/products-logos/nulingaCourseBumeran.svg'
import nulingaCourseLogoKonzerta from '../../../assets/images/products/products-logos/nulingaCourseKonzerta.svg'
import nulingaCourseLogoLaborum from '../../../assets/images/products/products-logos/nulingaCourseLaborum.svg'
import nulingaCourseLogoMultitrabajos from '../../../assets/images/products/products-logos/nulingaCourseMultitrabajos.svg'
import nulingaCourseHome from '../../../assets/images/product-landings/nulingacourse/home.svg'
import teachers from '../../../assets/images/product-landings/nulingacourse/teachers.svg'
import groupChat from '../../../assets/images/product-landings/nulingacourse/group-chat.svg'
import contentImage from '../../../assets/images/product-landings/nulingacourse/content.svg'
import management from '../../../assets/images/product-landings/nulingacourse/management.svg'
import Benefits from '../nulinga-course/benefits'
import levels from '../../../assets/images/product-landings/nulingacourse/levels.svg'
import Time from '../nulinga-course/time'
import Share from '../nulinga-course/share'
import group from '../../../assets/images/product-landings/nulingacourse/group.svg'
import individual from '../../../assets/images/product-landings/nulingacourse/individual.svg'
import intensive from '../../../assets/images/product-landings/nulingacourse/intensive.svg'
import contact from '../../../assets/images/product-landings/nulingacourse/contact.svg'
import { LinkNulingaCourses } from './mixins'

// SALARIO
import MetasSalario from '../../metas/product-landings/salario'
import salarioHome from '../../../assets/images/product-landings/salario/home.svg'
import salarioMoney from '../../../assets/images/product-landings/salario/money.svg'
import salarioHandMoney from '../../../assets/images/product-landings/salario/hand-money.svg'
import salarioFees from '../../../assets/images/product-landings/salario/fees.svg'
import DescriptionCustom from '../salario/description'

// metricas
import { DataLayersLanguageTest } from '../../../constants/gtm-events-language-test'
import { DataLayerAnalysisCv } from '../../../constants/gtm-events-cv-analysis'

// constants
import features from '../../../constants/features-per-country'
import { SITE_ID } from '../../../constants'

const PRODUCT_CONFIG_NULINGA = 'nulinga'
const PRODUCT_MP_NAME_NULINGA = 'NULINGA'
const PRODUCT_CONFIG_MIGURU = 'miguru'
const PRODUCT_MP_NAME_MIGURU = 'MIGURU'
const PRODUCT_INFO_NAME_MIGURU = 'ANALISIS_CV_LABLAB'
const PRODUCT_CONFIG_DOCTORCV = 'doctorcv'
const PRODUCT_MP_NAME_DOCTORCV = 'DOCTORCV'
const PRODUCT_INFO_NAME_DOCTORCV = 'ANALISIS_CV_DR_CV'
const PRODUCT_CONFIG_PDA = 'pda'
const PRODUCT_INFO_NAME_PDA = 'PDA'
const PRODUCT_MP_NAME_PDA = 'PDA_POSTULANTE'
const PRODUCT_CONFIG_NULINGA_COURSE = 'nulingaCourse'
const PRODUCT_CONFIG_SALARIO = 'salario'

const nulingaCourseLogoSelector = () => {
  if (SITE_ID === 'BMCL') return nulingaCourseLogoLaborum
  if (SITE_ID === 'BMEC') return nulingaCourseLogoMultitrabajos
  if (SITE_ID === 'BMPA') return nulingaCourseLogoKonzerta
  return nulingaCourseLogoBumeran
}

export const productLandingTranslations = () => {
  return {
    nulinga: {
      home: {
        backgroundImage,
        logoImage: nulingaLogo,
        title: i18n.t('productLanding.nulinga.home.title'),
        description: i18n.t('productLanding.nulinga.home.description'),
        secondaryButtonText: i18n.t('productLanding.nulinga.home.secondaryButton'),
        secondaryMetric: () => DataLayersLanguageTest.landing('Conoce mas'),
        primaryButtonText: i18n.t('productLanding.nulinga.home.primaryButtonText'),
        primaryMetric: () => DataLayersLanguageTest.landing('Hacer el test-banner'),
        homeImage: nulingaHome,
        componentToScroll: '#BECAUSE',
      },
      because: {
        image: nulingaBecause,
        titleFirstPart: i18n.t('productLanding.nulinga.because.title'),
        bulletIconName: 'icon-light-rocket',
        bulletColor: '#0A26EE',
        bulletSize: '20',
        answers: [
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulinga.because.answer1.answer'),
          },
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulinga.because.answer2.answer'),
          },
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulinga.because.answer3.answer'),
          },
        ],
        imgHeight: '436px',
      },
      description: {
        logoImage: nulingaLogo,
        titleFirstPart: i18n.t('productLanding.nulinga.description.titleFirstPart'),
        titleSecondPart: i18n.t('productLanding.nulinga.description.titleSecondPart'),
        rows: [
          {
            firstColumn: {
              image: nulingaQuick,
              title: i18n.t('productLanding.nulinga.description.firstRow.firstColumn.title'),
              description: i18n.t('productLanding.nulinga.description.firstRow.firstColumn.description'),
            },
            secondColumn: {
              image: nulingaTrustable,
              title: i18n.t('productLanding.nulinga.description.firstRow.secondColumn.title'),
              description: i18n.t('productLanding.nulinga.description.firstRow.secondColumn.description'),
            },
            thirdColumn: {
              image: nulingaAccesible,
              title: i18n.t('productLanding.nulinga.description.firstRow.thirdColumn.title'),
              description: i18n.t('productLanding.nulinga.description.firstRow.thirdColumn.description'),
            },
          },
        ],
      },
      product: {
        productName: 'NULINGA',
        backgroundColor: '#3D47F5',
        productImage: nulingaProduct,
        logoImage: nulingaLogo,
        description: i18n.t('productLanding.nulinga.product.description'),
        buttonText: i18n.t('productLanding.nulinga.product.buttonText'),
        metric: () => DataLayersLanguageTest.landing('Hacer el test-footer'),
      },
    },
    miguru: {
      home: {
        backgroundImage,
        logoImage: miguruLogo,
        logoStyles: {
          height: 51,
        },
        title: i18n.t('productLanding.miguru.home.title', { cv: i18n.t('cv') }),
        description: i18n.t('productLanding.miguru.home.description', { cv: i18n.t('cv') }),
        secondaryButtonText: i18n.t('productLanding.miguru.home.secondaryButtonText'),
        secondaryMetric: () => DataLayerAnalysisCv.miguruPreview('banner'),
        primaryButtonText: i18n.t('productLanding.miguru.home.primaryButtonText', { cv: i18n.t('cv') }),
        primaryMetric: () => DataLayerAnalysisCv.landing('Analiza tu CV-banner'),
        homeImage: miguruHome,
        componentToScroll: 'none',
        popOver: {
          enabled: features[SITE_ID || 'BMAR'].products.lablab.discountPopover,
          id: 'miguru-button-popover',
          content: <MiguruPopOverContent />,
          imgSrc: MiguruPopOverImg,
        },
      },
      because: {
        image: miguruBecause,
        titleFirstPart: i18n.t('productLanding.miguru.because.title'),
        bulletSize: '20',
        answers: [
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.because.answer1.answer', { cv: i18n.t('cv') }),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.because.answer2.answer'),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.because.answer3.answer', { cv: i18n.t('cv') }),
          },
        ],
        description: i18n.t('productLanding.miguru.because.description'),
        answerTitle: i18n.t('productLanding.miguru.because.answerTitle'),
        imgHeight: '436px',
      },
      highlight: {
        textA: i18n.t('productLanding.miguru.highlight.textA'),
        bold: i18n.t('productLanding.miguru.highlight.bold'),
        textB: i18n.t('productLanding.miguru.highlight.textB'),
        buttonText: i18n.t('productLanding.miguru.highlight.buttonText'),
        metric: () => DataLayerAnalysisCv.miguruPreview('footer'),
      },
      description: {
        logoImage: miguruName,
        logoStyles: {
          marginBottomDesktop: 8,
          marginBottomMobile: 6,
          heightDesktop: 38,
          heightMobile: 19,
        },
        titleFirstPart: i18n.t('productLanding.miguru.description.titleFirstPart'),
        titleSecondPart: i18n.t('productLanding.miguru.description.titleSecondPart'),
        rows: [
          {
            firstColumn: {
              image: miguruCompleteCv,
              title: i18n.t('productLanding.miguru.description.firstRow.firstColumn.title', { cv: i18n.t('cv') }),
              description: i18n.t('productLanding.miguru.description.firstRow.firstColumn.description'),
            },
            secondColumn: {
              image: miguruEnterSite,
              title: i18n.t('productLanding.miguru.description.firstRow.secondColumn.title'),
              description: i18n.t('productLanding.miguru.description.firstRow.secondColumn.description', {
                cv: i18n.t('cv'),
              }),
            },
            thirdColumn: {
              image: miguruReady,
              title: i18n.t('productLanding.miguru.description.firstRow.thirdColumn.title'),
              description: i18n.t('productLanding.miguru.description.firstRow.thirdColumn.description', {
                cv: i18n.t('cv'),
              }),
            },
          },
        ],
      },
      data: {
        image: miguruData,
        titleFirstPart: i18n.t('productLanding.miguru.data.titleFirstPart'),
        logoImage: miguruName,
        titleSecondPart: i18n.t('productLanding.miguru.data.titleSecondPart'),
        bulletSize: '20',
        answers: [
          {
            title: i18n.t('productLanding.miguru.data.answer1.title'),
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.data.answer1.answer', { cv: i18n.t('cv') }),
          },
          {
            title: i18n.t('productLanding.miguru.data.answer2.title'),
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.data.answer2.answer'),
          },
          {
            title: i18n.t('productLanding.miguru.data.answer3.title'),
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.miguru.data.answer3.answer'),
          },
        ],
        description: i18n.t('productLanding.miguru.data.description', { cv: i18n.t('cv') }),
        infoText: i18n.t('productLanding.miguru.data.infoText'),
        infoTextLink: i18n.t('productLanding.miguru.data.infoTextLink'),
        infoTextLinkMetric: () => DataLayerAnalysisCv.miguruPreview('click aqui'),
        infoTextSecondPart: i18n.t('productLanding.miguru.data.infoTextSecondPart'),
        scrollRef: 'DATA',
      },
      product: {
        productName: PRODUCT_INFO_NAME_MIGURU,
        backgroundColor: '#3D47F5',
        productImage: miguruProduct,
        logoImage: miguruLogo,
        description: i18n.t('productLanding.miguru.product.description', { cv: i18n.t('cv') }),
        buttonText: i18n.t('productLanding.miguru.product.buttonText', { cv: i18n.t('cv') }),
        metric: () => DataLayerAnalysisCv.landing('Analiza tu CV-footer'),
      },
    },
    doctorcv: {
      home: {
        backgroundImage,
        logoImage: doctorcvLogo,
        logoStyles: {
          height: 51,
        },
        title: i18n.t('productLanding.doctorcv.home.title', { cv: i18n.t('cv') }),
        preTitle: i18n.t('productLanding.doctorcv.home.preTitle'),
        description: i18n.t('productLanding.doctorcv.home.description', { cv: i18n.t('cv') }),
        secondaryMetric: () => DataLayerAnalysisCv.landing('Conoce mas', 'Doctor CV'),
        secondaryButtonText: i18n.t('productLanding.doctorcv.home.secondaryButtonText'),
        primaryMetric: () => DataLayerAnalysisCv.landing('Analiza tu CV-banner', 'Doctor CV'),
        primaryButtonText: i18n.t('productLanding.doctorcv.home.primaryButtonText', { cv: i18n.t('cv') }),
        homeImage: doctorcvHome,
        componentToScroll: '#BECAUSE',
      },
      because: {
        image: doctorcvBecause,
        titleFirstPart: i18n.t('productLanding.doctorcv.because.title'),
        bulletSize: '20',
        answers: [
          {
            icon: 'icon-light-edit-1',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.because.answer1.answer', { cv: i18n.t('cv') }),
          },
          {
            icon: 'icon-light-edit-1',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.because.answer2.answer'),
          },
          {
            icon: 'icon-light-edit-1',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.because.answer3.answer'),
          },
        ],
        description: i18n.t('productLanding.doctorcv.because.description'),
        answerTitle: i18n.t('productLanding.doctorcv.because.answerTitle'),
      },
      description: {
        logoImage: doctorcvLogo,
        logoStyles: {
          marginBottomDesktop: 8,
          marginBottomMobile: 3,
          heightDesktop: 64,
          heightMobile: 32,
        },
        titleFirstPart: i18n.t('productLanding.doctorcv.description.titleFirstPart'),
        titleSecondPart: i18n.t('productLanding.doctorcv.description.titleSecondPart'),
        rows: [
          {
            firstColumn: {
              image: doctorcvImmediate,
              title: i18n.t('productLanding.doctorcv.description.firstRow.firstColumn.title'),
              description: i18n.t('productLanding.doctorcv.description.firstRow.firstColumn.description', {
                cv: i18n.t('cv'),
              }),
            },
            secondColumn: {
              image: doctorcvCustomizable,
              title: i18n.t('productLanding.doctorcv.description.firstRow.secondColumn.title'),
              description: i18n.t('productLanding.doctorcv.description.firstRow.secondColumn.description', {
                cv: i18n.t('cv'),
              }),
            },
            thirdColumn: {
              image: doctorcvAccessible,
              title: i18n.t('productLanding.doctorcv.description.firstRow.thirdColumn.title'),
              description: i18n.t('productLanding.doctorcv.description.firstRow.thirdColumn.description'),
            },
          },
        ],
      },
      data: {
        image: doctorcvData,
        titleFirstPart: i18n.t('productLanding.doctorcv.data.titleFirstPart'),
        bulletSize: '20',
        answers: [
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.data.answer1.answer'),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.data.answer2.answer'),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.doctorcv.data.answer3.answer', { cv: i18n.t('cv') }),
          },
        ],
        description: i18n.t('productLanding.doctorcv.data.description', { cv: i18n.t('cv') }),
        infoText: i18n.t('productLanding.doctorcv.data.infoText', { cv: i18n.t('cv') }),
        infoTextBold: i18n.t('productLanding.doctorcv.data.infoTextBold'),
        infoTextSecondPart: i18n.t('productLanding.doctorcv.data.infoTextSecondPart'),
        imgHeight: '516px',
      },
      product: {
        productName: PRODUCT_INFO_NAME_DOCTORCV,
        backgroundColor: '#3D47F5',
        productImage: doctorcvProduct,
        logoImage: doctorcvLogo,
        description: i18n.t('productLanding.doctorcv.product.description'),
        buttonText: i18n.t('productLanding.doctorcv.product.buttonText', { cv: i18n.t('cv') }),
        metric: () => DataLayerAnalysisCv.landing('Analiza tu CV-footer', 'Doctor CV'),
      },
    },
    pda: {
      home: {
        backgroundImage,
        logoImage: pdaLogo,
        title: i18n.t('productLanding.pda.home.title'),
        description: i18n.t('productLanding.pda.home.description', { cv: i18n.t('cv') }),
        secondaryButtonText: i18n.t('productLanding.pda.home.secondaryButton'),
        primaryButtonText: i18n.t('productLanding.pda.home.primaryButtonText'),
        homeImage: pdaHome,
        componentToScroll: '#BECAUSE',
      },
      because: {
        image: pdaBecause,
        titleFirstPart: i18n.t('productLanding.pda.because.title'),
        bulletSize: '20',
        description: i18n.t('productLanding.pda.because.description'),
        answers: [
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.because.answer1.answer', { cv: i18n.t('cv') }),
          },
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.because.answer2.answer'),
          },
          {
            icon: 'icon-light-rocket',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.because.answer3.answer'),
          },
        ],
        imgHeight: '436px',
      },
      description: {
        titleFirstPart: i18n.t('productLanding.pda.description.titleFirstPart'),
        rows: [
          {
            firstColumn: {
              image: pdaTrustable,
              title: i18n.t('productLanding.pda.description.firstRow.firstColumn.title'),
              description: i18n.t('productLanding.pda.description.firstRow.firstColumn.description'),
            },
            secondColumn: {
              image: pdaQuick,
              title: i18n.t('productLanding.pda.description.firstRow.secondColumn.title'),
              description: i18n.t('productLanding.pda.description.firstRow.secondColumn.description'),
            },
            thirdColumn: {
              image: pdaAccessible,
              title: i18n.t('productLanding.nulinga.description.firstRow.thirdColumn.title'),
              description: i18n.t('productLanding.pda.description.firstRow.thirdColumn.description'),
            },
          },
        ],
      },
      data: {
        image: pdaData,
        titleFirstPart: i18n.t('productLanding.pda.data.titleFirstPart'),
        bulletSize: '20',
        answers: [
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.data.answer1.answer'),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.data.answer2.answer'),
          },
          {
            icon: 'icon-light-clipboard',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.pda.data.answer3.answer'),
          },
        ],
        description: i18n.t('productLanding.pda.data.description'),
        imgHeight: '516px',
      },
      product: {
        productName: PRODUCT_MP_NAME_PDA,
        backgroundColor: '#3D47F5',
        productImage: pdaProduct,
        logoImage: pdaLogo,
        description: i18n.t('productLanding.pda.product.description'),
        buttonText: i18n.t('productLanding.pda.product.buttonText'),
      },
    },
    nulingaCourse: {
      home: {
        backgroundImage,
        logoImage: nulingaCourseLogoSelector(),
        title: i18n.t('productLanding.nulingaCourse.home.title'),
        description: i18n.t('productLanding.nulingaCourse.home.description'),
        // primaryButtonText: i18n.t('productLanding.nulingaCourse.home.primaryButtonText'),
        homeImage: nulingaCourseHome,
        logoStyles: {
          width: '100%',
        },
      },
      benefits: {
        titleFirstPart: i18n.t('productLanding.nulingaCourse.benefits.titleFirstPart'),
        titleSecondPart: i18n.t('productLanding.nulingaCourse.benefits.titleSecondPart'),
        logoImage: nulingaLogo,
        row1: {
          firstColumn: {
            image: teachers,
            title: i18n.t('productLanding.nulingaCourse.benefits.row1.firstColumn.title'),
            description: i18n.t('productLanding.nulingaCourse.benefits.row1.firstColumn.description'),
          },
          secondColumn: {
            image: groupChat,
            title: i18n.t('productLanding.nulingaCourse.benefits.row1.secondColumn.title'),
            description: i18n.t('productLanding.nulingaCourse.benefits.row1.secondColumn.description'),
          },
        },
        row2: {
          firstColumn: {
            image: contentImage,
            title: i18n.t('productLanding.nulingaCourse.benefits.row2.firstColumn.title'),
            description: i18n.t('productLanding.nulingaCourse.benefits.row2.firstColumn.description'),
          },
          secondColumn: {
            image: management,
            title: i18n.t('productLanding.nulingaCourse.benefits.row2.secondColumn.title'),
            description: i18n.t('productLanding.nulingaCourse.benefits.row2.secondColumn.description'),
          },
        },
        videoUrl: 'https://www.youtube.com/embed/COS8now8UWo',
      },
      because: {
        logoImage: nulingaLogo,
        titleFirstPart: i18n.t('productLanding.nulingaCourse.because.titleFirstPart'),
        titleSecondPart: i18n.t('productLanding.nulingaCourse.because.titleSecondPart'),
        bulletSize: '24',
        videoUrl: 'https://www.youtube.com/embed/V0Xotw8IvyU',
        answers: [
          {
            title: i18n.t('productLanding.nulingaCourse.because.answer1.title'),
            icon: 'icon-light-person',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulingaCourse.because.answer1.answer'),
          },
          {
            title: i18n.t('productLanding.nulingaCourse.because.answer2.title'),
            icon: 'icon-light-play-circle',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulingaCourse.because.answer2.answer'),
          },
          {
            title: i18n.t('productLanding.nulingaCourse.because.answer3.title'),
            icon: 'icon-light-publish',
            iconColor: '#0A26EE',
            answer: i18n.t('productLanding.nulingaCourse.because.answer3.answer'),
          },
        ],
        customContainerStyles: { paddingTop: 80, paddingLeft: 49, paddingBottom: 80 },
        customContainerMobileStyles: {
          paddingTop: 0,
          paddingBottom: 24,
          paddingLeft: 16,
          paddingRight: 16,
          flexDirection: 'column-reverse',
        },
        logoStyles: { height: 31, verticalAlign: 'middle' },
        logoMobileStyles: { height: 24, verticalAlign: 'middle' },
      },
      data: {
        titleFirstPart: i18n.t('productLanding.nulingaCourse.data.titleFirstPart'),
        image: levels,
        imgHeightMobile: 'auto',
        imgGutterMobile: true,
        transTextKey: 'productLanding.nulingaCourse.data.translationText',
        answerTitle: i18n.t('productLanding.nulingaCourse.data.answerTitle'),
        infoText: i18n.t('productLanding.nulingaCourse.data.infoText'),
        noPaddingTop: true,
        customContainerStyles: { background: 'transparent', paddingLeft: 46 },
        customContainerMobileStyles: { background: 'transparent', flexDirection: 'column-reverse' },
      },
      description: {
        titleFirstPart: i18n.t('productLanding.nulingaCourse.description.title'),
        rows: [
          {
            firstColumn: {
              image: group,
              title: i18n.t('productLanding.nulingaCourse.description.firstRow.firstColumn.title'),
              description: (
                <>
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.firstColumn.description1')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.firstColumn.description2')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.firstColumn.description3')}</span>
                </>
              ),
            },
            secondColumn: {
              image: individual,
              title: i18n.t('productLanding.nulingaCourse.description.firstRow.secondColumn.title'),
              description: (
                <>
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.secondColumn.description1')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.secondColumn.description2')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.secondColumn.description3')}</span>
                </>
              ),
            },
            thirdColumn: {
              image: intensive,
              title: i18n.t('productLanding.nulingaCourse.description.firstRow.thirdColumn.title'),
              description: (
                <>
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.thirdColumn.description1')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.thirdColumn.description2')}</span>
                  <br />
                  <span>{i18n.t('productLanding.nulingaCourse.description.firstRow.thirdColumn.description3')}</span>
                </>
              ),
            },
          },
        ],
        backgroundColor: '#EDF1F7',
      },
      product: {
        backgroundColor: '#3D47F5',
        productImage: nulingaProduct,
        logoImage: nulingaLogo,
        description: i18n.t('productLanding.nulingaCourse.product.description'),
        buttonText: i18n.t('productLanding.nulingaCourse.product.buttonText'),
      },
      contact: {
        image: contact,
        titleFirstPart: i18n.t('productLanding.nulingaCourse.contact.titleFirstPart'),
        bulletSize: '24',
        noPaddingBottom: true,
        answers: [
          {
            title: i18n.t('productLanding.nulingaCourse.contact.answer1.title'),
            icon: 'icon-light-whatsapp',
            iconColor: '#0A26EE',
            answer: (
              <>
                <LinkNulingaCourses
                  href="https://api.whatsapp.com/send/?phone=13166130217&text&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <u>{i18n.t('productLanding.nulingaCourse.contact.answer1.answerLink')}</u>
                </LinkNulingaCourses>
                <span>{i18n.t('productLanding.nulingaCourse.contact.answer1.answerText')}</span>
              </>
            ),
          },
          {
            title: i18n.t('productLanding.nulingaCourse.contact.answer2.title'),
            icon: 'icon-light-email',
            iconColor: '#0A26EE',
            answer: (
              <>
                <span>{i18n.t('productLanding.nulingaCourse.contact.answer2.answerText1')}</span>
                <LinkNulingaCourses
                  href="https://nulinga.com/colaboradores?utm_source=notion&utm_medium=landing&utm_campaign=meli_latam"
                  target="_blank"
                >
                  <u>{i18n.t('productLanding.nulingaCourse.contact.answer2.answerLink')}</u>
                </LinkNulingaCourses>
                <span>{i18n.t('productLanding.nulingaCourse.contact.answer2.answerText2')}</span>
              </>
            ),
          },
        ],
      },
    },
    salario: {
      home: {
        backgroundImage,
        title: i18n.t('productLanding.salario.home.title'),
        homeImage: salarioHome,
        showImageMobile: true,
      },
      description: {
        titleFirstPart: i18n.t('productLanding.salario.description.title'),
        information: i18n.t('productLanding.salario.description.information'),
        rows: [
          {
            firstColumn: {
              image: salarioMoney,
              title: i18n.t('productLanding.salario.description.rows.firstRow.firstColumn.title'),
              description: i18n.t('productLanding.salario.description.rows.firstRow.firstColumn.description'),
            },
            secondColumn: {
              image: salarioHandMoney,
              title: i18n.t('productLanding.salario.description.rows.firstRow.secondColumn.title'),
              description: i18n.t('productLanding.salario.description.rows.firstRow.secondColumn.description'),
            },
          },
        ],
      },
      because: {
        image: salarioFees,
        titleFirstPart: i18n.t('productLanding.salario.because.title'),
        bulletSize: '0',
        imgHeight: '760px',
        answers: [
          {
            title: i18n.t('productLanding.salario.because.answers.title1'),
            answer: i18n.t('productLanding.salario.because.answers.answer1'),
          },
          {
            title: i18n.t('productLanding.salario.because.answers.title2'),
            answer: i18n.t('productLanding.salario.because.answers.answer2'),
          },
          {
            title: i18n.t('productLanding.salario.because.answers.title3'),
            answer: i18n.t('productLanding.salario.because.answers.answer3'),
          },
          {
            title: i18n.t('productLanding.salario.because.answers.title4'),
            answer: i18n.t('productLanding.salario.because.answers.answer4'),
          },
        ],
        transTextKey: 'productLanding.salario.because.text',
      },
    },
  }
}

export const productLandingSetting = {
  nulinga: {
    views: [
      <MetasNulinga key="metas" />,
      <MercadoPagoWrapper key="homeMP" productName={PRODUCT_MP_NAME_NULINGA}>
        <Home key="home" productName={PRODUCT_CONFIG_NULINGA} />
      </MercadoPagoWrapper>,
      <Because key="because" productName={PRODUCT_CONFIG_NULINGA} accessor="because" />,
      <Description key="description" productName={PRODUCT_CONFIG_NULINGA} />,
      <Data key="data" />,
      <MercadoPagoWrapper key="productMP" productName={PRODUCT_MP_NAME_NULINGA}>
        <Product key="product" productName={PRODUCT_CONFIG_NULINGA} />
      </MercadoPagoWrapper>,
      <Actions key="actions" productName={PRODUCT_MP_NAME_NULINGA} />,
    ],
  },
  miguru: {
    views: [
      <MetasMiguru key="metas" />,
      <CheckStatusWrapper key="homeStatus" action={postAnalysisCvPreview} productName={PRODUCT_MP_NAME_MIGURU}>
        <MercadoPagoWrapper key="homeMP" productName={PRODUCT_MP_NAME_MIGURU}>
          <Home key="home" productName={PRODUCT_CONFIG_MIGURU} />
        </MercadoPagoWrapper>
      </CheckStatusWrapper>,
      <Because key="because" productName={PRODUCT_CONFIG_MIGURU} />,
      <CheckStatusWrapper key="highlightStatus" action={postAnalysisCvPreview} productName={PRODUCT_MP_NAME_MIGURU}>
        <Highlight key="highlight" productName={PRODUCT_CONFIG_MIGURU} />
      </CheckStatusWrapper>,
      <Description key="description" productName={PRODUCT_CONFIG_MIGURU} />,
      <CheckStatusWrapper key="dataStatus" action={postAnalysisCvPreview} productName={PRODUCT_MP_NAME_MIGURU}>
        <Because key="data" productName={PRODUCT_CONFIG_MIGURU} accessor="data" />
      </CheckStatusWrapper>,
      <MercadoPagoWrapper key="productMP" productName={PRODUCT_MP_NAME_MIGURU}>
        <Product key="product" productName={PRODUCT_CONFIG_MIGURU} />
      </MercadoPagoWrapper>,
      <Actions key="actions" productName={PRODUCT_MP_NAME_MIGURU} />,
    ],
  },
  doctorcv: {
    views: [
      <MetasDoctorcv key="metas" />,
      <MercadoPagoWrapper key="homeMP" productName={PRODUCT_MP_NAME_DOCTORCV}>
        <Home key="home" productName={PRODUCT_CONFIG_DOCTORCV} />
      </MercadoPagoWrapper>,
      <Because key="because" productName={PRODUCT_CONFIG_DOCTORCV} />,
      <Description key="description" productName={PRODUCT_CONFIG_DOCTORCV} />,
      <Because key="data" productName={PRODUCT_CONFIG_DOCTORCV} accessor="data" />,
      <MercadoPagoWrapper key="productMP" productName={PRODUCT_MP_NAME_DOCTORCV}>
        <Product key="product" productName={PRODUCT_CONFIG_DOCTORCV} />
      </MercadoPagoWrapper>,
      <Actions key="actions" productName={PRODUCT_MP_NAME_DOCTORCV} />,
    ],
  },
  pda: {
    views: [
      <MetasPda key="metas" />,
      <MercadoPagoWrapper key="homeMP" productName={PRODUCT_INFO_NAME_PDA}>
        <Home key="home" productName={PRODUCT_CONFIG_PDA} />
      </MercadoPagoWrapper>,
      <Because key="because" productName={PRODUCT_CONFIG_PDA} />,
      <Description key="description" productName={PRODUCT_CONFIG_PDA} />,
      <Because key="data" productName={PRODUCT_CONFIG_PDA} accessor="data" />,
      <MercadoPagoWrapper key="productMP" productName={PRODUCT_INFO_NAME_PDA}>
        <Product key="product" productName={PRODUCT_CONFIG_PDA} />
      </MercadoPagoWrapper>,
      <Actions key="actions" productName={PRODUCT_INFO_NAME_PDA} />,
    ],
  },
  nulingaCourse: {
    views: [
      <Home
        key="home"
        productName={PRODUCT_CONFIG_NULINGA_COURSE}
        mpOptions={{
          primaryAction: () =>
            window.open(
              'https://nulinga.notion.site/Bumeran-Argentina-Nulinga-Mejor-tu-nivel-de-idiomas-100-online-bbb947f7e078436b90c76ddcf3a60b21',
              '_self'
            ),
          primaryLoading: false,
          secondaryAction: () => {},
        }}
      />,
      <Benefits key="benefits" productName={PRODUCT_CONFIG_NULINGA_COURSE} />,
      <Because key="because" productName={PRODUCT_CONFIG_NULINGA_COURSE} />,
      <Because key="data" productName={PRODUCT_CONFIG_NULINGA_COURSE} accessor="data" />,
      <Time key="time" />,
      <Share key="share" />,
      <Description key="description" productName={PRODUCT_CONFIG_NULINGA_COURSE} />,
      /*  <Product
        key="product"
        productName={PRODUCT_CONFIG_NULINGA_COURSE}
        mpOptions={{
          primaryAction: () =>
            window.open(
              'https://nulinga.notion.site/Bumeran-Argentina-Nulinga-Mejor-tu-nivel-de-idiomas-100-online-bbb947f7e078436b90c76ddcf3a60b21',
              '_self'
            ),
          primaryLoading: false,
        }}
      />, */
      <Because key="contact" productName={PRODUCT_CONFIG_NULINGA_COURSE} accessor="contact" />,
    ],
  },
  salario: {
    views: [
      <MetasSalario key="metas" />,
      <Home key="home" productName={PRODUCT_CONFIG_SALARIO} />,
      <DescriptionCustom key="description" productName={PRODUCT_CONFIG_SALARIO} />,
      <iframe width="100%" height="625" src="https://www.youtube.com/embed/4Jl9uxRaz50" title="Bumeran - Diferencias entre sueldo bruto y sueldo neto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
      <Because key="data" productName={PRODUCT_CONFIG_SALARIO} />,
    ],
  },
}
