import { put, call, takeLatest, take } from 'redux-saga/effects'
import service from '../../services/confirm-reference'
import {
  ConfirmReference,
  ConfirmReferenceError,
  ConfirmReferenceSuccess,
  ConfirmReferenceType,
  CONFIRM_REFERENCE,
  CONFIRM_REFERENCE_ERROR,
  CONFIRM_REFERENCE_SUCCESS,
  UpdateReferenceStatusError,
  UpdateReferenceStatusSuccess,
  UPDATE_REFERENCE_STATUS,
  UPDATE_REFERENCE_STATUS_ERROR,
  UPDATE_REFERENCE_STATUS_SUCCESS,
} from '../types/confirm-reference'

function* getConfirmReference({ payload }: ConfirmReference) {
  try {
    const result: ConfirmReferenceType = yield call(service.getReferenceToConfirm, payload)
    yield put<ConfirmReferenceSuccess>({ type: CONFIRM_REFERENCE_SUCCESS, payload: result })
  } catch {
    yield put<ConfirmReferenceError>({ type: CONFIRM_REFERENCE_ERROR, payload: null })
  }
}

function* updateConfirmReference({ payload }) {
  try {
    yield call(service.updateReferenceStatus, payload)
    yield put<ConfirmReference>({ type: CONFIRM_REFERENCE, payload })
    const action = yield take([CONFIRM_REFERENCE_SUCCESS, CONFIRM_REFERENCE_ERROR])
    if (action.type === CONFIRM_REFERENCE_SUCCESS) {
      yield put<UpdateReferenceStatusSuccess>({ type: UPDATE_REFERENCE_STATUS_SUCCESS, payload: null })
    } else {
      yield put<UpdateReferenceStatusError>({ type: UPDATE_REFERENCE_STATUS_ERROR, payload: null })
    }
  } catch {
    yield put<UpdateReferenceStatusError>({ type: UPDATE_REFERENCE_STATUS_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* confirmReference() {
  yield takeLatest<ConfirmReference>(CONFIRM_REFERENCE, getConfirmReference)
  yield takeLatest<ConfirmReference>(UPDATE_REFERENCE_STATUS, updateConfirmReference)
}
