import { StatusType, status } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */
export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'

export const SIGN_IN_GOOGLE = 'SIGN_IN_GOOGLE'
export const SIGN_IN_GOOGLE_SUCCESS = 'SIGN_IN_GOOGLE_SUCCESS'
export const SIGN_IN_GOOGLE_ERROR = 'SIGN_IN_GOOGLE_ERROR'
export const SIGN_IN_GOOGLE_RESET = 'SIGN_IN_GOOGLE_RESET'

export const SIGN_IN_LINKEDIN = 'SIGN_IN_LINKEDIN'
export const SIGN_IN_LINKEDIN_SUCCESS = 'SIGN_IN_LINKEDIN_SUCCESS'
export const SIGN_IN_LINKEDIN_ERROR = 'SIGN_IN_LINKEDIN_ERROR'
export const SIGN_IN_LINKEDIN_RESET = 'SIGN_IN_LINKEDIN_RESET'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */
export interface SignInRequestDto {
  keepSession: boolean
  password: string
  user: string
}
export interface SignInGoogleRequestDto {
  token: string
}

export interface SignInLinkedinRequestDto {
  code: string
}

export interface SignInLinkedinResponseDto {
  userExist: boolean
  linkedinIdToken: string | null
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface SignInState {
  data: {} | null
  accountExist: boolean | null
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
  token: string | null
  type: 'google' | 'linkedin' | null
  googleStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
  signInLinkedin: {
    status: StatusType
  }
}

export const initialState: SignInState = {
  data: {} || null,
  accountExist: null,
  token: null,
  type: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
  googleStates: {
    loading: false,
    success: false,
    error: false,
  },
  signInLinkedin: {
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface SignIn {
  type: typeof SIGN_IN
  payload: SignInRequestDto
}

export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS
  payload: null
}

export interface SignInError {
  type: typeof SIGN_IN_ERROR
  payload: boolean
}

export interface SignInGoogle {
  type: typeof SIGN_IN_GOOGLE
  payload: SignInGoogleRequestDto
}

export interface SignInGoogleSuccess {
  type: typeof SIGN_IN_GOOGLE_SUCCESS
  payload: null
}

export interface SignInGoogleError {
  type: typeof SIGN_IN_GOOGLE_ERROR
  payload: null
}

export interface SignInGoogleReset {
  type: typeof SIGN_IN_GOOGLE_RESET
  payload: null
}

export interface SignInLinkedin {
  type: typeof SIGN_IN_LINKEDIN
  payload: SignInLinkedinRequestDto
}

export interface SignInLinkedinSuccess {
  type: typeof SIGN_IN_LINKEDIN_SUCCESS
  payload: SignInLinkedinResponseDto
}

export interface SignInLinkedinError {
  type: typeof SIGN_IN_LINKEDIN_ERROR
  payload: null
}

export interface SignInLinkedinReset {
  type: typeof SIGN_IN_LINKEDIN_RESET
  payload: null
}

export type SignInActions =
  | SignIn
  | SignInSuccess
  | SignInError
  | SignInGoogle
  | SignInGoogleSuccess
  | SignInGoogleError
  | SignInGoogleReset
  | SignInLinkedin
  | SignInLinkedinSuccess
  | SignInLinkedinError
  | SignInLinkedinReset
