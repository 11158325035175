import { GetArchivoCertificadoAdjunto } from '../commons'

export const GET_CERTIFICADO_ADJUNTO = 'GET_CERTIFICADO_ADJUNTO'
export const GET_CERTIFICADO_ADJUNTO_RESET = 'GET_CERTIFICADO_ADJUNTO_RESET'
export const GET_CERTIFICADO_ADJUNTO_SUCCESS = 'GET_CERTIFICADO_ADJUNTO_SUCCESS'
export const GET_CERTIFICADO_ADJUNTO_ERROR = 'GET_CERTIFICADO_ADJUNTO_ERROR'

// Get Certificado Adjunto
export interface GetCertificadoAdjunto {
  type: typeof GET_CERTIFICADO_ADJUNTO
  payload: GetArchivoCertificadoAdjunto
}
export interface GetCertificadoAdjuntoReset {
  type: typeof GET_CERTIFICADO_ADJUNTO_RESET
  payload: null
}
export interface GetCertificadoAdjuntoSuccess {
  type: typeof GET_CERTIFICADO_ADJUNTO_SUCCESS
  payload: string | null
}
export interface GetCertificadoAdjuntoError {
  type: typeof GET_CERTIFICADO_ADJUNTO_ERROR
  payload: null
}
