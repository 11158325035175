import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import _ from 'lodash'
import OptionLabels from '../utils'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { InputField, SelectField, RecentSearchesOption } from './mixins'

interface KeywordSelectProps {
  showOptions?: boolean
  handlerOnChange: Function
  handlerOnFocus: Function
  handlerOnBlur: Function
  handlerOnEnter?: Function
  options?: {}[]
  optionsOnTop?: boolean
  keyword: { keyword?: string; label: string; value: string } | undefined
  setKeyword: (param: { keyword?: string; label: string; value: string } | undefined) => void
  placeholder?: string
  handleMetric?: (searchTerm, locationTerm, recentSearch) => void
  disabled?: boolean
}

const createOptions = recentSearchList => {
  if (!_.isEmpty(recentSearchList)) {
    return recentSearchList?.map(f => {
      let valueConjunto = ''
      Object.keys(f).forEach(k => {
        if (f[k].value) {
          valueConjunto += `${f[k].value.toLowerCase().replace('-', ' ')} `
        }
      })

      return {
        ...f,
        value: valueConjunto,
      }
    })
  }
}

const handleOnKeyDown = (event, handlerOnEnter) => {
  if (event.key === 'Enter') {
    event.preventDefault()

    if (handlerOnEnter) {
      handlerOnEnter()
    }
  }
}

const IS_HOME = window.location.pathname === '/'

const Component = ({
  handlerOnFocus,
  handlerOnBlur,
  handlerOnChange,
  handlerOnEnter,
  options = [],
  showOptions = false,
  optionsOnTop = false,
  keyword = undefined,
  setKeyword,
  placeholder,
  handleMetric,
  disabled = false,
}: KeywordSelectProps) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [lastsSearchs, setLastsSearchs] = useState({})
  const [isOpened, setIsOpened] = useState(false)

  const selectHandleChange = filedValue => {
    if (filedValue?.value && filedValue?.keyword) {
      const newValue = { value: filedValue.value, label: filedValue.keyword, keyword: filedValue.keyword }
      setKeyword(newValue)
      handlerOnChange(newValue)
    } else {
      clearInput()
    }
  }

  const clearInput = () => {
    setKeyword(undefined)
    handlerOnChange('')
  }

  const inputHandleChange = event => {
    const newValue = { value: event.target.value, label: event.target.value, keyword: event.target.value }
    setKeyword(newValue)
    handlerOnChange(newValue)
  }

  const handleChange = search => {
    showOptions ? selectHandleChange(search) : inputHandleChange(search)
  }

  const handleAction = action => {
    return action.action !== 'input-blur' && action.action !== 'menu-close'
  }

  const handleInputChange = (str, action) => {
    if (handleAction(action)) {
      const newValue = { value: str, keyword: str }
      selectHandleChange(newValue)
    }
  }

  useEffect(() => {
    setLastsSearchs(options)
  }, [options])

  return showOptions ? (
    <SelectField
      small
      inputValue={keyword?.value}
      onFocus={handlerOnFocus}
      onBlur={handlerOnBlur}
      onClick={() => setIsOpened(!isOpened)}
      isClearable
      onChange={event => {
        handleChange(event)
      }}
      onInputChange={(str, action) => {
        handleInputChange(str, action)
      }}
      onKeyDown={e => handleOnKeyDown(e, handlerOnEnter)}
      options={createOptions(lastsSearchs)}
      value={keyword}
      placeholder={placeholder || 'Puesto, empresa o palabra clave'}
      name="busqueda"
      id="busqueda"
      className={`basic-single ${isOpened ? 'select-opened' : ''}`}
      classNamePrefix="select"
      components={{ DropdownIndicator: () => null }}
      formatCreateLabel={value => value}
      formatOptionLabel={(option, input) =>
        input.context !== 'value' ? (
          <RecentSearchesOption onClick={() => handleMetric && handleMetric(option?.busqueda, option?.provincia, true)}>
            <OptionLabels option={option} />
          </RecentSearchesOption>
        ) : (
          keyword?.label
        )
      }
      createOptionPosition="first"
      noOptionsMessage={() => 'Sin busquedas recientes'}
      optionsOnTop={optionsOnTop}
      isDisabled={disabled}
    />
  ) : (
    <>
      <InputField
        small
        autoComplete="off"
        onFocus={handlerOnFocus}
        onBlur={handlerOnBlur}
        onClick={() => setIsOpened(!isOpened)}
        onChange={handleChange}
        value={keyword?.label || ''}
        placeholder={placeholder || 'Puesto, empresa o palabra clave'}
        name="busqueda"
        id="busqueda"
      />
      {keyword?.label && (
        <div onClick={clearInput}>
          <Icon name="icon-light-close" size="24" color={colors.grey48} />
        </div>
      )}
    </>
  )
}

export default Component
