import { TagManager } from '@navent-jobs/config'

export const DataLayersProductBanner = {
  click: (from, product) => {
    let category
    if (from.includes('/empleos/')) {
      category = 'Aviso'
    } else if (from.includes('/postulantes/postulaciones/')) {
      category = 'Mis Postulaciones'
    } else {
      category = 'Listado'
    }
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: category,
      eventAction: 'Banners promocionales',
      eventLabel: `Click banner-${product}`,
    })
  },
}
