import React from 'react'
import { connect } from 'react-redux'
import { CustomImage } from './mixins'
import EmptyBanner from '../../../../../assets/images/career-site/empty-banner.svg'

const Header = ({ careerSite }) => {
  if (!careerSite?.header?.visible) {
    return null
  }

  return (
    <CustomImage
      empty={!careerSite.header.siteCoverImage}
      src={careerSite.header.siteCoverImage ? careerSite.header.siteCoverImage : EmptyBanner}
    />
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
})

export default connect(states)(Header)
