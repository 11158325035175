export default {
  snackbarNotificationError: 'Hubo un error, volvé a intentarlo.',
  common: {
    phoneErrorText: 'Ingresá un teléfono válido.',
    emailInvalid: 'Ingresá un email válido.',
    linkInvalid: 'Ingresá una url válida.',
    autocomplete: 'Ingresá al menos un caracter',
    fechaNacError: 'Completá tu fecha de nacimiento',
    generoError: 'Seleccioná tu género',
    paisError: 'Seleccioná el país en el que vivís',
    provinciaError: 'Seleccioná la provincia en la que vivís',
    localidadError: 'Seleccioná la localidad en la que vivís',
    direccionError: 'Escribí tu dirección',
  },
  datosContacto: {
    form: {
      emailPlaceholder: 'Ingresá tu email',
      paisPlaceholder: 'Elegí una opción',
      emailInvalid: 'Ingresá un email válido.',
      provinciaTitle: 'Provincia',
      provinciaPlaceholder: 'Elegí una opción',
      localidadTitle: 'Localidad',
      localidadPlaceholder: 'Elegí una opción',
      direccionPlaceholder: 'Ingresá tu dirección',
      phoneErrorText: 'Ingresá un teléfono válido.',
    },
  },
  conocimientosYHabilidades: {
    emptyStateDescription: 'Agregá tus conocimientos y habilidades',
    deleteTitle: '¿Querés eliminar tu conocimiento o habilidad?',
    form: {
      remainingText: 'Tenés {{restantes}} conocimientos y habilidades para agregar',
      subtitle: 'Escribí una palabra simple o compuesta. Ej: Microsoft power point',
      conocimientoPlaceholder: 'Ingresá al menos 2 caracteres',
    },
  },
  completitudCv: {
    titleCvComplete: '¡Tu {{cv}} está completo!',
    titleCvIncomplete: 'Mejorá tu {{cv}} agregando:',
  },
  idiomas: {
    emptyState: 'Agregá tu primer idioma',
    deleteIdioma: '¿Querés eliminar tu idioma?',
    form: {
      genericPlaceholder: 'Elegí una opción',
    },
  },
  educacion: {
    addItemReference: 'Sumar referencia académica',
    deleteEstudio: '¿Querés eliminar tu estudio?',
    deleteReferenciaEstudio: '¿Querés eliminar tu referencia académica? ',
    deleteCertificadoEstudio: '¿Querés eliminar tu certificado?',
    emptyStateDescription: 'Agregá tu primer estudio',
    form: {
      carreraTituloPlaceholder: 'Ingresá tu título o carrera',
      genericPlaceholder: 'Elegí una opción',
      institucionPlaceholder: 'Ingresá la institución',
    },
    referencia: {
      nombrePlaceholder: 'Ingresá el nombre',
      apellidoPlaceholder: 'Ingresá el apellido',
      emailPlaceholder: 'Ingresá el email',
      relacionPlaceholder: 'Elegí una opción',
    },
    certificado: {
      certificadoCargado: 'Certificado cargado',
      emptyState: {
        description: 'Solo podés elegir una opción',
        linkOption: 'Ingresá un link',
        fileOption: 'Subí un archivo adjunto',
      },
      form: {
        label: 'Certificado',
        placeholder: 'Pegá el link al certificado',
      },
    },
  },
  datosPersonales: {
    form: {
      genericPlaceholder: 'Elegí una opción',
      nombrePlaceholder: 'Ingresá tu nombre',
      apellidoPlaceholder: 'Ingresá tu apellido',
      estadoCivilPlaceholder: 'Estado civil',
      tipoLicenciaTitle: 'Seleccioná el tipo de licencia',
    },

    avatar: {
      confirmModalTitle: '¿Querés eliminar tu foto?',
    },
  },
  experienciaLaboral: {
    emptyStateDescription: 'Agregá tu primera experiencia laboral',
    deleteExperiencia: '¿Querés eliminar tu experiencia laboral?',
    deleteReferenciaExperiencia: '¿Querés eliminar tu referencia laboral?',
    alert: {
      modalTitle: 'Tenés {{ number }} experiencias actuales',
    },
    form: {
      ingresaElNombre: 'Ingresá el nombre',
      ingresaElApellido: 'Ingresá el apellido',
      eligeUnaOpcion: 'Elegí una opción',
      ingresaElMail: 'Ingresá el email',
      comboPlaceholder: 'Elegí una opción',
      empresaPlaceholder: 'Ingresá el nombre',
      puestoPlaceholder: 'Ingresá el nombre',
      personasACargoPlaceholder: 'Ingresá un número',
      detallePlaceholder: 'Escribí cuáles son tus tareas',
    },
    formLocation: {
      title: 'Confirmá tu dirección',
    },
  },
  discapacidad: {
    helperText:
      'Si tenés algún tipo de discapacidad, no es obligatorio que la declares. Pero si lo hacés, te ayudará a encontrar puestos que mejor se adapten a tus necesidades.',
    borrarDiscapacidad: '¿Querés borrar el archivo?',
    form: {
      archivosPermitidos: 'Podés subir un archivo de hasta 3.5M en formato .doc, .docx, .pdf o .jpg.',
      discapacidadDescription: 'Describí qué tipo de discapacidad tenés',
      archivoInvalido:
        'No pudimos cargar tu archivo. Recordá que solo puede tener formato .doc, .docx, .pdf o .jpg y que no debe superar los 3.5MB.',
    },
  },

  cvAdjunto: {
    confirmDelete: '¿Querés eliminar tu {{cv}} adjunto?',
    puedeSubirAdjunto: {
      helperText: 'Cargá tu {{cv}} de hasta 3.5Mb en formato .doc, .docx o .pdf.',
    },
    noPuedeSubirAdjunto: {
      helperText: 'Si querés subir un archivo, escribí al menos una experiencia laboral o un estudio.',
    },
    errorForm: {
      archivoInvalido:
        'Recordá que solo se puede subir un archivo en formato .doc, .docx, .pdf o .jpg y que no debe superar los 3.5MB.',
    },
  },

  preferenciaSalarial: {
    sectionTitle: 'Preferencia salarial',
    salarioDescription: 'Sueldo bruto pretendido: ',
    emptyDescription: 'Contanos cuánto querés ganar',
    confirmDelete: '¿Querés eliminar tu preferencia salarial?',
    form: {
      label: '¿Cuánto querés ganar?',
      salarioPlaceholder: 'Sueldo bruto pretendido',
    },
    salaryLabelInfo: 'Recordá que el sueldo bruto es el salario total sin las retenciones e impuestos.',
    knowMore: 'Conocé más.',
  },
  objetivoLaboral: {
    emptyTitle: 'Contanos cuáles son tus expectativas profesionales',
    confirmDelete: '¿Querés eliminar tu objetivo laboral?',
    form: {
      objetivoPlaceholder: 'Contanos en pocas palabras',
    },
  },
  testPersonalidad: {
    emptyDescription:
      'Conocé más de tu perfil y permitile a las empresas que descubran los puntos fuertes de tu personalidad.',
  },
  videoPresentacion: {
    addItem: 'Grabar video',
    emptyDescriptionBold: 'Grabá un video',
    confirmDelete: '¿Querés eliminar tu video presentación?',
    emptyDescription:
      'de 20 segundos contando un poco sobre vos. Resaltá tus fortalezas y menciona todo lo que no aparece en tu {{cv}}.',
    info: 'Podés volver a grabarlo las veces que quieras.',
  },
  onboarding: {
    steps: {
      bienvenida: {
        titleLogin: '¿Nos contás un poco más sobre vos?',
        titleQrPart2: 'Ahora, ¿nos contás un poco más sobre vos?',
      },
      finalizar: {
        title: '¡Creaste tu {{cv}} con éxito!',
        description: 'Si querés modificar tus datos o agregar nuevos, hacelo desde tu {{cv}}.',
      },
      trabajo: {
        subtitle: '¡Escribí el puesto que te interese!',
        inputDescription: 'Escribí el puesto que te interese',
        selectDescription: 'Elegí una opción',
        helperText: 'Ingresá al menos 2 caracteres.',
      },
      modalidadCargaDeDatos: {
        titleQr: '¡Ya enviamos tus datos a la empresa! Ahora, terminá de completar tu perfil',
        subtitle: '¿Querés cargar tu {{cv}} a partir de un archivo o de forma manual?',
        linkedinRegistro: 'Podés descargar tu {{cv}} de Linkedin y subirlo en el próximo paso',
      },
      cvParcer: {
        uploadFile: {
          title: 'Seleccioná el archivo de tu {{cv}}',
        },
        uploadError: {
          info: 'Recordá que solo se puede subir un archivo en formato .pdf, y no debe superar los 2 MB.',
        },
        uploadSuccess: {
          check: 'Quiero usar este archivo como mi {{cv}} adjunto',
        },
        dataExtractionSuccess: {
          subTitle: 'Te pedimos que lo revises y, si encontrás algún error, podés corregirlo.',
          info: 'En cualquier momento podés revisar y editar tu {{cv}} desde Mi perfil.',
        },
      },
      experiencia: {
        sinExperienciaDesc:
          'Si estás buscando tu primer trabajo, te invitamos a pasar a la siguiente pantalla para seguir completando tu {{cv}}. ¡Mucha suerte!',
        genericTitle: '¿Tenés experiencia laboral?',
        manualTitle: '¿Tenés experiencia laboral?',

        form: {
          empresaEmpty: 'Ingresá el nombre de la empresa',
          actividadEmpresaEmpty: 'Seleccioná la actividad de la empresa',
          puestoEmpty: 'Ingresá tu puesto en la empresa',
          nivelEmpty: 'Seleccioná tu nivel de experiencia',
          areaEmpty: 'Seleccioná el área del puesto',
          subAreaEmpty: 'Seleccioná la subárea del puesto',
          paisEmpty: 'Seleccioná el país',
        },

        parser: {
          experienciaListTitle: 'Revisá tus experiencias laborales',
          addTitle: 'Agregar experiencia',
          pluralGenericError: 'Tenés {{emptyFieldsNumber}} experiencias incompletas.',
          singularGenericError: 'Tenés 1 experiencia incompleta.',
          pluralErrorDescription: 'Completalas o eliminalas para continuar.',
          singularErrorDescription: 'Completala o eliminala para continuar.',
          experienciaReemplazar:
            'Podés optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      idioma: {
        title: 'Revisá tus idiomas',
        emptyState: 'Agregá tu primer idioma',
        idiomasReemplazar:
          'Podés optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
      },
      educacion: {
        genericTitle: 'Contanos qué estudios tenés',
        manualTitle: '¿Tenés estudios?',
        tieneEducacionTitle: 'Ingresá tu estudio más reciente',
        educacionDescripcion: 'Podés sumar diferentes tipos: secundario, cursos, etc.',
        form: {
          tituloEmpty: 'Ingresá un título / carrera',
          paisEmpty: 'Seleccioná el país',
          tipoEstudioEmpty: 'Seleccioná el tipo de estudio',
          areaEstudioEmpty: 'Ingresá el área de estudio',
          institucionEmpty: 'Seleccioná una institución',
          estadoEmpty: 'Seleccioná el estado',
        },
        parser: {
          educacionListTitle: 'Revisá tus estudios',
          pluralGenericError: 'Tenés {{emptyFieldsNumber}} estudios incompletos.',
          singularGenericError: 'Tenés 1 estudio incompleto.',
          pluralErrorDescription: 'Completalos o eliminalos para continuar.',
          singularErrorDescription: 'Completalo o eliminalo para continuar.',
          estudiosReemplazar:
            'Podés optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      conocimientos: {
        titleParse: 'Revisá tus conocimientos y habilidades',
        titleParseEmpty: 'Completá tus conocimientos y habilidades',
        emptyParse: 'No encontramos conocimientos y habilidades, pero podés cargarlos manualmente',
        conocimientosReemplazar:
          'Podés optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
      },
      datosPersonales: {
        parser: {
          title: 'Revisá tus datos personales',
          datospersonalesReemplazar:
            'Podés optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      objetivoLaboral: {
        title: 'Revisá tu objetivo laboral',
        subtitle: 'Generamos este objetivo laboral a partir del contenido de tu {{cv}}, pero podés editarlo.',
        actualizaObLaboral: 'Generamos este objetivo laboral a partir del contenido de tu CV, pero podés editarlo.',
      },
      skipModal: {
        title: '¿Querés omitir este paso?',
        subTitle: 'Si lo omitís, no se guardarán los datos que cargaste.',
      },
    },
  },
  descargaCv: {
    modalSidebar: {
      title: 'Descargá tu {{cv}}',
      subtitle: 'Seleccioná el diseño que te guste para descargar tu {{cv}}.',
    },
  },
  marketplace: {
    products: {
      nulinga: {
        course: {
          claim: {
            default: 'Curso de inglés con todas las comodidades que necesitás',
          },
        },
        claim: {
          default: 'Certificá tu nivel de inglés con un rápido cuestionario',
        },
      },
      pda: {
        claim: {
          completed: 'Descargá el resultado de tu test de personalidad completo',
        },
      },
      coderhouse: {
        claim: {
          default: 'Aprovechá un 20% de descuento en cualquier curso o carrera',
        },
      },
    },
    menu: 'Potenciá tu perfil',
    claim: {
      text: 'Si querés conocer más productos y beneficios para potenciar tu perfil,',
      link: 'hacé click aquí',
    },
    modalProductInfo: {
      nulinga: {
        title: 'Potenciá tu CV con Nulinga',
        paragraph1a:
          'Certificá tu nivel de inglés con un rápido cuestionario de selección múltiple. El resultado será analizado por los profesores de',
      },
    },
    accessMarketplace: {
      claim: '¡Potenciá tu perfil!',
    },
  },
  bannerOnboardingAccess: {
    modal: {
      title: '¿Querés reemplazar la información que tenés en tu {{cv}} por la del nuevo archivo?',
    },
    new: {
      title: 'Completá tu {{cv}} con IA',
      subtitle: '¡Tu {{cv}} está incompleto! Subilo en pdf y nuestra IA lo autocompletará.',
    },
    update: {
      title: 'Actualizá tu {{cv}} con IA',
      subtitle: 'Cargá tu {{cv}} en pdf y nuestra Inteligencia Artificial actualizará la información. ',
      cta: 'Actualizar {{cv}}',
    },
  },
}
