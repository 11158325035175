import React from 'react'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { useTranslation } from 'react-i18next'
import { ComponentContainer, CustomButton, CustomLink } from './mixins'

interface FormButtonsProps {
  isLoading: boolean
  disabled: boolean
}

const Component = ({ isLoading, disabled }: FormButtonsProps) => {
  const { t } = useTranslation()

  return (
    <ComponentContainer>
      <CustomButton
        disabled={disabled}
        type="submit"
        buttonoptions={{
          size: 'medium',
          variant: 'primary',
          type: 'fill',
          native: false,
        }}
        isLoading={isLoading}
      >
        {t('Enviar')}
      </CustomButton>
      <CustomLink
        href="/login"
        linkoptions={{
          native: false,
          size: 'medium',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('Volver')}
      </CustomLink>
    </ComponentContainer>
  )
}

export default Component
