import React from 'react'
import { Container, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Title, ImgLogo, Img, Subtitle, Description, Page, ColContainer } from './mixins'
import { productLandingTranslations } from '../../constants'

interface DescriptionInterface {
  logoImage: React.Component
  titleFirstPart?: string
  titleSecondPart?: string
  backgroundColor?: string
  rows: {
    firstColumn: {
      image: React.Component
      title: string
      description: string
    }
    secondColumn: {
      image: React.Component
      title: string
      description: string
    }
    thirdColumn: {
      image: React.Component
      title: string
      description: string
    }
  }[]
  scrollRef?: string
  logoStyles?: any
}

const Component = ({ productName, accessor = 'description' }) => {
  const {
    logoImage,
    titleFirstPart,
    titleSecondPart,
    rows,
    scrollRef,
    logoStyles,
    backgroundColor,
  }: DescriptionInterface = productLandingTranslations()[productName][accessor]

  return (
    <Page id={scrollRef || 'DESCRIPTION'} backgroundColor={backgroundColor}>
      <Container noGutter>
        <Row>
          <Col>
            <Title>
              {titleFirstPart}
              <ImgLogo src={logoImage} logoStyles={logoStyles} />
              {titleSecondPart}
            </Title>
          </Col>
        </Row>
        {rows &&
          rows.map(row => {
            return (
              <Row key="row">
                <Col lg={3}>
                  <ColContainer>
                    <Img src={row.firstColumn.image} />
                    <Subtitle>{row.firstColumn.title}</Subtitle>
                    <Description>{row.firstColumn.description}</Description>
                  </ColContainer>
                </Col>
                <Col lg={4} offset={{ lg: 1 }}>
                  <ColContainer>
                    <Img src={row.secondColumn.image} />
                    <Subtitle>{row.secondColumn.title}</Subtitle>
                    <Description>{row.secondColumn.description}</Description>
                  </ColContainer>
                </Col>
                <Col lg={3} offset={{ lg: 1 }}>
                  <ColContainer>
                    <Img src={row.thirdColumn.image} />
                    <Subtitle>{row.thirdColumn.title}</Subtitle>
                    <Description>{row.thirdColumn.description}</Description>
                  </ColContainer>
                </Col>
              </Row>
            )
          })}
      </Container>
    </Page>
  )
}

export default Component
