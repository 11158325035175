import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  clickCard: prouct =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Marketplace postulantes',
      eventAction: 'Clic a cards productos',
      eventLabel: `Card-${prouct}`,
    }),

  clickFormulario: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Marketplace postulantes',
      eventAction: 'Formulario-Suma tu producto',
      eventLabel: `CTA-Suma tu producto`,
    }),

  envioFormulario: state =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Marketplace postulantes',
      eventAction: 'Formulario-Suma tu producto',
      eventLabel: `Envio de formulario-${state}`,
    }),
}
