import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { GraphTooltip, GraphTooltipText, TooltipTextRow, Color, Level, Percentage } from './mixins'
import { keysEducation, levels } from '../../../constants'
import useGraphColors from '../../../hooks/useGraphColors'

export const TooltipAge = item => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  return (
    <GraphTooltip>
      <GraphTooltipText key={item?.data?.index}>
        <TooltipTextRow>
          <Color color={colors.tertiary.normal} />
          <Level>
            Total - {item?.indexValue}: <strong>{item?.value}</strong>
          </Level>
        </TooltipTextRow>
      </GraphTooltipText>
    </GraphTooltip>
  )
}

export const TooltipEducation = item => {
  const { colorEducation } = useGraphColors()

  return (
    <GraphTooltip>
      {levels.map((level, index) => {
        const hasLevelNumber = item.data[keysEducation[index]] || '0'
        return (
          <GraphTooltipText key={item.data + level + String(index)}>
            <TooltipTextRow>
              <Color color={colorEducation[index]} />
              <Level color={colorEducation[index]} fontSize="13" fontWeight="600">
                {level}
              </Level>
            </TooltipTextRow>
            <Percentage color={colorEducation[index]}>{hasLevelNumber}</Percentage>
          </GraphTooltipText>
        )
      })}
    </GraphTooltip>
  )
}
