import React from 'react'
import { numberTropicalizer } from '@navent-jobs/config'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// mixins
import { Info, InfoText, InfoValue, Text } from './mixins'

const DetailPromedioSalarial = ({ promedio, cantidad, bigger = false }) => {
  const { t } = useTranslation()
  const { isDesktop, isTabletLandscape } = useWindowSize()

  if (!promedio || !cantidad) return null
  return (
    <Info>
      <InfoText>Media salarial</InfoText>
      <InfoValue bigger={bigger}>
        {t(`currencyMask.symbol`)}
        {numberTropicalizer(+promedio.toFixed(0))}
      </InfoValue>
      <Text>
        Basado en {cantidad} salarios {(isDesktop || isTabletLandscape) && 'pretendidos'}
      </Text>
    </Info>
  )
}

export default DetailPromedioSalarial
