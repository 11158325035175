import {
  SIGN_IN,
  SignIn,
  SignInRequestDto,
  SignInGoogleRequestDto,
  SIGN_IN_GOOGLE,
  SignInGoogle,
  SIGN_IN_GOOGLE_RESET,
  SignInGoogleReset,
  SignInLinkedin,
  SIGN_IN_LINKEDIN,
  SIGN_IN_LINKEDIN_RESET,
  SignInLinkedinReset,
} from '../types/sign-in'

export const signIn = (payload: SignInRequestDto): SignIn => {
  return {
    type: SIGN_IN,
    payload,
  }
}

export const signInGoogle = (payload: SignInGoogleRequestDto): SignInGoogle => {
  return {
    type: SIGN_IN_GOOGLE,
    payload,
  }
}

export const signInGoogleReset = (): SignInGoogleReset => {
  return {
    type: SIGN_IN_GOOGLE_RESET,
    payload: null,
  }
}

export const signInLinkedinAction = (payload: SignInLinkedin['payload']): SignInLinkedin => {
  return {
    type: SIGN_IN_LINKEDIN,
    payload,
  }
}

export const signInLinkedinReset = (): SignInLinkedinReset => {
  return {
    type: SIGN_IN_LINKEDIN_RESET,
    payload: null,
  }
}
