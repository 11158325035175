import { FirebaseApp, initializeApp } from 'firebase/app'
import { fetchAndActivate, getBoolean, getRemoteConfig } from 'firebase/remote-config'
import { SITE_ID } from '../constants'
import { firebaseConfig, firebaseCustomConfig } from '../constants/firebase'

const customConfig = SITE_ID ? firebaseCustomConfig[SITE_ID] : {}
const config = { ...firebaseConfig, ...customConfig }
export const firebaseApp: FirebaseApp = initializeApp(config)
const remoteConfig = getRemoteConfig(firebaseApp)
remoteConfig.settings.minimumFetchIntervalMillis = 3600000 // 1 hora

export const getRemoteConfigFeature = (key: string): Promise<boolean> => {
  return fetchAndActivate(remoteConfig).then(() => {
    return getBoolean(remoteConfig, `${process.env.NODE_ENV === 'production' ? '' : 'PREPRO_'}${key}`) || false
  })
}
