import { status } from '../../types/status'
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  initialState,
  SignInActions,
  SignInState,
  SIGN_IN_GOOGLE_ERROR,
  SIGN_IN_GOOGLE_SUCCESS,
  SIGN_IN_GOOGLE,
  SIGN_IN_GOOGLE_RESET,
  SIGN_IN_LINKEDIN,
  SIGN_IN_LINKEDIN_SUCCESS,
  SIGN_IN_LINKEDIN_ERROR,
  SIGN_IN_LINKEDIN_RESET,
} from '../../types/sign-in'

const reducers = (state: SignInState = initialState, action: SignInActions): SignInState => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SIGN_IN_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SIGN_IN_GOOGLE:
      return {
        ...state,
        type: 'google',
        token: action.payload.token,
        googleStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case SIGN_IN_GOOGLE_SUCCESS:
      return {
        ...state,
        accountExist: action.payload,
        googleStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SIGN_IN_GOOGLE_ERROR:
      return {
        ...state,
        googleStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SIGN_IN_GOOGLE_RESET:
      return {
        ...state,
        accountExist: null,
        type: null,
        token: null,
        googleStates: {
          loading: false,
          success: false,
          error: false,
        },
      }
    case SIGN_IN_LINKEDIN:
      return {
        ...state,
        type: 'linkedin',
        signInLinkedin: {
          status: status.loading,
        },
      }
    case SIGN_IN_LINKEDIN_SUCCESS:
      return {
        ...state,
        accountExist: action.payload.userExist,
        token: action.payload.linkedinIdToken,
        signInLinkedin: {
          status: status.success,
        },
      }
    case SIGN_IN_LINKEDIN_ERROR:
      return {
        ...state,
        signInLinkedin: {
          status: status.error,
        },
      }
    case SIGN_IN_LINKEDIN_RESET:
      return {
        ...state,
        accountExist: null,
        type: null,
        token: null,
        signInLinkedin: {
          status: status.error,
        },
      }
    default:
      return state
  }
}

export default reducers
