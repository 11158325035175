import styled from 'styled-components'

export const Heading = styled('div')`
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 32px;
  }
`

export const Lead = styled('p')`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-bottom: 32px;
  }
`
