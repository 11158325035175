export const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL'
export const SEND_ACTIVATION_EMAIL_SUCCESS = 'SEND_ACTIVATION_EMAIL_SUCCESS'
export const SEND_ACTIVATION_EMAIL_ERROR = 'SEND_ACTIVATION_EMAIL_ERROR'

export interface SendActivationEmailRequestDto {
  applicantMail: string
  fetchMail: string
}

export interface SendActivationEmail {
  type: typeof SEND_ACTIVATION_EMAIL
  payload: SendActivationEmailRequestDto
}

export interface SendActivationEmailSuccess {
  type: typeof SEND_ACTIVATION_EMAIL_SUCCESS
  payload: null
}

export interface SendActivationEmailError {
  type: typeof SEND_ACTIVATION_EMAIL_ERROR
  payload: boolean
}

export interface ActivationEmailState {
  data: {} | null
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: ActivationEmailState = {
  data: {} || null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type ActivationEmailAction = SendActivationEmail | SendActivationEmailSuccess | SendActivationEmailError
