import {
  GET_BREADCRUMBS,
  GET_BREADCRUMBS_SUCCESS,
  GET_BREADCRUMBS_ERROR,
  BreadcrumbsActions,
  BreadcrumbsState,
  initialState,
} from '../types/breadcrumbs'

const reducers = (state: any = initialState, action: BreadcrumbsActions): BreadcrumbsState => {
  switch (action.type) {
    case GET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: null,
        loading: true,
        error: false,
      }
    case GET_BREADCRUMBS_SUCCESS:
      return {
        ...state,
        breadcrumbs: { ...action.payload },
        loading: false,
        error: false,
      }
    case GET_BREADCRUMBS_ERROR:
      return {
        ...state,
        breadcrumbs: null,
        loading: true,
      }
    default:
      return state
  }
}

export default reducers
