import React, { useState } from 'react'

import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
// style
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Container, Star, CustomDiv } from './mixins'

const StarRating = ({ name, size = '24', value, setValue, onChange }) => {
  const { isMobile } = useScreenSize()
  const [hover, setHover] = useState(0)
  return (
    <Container>
      {[...Array(5)].map((_star, index) => {
        index += 1
        return (
          <Star
            index={index}
            type="button"
            key={index}
            onClick={() => {
              onChange(name)
              setValue(name, index)
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(value)}
          >
            <Icon name="icon-light-star" color="#FAB306" size={isMobile ? '32' : size} />
            {/** HACK: se crea una nueva row porque ios no reconoce el cambio dinamico de nombre del icono */}
            <CustomDiv>
              <Icon
                name="icon-bold-star"
                color={index <= (hover || value) ? '#FAB306' : 'transparent'}
                size={isMobile ? '32' : size}
              />
            </CustomDiv>
          </Star>
        )
      })}
    </Container>
  )
}

export default StarRating
