import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  CONFIRM_CANDIDATE_ACCOUNT,
  CONFIRM_CANDIDATE_ACCOUNT_SUCCESS,
  CONFIRM_CANDIDATE_ACCOUNT_ERROR,
  ConfirmCandidateAccount,
} from '../types/applicant/confirm-candidate'

function* confirmCandidateAccount({ payload }: ConfirmCandidateAccount) {
  try {
    const result = yield call(services.applicant.confirmCandidateAccount, payload)
    yield put({ type: CONFIRM_CANDIDATE_ACCOUNT_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: CONFIRM_CANDIDATE_ACCOUNT_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest(CONFIRM_CANDIDATE_ACCOUNT, confirmCandidateAccount)
}
