const reviews = {
  enabled: true,
  comments: {
    enabled: true,
  },
  banner: {
    enabled: true,
  },
  wantsToWork: {
    enabled: true,
  },
}

export default reviews
