import { AddVideoCVType, GetArchivoVideo } from '../commons'

export const GET_VIDEO_PRESENTACION = 'GET_VIDEO_PRESENTACION'
export const GET_VIDEO_PRESENTACION_RESET = 'GET_VIDEO_PRESENTACION_RESET'
export const GET_VIDEO_PRESENTACION_SUCCESS = 'GET_VIDEO_PRESENTACION_SUCCESS'
export const GET_VIDEO_PRESENTACION_ERROR = 'GET_VIDEO_PRESENTACION_ERROR'
export const GET_VIDEO_PRESENTACION_PREVIEW = 'GET_VIDEO_PRESENTACION_PREVIEW'
export const GET_VIDEO_PRESENTACION_PREVIEW_RESET = 'GET_VIDEO_PRESENTACION_PREVIEW_RESET'
export const GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS = 'GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS'
export const GET_VIDEO_PRESENTACION_PREVIEW_ERROR = 'GET_VIDEO_PRESENTACION_PREVIEW_ERROR'
export const ADD_VIDEO_PRESENTACION = 'ADD_VIDEO_PRESENTACION'
export const ADD_VIDEO_PRESENTACION_RESET = 'ADD_VIDEO_PRESENTACION_RESET'
export const ADD_VIDEO_PRESENTACION_SUCCESS = 'ADD_VIDEO_PRESENTACION_SUCCESS'
export const ADD_VIDEO_PRESENTACION_ERROR = 'ADD_VIDEO_PRESENTACION_ERROR'
export const DELETE_VIDEO_PRESENTACION = 'DELETE_VIDEO_PRESENTACION'
export const DELETE_VIDEO_PRESENTACION_RESET = 'DELETE_VIDEO_PRESENTACION_RESET'
export const DELETE_VIDEO_PRESENTACION_SUCCESS = 'DELETE_VIDEO_PRESENTACION_SUCCESS'
export const DELETE_VIDEO_PRESENTACION_ERROR = 'DELETE_VIDEO_PRESENTACION_ERROR'

// Delete VideoPresentacion
export interface DeleteVideoPresentacion {
  type: typeof DELETE_VIDEO_PRESENTACION
  payload: null
}
export interface DeleteVideoPresentacionReset {
  type: typeof DELETE_VIDEO_PRESENTACION_RESET
  payload: null
}
export interface DeleteVideoPresentacionSuccess {
  type: typeof DELETE_VIDEO_PRESENTACION_SUCCESS
  payload: null
}
export interface DeleteVideoPresentacionError {
  type: typeof DELETE_VIDEO_PRESENTACION_ERROR
  payload: null
}

// Get VideoPresentacion
export interface GetVideoPresentacion {
  type: typeof GET_VIDEO_PRESENTACION
  payload: GetArchivoVideo
}
export interface GetVideoPresentacionReset {
  type: typeof GET_VIDEO_PRESENTACION_RESET
  payload: null
}
export interface GetVideoPresentacionSuccess {
  type: typeof GET_VIDEO_PRESENTACION_SUCCESS
  payload: string | null
}
export interface GetVideoPresentacionError {
  type: typeof GET_VIDEO_PRESENTACION_ERROR
  payload: null
}

// Add VideoPresentacion
export interface AddVideoPresentacion {
  type: typeof ADD_VIDEO_PRESENTACION
  payload: AddVideoCVType
}
export interface AddVideoPresentacionReset {
  type: typeof ADD_VIDEO_PRESENTACION_RESET
  payload: null
}
export interface AddVideoPresentacionSuccess {
  type: typeof ADD_VIDEO_PRESENTACION_SUCCESS
  payload: string | null
}
export interface AddVideoPresentacionError {
  type: typeof ADD_VIDEO_PRESENTACION_ERROR
  payload: null
}

// Get VideoPresentacion Preview
export interface GetVideoPresentacionPreview {
  type: typeof GET_VIDEO_PRESENTACION_PREVIEW
  payload: GetArchivoVideo
}
export interface GetVideoPresentacionPreviewReset {
  type: typeof GET_VIDEO_PRESENTACION_PREVIEW_RESET
  payload: null
}
export interface GetVideoPresentacionPreviewSuccess {
  type: typeof GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS
  payload: string
}
export interface GetVideoPresentacionPreviewError {
  type: typeof GET_VIDEO_PRESENTACION_PREVIEW_ERROR
  payload: null
}
