import { status, StatusType } from './status'

export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'

export const GET_PRODUCT_AVAILABILITY = 'GET_PRODUCT_AVAILABILITY'
export const GET_PRODUCT_AVAILABILITY_SUCCESS = 'GET_PRODUCT_AVAILABILITY_SUCCESS'
export const GET_PRODUCT_AVAILABILITY_ERROR = 'GET_PRODUCT_AVAILABILITY_ERROR'

export const SEND_DATA_FORM = 'SEND_DATA_FORM'
export const SEND_DATA_FORM_SUCCESS = 'SEND_DATA_FORM_SUCCESS'
export const SEND_DATA_FORM_ERROR = 'SEND_DATA_FORM_ERROR'

export const GET_PRODUCTS_STATUS = 'GET_PRODUCTS_STATUS'
export const GET_PRODUCTS_STATUS_SUCCESS = 'GET_PRODUCTS_STATUS_SUCCESS'
export const GET_PRODUCTS_STATUS_ERROR = 'GET_PRODUCTS_STATUS_ERROR'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export interface Product {
  productId: number
  sku: string
  credits: number
  creditsUnits: string
  description: string
  frecuencia: string
  fullDescription: string
  material: string
  name: string
  orden: string
  orgVtas: string
  tipoOperacion: string
  title: string
  allowedDiscount: boolean
  unitPrice: number
}

export interface ProductAvailability {
  sku: string
  available: boolean
  code: string
  detail: string
}

export interface ProductStatus {
  sku: string
  acquired: boolean
}

export interface SendDataFormRequestDto {
  nameSurname: string
  nameCompany: string
  nameSite: string
  email: string
  message: string
}

export interface SendDataForm {
  type: typeof SEND_DATA_FORM
  payload: SendDataFormRequestDto
}

export interface SendDataFormSuccess {
  type: typeof SEND_DATA_FORM_SUCCESS
  payload: boolean
}

export interface SendDataFormError {
  type: typeof SEND_DATA_FORM_ERROR
  payload: boolean
}

// Params
export interface GetProductSuccessParams {
  product: Product
}
export interface GetProductAvailabilitySuccessParams {
  product: ProductAvailability[]
}
export interface GetProductsStatusSuccessParams {
  productsStatus: ProductStatus[]
}
export interface GetProductsSuccessParams {
  products: Product[]
}

// Actions y Reducers Product
export interface GetProduct {
  type: typeof GET_PRODUCT
  payload: string
}

export interface GetProductSuccess {
  type: typeof GET_PRODUCT_SUCCESS
  payload: GetProductSuccessParams
}

export interface GetProductError {
  type: typeof GET_PRODUCT_ERROR
  payload: boolean
}

// Actions y Reducers Product availability
export interface GetProductAvailability {
  type: typeof GET_PRODUCT_AVAILABILITY
  payload: string
}

export interface GetProductAvailabilitySuccess {
  type: typeof GET_PRODUCT_AVAILABILITY_SUCCESS
  payload: ProductAvailability[]
}

export interface GetProductAvailabilityError {
  type: typeof GET_PRODUCT_AVAILABILITY_ERROR
  payload: boolean
}

// Actions y Reducers Products Status
export interface GetProductsStatus {
  type: typeof GET_PRODUCTS_STATUS
}

export interface GetProductsStatusSuccess {
  type: typeof GET_PRODUCTS_STATUS_SUCCESS
  payload: GetProductsStatusSuccessParams
}

export interface GetProductsStatusError {
  type: typeof GET_PRODUCTS_STATUS_ERROR
  payload: boolean
}

// Actions y Reducers Product
export interface GetProducts {
  type: typeof GET_PRODUCTS
  payload: string[]
}

export interface GetProductsSuccess {
  type: typeof GET_PRODUCTS_SUCCESS
  payload: GetProductsSuccessParams
}

export interface GetProductsError {
  type: typeof GET_PRODUCTS_ERROR
  payload: boolean
}

export interface ProductsState {
  SendDataForm: {
    data: {} | null
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
  states: {
    loading: boolean
    error: boolean
    success: boolean
  }
  producto: Product | null
  productos: Product[]
  productsStatus: ProductStatus[]
  productsStatusStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
  productAvailability: {
    availability: ProductAvailability[] | []
    states: {
      loading: boolean
      success: boolean
      error: boolean
    }
  }
}

export const initialState: ProductsState = {
  SendDataForm: {
    data: {} || null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  states: {
    loading: false,
    error: false,
    success: false,
  },
  producto: null,
  productos: [],
  productsStatus: [],
  productsStatusStates: {
    loading: false,
    error: false,
    success: false,
  },
  productAvailability: {
    availability: [],
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
}

export type ProductsActions =
  | GetProduct
  | GetProductSuccess
  | GetProductError
  | GetProductAvailability
  | GetProductAvailabilitySuccess
  | GetProductAvailabilityError
  | SendDataForm
  | SendDataFormSuccess
  | SendDataFormError
  | GetProductsStatus
  | GetProductsStatusSuccess
  | GetProductsStatusError
  | GetProducts
  | GetProductsSuccess
  | GetProductsError
