import styled from 'styled-components'

export const Spacer = styled.div`
  padding: 80px 0 55px;
  background-color: ${({ theme }) => theme.colors.grey0};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 120px 0;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  margin-bottom: 35px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    text-align: left;
    margin-bottom: 0;
  }
`

export const CustomIframe = styled.iframe`
  height: 193px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: 229px;
  }
`
