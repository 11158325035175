import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  SEND_DATA_FORM,
  SEND_DATA_FORM_SUCCESS,
  SEND_DATA_FORM_ERROR,
  ProductsActions,
  initialState,
  ProductsState,
  GET_PRODUCTS_STATUS,
  GET_PRODUCTS_STATUS_SUCCESS,
  GET_PRODUCTS_STATUS_ERROR,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_AVAILABILITY,
  GET_PRODUCT_AVAILABILITY_SUCCESS,
  GET_PRODUCT_AVAILABILITY_ERROR,
} from '../types/products'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: ProductsActions): ProductsState => {
  switch (action.type) {
    case GET_PRODUCT:
    case GET_PRODUCTS:
      return {
        ...state,
        states: {
          loading: true,
        },
      }
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        producto: {
          ...action.payload,
        },
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_PRODUCT_ERROR:
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_PRODUCT_AVAILABILITY:
      return {
        ...state,
        productAvailability: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case GET_PRODUCT_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        productAvailability: {
          availability: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    }
    case GET_PRODUCT_AVAILABILITY_ERROR:
      return {
        ...state,
        productAvailability: {
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case SEND_DATA_FORM:
      return {
        ...state,
        SendDataForm: {
          data: action.payload,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case SEND_DATA_FORM_SUCCESS:
      return {
        ...state,
        SendDataForm: {
          data: null,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case SEND_DATA_FORM_ERROR:
      return {
        ...state,
        SendDataForm: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case GET_PRODUCTS_STATUS:
      return {
        ...state,
        productsStatusStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_PRODUCTS_STATUS_SUCCESS:
      return {
        ...state,
        productsStatusStates: {
          loading: false,
          success: true,
          error: false,
        },
        productsStatus: action.payload,
      }
    case GET_PRODUCTS_STATUS_ERROR:
      return {
        ...state,
        productsStatusStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productos: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    default:
      return state
  }
}

export default reducers
