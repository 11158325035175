import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, connect } from 'react-redux'

// components
import Card from '../card/index'
import BackgroundBubbles from '../../../../assets/images/background-bubbles.svg'

// hooks
import { useProducts } from '../../hooks/products'
import { useCourses } from '../../hooks/courses'

// constants
import { NOMBRE_SITIO, SITE_ID } from '../../../../constants'

// mixins
import { Title, CardsContainer, Section, TitleContainer, Subtitle, Divider, BackgroundImage } from './mixins'
import { getProducts, getProductsStatus } from '../../../../redux/actions/products'
import features from '../../../../constants/features-per-country'

const cursosEnabled = features[SITE_ID || 'BMAR'].marketplace?.courses?.enabled

const Component = ({ isUserLoggedIn, productsStatus, productsInfo }) => {
  const products = useProducts(isUserLoggedIn)
  const courses = useCourses()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const acquiredSKUs = productsStatus?.map(p => p.sku)

  const getProductList = () => {
    return isUserLoggedIn && acquiredSKUs
      ? products.filter(p => acquiredSKUs.includes(p.sku))
      : products.map(p => ({ ...p, price: productsInfo?.find(i => i.sku === p.sku)?.unitPrice }))
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getProductsStatus())
    } else {
      dispatch(getProducts(products.map(p => p.sku)))
    }
  }, [isUserLoggedIn])

  const getCursosOnClick = () => {
    return courses.map(curso => {
      return {
        ...curso,
        onClick: () => {},
        isLoading: false,
      }
    })
  }

  return (
    <>
      <TitleContainer>
        <Title>{t('products.products.title', { sitio: NOMBRE_SITIO })}</Title>
      </TitleContainer>
      <Section id="products">
        <Subtitle>Tests para resaltar tu perfil</Subtitle>
        <CardsContainer>
          {getProductList().map(product => {
            return (
              <Card
                key={product.key}
                name={product.key}
                logo={product.logo}
                title={product.title}
                body={product.body}
                price={productsStatus?.find(p => p.sku === product.sku)?.price || product.price}
                href={product.href}
                alt={product.alt}
                hrefText={product.hrefText}
                acquired={productsStatus?.find(p => p.sku === product.sku)?.acquired}
                acquiredHref={product.acquiredHref}
                acquiredText={product.acquiredText}
                alternativeAcquired={product.alternativeAcquired}
                tag={product.tag}
              />
            )
          })}
        </CardsContainer>
        {cursosEnabled && courses.length > 0 && (
          <>
            <Divider />
            <BackgroundImage source={BackgroundBubbles}>
              <Subtitle>Cursos en línea</Subtitle>
              <CardsContainer>
                {getCursosOnClick().map(course => {
                  return (
                    <Card
                      key={course.key}
                      name={course.key}
                      logo={course.logo}
                      title={course.title}
                      body={course.body}
                      price={null}
                      href={course.href}
                      alt={course.alt}
                      hrefText={course.hrefText}
                      acquired={null}
                      acquiredText={null}
                      acquiredHref={null}
                      alternativeAcquired={null}
                      tag={null}
                      isLoading={course.isLoading}
                      onClick={course.onClick}
                    />
                  )
                })}
              </CardsContainer>
            </BackgroundImage>
          </>
        )}
      </Section>
    </>
  )
}

const states = ({ productsStore }) => ({
  productsStatus: productsStore?.productsStatus,
  productsInfo: productsStore?.productos,
})

export default connect(states)(Component)
