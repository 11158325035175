import i18n from 'i18next'

const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const regexName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u

const checkIsFilledName = value => {
  const isFilled = value.replace(/\s/g, '') !== ''
  if (!isFilled) {
    return i18n.t('Ingresá tu nombre')
  }
  return isFilled
}

const checkIsFilledSurname = value => {
  const isFilled = value.replace(/\s/g, '') !== ''
  if (!isFilled) {
    return i18n.t('Ingresá tu apellido')
  }
  return isFilled
}

const checkIsFilled = value => {
  const isFilled = value.replace(/\s/g, '') !== ''
  return isFilled
}

const formRules = () => {
  return {
    name: {
      validate: {
        checkIsFilledName,
      },
      minLength: {
        value: 1,
        message: i18n.t('El nombre debe tener al menos 1 caracter'),
      },
      maxLength: {
        value: 50,
        message: i18n.t('El nombre excede el límite permitido'),
      },
      pattern: {
        value: regexName,
        message: i18n.t('Ingresá un nombre válido'),
      },
    },

    surname: {
      validate: {
        checkIsFilledSurname,
      },
      minLength: {
        value: 1,
        message: i18n.t('El apellido debe tener al menos 1 caracter'),
      },
      maxLength: {
        value: 50,
        message: i18n.t('El apellido excede el límite permitido'),
      },
      pattern: {
        value: regexName,
        message: i18n.t('Ingresá un apellido válido'),
      },
    },
    numeroDocumento: {},

    email: {
      required: i18n.t('Ingresá un email válido'),
      pattern: {
        value: mailformat,
        message: i18n.t('Ingresá un email válido'),
      },
    },

    password: {
      required: i18n.t('Ingresá una contraseña válida'),
      minLength: {
        value: 6,
        message: i18n.t('Ingresá al menos 6 caracteres'),
      },
    },
  }
}

export default formRules
