import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Col, Row } from '@navent-jobs/ui-kit/components/grid'

import { CustomButton, CustomContainer, CustomInput, InputContainer } from './mixins'

const SearchBar = ({ query, setQuery, onSubmit }) => {
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()

  return (
    <CustomContainer noGutter>
      <form onSubmit={onSubmit}>
        <Row noGutter>
          <Col offset={{ xl: 4 }} xl={8} lg={8} md={8} sm={12} noGutter>
            <InputContainer>
              <CustomInput
                id="search"
                name="search"
                type="search"
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder={t('postulaciones.search.placeholder')}
                fieldOptions={{
                  variant: 'darken',
                  size: 'medium',
                  before: <Icon name="icon-light-search" color="#0a26ee" size="24" />,
                }}
                noBottomMargin
              />
            </InputContainer>
          </Col>

          {!isDesktop && (
            <Col lg={4} md={4} noGutter>
              <CustomButton type="submit" buttonoptions={{ variant: 'secondary' }}>
                {t('postulaciones.search.button')}
              </CustomButton>
            </Col>
          )}
        </Row>
      </form>
    </CustomContainer>
  )
}

export default SearchBar
