import React from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// components
import HomeSearchBar from '../../../../components/search-bar/HomeSearchBar'
import ScreenSearchBar from '../../../../components/search-bar/ScreenSearchBar'

// mixins
import { SearchBarSectionComponent, ScreenContainer } from './mixins'

// services
import { SearchStack as recentSearches } from '../../../../services/search-stack'

// store
import { getLocations } from '../../../../redux/actions/locations'

const Component = ({ locations, opened, optionsOnTop = false }) => {
  const dispatch = useDispatch()
  const { isMobile, isTabletPortrait } = useWindowSize()

  const onLocations = () => {
    if (!opened) {
      dispatch(getLocations())
    }
  }

  return (
    <SearchBarSectionComponent>
      {isMobile || isTabletPortrait ? (
        <ScreenContainer>
          <ScreenSearchBar />
        </ScreenContainer>
      ) : (
        <HomeSearchBar
          recentSearches={recentSearches}
          availableLocations={locations}
          onLocations={onLocations}
          optionsOnTop={optionsOnTop}
        />
      )}
    </SearchBarSectionComponent>
  )
}

const state = ({ locationsStore }) => ({
  locations: locationsStore && locationsStore.locations,
  opened: locationsStore && locationsStore.opened,
})

export default connect(state)(Component)
