import React from 'react'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { ContainerLoader, CustomLoader } from './mixins'
import { CustomMainContainer } from '../../mixins'

const LoadingComponent = () => {
  return (
    <CustomMainContainer>
      <Row>
        <Col>
          <ContainerLoader>
            <CustomLoader />
          </ContainerLoader>
        </Col>
      </Row>
    </CustomMainContainer>
  )
}
export default LoadingComponent
