export const products = {
  products: {
    doctorCv: {
      title: 'Evalúa y mejora tu curriculum  con Doctor CV!',
      body: 'Doctor CV realiza la evaluación de tu curriculum, brindándote un informe detallado con recomendaciones para mejorarlo y aumentar las posibilidades de encontrar el empleo que buscas',
      cta: 'Conoce más',
      acquiredText: 'Ir a mi CV',
    },
    nulinga: {
      title: 'Valida tu nivel de Inglés con Nulinga',
      bannerTitle: 'Valida tu nivel de Inglés y resalta tu perfil',
      body: 'Completa el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
      claim: 'Certifica tu nivel de inglés con un rápido cuestionario',
      cta: 'Conoce más',
      acquiredText: 'Ir a mi CV',
    },
    pda: {
      title: 'Resalta tus talentos frente a las empresas con PDA!',
      body: 'Conoce tus fortalezas gracias a un reporte detallado que te brinda PDA, al realizar el test de personalidad, permitiendo resaltar Tu CV al momento de postularte',
      cta: 'Conoce más',
      acquiredText: 'Ir a mi CV',
    },
  },
  modal: {
    coderhouse: {
      url: 'https://www.coderhouse.com/alianzas/bumeran?utm_source=bumeran&utm_medium=alianzas&utm_campaign=peru',
      code: 'BUMERANCOD',
    },
  },
}
