import React from 'react'
import { IS_ZONA_JOBS } from '../../../../../constants'
import ChangePasswordSuccessBum from '../../../../../assets/images/change-password-success.svg'
import ChangePasswordSuccessZona from '../../../../../assets/images/change-password-success-zona.svg'
import { ComponentContainer, CustomImage } from './mixins'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage
        src={IS_ZONA_JOBS ? ChangePasswordSuccessZona : ChangePasswordSuccessBum}
        alt="Change password success Image"
      />
    </ComponentContainer>
  )
}

export default Component
