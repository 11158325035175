import React from 'react'
import ContentLoader from 'react-content-loader'
import Card from '../../commons/Card'
import SectionWrapper from '../../commons/SectionWrapper'

// Datos empresa:
const SkeletonDatoEmpresa = ({ showDescription }) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={showDescription ? 285 : 160}
    backgroundColor="#f3f3f3"
    foregroundColor="#d4d4d4"
  >
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="16" />
    <rect x="0" y="24" rx="4" ry="4" width="27%" height="16" />
    <rect x="0" y="56" rx="4" ry="4" width="100%" height="16" />
    <rect x="0" y="80" rx="4" ry="4" width="27%" height="16" />
    <rect x="0" y="112" rx="4" ry="4" width="100%" height="16" />
    <rect x="0" y="136" rx="4" ry="4" width="27%" height="16" />
    {showDescription && (
      <>
        <rect x="0" y="168" rx="4" ry="4" width="100%" height="16" />
        <rect x="0" y="192" rx="4" ry="4" width="100%" height="16" />
        <rect x="0" y="216" rx="4" ry="4" width="100%" height="16" />
        <rect x="0" y="240" rx="4" ry="4" width="100%" height="16" />
        <rect x="0" y="264" rx="4" ry="4" width="80%" height="16" />
      </>
    )}
  </ContentLoader>
)

// Video:
const VideoPlaceholder = () => (
  <ContentLoader speed={2} width="100%" height={154} backgroundColor="#f3f3f3" foregroundColor="#d4d4d4">
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="154" />
  </ContentLoader>
)

const ComplementaryInfoPlaceholder = ({ showDescription = false }) => {
  return (
    <>
      {/* Industria y localidad */}
      <SectionWrapper>
        <Card>
          <SkeletonDatoEmpresa showDescription={showDescription} />
        </Card>
      </SectionWrapper>

      {/* Video */}
      <SectionWrapper>
        <Card>
          <VideoPlaceholder />
        </Card>
      </SectionWrapper>
    </>
  )
}

export default ComplementaryInfoPlaceholder
