import React from 'react'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'

// components
import { Col, Row } from '@navent-jobs/ui-kit/components/grid'
import CardPlaceholder from '../../card/CardPlaceholder'
import HeaderCard from './components/HeaderCard'
import FooterCard from './components/FooterCard'
import SeguirEmpresa from './components/SeguirEmpresa'
import BusquedaActivaChip from '../../busqueda-activa-chip'

// mixins
import { CardComponentWrapper, LinkCustom, CardContainer, FlexContainer, SeguirEmpresaContainer } from './mixins'

const CardComponent = props => {
  const { isMobile } = useScreenSize()
  const { company, loading, settingsCompanyURL, showSeguirEmpresa } = props
  const {
    logo,
    name,
    jobPostingsQuantity,
    industry,
    province,
    description,
    reviewAverage,
    employeesRange,
    gptw,
    id,
    activeSearch,
  } = company

  if (loading) {
    return <CardPlaceholder />
  }

  return (
    <CardComponentWrapper>
      <LinkCustom
        href={settingsCompanyURL.href ? settingsCompanyURL.href : settingsCompanyURL.link}
        linkoptions={{ native: false, variant: 'black', target: isMobile ? '_self' : '_blank' }}
      >
        <CardContainer>
          <HeaderCard
            logo={logo}
            name={name}
            jobPostingsQuantity={jobPostingsQuantity}
            industry={industry}
            location={province}
            reviewAverage={reviewAverage}
            employeesRange={employeesRange}
            gptw={gptw}
          />
          <FooterCard description={description} />
          <Row>
            <Col>
              <FlexContainer>
                <div>
                  <BusquedaActivaChip busquedaActiva={activeSearch} />
                </div>
                <SeguirEmpresaContainer>
                  {showSeguirEmpresa && <SeguirEmpresa empresaId={id} siguiendo={company.following} />}
                </SeguirEmpresaContainer>
              </FlexContainer>
            </Col>
          </Row>
        </CardContainer>
      </LinkCustom>
    </CardComponentWrapper>
  )
}

export default CardComponent
