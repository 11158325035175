import React from 'react'
import { Container, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Title, ImgLogo, Img, Subtitle, Description, Page, ColContainer, Iframe } from './mixins'
import { productLandingTranslations } from '../../constants'
import back from '../../../../assets/images/background-waves-grey.svg'

interface BenefitsInterface {
  logoImage: React.Component
  titleFirstPart?: string
  titleSecondPart?: string
  row1: {
    firstColumn: {
      image: React.Component
      title: string
      description: string
    }
    secondColumn: {
      image: React.Component
      title: string
      description: string
    }
  }
  row2: {
    firstColumn: {
      image: React.Component
      title: string
      description: string
    }
    secondColumn: {
      image: React.Component
      title: string
      description: string
    }
  }
  logoStyles?: any
  videoUrl?: string
}

const Component = ({ productName, accessor = 'benefits' }) => {
  const { logoImage, titleFirstPart, titleSecondPart, row1, row2, logoStyles, videoUrl }: BenefitsInterface =
    productLandingTranslations()[productName][accessor]

  return (
    <Page urlImg={back}>
      <Container noGutter>
        <Row noGutter>
          <Col>
            <Title>
              {titleFirstPart}
              <ImgLogo src={logoImage} logoStyles={logoStyles}></ImgLogo>
              {titleSecondPart}
            </Title>
          </Col>
        </Row>
        <Row noGutter key="row">
          <Col lg={4} offset={{ lg: 2 }}>
            <ColContainer>
              <Img src={row1.firstColumn.image} />
              <Subtitle>{row1.firstColumn.title}</Subtitle>
              <Description>{row1.firstColumn.description}</Description>
            </ColContainer>
          </Col>
          <Col lg={4}>
            <ColContainer>
              <Img src={row1.secondColumn.image} />
              <Subtitle>{row1.secondColumn.title}</Subtitle>
              <Description>{row1.secondColumn.description}</Description>
            </ColContainer>
          </Col>
        </Row>
        <Row noGutter key="row2">
          <Col lg={4} offset={{ lg: 2 }}>
            <ColContainer>
              <Img src={row2.firstColumn.image} />
              <Subtitle>{row2.firstColumn.title}</Subtitle>
              <Description>{row2.firstColumn.description}</Description>
            </ColContainer>
          </Col>
          <Col lg={4}>
            <ColContainer>
              <Img src={row2.secondColumn.image} />
              <Subtitle>{row2.secondColumn.title}</Subtitle>
              <Description>{row2.secondColumn.description}</Description>
            </ColContainer>
          </Col>
        </Row>
        {videoUrl && (
          <Row noGutter>
            <Col lg={8} offset={{ lg: 2 }}>
              <Iframe
                src={videoUrl}
                frameBorder="0"
                allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Col>
          </Row>
        )}
      </Container>
    </Page>
  )
}

export default Component
