import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'
import { Legales } from './mixins'

const AdvertenciaLegales = ({ small = false, semibold = false }: { small?: boolean; semibold?: boolean }) => {
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const showWarningIcon = window.innerWidth > 575

  return (
    <Legales>
      {showWarningIcon && <Icon name="icon-light-alert-triangle" size="24" color={colors.secondary.normal} />}
      <Text size={small ? 'sm' : 'xs'} variant="secondary">
        <Text size={small ? 'sm' : 'xs'} variant="secondary" fontWeight={semibold ? 'semibold' : 'regular'} as="span">
          {`${t('legales.advertenciaPagos')} `}
        </Text>
        {t('legales.advertenciaPagosReclutador')}
      </Text>
    </Legales>
  )
}

export default AdvertenciaLegales
