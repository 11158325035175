import styled from 'styled-components'

export const Spacer = styled.div`
  padding: 80px 0 30px;
  background-color: ${({ theme }) => theme.colors.grey0};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 120px 0;
  }
`

export const TitleContainer = styled.div`
  margin-bottom: 32px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 64px;
  }
`

export const CardDescription = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 20px;
  padding: 40px 16px;
  width: 80%;
  position: relative;
  margin-top: -65px;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.grey16};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 40px;
  }
`

export const CustomImage = styled.img`
  max-height: 280px;
  min-height: 160px
  width: 100%;
  object-fit: cover;
`
