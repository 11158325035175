import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import RadioButton from '@navent-jobs/ui-kit/components/RadioButton'
import { Row, Col, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import { useTranslation } from 'react-i18next'
import { Content, Header, Description, CustomRadioButton } from './mixins'
import { Title, Divider } from '../../mixins'
import { SkeletonPrivacy } from '../common/skeleton/Privacy'
import { getPrivacy, setPrivacy } from '../../../../redux/actions/applicant/account/privacy'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'

interface PrivacyProps {
  privacy: { tipo: string }
  setStates: { success: boolean; error: boolean; loading: boolean }
  getStates: { success: boolean; error: boolean; loading: boolean }
}

const PrivacyCard = ({ privacy, setStates, getStates }: PrivacyProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { success, error, loading } = setStates

  useEffect(() => {
    if (!privacy) {
      dispatch(getPrivacy())
    }
  }, [privacy])

  const privacyHandler = e => {
    const { value } = e.target
    dispatch(setPrivacy({ tipo: value }))
  }

  useEffect(() => {
    if (success) {
      dispatch(showSnackbar(t('account.snackbarMessage.success'), 'icon-light-save'))
    } else if (error) {
      dispatch(showSnackbarError(t('account.snackbarMessage.error')))
    }
  }, [setStates])

  if (getStates.loading) {
    return <SkeletonPrivacy />
  }

  return (
    <Content>
      <Header>
        <Title>{t('account.title', { cv: t('cv') })}</Title>
        <Divider />
      </Header>
      <ContainerFluid>
        <Row>
          <Col>
            <CustomRadioButton>
              <RadioButton
                name="publico"
                fieldOptions={{
                  size: 'medium',
                  direction: 'column',
                  variant: 'lighten',
                }}
                options={[
                  { id: 'publico', opcion: 'Público' },
                  { id: 'privado', opcion: 'Privado' },
                ]}
                onChange={privacyHandler}
                disabled={loading}
                value={privacy && privacy.tipo}
              />
            </CustomRadioButton>
          </Col>
        </Row>
      </ContainerFluid>
      <Description>
        <p>
          <strong>Público: </strong>
          {t('account.privacy.public', { cv: t('cv') })}
        </p>
        <p>
          <strong>Privado: </strong>
          {t('account.privacy.private', { cv: t('cv') })}
        </p>
      </Description>
    </Content>
  )
}
const states = ({ accountPrivacyStore }) => {
  const { privacy, setStates, getStates } = accountPrivacyStore
  return {
    privacy,
    setStates,
    getStates,
  }
}

export default connect(states)(PrivacyCard)
