import {
  CV_PARSER,
  CV_PARSER_ERROR,
  CV_PARSER_RESET,
  CV_PARSER_SUCCESS,
  CvParserActions,
  CvParserState,
  initialState,
} from '../types/cv-parser'
import { status } from '../types/status'

const reducers = (state: CvParserState = initialState, action: CvParserActions): CvParserState => {
  switch (action.type) {
    case CV_PARSER:
      return {
        ...state,
        cvParser: {
          ...state.cvParser,
          status: status.loading,
        },
      }
    case CV_PARSER_SUCCESS:
      return {
        ...state,
        cvParser: {
          ...state.cvParser,
          parsedInfo: action.payload,
          status: status.success,
        },
      }
    case CV_PARSER_ERROR:
      return {
        ...state,
        cvParser: {
          ...state.cvParser,
          status: status.error,
        },
      }
    case CV_PARSER_RESET:
      return {
        ...state,
        cvParser: {
          ...state.cvParser,
          parsedInfo: null,
          status: status.initial,
        },
      }

    default:
      return state
  }
}

export default reducers
