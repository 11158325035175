/* eslint-disable react-hooks/exhaustive-deps */
import { SearchCompaniesStack } from '../../services/search-companies-stack'

export const shotCompanySearch = (keyword): void => {
  if (keyword && SearchCompaniesStack) {
    SearchCompaniesStack.getInstance().push({
      keyword,
      busqueda: keyword,
    })
  }
}
