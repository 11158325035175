export const colorPalletForPructs = {
  bumeran: {
    aviso: {
      freemium: '#29293D',
      talento: '#29293D',
      simple: '#00DCD4',
      destacado: '#0A26EE',
      home: '#FAB306',
      tecnologia: '#E90066',
      infinit: '#8E3DF5',
      border: {
        freemium: '#0A26EE',
        talento: '#0A26EE',
        simple: '#0A26EE',
        destacado: '#0A26EE',
        home: '#FAB306',
        tecnologia: '#0D164E',
        zonajobs: '#FE633F',
        infinit: '#8E3DF5',
      },
      zonajobs: '#FF652B',
    },
    bbd: '#D9005C',
    pda: '#3D47F5',
    hunter: '#0D164E',
    conecta: '#8E3DF5',
    express: '#6700B8',
  },
  zonajobs: {
    aviso: {
      freemium: '#585992',
      talento: '#47B0E2',
      simple: '#FF652B',
      destacado: '#F1D40D',
      home: '#959595',
      tecnologia: '#3B76CE',

      border: {
        freemium: '#FF652B',
        talento: '#FF652B',
        tecnologia: '#3B76CE',
        destacado: '#F1D40D',
        simple: '#FF652B',
        home: '#FF652B',
        zonajobs: '#FE633F',
        infinit: '#8E3DF5',
        bumeran: '#0A26EE',
      },
      bumeran: '#0A26EE',
    },
    bbd: '#646464',
  },
}
