export const SET_CONFETTI = 'SET_CONFETTI'

export interface SetConfetti {
  type: typeof SET_CONFETTI
  payload: null
}

export interface ConfettiState {
  on: boolean | undefined
}

export const initialState: ConfettiState = {
  on: undefined,
}

export type ConfettiActions = SetConfetti