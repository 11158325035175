import styled from 'styled-components'

export const Container = styled.div``

export const Star = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding-right: ${({ index }) => (index < 5 ? '4px' : '0px')};
  padding-left: ${({ index }) => (index > 1 ? '4px' : '0px')};
`

export const CustomDiv = styled.div`
  position: absolute;
  top: 0;
`
