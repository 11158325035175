import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { NOMBRE_SITIO, NOMBRE_PAIS } from '../../../constants'

const Metas = () => {
  const { t } = useTranslation()
  const anio = new Date().getFullYear()
  const wordingData = {
    countryName: NOMBRE_PAIS,
    siteName: NOMBRE_SITIO,
    anio,
  }
  const pageTitle = t(`seoWordings.change_password.title`, wordingData)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default Metas
