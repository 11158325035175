import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomLink, Label, SubLabel } from './mixins'

interface Props {
  isUserLoggedIn: boolean
}

const RecommendedLabel = ({ isUserLoggedIn }: Props) => {
  const { t } = useTranslation()

  return isUserLoggedIn ? (
    <Label>{t('listadoEmpresas.recommended.loggedLabel')}</Label>
  ) : (
    <>
      <Label>{t('listadoEmpresas.recommended.notLoggedLabel')}</Label>
      <SubLabel>
        <CustomLink
          href="/login?returnTo=/listado-empresas"
          linkoptions={{
            size: 'medium',
            type: 'link',
            native: false,
          }}
        >
          {t('listadoEmpresas.recommended.subLabel.part1')}
        </CustomLink>
        {t('listadoEmpresas.recommended.subLabel.part2')}
        <CustomLink
          href="/signin?returnTo=/listado-empresas"
          linkoptions={{
            size: 'medium',
            type: 'link',
            native: false,
          }}
        >
          {t('listadoEmpresas.recommended.subLabel.part3')}
        </CustomLink>
        {t('listadoEmpresas.recommended.subLabel.part4')}
      </SubLabel>
    </>
  )
}

export default RecommendedLabel
