const filters = {
  filtros: {
    'Salario Sin Especificar': 'Sueldo Sin Especificar',
    placeholderSearchFacet: {
      text: 'Busca {{facet}}',
      area: 'un Área',
      provincia: 'una Provincia',
      localidad: 'una Localidad',
    },
  },
}

export default filters
