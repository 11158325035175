const notificationAlerts = {
  alerts: {
    title: 'Alertas',
    subtitle: 'Las alertas de empleo que hayas creado te llegarán por correo electrónico.',
    emptyStateTitle: 'Aún no tienes alertas guardadas.',
    emptyStateDescription: 'Para recibir ofertas de trabajo por email, crea alertas después de realizar una búsqueda.',
    label: {
      created: 'Fecha de creación:',
      keyword: 'Palabra clave:',
      filters: 'Filtros:',
      location: 'Ubicación:',
      frecuencie: 'Frecuencia de envío:',
      state: 'Estado:',
      enable: 'Activada',
      disable: 'Desactivada',
    },
  },
  modalEdit: {
    title: '¿Cuándo te notificamos?',
  },
  modalRemove: {
    title: '¿Quieres eliminar esta alerta?',
  },
  snackbarMessage: {
    success: 'Alerta eliminada',
    error: 'Ocurrió un error inesperado',
  },
  notifications: {
    title: 'Notificaciones',
    subTitle1: 'Mis búsquedas de empleo',
    subTitle2: 'Comunicaciones de',
    helperInfo: {
      typeEmailLabel: 'Por email:',
      typeEmailDescription: 'avisos que te enviamos por correo electrónico.',
      typePushLabel: 'Emergentes:',
      typePushDescription: 'avisos que aparecen de forma automática en tu celular o navegador.',
    },
    postulaciones: {
      description: 'Notificaciones sobre el estado de tus procesos de selección.',
    },
    mensajes: {
      description: 'Consejos, promocionales y nuevas herramientas para encontrar tu próximo trabajo.',
    },
    recomendados: {
      description: 'Envío semanal de ofertas laborales que se relacionan con tus búsquedas y tu {{cv}}. ',
    },
    comerciales: {
      novedades: 'Comunicaciones sobre formación y empresas que pueden interesarte.',
    },
  },
  metaTitle: 'Notificaciones y alertas',
}
export default notificationAlerts
