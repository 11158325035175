import logoBumeran from '../assets/images/logos/bumeran.svg'
import logoKonzerta from '../assets/images/logos/konzerta.svg'
import logoLaborum from '../assets/images/logos/laborum.svg'
import logoMultitrabajo from '../assets/images/logos/multitrabajos.svg'
import logoZonajobs from '../assets/images/logos/logo-zonajobs.svg'

const logos = {
  BMAR: {
    src: logoBumeran,
    width: '122px',
    height: '28px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
  BMCL: {
    src: logoLaborum,
    width: '119px',
    height: '28px',
    alt: 'Logo BLaborum',
    className: 'logo',
  },
  BMMX: {
    src: logoBumeran,
    width: '122px',
    height: '28px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
  BMEC: {
    src: logoMultitrabajo,
    width: '172px',
    height: '28px',
    alt: 'Logo Multitrabajos',
    className: 'logo',
  },
  BMPA: {
    src: logoKonzerta,
    width: '123px',
    height: '28px',
    alt: 'Logo Konzerta',
    className: 'logo',
  },
  BMPE: {
    src: logoBumeran,
    width: '122px',
    height: '28px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
  BMVE: {
    src: logoBumeran,
    width: '122px',
    height: '28px',
    alt: 'Logo Bumeran',
    className: 'logo',
  },
  ZJAR: {
    src: logoZonajobs,
    width: '122px',
    height: '50px',
    alt: 'Logo Zonajobs',
    className: 'logo',
  },
}

export default logos
