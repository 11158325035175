import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import React from 'react'
import ContentLoader from 'react-content-loader'

// Header:
const SkeletonHeader = ({ isMobile }) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={isMobile ? 140 : 164}
    backgroundColor="#f3f3f3"
    foregroundColor="#d4d4d4"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height={isMobile ? '140' : '164'} />
  </ContentLoader>
)

const HeaderPlaceholder = () => {
  const { isMobile } = useWindowSize()
  return <SkeletonHeader isMobile={isMobile} />
}

export default HeaderPlaceholder
