import { Request, Get, Put, Delete } from '@navent-jobs/config'

export class ErrorLanguageTest extends Error {}

class LanguageTest {
  public async GetLanguageTestResult(provider: string, language: string) {
    const request = new Request()
    request.path = `/api/curriculum/language/test/result?language=${language}&provider=${provider}`
    request.method = Get
    return request.call().catch(error => {
      throw new ErrorLanguageTest(error)
    })
  }

  public async ChangeVisibilityToCompany(payload, provider: string, language: string) {
    const request = new Request()
    const param = payload ? 'showResult' : 'hideResult'
    request.path = `/api/curriculum/language/test/${param}?language=${language}&provider=${provider}`
    request.method = payload ? Put : Delete
    return request.call().catch(error => {
      throw new ErrorLanguageTest(error)
    })
  }
}

const languageTest = new LanguageTest()
export default languageTest
