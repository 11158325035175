import {
  GET_JOB_POSTINGS_INFO,
  GET_NEW_FEATURES,
  GetNewFeatures,
  GetJobPostingsInfo,
  GET_TOTAL_AVISOS,
  GetTotalAvisos,
  GetJobPostingsHome,
  GET_JOB_POSTINGS_HOME,
} from '../types/home-page'

export const getJobPostingsInfo = (): GetJobPostingsInfo => {
  return {
    type: GET_JOB_POSTINGS_INFO,
    payload: null,
  }
}

export const getNewFeatures = (): GetNewFeatures => {
  return {
    type: GET_NEW_FEATURES,
    payload: null,
  }
}

export const getTotalAvisos = (): GetTotalAvisos => {
  return {
    type: GET_TOTAL_AVISOS,
    payload: null,
  }
}

export const getJobPostingsHome = (filters): GetJobPostingsHome => {
  return {
    type: GET_JOB_POSTINGS_HOME,
    payload: filters,
  }
}
