import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeFichaAvisoURL } from '@navent-jobs/interlinking'
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import fichaAvisoService from '../../../../services/ficha-aviso-service'
import { ContainerAvisosRecomendados, AvisoRecomendado, Heading, Title } from './mixins'
import { Aviso } from '../../../../redux/types/listado-avisos'

const CONFIDENCIAL = 'Confidencial'
const RECOMMENDED_JOB_POSTING_AMOUNT = 5

const onClickAviso = (event, url) => {
  if (!(event.ctrlKey || event.metaKey)) {
    event.preventDefault()
    window.open(url, '_blank')
  }
}

interface Props {
  avisoId: number
}

const SeccionAvisosRecomendados = ({ avisoId }: Props) => {
  const { t } = useTranslation()
  const [avisosRecomendados, setAvisosRecomendados] = useState<Aviso[]>([])

  useEffect(() => {
    if (avisoId) {
      fichaAvisoService.getAvisosPostPostulacion(avisoId, RECOMMENDED_JOB_POSTING_AMOUNT).then(response => {
        setAvisosRecomendados(response)
      })
    }
  }, [avisoId])

  if (!avisosRecomendados || avisosRecomendados.length <= 0) {
    return null
  }

  return (
    <>
      <ContainerAvisosRecomendados>
        <Row>
          <Col lg="12">
            <Heading>
              <Title>{t('postulaciones.statistics.recommended.title')}</Title>
            </Heading>
            {avisosRecomendados.map((aviso, index) => {
              return (
                <AvisoRecomendado
                  key={aviso.id}
                  onClick={({ event }) =>
                    onClickAviso(
                      event,
                      makeFichaAvisoURL({
                        ...aviso,
                        empresa: {
                          denominacion: aviso.empresa,
                          confidencial: aviso.empresa === CONFIDENCIAL,
                        },
                      })
                    )
                  }
                  aviso={aviso}
                  index={index}
                  ubicacion="Recomendados - Estadisticas"
                />
              )
            })}
          </Col>
        </Row>
      </ContainerAvisosRecomendados>
    </>
  )
}

export default SeccionAvisosRecomendados
