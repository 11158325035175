const avisosRecomendadosIA = {
  listado: {
    cta: '¿No sabes qué buscar? <0>Mira los empleos recomendados por IA</0> (Beta)',
    empty: {
      title: 'Aún no encontramos empleos que se relacionen a tus búsquedas',
      description:
        'Estas recomendaciones las realizamos con inteligencia artificial en base a tu actividad, información de tu perfil y tus últimas postulaciones.',
    },
  },
}

export default avisosRecomendadosIA
