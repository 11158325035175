import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit
import { Row, ContainerFluid, Col } from '@navent-jobs/ui-kit/components/grid'

// components
import ListadoAvisos from './components/listado'
import InfoCards from './components/info-cards'
import EmptyState from './components/empty-state'

// redux
import { deleteSaveJobPostingReset, getAvisosGuardados } from '../../../../redux/actions/save-job-posting'

const AvisosGuardados = ({ avisos, pagination, loading, deleteSaveJobPosting, match, location, resetPageParam }) => {
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const pageParam = Number(new URLSearchParams(location.search).get('page'))
  const currentPage = pageParam === 0 ? 0 : pageParam - 1

  const getListadoAvisosGuardados = (pageNumber = 0) => {
    dispatch(getAvisosGuardados({ page: pageNumber }))
  }

  useEffect(() => {
    getListadoAvisosGuardados(Number(currentPage))
  }, [match.url, location.search])

  useEffect(() => {
    if (deleteSaveJobPosting?.states?.success) {
      if (currentPage === 0) getListadoAvisosGuardados()
      else resetPageParam()
      dispatch(deleteSaveJobPostingReset())
    }
  }, [deleteSaveJobPosting?.states])

  return (
    <ContainerFluid>
      <Row>
        <Col noGutter>
          {!avisos && !loading ? (
            <EmptyState />
          ) : (
            <ListadoAvisos
              avisos={avisos}
              pagination={pagination}
              loading={loading}
              currentPage={currentPage}
              queryParams={queryParams}
            />
          )}
        </Col>
      </Row>
      <Row>
        <InfoCards />
      </Row>
    </ContainerFluid>
  )
}

const states = ({ saveJobPostingStore }) => ({
  avisos: saveJobPostingStore.avisos,
  pagination: saveJobPostingStore.pagination,
  loading: saveJobPostingStore.loading,
  deleteSaveJobPosting: saveJobPostingStore.deleteSaveJobPosting,
})

export default connect(states)(AvisosGuardados)
