import { get, post } from '@navent-jobs/config'
import { ChangePasswordSendEmailSuccessDto } from '../redux/types/change-password'

class MailService {
  public async sendMail(data) {
    await get(`/api/email/${data.fetchMail}?email=${data.applicantMail}`, { sendCookies: true })
  }

  public async sendEmailChangePassword(data): Promise<ChangePasswordSendEmailSuccessDto> {
    try {
      const resp = await post(`/api/email/${data.fetchMail}?email=${encodeURIComponent(data.applicantMail)}`, {
        sendCookies: true,
      })
      return {
        email: resp.email,
        invalidEmail: false,
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          email: null,
          invalidEmail: true,
        }
      }
      throw error
    }
  }
}

const mailService = new MailService()
export default mailService
