export const products = {
  header: {
    title: 'Los productos y servicios que necesitás para potenciar tu Perfil',
  },
  products: {
    pda: {
      title: 'Resaltá tus talentos frente a las empresas con PDA!',
      body: 'Conocé y mostrá tus fortalezas a través de un reporte detallado al realizar el test de personalidad de PDA permitiendo resaltar Tu CV al momento de postularte.',
      cta: 'Conocé más',
      acquiredText: 'Ir a mi CV',
    },
    nulinga: {
      title: 'Validá tu nivel de Inglés con Nulinga',
      bannerTitle: 'Validá tu nivel de Inglés y resaltá tu perfil',
      body: 'Completá el test que te permitirá validar tu nivel del idioma y certificarlo en nuestra plataforma.',
      claim: 'Certificá tu nivel de inglés con un rápido cuestionario',
      cta: 'Conocé más',
      acquiredText: 'Ir a mi CV',
    },
    coderhouse: {
      banner: {
        title: 'Potenciá tu talento con Coderhouse',
        claim: 'Aprovechá un 20% de descuento en todos los cursos y carreras.',
        cta: 'Conocé más',
      },
    },
    review: {
      bannerTitle: 'Calificá una empresa y compartí tu experiencia',
      bannerTitleMobile: 'Calificá una empresa y compartí tu experiencia con otros postulantes',
      claim: 'Tu opinión ayudará a los demás postulantes a conocer más sobre las empresas',
    },
    marketplace: {
      bannerTitle: 'Resaltá tu CV y mejorá tu búsqueda laboral',
      claim: 'Conocé las herramientas que Bumeran te ofrece para facilitar tu búsqueda de empleo.',
      cta: 'Conocer más',
    },
    nulingaCursos: {
      claim:
        'Capacitate en inglés con todas las comodidades que necesitas: clases personalizadas en vivo, cursado 100% online, y valores preferenciales.',
      cta: 'Conocé más',
    },
  },

  banner: {
    title: 'Conocé nuestros nuevos beneficios',
  },
  form: {
    card: {
      title: 'Sumá tu producto a {{sitio}}',
      subtitle:
        'Si tenés un producto o servicio que pueda ayudar a nuestros postulantes a facilitar su búsqueda de empleo y querés tener visibilidad en nuestra plataforma,',
      subtitleBold: '¡Te invitamos a dejar tu contacto!',
      button: 'Sumá tu producto',
    },
    modal: {
      title: 'Sumá tu producto',
      placeholder: {
        nameSurname: 'Ingresá tu nombre y apellido',
        nameCompany: 'Ingresá tu nombre de empresa o producto',
        nameSite: 'Ingresá tu sitio web',
        email: 'Ingresá tu correo',
        message: 'Ingresá tu respuesta...',
      },
      rules: {
        email: 'Ingresá un email válido',
      },
    },
  },
  modal: {
    document: {
      title: 'Agregá tu número de documento',
      description: 'Necesitamos ese dato para efectuar la compra.',
      continue: 'Aceptar',
    },
    coderhouse: {
      title: 'Potenciá tu talento con Coderhouse',
      descripcion: 'Usá el código de descuento en cualquier curso o carrera de Coderhouse y potenciá tu perfil.',
    },
  },
}
