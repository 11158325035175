import { Request, Get } from '@navent-jobs/config'
import { Campaign, CompanyLogos } from '../redux/types/marketing'
import { getRandomInt, randomizeArray } from '../utils/random-util'

export class ErrorMessages extends Error {}

class Marketing {
  public async getCampaign(): Promise<Campaign> {
    try {
      const request = new Request()
      request.method = Get
      request.path = '/api/marketing/campaigns'
      const resp = await request.call()
      const index = resp.length > 1 ? getRandomInt(0, resp.length) : 0
      return resp[index]
    } catch (e) {
      throw new ErrorMessages(`Error al las campañas de marketing`)
    }
  }

  public async getCompanyLogos(): Promise<CompanyLogos[]> {
    try {
      const request = new Request()
      request.method = Get
      request.path = '/api/marketing/company/logos'
      const resp = await request.call()

      // Reordenamos el array de forma aleatoria
      randomizeArray(resp)

      return resp
    } catch (e) {
      throw new ErrorMessages(`Error al obtener los logos de empresas`)
    }
  }
}

const marketing = new Marketing()

export default marketing
