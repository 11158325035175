import { status } from '../types/status'
import {
  RemoveAlertState,
  initialStateRemoveAlert,
  RemoveAlertActions,
  SHOW_MODAL_ALERT_TO_REMOVE,
  HIDE_MODAL_ALERT_TO_REMOVE,
  REMOVE_ALERT,
  REMOVE_ALERT_SUCCESS,
  REMOVE_ALERT_ERROR,
  REMOVE_ALERT_RESET,
} from '../types/applicant/notifications-alerts/alert-remove'

const reducers = (state: RemoveAlertState = initialStateRemoveAlert, action: RemoveAlertActions): RemoveAlertState => {
  switch (action.type) {
    case SHOW_MODAL_ALERT_TO_REMOVE:
      return {
        ...state,
        data: {
          open: true,
          id: action.payload,
        },
      }
    case HIDE_MODAL_ALERT_TO_REMOVE:
      return {
        ...state,
        data: {
          open: false,
          id: null,
        },
      }
    case REMOVE_ALERT:
      return {
        ...state,
        status: status.loading,
      }
    case REMOVE_ALERT_SUCCESS:
      return {
        ...state,
        data: {
          open: false,
          id: null,
        },
        status: status.success,
      }
    case REMOVE_ALERT_ERROR:
      return {
        ...state,
        data: {
          open: false,
          id: null,
        },
        status: status.error,
      }
    case REMOVE_ALERT_RESET:
      return {
        ...state,
        data: {
          open: false,
          id: null,
        },
        status: status.initial,
      }
    default:
      return state
  }
}
export default reducers
