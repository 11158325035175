import {
  CHANGE_PASSWORD_ACCOUNT,
  CHANGE_PASSWORD_ACCOUNT_RESET,
  ChangePasswordAccountType,
  ChangePassword,
  ChangePasswordReset,
} from '../../../types/applicant/account/changePassword'

export const changePassword = (data: ChangePasswordAccountType): ChangePassword => {
  return {
    type: CHANGE_PASSWORD_ACCOUNT,
    payload: data,
  }
}

export const changePasswordReset = (): ChangePasswordReset => {
  return {
    type: CHANGE_PASSWORD_ACCOUNT_RESET,
    payload: null,
  }
}
