import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import React from 'react'
import ContentLoader from 'react-content-loader'

const DesktopSkeleton = props => (
  <ContentLoader
    speed={2}
    width={684}
    height={553}
    viewBox="0 0 684 553"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="47" rx="2" ry="2" width="626" height="16" />
    <rect x="0" y="71" rx="2" ry="2" width="218" height="16" />
    <rect x="0" y="103" rx="2" ry="2" width="195" height="16" />
    <rect x="0" y="135" rx="2" ry="2" width="190" height="12" />
    <rect x="0" y="171" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="263" rx="2" ry="2" width="240" height="12" />
    <rect x="0" y="6" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="191" rx="8" ry="8" width="306" height="48" />
    <rect x="0" y="283" rx="8" ry="8" width="636" height="200" />
    <rect x="220" y="503" rx="8" ry="8" width="200" height="48" />
  </ContentLoader>
)

const MobileSkeleton = props => (
  <ContentLoader
    speed={2}
    height={600}
    width="100%"
    viewBox="0 0 100% 420"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="22" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="63" rx="2" ry="2" width="291" height="12" />
    <rect x="0" y="91" rx="2" ry="2" width="291" height="12" />
    <rect x="0" y="119" rx="2" ry="2" width="182" height="12" />
    <rect x="0" y="147" rx="2" ry="2" width="209" height="12" />
    <rect x="0" y="175" rx="2" ry="2" width="209" height="12" />
    <rect x="0" y="203" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="223" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
    <rect x="0" y="291" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="311" rx="8" ry="8" width="calc(100% - 3px)" height="200" />
    <rect x="0" y="531" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
  </ContentLoader>
)

export const SkeletonEliminarCuenta = () => {
  const { isMobile } = useWindowSize()

  return isMobile ? <MobileSkeleton /> : <DesktopSkeleton />
}
