import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// components
import Anchors from './Anchors'

// mixins
import { AnchorbarSpacer, ContainerAnchorBar, Link } from './mixins'

import { SITE_ID } from '../../../../constants'
import features from '../../../../constants/features-per-country'
import Placeholder from './Placeholder'

interface ComponentProps {
  isLoading: boolean
  company?
  isNavResized?: boolean
  listadoAvisosEmpresa: {}
  visibleId?: any
  hideAnchorReview?: boolean
}

const Component = ({
  isLoading,
  listadoAvisosEmpresa,
  isNavResized,
  visibleId,
  company,
  hideAnchorReview = false,
}: ComponentProps) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const isMobileOrTabletPortrait = window.innerWidth < 1024
  const seccionDetalle = 'section-detalle'

  const hasComplementaryInfo = !!(
    company?.industria?.nombre ||
    company?.provincia?.nombre ||
    company?.ciudad ||
    company?.rangoEmpleados
  )
  const hasDetails = !!(company?.minisitio || company?.descripcion || company?.portadaUrl)

  const showAnchorCompany = features[SITE_ID || 'BMAR'].reviews.enabled
  const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled
  const enableAnchorDetalles = hasDetails || hasComplementaryInfo
  const enableAnchorAvisos = !!listadoAvisosEmpresa
  const showGptw = gptwEnabled && company?.gptwUrl
  const hasLogo = company?.logoURL || (company?.gptwUrl && gptwEnabled)
  const buscaActivamente = company?.busquedaActiva

  return (
    <ContainerAnchorBar isNavResized={isNavResized} id="anchorBarComponent">
      {!isMobileOrTabletPortrait && <AnchorbarSpacer></AnchorbarSpacer>}
      {isLoading ? (
        <Placeholder />
      ) : (
        <Anchors buscaActivamente={buscaActivamente} hasLogo={hasLogo} visibleId={visibleId}>
          <Link
            anchorId="section-detalle"
            isVisible={enableAnchorDetalles}
            href={`#${seccionDetalle}`}
            disabled={!enableAnchorDetalles}
          >
            {t('companySummary.anchorBar.detalle')}
          </Link>

          {showAnchorCompany && !showGptw && !hideAnchorReview && (
            <Link anchorId="section-calificaciones" href="#section-calificaciones">
              {t('companySummary.anchorBar.calificaciones')}
            </Link>
          )}

          <Link
            isVisible={enableAnchorAvisos}
            anchorId="section-avisos"
            href={!enableAnchorAvisos ? '#' : '#section-avisos'}
            disabled={!enableAnchorAvisos}
            withIcon={isMobile}
          >
            {isMobile ? (
              <Icon name="icon-light-list-cards" size="20" color="rgba(0, 0, 24, 0.48)" />
            ) : (
              t('companySummary.anchorBar.avisos')
            )}
          </Link>
        </Anchors>
      )}
    </ContainerAnchorBar>
  )
}

const states = ({ companySummaryStore }) => {
  return {
    listadoAvisosEmpresa: companySummaryStore?.listadoAvisos,
  }
}

export default connect(states)(Component)
