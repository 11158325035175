/* eslint-disable max-len */
const metatagsListado = {
  'Listado Area Title': 'Ofertas de trabajo - Empleos en el área {{area}} - Bumeran.com.pe',
  'Listado Pais Title': 'Trabajos en Perú - Empleos Bumeran 2020',
  'Listado Title Sufijo': 'Bumeran 2020',

  'Listado Meta Description':
    'Encontrados {{cantidadAvisos}} trabajos {{seniority}} en Perú {{fecha}} en Bumeran, la bolsa de empleo más grande del país. Empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 1':
    'Encuentra trabajos {{seniority}} en {{ubicacion}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 2':
    'Busca en {{cantidadAvisos}} ofertas de trabajo {{seniority}} de las mejores empresas en {{area}} {{fecha}}. Bumeran, la bolsa de empleo más grande de Perú.',
  'Listado Meta Description Variante 3':
    'Encuentra trabajos {{seniority}} {{tipoTrabajo}} {{fecha}} en Bumeran, la bolsa de empleo con más ofertas de trabajo {{tipoTrabajo}}, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 4':
    'Encuentra trabajos {{seniority}} {{cantidadAvisos}} en {{area}} {{fecha}} en Bumeran, la bolsa de empleos {{cantidadAvisos}} más grande de Perú, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 5':
    'Encontrados {{cantidadAvisos}} trabajos {{seniority}} {{tipoTrabajo}} de {{area}} en {{ubicacion}} {{fecha}} en Bumeran, la bolsa de empleos {{tipoTrabajo}} más grande de Perú. Empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 6':
    'Encontrados {{cantidadAvisos}} trabajos de {{seniority}} {{area}} en {{ubicacion}} {{fecha}} en Bumeran, la bolsa de empleos más grande de Perú. Empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 7':
    'Se han encontrado {{cantidadAvisos}} avisos de trabajo {{seniority}} para {{busqueda}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 8':
    'Se han encontrado {{cantidadAvisos}} avisos de trabajo {{seniority}} en {{ubicacion}} para {{busqueda}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 9':
    'Se han encontrado {{cantidadAvisos}} avisos de trabajo {{seniority}} {{tipoTrabajo}} para {{busqueda}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 10':
    'Se han encontrado {{cantidadAvisos}} avisos de trabajo {{seniority}} de {{area}} en {{ubicacion}} para {{busqueda}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 11':
    'Se han encontrado {{cantidadAvisos}} avisos de trabajo {{seniority}} {{tipoTrabajo}} de {{area}} en {{ubicacion}} para {{busqueda}} {{fecha}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 12':
    'El empleo de {{busqueda}} {{fecha}} que estás buscando lo encontrás en Bumeran.com.pe. Buscá también más empleos dentro de {{area}}',
  'Listado Meta Description Variante 13':
    'Encuentra trabajos {{modalidad}} en Bumeran, la bolsa de empleos más grande de Perú. Empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 14':
    'Encuentra trabajos {{modalidad}} en Bumeran, la bolsa de empleo con más ofertas de trabajo {{tipoTrabajo}}, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 15':
    'Encuentra trabajos {{modalidad}} para {{busqueda}} en Bumeran, la bolsa de empleo más grande de Perú, empieza a trabajar ahora mismo.',
  'Listado Meta Description Variante 16':
    'Encuentra trabajos {{modalidad}} de {{area}} en {{ubicacion}} en Bumeran, la bolsa de empleo más grande de Perú, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 17':
    'Encuentra trabajos {{modalidad}} de {{area}} en Bumeran, la bolsa de empleo más grande de Perú, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 18':
    'Encuentra trabajos {{modalidad}} de {{area}} en {{ubicacion}} para {{busqueda}} en Bumeran, la bolsa de empleo más grande de Perú, y empieza a trabajar ya mismo.',
  'Listado Meta Description Variante 19':
    'Encuentra trabajos {{modalidad}} {{tipoTrabajo}} de {{area}} en {{ubicacion}} para {{busqueda}} en Bumeran, la bolsa de empleo más grande de Perú, y empieza a trabajar ya mismo.',
}

export default metatagsListado
