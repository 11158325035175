import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const GET_SUSCRIPCION = 'GET_SUSCRIPCION'
export const GET_SUSCRIPCION_SUCCESS = 'GET_SUSCRIPCION_SUCCESS'
export const GET_SUSCRIPCION_ERROR = 'GET_SUSCRIPCION_ERROR'

export const EDITAR_SUSCRIPCION = 'EDITAR_SUSCRIPCION'
export const EDITAR_SUSCRIPCION_SUCCESS = 'EDITAR_SUSCRIPCION_SUCCESS'
export const EDITAR_SUSCRIPCION_ERROR = 'EDITAR_SUSCRIPCION_ERROR'
export const EDITAR_SUSCRIPCION_RESET = 'EDITAR_SUSCRIPCION_RESET'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

export interface GetSuscripcionType {
  idCategoria: number
  email: boolean | null
  push: boolean | null
}

export interface GetSuscripcionMapType {
  [key: number]: GetSuscripcionType
}

export interface EditarSuscripcionType {
  idCategoria: number
  email: boolean | null
  push: boolean | null
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface SuscripcionState {
  getSuscripcion: {
    items: GetSuscripcionType[] | null
    status: StatusType
  }
  editSuscripcion: {
    status: StatusType
  }
}

export const initialState: SuscripcionState = {
  getSuscripcion: {
    items: null,
    status: status.initial,
  },
  editSuscripcion: {
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */

// get
export interface GetSuscripcion {
  type: typeof GET_SUSCRIPCION
  payload: null
}

export interface GetSuscripcionSuccess {
  type: typeof GET_SUSCRIPCION_SUCCESS
  payload: GetSuscripcionMapType
}

export interface GetSuscripcionError {
  type: typeof GET_SUSCRIPCION_ERROR
  payload: null
}

// edit
export interface EditarSuscripcion {
  type: typeof EDITAR_SUSCRIPCION
  payload: EditarSuscripcionType
}

export interface EditarSuscripcionSuccess {
  type: typeof EDITAR_SUSCRIPCION_SUCCESS
  payload: null
}

export interface EditarSuscripcionError {
  type: typeof EDITAR_SUSCRIPCION_ERROR
  payload: null
}

export interface EditartSuscripcionReset {
  type: typeof EDITAR_SUSCRIPCION_RESET
  payload: null
}

export type SuscripcionActions =
  | GetSuscripcion
  | GetSuscripcionSuccess
  | GetSuscripcionError
  | EditarSuscripcion
  | EditarSuscripcionSuccess
  | EditarSuscripcionError
  | EditartSuscripcionReset
