import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pie } from '@nivo/pie'
import { Container, BackgroundText, TextContainer, Spacer } from './mixins'
import Text from '@navent-jobs/ui-kit/components/text'

const Component = ({ category, mobileView }) => {
  const { t } = useTranslation()

  const data = [
    {
      id: category?.categoryName,
      label: category?.categoryName,
      value: category?.average,
      color: '#FAB306',
    },
    {
      value: 5 - category?.average,
      color: '#E4E4E4',
    },
  ]

  const commonProperties = {
    width: 160,
    height: 80,
    data,
    animate: true,
    activeOuterRadiusOffset: 8,
  }

  return (
    <>
      <Container mobileView={mobileView}>
        <Spacer>
          <Pie
            {...commonProperties}
            enableSliceLabels={false}
            startAngle={-90}
            endAngle={90}
            innerRadius={0.8}
            enableRadialLabels={false}
            colors={{ datum: 'data.color' }}
            theme={{
              tooltip: {
                container: {
                  display: 'none',
                },
              },
            }}
          />
          <TextContainer>
            <Text type="title" size="sm" fontWeight="semibold">
              {category?.average?.toFixed(1)}
            </Text>
          </TextContainer>
        </Spacer>
        <BackgroundText>
          <Text size="xs">{t('review.result.reviewBasedOn', { quantity: category?.quantity })}</Text>
        </BackgroundText>
      </Container>
    </>
  )
}

export default Component
