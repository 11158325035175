// ages
export const keysAges = ['count']

// educations
export const keysEducation = ['done', 'inProgress', 'incomplete']
export const levels = ['Graduado', 'En curso', 'Abandonado']

// years experiencie
export const keysYearsExp = ['count']

export const mockGraphTheme = {
  mobile: {
    margin: { top: 10, right: 10, bottom: 40, left: 95 },
    axisBottom: {
      tickValues: 5,
    },
    legends: {
      anchor: 'bottom',
      direction: 'row',
      translateX: -58,
      translateY: 54,
      itemsSpacing: 30,
      itemWidth: 50,
    },
  },
  desktop: {
    margin: { top: 10, right: 180, bottom: 38, left: 80 },
    axisBottom: {
      tickValues: 10,
    },
    legends: {
      anchor: 'right',
      direction: 'column',
      translateX: 124,
      translateY: -50,
      itemsSpacing: 10,
      itemWidth: 100,
    },
  },
}
