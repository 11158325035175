import {
  RESET_ACTIVE_AVISO,
  SET_ACTIVE_AVISO,
  SET_BUSQUEDAS_RELACIONADAS,
  SetActiveAviso,
  SetBusquedasRelacionadas,
  ResetActiveAviso,
} from '../types/active-aviso'

export const resetActiveAviso = (): ResetActiveAviso => {
  return {
    type: RESET_ACTIVE_AVISO,
    payload: null,
  }
}

export const setActiveAviso = (value: any): SetActiveAviso => {
  return {
    type: SET_ACTIVE_AVISO,
    payload: value,
  }
}

export const setBusquedasRelacionadas = (value: any): SetBusquedasRelacionadas => {
  return {
    type: SET_BUSQUEDAS_RELACIONADAS,
    payload: value,
  }
}
