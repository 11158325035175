import { put, call, takeLatest } from 'redux-saga/effects'
import {
  DeleteAccount,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
} from '../types/applicant/account/deleteAccount'
import services from '../../services'
import {
  GET_PRIVACY,
  GET_PRIVACY_ERROR,
  GET_PRIVACY_SUCCESS,
  SetPrivacy,
  SET_PRIVACY_ERROR,
  SET_PRIVACY_SUCCESS,
  SET_PRIVACY,
} from '../types/applicant/account/privacy'
import {
  CHANGE_PASSWORD_ACCOUNT,
  CHANGE_PASSWORD_ACCOUNT_SUCCESS,
  CHANGE_PASSWORD_ACCOUNT_ERROR,
  ChangePassword,
} from '../types/applicant/account/changePassword'
import {
  UpdateEmail,
  UpdateEmailResponseType,
  UpdateEmailSuccess,
  UPDATE_EMAIL_ACCOUNT,
  UPDATE_EMAIL_ACCOUNT_ERROR,
  UPDATE_EMAIL_ACCOUNT_SUCCESS,
} from '../types/applicant/account/updateEmail'

const STATUS_INVALID_PASSWORD = '400'

function* getPrivacy() {
  try {
    const result = yield call(services.account.getPrivacy)
    yield put({ type: GET_PRIVACY_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PRIVACY_ERROR, error })
  }
}

function* setPrivacy({ payload }: SetPrivacy) {
  try {
    yield call(services.account.setPrivacy, payload)
    yield put({ type: SET_PRIVACY_SUCCESS, payload })
  } catch (error) {
    yield put({ type: SET_PRIVACY_ERROR, error })
  }
}

function* changePassword({ payload }: ChangePassword) {
  try {
    yield call(services.account.changePasswordAccountRequest, payload)
    yield put({ type: CHANGE_PASSWORD_ACCOUNT_SUCCESS, payload })
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ACCOUNT_ERROR, payload: error.message })
  }
}

function* updateEmail({ payload }: UpdateEmail) {
  try {
    const response:UpdateEmailResponseType = yield call(services.account.updateEmailRequest, payload)
    yield put<UpdateEmailSuccess>({ type: UPDATE_EMAIL_ACCOUNT_SUCCESS, payload: {statusCode: response.code} })
  } catch (error) {
    yield put({ type: UPDATE_EMAIL_ACCOUNT_ERROR, payload: null })
  }
}

function* deleteAccount({ payload }: DeleteAccount) {
  try {
    yield call(services.deleteaccount.postDeleteAccount, payload)
    yield put({ type: DELETE_ACCOUNT_SUCCESS, payload: null })
  } catch (error) {
    if (error instanceof Error && error.message === STATUS_INVALID_PASSWORD) {
      yield put({ type: DELETE_ACCOUNT_ERROR, payload: true })
    } else {
      yield put({ type: DELETE_ACCOUNT_ERROR, payload: false })
    }
  }
}

/**
 * Watchers
 */
export default function* activationEmail() {
  yield takeLatest(GET_PRIVACY, getPrivacy)
  yield takeLatest(SET_PRIVACY, setPrivacy)
  yield takeLatest(CHANGE_PASSWORD_ACCOUNT, changePassword)
  yield takeLatest(UPDATE_EMAIL_ACCOUNT, updateEmail)
  yield takeLatest(DELETE_ACCOUNT, deleteAccount)
}
