import styled from 'styled-components'

export const CustomContainer = styled.div`
  min-height: 400px;
  margin-bottom: 30px;
`

export const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 22px;
  row-gap: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
