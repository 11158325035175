export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Región',
      localidadTitle: 'Comuna',
    },
  },
  preferenciaSalarial: {
    salarioDescription: 'Sueldo bruto pretendido: ',
    form: {
      salarioPlaceholder: 'Sueldo bruto pretendido',
    },
  },
  legales:
    'es responsable por la Base de Datos. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos personalmente o por correo postal a la dirección Av. Apoquindo 5950, Of. 1701 Ps 17, Las Condes, Santiago, Chile.',
  marketplace: {
    products: {
      nulinga: {
        claim: {
          default: 'Certifica tu nivel de Inglés con un rápido cuestionario',
        },
      },
    },
    modalProductInfo: {
      nulinga: {
        title: 'Potencia tu CV con Nulinga',
        paragraph1a:
          'Certifica tu nivel de Inglés con un rápido cuestionario de selección múltiple. El resultado será analizado por los profesores de',
        paragraph1b: 'Nulinga, los expertos en programas de idioma.',
        paragraph2: 'Serás redireccionado al test una vez que lo hayas abonado a través de Mercado Pago.',
        paragraph3: 'Recuerda usar el e-mail registrado en Laborum para hacer el test. ',
      },
    },
  },
}
