import React from 'react'
import { CardDescription, CustomImage, Spacer, TitleContainer } from './mixins'
import { Col, Container, Row } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import { connect } from 'react-redux'
import EmptyBanner from '../../../../../assets/images/career-site/empty-banner.svg'

const SectionThree = ({ careerSite }) => {
  if (!careerSite?.sectionThree?.visible) {
    return null
  }

  return (
    <Spacer>
      <Container>
        <Row>
          <Col lg={12}>
            <TitleContainer>
              <Text size="sm" type="title" fontWeight="semibold">
                {careerSite.sectionThree.title}
              </Text>
            </TitleContainer>
          </Col>
          <Col>
            <div>
              <CustomImage src={careerSite.sectionThree.image ? careerSite.sectionThree.image : EmptyBanner} />
              <CardDescription>
                <Text size="sm">{careerSite.sectionThree.description}</Text>
              </CardDescription>
            </div>
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
})

export default connect(states)(SectionThree)
