const products = {
  pda: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
  nulinga: {
    marketplace: false,
    curriculumMarketplace: false,
    landing: false,
  },
}

export default products
