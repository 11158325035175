const feria = {
  ficha: {
    enviarPostulado: '{{cv}} enviada',
  },
  info: {
    avisos: 'Vacantes disponibles'
  },
}

export default feria
