import React from 'react'
import { Card, CustomImage, InfoContainer, Spacer, SubtitleContainer, TitleContainer } from './mixins'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import { connect } from 'react-redux'
import EmptyBanner from '../../../../../assets/images/career-site/empty-banner.svg'

const SectionTwo = ({ careerSite }) => {
  if (!careerSite?.sectionTwo?.visible) {
    return null
  }

  return (
    <Spacer>
      <MainContainer>
        <Row>
          <Col lg={12}>
            <TitleContainer>
              <Text type="title" size="sm" fontWeight="semibold">
                {careerSite.sectionTwo.title}
              </Text>
            </TitleContainer>
          </Col>
          <Col>
            <Row>
              <Col offset={{ md: 0.5 }} md={5}>
                <Card>
                  <CustomImage
                    empty={!careerSite.sectionTwo.imageOneUrl}
                    src={careerSite.sectionTwo.imageOneUrl ? careerSite.sectionTwo.imageOneUrl : EmptyBanner}
                  />
                  <InfoContainer>
                    <SubtitleContainer>
                      <Text type="title" size="xs" fontWeight="semibold">
                        {careerSite.sectionTwo.subtitleFirst}
                      </Text>
                    </SubtitleContainer>
                    <Text size="md">{careerSite.sectionTwo.descriptionFirst}</Text>
                  </InfoContainer>
                </Card>
              </Col>
              <Col offset={{ md: 1 }} md={5}>
                <Card>
                  <CustomImage
                    empty={!careerSite.sectionTwo.imageTwoUrl}
                    src={careerSite.sectionTwo.imageTwoUrl ? careerSite.sectionTwo.imageTwoUrl : EmptyBanner}
                  />
                  <InfoContainer>
                    <SubtitleContainer>
                      <Text type="title" size="xs" fontWeight="semibold">
                        {careerSite.sectionTwo.subtitleSecond}
                      </Text>
                    </SubtitleContainer>
                    <Text size="md">{careerSite.sectionTwo.descriptionSecond}</Text>
                  </InfoContainer>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MainContainer>
    </Spacer>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
})

export default connect(states)(SectionTwo)
