import { merge } from 'lodash'
import es from '../es'
import metatagsListado from './metatags-listado'
import { currencyMask } from './currency-mask'
import { facetsName } from './facets-name'
import filtersSettings from './filters-settings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import { seoWordings as seoWordingsCl } from './seo-wordings'
import modalCrearAlerta from './modal-crear-alerta'
import curriculumEs from '../es/curriculum'
import curriculumCl from './curriculum'
import companySummary from '../es/company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesCl from './postulaciones'
import { paymentMP as paymentMPEs } from '../es/payment-mp'
import { paymentMP as paymentMPCl } from './payment-mp'
import { nulinga as nulingaEs } from '../es/nulinga'
import { nulinga as nulingaCl } from './nulinga'
import { productLanding as productLandingEs } from '../es/product-landing'
import { productLanding as productLandingCl } from './product-landing'
import { products } from './products'
import { homeHeader as homeHeaderEs } from '../es/home-header'
import { homeHeader as homeHeaderCl } from './home-header'
import listadoEmpresasEs from '../es/listado-empresas'
import listadoEmpresasCl from './listado-empresas'
import filters from './filters'
import filtersEs from '../es/filters'
import fichaAviso from './ficha-aviso'
import fichaAvisoEs from '../es/ficha-aviso'

const cl = {
  ...es,
  cv: 'CV',
  ...metatagsListado,
  ...filtersSettings,
  ...modalCrearAlerta,
  currencyMask,
  'Postulantes Title': 'Empleos en Chile: trabajo y ofertas de empleo - Laborum 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Chile en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Laborum.',
  Buscar: 'Buscar CL',
  'Ir al inicio': 'Ir al inicio CL',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: '$',
    'currency-format': 'XXX.XXX.XXX,XX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - laborum.cl',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en laborum Empleos.',
  facetsName,
  seoWordings: merge({}, seoWordingsEs, seoWordingsCl),
  curriculum: merge({}, curriculumEs, curriculumCl),
  companySummary,
  postulaciones: merge({}, postulacionesEs, postulacionesCl),
  paymentMP: merge({}, paymentMPEs, paymentMPCl),
  nulinga: merge({}, nulingaEs, nulingaCl),
  productLanding: merge({}, productLandingEs, productLandingCl),
  products: merge({}, es.products, products),
  homeHeader: merge({}, homeHeaderEs, homeHeaderCl),
  listadoEmpresas: merge({}, listadoEmpresasEs, listadoEmpresasCl),
  ...merge({}, { ...filtersEs }, { ...filters }),
  fichaAviso: merge({}, fichaAvisoEs, fichaAviso),
}

export default cl
