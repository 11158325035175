import styled from 'styled-components'
import { Select } from '@navent-jobs/ui-kit/components/select'

export const CustomSelect = styled(Select)``

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 16px;
`

export const SelectFilterContainer = styled.div`
  width: 100%;
`
