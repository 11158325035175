import React from 'react'
import { TextContainer } from './mixins'
import { Trans } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'

const Component = ({ labelKey, percentage, addMarginTop = false }) => {
  if (!percentage) percentage = 0
  return (
    <TextContainer addMarginTop={addMarginTop}>
      <Text size="sm">
        <Trans
          i18nKey={labelKey}
          values={{ percentage: percentage === 100 || percentage === 0 ? percentage : percentage?.toFixed(1) }}
          components={[<strong></strong>]}
        />
      </Text>
    </TextContainer>
  )
}

export default Component
