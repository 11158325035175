import React from 'react'
import { makeFichaAvisoURL } from '@navent-jobs/interlinking'

// components
import CardFooter from './components/CardFooter'
import CardHeader from './components/CardHeader'
import CardPlaceholder from '../../../../components/card/CardPlaceholder'

// mixins
import { CardComponentWrapper, CardContentWrapper, CardContainer } from './mixins'

interface CardPostulationProps {
  activeAvisoStates
  postulacion: {
    id: number
    logoURL: string
    empresa: string
    localizacion: string
    titulo: string
    fechaPublicacion: string
    confidencial: boolean
    estado: string
    cantidadComentarios: string
    preferenciaSalarial: string
    fechaPublicacionAviso: string
    fechaPostulacion: string
    avisoId: number
    tienePreguntasPendientes: boolean
    tipoDePostingId: number
  }
  link: string
  disableLink: boolean
  onClick: (event: object) => void
  onClickSendAnswers?: (id: number) => void
  onClickChangeStatus?: (id: number, titulo: string) => void
  isLoading?: boolean
  className?: string
  sectionCard?: string
}

const CardPostulacion = ({
  className,
  sectionCard,
  activeAvisoStates,
  postulacion,
  link,
  disableLink,
  isLoading,
  onClick,
  onClickSendAnswers,
  onClickChangeStatus,
}: CardPostulationProps) => {
  const { id, titulo, empresa, confidencial } = postulacion

  const refTo = disableLink
    ? undefined
    : link ||
      makeFichaAvisoURL({
        id,
        titulo,
        empresa: { denominacion: empresa, confidencial },
      })

  if (!postulacion) {
    return <CardPlaceholder />
  }

  return (
    <CardComponentWrapper className={className}>
      <CardContentWrapper>
        <CardContainer>
          <CardHeader
            refTo={refTo}
            sectionCard={sectionCard}
            isLoading={isLoading}
            postulacion={postulacion}
            onClick={onClick}
            onClickSendAnswers={onClickSendAnswers}
            activeAvisoStates={activeAvisoStates}
            onClickChangeStatus={onClickChangeStatus}
          />

          <CardFooter postulacion={postulacion} />
        </CardContainer>
      </CardContentWrapper>
    </CardComponentWrapper>
  )
}

export default CardPostulacion
