import React, { useState, useEffect } from 'react'

// Tools
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// Components
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { Sidebar } from '@navent-jobs/ui-kit/components/sidebar'
import imageBodyBm from '../../../assets/images/man-sending-email.svg'
import imageBodyZj from '../../../assets/images/man-sending-email-zj.svg'

// Redux
import { hideModal, showModal } from '../../../redux/actions/modal'

// Styles
import {
  FooterContent,
  ParagraphSimple,
  CustomButton,
  MailSent,
  MailText,
  SidebarTitle,
  SidebarContent,
  Image,
  ModalBodyContainer,
} from './mixins'
import { ModalState } from '../../../redux/types/modal'
import { ApplicantState } from '../../../redux/types/applicant/commons'
import { SiteIds, SITE_ID } from '../../../constants'
import { DataLayer } from '../../../constants/gtm-events-modal-activar-cuenta'

const imageBody = SITE_ID === SiteIds.ZJAR ? imageBodyZj : imageBodyBm

const Footer = ({ sendActivationMail, activationMailSent, isLoading }) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  useEffect(() => {
    DataLayer.handleModalView()
  }, [])

  return (
    <FooterContent>
      <ParagraphSimple>{t('Modal Activar Cuenta Texto')}</ParagraphSimple>

      {activationMailSent ? (
        <MailSent variant="black">
          <MailText>{t('Modal Activar Cuenta Boton Enviado')}</MailText>
        </MailSent>
      ) : (
        <CustomButton
          buttonoptions={{ variant: 'primary', size: isMobile ? 'small' : 'medium' }}
          type="submit"
          onClick={() => sendActivationMail()}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('Modal Activar Cuenta Boton Enviar')}
        </CustomButton>
      )}
    </FooterContent>
  )
}

const ModalActivarCuenta = ({ applicant, isUserLoggedIn, mailService, visible }) => {
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()
  const [isLoading, setLoading] = useState(false)
  const [activationMailSent, setActivationMailSent] = useState(false)
  const modalActivarCuentaVisible = visible === 'modal-activar-cuenta'
  const { t } = useTranslation()
  const location = useLocation()
  const queryAccountActivated = new URLSearchParams(window.location.search).get('activation_success')
  const excludedPaths = ['/registro/confirmarCuenta', '/postulantes/registro/confirmar-cuenta']
  const urlExcluded = excludedPaths.includes(location.pathname)

  const onClose = () => {
    DataLayer.handleModalClose()
    dispatch(hideModal())
  }

  const sendActivationMail = () => {
    setLoading(true)
    mailService
      .sendMail({
        fetchMail: 'activar-cuenta',
        applicantMail: applicant && applicant.email,
      })
      .then(() => {
        DataLayer.handleSendEmail()
        setActivationMailSent(true)
      })
      .catch(error => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const checkUserAccount = (): any => {
    const showModalValidation =
      !urlExcluded &&
      !queryAccountActivated &&
      isUserLoggedIn &&
      applicant &&
      !applicant.mailValidado &&
      !activationMailSent

    if (showModalValidation) {
      dispatch(showModal('modal-activar-cuenta'))
    }
  }

  useEffect((): any => {
    let isSubscribed = true
    if (isSubscribed) {
      checkUserAccount()
    }
    // eslint-disable-next-line no-return-assign
    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, applicant])

  return isMobile ? (
    <Sidebar position="right" closeType="close" visible={modalActivarCuentaVisible} handleClose={onClose}>
      {visible && (
        <ContainerFluid>
          <Row>
            <Col>
              <>
                <SidebarTitle>{t('Modal Activar Cuenta Subtitulo')}</SidebarTitle>
                <SidebarContent>
                  <Image src={imageBody} alt="Activar cuenta" />
                  <Footer
                    sendActivationMail={sendActivationMail}
                    activationMailSent={activationMailSent}
                    isLoading={isLoading}
                  />
                </SidebarContent>
              </>
            </Col>
          </Row>
        </ContainerFluid>
      )}
    </Sidebar>
  ) : (
    <Modal
      width={484}
      visible={modalActivarCuentaVisible}
      onClose={onClose}
      skin="with-info"
      title={t('Modal Activar Cuenta Subtitulo')}
      footer={
        <Footer sendActivationMail={sendActivationMail} activationMailSent={activationMailSent} isLoading={isLoading} />
      }
    >
      <ContainerFluid>
        <Row>
          <Col>
            <ModalBodyContainer>
              <Image src={imageBody} alt="Activar cuenta" />
            </ModalBodyContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </Modal>
  )
}

const states = ({ applicantStore, modalStore }: { applicantStore: ApplicantState; modalStore: ModalState }) => ({
  isUserLoggedIn: applicantStore?.applicant,
  applicant: applicantStore ? applicantStore.applicant : {},
  visible: modalStore?.activeModal,
})

export default connect(states)(ModalActivarCuenta)
