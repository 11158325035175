import { status, StatusType } from '../../status'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR'
export const DELETE_ACCOUNT_RESET = 'DELETE_ACCOUNT_RESET'

export interface DeleteAccountType {
  data: object
}

export interface DeleteAccount {
  type: typeof DELETE_ACCOUNT
  payload: DeleteAccountType
}

export interface DeleteAccountSuccess {
  type: typeof DELETE_ACCOUNT_SUCCESS
  payload: null
}

export interface DeleteAccountError {
  type: typeof DELETE_ACCOUNT_ERROR
  payload: boolean
}

export interface DeleteAccountReset {
  type: typeof DELETE_ACCOUNT_RESET
  payload: null
}

export interface DeleteAccountState {
  status: StatusType
  invalidPassword: boolean
}

export const initialState: DeleteAccountState = {
  status: status.initial,
  invalidPassword: false,
}

export type DeleteAccountActions = DeleteAccount | DeleteAccountError | DeleteAccountReset | DeleteAccountSuccess
