const filtersSettings = {
  'filters-settings': {
    salario: {
      label: 'Salario mensual',
    },
    provincia: {
      label: 'Provincia',
    },
    localidad: {
      label: 'Distrito',
    },
  },
}

export default filtersSettings
