import styled from 'styled-components'
import { Col } from '@navent-jobs/ui-kit/components/grid'
import { selectStyles } from '../../../../components/search-bar/components/utils/mixins'

export const SearchBarContainer = styled('div')`
  background: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`

export const TitleComponent = styled('div')`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

export const Label = styled('p')`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 0 8px;
`

export const CustomCol = styled(Col)`
  ${({ theme }) => selectStyles(theme)}
`
