import React, { Suspense, useLayoutEffect } from 'react'
import { Row, Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import PageTitle from '../../components/PageTitle'
import PageSectionCard from '../../components/PageSectionCard'
import LayoutAuthHeader from '../../components/layouts/layout-auth-header'
import { PrivateRoute } from '../../components/private-route'
import PageLoader from '../../components/PageLoader'
import SuscripcionesContent from './components/notificationsContent'
import Meta from './components/Meta'
import { PageContent } from './mixins'
import { handleSmoothScroll } from '../../hooks/handle-smooth-scroll'
import AlertsContentV2 from './components/AlertsContent-v2'

const Notifications = props => {
  const { hash } = window.location
  const FICHA_AVISO_PAGE_PATH = '/empleos/(.*)-:idAviso.html*'

  useLayoutEffect(() => {
    if (hash) {
      setTimeout(() => {
        handleSmoothScroll(hash, 135)
      }, 1500)
    }
  }, [])

  return (
    <PrivateRoute>
      <Suspense fallback={<PageLoader />}>
        <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
          <PageContent>
            <Meta />
            <PageTitle iconName="icon-bold-notification" title="Notificaciones y alertas" maxWidth={684} />
            <MainContainer>
              <Row>
                <Col>
                  <PageSectionCard>
                    <SuscripcionesContent />
                  </PageSectionCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageSectionCard>
                    <AlertsContentV2 />
                  </PageSectionCard>
                </Col>
              </Row>
            </MainContainer>
          </PageContent>
        </LayoutAuthHeader>
      </Suspense>
    </PrivateRoute>
  )
}

export default Notifications
