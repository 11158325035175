import { TagManager } from '@navent-jobs/config'

export const DataLayers = {
  filterApplied: (fixed, filter, optionSelected) => {
    /**
     * El objetivo es trackear los filtros que utiliza el usuario.
     * Debe dispararse cuando el usuario hace click en alguno de los Filtros.
     */

    const isFixed = fixed ? 'Filtros' : 'Mas filtros'

    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado',
      eventAction: `${isFixed}-${filter}`,
      eventLabel: optionSelected,
    })
  },
}
