import { Pie } from '@nivo/pie'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Container, Text, TextContainer, Legend, LegendContainer, GraphContainer } from './mixins'

const Component = ({ skill, total }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  const percentage = item => Math.floor((item.value * 100) / total)

  const data = [
    {
      id: skill.name,
      label: skill.name,
      value: skill.value,
      color: colors.secondary.normal,
    },
    {
      value: total - skill.value,
      color: '#E4E4E4',
    },
  ]

  const commonProperties = {
    width: 160,
    height: 160,
    data,
    animate: true,
    activeOuterRadiusOffset: 8,
  }

  return (
    <Container>
      <GraphContainer>
        <Pie
          {...commonProperties}
          enableSliceLabels={false}
          innerRadius={0.7}
          enableRadialLabels={false}
          colors={{ datum: 'data.color' }}
          theme={{
            tooltip: {
              container: {
                display: 'none',
              },
            },
          }}
        />
        <TextContainer>
          <Text>{`${percentage(skill)}%`}</Text>
        </TextContainer>
      </GraphContainer>
      <LegendContainer>
        <Legend>{skill.name}</Legend>
      </LegendContainer>
    </Container>
  )
}

export default Component
