import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphPlaceholder = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={364}
    viewBox="0 0 100% 364"
    backgroundcolor="#f3f3f3"
    foregroundcolor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="200" height="24" />
    <rect x="0" y="44" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 44 h 38 v 20 H 40 z" />
    <rect x="0" y="94" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 94 h 78 v 20 H 40 z" />
    <rect x="0" y="144" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 144 h 157 v 20 H 40 z" />
    <rect x="0" y="194" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 194 h 313 v 20 H 40 z" />
    <rect x="0" y="244" rx="4" ry="4" width="20" height="20" />
    <path d="M 40.239 244 H 506 v 20 H 40.239 z" />
    <rect x="0" y="294" rx="4" ry="4" width="20" height="20" />
    <path d="M 40 294 h 624 v 20 H 40 z" />
    <rect x="232" y="340" rx="4" ry="4" width="200" height="24" />
  </ContentLoader>
)

export default GraphPlaceholder
