import { put, call, takeLatest } from 'redux-saga/effects'
import {
  REGISTRAR_ACTIVIDAD,
  REGISTRAR_ACTIVIDAD_ERROR,
  REGISTRAR_ACTIVIDAD_SUCCESS,
  RegistrarActividad,
  RegistrarActividadError,
  RegistrarActividadSuccess,
} from '../types/actividad'
import { actividadService } from '../../services/actividad'

function* registrarActividad() {
  try {
    yield call(actividadService.registrarActividad)
    yield put<RegistrarActividadSuccess>({ type: REGISTRAR_ACTIVIDAD_SUCCESS, payload: null })
  } catch {
    yield put<RegistrarActividadError>({ type: REGISTRAR_ACTIVIDAD_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* actividad() {
  yield takeLatest<RegistrarActividad>(REGISTRAR_ACTIVIDAD, registrarActividad)
}
