import {
  GET_ANALYSISCV,
  GET_ANALYSISCV_SUCCESS,
  GET_ANALYSISCV_ERROR,
  POST_ANALYSISCV,
  POST_ANALYSISCV_SUCCESS,
  POST_ANALYSISCV_ERROR,
  initialState,
  AnalysisCvActions,
  AnalysisCvState,
  POST_ANALYSISCV_PREVIEW,
  POST_ANALYSISCV_PREVIEW_SUCCESS,
  POST_ANALYSISCV_PREVIEW_ERROR,
  POST_ANALYSISCV_PREVIEW_RESET,
} from '../types/analysis-cv'

const reducers = (state: AnalysisCvState = initialState, action: AnalysisCvActions): AnalysisCvState => {
  switch (action.type) {
    case POST_ANALYSISCV:
      return {
        ...state,
        postStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case POST_ANALYSISCV_PREVIEW:
      return {
        ...state,
        previewStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_ANALYSISCV:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case POST_ANALYSISCV_SUCCESS:
      return {
        ...state,
        url: action.payload,
        postStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case POST_ANALYSISCV_PREVIEW_SUCCESS:
      return {
        ...state,
        previewUrl: action.payload,
        previewStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_ANALYSISCV_SUCCESS:
      return {
        ...state,
        url: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case POST_ANALYSISCV_ERROR:
      return {
        ...state,
        postStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case POST_ANALYSISCV_PREVIEW_ERROR:
      return {
        ...state,
        previewStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case GET_ANALYSISCV_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case POST_ANALYSISCV_PREVIEW_RESET:
      return {
        ...state,
        previewStates: {
          loading: false,
          success: false,
          error: false,
        },
      }
    default:
      return state
  }
}

export default reducers
