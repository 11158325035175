import { SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_ERROR, initialState, SignUpActions, SignUpState } from '../../types/sign-up'

const reducers = (state: SignUpState = initialState, action: SignUpActions): SignUpState => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SIGN_UP_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
