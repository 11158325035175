import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  SetNotifications,
  SET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS,
} from '../types/applicant/notifications-alerts/notifications'

function* getNotifications() {
  try {
    const result = yield call(services.notificationSettings.getNotifications)
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_ERROR, error })
  }
}

function* setNotifications({ payload }: SetNotifications) {
  const { value, update } = payload
  try {
    yield call(services.notificationSettings.setNotifications, value, update)
    yield put({ type: SET_NOTIFICATIONS_SUCCESS })
  } catch (error) {
    yield put({ type: SET_NOTIFICATIONS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* activationEmail() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications)
  yield takeLatest(SET_NOTIFICATIONS, setNotifications)
}
