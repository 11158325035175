import { StatusType, status } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const CV_PARSER = 'CV_PARSER'
export const CV_PARSER_SUCCESS = 'CV_PARSER_SUCCESS'
export const CV_PARSER_ERROR = 'CV_PARSER_ERROR'
export const CV_PARSER_RESET = 'CV_PARSER_RESET'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

export interface Identificador {
  id?: number
  nombre: string
}

export interface Experiencia {
  puesto: string | null
  empresa: string | null
  desde: Date | null
  hasta: Date | null
  pais: Identificador | null
  descripcion: string | null
  industria: Identificador | null
  nivel: Identificador | null
  area: Identificador | null
  subarea: Identificador | null
}

export interface Educacion {
  titulo: string | null
  institucion: Identificador | null
  desde: string | null
  hasta: string | null
  pais: Identificador | null
  tipo: Identificador | null
  estado: Identificador | null
  area: Identificador | null
}

export interface Conocimiento {
  nombre: string
}

export interface Idioma {
  denominacion: Identificador
  nivelOral: Identificador
  nivelEscrito: Identificador
}

export interface FotoPerfil {
  imagen: string
}

export interface DatosPersonalesParser {
  fechaNacimiento: Date | null
  direccionResidencia?: {
    pais: Identificador | null
    provincia: Identificador | null
    localidad: Identificador | null
    direccion: string | null
  } | null
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */

export interface ParsedInfo {
  datosPersonales?: DatosPersonalesParser | null
  experienciaLaboral: Experiencia[] | null
  educacion: Educacion[] | null
  conocimientos: Conocimiento[] | null
  idiomas: Idioma[] | null
  fotoPerfil: FotoPerfil | null
}

export interface CvParserState {
  cvParser: {
    parsedInfo: ParsedInfo | null
    status: StatusType
  }
}

export const initialState: CvParserState = {
  cvParser: {
    parsedInfo: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */
export interface CvParser {
  type: typeof CV_PARSER
  payload: null
}
export interface CvParserSuccess {
  type: typeof CV_PARSER_SUCCESS
  payload: ParsedInfo | null
}
export interface CvParserError {
  type: typeof CV_PARSER_ERROR
  payload: null
}
export interface CvParserReset {
  type: typeof CV_PARSER_RESET
  payload: null
}

export type CvParserActions = CvParser | CvParserSuccess | CvParserError | CvParserReset
