import {
  CHANGE_PASSWORD_SEND_EMAIL,
  ChangePasswordSendEmail,
  ChangePasswordSendEmailRequestDto,
  CHANGE_PASSWORD,
  ChangePassword,
  ChangePasswordRequestDto,
  ChangePasswordSendEmailReset,
  CHANGE_PASSWORD_SEND_EMAIL_RESET,
} from '../types/change-password'

export const sendEmailChangePassword = (payload: ChangePasswordSendEmailRequestDto): ChangePasswordSendEmail => {
  return {
    type: CHANGE_PASSWORD_SEND_EMAIL,
    payload,
  }
}

export const sendEmailChangePasswordReset = (): ChangePasswordSendEmailReset => {
  return {
    type: CHANGE_PASSWORD_SEND_EMAIL_RESET,
    payload: null,
  }
}

export const changePassword = (payload: ChangePasswordRequestDto): ChangePassword => {
  return {
    type: CHANGE_PASSWORD,
    payload,
  }
}
