import {
  POST_TO_APPLY,
  PostToApply,
  PostToApplyRequestDto,
  SEND_ANSWERS,
  SendAnswers,
  SendAnswersRequestDto,
  SEND_ANSWERS_RESET,
} from '../types/postular-bar'

export const postToApply = (payload: PostToApplyRequestDto): PostToApply => {
  return {
    type: POST_TO_APPLY,
    payload,
  }
}

export const sendAnswers = (payload: SendAnswersRequestDto): SendAnswers => {
  return {
    type: SEND_ANSWERS,
    payload,
  }
}

export const sendAnswersReset = () => {
  return {
    type: SEND_ANSWERS_RESET,
  }
}
