import React from 'react'
import { Helmet } from 'react-helmet'
import { NOMBRE_PAIS } from '../../../../constants'
import { useTranslation } from 'react-i18next'

const Metas = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <title>{t('seoWordings.listadoEmpresas.title', {pais: NOMBRE_PAIS})}</title>
    </Helmet>
  )
}

export default Metas
