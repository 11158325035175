import { TagManager } from '@navent-jobs/config'

export const FooterDataLayers = {
  redesSociales: redSocial => {
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'CrossSite',
      eventAction: 'Redes sociales',
      eventLabel: redSocial,
    })
  },
}
