import { SHOW_MODAL, HIDE_MODAL, ShowModal, HideModal } from '../types/modal'

export const showModal = (value: string): ShowModal => {
  return {
    type: SHOW_MODAL,
    payload: value,
  }
}

export const hideModal = (): HideModal => {
  return {
    type: HIDE_MODAL,
    payload: null,
  }
}
