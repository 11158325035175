import { put, call, takeLatest, take } from 'redux-saga/effects'
import service from '../../services/messages'
import {
  ChatType,
  EnviarMensaje,
  ENVIAR_MENSAJE,
  ENVIAR_MENSAJE_ERROR,
  ENVIAR_MENSAJE_SUCCESS,
  LeerChat,
  LEER_CHAT,
  LEER_CHAT_ERROR,
  LEER_CHAT_SUCCESS,
  ListadoItemType,
  LISTADO_MENSAJES_SUCCESS,
  LISTADO_MENSAJES_ERROR,
  ListadoMensajes,
  LISTADO_MENSAJES,
  LeerChatSuccess,
  LeerChatError,
  ListadoMensajesSuccess,
  ListadoMensajesError,
  EnviarMensajeError,
  EnviarMensajeSuccess,
  MarcarLeido,
  MARCAR_LEIDO,
  MarcarLeidoSuccess,
  MarcarLeidoError,
  MARCAR_LEIDO_ERROR,
  MARCAR_LEIDO_SUCCESS,
} from '../types/messages'

function* listadoMensajes() {
  try {
    const result: ListadoItemType[] = yield call(service.getListadoMensajes)
    yield put<ListadoMensajesSuccess>({ type: LISTADO_MENSAJES_SUCCESS, payload: result })
  } catch {
    yield put<ListadoMensajesError>({ type: LISTADO_MENSAJES_ERROR, payload: null })
  }
}

function* leerChat({ payload }: LeerChat) {
  try {
    const result: ChatType = yield call(service.leerChat, payload)
    yield put<LeerChatSuccess>({ type: LEER_CHAT_SUCCESS, payload: result })
    if (!result.leido) {
      yield put<MarcarLeido>({ type: MARCAR_LEIDO, payload: { id: result.idMensaje } })
    }
  } catch {
    yield put<LeerChatError>({ type: LEER_CHAT_ERROR, payload: null })
  }
}

function* enviarMensaje({ payload }: EnviarMensaje) {
  try {
    yield call(service.enviarMensaje, payload)
    yield put<LeerChat>({ type: LEER_CHAT, payload: { id: payload.id, tipo: payload.tipo } })
    const action = yield take([LEER_CHAT_SUCCESS, LEER_CHAT_ERROR])
    if (action.type === LEER_CHAT_SUCCESS) {
      yield put<EnviarMensajeSuccess>({ type: ENVIAR_MENSAJE_SUCCESS, payload: null })
    } else {
      yield put<EnviarMensajeError>({ type: ENVIAR_MENSAJE_ERROR, payload: null })
    }
  } catch {
    yield put<EnviarMensajeError>({ type: ENVIAR_MENSAJE_ERROR, payload: null })
  }
}

function* marcarLeido({ payload }: MarcarLeido) {
  try {
    yield call(service.marcarLeido, payload)
    yield put<MarcarLeidoSuccess>({ type: MARCAR_LEIDO_SUCCESS, payload })
  } catch {
    yield put<MarcarLeidoError>({ type: MARCAR_LEIDO_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* notification() {
  yield takeLatest<ListadoMensajes>(LISTADO_MENSAJES, listadoMensajes)
  yield takeLatest<LeerChat>(LEER_CHAT, leerChat)
  yield takeLatest<EnviarMensaje>(ENVIAR_MENSAJE, enviarMensaje)
  yield takeLatest<MarcarLeido>(MARCAR_LEIDO, marcarLeido)
}
