import { Request, Get, Put, Delete } from '@navent-jobs/config'
import { EditAlerts } from '../redux/types/applicant/notifications-alerts/edit-alerts'

export class ErrorApplicant extends Error {}

class Alerts {
  public async getAlerts() {
    const request = new Request()
    request.path = `/api/ajustes/alertas`
    request.method = Get
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }

  public async editAlerts({ payload, id }: EditAlerts) {
    const request = new Request()
    request.path = `/api/candidates/alertas/${id}`
    request.method = Put
    request.body = payload
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }

  public async deleteAlert(alertaId) {
    const request = new Request()
    request.path = `/api/ajustes/alertas/${alertaId}`
    request.method = Delete
    return request.call().catch(error => {
      throw new ErrorApplicant(error)
    })
  }
}

const alerts = new Alerts()
export default alerts
