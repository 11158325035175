import React from 'react'
import Text from '@navent-jobs/ui-kit/components/text'
import paymentMPSettings from '../../../../constants/payment-mp-settings'
import { PaymentStateTitle } from './mixins'

const Component = ({ status, product }) => {
  const pageSetting = paymentMPSettings()

  return (
    <PaymentStateTitle>
      <Text type="title" size="sm" fontWeight="semibold" variant="secondary" as="h2">
        {pageSetting.products[product][status].title}
      </Text>
    </PaymentStateTitle>
  )
}

export default Component
