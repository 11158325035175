import styled from 'styled-components'

export const CardComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffff;
`
