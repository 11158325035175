import { TagManager } from '@navent-jobs/config'

export const DataLayersLanguageTest = {
  doTest: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Acceder al pago del test',
      eventLabel: `CTA Hacer el test-${from}`,
    }),
  openDocumentModal: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Modal Agrega tu DNI en el CV',
      eventLabel: 'Impresion del modal',
    }),
  closeDocumentModal: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Modal Agrega tu DNI en el CV',
      eventLabel: `${from}`,
    }),
  completedPayment: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Pago del test',
      eventLabel: `${from}`,
    }),
  goToTest: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Iniciar test de ingles',
      eventLabel: from,
    }),
  completeTest: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Completar test de ingles',
      eventLabel: 'Link Click aca',
    }),
  landing: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Test de Ingles',
      eventAction: 'Landing test de Ingles',
      eventLabel: `CTA-${from}`,
    }),
}
