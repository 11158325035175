import React, { useState } from 'react'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import ContentLoader from 'react-content-loader'
import { LoaderContainer, PlaceholderHeader } from './mixins'


const Component = () => {
  const { isTabletLandscape, isDesktop } = useWindowSize()
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const wrapperSection = 0.33

  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <PlaceholderHeader>
        {isDesktop && (
          <ContentLoader
            speed={2}
            width={wrapperWidth}
            height={48}
            viewBox={`0 0 ${wrapperWidth} 48`}
            backgroundColor="#f3f3f3"
            foregroundColor="#d4d4d4"
          >
            <rect x="0" y="0" rx="8" ry="8" width={wrapperWidth * wrapperSection} height="48" />
            <rect x={wrapperWidth * wrapperSection + 8} y="0" rx="8" ry="8" width={wrapperWidth * wrapperSection} height="48" />
            <rect x={wrapperWidth * wrapperSection * 2 + 16} y="0" rx="8" ry="8" width={wrapperWidth * wrapperSection} height="48" />
          </ContentLoader>
        )}
        {isTabletLandscape && (
          <ContentLoader
            speed={2}
            width={wrapperWidth}
            height={36}
            viewBox={`0 0 ${wrapperWidth} 36`}
            backgroundColor="#f3f3f3"
            foregroundColor="#d4d4d4"
          >
            <rect x="0" y="0" rx="8" ry="8" width={wrapperWidth * 0.2513} height="36" />
            <rect x={wrapperWidth * 0.2513 + 4} y="0" rx="8" ry="8" width={wrapperWidth * 0.2513} height="36" />
            <rect x={wrapperWidth * 0.2513 * 2 + 8} y="0" rx="8" ry="8" width={wrapperWidth * 0.2513} height="36" />
            <rect x={wrapperWidth * 0.2513 * 3 + 12} y="0" rx="8" ry="8" width={wrapperWidth * 0.2291} height="36" />
          </ContentLoader>
        )}
      </PlaceholderHeader>
    </LoaderContainer>
  )
}

export default Component
