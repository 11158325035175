import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const FieldIcon = styled(Icon)`
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translate(0, -50%);
`

export const ToogleSidebarMenu = styled.button`
  position: relative;
  line-height: 48px;
  display: block;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey60};
  padding: 0px 0px 0px 44px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  width: 100%;
  text-align: left;
  outline: 0;
  border: 0;
`
