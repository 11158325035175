export const paymentMP = {
  successDefaultTitle: 'Tu pago se procesó con éxito',
  pendingDefaultTitle: 'Tu pago está en espera',
  failureDefaultTitle: 'Lamentablemente no se pudo procesar tu pago',
  failureDefaultSubTitle: 'Intentalo nuevamente en unos minutos.',
  products: {
    premium: {
      name: 'Premium',
      success: {
        subtitle: 'Ya sos parte de ',
        buttonTitle: 'Volver al portal',
        buttonRef: '/',
      },
      pending: {
        subtitle: 'Cuando se acredite, habilitaremos tu cuenta ',
        buttonTitle: 'Volver al portal',
        buttonRef: '/',
      },
      failure: {
        buttonTitle: 'Volver al portal',
        buttonRef: '/',
      },
    },
    testDePersonalidad: {
      name: 'Test de personalidad',
      success: {
        subtitle: '¡Felicitaciones! Ya recibimos tu pago de ',
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      pending: {
        subtitle: 'Cuando se acredite, habilitaremos tu ',
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      failure: {
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
    },
    nulinga: {
      name: 'Nulinga',
      success: {
        subtitle: '¡Felicitaciones! Ya recibimos tu pago para realizar el test de Inglés de ',
        checkLabel: 'Acepto usar el mismo email con el que estoy registrado en Bumeran para hacer el test de Inglés',
        buttonTitle: 'Ir al test',
        buttonRef: 'https://nulinga.com/postulantes/new?token=76Q',
        emailCopyTitle: 'Copiá tu email',
      },
      pending: {
        subtitle: 'Cuando se acredite, habilitaremos un enlace para realizar el test de Inglés de ',
        info: 'Recordá usar el e-mail registrado en Bumeran para hacer el test.',
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      failure: {
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
    },
    miguru: {
      name: 'MiGuru',
      success: {
        subtitle: '¡Felicitaciones! Ya recibimos tu pago para realizar el análisis de tu curriculum con ',
        info: 'Accede al informe desde tu {{cv}}',
        buttonTitle: 'Ir al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      pending: {
        subtitle: 'Cuando se acredite, habilitaremos un enlace para realizar el análisis de tu Curriculum con ',
        info: 'Recordá usar el e-mail registrado en Bumeran para hacer el análisis.',
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      failure: {
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
    },
    doctorcv: {
      name: 'Doctor CV',
      success: {
        subtitle: '¡Felicitaciones! Ya recibimos tu pago para realizar el análisis de tu curriculum con ',
        info: 'Accede al informe desde tu {{cv}}',
        buttonTitle: 'Ir al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      pending: {
        subtitle: 'Cuando se acredite, habilitaremos un enlace para realizar el análisis de tu Curriculum con ',
        info: 'Recordá usar el e-mail registrado en Bumeran para hacer el análisis.',
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
      failure: {
        buttonTitle: 'Volver al {{cv}}',
        buttonRef: '/candidatos/curriculum',
      },
    },
  },
}
