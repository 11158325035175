import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const Placeholder = props => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={46}
        viewBox="0 0 100% 136"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="136" height="46" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default Placeholder
