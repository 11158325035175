export const GET_LISTADO_EMPRESAS_SEGUIDAS = 'GET_LISTADO_EMPRESAS_SEGUIDAS'
export const GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS = 'GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS'
export const GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR = 'GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR'

export interface GetListadoEmpresasSeguidasRequestDto {
  page: number
  pageSize?: number
}

// Obtener listado de empresas seguidas
export interface GetListadoEmpresasSeguidas {
  type: typeof GET_LISTADO_EMPRESAS_SEGUIDAS
  payload: GetListadoEmpresasSeguidasRequestDto
}

export interface GetListadoEmpresasSeguidasSuccess {
  type: typeof GET_LISTADO_EMPRESAS_SEGUIDAS_SUCCESS
  payload
}

export interface GetListadoEmpresasSeguidasError {
  type: typeof GET_LISTADO_EMPRESAS_SEGUIDAS_ERROR
  payload: null
}

interface Pagination {
  currentPage: number
  totalPages: number
  elementsPerPage: number
  totalElements: number
}

export interface EmpresasSeguidasState {
  empresasSeguidas: any[]
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
  pagination: Pagination
}

export const initialState: EmpresasSeguidasState = {
  empresasSeguidas: [],
  states: {
    loading: false,
    success: false,
    error: false,
  },
  pagination: {
    currentPage: 0,
    totalPages: 0,
    elementsPerPage: 10,
    totalElements: 0,
  },
}

export type ListadoEmpresasSeguidasActions =
  | GetListadoEmpresasSeguidas
  | GetListadoEmpresasSeguidasError
  | GetListadoEmpresasSeguidasSuccess
