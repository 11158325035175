const loginColectionPathNames = {
  aviso: 'Aviso',
  'empleos.html': 'Listado ',
  perfiles: 'Listado de empresas',
  salarios: 'Salarios',
  '': 'Home',
}

export const actionOrigin = ({ url }) => {
  const href = url?.href
  const searchParams = new URLSearchParams(href.split('?')[1])
  // De los param en la url buscamos obtener returnTo
  const returnTo = searchParams.get('returnTo')
  // Ahora limpiamos el valor obtenido. Indentificamos alguno de interés.
  const cleaReturn = returnTo
    ?.replace(/^\//, '')
    ?.replace(/^empleos\/.*\.html$/, 'aviso')
    ?.replace(/^perfiles\/.*\.html$/, 'perfiles')
    ?.replace(/^salarios\/.*\.html$/, 'salarios')

  // Si el string obtenido ecomienza "en-" no nos va a servir
  const regexValue = /^en-/
  const uslessParam = regexValue.test(searchParams.toString())
  const pathName = url?.pathname.split('/')[1]

  // si el param no no interesa retornamos pathName
  if (uslessParam) {
    return pathName
  }

  // si pathName es "" significa que volvimos a home
  if (pathName === '' || cleaReturn === '') {
    return 'Home'
  }

  if (loginColectionPathNames[pathName]) {
    return loginColectionPathNames[pathName]
  }

  // si cleaReturn concide con algun valor de loginColectionPathNames, lo retornamos
  if (cleaReturn && loginColectionPathNames[cleaReturn]) {
    return loginColectionPathNames[cleaReturn]
  }

  // si cleaReturn no existe en loginColectionPathNames,
  // todavía nos interesa, como último recurso retornamos pathName
  return cleaReturn ?? pathName
}
