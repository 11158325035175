import styled from 'styled-components'

export const Text = styled('p')`
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 24px;
  
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 48px;
  }
`
