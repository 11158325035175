import React from 'react'
import { useHistory } from 'react-router-dom'

import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

import FilterBar from './filter-bar'
import FiltersMobile from './filter-screen'

const FiltersSection = ({ filtros, filtersApplied }) => {
  const history = useHistory()
  const { isMobile, isTabletPortrait } = useWindowSize()

  return isMobile || isTabletPortrait ? (
    <FiltersMobile location={history.location} filters={filtros} filtersApplied={filtersApplied} />
  ) : (
    <FilterBar filters={filtros} filtersApplied={filtersApplied} />
  )
}

export default FiltersSection
