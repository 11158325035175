import { Request, Get } from '@navent-jobs/config'
import { isEmpty, sortBy, orderBy } from 'lodash'
import { ID_PAIS, NOMBRE_PAIS } from '../constants'
import { LicenciaDto, StaticOption } from '../redux/types/statics'

export class ErrorStatics extends Error {}

class Statics {
  public async GetEstadoCivil(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/estadosCiviles`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`estadosCiviles no encontradas`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetPaises(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/paises`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`paises no encontrados`)
      }
      const paises = sortBy(response, ['nombre']).filter(pais => pais.id !== ID_PAIS)
      paises.unshift({ id: ID_PAIS, nombre: NOMBRE_PAIS })
      return paises.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetProvincias(idPais: number): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/provincias/${idPais}`
    request.method = Get
    return request.call().then(response => {
      return orderBy(response, [option => option.nombre.toLowerCase()], ['asc']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetLocalidades(idProvincia: number): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/localidadesPorProvincia/${idProvincia}`
    request.method = Get
    return request.call().then(response => {
      return orderBy(response, [option => option.nombre.toLowerCase()], ['asc']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetTipoDocumento(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/tiposDocumento/${ID_PAIS}`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`documentos no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetGeneros(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/generos`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`generos no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetTipoLicencias(): Promise<LicenciaDto[]> {
    const request = new Request()
    request.path = `/api/tiposLicencias`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`tipos de licencias no encontrados`)
      }
      return response.map(opt => ({
        id: opt.id,
        denominacion: opt.denominacion,
      }))
    })
  }

  public async GetInstitucionesEducativas(paisId: string): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/institucionesEducativas/${paisId}`
    request.method = Get
    return request.call().then(response => {
      const insti = response
      return insti.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetTiposEstudio(paisId: string): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/tiposEstudio/${paisId}`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`Tipos de Estudio no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetAreasEstudio(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/areasEstudio`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`Areas de Estudio no encontrados`)
      }
      return sortBy(response, ['nombre']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetEstadosEstudio(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/estadosEstudio`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`Estados de Estudio no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetIdiomas(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/idiomas`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`idiomas no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetNivelesIdiomas(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/nivelesIdioma`
    request.method = Get
    return request.call().then(response => {
      if (isEmpty(response)) {
        throw new ErrorStatics(`niveles de idioma no encontrados`)
      }
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetAreas(idPais): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/areas/${idPais}`
    request.method = Get
    return request.call().then(response => {
      return sortBy(response, ['nombre']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetSubAreas(idArea): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/subAreas/${idArea}`
    request.method = Get
    return request.call().then(response => {
      return sortBy(response, ['nombre']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetIndustrias(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/industrias`
    request.method = Get
    return request.call().then(response => {
      return sortBy(response, ['nombre']).map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }

  public async GetNivelPuesto(): Promise<StaticOption[]> {
    const request = new Request()
    request.path = `/api/nivelesPuesto`
    request.method = Get
    return request.call().then(response => {
      return response.map(opt => ({
        value: opt.id,
        label: opt.nombre,
      }))
    })
  }
}

export default new Statics()
