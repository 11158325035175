import { post } from '@navent-jobs/config'

interface ErrorLogRequest {
  token: string
  message: string
  url: string
  query: string
  trace?: string
}

class LoggerService {
  public async logError(paramMessage, paramTrace?) {
    console.error(`Error: ${paramMessage}`)
    if (paramTrace) {
      console.error(paramTrace)
    }
    const parsedUrl = new URL(window.location.href)

    let errorLogReq: ErrorLogRequest = {
      token: 'dd825b14-8c85-4ed0-a01a-648df37e4f85',
      message: paramMessage,
      url: parsedUrl.pathname,
      query: parsedUrl.search,
    }
    if (paramTrace) {
      errorLogReq = { ...errorLogReq, trace: paramTrace }
    }
    post(`api/log/error`, errorLogReq).catch(e => console.error(`Error en logger service: ${e.message}`))
  }
}

const loggerService = new LoggerService()
export default loggerService
