import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'
import { IS_ZONA_JOBS } from '../../../../../../constants'
import { EmptyStateComponent, Image, TextContainer, EmptyStateContainer, Heading, Subtitle } from './mixins'

import EmptyStateListadoSVG from '../../../../../../assets/images/empty-state-listado.svg'
import EmptyStateListadoSVGZJ from '../../../../../../assets/images/empty-state-listado-zj.svg'

const ListadoSinResultados = () => {
  const { t } = useTranslation()

  return (
    <EmptyStateComponent>
      <EmptyStateContainer>
        <TextContainer>
          <Heading>
            <Text type="title" size="sm" fontWeight="semibold" variant="secondary" as="h3">
              {t('No encontramos lo que estás buscando')}
            </Text>
          </Heading>
          <Subtitle>{t('Prueba con otras palabras o asegúrate de que estén bien escritas')}</Subtitle>
        </TextContainer>
        <Image
          alt={t('No encontramos lo que estás buscando')}
          src={IS_ZONA_JOBS ? EmptyStateListadoSVGZJ : EmptyStateListadoSVG}
        />
      </EmptyStateContainer>
    </EmptyStateComponent>
  )
}

export default ListadoSinResultados
