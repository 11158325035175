import styled from 'styled-components'

export const Spacer = styled.div`
  margin: 80px 0;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 120px 0;
  }
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  padding: 48px 20px;
  border-radius: 6px;
  text-align: center;
  margin: 32px 0;
  border: 2px solid ${({ theme }) => theme.colors.grey16};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 64px 0;
    padding: 48px 0;
  }
`

export const CenterContainer = styled.div`
  text-align: center;
`
