import React, { ReactElement, useContext } from 'react'
import { ThemeContext } from 'styled-components'

// Tools
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// Components
import { Col, ContainerFluid, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// styles
import { HeaderContainer, CardWrapper, Title, CardContent, Page, TitleContainer } from './mixins'

interface PageTitleProps {
  iconName: string
  title: string
  maxWidth?: number
  children?: string | ReactElement
}

const PageTitle = ({ iconName, title, maxWidth, children }: PageTitleProps) => {
  const { isMobile } = useWindowSize()
  const { colors } = useContext<any>(ThemeContext)
  return (
    <Page>
      <HeaderContainer />
      <MainContainer>
        <ContainerFluid noGutter>
          <Row>
            <Col>
              <CardWrapper>
                <CardContent maxWidth={maxWidth} hasChildren={children}>
                  <TitleContainer>
                    <Icon name={iconName} color={colors.secondary.normal} size={isMobile ? '24' : '28'} />
                    <Title>{title}</Title>
                  </TitleContainer>
                  {children}
                </CardContent>
              </CardWrapper>
            </Col>
          </Row>
        </ContainerFluid>
      </MainContainer>
    </Page>
  )
}

export default PageTitle
