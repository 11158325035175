import styled from 'styled-components'

export const Page = styled('div')`
  background-color: white;
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`
