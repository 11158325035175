/* eslint-disable prettier/prettier */
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Row } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// components
import { SearchbarPlaceholder } from './components/placeholder'

// mixins
import { SearchBarContainer, TitleComponent, Label, CustomCol } from './mixins'

// redux
import CompanySearchBar from '../../../../components/search-bar/CompanySearchBar'

interface SearchBarProps {
  loading: boolean
  location: any
  filtersApplied?: any
  shotSearch?: any
}

const SearchBar = ({ loading, location }: SearchBarProps) => {
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [selected, setSelected] = useState<{ label: string; value: string } | undefined>()

  if (loading) {
    return <SearchbarPlaceholder />
  }

  return (
    <SearchBarContainer>
      <TitleComponent>
        <Icon name="icon-light-office" size="20" color={colors.secondary.normal} />
        <Label>{t('searchBarCompanies.title')}</Label>
      </TitleComponent>

      <Row>
        <CustomCol>
          <CompanySearchBar selected={selected} setSelected={setSelected} location={location} />
        </CustomCol>
      </Row>
    </SearchBarContainer>
  )
}

export default SearchBar
