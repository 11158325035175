import styled from 'styled-components'
import { Col } from '@navent-jobs/ui-kit/components/grid'

export const Page = styled('div')`
  background-color: white;
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const ColImage = styled(Col)`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    order: 1;
  }
`

export const ColForm = styled(Col)``
