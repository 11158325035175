const filtersCompanies = {
  enabled: true,
  deleteAll: true,
  filters: () => [
    {
      type: 'industry',
      label: 'Industria',
      icon: 'icon-light-layers',
      order: 10,
      optionSelected: false,
    },
    {
      type: 'province',
      label: 'Ubicación',
      icon: 'icon-light-location-pin',
      order: 20,
      optionSelected: false,
    },
  ],
}

export default filtersCompanies
