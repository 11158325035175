import i18n from 'i18next'
import { IS_ZONA_JOBS } from '.'

const errorPagesSettings = () => {
  const suffix = IS_ZONA_JOBS ? '-zj' : ''
  return {
    '404': {
      title: i18n.t('errorPages.404.title'),
      subtitle: i18n.t('errorPages.404.subtitle'),
      img: `error-page-404${suffix}.svg`,
      alt: 'Error-404',
    },
    '500': {
      title: i18n.t('errorPages.500.title'),
      subtitle: i18n.t('errorPages.500.subtitle'),
      img: `error-page-500${suffix}.svg`,
      alt: 'Error-500',
    },
    '503': {
      title: i18n.t('errorPages.503.title'),
      subtitle: i18n.t('errorPages.503.subtitle'),
      img: `error-page-503${suffix}.svg`,
      alt: 'Error-503',
    },
    '503-cloudfare': {
      title: i18n.t('errorPages.503-cloudfare.title'),
      subtitle: i18n.t('errorPages.503-cloudfare.subtitle'),
      img: `error-page-503${suffix}.svg`,
      alt: 'Error-503-cloudfare',
    },
  }
}

export default errorPagesSettings
