import {
  ActividadActions,
  ActividadState,
  initialState,
  REGISTRAR_ACTIVIDAD,
  REGISTRAR_ACTIVIDAD_ERROR,
  REGISTRAR_ACTIVIDAD_SUCCESS,
} from '../types/actividad'
import { status } from '../types/status'

const reducers = (state: ActividadState = initialState, action: ActividadActions): ActividadState => {
  switch (action.type) {
    case REGISTRAR_ACTIVIDAD:
      return {
        ...state,
        registrarActividad: {
          status: status.loading,
        },
      }
    case REGISTRAR_ACTIVIDAD_SUCCESS:
      return {
        ...state,
        registrarActividad: {
          status: status.success,
        },
      }
    case REGISTRAR_ACTIVIDAD_ERROR:
      return {
        ...state,
        registrarActividad: {
          status: status.error,
        },
      }
    default:
      return state
  }
}

export default reducers
