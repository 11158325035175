import { TagManager } from '@navent-jobs/config'

export const DataLayersReviews = {
  goToReview: from => {
    const action = from.includes('/perfiles/') ? 'Micrositio Empresa' : ' Ficha'
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: action,
      eventLabel: 'CTA calificar empresa - Empresa sin calificaciones',
    })
  },
  goToReviewReviewd: from => {
    const action = from.includes('/perfiles/') ? 'Micrositio Empresa' : ' Ficha'
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: action,
      eventLabel: 'CTA calificar empresa - Empresa con calificaciones',
    })
  },
  goToReviewFromBanner: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: 'Listado de empresas',
      eventLabel: 'NA',
    })
  },
  stepClick: (from, label, employee = false || null) => {
    let action
    switch (from) {
      case 'empresas':
        action = 'Pantalla de seleccion de empresa a calificar'
        break
      case 'calificacion':
        action = `Pantalla de calificacion de empresa ${employee ? '(Trabajó)' : '(No trabajó)'}`
        break
      case 'comentario':
        action = 'Pantalla de comentarios adicionales'
        break
      default:
    }
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: action,
      eventLabel: label,
    })
  },
  selectCompanyPrint: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: 'Pantalla de seleccion de empresa a calificar',
      eventLabel: 'Visualizacion',
    })
  },
  selectEmployeeOpt: employee => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: 'Trabajas o trabajaste en esta empresa',
      eventLabel: employee ? 'Trabajo o trabaje' : 'No trabaje',
    })
  },
  reviewCompanyPrint: employee => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: `Pantalla de calificacion de empresa ${employee ? '(Trabajó)' : '(No trabajó)'}`,
      eventLabel: 'Visualizacion',
    })
  },
  commentCompanyPrint: () => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: 'Pantalla de comentarios adicionales',
      eventLabel: 'Visualizacion',
    })
  },
  thankYouPrint: company => {
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Calificar Empresa',
      eventAction: 'Calificacion exitosa',
      eventLabel: company,
    })
  },
}
