import { makeCompanyURL } from '@navent-jobs/interlinking'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BASE_URL, NOMBRE_SITIO, NOMBRE_PAIS, OG_ID_BY_SITE } from '../../../../constants'

interface MetasProps {
  company?: {
    denominacion: string
    id: number
  }
}

const Metas = ({ company }: MetasProps) => {
  const { t } = useTranslation()
  const nombreEmpresa = company?.denominacion

  const metaTitle = `${t('companySummary.metaData.title')} ${nombreEmpresa} | ${NOMBRE_SITIO} ${NOMBRE_PAIS}`
  const metaDescription = `${t('companySummary.metaData.description.part1')} 
  ${nombreEmpresa} ${t('companySummary.metaData.description.part2')}
   ${nombreEmpresa} ${t('companySummary.metaData.description.part3')} ${NOMBRE_SITIO} ${NOMBRE_PAIS}`
  const metaCanonical = company && BASE_URL + makeCompanyURL(company)

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta property="og:title" content={metaTitle} />
      <meta name="description" property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={NOMBRE_SITIO} />
      <meta property="fb:app_id" content={OG_ID_BY_SITE[NOMBRE_SITIO]} />
      <meta property="og:url" content={metaCanonical} />
      <link rel="canonical" href={metaCanonical} />
    </Helmet>
  )
}

export default Metas
