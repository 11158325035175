import styled from 'styled-components'

export const CardWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey24};
  display: flex;
  justify-content: space-between;
`

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.ultraLighten};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  max-width: 120px;

  ${({ fullWidth }) =>
    fullWidth &&
    `
    min-width: 70px;
    margin-right: 4px;
  `}
`

export const IconColumn = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
  height: 100%;
`

export const TextContainer = styled.div`
  padding: 24px 16px;
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SpaceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  width: 18%;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    width: 15%;
  }
`

export const ImageCustom = styled.img`
  display: block;
  position: relative;

  ${({ theme, fullWidth }) =>
    fullWidth
      ? `
      width: 95px;
      height: 95px;
      right: -16px;
      margin: 16px 0;

      @media (min-width: ${theme.screen.smMin}) {
        width: 76px;
        height: 76px;
        margin: 0;
        left: -20px;
      }

      @media (min-width: ${theme.screen.lgMin}) {
        left: -37px;
        top: 50%;
        transform: translateY(-50%);
      }
    `
      : `
    padding: 4px 0;
    width: 84px;
    height: 84px;

    @media (min-width: ${theme.screen.lgMin}) {
      display: flex;
      position: absolute;
      right: 63px;
      top: 19px;
    }

    @media (min-width: ${theme.screen.xlMin}) {
      display: flex;
      position: absolute;
      right: 90px;
      top: 14px;
    }
    `}
`

export const CloseContainer = styled.div`
  cursor: pointer;
`
