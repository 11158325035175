import { status, StatusType } from './status'

export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR'

export const GET_CARROUSEL_COMPANIES = 'GET_CARROUSEL_COMPANIES'
export const GET_CARROUSEL_COMPANIES_SUCCESS = 'GET_CARROUSEL_COMPANIES_SUCCESS'
export const GET_CARROUSEL_COMPANIES_ERROR = 'GET_CARROUSEL_COMPANIES_ERROR'

export const GET_COMPANIES_RECOMMENDED = 'GET_COMPANIES_RECOMMENDED'
export const GET_COMPANIES_RECOMMENDED_SUCCESS = 'GET_COMPANIES_RECOMMENDED_SUCCESS'
export const GET_COMPANIES_RECOMMENDED_ERROR = 'GET_COMPANIES_RECOMMENDED_ERROR'

export const SET_FILTERS_SETTINGS_COMPANIES = 'SET_FILTERS_SETTINGS_COMPANIES'
export const SET_FILTERS_SETTINGS_COMPANIES_SUCCESS = 'SET_FILTERS_SETTINGS_COMPANIES_SUCCESS'
export const SET_FILTERS_SETTINGS_COMPANIES_ERROR = 'SET_FILTERS_SETTINGS_COMPANIES_ERROR'

export const SET_FILTERS_APPLIED = 'SET_FILTERS_APPLIED'
export const SET_FILTERS_APPLIED_SUCCESS = 'SET_FILTERS_APPLIED_SUCCESS'
export const SET_FILTERS_APPLIED_ERROR = 'SET_FILTERS_APPLIED_ERROR'

export interface CardCompany {
  companyId: number
  description: string
  industry: string
  jobPostingsQuantity: number
  logo: string
  name: string
  position?: number
  province: string
  reviewAverage?: number
}

interface Filter {
  query?: string | null
  industries?: number[] | null
  provinces?: number[] | null
  sortByRating: boolean
}

export interface GetCompaniesParams {
  page: number
  filtros: Filter
}

export interface GetCompaniesRecommendedParams {
  pageSize?: number
}

export interface GetCompaniesSuccessParams {
  companies: CardCompany[]
  filters: FilterCore[]
  pagination: Pagination
  totalEmpresas: number
  carrouselRecommended: boolean
}

export interface GetCarrouselCompaniesSuccessParams {
  companies: CardCompany[]
  totalEmpresas: number
}

export interface SeoWordings {
  description: string | null
  h1: string | null
  option: string | null
  title: string | null
  preh1?: string
}

export interface Pagination {
  currentPage: number
  totalPages: number
  elementsPerPage: number
  totalElements: number
}

export interface FiltersSettings {
  enabled: boolean
  deleteAll: boolean
  filters: {
    type: string
    label: string
    icon: string
    order: number
    disabled: boolean
  }
}

export interface FilterCore {
  type: string
  facets: {
    id: string
    name: string
    quantity: number
  }[]
  disabled?: boolean
}

export interface FiltersAppliedType {
  type: string
  facets: {
    id: string
    name: string
    quantity: number
  }
}

/**
 * Get Companies
 */
export interface GetCompanies {
  type: typeof GET_COMPANIES
  payload: GetCompaniesParams
}
export interface GetCompaniesSuccess {
  type: typeof GET_COMPANIES_SUCCESS
  payload: GetCompaniesSuccessParams
}

export interface GetCompaniesError {
  type: typeof GET_COMPANIES_ERROR
  payload: boolean
}

/**
 * Get Companies Most Wanted
 */
export interface GetCarrouselCompanies {
  type: typeof GET_CARROUSEL_COMPANIES
  payload: null
}
export interface GetCarrouselCompaniesSuccess {
  type: typeof GET_CARROUSEL_COMPANIES_SUCCESS
  payload: GetCompaniesSuccessParams
}

export interface GetCarrouselCompaniesError {
  type: typeof GET_CARROUSEL_COMPANIES_ERROR
  payload: boolean
}

/**
 * Get Companies Recommended
 */
export interface GetCompaniesRecommended {
  type: typeof GET_COMPANIES_RECOMMENDED
  payload: GetCompaniesRecommendedParams
}

export interface GetCompaniesRecommendedSuccess {
  type: typeof GET_COMPANIES_RECOMMENDED_SUCCESS
  payload: CardCompany[]
}

export interface GetCompaniesRecommendedError {
  type: typeof GET_COMPANIES_RECOMMENDED_ERROR
  payload: null
}

/**
 * Set Filters Settings Companies
 */
export interface SetFiltersSettingsCompanies {
  type: typeof SET_FILTERS_SETTINGS_COMPANIES
  payload: null
}

export interface SetFiltersSettingsCompaniesSuccess {
  type: typeof SET_FILTERS_SETTINGS_COMPANIES_SUCCESS
  payload: FiltersSettings
}

export interface SetFiltersSettingsCompaniesError {
  type: typeof SET_FILTERS_SETTINGS_COMPANIES_ERROR
  payload: null
}

/**
 * Set Filters Applied
 */
export interface SetFiltersApplied {
  type: typeof SET_FILTERS_APPLIED
  payload: FiltersAppliedType[]
}

export interface SetFiltersAppliedSuccess {
  type: typeof SET_FILTERS_APPLIED_SUCCESS
  payload: FiltersAppliedType[]
}

export interface SetFiltersAppliedError {
  type: typeof SET_FILTERS_APPLIED_ERROR
  payload: null
}

/**
 * Los states del fetch deberiamos ubicarlos en un nuevo reducer
 */

export interface ListadoEmpresasState {
  companies: CardCompany[] | null
  carrouselCompanies: CardCompany[] | null
  carrouselRecommended: boolean
  companiesRecommended: CardCompany[] | null
  cantidadEmpresas: boolean
  totalEmpresas: number
  filters: FilterCore[] | null
  filtersApplied: FiltersAppliedType[] | null
  filtersSettings: FiltersSettings | null
  pagination: Pagination
  seoWordings?: SeoWordings | null
  filterStates: StatusType
  companiesStates: StatusType
  carrouselCompaniesStates: StatusType
  companiesRecommendedStates: StatusType
}

export const initialState: ListadoEmpresasState = {
  companies: null,
  carrouselCompanies: null,
  carrouselRecommended: false,
  companiesRecommended: null,
  cantidadEmpresas: true,
  totalEmpresas: 0,
  filters: null,
  filtersApplied: [],
  filtersSettings: null,
  pagination: {
    currentPage: 0,
    totalPages: 0,
    elementsPerPage: 20,
    totalElements: 0,
  },
  seoWordings: {
    description: null,
    h1: null,
    option: null,
    title: null,
    preh1: undefined,
  },
  filterStates: status.initial,
  companiesStates: status.initial,
  carrouselCompaniesStates: status.initial,
  companiesRecommendedStates: status.initial,
}

export type ListadoEmpresasActions =
  | GetCompanies
  | GetCompaniesSuccess
  | GetCompaniesError
  | GetCarrouselCompanies
  | GetCarrouselCompaniesSuccess
  | GetCarrouselCompaniesError
  | GetCompaniesRecommended
  | GetCompaniesRecommendedSuccess
  | GetCompaniesRecommendedError
  | SetFiltersSettingsCompanies
  | SetFiltersSettingsCompaniesSuccess
  | SetFiltersSettingsCompaniesError
  | SetFiltersApplied
  | SetFiltersAppliedSuccess
  | SetFiltersAppliedError
