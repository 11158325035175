import React from 'react'
import { IS_ZONA_JOBS } from '../../../../../constants'
import ChangePasswordBumImage from '../../../../../assets/images/change-password.svg'
import ChangePasswordZonaImage from '../../../../../assets/images/change-password-zona.svg'
import { ComponentContainer, CustomImage } from './mixins'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage
        src={IS_ZONA_JOBS ? ChangePasswordZonaImage : ChangePasswordBumImage}
        width="414px"
        height="539px"
        alt="Change Password Image"
      />
    </ComponentContainer>
  )
}

export default Component
