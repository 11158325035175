import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

const selectStyles = `
transition: all 0.3s ease-in-out;
  .basic-single {
    position: static!important;
    
    .select__control{
      .select__value-container{
        padding: 0;
        line-height: 32px;
        font-size: 16px;
      }
    }

    .select__menu{
      left: 0px;
      top: calc( 100% + 1px );
      max-height: 196px;

      & > div {
        overflow: scroll;
        max-height: 196px;
      }
    }

    .select__indicators {
      i:before {
        background-color: #0A26EE;
      }
    }
  }
`
const FieldsContainers = `
  background-color: #FFFF;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
`
const FormActiveStyles = `
  outline: none;
    


  & > input{
    color: #000DF1;
  }

  .select__indicators {
    i:before {
      background-color: #3D47F5;
    }
  }
`

export const SearchbarContainer = styled.div`
  position: relative;
  z-index: 3;
  padding: 1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    justify-content: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    justify-content: center;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 376px;

  ${({ isActive }) => isActive && FormActiveStyles}
`

export const KeywordFieldContainer = styled.div`
  ${FieldsContainers}
  width: 100%;
  z-index: 1;

  ${selectStyles}
`

export const LocationFieldContainer = styled.div`
  ${FieldsContainers}
  width: 100%;
  ${selectStyles}
`

export const ButtonContainer = styled.div`
  width: 100%;
`

export const FieldIcon = styled(Icon)`
  margin-right: 16px;
`
