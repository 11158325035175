import { put, call, takeLatest, take } from 'redux-saga/effects'
import services from '../../services'
import { TIENE_CV, TIENE_CV_ERROR, TIENE_CV_SUCCESS } from '../types/applicant/curriculum/tiene-cv'
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SignIn,
  SignInGoogle,
  SIGN_IN_GOOGLE_ERROR,
  SIGN_IN_GOOGLE_SUCCESS,
  SIGN_IN_GOOGLE,
  SignInLinkedin,
  SIGN_IN_LINKEDIN_SUCCESS,
  SIGN_IN_LINKEDIN_ERROR,
  SIGN_IN_LINKEDIN,
} from '../types/sign-in'

function* signIn({ payload }: SignIn) {
  try {
    const result = yield call(services.auth.loginUser, payload)
    yield put({ type: TIENE_CV, payload: null })
    const action = yield take([TIENE_CV_SUCCESS, TIENE_CV_ERROR])
    if (action.type === TIENE_CV_SUCCESS) {
      yield put({ type: SIGN_IN_SUCCESS, payload: result })
    } else {
      yield put({ type: SIGN_IN_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: SIGN_IN_ERROR, error })
  }
}

function* signInGoogle({ payload }: SignInGoogle) {
  try {
    const result = yield call(services.auth.loginUserGoogle, payload)
    if (result) {
      yield put({ type: TIENE_CV, payload: null })
      const action = yield take([TIENE_CV_SUCCESS, TIENE_CV_ERROR])
      if (action.type === TIENE_CV_SUCCESS) {
        yield put({ type: SIGN_IN_GOOGLE_SUCCESS, payload: result })
      } else {
        yield put({ type: SIGN_IN_GOOGLE_ERROR, payload: null })
      }
    } else {
      yield put({ type: SIGN_IN_GOOGLE_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: SIGN_IN_GOOGLE_ERROR, error })
  }
}

function* signInLinkedin({ payload }: SignInLinkedin) {
  try {
    const result = yield call(services.auth.loginUserLinkedin, payload)
    if (result.token) {
      yield put({ type: TIENE_CV, payload: null })
      const action = yield take([TIENE_CV_SUCCESS, TIENE_CV_ERROR])
      if (action.type === TIENE_CV_SUCCESS) {
        yield put({ type: SIGN_IN_LINKEDIN_SUCCESS, payload: result })
      } else {
        yield put({ type: SIGN_IN_LINKEDIN_ERROR, payload: null })
      }
    } else {
      yield put({ type: SIGN_IN_LINKEDIN_SUCCESS, payload: result })
    }
  } catch (error) {
    yield put({ type: SIGN_IN_LINKEDIN_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest(SIGN_IN, signIn)
  yield takeLatest(SIGN_IN_GOOGLE, signInGoogle)
  yield takeLatest(SIGN_IN_LINKEDIN, signInLinkedin)
}
