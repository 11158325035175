import { status } from '../types/status'
import {
  LogoutActions,
  LogoutState,
  initialState,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_RESET,
} from '../types/logout'

const reducers = (state: LogoutState = initialState, action: LogoutActions): LogoutState => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        status: status.loading,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        status: status.success,
      }
    case LOGOUT_ERROR:
      return {
        ...state,
        status: status.error,
      }
    case LOGOUT_RESET:
      return {
        ...state,
        status: status.initial,
      }
    default:
      return state
  }
}

export default reducers
