import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ui-kit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'

import SocialShare from '../../../../components/SocialShare'
import { ComponentContainer, FlexBox, ButtonBack } from './mixins'

// utils
import { goBackOrHome } from '../../../../utils/navigation-util'
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'
import SeguirButton from '../seguir-button'

interface Args {
  shareData: {
    shareUrl: string | ''
    shareTitle: string | ''
    shareHelperDescription: string | ''
  }
  company: {
    denominacion?
    logoURL?
    id?
    gptwUrl?
    busquedaActiva?: boolean
  }
  isLoading: boolean
}

const Navbar = ({ shareData, company, isLoading }: Args) => {
  const { t } = useTranslation()
  const history = useHistory()

  const url = shareData?.shareUrl
  const title = shareData?.shareTitle
  const description = shareData?.shareHelperDescription

  const isMobileOrTabletPortrait = window.innerWidth < 1024

  if (!isMobileOrTabletPortrait) {
    return null
  }

  return (
    <ComponentContainer>
      <MainContainer>
        <Row>
          <Col>
            <FlexBox>
              <ButtonBack
                onClick={() => goBackOrHome(history)}
                buttonoptions={{ size: 'medium', type: 'link' }}
                icon={{ name: 'icon-light-arrow-left' }}
              />

              <FlexBox>
                <SeguirButton onlyIcon={true} empresaId={company?.id} isLoading={isLoading} />
                {/* Cta que habilita interfaz para compartir */}
                <SocialShare
                  shareUrl={url}
                  shareTitle={title}
                  shareHelperDescription={description}
                  modalTitle={t('share.modal.perfilEmpresa.title')}
                >
                  {t('share.labelButton')}
                </SocialShare>
              </FlexBox>
            </FlexBox>
          </Col>
        </Row>
      </MainContainer>
    </ComponentContainer>
  )
}

export default Navbar
