import { TagManager } from '@navent-jobs/config'

const actionsCollection = {
  editarAlerta: 'Editar alerta',
  eliminarAlerta: 'Eliminar alerta',
  activarAlerta: 'Activar alerta',
  desactivarAlerta: 'Desactivar alerta',
}
const handlefrecuencyName = idFrecuencia => {
  if (idFrecuencia === 0) {
    return 'Inmediata'
  }
  return 'Diaria'
}

export const DataLayer = {
  handlerCreateAlertTraking: ({
    idFrecuencia,
    area,
    subarea,
    localidad,
    provincia,
    tipoTrabajo,
    modalidadTrabajo,
    nivelLaboral,
    discapacidad,
  }) => {
    const emptyValue = 'NA'

    // created_alert
    return TagManager.Push({
      event: 'created_alert',
      item_id: handlefrecuencyName(idFrecuencia),
      area: area || emptyValue,
      subarea: subarea || emptyValue,
      ciudad: localidad || emptyValue,
      provincia: provincia || emptyValue,
      tipoPuesto: tipoTrabajo || emptyValue,
      modalidad: modalidadTrabajo || emptyValue,
      experiencia: nivelLaboral || emptyValue,
      aptoDiscapacitado: discapacidad ? 'si' : 'no',
    })
  },
  handlerAlertIntentionTraking: () => {
    // ver modal crear alerta
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado',
      eventAction: 'Alerta',
      eventLabel: 'Intencion',
    })
  },
  handlerPopoverAlertTraking: () => {
    // Pop over - Búsqueda demasiado ampliahandlerPopoverAlertTraking
    return TagManager.Push({
      event: 'impression_modal',
      modal_name: 'Alerta - Busqueda demasiado amplia',
    })
  },

  handlerShowModalAlertTraking: eventLabel => {
    // Visualización modal - Alerta repetida o máximo de alertas alcanzado
    return TagManager.Push({
      event: 'impression_modal',
      modal_name: `Alerta - ${eventLabel === 'DUPLICATE_ALERT' ? 'Alerta repetida' : 'Maximo de alertas alcanzado'}`,
    })
  },
  handlerConfigAlertTraking: action => {
    // Configuración de alertas
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Configuración de alertas',
      item_id: actionsCollection[action],
    })
  },
  handlerAlertDeletedTraking: () => {
    // Eliminar alerta confirmación
    return TagManager.Push({
      event: 'delete_alert',
    })
  },

  handlerAlertEditedTraking: frecuencia => {
    // Editar alerta
    return TagManager.Push({
      event: 'edit_alert',
      item_id: handlefrecuencyName(frecuencia),
    })
  },
}
