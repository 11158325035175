import React from 'react'
import { Iframe, Main } from './mixins'

interface EmbedVideoProps {
  videoUrl?: string
}

const EmbedVideo = ({ videoUrl }: EmbedVideoProps) => {
  return (
    <Main>
      <Iframe
        src={videoUrl}
        frameBorder="0"
        allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Main>
  )
}
export default EmbedVideo
