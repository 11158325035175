import styled from 'styled-components'

export const Card = styled('div')`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  width: 100%;
`

export const SubtitleText = styled('p')`
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.grey84};
  text-align: left;
`

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey24};
  margin: 8px 0;
`

export const InfoText = styled.p`
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey60};
`

export const Container = styled.div`
  & > :first-child {
    margin-bottom: 8px;
  }
`
