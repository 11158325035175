import React from 'react'
// Components
import { Checkbox } from '@navent-jobs/ui-kit/components/checkbox'

// tools
import { useTranslation } from 'react-i18next'
import { NOMBRE_SITIO, SITE_ID } from '../../../../constants'

// mixins
import { Reminder } from '../checkboxs/mixins'
import { ComponentContainer, CustomLink } from './mixins'

const NOT_BMEC = SITE_ID !== 'BMEC'

const TermsContent = () => {
  const { t } = useTranslation()

  return (
    <>
      {t('signUp.termsAndConditions.part1')}
      <CustomLink
        target="_blank"
        href="/terminos-y-condiciones"
        linkoptions={{
          native: false,
          size: 'small',
          type: 'link',
          variant: 'primary',
        }}
      >
        {t('signUp.termsAndConditions.part2')}
      </CustomLink>
      {NOT_BMEC && (
        <>
          {t('signUp.termsAndConditions.part3')}
          <CustomLink
            target="_blank"
            href="/politica-de-privacidad"
            linkoptions={{
              native: false,
              size: 'small',
              type: 'link',
              variant: 'primary',
            }}
          >
            {t('signUp.termsAndConditions.part4')}
          </CustomLink>
        </>
      )}
      de {NOMBRE_SITIO}. <Reminder />
    </>
  )
}

const TermsAndConditions = ({ setAcceptAccountCreation }) => {
  return (
    <ComponentContainer>
      <Checkbox
        id="accept-account-creation"
        name="accept-account-creation"
        label={<TermsContent />}
        onChange={e => setAcceptAccountCreation(e.currentTarget.checked)}
        fieldOptions={{
          size: 'small',
        }}
      />
    </ComponentContainer>
  )
}

export default TermsAndConditions
