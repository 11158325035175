import { Pagination } from 'swiper'

export const swiperSettings = totalSliders => {
  return {
    loop: true,
    speed: 600,
    slidesPerView: 1,
    spaceBetween: 8,
    pagination: {
      clickable: true,
    },
    modules: [Pagination],
    breakpoints: {
      1024: {
        enabled: false,
        slidesPerView: totalSliders,
        spaceBetween: 24,
      },
    },
  }
}
