import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { GET_LOCATIONS, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_ERROR } from '../types/locations'

function* getLocations({ payload }) {
  try {
    const result = yield call(services.locations.GetLocations)
    yield put({ type: GET_LOCATIONS_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_LOCATIONS_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* locations() {
  yield takeLatest<any>(GET_LOCATIONS, getLocations)
}
