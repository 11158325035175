import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import React from 'react'
import ContentLoader from 'react-content-loader'

const DesktopSkeleton = props => (
  <ContentLoader
    speed={2}
    width={684}
    height={215}
    viewBox="0 0 684 215"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="6" rx="4" ry="4" width="159" height="16" />
    <rect x="1" y="141" rx="2" ry="2" width="593" height="12" />
    <rect x="1" y="165" rx="2" ry="2" width="148" height="12" />
    <rect x="1" y="197" rx="2" ry="2" width="288" height="12" />
    <rect x="38" y="61" rx="2" ry="2" width="56" height="12" />
    <rect x="10" y="57" rx="10" ry="10" width="20" height="20" />
    <rect x="38" y="101" rx="2" ry="2" width="56" height="12" />
    <rect x="10" y="97" rx="10" ry="10" width="20" height="20" />
  </ContentLoader>
)

const MobileSkeleton = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={262}
    viewBox="0 0 100% 262"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="4" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="134" rx="2" ry="2" width="calc(100% - 16px)" height="12" />
    <rect x="0" y="158" rx="2" ry="2" width="calc(100% - 16px)" height="12" />
    <rect x="0" y="210" rx="2" ry="2" width="calc(100% - 16px)" height="12" />
    <rect x="0" y="182" rx="2" ry="2" width="148" height="12" />
    <rect x="0" y="234" rx="2" ry="2" width="205" height="12" />
    <rect x="38" y="59" rx="2" ry="2" width="56" height="12" />
    <rect x="10" y="55" rx="10" ry="10" width="20" height="20" />
    <rect x="38" y="99" rx="2" ry="2" width="56" height="12" />
    <rect x="10" y="95" rx="10" ry="10" width="20" height="20" />
  </ContentLoader>
)

export const SkeletonPrivacy = () => {
  const { isMobile } = useWindowSize()

  return isMobile ? <MobileSkeleton /> : <DesktopSkeleton />
}
