import React from 'react'
import { Section, Head, Body, Title } from './mixins'

type GraphSectionProps = React.PropsWithChildren<{
  title?: string
  loading: boolean
  bodyStyles?: any
}>

const Component = ({ children, title, loading, bodyStyles, ...props }: GraphSectionProps) => {
  return (
    <Section {...props}>
      {title && !loading && (
        <Head>
          <Title>{title}</Title>
        </Head>
      )}
      <Body style={bodyStyles}>{children}</Body>
    </Section>
  )
}

export default Component
