import {
  EDITAR_SUSCRIPCION,
  EDITAR_SUSCRIPCION_RESET,
  EditarSuscripcionType,
  EditarSuscripcion,
  EditartSuscripcionReset,
  GET_SUSCRIPCION,
  GetSuscripcion,
} from '../types/suscripcion'

export const getSuscriptionAction = (): GetSuscripcion => {
  return {
    type: GET_SUSCRIPCION,
    payload: null,
  }
}

export const editSuscriptionAction = (suscripcion: EditarSuscripcionType): EditarSuscripcion => {
  return {
    type: EDITAR_SUSCRIPCION,
    payload: suscripcion,
  }
}

export const resetEditSuscription = (): EditartSuscripcionReset => {
  return {
    type: EDITAR_SUSCRIPCION_RESET,
    payload: null,
  }
}
