import styled from 'styled-components'
import CardAviso from '../../../../components/card'

export const Heading = styled.h3`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  padding: 0 0 11px 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
    padding: 0 0 16px 0;
  }
`

export const Title = styled.span`
  font-weight: 600;
`

export const AvisoRecomendado = styled(CardAviso)`
  margin-bottom: 16px;
`

export const ContainerAvisosRecomendados = styled('div')`
  margin-top: 24px;
  margin-bottom: 8px;
`
