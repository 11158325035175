import {
  SET_SAVE_JOB_POSTING,
  SET_SAVE_JOB_POSTING_SUCCESS,
  SET_SAVE_JOB_POSTING_ERROR,
  DELETE_SAVE_JOB_POSTING,
  DELETE_SAVE_JOB_POSTING_SUCCESS,
  DELETE_SAVE_JOB_POSTING_ERROR,
  DELETE_SAVE_JOB_POSTING_RESET,
  SaveJobPostingAction,
  SaveJobPostingState,
  initialState,
  GET_AVISOS_GUARDADOS,
  GET_AVISOS_GUARDADOS_SUCCESS,
  GET_AVISOS_GUARDADOS_ERROR,
  SET_SAVE_JOB_POSTING_RESET,
} from '../types/save-job-posting'
import { status } from '../types/status'

const reducers = (state: SaveJobPostingState = initialState, action: SaveJobPostingAction): SaveJobPostingState => {
  switch (action.type) {
    case SET_SAVE_JOB_POSTING:
      return {
        ...state,
        setSaveJobPosting: {
          saved: action.payload,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case SET_SAVE_JOB_POSTING_SUCCESS:
      return {
        ...state,
        setSaveJobPosting: {
          saved: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case SET_SAVE_JOB_POSTING_ERROR:
      return {
        ...state,
        setSaveJobPosting: {
          saved: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case SET_SAVE_JOB_POSTING_RESET:
      return {
        ...state,
        setSaveJobPosting: {
          saved: null,
          states: status.initial,
        },
      }
    case DELETE_SAVE_JOB_POSTING:
      return {
        ...state,
        deleteSaveJobPosting: {
          saved: action.payload,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case DELETE_SAVE_JOB_POSTING_SUCCESS:
      return {
        ...state,
        deleteSaveJobPosting: {
          saved: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case DELETE_SAVE_JOB_POSTING_RESET:
      return {
        ...state,
        deleteSaveJobPosting: {
          ...state.deleteSaveJobPosting,
          states: status.initial,
        },
      }
    case DELETE_SAVE_JOB_POSTING_ERROR:
      return {
        ...state,
        deleteSaveJobPosting: {
          saved: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case GET_AVISOS_GUARDADOS:
      return {
        ...state,
        loading: true,
      }
    case GET_AVISOS_GUARDADOS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
      }
    case GET_AVISOS_GUARDADOS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducers
