import React from 'react'
import { useDispatch } from 'react-redux'
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Trans } from 'react-i18next'
import {
  Page,
  ImgCustom,
  Container,
  Title,
  Response,
  CustomIcon,
  CustomText,
  CustomContainer,
  DescriptionText,
  AnswerTitle,
  InfoText,
  InfoTextLink,
  CustomDiv,
  ImgLogo,
  Iframe,
} from './mixins'
import { productLandingTranslations } from '../../constants'

interface BecauseInterface {
  image: React.Component
  titleFirstPart: string
  logoImage?: string
  titleSecondPart?: string
  bulletSize?: string
  answers: { title?: string; answer: string; icon: string; iconColor: string }[]
  scrollRef?: string
  description?: string
  answerTitle?: string
  infoText?: string
  infoTextLink?: string
  infoTextLinkMetric?: () => void
  infoTextAction?: () => void
  infoTextFunction?: Function
  infoTextBold?: string
  infoTextSecondPart?: string
  imgHeight?: string
  imgHeightMobile?: string
  imgGutterMobile?: boolean
  transTextKey?: string
  videoUrl?: string
  customContainerStyles?: any
  customContainerMobileStyles?: any
  logoStyles?: any
  logoMobileStyles?: any
  noPaddingTop?: boolean
  noPaddingBottom?: boolean
}

const Component = ({ action = { primaryAction: () => {} }, productName, accessor = 'because' }) => {
  const {
    image,
    titleFirstPart,
    logoImage,
    titleSecondPart,
    bulletSize,
    answers,
    scrollRef,
    description,
    answerTitle,
    infoText,
    infoTextLink,
    infoTextAction,
    infoTextLinkMetric,
    infoTextFunction,
    infoTextBold,
    infoTextSecondPart,
    imgHeight,
    imgHeightMobile,
    imgGutterMobile,
    transTextKey,
    videoUrl,
    customContainerStyles,
    customContainerMobileStyles,
    logoStyles,
    logoMobileStyles,
    noPaddingTop,
    noPaddingBottom,
  }: BecauseInterface = productLandingTranslations()[productName][accessor]

  const dispatch = useDispatch()

  const onClick = () => {
    infoTextLinkMetric && infoTextLinkMetric()
    if (infoTextAction) {
      dispatch(infoTextAction())
    } else if (action?.primaryAction) {
      action.primaryAction()
    } else if (infoTextFunction) {
      infoTextFunction()
    }
  }

  return (
    <Page id={scrollRef || 'BECAUSE'} noPaddingTop={noPaddingTop} noPaddingBottom={noPaddingBottom}>
      <ContainerFluid noGutter>
        <Row noGutter>
          <Col noGutter>
            <CustomContainer styles={customContainerStyles} mobileStyles={customContainerMobileStyles}>
              {image && (
                <ImgCustom
                  src={image}
                  imgHeight={imgHeight}
                  imgHeightMobile={imgHeightMobile}
                  imgGutterMobile={imgGutterMobile}
                />
              )}
              {videoUrl && (
                <Iframe
                  src={videoUrl}
                  frameBorder="0"
                  allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              )}
              <Container>
                <Title>
                  {titleFirstPart}
                  <ImgLogo src={logoImage} styles={logoStyles} mobileStyles={logoMobileStyles} />
                  {titleSecondPart}
                </Title>
                {description && <DescriptionText>{description}</DescriptionText>}
                {transTextKey && (
                  <DescriptionText>
                    <Trans i18nKey={transTextKey} components={[<strong></strong>]} />
                  </DescriptionText>
                )}
                {answerTitle && <AnswerTitle>{answerTitle}</AnswerTitle>}
                {answers &&
                  answers.map(answer => {
                    return (
                      <Response key="answer">
                        <CustomIcon
                          name={answer.icon}
                          color={answer.iconColor || '#0A26EE'}
                          size={bulletSize || '20'}
                        />
                        <CustomText>
                          {answer.title && <strong>{answer.title}</strong>} {answer.answer}
                        </CustomText>
                      </Response>
                    )
                  })}
                {infoText && (
                  <InfoText>
                    {infoText}
                    {infoTextBold && <strong>{infoTextBold}</strong>}
                    {infoTextLink && (
                      <CustomDiv onClick={onClick}>
                        <InfoTextLink
                          linkoptions={{
                            native: false,
                            size: 'small',
                            type: 'link',
                            variant: 'primary',
                          }}
                        >
                          {infoTextLink}
                        </InfoTextLink>
                      </CustomDiv>
                    )}
                    {infoTextSecondPart}
                  </InfoText>
                )}
              </Container>
            </CustomContainer>
          </Col>
        </Row>
      </ContainerFluid>
    </Page>
  )
}

export default Component
