import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Card, SubtitleText, StrongText } from './mixins'
import NotEndedCard from '../not-ended'

const InfoCards = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const goToMisPostulaciones = () => {
    history.push('/postulantes/postulaciones')
  }

  return (
    <>
      <Card>
        <SubtitleText>
          {t('saveJobPosting.cards.postulaciones.text')}{' '}
          <StrongText onClick={goToMisPostulaciones}>{t('saveJobPosting.cards.postulaciones.strongText')}</StrongText>
        </SubtitleText>
      </Card>
      <NotEndedCard />
    </>
  )
}

export default InfoCards
