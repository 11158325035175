import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// components
import { Modal } from '@navent-jobs/ui-kit/components/modal'
import { DocumentoField } from './components/document-field'

// mixins
import { MainWrapper, ImgContrasenia, BannerDescription, Title, CtaContinuar, ButtonContainer } from './mixins'

// images
import srcContrasenia from '../../../../../../assets/images/test-personalidad/contrasenia.svg'

// store
import { hideModal } from '../../../../../../redux/actions/modal'
import { getTipoDocumento } from '../../../../../../redux/actions/statics'
import { StaticOption } from '../../../../../../redux/types/statics'

// constants
import { DataLayersLanguageTest } from '../../../../../../constants/gtm-events-language-test'
import { DataLayerAnalysisCv } from '../../../../../../constants/gtm-events-cv-analysis'
import { updateDocumento } from '../../../../../../redux/actions/applicant/applicant'
import { goToMP } from '../../../../../../utils/mercado-pago'
import { PaymentMPProductSKU } from '../../../../../../constants/payment-mp-product-sku'

interface ComponentProps {
  visible: boolean
  productName?: string
  optionsTipoDocumento?: StaticOption[]
  disponibilidad?: any
  updateDocumentoStatus?: any
}

const validateData = data => {
  return data.numeroDocumento && data.tipoDocumento?.value
}

const Component = ({
  visible,
  productName = '',
  optionsTipoDocumento,
  disponibilidad,
  updateDocumentoStatus,
}: ComponentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handlerGetTipoDocumento = useCallback(() => dispatch(getTipoDocumento()), [])
  const history = useHistory()
  const productSku = PaymentMPProductSKU[productName]
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const closeModal = () => {
    if (productName === 'MIGURU') {
      DataLayerAnalysisCv.closeModalDocument('Salir')
    }
    if (productName === 'DOCTORCV') {
      DataLayerAnalysisCv.closeModalDocument('Salir', 'Doctor CV')
    }
    if (productName === 'NULINGA') {
      DataLayersLanguageTest.closeDocumentModal('Salir')
    }
    dispatch(hideModal())
  }

  const updateCv = data => {
    if (validateData(data)) {
      setLoading(true)
      dispatch(
        updateDocumento({ documento: data.numeroDocumento, tipoDocumentoId: data.tipoDocumento.value, productSku })
      )
    }
  }

  const getLinkMp = () => {
    goToMP({ setLoadingMPLink: setLoading, dispatch, history, productName, disponibilidad })
  }

  const cancelModal = data => {
    if (productName === 'MIGURU') {
      DataLayerAnalysisCv.closeModalDocument('Aceptar')
    }
    if (productName === 'DOCTORCV') {
      DataLayerAnalysisCv.closeModalDocument('CTA Aceptar', 'Doctor CV')
    }
    if (productName === 'NULINGA') {
      DataLayersLanguageTest.closeDocumentModal('CTA Aceptar')
    }
    updateCv(data)
  }

  useEffect(() => {
    if (visible && productName === 'MIGURU') {
      DataLayerAnalysisCv.openModalDocument()
    }
    if (visible && productName === 'NULINGA') {
      DataLayersLanguageTest.openDocumentModal()
    }
  }, [visible])

  useEffect(() => {
    if (updateDocumentoStatus.success) {
      setLoading(false)
      getLinkMp()
    }
    if (updateDocumentoStatus.error) {
      setLoading(false)
    }
  }, [updateDocumentoStatus.success])

  return (
    <Modal width={456} visible={visible} onClose={closeModal}>
      <MainWrapper>
        <ImgContrasenia src={srcContrasenia} alt="Contraseña" />
        <Title>{t('products.modal.document.title')}</Title>
        <BannerDescription>{t('products.modal.document.description')}</BannerDescription>
        <form>
          <DocumentoField
            control={control}
            options={optionsTipoDocumento}
            handlerOptions={handlerGetTipoDocumento}
            errors={errors}
          />
          <ButtonContainer>
            <CtaContinuar
              onClick={e => {
                e.preventDefault()
                handleSubmit(cancelModal)()
              }}
              buttonoptions={{
                variant: 'primary',
                size: 'medium',
                type: 'fill',
              }}
              isLoading={loading}
            >
              {t('products.modal.document.continue')}
            </CtaContinuar>
          </ButtonContainer>
        </form>
      </MainWrapper>
    </Modal>
  )
}

const states = ({ modalStore, staticStore, productsStore, applicantStore }) => ({
  visible: modalStore.activeModal === 'modal-without-document-number',
  optionsTipoDocumento: staticStore.tipoDocumento,
  disponibilidad: productsStore?.productAvailability?.availability,
  updateDocumentoStatus: applicantStore?.updateDocumentoStatus,
})

export default connect(states)(Component)
