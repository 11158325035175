import React, { lazy, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

// ui-kit & mixins
import { MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import { useHistory, useLocation } from 'react-router-dom'
import { Page, ColImage, ColForm } from './mixins'

// components
import Heading from './components/Heading'
import TextBody from './components/TextBody'
import FormButton from './components/FormButton'
import Image from './components/Image'
import Metas from '../../components/metas/change-password'

import { sendEmailChangePassword } from '../../redux/actions/change-password'
import { ChangePasswordState } from '../../redux/types/change-password'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../components/PageLoader'))

const ChangePasswordEmailSent = ({ emailStatus }) => {
  const location = useLocation()
  const { user, email } = location.state ?? {}
  const dispatch = useDispatch()
  const history = useHistory()

  const onSubmit = () => {
    dispatch(
      sendEmailChangePassword({
        fetchMail: 'change-password',
        applicantMail: user,
      })
    )
  }

  useEffect(() => {
    if (!user) {
      history.push('/postulantes/recuperar-contrasena')
    }
  }, [])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader showBackButton={false}>
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <ColImage lg={6} xl={4} offset={{ xl: 2 }}>
                <Image />
              </ColImage>

              <ColForm lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading applicantEmail={email} />
                <TextBody />
                <FormButton onSave={() => onSubmit()} isLoading={emailStatus.loading} sent={emailStatus.success} />
              </ColForm>
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

const mapStateToProps = ({ changePasswordStore }: { changePasswordStore: ChangePasswordState }) => {
  return {
    emailStatus: changePasswordStore.sendMail.status,
  }
}

export default connect(mapStateToProps)(ChangePasswordEmailSent)
