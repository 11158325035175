import styled from 'styled-components'

export const Section = styled.div``

export const Head = styled.h3`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  padding: 0 0 11px 0;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
    padding: 0 0 16px 0;
  }
`

export const Title = styled.span`
  font-weight: 600;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
  }
`
