import React from 'react'
import { StepContent, StepItem } from './mixins'

interface StepIndicatorProps {
  steps: ReadonlyArray<string>
  currentStep: string
  startOnFirstStep?: boolean
}

export const StepIndicator = ({ currentStep, steps, startOnFirstStep = true }: StepIndicatorProps) => {
  const delta = startOnFirstStep ? 0 : 1
  const curr = steps.indexOf(currentStep) + 1 - delta
  const size = steps.length - delta
  return (
    <StepContent>
      {[...Array(size)].map((_, index) => {
        return <StepItem key={index} complete={index < curr}></StepItem>
      })}
    </StepContent>
  )
}
