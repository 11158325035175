const listadoEmpresas = {
  recommended: {
    notLoggedLabel: '¡Mirá todas las empresas que necesitan personal hoy!',
    subLabel: {
      part1: 'Iniciá sesión',
      part3: 'Registrate',
    },
  },
}

export default listadoEmpresas
