import styled from 'styled-components'

export const Page = styled('div')`
  background: ${({ theme }) => theme.colors.secondary.lighten};
  padding: 16px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 24px;
  }
`

export const Title = styled.p`
  margin: 0;
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Description = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: #fff;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`
