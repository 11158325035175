import { status, StatusType } from './status'

export const GET_RECOMENDADOS = 'GET_RECOMENDADOS'
export const GET_RECOMENDADOS_SUCCESS = 'GET_RECOMENDADOS_SUCCESS'
export const GET_RECOMENDADOS_ERROR = 'GET_RECOMENDADOS_ERROR'
export const GET_RECOMENDADOS_RESET = 'GET_RECOMENDADOS_RESET'

export const GET_RECOMENDADOS_LISTADO = 'GET_RECOMENDADOS_LISTADO'
export const GET_RECOMENDADOS_LISTADO_SUCCESS = 'GET_RECOMENDADOS_LISTADO_SUCCESS'
export const GET_RECOMENDADOS_LISTADO_ERROR = 'GET_RECOMENDADOS_LISTADO_ERROR'

export const GET_MOTIVOS_NO_INTERESADO = 'GET_MOTIVOS_NO_INTERESADO'
export const GET_MOTIVOS_NO_INTERESADO_SUCCESS = 'GET_MOTIVOS_NO_INTERESADO_SUCCESS'
export const GET_MOTIVOS_NO_INTERESADO_ERROR = 'GET_MOTIVOS_NO_INTERESADO_ERROR'

export const SET_NOT_INTERESTED = 'SET_NOT_INTERESTED'
export const SET_NOT_INTERESTED_SUCCESS = 'SET_NOT_INTERESTED_SUCCESS'
export const SET_NOT_INTERESTED_ERROR = 'SET_NOT_INTERESTED_ERROR'
export const SET_NOT_INTERESTED_RESET = 'SET_NOT_INTERESTED_RESET'

export interface GetRecomendadosParams {
  avisoId: number
  limit: number
  source: string
}

export interface SetNotInterestedParams {
  avisoId: number
  idMotivos: number[]
  otroMotivo: string
}

export interface GetRecomendadosResponse {
  labsParams: any
  avisos: any
}

export interface GetRecomendadosListadoParams {
  limit: number
  source: string
}

export interface GetRecomendadosListadoResponse {
  labsParams: any
  avisos: any
}

export interface GetMotivosNoInteresadoResponse {
  motivos: any
}

export interface GetRecomendados {
  type: typeof GET_RECOMENDADOS
  payload: GetRecomendadosParams
}

export interface GetRecomendadosSuccess {
  type: typeof GET_RECOMENDADOS_SUCCESS
  payload: GetRecomendadosResponse
}

export interface GetRecomendadosError {
  type: typeof GET_RECOMENDADOS_ERROR
  payload: null
}

export interface GetRecomendadosReset {
  type: typeof GET_RECOMENDADOS_RESET
  payload: null
}

export interface GetRecomendadosListado {
  type: typeof GET_RECOMENDADOS_LISTADO
  payload: GetRecomendadosListadoParams
}

export interface GetRecomendadosListadoSuccess {
  type: typeof GET_RECOMENDADOS_LISTADO_SUCCESS
  payload: GetRecomendadosListadoResponse
}

export interface GetRecomendadosListadoError {
  type: typeof GET_RECOMENDADOS_LISTADO_ERROR
  payload: null
}

export interface GetMotivosNoInteresado {
  type: typeof GET_MOTIVOS_NO_INTERESADO
  payload: null
}

export interface GetMotivosNoInteresadoSuccess {
  type: typeof GET_MOTIVOS_NO_INTERESADO_SUCCESS
  payload: GetMotivosNoInteresadoResponse
}

export interface GetMotivosNoInteresadoError {
  type: typeof GET_MOTIVOS_NO_INTERESADO_ERROR
  payload: null
}

export interface SetNotInterested {
  type: typeof SET_NOT_INTERESTED
  payload: SetNotInterestedParams
}

export interface SetNotInterestedSuccess {
  type: typeof SET_NOT_INTERESTED_SUCCESS
  payload: number
}

export interface SetNotInterestedError {
  type: typeof SET_NOT_INTERESTED_ERROR
  payload: number
}

export interface SetNotInterestedReset {
  type: typeof SET_NOT_INTERESTED_RESET
}

export interface RecomendadosState {
  avisos?: any | null
  labsParams?: any | null
  notInterestedId?: number | null
  motivosNoInteresado: any | null
  status: StatusType
  notInterestedStatus: StatusType
  motivosNoInteresadoStatus: StatusType
}

export const initialState: RecomendadosState = {
  avisos: null,
  labsParams: null,
  notInterestedId: null,
  motivosNoInteresado: null,
  status: status.initial,
  notInterestedStatus: status.initial,
  motivosNoInteresadoStatus: status.initial,
}

export type RecomendadosAction =
  | GetRecomendados
  | GetRecomendadosSuccess
  | GetRecomendadosError
  | GetRecomendadosReset
  | GetRecomendadosListado
  | GetRecomendadosListadoSuccess
  | GetRecomendadosListadoError
  | GetMotivosNoInteresado
  | GetMotivosNoInteresadoSuccess
  | GetMotivosNoInteresadoError
  | SetNotInterested
  | SetNotInterestedError
  | SetNotInterestedSuccess
  | SetNotInterestedReset
