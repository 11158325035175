import React from 'react'
import { Main } from './mixins'

interface SectionWrapperProps {
  children
}

const SectionWrapper = ({ children }: SectionWrapperProps) => {
  return <Main>{children}</Main>
}

export default SectionWrapper
