import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link/Link'

export const CustomLink = styled(Link)`
  min-width: 200px;
`

export const Title = styled.div`
  margin-bottom: 16px;
`
