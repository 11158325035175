import i18n from 'i18next'
import ImagePaymentSuccess from '../assets/images/pago-mp-success.svg'
import ImagePaymentPending from '../assets/images/pago-mp-pending.svg'
import ImagePaymentFailure from '../assets/images/pago-mp-failure.svg'

const paymentMPSettings = () => {
  return {
    products: {
      premium: {
        name: i18n.t('paymentMP.products.premium.name'),
        success: {
          title: i18n.t('paymentMP.successDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.premium.success.subtitle'),
          image: ImagePaymentSuccess,
          alt: 'Payment Success Image',
          buttonTitle: i18n.t('paymentMP.products.premium.success.buttonTitle'),
          buttonRef: i18n.t('paymentMP.products.premium.success.buttonRef'),
        },
        pending: {
          title: i18n.t('paymentMP.pendingDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.premium.pending.subtitle'),
          image: ImagePaymentPending,
          alt: 'Payment Pending Image',
          buttonTitle: i18n.t('paymentMP.products.premium.pending.buttonTitle'),
          buttonRef: i18n.t('paymentMP.products.premium.pending.buttonRef'),
        },
        failure: {
          title: i18n.t('paymentMP.failureDefaultTitle'),
          subtitle: i18n.t('paymentMP.failureDefaultSubTitle'),
          image: ImagePaymentFailure,
          alt: 'Payment Failure Image',
          buttonTitle: i18n.t('paymentMP.products.premium.failure.buttonTitle'),
          buttonRef: i18n.t('paymentMP.products.premium.failure.buttonRef'),
        },
      },

      testDePersonalidad: {
        name: i18n.t('paymentMP.products.testDePersonalidad.name'),
        success: {
          title: i18n.t('paymentMP.successDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.testDePersonalidad.success.subtitle'),
          image: ImagePaymentSuccess,
          alt: 'Payment Success Image',
          buttonTitle: i18n.t('paymentMP.products.testDePersonalidad.success.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.testDePersonalidad.success.buttonRef'),
        },
        pending: {
          title: i18n.t('paymentMP.pendingDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.testDePersonalidad.pending.subtitle'),
          image: ImagePaymentPending,
          alt: 'Payment Pending Image',
          buttonTitle: i18n.t('paymentMP.products.testDePersonalidad.pending.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.testDePersonalidad.pending.buttonRef'),
        },
        failure: {
          title: i18n.t('paymentMP.failureDefaultTitle'),
          subtitle: i18n.t('paymentMP.failureDefaultSubTitle'),
          image: ImagePaymentFailure,
          alt: 'Payment Failure Image',
          buttonTitle: i18n.t('paymentMP.products.testDePersonalidad.failure.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.testDePersonalidad.failure.buttonRef'),
        },
      },

      nulinga: {
        name: i18n.t('paymentMP.products.nulinga.name'),
        success: {
          title: i18n.t('paymentMP.successDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.nulinga.success.subtitle'),
          image: ImagePaymentSuccess,
          alt: 'Payment Success Image',
          buttonTitle: i18n.t('paymentMP.products.nulinga.success.buttonTitle'),
          buttonRef: i18n.t('paymentMP.products.nulinga.success.buttonRef'),
          checkLabel: i18n.t('paymentMP.products.nulinga.success.checkLabel'),
          emailCopyTitle: i18n.t('paymentMP.products.nulinga.success.emailCopyTitle'),
        },
        pending: {
          title: i18n.t('paymentMP.pendingDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.nulinga.pending.subtitle'),
          image: ImagePaymentPending,
          alt: 'Payment Pending Image',
          buttonTitle: i18n.t('paymentMP.products.nulinga.pending.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.nulinga.pending.buttonRef'),
          info: i18n.t('paymentMP.products.nulinga.pending.info'),
        },
        failure: {
          title: i18n.t('paymentMP.failureDefaultTitle'),
          subtitle: i18n.t('paymentMP.failureDefaultSubTitle'),
          image: ImagePaymentFailure,
          alt: 'Payment Failure Image',
          buttonTitle: i18n.t('paymentMP.products.nulinga.failure.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.nulinga.failure.buttonRef'),
        },
      },

      miguru: {
        name: i18n.t('paymentMP.products.miguru.name'),
        success: {
          title: i18n.t('paymentMP.successDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.miguru.success.subtitle'),
          image: ImagePaymentSuccess,
          alt: 'Payment Success Image',
          buttonTitle: i18n.t('paymentMP.products.miguru.success.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.miguru.success.buttonRef'),
          info: i18n.t('paymentMP.products.miguru.success.info', { cv: i18n.t('CV') }),
        },
        pending: {
          title: i18n.t('paymentMP.pendingDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.miguru.pending.subtitle'),
          image: ImagePaymentPending,
          alt: 'Payment Pending Image',
          buttonTitle: i18n.t('paymentMP.products.miguru.pending.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.miguru.pending.buttonRef'),
          info: i18n.t('paymentMP.products.miguru.pending.info'),
        },
        failure: {
          title: i18n.t('paymentMP.failureDefaultTitle'),
          subtitle: i18n.t('paymentMP.failureDefaultSubTitle'),
          image: ImagePaymentFailure,
          alt: 'Payment Failure Image',
          buttonTitle: i18n.t('paymentMP.products.miguru.failure.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.miguru.failure.buttonRef'),
        },
      },

      doctorcv: {
        name: i18n.t('paymentMP.products.doctorcv.name'),
        success: {
          title: i18n.t('paymentMP.successDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.doctorcv.success.subtitle'),
          image: ImagePaymentSuccess,
          alt: 'Payment Success Image',
          buttonTitle: i18n.t('paymentMP.products.doctorcv.success.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.doctorcv.success.buttonRef'),
          info: i18n.t('paymentMP.products.doctorcv.success.info', { cv: i18n.t('CV') }),
        },
        pending: {
          title: i18n.t('paymentMP.pendingDefaultTitle'),
          subtitle: i18n.t('paymentMP.products.doctorcv.pending.subtitle'),
          image: ImagePaymentPending,
          alt: 'Payment Pending Image',
          buttonTitle: i18n.t('paymentMP.products.doctorcv.pending.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.doctorcv.pending.buttonRef'),
          info: i18n.t('paymentMP.products.doctorcv.pending.info'),
        },
        failure: {
          title: i18n.t('paymentMP.failureDefaultTitle'),
          subtitle: i18n.t('paymentMP.failureDefaultSubTitle'),
          image: ImagePaymentFailure,
          alt: 'Payment Failure Image',
          buttonTitle: i18n.t('paymentMP.products.doctorcv.failure.buttonTitle', { cv: i18n.t('cv') }),
          buttonRef: i18n.t('paymentMP.products.doctorcv.failure.buttonRef'),
        },
      },
    },
  }
}

export default paymentMPSettings
