import { makeURL as makeURLV1, makeURLV2, parseURL as parseURLV1, parseURLV2 } from '@navent-jobs/interlinking'
import { SITE_ID } from '../constants'
import features from '../constants/features-per-country'

/**
 * Wrapper para usar la versión de las funciones de interlinking que correspondan
 * durante la migración al nuevo search de avisos
 */

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
export const makeURL = searchAvisosV2Enabled ? makeURLV2 : makeURLV1
export const parseURL = searchAvisosV2Enabled ? parseURLV2 : parseURLV1

const areasPorSubarea = {
  administracion: 'administracion-contabilidad-y-finanzas',
  finanzas: 'administracion-contabilidad-y-finanzas',
  consultoria: 'administracion-contabilidad-y-finanzas',
  contabilidad: 'administracion-contabilidad-y-finanzas',
  auditoria: 'administracion-contabilidad-y-finanzas',
  'control-de-gestion': 'administracion-contabilidad-y-finanzas',
  impuestos: 'administracion-contabilidad-y-finanzas',
  'finanzas-internacionales': 'administracion-contabilidad-y-finanzas',
  'organizacion-y-metodos': 'administracion-contabilidad-y-finanzas',
  'creditos-y-cobranzas': 'administracion-contabilidad-y-finanzas',
  'cuentas-corrientes': 'administracion-contabilidad-y-finanzas',
  'evaluacion-economica': 'administracion-contabilidad-y-finanzas',
  'inversiones-proyectos-de-inversion': 'administracion-contabilidad-y-finanzas',
  'planeamiento-economico-financiero': 'administracion-contabilidad-y-finanzas',
  tesoreria: 'administracion-contabilidad-y-finanzas',
  'analisis-de-riesgos': 'administracion-contabilidad-y-finanzas',
  'corporate-finance-banca-inversion': 'administracion-contabilidad-y-finanzas',
  facturacion: 'administracion-contabilidad-y-finanzas',

  'ventas-internacionales-exportacion': 'aduana-y-comercio-exterior',
  'apoderado-aduanal': 'aduana-y-comercio-exterior',
  'compras-internacionales-importacion': 'aduana-y-comercio-exterior',
  'consultorias-comercio-exterior': 'aduana-y-comercio-exterior',

  logistica: 'abastecimiento-y-logistica',
  distribucion: 'abastecimiento-y-logistica',
  compras: 'abastecimiento-y-logistica',
  abastecimiento: 'abastecimiento-y-logistica',
  'asistente-de-trafico': 'abastecimiento-y-logistica',
  transporte: 'abastecimiento-y-logistica',
  'almacen-deposito-expedicion': 'abastecimiento-y-logistica',

  arquitectura: 'ingenieria-civil-y-construccion',
  urbanismo: 'ingenieria-civil-y-construccion',
  construccion: 'ingenieria-civil-y-construccion',
  operaciones: 'ingenieria-civil-y-construccion',
  topografia: 'ingenieria-civil-y-construccion',
  instrumentacion: 'ingenieria-civil-y-construccion',
  'ingenieria-civil': 'ingenieria-civil-y-construccion',
  'direccion-de-obra': 'ingenieria-civil-y-construccion',
  'seguridad-e-higiene': 'ingenieria-civil-y-construccion',

  comercial: 'comercial-ventas-y-negocios',
  ventas: 'comercial-ventas-y-negocios',
  'comercio-exterior': 'comercial-ventas-y-negocios',
  'negocios-internacionales': 'comercial-ventas-y-negocios',
  'desarrollo-de-negocios': 'comercial-ventas-y-negocios',
  'planeamiento-comercial': 'comercial-ventas-y-negocios',

  'investigacion-y-desarrollo': 'educacion-docencia-e-investigacion',
  'educacion-docencia': 'educacion-docencia-e-investigacion',
  idiomas: 'educacion-docencia-e-investigacion',
  'educacion-especial': 'educacion-docencia-e-investigacion',
  'bienestar-estudiantil': 'educacion-docencia-e-investigacion',
  psicopedagogia: 'educacion-docencia-e-investigacion',

  gastronomia: 'gastronomia-y-turismo',
  turismo: 'gastronomia-y-turismo',
  hoteleria: 'gastronomia-y-turismo',
  casinos: 'gastronomia-y-turismo',
  camareros: 'gastronomia-y-turismo',

  'gerencia-direccion-general': 'gerencia-y-direccion-general',
  direccion: 'gerencia-y-direccion-general',

  legal: 'legales',
  'asesoria-legal-internacional': 'legales',
  garantias: 'legales',

  marketing: 'marketing-y-publicidad',
  creatividad: 'marketing-y-publicidad',
  multimedia: 'marketing-y-publicidad',
  'e-commerce': 'marketing-y-publicidad',
  'mercadotecnia-internacional': 'marketing-y-publicidad',
  'media-planning': 'marketing-y-publicidad',
  'community-management': 'marketing-y-publicidad',
  'business-intelligence': 'marketing-y-publicidad',
  producto: 'marketing-y-publicidad',

  produccion: 'produccion-y-manufactura',
  mantenimiento: 'produccion-y-manufactura',
  calidad: 'produccion-y-manufactura',
  'programacion-de-produccion': 'produccion-y-manufactura',

  'recursos-humanos': 'recursos-humanos-y-capacitacion',
  'capacitacion-comercio-exterior': 'recursos-humanos-y-capacitacion',
  seleccion: 'recursos-humanos-y-capacitacion',
  capacitacion: 'recursos-humanos-y-capacitacion',
  'compensacion-y-planilla': 'recursos-humanos-y-capacitacion',
  'administracion-de-personal': 'recursos-humanos-y-capacitacion',

  'relaciones-institucionales-publicas': 'comunicacion-relaciones-institucionales-y-publicas',
  comunicacion: 'comunicacion-relaciones-institucionales-y-publicas',
  periodismo: 'comunicacion-relaciones-institucionales-y-publicas',
  'comunicaciones-internas': 'comunicacion-relaciones-institucionales-y-publicas',
  'comunicaciones-externas': 'comunicacion-relaciones-institucionales-y-publicas',
  'responsabilidad-social': 'comunicacion-relaciones-institucionales-y-publicas',

  internet: 'tecnologia-sistemas-y-telecomunicaciones',
  'tecnologia-sistemas': 'tecnologia-sistemas-y-telecomunicaciones',
  'tecnologias-de-la-informacion': 'tecnologia-sistemas-y-telecomunicaciones',
  telecomunicaciones: 'tecnologia-sistemas-y-telecomunicaciones',
  'soporte-tecnico': 'tecnologia-sistemas-y-telecomunicaciones',
  'data-entry': 'tecnologia-sistemas-y-telecomunicaciones',
  'administracion-de-base-de-datos': 'tecnologia-sistemas-y-telecomunicaciones',
  'analisis-funcional': 'tecnologia-sistemas-y-telecomunicaciones',
  'liderazgo-de-proyecto': 'tecnologia-sistemas-y-telecomunicaciones',
  'data-warehousing': 'tecnologia-sistemas-y-telecomunicaciones',
  programacion: 'tecnologia-sistemas-y-telecomunicaciones',
  redes: 'tecnologia-sistemas-y-telecomunicaciones',
  'seguridad-informatica': 'tecnologia-sistemas-y-telecomunicaciones',
  sistemas: 'tecnologia-sistemas-y-telecomunicaciones',
  infraestructura: 'tecnologia-sistemas-y-telecomunicaciones',
  'testing-qa-qc': 'tecnologia-sistemas-y-telecomunicaciones',
  'analisis-de-datos': 'tecnologia-sistemas-y-telecomunicaciones',

  'practicas-profesionales': 'oficios-y-otros',
  otros: 'oficios-y-otros',
  'jovenes-profesionales': 'oficios-y-otros',
  planeamiento: 'oficios-y-otros',
  traduccion: 'oficios-y-otros',
  servicios: 'oficios-y-otros',
  independientes: 'oficios-y-otros',
  'arte-y-cultura': 'oficios-y-otros',
  'back-office': 'oficios-y-otros',
  cadeteria: 'oficios-y-otros',
  caja: 'oficios-y-otros',
  'mantenimiento-y-limpieza': 'oficios-y-otros',
  'oficios-y-profesiones': 'oficios-y-otros',
  'promotoras-es': 'oficios-y-otros',
  seguridad: 'oficios-y-otros',
  'trabajo-social': 'oficios-y-otros',
  'transporte-de-pasajeros': 'oficios-y-otros',
  'estetica-y-cuidado-personal': 'oficios-y-otros',
  'pasantia-trainee': 'oficios-y-otros',

  salud: 'salud-medicina-y-farmacia',
  laboratorio: 'salud-medicina-y-farmacia',
  farmaceutica: 'salud-medicina-y-farmacia',
  medicina: 'salud-medicina-y-farmacia',
  veterinaria: 'salud-medicina-y-farmacia',
  bioquimica: 'salud-medicina-y-farmacia',
  quimica: 'salud-medicina-y-farmacia',
  'anatomia-patologica-tecnicos': 'salud-medicina-y-farmacia',
  'anestesia-tecnicos': 'salud-medicina-y-farmacia',
  'auditoria-medica': 'salud-medicina-y-farmacia',
  'cosmiatra-dermocosmiatria': 'salud-medicina-y-farmacia',
  'diagnostico-por-imagenes': 'salud-medicina-y-farmacia',
  emergentologia: 'salud-medicina-y-farmacia',
  enfermeria: 'salud-medicina-y-farmacia',
  esterilizacion: 'salud-medicina-y-farmacia',
  fonoaudiologia: 'salud-medicina-y-farmacia',
  'hoteleria-hospitalaria': 'salud-medicina-y-farmacia',
  'instrumentacion-quirurgica': 'salud-medicina-y-farmacia',
  'kinesiologia-terapia-ocupacional': 'salud-medicina-y-farmacia',
  'medicina-laboral': 'salud-medicina-y-farmacia',
  'neurologia-tecnicos': 'salud-medicina-y-farmacia',
  nutricion: 'salud-medicina-y-farmacia',
  odontologia: 'salud-medicina-y-farmacia',
  'otras-areas-tecnicas-en-salud': 'salud-medicina-y-farmacia',
  'otras-especialidades-medicas': 'salud-medicina-y-farmacia',
  'practicas-cardiologicas': 'salud-medicina-y-farmacia',
  psicologia: 'salud-medicina-y-farmacia',
  'traslados-choferes-camilleros': 'salud-medicina-y-farmacia',
  'farmacia-comercial': 'salud-medicina-y-farmacia',
  'farmacia-hospitalaria': 'salud-medicina-y-farmacia',
  'farmacia-industrial': 'salud-medicina-y-farmacia',
  'prevencion-de-riesgo': 'salud-medicina-y-farmacia',

  telemarketing: 'atencion-al-cliente-call-center-y-telemarketing',
  'call-center': 'atencion-al-cliente-call-center-y-telemarketing',
  'atencion-al-cliente': 'atencion-al-cliente-call-center-y-telemarketing',

  secretaria: 'secretarias-y-recepcion',
  recepcionista: 'secretarias-y-recepcion',
  asistente: 'secretarias-y-recepcion',
  telefonista: 'secretarias-y-recepcion',

  seguros: 'seguros',
  'administracion-de-seguros': 'seguros',
  'venta-de-seguros': 'seguros',
  'tecnico-de-seguros': 'seguros',
  'auditoria-de-seguros': 'seguros',

  'seguridad-industrial': 'mineria-petroleo-y-gas',
  'medio-ambiente': 'mineria-petroleo-y-gas',
  'mineria-petroleo-gas': 'mineria-petroleo-y-gas',
  'ingenieria-en-petroleo-y-petroquimica': 'mineria-petroleo-y-gas',
  'ingenieria-en-minas': 'mineria-petroleo-y-gas',
  'exploracion-minera-y-petroquimica': 'mineria-petroleo-y-gas',
  'instrumentacion-minera': 'mineria-petroleo-y-gas',
  'ingenieria-geologica': 'mineria-petroleo-y-gas',

  'otras-ingenierias': 'ingenierias',
  'ingenieria-automotriz': 'ingenierias',
  'ingenieria-electrica-y-electronica': 'ingenierias',
  'ingenieria-textil': 'ingenierias',
  'ingenieria-mecanica': 'ingenierias',
  'ingenieria-industrial': 'ingenierias',
  'ingenieria-metalurgica': 'ingenierias',
  'ingenieria-quimica': 'ingenierias',
  'ingenieria-de-procesos': 'ingenierias',
  'ingenieria-de-producto': 'ingenierias',
  'ingenieria-de-ventas': 'ingenierias',
  'ingenieria-electromecanica': 'ingenierias',
  'ingenieria-en-alimentos': 'ingenierias',
  'ingenieria-oficina-tecnica-proyecto': 'ingenierias',
  'ingenieria-agronoma': 'ingenierias',

  diseno: 'diseno',
  'diseno-de-interiores-decoracion': 'diseno',
  'diseno-grafico': 'diseno',
  'diseno-industrial': 'diseno',
  'diseno-multimedia': 'diseno',
  'diseno-textil-e-indumentaria': 'diseno',
  'diseno-web': 'diseno',

  'central-de-emergencias': 'enfermeria',
  'clinica-medica': 'enfermeria',
  consultorios: 'enfermeria',
  'cuidados-intensivos-cardiologicos-adultos': 'enfermeria',
  hemodinamia: 'enfermeria',
  'internacion-domiciliaria': 'enfermeria',
  'internacion-general-pediatrica': 'enfermeria',
  maternidad: 'enfermeria',
  nefrologia: 'enfermeria',
  oncologia: 'enfermeria',
  'recien-nacido-sano': 'enfermeria',
  'recuperacion-de-anestesia': 'enfermeria',
  'terapia-intermedia-e-intensiva': 'enfermeria',
  'terapia-neonatal': 'enfermeria',
  vacunatorio: 'enfermeria',

  'gps-y-alarma-de-seguridad': 'departamento-tecnico',
  'naviero-maritimo-portuario': 'naviero-maritimo-portuario',
}

/**
 * Convierte una url con subarea a una con area + subarea.
 * Devuelve null si no se puede convertir.
 */
export const subareaRedirectURL = (url: string, match: any): string | null => {
  const filtros = parseURLV1(url, match)
  const filtrosV2 = parseURLV2(url, match)
  const { subarea } = filtros
  if (!subarea) {
    return null
  }

  const area = areasPorSubarea[subarea]
  if (!area) {
    return null
  }

  const subareaIdSemantico = `${area}|${subarea}`
  return makeURLV2({ ...filtrosV2, subarea: subareaIdSemantico, area })
}

/**
 * Chequea si la url tiene una subarea pero no tiene un area
 */
export const isLegacySubareaURL = (url: string): boolean => {
  return url.includes('-subarea-') && !url.includes('-area-')
}
