import {
  CreateAlert,
  CreateAlertReset,
  CREATE_ALERT,
  CREATE_ALERT_RESET,
  DeleteAlert,
  DeleteAlertReset,
  DELETE_ALERT,
  DELETE_ALERT_RESET,
  EditAlert,
  EditAlertReset,
  EDIT_ALERT,
  EDIT_ALERT_RESET,
  GetAlert,
  GET_ALERT,
  GET_ALERTAS_FRECUENCIA,
  SwitchAlert,
  SwitchAlertReset,
  SWITCH_ALERT,
  SWITCH_ALERT_RESET,
} from '../types/alert-v2'

export const getAlertasFrecuencia = () => {
  return {
    type: GET_ALERTAS_FRECUENCIA,
    payload: null,
  }
}

export const createAlertAction = (payload: CreateAlert['payload']): CreateAlert => {
  return {
    type: CREATE_ALERT,
    payload,
  }
}

export const createAlertResetAction = (): CreateAlertReset => {
  return {
    type: CREATE_ALERT_RESET,
    payload: null,
  }
}

export const getAlertAction = (): GetAlert => {
  return {
    type: GET_ALERT,
    payload: null,
  }
}

export const deleteAlertAction = (payload: DeleteAlert['payload']): DeleteAlert => {
  return {
    type: DELETE_ALERT,
    payload,
  }
}

export const resetDeleteAlert = (): DeleteAlertReset => {
  return {
    type: DELETE_ALERT_RESET,
    payload: null,
  }
}

export const editAlertAction = (payload: EditAlert['payload']): EditAlert => {
  return {
    type: EDIT_ALERT,
    payload,
  }
}

export const resetEditAlert = (): EditAlertReset => {
  return {
    type: EDIT_ALERT_RESET,
    payload: null,
  }
}

export const switchAlertAction = (payload: SwitchAlert['payload']): SwitchAlert => {
  return {
    type: SWITCH_ALERT,
    payload,
  }
}

export const resetSwitchAlert = (): SwitchAlertReset => {
  return {
    type: SWITCH_ALERT_RESET,
    payload: null,
  }
}
