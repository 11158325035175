const filters = {
  filtros: {
    'Salario Sin Especificar': 'Salario Sin Especificar',
    placeholderSearchFacet: {
      provincia: 'una Provincia',
      localidad: 'un Distrito',
    },
  },
}

export default filters
