import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../../services'
import {
  GET_PAYMENT_INVENTORY,
  GET_PAYMENT_INVENTORY_SUCCESS,
  GET_PAYMENT_INVENTORY_ERROR,
} from '../../types/applicant/inventory'

function* getPaymentInventory() {
  try {
    const result = yield call(services.paymentInventory.GetPaymentIventory)
    yield put({ type: GET_PAYMENT_INVENTORY_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PAYMENT_INVENTORY_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* paymentInventory() {
  yield takeLatest(GET_PAYMENT_INVENTORY, getPaymentInventory)
}
