import {
  GET_PAYMENT_INVENTORY,
  GET_PAYMENT_INVENTORY_SUCCESS,
  GET_PAYMENT_INVENTORY_ERROR,
  initialState,
  PaymentInventoryActions,
  PaymentInventoryState,
} from '../../types/applicant/inventory'

const reducers = (
  state: PaymentInventoryState = initialState,
  action: PaymentInventoryActions
): PaymentInventoryState => {
  switch (action.type) {
    case GET_PAYMENT_INVENTORY:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_PAYMENT_INVENTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_PAYMENT_INVENTORY_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
