import {
  SET_FILTERS,
  SET_FILTERS_SUCCESS,
  SET_FILTERS_ERROR,
  SET_FILTERS_SETTINGS,
  SET_FILTERS_SETTINGS_SUCCESS,
  SET_FILTERS_SETTINGS_ERROR,
  SET_FILTERS_DEFAULT,
  SET_FILTERS_DEFAULT_SUCCESS,
  SET_FILTERS_DEFAULT_ERROR,
  SET_FILTERS_DELETE_ALL,
  SET_FILTERS_DELETE_ALL_SUCCESS,
  SET_FILTERS_DELETE_ALL_ERROR,
  SET_FILTERS_HIDE,
  SET_FILTERS_HIDE_SUCCESS,
  SET_FILTERS_HIDE_ERROR,
  FiltersState,
  FiltersActions,
  initialState,
  SET_FILTERS_APPLIED,
} from '../types/filters'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: FiltersActions): FiltersState => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }
    case SET_FILTERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
      }
    case SET_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case SET_FILTERS_SETTINGS:
      return {
        ...state,
        error: false,
      }
    case SET_FILTERS_SETTINGS_SUCCESS:
      return {
        ...state,
        filtersSettings: {
          ...action.payload,
        },
        error: false,
      }
    case SET_FILTERS_SETTINGS_ERROR:
      return {
        ...state,
        filtersSettings: null,
        error: true,
      }
    case SET_FILTERS_DEFAULT:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case SET_FILTERS_DEFAULT_SUCCESS:
      return {
        ...state,
        filtersSettings: {
          ...state.filtersSettings,
          defaultFiltersApplied: { ...action.payload },
        },
        error: false,
      }
    case SET_FILTERS_DEFAULT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case SET_FILTERS_DELETE_ALL:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case SET_FILTERS_DELETE_ALL_SUCCESS:
      return {
        ...state,
        filtersSettings: {
          ...state.filtersSettings,
          deleteAll: action.payload,
        },
        error: false,
      }
    case SET_FILTERS_DELETE_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case SET_FILTERS_HIDE:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case SET_FILTERS_HIDE_SUCCESS:
      return {
        ...state,
        filtersSettings: {
          ...state.filtersSettings,
          filters: action.payload.filters,
        },
        error: false,
      }
    case SET_FILTERS_HIDE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case SET_FILTERS_APPLIED:
      return {
        ...state,
        filtersApplied: action.payload,
      }
    default:
      return state
  }
}

export default reducers
