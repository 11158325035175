import React from 'react'
import { useTranslation } from 'react-i18next'

// images
import logoApp from '../../../../assets/images/cta-logo-app.svg'

// mixins
import { ComponentContainer, Heading, ImageCustom, Title, ClaimText, IconCustom, Text } from './mixins'

const Component = () => {
  const { t } = useTranslation()

  return (
    <ComponentContainer>
      <Heading>
        <ImageCustom src={logoApp} alt="Logo App" />
        <div>
          <Title>{t('ctaDownloadApp.mobile.title')}</Title>
          <ClaimText>
            <IconCustom name="icon-light-download" size="16" color="#E90066" />
            <Text>{t('ctaDownloadApp.mobile.button.sidebar')}</Text>
          </ClaimText>
        </div>
      </Heading>
    </ComponentContainer>
  )
}

export default Component
