import {
  initialPrivacyState,
  PrivacyState,
  PrivacyActions,
  GET_PRIVACY,
  GET_PRIVACY_SUCCESS,
  GET_PRIVACY_ERROR,
  SET_PRIVACY,
  SET_PRIVACY_ERROR,
  SET_PRIVACY_SUCCESS,
  SET_PRIVACY_RESET,
} from '../../types/applicant/account/privacy'

const reducers = (state: PrivacyState = initialPrivacyState, action: PrivacyActions): PrivacyState => {
  switch (action.type) {
    case GET_PRIVACY:
      return {
        ...state,
        privacy: null,
        getStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_PRIVACY_SUCCESS:
      return {
        ...state,
        privacy: action.payload,
        getStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_PRIVACY_ERROR:
      return {
        ...state,
        privacy: null,
        getStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SET_PRIVACY:
      return {
        ...state,
        setStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case SET_PRIVACY_SUCCESS:
      return {
        ...state,
        privacy: action.payload,
        setStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case SET_PRIVACY_ERROR:
      return {
        ...state,
        setStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SET_PRIVACY_RESET:
      return {
        ...state,
        setStates: {
          loading: false,
          success: false,
          error: false,
        },
      }
    default:
      return state
  }
}

export default reducers
