import i18n from 'i18next'
import mejoraPerfilImage from '../../assets/images/banner-consejos/mejoraPerfil.svg'
import sumaConocimientosImage from '../../assets/images/banner-consejos/sumaConocimientos.svg'
import informacionActualizadaImage from '../../assets/images/banner-consejos/informacionActualizada.svg'
import experienciaImage from '../../assets/images/banner-consejos/experiencia.svg'
import mejoraPerfilImageZj from '../../assets/images/banner-consejos/mejoraPerfil-zj.svg'
import sumaConocimientosImageZj from '../../assets/images/banner-consejos/sumaConocimientos-zj.svg'
import informacionActualizadaImageZj from '../../assets/images/banner-consejos/informacionActualizada-zj.svg'
import experienciaImageZj from '../../assets/images/banner-consejos/experiencia-zj.svg'
import { IS_ZONA_JOBS } from '..'

export interface BannerConsejo {
  title: string
  description: string
  image: Element
  icon: string
}

export const BANNERS_CONSEJOS: BannerConsejo[] = [
  {
    title: i18n.t('bannerConsejos.mejoraPerfil.title'),
    description: i18n.t('bannerConsejos.mejoraPerfil.description'),
    image: IS_ZONA_JOBS ? mejoraPerfilImageZj : mejoraPerfilImage,
    icon: 'icon-light-checkmark-circle',
  },
  {
    title: i18n.t('bannerConsejos.sumaConocimientos.title'),
    description: i18n.t('bannerConsejos.sumaConocimientos.description'),
    image: IS_ZONA_JOBS ? sumaConocimientosImageZj : sumaConocimientosImage,
    icon: 'icon-light-flash-on',
  },
  {
    title: i18n.t('bannerConsejos.informacionActualizada.title'),
    description: i18n.t('bannerConsejos.informacionActualizada.description'),
    image: IS_ZONA_JOBS ? informacionActualizadaImageZj : informacionActualizadaImage,
    icon: 'icon-light-clock',
  },
  {
    title: i18n.t('bannerConsejos.experiencia.title'),
    description: i18n.t('bannerConsejos.experiencia.description'),
    image: IS_ZONA_JOBS ? experienciaImageZj : experienciaImage,
    icon: 'icon-light-edit-2',
  },
]

export const BANNERS_CONSEJOS_POSTULACIONES: BannerConsejo = {
  title: `Consejos: ${i18n.t('legales.advertenciaPagos')}`,
  description: i18n.t('legales.advertenciaPagosReclutador'),
  image: IS_ZONA_JOBS ? experienciaImageZj : experienciaImage,
  icon: 'icon-light-alert-triangle',
}
