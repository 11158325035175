import React from 'react'
import { Helmet } from 'react-helmet'

const Component = ({ step }) => {
  const stepTitle = {
    calificacion: 'Calificación de empresa',
    confirmacion: 'Gracias por calificar',
  }

  return (
    <Helmet>
      <title>{stepTitle[step]}</title>
    </Helmet>
  )
}

export default Component
