import {
  GET_JOB_POSTINGS_INFO,
  GET_JOB_POSTINGS_INFO_SUCCESS,
  GET_JOB_POSTING_INFO_ERROR,
  GET_NEW_FEATURES,
  GET_NEW_FEATURES_SUCCESS,
  GET_NEW_FEATURES_ERROR,
  HomePageActions,
  HomePageState,
  initialState,
  GET_TOTAL_AVISOS,
  GET_TOTAL_AVISOS_SUCCESS,
  GET_TOTAL_AVISOS_ERROR,
  GET_JOB_POSTINGS_HOME,
  GET_JOB_POSTINGS_HOME_SUCCESS,
  GET_JOB_POSTING_HOME_ERROR,
} from '../types/home-page'
import { status } from '../types/status'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: HomePageActions): HomePageState => {
  switch (action.type) {
    case GET_JOB_POSTINGS_INFO:
      return {
        ...state,
        areasCarousel: null,
        landingButtons: null,
        loading: true,
      }
    case GET_JOB_POSTINGS_INFO_SUCCESS:
      return {
        ...state,
        areasCarousel: { ...action.payload.areasCarousel },
        landingButtons: action.payload.landingButtons,
        loading: false,
        error: false,
      }
    case GET_JOB_POSTING_INFO_ERROR:
      return {
        ...state,
        areasCarousel: null,
        landingButtons: null,
        loading: false,
        error: true,
      }
    case GET_NEW_FEATURES:
      return {
        ...state,
        newFeatures: null,
        loading: true,
      }
    case GET_NEW_FEATURES_SUCCESS:
      return {
        ...state,
        newFeatures: [...action.payload],
        loading: false,
        error: false,
      }
    case GET_NEW_FEATURES_ERROR:
      return {
        ...state,
        newFeatures: null,
        loading: false,
        error: true,
      }
    case GET_TOTAL_AVISOS:
      return {
        ...state,
        totalAvisos: {
          ...state.totalAvisos,
          status: {
            loading: true,
            success: false,
            error: false,
          },
        },
      }
    case GET_TOTAL_AVISOS_SUCCESS:
      return {
        ...state,
        totalAvisos: {
          ...state.totalAvisos,
          value: action.payload,
          status: {
            loading: false,
            success: true,
            error: false,
          },
        },
      }
    case GET_TOTAL_AVISOS_ERROR:
      return {
        ...state,
        totalAvisos: {
          ...state.totalAvisos,
          status: {
            loading: false,
            success: false,
            error: true,
          },
        },
      }
    case GET_JOB_POSTINGS_HOME:
      return {
        ...state,
        jobPostingsHome: {
          states: status.loading,
        },
      }
    case GET_JOB_POSTINGS_HOME_SUCCESS:
      return {
        ...state,
        jobPostingsHome: {
          states: status.success,
          jobPostings: action.payload.avisos,
          filters: action.payload.filters,
        },
      }
    case GET_JOB_POSTING_HOME_ERROR:
      return {
        ...state,
        jobPostingsHome: {
          states: status.error,
        },
      }
    default:
      return state
  }
}

export default reducers
