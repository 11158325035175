const bannerConsejos = {
  mejoraPerfil: {
    title: 'Consejos: Mejora tu perfil profesional',
    description:
      'Tomate el tiempo necesario para completar cada campo de manera clara y brindar información de valor sobre tu perfil.',
  },
  sumaConocimientos: {
    title: 'Consejos: Suma tus conocimientos y habilidades',
    description:
      'Además de tus experiencias y estudios, agrega tanto tus conocimientos técnicos como tus cualidades relacionadas al desempeño de tu trabajo.',
  },
  informacionActualizada: {
    title: 'Consejos: Mantén tu información actualizada',
    description:
      'Si cambiaste de empleo o tus datos de contacto, ¡regístralo! Para los empleadores es esencial que tu perfil esté al día.',
  },
  experiencia: {
    title: 'Consejos: Describe tu experiencia',
    description:
      'Ofrece la mayor cantidad de detalle de las actividades que realizas o hayas realizado en tu puesto de trabajo.',
  },
}

export default bannerConsejos
