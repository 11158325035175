import React from 'react'
import { FooterContainer, Description } from './mixins'

interface ComponentProps {
  description: string | undefined
}

const Component = ({ description }: ComponentProps) => {
  if (!description) {
    return null
  }

  return (
    <FooterContainer>
      <Description>{description}</Description>
    </FooterContainer>
  )
}

export default Component
