import React, { useContext } from 'react'
import { CustomIframe, DataContainer, MapContainer, Spacer, TitleContainer } from './mixins'
import { ThemeContext } from 'styled-components'
import { useScreenSize } from '@navent-jobs/ui-kit/hooks/screen-size'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import Text from '@navent-jobs/ui-kit/components/text'
import { connect } from 'react-redux'

const GOOGLE_MAPS_KEY = window.GOOGLE_MAPS_KEY ?? process.env.REACT_APP_GOOGLE_MAPS_KEY

const Contact = ({ careerSite }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { isMobile } = useScreenSize()

  if (!careerSite?.contactInformation?.visible) {
    return null
  }

  const getLinkUrl = url => {
    if (url) {
      return url.includes('http://') || url.includes('https://') ? url : `https://${url}`
    }
    return ''
  }

  return (
    <Spacer>
      <MainContainer>
        <Row>
          <Col lg={12}>
            <TitleContainer>
              <Text type="title" size="sm" fontWeight="semibold">
                Información de contacto
              </Text>
            </TitleContainer>
          </Col>
          <Col offset={{ lg: 1 }} lg={10}>
            {careerSite.contactInformation.street &&
              careerSite.contactInformation.neighborhood &&
              careerSite.contactInformation.city &&
              careerSite.contactInformation.state && (
                <MapContainer>
                  <CustomIframe
                    src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_KEY}&q=${careerSite.contactInformation.street},${careerSite.contactInformation.neighborhood},${careerSite.contactInformation.city},${careerSite.contactInformation.state}`}
                    aria-hidden="false"
                    frameBorder="0"
                    allowFullScreen
                    title="Ubicación de empresa"
                  />
                </MapContainer>
              )}

            <Row>
              {careerSite.contactInformation.phone && (
                <Col xs={6} lg={4}>
                  <DataContainer>
                    <Icon name="icon-light-phone" size={'24'} color={colors.black84} />
                    {isMobile ? (
                      <Link linkoptions={{ variant: 'secondary' }} href={`tel:${careerSite.contactInformation.phone}`}>
                        {careerSite.contactInformation.phone}
                      </Link>
                    ) : (
                      <Text size="md">{careerSite.contactInformation.phone}</Text>
                    )}
                  </DataContainer>
                </Col>
              )}

              {careerSite.contactInformation.email && (
                <Col xs={6} lg={4}>
                  <DataContainer>
                    <Icon name="icon-light-email" size={'24'} color={colors.black84} />
                    {isMobile ? (
                      <Link
                        linkoptions={{ variant: 'secondary' }}
                        href={`mailto:${careerSite.contactInformation.email}`}
                      >
                        {careerSite.contactInformation.email}
                      </Link>
                    ) : (
                      <Text size="md">{careerSite.contactInformation.email}</Text>
                    )}
                  </DataContainer>
                </Col>
              )}

              {careerSite.contactInformation.web && (
                <Col xs={12} lg={4}>
                  <DataContainer>
                    <Icon name="icon-light-globe" size={'24'} color={colors.black84} />
                    {isMobile ? (
                      <Link
                        linkoptions={{ variant: 'secondary', target: '_blank' }}
                        href={getLinkUrl(careerSite.contactInformation.web)}
                      >
                        Ingresar a la web
                      </Link>
                    ) : (
                      <Text
                        size="md"
                        as="ins"
                        action={() => window.open(getLinkUrl(careerSite.contactInformation.web), '_blank')}
                      >
                        Ingresar a la web
                      </Text>
                    )}
                  </DataContainer>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </MainContainer>
    </Spacer>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore.careerSite,
})

export default connect(states)(Contact)
