import React from 'react'

import Header from './header'
import Footer from './footer'

import { GlobalStyles, Page } from './mixins'

const Component = props => {
  const { children } = props

  return (
    <>
      <GlobalStyles />

      <Page>
        <Header />
        {children}
        <Footer />
      </Page>
    </>
  )
}

export default Component
