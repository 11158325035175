export const empleosMasBuscados = {
  BMAR: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Operario',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-operario.html',
      },
      {
        label: 'Recepcionista',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-recepcionista.html',
      },
      {
        label: 'Chofer',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-chofer.html',
      },
      {
        label: 'Cajero',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-cajero.html',
      },
      {
        label: 'Atención al cliente',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-atencion-al-cliente.html',
      },
      {
        label: 'Limpieza',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-limpieza.html',
      },
      {
        label: 'Administrativo',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-administrativo.html',
      },
      {
        label: 'Vendedora',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-vendedora.html',
      },
      {
        label: 'Logística',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-logistica.html',
      },
      {
        label: 'Repositor',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-repositor.html',
      },
      {
        label: 'Administración',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-administracion.html',
      },
      {
        label: 'Enfermera',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-enfermera.html',
      },
      {
        label: 'Farmacia',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-farmacia.html',
      },
      {
        label: 'Secretaria',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-secretaria.html',
      },
      {
        label: 'Recursos Humanos',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-recursos-humanos.html',
      },
      {
        label: 'Vigilador',
        url: 'https://www.bumeran.com.ar/empleos-busqueda-vigilador.html',
      },
    ],
  },
  BMCL: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Abogado',
        url: 'https://www.laborum.cl/empleos-busqueda-abogado.html',
      },
      {
        label: 'Administrativo',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-administrativo.html',
      },
      {
        label: 'Reponedor',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-reponedor.html',
      },
      {
        label: 'Cajera',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-cajera.html',
      },
      {
        label: 'Chofer',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-chofer.html',
      },
      {
        label: 'Vendedor',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-vendedor.html',
      },
      {
        label: 'Call-Center',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-call-center.html',
      },
      {
        label: 'Enfermera',
        url: 'https://www.laborum.cl/empleos-busqueda-enfermera.html',
      },
      {
        label: 'Gerente',
        url: 'https://www.laborum.cl/empleos-busqueda-gerente.html',
      },
      {
        label: 'Secretaria',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-secretaria.html',
      },
      {
        label: 'Vendedora',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-vendedora.html',
      },
      {
        label: 'Recepcionista',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-recepcionista.html',
      },
      {
        label: 'Logística',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-logistica.html',
      },
      {
        label: 'Recursos Humanos',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-recursos-humanos.html',
      },
      {
        label: 'Psicólogo',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-psicologo.html',
      },
      {
        label: 'Operario',
        url: 'https://www.laborum.cl/en-region-metropolitana/empleos-busqueda-operario.html',
      },
    ],
  },
  BMPE: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Recursos Humanos',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-recursos-humanos.html',
      },
      {
        label: 'Practicante',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-practicante.html',
      },
      {
        label: 'Asistente Administrativo',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-asistente-administrativo.html',
      },
      {
        label: 'Marketing',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-marketing.html',
      },
      {
        label: 'Analista',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-analista.html',
      },
      {
        label: 'Contador',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-contador.html',
      },
      {
        label: 'Compras',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-compras.html',
      },
      {
        label: 'Logística',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-logistica.html',
      },
      {
        label: 'Atención al Cliente',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-atencion-al-cliente.html',
      },
      {
        label: 'Ingeniero civil',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-ingeniero-civil.html',
      },
      {
        label: 'Gerente',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-gerente.html',
      },
      {
        label: 'Minería',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-mineria.html',
      },
      {
        label: 'Docente',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-docente.html',
      },
      {
        label: 'Abogado',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-abogado.html',
      },
      {
        label: 'Administración',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-administracion.html',
      },
      {
        label: 'Psicólogo',
        url: 'https://www.bumeran.com.pe/empleos-busqueda-psicologo.html',
      },
    ],
  },
  BMEC: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Chofer',
        url: 'https://www.multitrabajos.com/empleos-busqueda-chofer.html',
      },
      {
        label: 'Atención al cliente',
        url: 'https://www.multitrabajos.com/empleos-busqueda-atencion-al-cliente.html',
      },
      {
        label: 'Mantenimiento',
        url: 'https://www.multitrabajos.com/empleos-busqueda-mantenimiento.html',
      },
      {
        label: 'Abogado',
        url: 'https://www.multitrabajos.com/empleos-busqueda-abogado.html',
      },
      {
        label: 'Docente',
        url: 'https://www.multitrabajos.com/empleos-busqueda-docente.html',
      },
      {
        label: 'Médico',
        url: 'https://www.multitrabajos.com/empleos-busqueda-medico.html',
      },
      {
        label: 'Asistente',
        url: 'https://www.multitrabajos.com/empleos-busqueda-asistente.html',
      },
      {
        label: 'Gerente',
        url: 'https://www.multitrabajos.com/empleos-busqueda-gerente.html',
      },
      {
        label: 'Marketing',
        url: 'https://www.multitrabajos.com/empleos-busqueda-marketing.html',
      },
      {
        label: 'Seguridad',
        url: 'https://www.multitrabajos.com/empleos-busqueda-seguridad.html',
      },
      {
        label: 'Cajero',
        url: 'https://www.multitrabajos.com/empleos-busqueda-cajero.html',
      },
      {
        label: 'Ingeniero civil',
        url: 'https://www.multitrabajos.com/empleos-busqueda-ingeniero-civil.html',
      },
      {
        label: 'Arquitecto',
        url: 'https://www.multitrabajos.com/empleos-busqueda-arquitecto.html',
      },
      {
        label: 'Limpieza',
        url: 'https://www.multitrabajos.com/empleos-busqueda-limpieza.html',
      },
      {
        label: 'Ventas',
        url: 'https://www.multitrabajos.com/empleos-busqueda-ventas.html',
      },
      {
        label: 'Recepcionista',
        url: 'https://www.multitrabajos.com/empleos-busqueda-recepcionista.html',
      },
    ],
  },
  BMPA: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Ayudante general',
        url: 'https://www.konzerta.com/empleos-busqueda-ayudante-general.html',
      },
      {
        label: 'Cajera',
        url: 'https://www.konzerta.com/empleos-busqueda-cajera.html',
      },
      {
        label: 'Logística',
        url: 'https://www.konzerta.com/empleos-busqueda-logistica.html',
      },
      {
        label: 'Recursos humanos',
        url: 'https://www.konzerta.com/empleos-busqueda-recursos-humanos.html',
      },
      {
        label: 'Chofer',
        url: 'https://www.konzerta.com/empleos-busqueda-chofer.html',
      },
      {
        label: 'Conductor',
        url: 'https://www.konzerta.com/empleos-busqueda-conductor.html',
      },
      {
        label: 'Compras',
        url: 'https://www.konzerta.com/empleos-busqueda-compras.html',
      },
      {
        label: 'Asistente',
        url: 'https://www.konzerta.com/empleos-busqueda-asistente.html',
      },
      {
        label: 'Call-Center',
        url: 'https://www.konzerta.com/empleos-busqueda-call-center.html',
      },
      {
        label: 'Atención al cliente',
        url: 'https://www.konzerta.com/empleos-busqueda-atencion-al-cliente.html',
      },
      {
        label: 'Ventas',
        url: 'https://www.konzerta.com/empleos-busqueda-ventas.html',
      },
      {
        label: 'Abogado',
        url: 'https://www.konzerta.com/empleos-busqueda-abogado.html',
      },
      {
        label: 'Recepcionista',
        url: 'https://www.konzerta.com/empleos-busqueda-recepcionista.html',
      },
      {
        label: 'Seguridad',
        url: 'https://www.konzerta.com/empleos-busqueda-seguridad.html',
      },
      {
        label: 'Contador',
        url: 'https://www.konzerta.com/empleos-busqueda-contador.html',
      },
      {
        label: 'Mecánico',
        url: 'https://www.konzerta.com/empleos-busqueda-mecanico.html',
      },
    ],
  },
  ZJAR: {
    title: 'Puestos más buscados',
    links: [
      {
        label: 'Recepcionista',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-recepcionista.html',
      },
      {
        label: 'Operario',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-operario.html',
      },
      {
        label: 'Vendedora',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-vendedora.html',
      },
      {
        label: 'Abogado',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-abogado.html',
      },
      {
        label: 'Administrativa',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-administrativa.html',
      },
      {
        label: 'Administrativo',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-administrativo.html',
      },
      {
        label: 'Chofer',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-chofer.html',
      },
      {
        label: 'Atención al cliente',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-atencion-al-cliente.html',
      },
      {
        label: 'Secretaria',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-secretaria.html',
      },
      {
        label: 'Cajera',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-cajera.html',
      },
      {
        label: 'Logística',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-logistica.html',
      },
      {
        label: 'Recursos Humanos',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-recursos-humanos.html',
      },
      {
        label: 'Gerente',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-gerente.html',
      },
      {
        label: 'Comercio Exterior',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-comercio-exterior.html',
      },
      {
        label: 'Limpieza',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-limpieza.html',
      },
      {
        label: 'Vendedor',
        url: 'https://www.zonajobs.com.ar/empleos-busqueda-vendedor.html',
      },
    ],
  },
}
