import React from 'react'
import { useDispatch } from 'react-redux'
import { MainContainer, ButtonImg, LinkedInButton } from './mixins'
import linkedinImg from '../../../../assets/images/boton-linkedin.svg'
import { signInLinkedinAction } from '../../../../redux/actions/sign-in'

const LINKEDIN_CLIENT_ID = window.LINKEDIN_CLIENT_ID ?? process.env.REACT_APP_LINKEDIN_CLIENT_ID

export const LinkedinLoginButton = () => {
  const dispatch = useDispatch()

  return (
    <MainContainer>
      <LinkedInButton
        clientId={LINKEDIN_CLIENT_ID}
        scope="openid profile email"
        redirectUri={`${window.location.origin}/linkedin`}
        onSuccess={res => dispatch(signInLinkedinAction({ code: res.code }))}
      >
        <ButtonImg src={linkedinImg} alt="Log in with Linked In" />
      </LinkedInButton>
    </MainContainer>
  )
}
