const signUp = {
  title: 'Crea tu cuenta y encuentra tu empleo ideal',
  titleNeutro: 'Crea tu cuenta en {{portal}} y encuentra tu empleo ideal',
  ingresaNombre: 'Ingresa tu nombre',
  ingresaApellido: 'Ingresa tu apellido',
  ingresaEmail: 'Ingresa tu email',
  ingresaContrasena: 'Ingresa tu contraseña',
  'Ingresá un número': 'Ingresa un número',
  conCuenta: '¿Ya tienes cuenta?',
  ingresa: 'Ingresa como candidato',
  linkEmpresas: 'Regístrate como empresa',
  'Ingresá al menos 6 caracteres': 'Ingresa al menos 6 caracteres',
  'Ingresá un nombre válido': 'Ingresa un nombre válido',
  'Ingresá un apellido válido': 'Ingresa un apellido válido',
  'Ingresá un email válido': 'Ingresa un email válido',
  'Ingresá una contraseña válida': 'Ingresa una contraseña válida',
  'El nombre debe tener al menos 1 carácter': 'El nombre debe tener al menos 1 carácter',
  'El nombre excede el límite permitido': 'El nombre excede el límite permitido',
  'El apellido debe tener al menos 1 carácter': 'El apellido debe tener al menos 1 carácter',
  'El apellido excede el límite permitido': 'El apellido excede el límite permitido',

  documento: {
    tipo: {
      placeholder: 'Tipo',
      label: 'Tipo de documento',
    },
    numero: {
      placeholder: 'Número',
    },
  },
  telefono: {
    placeholder: 'Número',
    label: 'Teléfono celular',
    format: 'Ingresa un teléfono válido.',
  },

  termsAndConditions: {
    part1: 'Acepto las',
    part2: 'Condiciones de uso',
    part3: 'y las',
    part4: 'Políticas de privacidad',
  },

  newsAndPromotions: 'Acepto recibir',
  politicaDatosPersonales: 'He leído y comprendo la',
  googleDataLoaded: 'Migramos tus datos de Google',
  linkedinDataLoaded: 'Migramos tus datos de Linkedin',
}

export default signUp
