import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import { Row, Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'
import ModalCodeCoderhouse from '../../../../components/modals/code-coderhouse'
import { getUrlParamByName } from '../../../../hooks/get-url-param'

// mixins
import { Title, Section } from './mixins'

// images
import backgroundWaves from '../../../../assets/images/background-waves-grey.svg'

// redux
import { showModal } from '../../../../redux/actions/modal'
import ProductBanner from '../../../../components/product-banner'
import { DataLayersCoderhouse } from '../../../../constants/gtm-events-coderhouse'

const FormSection = ({ isUserLoggedIn }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const modalCoderhouse = getUrlParamByName('modalCoderhouse')
  const returnPath = `${window.location.pathname}?modalCoderhouse=true`

  const handleModal = () => {
    if (isUserLoggedIn) {
      DataLayersCoderhouse.clickCard('Marketplace', true)
      dispatch(showModal('modal-code-coderhouse'))
    } else {
      DataLayersCoderhouse.clickCard('Marketplace', false)
      history.push(`/login?returnTo=${returnPath}`)
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (isUserLoggedIn && modalCoderhouse) {
      dispatch(showModal('modal-code-coderhouse'))
    }
  }, [isUserLoggedIn])

  return (
    <Section bkg={backgroundWaves} id="banner">
      <MainContainer>
        <Row>
          <Col>
            <Title>{t('products.banner.title')}</Title>
            <ProductBanner selectedProduct="CODERHOUSE" colSizeXl={12} verticalSize="lg" action={handleModal} />
          </Col>
        </Row>
      </MainContainer>
      <ModalCodeCoderhouse />
    </Section>
  )
}

export default FormSection
