export default {
  title: 'Privacidad de mi {{cv}}',
  deleteAccount: {
    title: 'Eliminar mi cuenta',
    subtitle:
      'Si eliminas tu cuenta de {{nombre_portal}}, no podrás postularte a más ofertas de trabajo ni revisar tus postulaciones.',
    password: {
      placeholder: 'Ingresa tu contraseña',
      checkPassword: 'Revisa tu contraseña.',
      characterValidation: 'Ingresa al menos 6 caracteres.',
      invalidPassword: 'Ingresa una contraseña válida.',
    },
    reason: {
      title: '¿Por qué quieres eliminar tu cuenta?',
      placeholder: 'Escribe tu respuesta',
    },
    requeridData: 'Estos campos son obligatorios',
    snackbarMessage: {
      success: '¡Eliminamos tu cuenta!',
      error: 'Ocurrió un error inesperado.',
    },
    buttonDelete: 'Eliminar cuenta',
  },
  privacy: {
    public:
      'Tu {{cv}} es visible en los avisos a los que te postulaste y en otras búsquedas que realizan las empresas.',
    private: 'Tu {{cv}} es visible únicamente en los avisos a los que te postulaste.',
  },
  password: {
    title: 'Cambiar mi contraseña',
    subTitle: 'Modifica tu contraseña actual por una nueva.',
    currentPassword: 'Ingresa tu contraseña actual',
    newPassword: 'Ingresa tu nueva contraseña',
    newPasswordAgain: 'Vuelve a ingresar la contraseña',
    newPasswordAgainLabel: 'Repite tu nueva contraseña',
    checkPassword: 'Revisa tu contraseña.',
    characterValidation: 'Ingresa al menos 6 caracteres.',
    checkNewPassword: 'Ingresa una contraseña válida',
    checkMachError: 'Las contraseñas no coinciden',
  },
  updateEmail: {
    title: 'Cambiar mi email',
    subTitle: 'Modifica tu email de ingreso',
    warning: 'El mail de ingreso es el mismo que se les muestra a las empresas.',
    blueWarning: 'Cuando cambies el email, te llegará un correo electrónico para que actives tu cuenta.',
    currentEmail: 'Email actual',
    newEmail: 'Ingresa nuevo email',
    newEmailPlaceholder: 'Ingresa un email válido.',
    newEmailLabel: 'Nuevo Email',
    password: 'Ingresa tu contraseña actual',
    passwordLabel: 'Contraseña actual',
    cta:'Cambiar email',
    validations: {
      invalid: 'Ingresa un email válido',
      underUse: 'El email ya está en uso',
      equal: 'Ingresa un mail diferente al actual',
      wrongPass:'Ingresa una contraseña válida'
    },
      snackbarMessage: {
      success: 'Revisa tu email para confirmar el cambio',
    }
  },

  snackbarMessage: {
    success: '¡Guardamos tus cambios!',
    error: 'Ocurrió un error inesperado.',
  },
  metaTitle: 'Mi cuenta',
  requiredTitle: 'Estos campos son obligatorios',
}
