export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const VALIDATE_EMAIL_RESET = 'VALIDATE_EMAIL_RESET'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_ERROR = 'VALIDATE_EMAIL_ERROR'

export interface ValidateEmailType {
  inUse: boolean
}

export interface EmailState {
  response: ValidateEmailType | null
  loading: boolean
  success: boolean
  error: boolean
}

export const initialState: EmailState = {
  response: null,
  loading: false,
  success: false,
  error: false,
}

//  validate Email
export interface ValidateEmail {
  type: typeof VALIDATE_EMAIL
  payload: string
}
export interface ValidateEmailSuccess {
  type: typeof VALIDATE_EMAIL_SUCCESS
  payload: ValidateEmailType | null
}
export interface ValidateEmailError {
  type: typeof VALIDATE_EMAIL_ERROR
  payload: boolean
}

export interface ValidateEmailReset {
  type: typeof VALIDATE_EMAIL_RESET
  payload: null
}

export type EmailActions = ValidateEmail | ValidateEmailSuccess | ValidateEmailError | ValidateEmailReset
