import { StatusType, status } from './status'

export const GET_POSTULACION = 'GET_POSTULACION'
export const GET_POSTULACION_SUCCESS = 'GET_POSTULACION_SUCCESS'
export const GET_POSTULACION_ERROR = 'GET_POSTULACION_ERROR'

export const GET_POSTULACIONES = 'GET_POSTULACIONES'
export const GET_POSTULACIONES_SUCCESS = 'GET_POSTULACIONES_SUCCESS'
export const GET_POSTULACIONES_ERROR = 'GET_POSTULACIONES_ERROR'

export const GET_ESTADOS_SELECCION_POSTULACION = 'GET_ESTADOS_SELECCION_POSTULACION'
export const GET_ESTADOS_SELECCION_POSTULACION_SUCCESS = 'GET_ESTADOS_SELECCION_POSTULACION_SUCCESS'
export const GET_ESTADOS_SELECCION_POSTULACION_ERROR = 'GET_ESTADOS_SELECCION_POSTULACION_ERROR'

export const PUT_ESTADO_SELECCION_POSTULACION = 'PUT_ESTADO_SELECCION_POSTULACION'
export const PUT_ESTADO_SELECCION_POSTULACION_SUCCESS = 'PUT_ESTADO_SELECCION_POSTULACION_SUCCESS'
export const PUT_ESTADO_SELECCION_POSTULACION_ERROR = 'PUT_ESTADO_SELECCION_POSTULACION_ERROR'
export const PUT_ESTADO_SELECCION_POSTULACION_RESET = 'PUT_ESTADO_SELECCION_POSTULACION_RESET'

interface Facet {
  id: string
  name: string
  quantity: number
}

interface FacetFilter {
  type: string
  facets: Facet[]
}

interface Filter {
  sort: string | null
  estado: string[] | null
  query: string | null
  fechaDesde?: string | null
}

interface Pagination {
  currentPage: number
  totalPages: number
  elementsPerPage: number
  totalElements: number
}

export interface Postulacion {
  estado?: string
  logoURL?: string
  empresa?: string
  titulo?: string
  fechaPostulacion?: Date
  avisoId?: number
  preferenciaSalarial?: number
  fechaPublicacionAviso?: Date
  estadoAviso?: string
  estadoPostulacion?: string
  nivelPostulometro?: string
  postulacionExtendidaId?: number
  posicionDestaque?: number
  cantidadComentariosTolki?: number
  cantidadComentarios?: number
  ultimaVezQueSeRevisoAviso?: Date
  cartaDePresentacion?: string
  id?: number
  tienePreguntasPendientes: boolean
  planDePublicacionId: number
  salarioRequerido: boolean
}

export interface Statistics {
  cantidadPostulaciones: number
  edadPromedioLabel: string
  edades: {
    age: string
    count: number
  }[]
  expectativaSalarial: {
    salario: number
    cantidad: number
  }[]
  experiencias: {
    experienceRange: string
    count: number
  }
  genero: {
    gender: string
    count: number
  }[]
  nivelesEducacion: {
    done: number
    inProgress: number
    incomplete: number
    level: string
  }[]
  postulaciones: {
    fecha: string
    cantidad: number
  }[]
  salarioPromedio: number
  ultimaModificacion: string
  visible: number
  estadosPostulacion: {
    id: number
    nombre: string
    descripcion: string
    porcentaje: number
    cantidad: number
  }[]
  nivelEducativoMasFrecuenteLabel: string
}

export interface GetPostulacionesParams {
  page: number
  pageSize?: number
  filtros: Filter
}

export interface GetPostulacionParams {
  idPostulacion: string
  statsTypes?: string[]
  onlyStats?: boolean
}

export interface GetPostulacionSuccessParams {
  estadisticas: Statistics | null
  postulacion: Postulacion | null
}

export interface GetPostulacion {
  type: typeof GET_POSTULACION
  payload: GetPostulacionParams
}
export interface GetPostulacionSuccess {
  type: typeof GET_POSTULACION_SUCCESS
  payload: GetPostulacionSuccessParams
}

export interface GetPostulacionError {
  type: typeof GET_POSTULACION_ERROR
  payload: boolean
}

export interface GetPostulaciones {
  type: typeof GET_POSTULACIONES
  payload: GetPostulacionesParams
}
export interface GetPostulacionesSuccess {
  type: typeof GET_POSTULACIONES_SUCCESS
  payload: {
    pagination: number
    postulaciones: number
    totalAvisos: number
    filters: FacetFilter[]
    totalPreguntasPendientes: number
  }
}

export interface GetPostulacionesError {
  type: typeof GET_POSTULACIONES_ERROR
  payload: boolean
}

interface EstadoSeleccion {
  id: number
  nombre: string
  descripcion: string
}

export interface GetEstadosSeleccion {
  type: typeof GET_ESTADOS_SELECCION_POSTULACION
}

export interface GetEstadosSeleccionSuccess {
  type: typeof GET_ESTADOS_SELECCION_POSTULACION_SUCCESS
  payload: EstadoSeleccion[]
}

export interface GetEstadosSeleccionError {
  type: typeof GET_ESTADOS_SELECCION_POSTULACION_ERROR
  payload: boolean
}

export interface PutEstadoSeleccion {
  type: typeof PUT_ESTADO_SELECCION_POSTULACION
  payload: {
    postulacionId: number
    estadoSeleccionado: number
  }
}

export interface PutEstadoSeleccionSuccess {
  type: typeof PUT_ESTADO_SELECCION_POSTULACION_SUCCESS
}

export interface PutEstadoSeleccionError {
  type: typeof PUT_ESTADO_SELECCION_POSTULACION_ERROR
  payload: boolean
}

export interface PutEstadoSeleccionReset {
  type: typeof PUT_ESTADO_SELECCION_POSTULACION_RESET
}

export interface PostulacionesState {
  postulaciones: Postulacion[] | null
  loading: boolean
  error: boolean
  success: boolean
  postulacion: [] | null
  postulacionStates: StatusType
  pagination: Pagination
  filters: FacetFilter[]
  perfil: {
    primerPostulacion: number | null
    totalPreguntasPendientes: number
  }
  estados_seleccion: {
    state: {
      loading: boolean
      error: boolean
      success: boolean
    }
    estados: EstadoSeleccion[]
    putState: {
      loading: boolean
      error: boolean
      success: boolean
    }
  }
}

export const initialState: PostulacionesState = {
  postulaciones: null,
  loading: false,
  error: false,
  success: false,
  postulacion: null,
  postulacionStates: status.initial,
  pagination: {
    currentPage: 0,
    totalPages: 0,
    elementsPerPage: 10,
    totalElements: 0,
  },
  filters: [],
  perfil: {
    primerPostulacion: null,
    totalPreguntasPendientes: 0,
  },
  estados_seleccion: {
    state: {
      loading: false,
      error: false,
      success: false,
    },
    estados: [],
    putState: {
      loading: false,
      error: false,
      success: false,
    },
  },
}

export type PostulacionesActions =
  | GetPostulacion
  | GetPostulacionSuccess
  | GetPostulacionError
  | GetPostulaciones
  | GetPostulacionesSuccess
  | GetPostulacionesError
  | GetEstadosSeleccion
  | GetEstadosSeleccionError
  | GetEstadosSeleccionSuccess
  | PutEstadoSeleccion
  | PutEstadoSeleccionSuccess
  | PutEstadoSeleccionError
  | PutEstadoSeleccionReset
