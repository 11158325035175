import React from 'react'
import { Helmet } from 'react-helmet'
import { SUFIJO_PAIS } from './constants'

const SUFIJO = SUFIJO_PAIS.toUpperCase()

const Component = () => {
  return <Helmet htmlAttributes={{ lang: `es-${SUFIJO}` }} />
}

export default Component
