import { Aviso } from './listado-avisos'
import { status, StatusType } from './status'

export const GET_JOB_POSTINGS_INFO = 'GET_JOB_POSTING_INFO'
export const GET_JOB_POSTINGS_INFO_SUCCESS = 'GET_JOB_POSTING_INFO_SUCCESS'
export const GET_JOB_POSTING_INFO_ERROR = 'GET_JOB_POSTING_INFO_ERROR'
export const GET_NEW_FEATURES = 'GET_NEW_FEATURES'
export const GET_NEW_FEATURES_SUCCESS = 'GET_NEW_FEATURES_SUCCESS'
export const GET_NEW_FEATURES_ERROR = 'GET_NEW_FEATURES_ERROR'
export const GET_TOTAL_AVISOS = 'GET_TOTAL_AVISOS'
export const GET_TOTAL_AVISOS_SUCCESS = 'GET_TOTAL_AVISOS_SUCCESS'
export const GET_TOTAL_AVISOS_ERROR = 'GET_TOTAL_AVISOS_ERROR'
export const GET_JOB_POSTINGS_HOME = 'GET_JOB_POSTING_HOME'
export const GET_JOB_POSTINGS_HOME_SUCCESS = 'GET_JOB_POSTING_HOME_SUCCESS'
export const GET_JOB_POSTING_HOME_ERROR = 'GET_JOB_POSTING_HOME_ERROR'

interface NewFeature {
  actionButton: string
  buttonText: string
  detail: string
  img: string
  title: string
}

interface AreaCarousel {
  areaId: string
  quantity: number
  title: string
}

interface AreasCarousel {
  content: AreaCarousel[]
  countryAvisoCount: number
}

interface LandingButton {
  filterId: string
  facetId: string
  quantity: number
  title: string
}

interface JobPostingsInfo {
  areasCarousel: AreasCarousel
  landingButtons: LandingButton[]
}

interface Filter {
  id: string
  value: string
}

interface FilterRequest {
  filtros: any[]
  busquedaExtendida: boolean
  tipoDetalle: string
  page: number
  pageSize: number
  sort: string
}

export interface JobPostingsHome {
  filters: Filter[]
  avisos: Aviso[]
}

export interface GetJobPostingsInfo {
  type: typeof GET_JOB_POSTINGS_INFO
  payload: null
}

export interface GetJobPostingsInfoSuccess {
  type: typeof GET_JOB_POSTINGS_INFO_SUCCESS
  payload: JobPostingsInfo
}

export interface GetJobPostingsInfoError {
  type: typeof GET_JOB_POSTING_INFO_ERROR
  payload: boolean
}

export interface GetNewFeatures {
  type: typeof GET_NEW_FEATURES
  payload: null
}

export interface GetNewFeaturesSuccess {
  type: typeof GET_NEW_FEATURES_SUCCESS
  payload: NewFeature[]
}

export interface GetNewFeaturesError {
  type: typeof GET_NEW_FEATURES_ERROR
  payload: boolean
}

export interface GetTotalAvisos {
  type: typeof GET_TOTAL_AVISOS
  payload: null
}

export interface GetTotalAvisosSuccess {
  type: typeof GET_TOTAL_AVISOS_SUCCESS
  payload: number
}

export interface GetTotalAvisosError {
  type: typeof GET_TOTAL_AVISOS_ERROR
  payload: null
}

export interface GetJobPostingsHome {
  type: typeof GET_JOB_POSTINGS_HOME
  payload: FilterRequest
}

export interface GetJobPostingsHomeSuccess {
  type: typeof GET_JOB_POSTINGS_HOME_SUCCESS
  payload: JobPostingsHome
}

export interface GetJobPostingsHomeError {
  type: typeof GET_JOB_POSTING_HOME_ERROR
  payload: boolean
}

export interface Status {
  loading: boolean
  success: boolean
  error: boolean
}

const initialStatus = {
  success: false,
  error: false,
  loading: false,
}

export interface HomePageState {
  newFeatures: NewFeature[] | null
  areasCarousel: AreasCarousel | null
  totalAvisos: {
    value: number | null
    status: Status
  }
  loading: boolean
  error: boolean
  jobPostingsHome: {
    states: StatusType
    jobPostings: Aviso[]
    filters: Filter[]
  }
}

export const initialState: HomePageState = {
  newFeatures: null,
  areasCarousel: null,
  totalAvisos: {
    value: null,
    status: initialStatus,
  },
  loading: false,
  error: false,
  jobPostingsHome: {
    states: status.initial,
    jobPostings: [],
    filters: [],
  },
}

export type HomePageActions =
  | GetNewFeatures
  | GetNewFeaturesSuccess
  | GetNewFeaturesError
  | GetJobPostingsInfo
  | GetJobPostingsInfoSuccess
  | GetJobPostingsInfoError
  | GetTotalAvisos
  | GetTotalAvisosSuccess
  | GetTotalAvisosError
  | GetJobPostingsHome
  | GetJobPostingsHomeSuccess
  | GetJobPostingsHomeError
