import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS_RESET,
  NotificationsState,
  initialState,
  NotificationsActions,
} from '../types/applicant/notifications-alerts/notifications'

const reducers = (state = initialState, action: NotificationsActions): NotificationsState => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationSetting: [],
        getStates: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationSetting: action.payload,
        getStates: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationSetting: [],
        getStates: {
          loading: false,
          success: false,
          error: true,
        },
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        setStates: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case SET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        setStates: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case SET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        setStates: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case SET_NOTIFICATIONS_RESET:
      return {
        ...state,
        setStates: {
          success: false,
          error: false,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default reducers
