import React from 'react'
import { IS_ZONA_JOBS } from '../../../../../constants'
import ExpiredImageBum from '../../../../../assets/images/expired.svg'
import ExpiredImageZona from '../../../../../assets/images/expired-zona.svg'
import { ComponentContainer, CustomImage } from './mixins'

const Component = () => {
  return (
    <ComponentContainer>
      <CustomImage src={IS_ZONA_JOBS ? ExpiredImageZona : ExpiredImageBum} alt="Enlace vencido" />
    </ComponentContainer>
  )
}

export default Component
