import { Request, Post } from '@navent-jobs/config'

export class ErrorActividad extends Error {}

class Actividad {
  public registrarActividad(): Promise<void> {
    const path = '/api/actividad/registrar'
    const request = new Request()
    request.method = Post
    request.path = path
    request.body = { dispositivo: 'WEB' }
    return request.call().catch(() => {
      throw new ErrorActividad('Error al registrar la actividad')
    })
  }
}

export const actividadService = new Actividad()
