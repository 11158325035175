import styled from 'styled-components'

export const LookAndFeelWrapper = styled('div')`
  width: 100%;
  height: 128px;
  display: flex;
  position: relative;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    height: 200px;
  }
`

export const LookAndFeelImage = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${({ productoLookAndFeel }) =>
    productoLookAndFeel
      ? `background: transparent url(${productoLookAndFeel.bannerEmpresa}) no-repeat;
    background-size: cover;`
      : ``}

  @media (min-width: 1024px) {
    border-radius: 8px 8px 0px 0px;
  }
`

export const DefaultLookAndFeelContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  background: ${({ src }) => `transparent url(${src}) no-repeat`};
  background-size: cover;
  background-color: white;
  background-position: right;

  @media (min-width: 1024px) {
    border-radius: 8px 8px 0px 0px;
  }
`
