import React, { ReactNode } from 'react'
import { Route } from 'react-router-dom'

/*

<PrivateRoute> 
 Componente que restringe el acceso a las routes validando al usuario como logeado. 
 Existe el caso que se necesite acceder con usuario logeado mediante autologin, para esto es necesario definir el componente con el attr 'withRToken'

*/


interface PrivateRouteInterface {
  children: ReactNode
  withRToken?: boolean
}

const getCookieData = (key: string, cookieString: string) => {
  if (!cookieString) return null
  const data = cookieString.split(`${key}=`)
  if (!data[1]) return null
  const value = data[1].split(';')[0]
  if (value && value.length) return value
  return null
}

export const PrivateRoute = ({ withRToken = false, children, ...rest }: PrivateRouteInterface) => {
  
  // Usuario esta logueado si/no
  const applicant = getCookieData('token_auth_postulante', document.cookie)
  const rtoken = getCookieData('loginRestringido', document.cookie)

  // Si la privacidad es sin considerar el rtoken, entonces el usuario estará correctamente logeando cuando tenga token y no exista el flag de loginRestringido.
  // Por lo contario, si se considera el rtoken la privacidad necesitara tener rtoken y el flag de loginRestringido.
  const isUserLoggedInOk = withRToken ? !!applicant && !!rtoken : !!applicant && !rtoken
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserLoggedInOk ? children : window.location.assign(`/login?returnTo=${location.pathname}`)
      }
    />
  )
}
