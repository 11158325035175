import React from 'react'
import paymentMPSettings from '../../../../constants/payment-mp-settings'
import { Subtitle } from './mixins'

const Component = ({ status, product, emailCopyTitle }) => {
  const pageSetting = paymentMPSettings()

  return (
    <Subtitle emailCopyTitle={emailCopyTitle}>
      {status === 'success' || status === 'pending' ? (
        <>
          {`${pageSetting.products[product][status].subtitle}`}
          <strong>{`${pageSetting.products[product].name}`}</strong>
        </>
      ) : (
        `${pageSetting.products[product][status].subtitle}`
      )}
    </Subtitle>
  )
}

export default Component
