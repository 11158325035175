import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'
import { ThemeContext } from 'styled-components'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// compontens
import KeywordSelect from '../KeywordSelect'
import LocationSelect from '../LocationSelect'
import { useSearchKeyword } from '../../../../hooks/search-keyword'

// services
import { SearchStack as recentSearchesService } from '../../../../services/search-stack'

// mixins
import {
  KeywordFieldContainer,
  LocationFieldContainer,
  FieldIcon,
  CustomButton,
  RecentSearches,
  RecentSearchTitlte,
  RecentSearchesOption,
  CustomButtonTrash,
} from './mixins'

// constants
import { DataLayers } from '../../../../constants/gtm-events-site-search'
import OptionLabels from '../utils'
import {
  NuevoFiltro,
  pushFilterToRecentSearches,
} from '../../../../pages/listado-avisos/components/filters/utils/pushfilter'

// redux
import { getLocations } from '../../../../redux/actions/locations'
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'

/**
 * Traduce los nombres de los filtros cuando están aplicados
 */
const translateFiltersApplied = filtersApplied => {
  if (!filtersApplied) {
    return filtersApplied
  }
  return filtersApplied.map(filter => {
    return { ...filter, name: i18n.t(`filtros.${filter.name}`, { defaultValue: filter.name }) }
  })
}

const searchAvisosV2Enabled = features[SITE_ID || 'BMAR'].searchAvisosV2.enabled
const CAPITAL_FEDERAL = searchAvisosV2Enabled ? 'argentina|buenos-aires|capital-federal' : 'capital-federal'

const Component = ({ availableLocations, opened, filtersApplied, setMenuOpened, setActive }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname, search } = window.location
  const history = useHistory()
  const { colors } = useContext<{ colors }>(ThemeContext)

  const { searchKeyword, storeSearchKeyword } = useSearchKeyword()
  const [keyword, setKeyword] = useState<{ keyword?: string; label: string; value: string } | undefined>(undefined)
  const [location, setLocation] = useState<{ label: string; value: string } | undefined>(undefined)

  const pushDataLayer = (searchTerm, locationTerm, isRecentSearch) => {
    DataLayers.siteSearch(searchTerm, locationTerm, pathname, isRecentSearch)
  }

  const handlerSearch = e => {
    e.preventDefault()
    const filtrosNuevos: NuevoFiltro[] = [{ type: 'busqueda', facetSelected: keyword || { label: '', value: '' } }]

    if (location?.value === CAPITAL_FEDERAL) {
      filtrosNuevos.push({ type: 'localidad', facetSelected: location })
    } else {
      filtrosNuevos.push({ type: 'provincia', facetSelected: location })
      // Remove localidad filter from filtersApplied if provincia is selected
      const index = filtersApplied?.findIndex(f => f.type === 'localidad')
      if (index >= 0) {
        filtersApplied.splice(index, 1)
      }
    }

    const redirectUrl = pushFilterToRecentSearches(filtrosNuevos, translateFiltersApplied(filtersApplied))
    if (history.location.pathname !== redirectUrl) {
      history.push(redirectUrl)
      keyword && storeSearchKeyword(keyword.label)
    }
    pushDataLayer(keyword, location, false)
    setMenuOpened(false)
  }

  const handlerClear = e => {
    e.preventDefault()
    setKeyword(undefined)
    setLocation({ value: '', label: '' })
  }

  const recentSearchOnClick = recentSearch => {
    pushDataLayer(recentSearch?.busqueda, recentSearch?.provincia, true)
    setMenuOpened(false)
  }

  const onLocations = () => {
    if (!opened) {
      dispatch(getLocations())
    }
  }

  useEffect(() => {
    if (filtersApplied && !search.includes('query')) {
      const keyValue = filtersApplied?.find(f => f.id === 'query')?.value
      if (keyValue) {
        setKeyword({ label: searchKeyword || keyValue?.replaceAll('-', ' '), value: keyValue, keyword: keyValue })
      } else {
        setKeyword(undefined)
      }

      // Seteo capital federal en el buscador de provincias del searchbar del header
      const capitalLocation = filtersApplied?.find(f => f.type === 'localidad')
      if (capitalLocation && (capitalLocation.idSemantico || capitalLocation.id) === CAPITAL_FEDERAL) {
        setLocation({ label: capitalLocation?.name, value: capitalLocation?.idSemantico || capitalLocation?.id })
      } else {
        const locationValue = filtersApplied?.find(f => f.type === 'provincia')
        if (locationValue) {
          setLocation({ label: locationValue?.name, value: locationValue?.idSemantico || locationValue?.id })
        } else {
          setLocation(undefined)
        }
      }
    } else {
      setKeyword(undefined)
      setLocation(undefined)
    }
  }, [filtersApplied])

  return (
    <form onSubmit={handlerSearch}>
      <KeywordFieldContainer value={keyword?.value}>
        <FieldIcon size="24" color={colors.secondary.normal} name="icon-light-search" />

        <KeywordSelect
          handlerOnFocus={setActive}
          handlerOnBlur={setActive}
          handlerOnChange={setKeyword}
          keyword={keyword}
          setKeyword={setKeyword}
          placeholder="Buscar por puesto o palabra clave"
        />
      </KeywordFieldContainer>

      <LocationFieldContainer value={location?.value}>
        <FieldIcon size="24" color={colors.secondary.normal} name="icon-light-location-pin" />
        <LocationSelect
          options={availableLocations}
          handlerOnFocus={onLocations}
          handlerOnBlur={setActive}
          handlerOnChange={setLocation}
          value={location}
        />
      </LocationFieldContainer>

      <CustomButton buttonoptions={{ variant: 'primary', block: true }} type="submit">
        {t('Buscar trabajo')}
      </CustomButton>

      <CustomButtonTrash
        buttonoptions={{ variant: 'primary', block: true, type: 'outline' }}
        type="button"
        onClick={handlerClear}
      >
        <Icon name="icon-light-trash-2" size="20" />
        {t('Borrar búsqueda')}
      </CustomButtonTrash>

      {recentSearchesService?.getInstance()?.getHistory()?.length > 0 && (
        <RecentSearches>
          <RecentSearchTitlte>Búsquedas recientes</RecentSearchTitlte>
          {recentSearchesService
            .getInstance()
            .getHistory()
            .map(recentSearch => {
              return (
                recentSearch && (
                  <RecentSearchesOption onClick={() => recentSearchOnClick(recentSearch)}>
                    <OptionLabels option={recentSearch} />
                  </RecentSearchesOption>
                )
              )
            })}
        </RecentSearches>
      )}
    </form>
  )
}

const states = ({ locationsStore, filtersStore }) => ({
  availableLocations: locationsStore && locationsStore.locations,
  opened: locationsStore && locationsStore.opened,
  filtersApplied: filtersStore.filtersApplied,
})

export default connect(states)(Component)
