import { dataLink } from './data-link'
import { commonItems } from '../common-items'
import { IS_ZONA_JOBS } from '../../..'

export const noLoggedInMenuItems = ({ storeUrl }) => {
  const downloadAppSection = IS_ZONA_JOBS
    ? []
    : [commonItems.separador, commonItems.downloadApp(storeUrl), commonItems.separador]

  return [
    {
      key: 'user-menu',
      type: 'dropdown',
      visibleIn: ['mobile'],
      items: [
        dataLink.signIn,
        dataLink.login,
        dataLink.publicarGratisMobile,
        dataLink.soyEmpresasMobile,
        commonItems.separador,
        commonItems.explorar,
        commonItems.promedioSalarial,
        commonItems.listadoEmpresas,
        commonItems.mapaEmpleos,
        commonItems.separadorSitiosInteres,
        commonItems.sitiosDeInteres,
        commonItems.jovenesProfesionalesIcon,
        commonItems.puestosEjecutivos,
        commonItems.empleosInclusivos,
        commonItems.empleosPartTime,
        commonItems.practicasProfesionales,
        ...downloadAppSection,
      ],
    },
    {
      key: 'navbar-menu',
      type: 'navbar',
      visibleIn: ['desktop'],
      items: [
        dataLink.login,
        dataLink.signIn,
        dataLink.publicarGratis,
        dataLink.soyEmpresas,
        dataLink.jovenesProfesionales,
        // dataLink.listadoEmpresas,
        // dataLink.mapaEmpleos,
        // dataLink.downloadApp(storeUrl),
      ],
    },
  ]
}
