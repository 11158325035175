import {
  SET_COMPONENT_SETTINGS,
  CtaDownloadAppState,
  CtaDownloadAppActions,
  initialState,
} from '../types/cta-download-app'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state: any = initialState, action: CtaDownloadAppActions): CtaDownloadAppState => {
  switch (action.type) {
    case SET_COMPONENT_SETTINGS:
      return {
        settings: action.payload,
      }
    default:
      return state
  }
}

export default reducers
