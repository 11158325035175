import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import { SelectField } from './mixins'

interface LocationSelectProps {
  handlerOnFocus: Function
  handlerOnBlur: Function
  handlerOnChange: Function
  options: {}
  defaultValue?: {}
  value:
    | {
        value: string
        label: string
      }
    | undefined
  disabled: boolean
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="icon-light-caret-down" color="rgba(0, 0, 24, 0.16)" size="20" />
    </components.DropdownIndicator>
  )
}

const Component = ({
  handlerOnFocus,
  handlerOnBlur,
  handlerOnChange,
  options,
  defaultValue,
  value = undefined,
  disabled = false,
}: LocationSelectProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const isSelectOpened = isOpened ? 'select-opened' : ''
  const { t } = useTranslation()

  const openSelect = () => {
    setIsOpened(!isOpened)
  }
  let selectedValue: {} = []
  if (value && value.label) {
    selectedValue = value
  } else if (defaultValue) {
    selectedValue = defaultValue
  }

  return (
    <SelectField
      small
      placeholder={t('Lugar de trabajo')}
      name="lugar-de-trabajo"
      id="lugar-de-trabajo"
      className={`basic-single ${isSelectOpened}`}
      classNamePrefix="select"
      options={options}
      onFocus={() => handlerOnFocus('focusin')}
      onBlur={() => handlerOnBlur('')}
      onChange={e => handlerOnChange(e)}
      value={selectedValue}
      onClick={openSelect}
      onMenuOpen={openSelect}
      components={{ DropdownIndicator }}
      isDisabled={disabled}
    />
  )
}

export default Component
