/**
 * Sistema de anclas por url
 * se ejecuta al detectar cambios en window.location.hash
 *
 * 1-Buscamos el elemento por ID
 * 2-Si existe determinamos su coordenada Y en el dom
 * 3-Le restamos un offset y hacemos un suave scroll
 * al terminar ya sea que el elemento sea encontrado o no, limpiamos el hash
 */

export const handleSmoothScroll = (urlHash, offsetDif) => {
  const elementId = urlHash.slice(1)
  const domElementPosittion = document.getElementById(`${elementId}`)?.getBoundingClientRect().top
  const offset = domElementPosittion ? domElementPosittion + window.scrollY - offsetDif : false
  offset && window.scrollTo({ top: offset, behavior: 'smooth' })
  window.history.replaceState('', document.title, window.location.pathname)
}
