import React, { useEffect } from 'react'
// Tools
import { connect, useDispatch } from 'react-redux'

// Components
import NotificationsSkeleton from './components/placeholder'
import UiSuscriptionList from './ui-suscription-list'

// Redux
import { getSuscriptionAction } from '../../../../redux/actions/suscriptcion'
import { StatusType } from '../../../../redux/types/status'
import { SuscripcionState } from '../../../../redux/types/suscripcion'

interface SuscriptcionesProps {
  suscripcionsListStatus: StatusType | null
  suscripcionsList: SuscripcionState['getSuscripcion']['items']
}

const SuscripcionesContent = ({ suscripcionsList, suscripcionsListStatus }: SuscriptcionesProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSuscriptionAction())
  }, [])

  if (suscripcionsList && !suscripcionsListStatus?.loading) {
    return <UiSuscriptionList suscripcionsList={suscripcionsList} />
  }

  return <NotificationsSkeleton />
}

const states = ({ suscripcionesStore }) => {
  return {
    suscripcionsList: suscripcionesStore?.getSuscripcion.items || null,
    suscripcionsListStatus: suscripcionesStore?.getSuscripcion.status,
  }
}

export default connect(states)(SuscripcionesContent)
