import styled from 'styled-components'

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const CardContent = styled.div`
  width: 100%;
  max-width: 684px;
  border-radius: 8px;
  background: white;
  padding: 18px 16px 8px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px;
    margin-bottom: 24px;
  }
`
