import {
  GET_LISTADO_AVISOS_EMPRESA,
  GET_LISTADO_AVISOS_EMPRESA_SUCCESS,
  GET_LISTADO_AVISOS_EMPRESA_ERROR,
  CompanySummaryActions,
  GET_FICHA_EMPRESA,
  GET_FICHA_EMPRESA_SUCCESS,
  GET_FICHA_EMPRESA_ERROR,
  SET_FICHA_EMPRESA_FILTERS_APPLIED,
  SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS,
  SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR,
  POST_SEGUIR_EMPRESA,
  POST_SEGUIR_EMPRESA_SUCCESS,
  POST_SEGUIR_EMPRESA_ERROR,
  GET_SEGUIR_EMPRESA,
  GET_SEGUIR_EMPRESA_SUCCESS,
  GET_SEGUIR_EMPRESA_ERROR,
  DELETE_SEGUIR_EMPRESA,
  DELETE_SEGUIR_EMPRESA_SUCCESS,
  DELETE_SEGUIR_EMPRESA_ERROR,
  POST_DELETE_SEGUIR_EMPRESA_RESET,
} from '../types/company-summary/company-summary'
import { status } from '../types/status'

import { CompanySummaryState, initialState } from '../types/company-summary/commons'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state = initialState, action: CompanySummaryActions): CompanySummaryState => {
  switch (action.type) {
    case GET_LISTADO_AVISOS_EMPRESA:
      return {
        ...state,
        listadoAvisosStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_LISTADO_AVISOS_EMPRESA_SUCCESS:
      return {
        ...state,
        listadoAvisos: action.payload,
        listadoAvisosStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_LISTADO_AVISOS_EMPRESA_ERROR:
      return {
        ...state,
        listadoAvisosStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case SET_FICHA_EMPRESA_FILTERS_APPLIED:
      return {
        ...state,
        filterStates: status.loading,
      }
    case SET_FICHA_EMPRESA_FILTERS_APPLIED_SUCCESS:
      return {
        ...state,
        filters: action.payload,
        filterStates: status.success,
      }
    case SET_FICHA_EMPRESA_FILTERS_APPLIED_ERROR:
      return {
        ...state,
        filters: null,
        filterStates: status.error,
      }
    case GET_FICHA_EMPRESA:
      return {
        ...state,
        fichaEmpresaStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_FICHA_EMPRESA_SUCCESS:
      return {
        ...state,
        fichaEmpresa: action.payload,
        fichaEmpresaStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_FICHA_EMPRESA_ERROR:
      return {
        ...state,
        fichaEmpresaStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case POST_SEGUIR_EMPRESA:
    case DELETE_SEGUIR_EMPRESA:
      return {
        ...state,
        siguiendoStates: status.loading,
      }
    case POST_SEGUIR_EMPRESA_SUCCESS:
      return {
        ...state,
        idEmpresa: action.payload,
        siguiendo: true,
        siguiendoStates: status.success,
      }
    case POST_SEGUIR_EMPRESA_ERROR:
    case DELETE_SEGUIR_EMPRESA_ERROR:
      return {
        ...state,
        siguiendoStates: status.error,
      }
    case DELETE_SEGUIR_EMPRESA_SUCCESS:
      return {
        ...state,
        idEmpresa: action.payload,
        siguiendo: false,
        siguiendoStates: status.success,
      }
    case POST_DELETE_SEGUIR_EMPRESA_RESET:
      return {
        ...state,
        siguiendoStates: status.initial,
      }
    case GET_SEGUIR_EMPRESA:
      return {
        ...state,
        getSiguiendoStates: status.loading,
      }
    case GET_SEGUIR_EMPRESA_SUCCESS:
      return {
        ...state,
        siguiendo: action.payload,
        getSiguiendoStates: status.success,
      }
    case GET_SEGUIR_EMPRESA_ERROR:
      return {
        ...state,
        siguiendo: null,
        getSiguiendoStates: status.error,
      }
    default:
      return state
  }
}

export default reducers
