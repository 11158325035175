import {
  GetApplicantNotifications,
  GetApplicantUnreadtNotifications,
  GET_APPLICANT_NOTIFICATIONS,
  GET_APPLICANT_UNREAD_NOTIFICATIONS,
  MarkAsReadNotifications,
  READ_MARK_APPLICANT_NOTIFICATIONS,
  UpdateFCMToken,
  UPDATE_APPLICANT_FCM_TOKEN,
} from '../types/notifications'

export const getApplicantUnreadNotifications = (): GetApplicantUnreadtNotifications => {
  return {
    type: GET_APPLICANT_UNREAD_NOTIFICATIONS,
    payload: null,
  }
}

export const getApplicantNotifications = (): GetApplicantNotifications => {
  return {
    type: GET_APPLICANT_NOTIFICATIONS,
    payload: null,
  }
}

export const marckAsReadNotifications = (): MarkAsReadNotifications => {
  return {
    type: READ_MARK_APPLICANT_NOTIFICATIONS,
    payload: null,
  }
}

export const updateTokenFCM = (): UpdateFCMToken => {
  return {
    type: UPDATE_APPLICANT_FCM_TOKEN,
    payload: null,
  }
}
