import {
  GET_LINK_PAGO,
  GET_LINK_PAGO_SUCCESS,
  GET_LINK_PAGO_ERROR,
  NOTIFICAR_PAGO_EXITOSO,
  NOTIFICAR_PAGO_EXITOSO_SUCCESS,
  NOTIFICAR_PAGO_EXITOSO_ERROR,
  initialState,
  PaymentMPActions,
  PaymentMPState,
  GET_LINK_PAGO_RESET,
} from '../types/payment-mp'

const reducers = (state: PaymentMPState = initialState, action: PaymentMPActions): PaymentMPState => {
  switch (action.type) {
    case GET_LINK_PAGO:
      return {
        ...state,
        refName: action.payload.refName,
        getLinkPagoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case GET_LINK_PAGO_SUCCESS:
      return {
        ...state,
        linkPago: action.payload,
        getLinkPagoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case GET_LINK_PAGO_ERROR:
      return {
        ...state,
        getLinkPagoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    case GET_LINK_PAGO_RESET:
      return {
        ...state,
        linkPago: null,
        refName: null,
        getLinkPagoStatus: {
          success: false,
          loading: false,
          error: false,
        },
      }
    case NOTIFICAR_PAGO_EXITOSO:
      return {
        ...state,
        notificarPagoExitosoStatus: {
          success: false,
          loading: true,
          error: false,
        },
      }
    case NOTIFICAR_PAGO_EXITOSO_SUCCESS:
      return {
        ...state,
        notificarPagoExitosoStatus: {
          success: true,
          loading: false,
          error: false,
        },
      }
    case NOTIFICAR_PAGO_EXITOSO_ERROR:
      return {
        ...state,
        notificarPagoExitosoStatus: {
          success: false,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default reducers
