import { GetArchivoCvAdjunto } from '../commons'

export const ADD_CV_ADJUNTO = 'ADD_CV_ADJUNTO'
export const ADD_CV_ADJUNTO_RESET = 'ADD_CV_ADJUNTO_RESET'
export const ADD_CV_ADJUNTO_SUCCESS = 'ADD_CV_ADJUNTO_SUCCESS'
export const ADD_CV_ADJUNTO_ERROR = 'ADD_CV_ADJUNTO_ERROR'

export const GET_CV_ADJUNTO = 'GET_CV_ADJUNTO'
export const GET_CV_ADJUNTO_RESET = 'GET_CV_ADJUNTO_RESET'
export const GET_CV_ADJUNTO_SUCCESS = 'GET_CV_ADJUNTO_SUCCESS'
export const GET_CV_ADJUNTO_ERROR = 'GET_CV_ADJUNTO_ERROR'

export const DELETE_CV_ADJUNTO = 'DELETE_CV_ADJUNTO'
export const DELETE_CV_ADJUNTO_RESET = 'DELETE_CV_ADJUNTO_RESET'
export const DELETE_CV_ADJUNTO_SUCCESS = 'DELETE_CV_ADJUNTO_SUCCESS'
export const DELETE_CV_ADJUNTO_ERROR = 'DELETE_CV_ADJUNTO_ERROR'

// Add cvAdjunto
export interface AddCvAdjunto {
  type: typeof ADD_CV_ADJUNTO
  payload: File
}
export interface AddCvAdjuntoReset {
  type: typeof ADD_CV_ADJUNTO_RESET
  payload: null
}
export interface AddCvAdjuntoSuccess {
  type: typeof ADD_CV_ADJUNTO_SUCCESS
  payload: null
}
export interface AddCvAdjuntoError {
  type: typeof ADD_CV_ADJUNTO_ERROR
  payload: null
}

// Get cvAdjunto
export interface GetCvAdjunto {
  type: typeof GET_CV_ADJUNTO
  payload: GetArchivoCvAdjunto
}
export interface GetCvAdjuntoReset {
  type: typeof GET_CV_ADJUNTO_RESET
  payload: null
}
export interface GetCvAdjuntoSuccess {
  type: typeof GET_CV_ADJUNTO_SUCCESS
  payload: string | null
}
export interface GetCvAdjuntoError {
  type: typeof GET_CV_ADJUNTO_ERROR
  payload: null
}

// Delete cvAdjunto
export interface DeleteCvAdjunto {
  type: typeof DELETE_CV_ADJUNTO
  payload: null
}
export interface DeleteCvAdjuntoReset {
  type: typeof DELETE_CV_ADJUNTO_RESET
  payload: null
}
export interface DeleteCvAdjuntoSuccess {
  type: typeof DELETE_CV_ADJUNTO_SUCCESS
  payload: null
}
export interface DeleteCvAdjuntoError {
  type: typeof DELETE_CV_ADJUNTO_ERROR
  payload: null
}
