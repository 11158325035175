export const GET_CAREER_SITE = 'GET_CAREER_SITE'
export const GET_CAREER_SITE_SUCCESS = 'GET_CAREER_SITE_SUCCESS'
export const GET_CAREER_SITE_ERROR = 'GET_CAREER_SITE_ERROR'

export interface CareerSite {
  area: number
  image: string
  key: string
  link: string
  external?: boolean
  newTab?: boolean
}

export interface GetCareerSite {
  type: typeof GET_CAREER_SITE
  payload: string
}

export interface GetCareerSiteSuccess {
  type: typeof GET_CAREER_SITE_SUCCESS
  payload: CareerSite
}

export interface GetCareerSiteError {
  type: typeof GET_CAREER_SITE_ERROR
}

export interface CareerSiteState {
  careerSite: CareerSite | null
  careerSiteStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: CareerSiteState = {
  careerSite: null,
  careerSiteStates: {
    loading: false,
    success: false,
    error: false,
  },
}

export type CareerSiteActions = GetCareerSite | GetCareerSiteError | GetCareerSiteSuccess
