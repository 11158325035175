import React from 'react'
import Text from '@navent-jobs/ui-kit/components/text'
import { Chip } from '@navent-jobs/ui-kit/components/chip'
import { SectionContainer, TagContainer, CustomText } from './mixins'

interface HeaderTagDataProps {
  estadoPostulacion: string
  isRead: boolean
  avisoNuevo: boolean
  cantidadVacantes: number
  cantidadDeDias: string
  postulacionRapida: boolean
}

const HeaderTagData = ({
  estadoPostulacion,
  isRead,
  avisoNuevo,
  cantidadVacantes,
  cantidadDeDias,
  postulacionRapida,
}: HeaderTagDataProps) => {
  const showMultipleVacancies = cantidadVacantes > 1
  const showNew = !(estadoPostulacion || isRead) && avisoNuevo
  const showPostulacionRapida = postulacionRapida

  return (
    <SectionContainer>
      {(showMultipleVacancies || showPostulacionRapida) && (
        <TagContainer columnDir>
          {showPostulacionRapida && (
            <Chip
              text="Postulación rápida"
              iconName="icon-light-flash-on"
              chipOptions={{ color: 'extra', skin: 'fill', variant: 'darken', size: 'small' }}
            />
          )}
          {showMultipleVacancies && (
            <Chip
              text="Múltiples vacantes"
              iconName="icon-light-people"
              chipOptions={{ color: 'extra', size: 'small', skin: 'outline', variant: 'darken' }}
            />
          )}
        </TagContainer>
      )}

      <TagContainer center>
        {showNew && (
          <Chip text="Nuevo" chipOptions={{ color: 'black', size: 'small', skin: 'outline', variant: 'lighten' }} />
        )}

        <CustomText>
          <Text size="xs" fontWeight="semibold" variant="gray60" as="h3">
            {cantidadDeDias}
          </Text>
        </CustomText>
      </TagContainer>
    </SectionContainer>
  )
}

export default HeaderTagData
