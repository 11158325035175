import { status } from '../types/status'
import {
  MarketingActions,
  MarketingState,
  initialState,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
  GET_COMPANY_LOGOS,
  GET_COMPANY_LOGOS_ERROR,
  GET_COMPANY_LOGOS_SUCCESS,
} from '../types/marketing'

const reducers = (state: MarketingState = initialState, action: MarketingActions): MarketingState => {
  switch (action.type) {
    case GET_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          status: status.loading,
        },
      }
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          value: action.payload,
          status: status.success,
        },
      }
    case GET_CAMPAIGN_ERROR:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          status: status.error,
        },
      }

    case GET_COMPANY_LOGOS:
      return {
        ...state,
        companyLogos: {
          ...state.companyLogos,
          status: status.loading,
        },
      }
    case GET_COMPANY_LOGOS_SUCCESS:
      return {
        ...state,
        companyLogos: {
          ...state.companyLogos,
          value: action.payload,
          status: status.success,
        },
      }
    case GET_COMPANY_LOGOS_ERROR:
      return {
        ...state,
        companyLogos: {
          ...state.companyLogos,
          status: status.error,
        },
      }

    default:
      return state
  }
}

export default reducers
