const fichaAviso = {
  postularBar: {
    activaTuCuenta: 'activá tu cuenta.',
    completaTuCV: 'completá tu {{cv}}.',
    ingresaSueldoPretendido: 'Ingresá tu sueldo bruto pretendido',
  },
  salaryLabelInfo: 'Recordá que el sueldo bruto es el salario total sin las retenciones e impuestos.',
  postulacion: {
    incompleta: {
      estado: 'Postulación incompleta: ',
      activarCuenta:
        'Para que la empresa reciba tu postulación, tenés que activar tu cuenta desde el email que te enviamos a ',
      completarCV:
        'Para que la empresa reciba tu postulación, tenés que completar tu CV con tu experiencia laboral o con tus estudios.',
    },
  },
  modalAvisos: {
    title: 'Seguí postulandote a más empleos',
  },
}

export default fichaAviso
