const filters = {
  filtros: {
    placeholderSearchFacet: {
      provincia: 'una Provincia',
      localidad: 'un Cantón',
    },
  },
}

export default filters
