import { merge } from 'lodash'
import es from '../es'
import metatagsListado from './metatags-listado'
import { currencyMask } from './currency-mask'
import { seoWordings as seoWordingsMx } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import modalCrearAlerta from './modal-crear-alerta'
import filtersSettings from './filters-settings'
import { facetsName as facetsNameMx } from './facets-name'
import { facetsName as facetsNameEs } from '../es/facets-name'
import curriculumEs from '../es/curriculum'
import curriculumMx from './curriculum'
import resendEmail from './resend-email'
import newFeatureEs from '../es/new-feature'
import newFeatureMx from './new-feature'
import companySummary from '../es/company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesMx from './postulaciones'
import accountMx from './account'
import accountEs from '../es/account'
import { products as productsEs } from '../es/products'
import { products as productsMx } from './products'
import filters from './filters'
import filtersEs from '../es/filters'

const mx = {
  ...es,
  cv: 'CV',
  ...metatagsListado,
  ...filtersSettings,
  newFeature: merge({}, newFeatureEs, newFeatureMx),
  modalCrearAlerta,
  currencyMask,
  'Postulantes Title': 'Empleos en México: trabajo y ofertas de empleo - Bumeran 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en México en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Bumeran.',
  Buscar: 'Buscar MX',
  'Ir al inicio': 'Ir al inicio MX',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: '$',
    'currency-format': 'XXX,XXX,XXX.XX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - bumeran.com.mx',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en bumeran Empleos.',
  facets: {
    ...es.facets,
    provincia: 'Estado',
  },
  seoWordings: merge({}, seoWordingsEs, seoWordingsMx),
  facetsName: merge({}, facetsNameEs, facetsNameMx),
  curriculum: merge({}, curriculumEs, curriculumMx),
  ...resendEmail,
  companySummary,
  postulaciones: merge({}, postulacionesEs, postulacionesMx),
  account: merge({}, accountEs, accountMx),
  products: merge({}, productsEs, productsMx),
  ...merge({}, { ...filtersEs }, { ...filters }),
}

export default mx
