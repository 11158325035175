export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS'
export const SET_FILTERS_ERROR = 'SET_FILTERS_ERROR'

export const SET_FILTERS_SETTINGS = 'SET_FILTERS_SETTINGS'
export const SET_FILTERS_SETTINGS_SUCCESS = 'SET_FILTERS_SETTINGS_SUCCESS'
export const SET_FILTERS_SETTINGS_ERROR = 'SET_FILTERS_SETTINGS_ERROR'

export const SET_FILTERS_DEFAULT = 'SET_FILTERS_DEFAULT'
export const SET_FILTERS_DEFAULT_SUCCESS = 'SET_FILTERS_DEFAULT_SUCCESS'
export const SET_FILTERS_DEFAULT_ERROR = 'SET_FILTERS_DEFAULT_ERROR'

export const SET_FILTERS_DELETE_ALL = 'SET_FILTERS_DELETE_ALL'
export const SET_FILTERS_DELETE_ALL_SUCCESS = 'SET_FILTERS_DELETE_ALL_SUCCESS'
export const SET_FILTERS_DELETE_ALL_ERROR = 'SET_FILTERS_DELETE_ALL_ERROR'

export const SET_FILTERS_HIDE = 'SET_FILTERS_HIDE'
export const SET_FILTERS_HIDE_SUCCESS = 'SET_FILTERS_HIDE_SUCCESS'
export const SET_FILTERS_HIDE_ERROR = 'SET_FILTERS_HIDE_ERROR'

export const SET_FILTERS_APPLIED = 'SET_FILTERS_APPLIED'

export interface FilterCore {
  type: string
  facets: {
    id: string
    idSemantico?: string
    name: string
    quantity: number
  }[]
}

export interface FilterApplied {
  id: string
  idSemantico?: string
  name?: string
  type: string
  value?: string
}

interface Filter {
  id: string
  value: string
}

export interface SetFilters {
  type: typeof SET_FILTERS
  payload: any
}

export interface SetFiltersSuccess {
  type: typeof SET_FILTERS_SUCCESS
  payload: any
}

export interface SetFiltersError {
  type: typeof SET_FILTERS_ERROR
  payload: null
}

export interface SetFiltersSettings {
  type: typeof SET_FILTERS_SETTINGS
  payload: null
}

export interface SetFiltersSettingsSuccess {
  type: typeof SET_FILTERS_SETTINGS_SUCCESS
  payload: any
}

export interface SetFiltersSettingsError {
  type: typeof SET_FILTERS_SETTINGS_ERROR
  payload: null
}

export interface SetFiltersDefault {
  type: typeof SET_FILTERS_DEFAULT
  payload: any
}

export interface SetFiltersDefaultSuccess {
  type: typeof SET_FILTERS_DEFAULT_SUCCESS
  payload: any
}
export interface SetFiltersDefaultError {
  type: typeof SET_FILTERS_DEFAULT_ERROR
  payload: null
}

export interface SetFiltersDeleteAll {
  type: typeof SET_FILTERS_DELETE_ALL
  payload: boolean
}

export interface SetFiltersDeleteAllSuccess {
  type: typeof SET_FILTERS_DELETE_ALL_SUCCESS
  payload: boolean
}

export interface SetFiltersDeleteAllError {
  type: typeof SET_FILTERS_DELETE_ALL_ERROR
  payload: null
}

export interface SetFiltersHide {
  type: typeof SET_FILTERS_HIDE
  payload: any
}

export interface SetFiltersHideSuccess {
  type: typeof SET_FILTERS_HIDE_SUCCESS
  payload: any
}

export interface SetFiltersHideError {
  type: typeof SET_FILTERS_HIDE_ERROR
  payload: null
}

export interface SetFiltersApplied {
  type: typeof SET_FILTERS_APPLIED
  payload: any
}

export interface FiltersSettings {
  defaultFiltersApplied: {
    name: string
    option: {
      label: string
      value: string
    }
  } | null
  enabled: boolean
  deleteAll: boolean
  filters: {
    type: string
    label: string
    order: number
    fixed: boolean
    fixedUntil: number | null
    disabled: boolean
    icon: string
    options?: {
      menuWidth: string
      withLabelNew: boolean
      withCheckbox: boolean
      prefix: string
    }
  }
}

export interface FiltersState {
  loading: boolean
  error: boolean
  filters: FilterCore[] | null
  filtersApplied?: FilterApplied[] | null
  filtersSettings: FiltersSettings | null
}

export const initialState: FiltersState = {
  loading: false,
  error: false,
  filters: null,
  filtersApplied: null,
  filtersSettings: null,
}

export type FiltersActions =
  | SetFilters
  | SetFiltersSuccess
  | SetFiltersError
  | SetFiltersSettings
  | SetFiltersSettingsSuccess
  | SetFiltersSettingsError
  | SetFiltersDefault
  | SetFiltersDefaultSuccess
  | SetFiltersDefaultError
  | SetFiltersDeleteAll
  | SetFiltersDeleteAllSuccess
  | SetFiltersDeleteAllError
  | SetFiltersHide
  | SetFiltersHideSuccess
  | SetFiltersHideError
  | SetFiltersApplied
