import { merge } from 'lodash'
import es from '../es'
import metatagsListado from './metatags-listado'
import filtersSettings from './filters-settings'
import { currencyMask } from './currency-mask'
import { seoWordings as seoWordingsPe } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import modalCrearAlerta from './modal-crear-alerta'
import curriculumEs from '../es/curriculum'
import curriculumPe from './curriculum'
import companySummary from '../es/company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesPe from './postulaciones'
import { products } from './products'
import { nulinga as nulingaEs } from '../es/nulinga'
import { nulinga as nulingaPe } from './nulinga'
import { paymentMP as paymentMPEs } from '../es/payment-mp'
import { paymentMP as paymentMPPe } from './payment-mp'
import filtersEs from '../es/filters'
import filtersPe from './filters'
import fichaAviso from './ficha-aviso'
import fichaAvisoEs from '../es/ficha-aviso'
import feriaEs from '../es/feria'
import feriaPe from './feria'

const pe = {
  ...es,
  cv: 'CV',
  ...metatagsListado,
  ...filtersSettings,
  currencyMask,
  ...modalCrearAlerta,
  'Postulantes Title': 'Empleos en Perú: trabajo y ofertas de empleo - Bumeran 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Perú en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Bumeran.',
  Buscar: 'Buscar PA',
  'Ir al inicio': 'Ir al inicio PA',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: 'S/.',
    'currency-format': 'XXX.XXX.XXX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - bumeran.com.pe',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en bumeran Empleos.',
  seoWordings: merge({}, seoWordingsEs, seoWordingsPe),
  curriculum: merge({}, curriculumEs, curriculumPe),
  companySummary,
  postulaciones: merge({}, postulacionesEs, postulacionesPe),
  products: merge({}, es.products, products),
  nulinga: merge({}, nulingaEs, nulingaPe),
  paymentMP: merge({}, paymentMPEs, paymentMPPe),
  ...merge({}, { ...filtersEs }, { ...filtersPe }),
  fichaAviso: merge({}, fichaAvisoEs, fichaAviso),
  feria: merge({}, feriaEs, feriaPe),
}

export default pe
