import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { NOMBRE_PAIS, NOMBRE_SITIO, OG_ID_BY_SITE, BASE_URL } from '../../../../constants'

const Metas = () => {
  const { t } = useTranslation()
  const pageTitle = t('curriculum.marketplace.menu')
  const pageDescription = t('seoWordings.home.description', { countryName: NOMBRE_PAIS, siteName: NOMBRE_SITIO })
  const ulrCanonical = `${window.SEO_PROTOCOL || window.location.protocol}//${window.SEO_HOST || window.location.host}`

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <title>{pageTitle}</title>
      <meta name="description" property="og:description" content={pageDescription} />
      <meta property="og:image" content={`${BASE_URL}/favicon/social-logo.png`} />
      <meta property="og:image:height" content="200" />
      <meta property="og:image:width" content="200" />
      <meta property="og:site_name" content={NOMBRE_SITIO} />
      <meta property="og:url" content={ulrCanonical} />
      <meta property="og:locale" content="es_LA" />
      <meta property="fb:app_id" content={OG_ID_BY_SITE[NOMBRE_SITIO]} />
      <link rel="canonical" href={ulrCanonical} />
    </Helmet>
  )
}

export default Metas
