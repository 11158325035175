import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// ui-kit
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { RedirectHeaderMeta } from '@navent-jobs/ui-kit/components/redirect-prerender-meta'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

import { handleSmoothScroll } from '../../hooks/handle-smooth-scroll'

// components
import Metas from './components/Metas'
import Navbar from './components/Navbar'
import Header from './components/Header'
import ResultadoCalificacionEmpresa from '../../components/resultado-calificacion-empresa'
import ComplementaryInfo from './components/ComplementaryInfo'
import Publications from './components/Publications'
import ModalQrSuccess from './components/Modals/postulation-qr-success'
import ModalQrValidate from './components/Modals/postulation-qr-validate'
import LookAndFeel from '../ficha-aviso/components/ficha-aviso/components/LookAndFeel'

// mixins
import {
  Page,
  StickyContainerHeader,
  ColCustom,
  CvAnonimoDescription,
  IconWrapper,
  MinisitioContainer,
  ImageWrapper,
  Img,
  Spacer,
  VideoSpacer,
  StickyContainerWrapper,
} from './mixins'

// constants
import { NOMBRE_SITIO, BASE_URL, SITE_ID } from '../../constants'
import features from '../../constants/features-per-country'

// redux
import { CompanySummaryState, CompanySummaryStatus } from '../../redux/types/company-summary/commons'
import { getListadoAvisosEmpresa, getFichaEmpresa } from '../../redux/actions/company-summary'
import { hideModal, showModal } from '../../redux/actions/modal'
import { sendActivationEmail } from '../../redux/actions/activation-email'
import { ActivationEmailState } from '../../redux/types/activation-email'

import { scrollToSection } from './utils/scroll-to-section'
import BannerGptw from '../../components/Gptw/banner'
import Card from './components/commons/Card'
import EmbedVideo from './components/ComplementaryInfo/EmbedVideo'
import { useTranslation } from 'react-i18next'

interface CompanySummaryProps {
  listadoAvisosEmpresa?
  listadoAvisosStatus: CompanySummaryStatus
  emailStatus: ActivationEmailState['states']
  fichaEmpresa?
  match
  visible
  applicant
  reviews
  applicantReview
  loadingReviews
  filters
}

const reviewEnabled = features[SITE_ID || 'BMAR'].reviews.enabled
const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled

const AVISOS_PAGE_SIZE = 20

// evitamos magicNumber de sonar
const size12 = 12
const size9 = 9
const size3 = 3

const HASH_PUBLICATIONS = '#avisos'
const ID_PUBLICATIONS = 'section-avisos'

const scrollToAnchor = ({ hash, listadoAvisosEmpresa, stickyHeaderRef, stickyTabsRef }) => {
  if (hash === HASH_PUBLICATIONS && listadoAvisosEmpresa) {
    setTimeout(() => {
      // Calculo offset con la altura de los sticky headers
      const headerDesktop = document.getElementById('header-search')?.offsetHeight || 0
      const headerCompany = stickyHeaderRef?.current?.offsetHeight || 0
      const tabs = stickyTabsRef?.current?.offsetHeight || 0
      const margin = headerDesktop ? -2 : 4
      handleSmoothScroll(`#${ID_PUBLICATIONS}`, headerDesktop + headerCompany + tabs + margin)
    }, 1000)
  }
}

const RedirectByWrongId = ({ id, url }) => {
  if (!id || Number.isNaN(id) || id < 0) {
    return (
      <>
        <RedirectHeaderMeta url={url} contentCode="410" />
        <Redirect to="/404" />
      </>
    )
  }
  return null
}

const CompanySummary = ({
  listadoAvisosEmpresa,
  listadoAvisosStatus,
  fichaEmpresa,
  visible,
  match,
  applicant,
  emailStatus,
  reviews,
  applicantReview,
  loadingReviews,
  filters,
}: CompanySummaryProps) => {
  // TODO datoEmpresa debe de llegar del store
  const dispatch = useDispatch()
  const location = useLocation()

  const rating =
    reviewEnabled && reviews?.totalEmployeeReviews >= 3
      ? reviews?.categories?.find(c => c.categoryId === 1)?.average
      : null
  const gptwUrl = fichaEmpresa?.gptwUrl

  const { origin } = location.state ?? {}
  const modalQrSuccessVisible = visible === 'modal-qr-success'
  const modalQrValidateVisible = visible === 'modal-qr-validate'

  // obtenemos el id empresa de la url
  const currentUrl = window.location.pathname
  const pieces = currentUrl.split('_')
  const dirtyId = pieces[pieces.length - 1].split('.')
  const id = parseInt(dirtyId[0], 10)
  const totalPages = listadoAvisosStatus.success ? Math.ceil(listadoAvisosEmpresa.total / AVISOS_PAGE_SIZE) : 0
  const pageParam = Number(new URLSearchParams(window.location.search).get('page'))
  const currentPage = pageParam === 0 ? 0 : pageParam - 1
  const queryParams = new URLSearchParams(window.location.search)

  const shareData = {
    shareUrl: `${BASE_URL}${currentUrl}`,
    shareTitle: `Ofertas de trabajo de ${fichaEmpresa?.denominacion}`,
    shareHelperDescription: `Te comparto los avisos de ${fichaEmpresa?.denominacion} en ${NOMBRE_SITIO}`,
  }

  const { hash } = window.location
  const stickyHeaderRef = useRef<HTMLDivElement>(null)
  const stickyTabsRef = useRef<HTMLDivElement>(null)

  // Definimos sector de referencia y luego la accion de scroll
  const scrollRef = useRef<HTMLDivElement>(null)

  // Se definen variables para la anchorbar
  const [visibleId, setVisibleid] = useState('section-detalle')
  const [isNavResized, setNavResize] = useState(false)
  const { isMobile, isTabletPortrait } = useWindowSize()
  const videoUrl = fichaEmpresa?.videoUrl?.startsWith('http') ? fichaEmpresa.videoUrl : null
  const { colors } = useContext<{ colors }>(ThemeContext)
  const { t } = useTranslation()
  const mobileView = true

  const companyHasMiniSitio = !!fichaEmpresa?.minisitio
  const companyHasPortada = !!fichaEmpresa?.portadaUrl
  const isMobileOrPortrait = isMobile || isTabletPortrait
  const [top, setTop] = useState<number | null>(null)

  useEffect(() => {
    const handleChangeTop = () => {
      if (fichaEmpresa) {
        setTop(fichaEmpresa?.productoLookAndFeel ? (isMobileOrPortrait ? -17 : -32) : 0)
      }
    }
    handleChangeTop()
    window.addEventListener('resize', handleChangeTop)
    return () => {
      window.removeEventListener('resize', handleChangeTop)
    }
  }, [fichaEmpresa])

  // Handler - Cambio de pagina
  const pageHandler = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView(true)
      window.scrollBy(0, -100)
    }
  }

  const sendActivationMail = () => {
    dispatch(
      sendActivationEmail({
        fetchMail: 'activar-cuenta',
        applicantMail: applicant && applicant.email,
      })
    )
  }

  useEffect(() => {
    const scrollFunction = e => {
      const scrollTop = e?.srcElement?.documentElement?.scrollTop
      scrollToSection({ scrollTop, setVisibleid, setNavResize })
    }

    window.addEventListener('scroll', scrollFunction, true)

    return () => {
      setNavResize(false)
      window.removeEventListener('scroll', scrollFunction, true)
    }
  }, [isMobile, isTabletPortrait])

  // si el id de la empresa en la irl cambia consultamos nuevamente la data
  useEffect(() => {
    if (!Number.isNaN(id)) {
      dispatch(getFichaEmpresa(id))
    }
  }, [id])

  // Effect - Carga el listado de avisos
  useEffect(() => {
    if (!Number.isNaN(id)) {
      dispatch(
        getListadoAvisosEmpresa({
          empresaId: id,
          page: currentPage,
          pageSize: AVISOS_PAGE_SIZE,
          filters,
        })
      )
    }
  }, [match.url, location.search, filters])

  // Effect - mostrar modal status postulacionpor QR
  useEffect(() => {
    if (origin === 'POSTULACION_QR' && applicant) {
      if (applicant.mailValidado) {
        dispatch(showModal('modal-qr-success'))
      } else {
        dispatch(showModal('modal-qr-validate'))
      }
    }
  }, [applicant, origin, visible === 'modal-activar-cuenta'])

  useLayoutEffect(() => {
    scrollToAnchor({ hash, listadoAvisosEmpresa, stickyHeaderRef, stickyTabsRef })
  }, [hash, listadoAvisosEmpresa])

  return (
    <>
      <Page>
        <Metas company={fichaEmpresa} />

        <RedirectByWrongId id={id} url={currentUrl} />

        <StickyContainerHeader id="navbar-header" ref={stickyHeaderRef}>
          {/* Navbar mobile and tablet */}
          <Navbar company={fichaEmpresa} isLoading={!fichaEmpresa} shareData={shareData} />
        </StickyContainerHeader>
        {fichaEmpresa?.productoLookAndFeel && (
          <LookAndFeel productoLookAndFeel={fichaEmpresa?.productoLookAndFeel} defaultImg={true} />
        )}
        <StickyContainerWrapper id="sticky-container-feria">
          {/* Page Header */}
          <Header
            company={fichaEmpresa}
            isLoading={!fichaEmpresa}
            shareData={shareData}
            rating={rating}
            applicantReview={!!applicantReview}
            loadingReviews={loadingReviews}
            visibleId={visibleId}
            isNavResized={isNavResized}
            top={top}
          />
        </StickyContainerWrapper>
        <MainContainer>
          <Row>
            <ColCustom xs={{ size: size12 }} lg={{ size: size3 }}>
              {/* Complementary Content */}
              <div id="section-detalle">
                <ComplementaryInfo company={fichaEmpresa} isLoading={!fichaEmpresa} showDescription={true} />

                {fichaEmpresa?.inclusiva && (
                  <CvAnonimoDescription>
                    <IconWrapper>
                      <Icon name="icon-light-info" color={colors.secondary.normal} size="20" />
                    </IconWrapper>
                    {t('companySummary.publications.cvAnonimoDescription', { cv: t('cv') })}
                  </CvAnonimoDescription>
                )}
                {videoUrl && (
                  <VideoSpacer>
                    <Card>
                      <EmbedVideo videoUrl={videoUrl} />
                    </Card>
                  </VideoSpacer>
                )}

                {gptwEnabled && gptwUrl && <BannerGptw mobileView={mobileView} url={gptwUrl} />}
              </div>
              {/* Calificaciones */}
              {reviewEnabled && (
                <div id="section-calificaciones">
                  <ResultadoCalificacionEmpresa
                    isLoading={!fichaEmpresa}
                    idCompany={fichaEmpresa?.id}
                    denominacion={fichaEmpresa?.denominacion}
                    gptw={gptwEnabled && gptwUrl}
                    showCommentSection
                    mobileView={mobileView}
                  />
                </div>
              )}
            </ColCustom>
            <Col xs={{ size: size12 }} lg={{ size: size9 }}>
              <div>
                {window.innerWidth >= 992 && (
                  <>
                    {/* Minisitio */}
                    {companyHasMiniSitio && (
                      <Spacer>
                        <Card>
                          <MinisitioContainer
                            dangerouslySetInnerHTML={{ __html: fichaEmpresa?.minisitio }}
                          ></MinisitioContainer>
                        </Card>
                      </Spacer>
                    )}
                    {/* Imagen de Portada/banner */}
                    {companyHasPortada && !companyHasMiniSitio && (
                      <ImageWrapper>
                        <Img src={fichaEmpresa?.portadaUrl} />
                      </ImageWrapper>
                    )}
                  </>
                )}
                {/* Listado */}
                <div id={ID_PUBLICATIONS} ref={scrollRef}>
                  <Publications
                    company={fichaEmpresa}
                    avisos={listadoAvisosEmpresa}
                    page={currentPage}
                    totalPages={totalPages}
                    pageHandler={pageHandler}
                    successLoad={listadoAvisosStatus.success}
                    queryParams={queryParams}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </MainContainer>
      </Page>

      {/* Modals */}
      <ModalQrValidate
        onClose={() => dispatch(hideModal())}
        onMailSending={() => sendActivationMail()}
        visible={modalQrValidateVisible}
        nombreEmpresa={fichaEmpresa?.denominacion}
        sended={emailStatus}
      />

      <ModalQrSuccess
        onClose={() => dispatch(hideModal())}
        visible={modalQrSuccessVisible}
        nombreEmpresa={fichaEmpresa?.denominacion}
      />
    </>
  )
}

const state = ({
  companySummaryStore,
  modalStore,
  applicantStore,
  activationEmailStore,
  reviewStore,
}: {
  companySummaryStore: CompanySummaryState
  modalStore
  applicantStore
  activationEmailStore
  reviewStore
}) => {
  return {
    applicant: applicantStore ? applicantStore.applicant : {},
    emailStatus: activationEmailStore ? activationEmailStore.states : {},
    listadoAvisosEmpresa: companySummaryStore?.listadoAvisos,
    listadoAvisosStatus: companySummaryStore.listadoAvisosStatus,
    fichaEmpresa: companySummaryStore?.fichaEmpresa,
    filters: companySummaryStore?.filters,
    visible: modalStore?.activeModal,
    reviews: reviewStore.reviews,
    loadingReviews: reviewStore.states.loading || reviewStore.reviewsCompanyStates.loading,
    applicantReview: reviewStore.applicantReview,
  }
}
export default connect(state)(CompanySummary)
