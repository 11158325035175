import { Request, Get, Post } from '@navent-jobs/config'
import { GetFichaEmpresaFeriaRequestDto } from '../redux/types/feria'

export class ErrorFeriaService extends Error {}

const DEFAULT_PAGE_SIZE = 9

class FeriaService {
  public async getFichaEmpresa({ idFeria, idEmpresa }: GetFichaEmpresaFeriaRequestDto) {
    const request = new Request()
    request.method = Get
    request.path = `/api/feria/ficha-empresa/${idFeria}?idEmpresa=${idEmpresa}`
    request.headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' }

    return request.call().catch(() => {
      throw new ErrorFeriaService('Error obteniendo la ficha de empresa feria')
    })
  }

  public getListadoEmpresas = async ({ page = 0, pageSize = DEFAULT_PAGE_SIZE, filtros, idFeria }) => {
    const request = new Request()
    request.path = `/api/feria/${idFeria}/listado-empresas?pageSize=${pageSize}&page=${page}`
    const filterQuery = filtros.query ? { query: filtros.query } : {}
    request.body = {
      ...filterQuery,
      industries: filtros.industries,
      provinces: filtros.provinces,
      sortByRating: filtros.sortByRating,
      sort: filtros.sort,
    }
    request.method = Post
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorFeriaService(error)
      })
  }

  public getFeria = async (slug: string) => {
    const request = new Request()
    request.path = `/api/feria/slug/${slug}`
    request.method = Get
    return request
      .call()
      .then(response => {
        if (response.error) {
          throw new ErrorFeriaService()
        }
        return response.feria
      })
      .catch(error => {
        throw new ErrorFeriaService(error)
      })
  }

  public getConferencias = async (idFeria: number) => {
    const request = new Request()
    request.path = `/api/feria/${idFeria}/conferencias`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorFeriaService(error)
      })
  }

  public getConferenciasEmpresa = async ({ idFeria, idEmpresa }: { idFeria: number; idEmpresa: number }) => {
    const request = new Request()
    request.path = `/api/feria/${idFeria}/conferencias?idEmpresa=${idEmpresa}`
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorFeriaService(error)
      })
  }

  public getVideosConferencia = async (idFeria: number) => {
    const request = new Request()
    request.path = `/api/feria/${idFeria}/conferencias/videos`
    request.method = Get
    return request.call().catch(error => {
      throw new ErrorFeriaService(error)
    })
  }
}

const feriaService = new FeriaService()
export default feriaService
