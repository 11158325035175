import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const ElementsContainer = styled.div<{ theme }>`
  display: flex;
  text-align: center;
  align-item: center;
  flex-direction: column;
  padding: 0 16px;
  margin: 64px auto;
  justify-content: center;
`

export const ImageForEmptyState = styled.img<{ theme }>`
  width: 100%;
  max-width: 288px;
  margin: 0 auto 40px;

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    max-width: 240px;
  }
`

export const TextContainer = styled.div`
  margin: auto;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    max-width: 400px;
  }
`

export const Subtitle = styled.p<{ theme }>`
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 24, 0.84);
  margin: 24px 0px 0px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0px 0px;
  }
`
export const ComponentContainer = styled('div')`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    margin-top: 25px;
  }
`

export const CustomButton = styled(Button)`
  width: 100%;
  max-width: 194px;
`
