import { TagManager } from '@navent-jobs/config'

const translateFacet = facetName => {
  switch (facetName) {
    case 'province':
      return 'Provincia'
    case 'industry':
      return 'Industria'
    default:
      return facetName
  }
}

export const DataLayerListadoEmpresas = {
  isLogged: logged =>
    TagManager.Push({
      event: 'trackEvent',
      content_group: 'Listado de empresas',
      isLogged: logged ? 'Si' : 'No',
    }),
  goToCompanyListHeader: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Ir a listado de empresas',
      eventLabel: `CTA-Listado de empresas-Header`,
    }),
  filter: (facet, value) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: `Filtro-${translateFacet(facet)}`,
      eventLabel: value,
    }),
  goToCompany: companyName =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Ir al micrositio de empresa',
      eventLabel: companyName,
    }),
  goToRecommendedCompany: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Buscador empresa',
      eventLabel: `Sin resultados-Click aviso ${from ? 'listado recomendado' : 'listado del dia'}`,
    }),
  mostWantedCompanies: companyName =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Empresas mas buscadas',
      eventLabel: companyName,
    }),
  search: term =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Buscador empresa',
      eventLabel: term,
    }),
  searchWithoutResult: logged =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Buscador empresa',
      eventLabel: `Sin resultados-${logged ? 'Ve listado recomendados' : 'Ve listado del dia'}`,
    }),
  ordenamiento: orden => {
    return TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Listado de empresas',
      eventAction: 'Ordenamiento',
      eventLabel: orden,
    })
  },
}
