import { Request, Post } from '@navent-jobs/config'
import { SwitchDisponibleInmediatoType } from '../redux/types/disponible-inmediato'

export class ErrorApplicant extends Error {}

class DisponibleInmediato {
  public async postDisponibleInmediato(disponible: SwitchDisponibleInmediatoType) {
    const request = new Request()
    request.path = `/api/disponible-inmediato?disponible=${disponible}`
    request.method = Post
    return request.call().catch(error => {
      throw new ErrorApplicant('Error al cambiar disponibile-inmediato', error)
    })
  }
}

const disponibleInmediatoService = new DisponibleInmediato()
export default disponibleInmediatoService
