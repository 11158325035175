import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CustomButton, IconContainer, IconCustom, CustomIconButton } from './mixins'
import SeguirButtonPlaceholder from './placeholder'

import { userLoggedIn } from '../../../../utils/cookies'
import { dejarDeSeguirEmpresa, seguirEmpresa, esSeguidor } from '../../../../redux/actions/company-summary'

interface SeguirButtonProps {
  empresaId: number
  siguiendo?: boolean
  isLoading?: boolean
  loadingState: boolean
  successState: boolean
  onlyIcon?: boolean
}

const SeguirButton = ({
  empresaId,
  siguiendo,
  isLoading,
  loadingState,
  successState,
  onlyIcon = false,
}: SeguirButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isUserLogged = userLoggedIn()
  const history = useHistory()

  const [buttonText, setButtonText] = useState(t('companySummary.seguirEmpresa.siguiendo'))
  const [isSaving, setIsSaving] = useState(false)
  const [hideIcon, setHideIcon] = useState(false)

  useEffect(() => {
    if (isUserLogged && empresaId && !successState) {
      dispatch(esSeguidor(empresaId))
    }
  }, [empresaId, successState])

  useEffect(() => {
    setIsSaving(loadingState)
  }, [loadingState])

  const seguirEmpresaHandler = () => {
    if (!isUserLogged) {
      history.push(`/login?returnTo=${window.location.pathname}`)
      return
    }

    if (siguiendo) {
      dispatch(dejarDeSeguirEmpresa(empresaId))
    } else {
      dispatch(seguirEmpresa(empresaId))
    }
  }

  const setDejarDeSeguirText = () => {
    if (siguiendo) {
      setButtonText(t('companySummary.seguirEmpresa.dejarDeSeguir'))
    }
  }

  const setSiguiendoText = () => {
    if (siguiendo) {
      setButtonText(t('companySummary.seguirEmpresa.siguiendo'))
    }
  }

  return isLoading ? (
    <SeguirButtonPlaceholder />
  ) : onlyIcon ? (
    <CustomIconButton
      onClick={seguirEmpresaHandler}
      buttonoptions={{
        block: false,
        size: 'medium',
        type: 'link',
        variant: siguiendo ? 'primary' : 'black',
      }}
      disabled={isSaving}
    >
      <IconCustom name={siguiendo ? 'icon-bold-heart' : 'icon-light-heart'} size={'24'} />
    </CustomIconButton>
  ) : (
    <CustomButton
      onClick={seguirEmpresaHandler}
      onFocus={setSiguiendoText}
      onMouseOver={() => {
        if (siguiendo) {
          setHideIcon(true)
        }
        setDejarDeSeguirText()
      }}
      onMouseLeave={() => {
        if (siguiendo) {
          setHideIcon(false)
        }
        setSiguiendoText()
      }}
      buttonoptions={{
        block: false,
        size: 'medium',
        type: 'outline',
        variant: 'primary',
      }}
      isLoading={isSaving}
    >
      <IconContainer>
        {!hideIcon && <IconCustom name={siguiendo ? 'icon-bold-heart' : 'icon-light-heart'} size={'24'} />}
        {siguiendo ? buttonText : t('companySummary.seguirEmpresa.seguir')}
      </IconContainer>
    </CustomButton>
  )
}

const state = ({ companySummaryStore }) => ({
  siguiendo: companySummaryStore?.siguiendo,
  loadingState: companySummaryStore?.siguiendoStates?.loading || companySummaryStore?.getSiguiendoStates?.loading,
  successState: companySummaryStore?.getSiguiendoStates?.success,
})

export default connect(state)(SeguirButton)
