import React, { ReactNode } from 'react'

import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

import mailService from '../../../services/mail-service'

import Header from '../../Header'

import Footer from '../../Footer'
import BottomNavBar from './components/bottom-navbar'

import CTADownloadApp from '../../CtaDownloadApp'
import ModalActivarCuenta from '../../modals/activar-cuenta/ModalActivarCuenta'

import { GlobalStyles, Page } from './styles'
import { SITE_ID } from '../../../constants'
import features from '../../../constants/features-per-country'

interface ComponentProps {
  children: ReactNode
  location
  hideWithPath?: string[]
  hideBottomNavbar?: boolean
  singleHeader?: boolean
}

const navbarMobile = features[SITE_ID || 'BMAR']?.navbarMobile?.enabled

const Component = (props: ComponentProps) => {
  const { children, hideBottomNavbar, ...rest } = props
  const { isDesktop } = useWindowSize()
  const showBottomNavBar = navbarMobile && !isDesktop && !hideBottomNavbar

  return (
    <>
      <GlobalStyles singleHeader={props.singleHeader} />

      <Page>
        <Header {...rest} />
        {showBottomNavBar && <BottomNavBar />}
        {children}

        <Footer showBottomNavBar={showBottomNavBar} />
        <ModalActivarCuenta mailService={mailService} />
        {SITE_ID !== 'ZJAR' && <CTADownloadApp type="mobile" />}
      </Page>
    </>
  )
}

export default Component
