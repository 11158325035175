import { Request, Post } from '@navent-jobs/config'

export class ErrorCvParser extends Error {}

class CvParser {
  public cvParserUploadFile = async file => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    const request = new Request()
    request.method = Post
    request.path = `api/cv-parser`
    request.body = formData
    request.headers = {
      'Content-Type': 'multipart/form-data',
    }

    return request.call().catch(() => {
      throw new ErrorCvParser(`No se pudo analizar el archivo`)
    })
  }
}

const cvParser = new CvParser()

export default cvParser
