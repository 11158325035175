import { Request, Get } from '@navent-jobs/config'

export class ErrorEmpresaSeguida extends Error {}

const DEFAULT_PAGE_SIZE = 6

class EmpresasSeguidasService {
  public getEmpresasSeguidas = async ({ page = 0, pageSize = DEFAULT_PAGE_SIZE }) => {
    const request = new Request()
    request.path = `/api/postulante/empresa/seguidas?pageSize=${pageSize}&page=${page}`
    request.method = Get
    return request
      .call()
      .then(response => {
        const pagination = {
          currentPage: response ? response.number + 1 : 1,
          totalPages: response.total > pageSize ? Math.ceil(response.total / response.size) : 0,
          elementsPerPage: response ? response.size : pageSize,
          totalElements: response ? response.total : 0,
        }

        const empresasSeguidas = response && response.content.length > 0 ? response.content : null

        return {
          pagination,
          empresasSeguidas,
        }
      })
      .catch(error => {
        throw new ErrorEmpresaSeguida(error)
      })
  }
}

const empresasSeguidasService = new EmpresasSeguidasService()
export default empresasSeguidasService
