import { status, StatusType } from './status'

/**
 * ---------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------
 */

export const SWITCH_DISPONIBLE_OFERTAS = 'SWITCH_DISPONIBLE_OFERTAS'
export const SWITCH_DISPONIBLE_OFERTAS_SUCCESS = 'SWITCH_DISPONIBLE_OFERTAS_SUCCESS'
export const SWITCH_DISPONIBLE_OFERTAS_ERROR = 'SWITCH_DISPONIBLE_OFERTAS_ERROR'

/**
 * ---------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------
 */

// switch
export interface SwitchDisponibleOfertasType {
  disponible: boolean
}

/**
 * ---------------------------------------------------------------------------
 * Store
 * ---------------------------------------------------------------------------
 */
export interface DisponibleOfertasState {
  switchDisponibleOfertas: {
    value: SwitchDisponibleOfertasType | null
    status: StatusType
  }
}

export const initialState: DisponibleOfertasState = {
  switchDisponibleOfertas: {
    value: null,
    status: status.initial,
  },
}

/**
 * ---------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------
 */

// switch
export interface SwitchDisponibleOfertas {
  type: typeof SWITCH_DISPONIBLE_OFERTAS
  payload: SwitchDisponibleOfertasType
}

export interface SwitchDisponibleOfertasSuccess {
  type: typeof SWITCH_DISPONIBLE_OFERTAS_SUCCESS
  payload: null
}
export interface SwitchDisponibleOfertasError {
  type: typeof SWITCH_DISPONIBLE_OFERTAS_ERROR
  payload: null
}

export type DisponibleOfertasActions =
  | SwitchDisponibleOfertas
  | SwitchDisponibleOfertasSuccess
  | SwitchDisponibleOfertasError
