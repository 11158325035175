import {
  DOWNLOAD_CURRICULUM,
  DOWNLOAD_CURRICULUM_SUCCESS,
  DOWNLOAD_CURRICULUM_ERROR,
  State,
  Actions,
  initialState,
} from '../types/applicant/descarga-curriculum'

const reducers = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case DOWNLOAD_CURRICULUM:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      }
    case DOWNLOAD_CURRICULUM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      }
    case DOWNLOAD_CURRICULUM_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export default reducers
