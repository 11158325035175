import React from 'react'
import { CustomChip } from './mixins'

interface Props {
  busquedaActiva?: boolean
}

const BusquedaActivaChip = ({ busquedaActiva }: Props) => {
  return busquedaActiva ? (
    <CustomChip
      className="company-active-searching"
      chipOptions={{
        color: 'extra',
        size: 'medium',
        skin: 'fill',
        variant: 'lighten',
      }}
      iconName="icon-light-search"
      text="Empresa busca activamente"
    />
  ) : null
}

export default BusquedaActivaChip
