import React from 'react'
import { Trans } from 'react-i18next'
import { Card, SubtitleText, Divider, InfoText, Container } from './mixins'
import NotEndedCard from '../notification-card'

const InfoCards = () => {
  return (
    <>
      <Card>
        <SubtitleText>
          <Trans i18nKey="saveCompany.cards.info.text" components={[<strong></strong>]} />
        </SubtitleText>
        <Divider />
        <Container>
          <InfoText>
            <Trans i18nKey="saveCompany.cards.info.mail" components={[<strong></strong>]} />
          </InfoText>
          <InfoText>
            <Trans i18nKey="saveCompany.cards.info.popup" components={[<strong></strong>]} />
          </InfoText>
        </Container>
      </Card>
      <NotEndedCard />
    </>
  )
}

export default InfoCards
