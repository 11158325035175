export const facetsName = {
  // FACET TYPE
  tipo_trabajo: {
    // FACET_ID: "FACET NAME TRANSLATED"
    'full-time': 'Tiempo completo',
    'part-time': 'Medio tiempo',
  },
  area: {
    'marketing-y-publicidad': 'Mercadotecnia y Publicidad',
  },
  subarea: {
    marketing: 'Mercadotecnia',
  },
  url: {
    'tipo-trabajo': {
      'full-time': 'tiempo-completo',
      'part-time': 'medio-tiempo',
    },
    area: {
      'marketing-y-publicidad': 'mercadotecnia-y-publicidad',
    },
    subarea: {
      marketing: 'mercadotecnia',
    },
  },
  'url-vuelta': {
    'tipo-trabajo': {
      'tiempo-completo': 'full-time',
      'medio-tiempo': 'part-time',
    },
    area: {
      'mercadotecnia-y-publicidad': 'marketing-y-publicidad',
    },
    subarea: {
      mercadotecnia: 'marketing',
    },
  },
}
