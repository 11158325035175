import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Header = styled.div`
  position: sticky;
  top: 0px;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.grey0};
  z-index: 999;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const NavBar = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    height: 72px;
  }
`

export const Back = styled(Button)`
  position: absolute;
  left: 0;
`
export const LinkEmpresasWrapper = styled.div`
  position: absolute;
  right: 0;
`

export const BrandLink = styled.a`
  display: inline-block;
  vertical-align: middle;
`
export const BackWording = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-left: 38px;
`
