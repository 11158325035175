import styled from 'styled-components'

export const SidebarComponent = styled('div')`
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  ${props => (props.closeType === 'back' ? 'padding: 56px 0 0;' : ``)}
`

export const CloseButton = styled('button')`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
`

export const BackArrow = styled('button')`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 100;
`
