import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleOneTapLogin } from '@react-oauth/google'
import { useDispatch, connect } from 'react-redux'
import services from '../../services'
import { signInGoogle, signInGoogleReset, signInLinkedinReset } from '../../redux/actions/sign-in'
import { getUrlParamByName } from '../../hooks/get-url-param'
import { SignInState } from '../../redux/types/sign-in'
import { DataLayer } from '../../constants/gtm-events-sign-in'

const excludedPaths = [
  '/login',
  '/signin',
  '/logout',
  '/cambioPassword/nuevaPassword',
  '/cambioPassword/nuevaPassword/exito',
  '/cambioPassword/nuevaPassword/enlace-vencido',
  '/recuperar-contrasena',
  '/recuperar-contrasena/email-enviado',
]

const parseToken = (token: string) => {
  const base64Payload = token.split('.')[1]
  const payload = Buffer.from(base64Payload, 'base64')
  return JSON.parse(payload.toString())
}

interface GoogleLoginProps {
  type: SignInState['type']
  token: string | null
  accountExist: boolean | null
  applicantId: string
  signInGoogleStatus: SignInState['googleStates']
  signInLinkedinStatus: SignInState['signInLinkedin']['status']
}

const OnTapHook = () => {
  const dispatch = useDispatch()

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      if (credentialResponse.credential) {
        dispatch(signInGoogle({ token: credentialResponse.credential }))
      }
    },
    onError: () => {},
  })

  return null
}

const getReturnTo = (location: Location) => {
  const returnTo = getUrlParamByName('returnTo')
  if (returnTo) {
    return returnTo
  }
  const path = location.href.replace(location.origin, '')
  if (path.startsWith('/signin') || path.startsWith('/login')) {
    return null
  }
  return path
}

const GlobalLoginHandler = ({
  type,
  token,
  accountExist,
  signInGoogleStatus,
  signInLinkedinStatus,
  applicantId,
}: GoogleLoginProps) => {
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const url = window?.location

  // Handler - Maneja de forma unificada el login con redes sociales
  const loginHandler = (loginEventHandler: () => void, resetHandler: () => void) => {
    if (accountExist === false && token) {
      const returnTo = getReturnTo(window.location)
      const href = returnTo ? `/signin?returnTo=${returnTo}` : '/signin'
      const decoded = parseToken(token)
      history.push(href, {
        nombre: decoded.given_name,
        apellido: decoded.family_name,
        email: decoded.email,
        type,
        token,
      })
      // Esto se hace en el caso que estes en la pantalla de registro tenes que recargar
      // para que se actualice los datos del token
      if (location.pathname.startsWith('/signin')) {
        history.go(0)
      }
    }
    if (accountExist) {
      loginEventHandler()
    }
    resetHandler()
  }

  // Effect - Hablita el on-tap en la pagina que corresponda
  useEffect(() => {
    const excluded = excludedPaths.includes(location.pathname)
    if (!excluded) {
      const cookie = services.auth.loginCookieExist()
      setDisabled(!!cookie)
    }
  }, [location])

  // Effect - Google
  useEffect(() => {
    if (type === 'google' && signInGoogleStatus.success) {
      loginHandler(
        () => DataLayer.userLogged({ applicantId, method: 'Ingresar con Google', url }),
        () => dispatch(signInGoogleReset())
      )
    }
  }, [type, signInGoogleStatus, applicantId])

  // Effect - Linkedin
  useEffect(() => {
    if (type === 'linkedin' && signInLinkedinStatus.success) {
      loginHandler(
        () => DataLayer.userLogged({ applicantId, method: 'Ingresar con Linkedin', url }),
        () => dispatch(signInLinkedinReset())
      )
    }
  }, [type, signInLinkedinStatus, applicantId])

  return disabled ? null : <OnTapHook />
}

const state = ({ authStore, applicantStore }) => {
  return {
    token: authStore.signIn.token,
    type: authStore.signIn.type,
    accountExist: authStore.signIn.accountExist,
    signInGoogleStatus: authStore.signIn.googleStates,
    signInLinkedinStatus: authStore.signIn.signInLinkedin.status,
    applicantId: applicantStore?.applicant?.id,
  }
}

export default connect(state)(GlobalLoginHandler)
