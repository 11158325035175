import { TagManager } from '@navent-jobs/config'

export const DataLayerAnalysisCv = {
  analyzeCv: (productName?) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: `Analisis de CV${productName ? '-' + productName : ''}`,
      eventAction: `${productName ? 'Acceder al pago' : 'Landing MiGuru'}`,
      eventLabel: `Card Analiza tu CV-Click`,
    }),
  openModalDocument: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Analisis de CV',
      eventAction: 'Landing MiGuru',
      eventLabel: 'Impresion - Modal Agregar documento al CV',
    }),
  closeModalDocument: (from, productName?) => {
    if (!productName) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: 'Analisis de CV',
        eventAction: 'Landing MiGuru',
        eventLabel: `Modal Agregar documento al CV - Click ${from}`,
      })
    } else {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV-${productName}`,
        eventAction: 'Agregar DNI al CV',
        eventLabel: `Modal Agrega tu DNI en el CV-${from}`,
      })
    }
  },
  paymentResult: (from, productName?) => {
    if (!productName) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: 'Analisis de CV',
        eventAction: 'Landing MiGuru',
        eventLabel: `Resultado pago - ${from}`,
      })
    } else {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV-${productName}`,
        eventAction: 'Resultado del pago',
        eventLabel: `${from}`,
      })
    }
  },
  goToCv: (from, productName?) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: `Analisis de CV${productName ? '-' + productName : ''}`,
      eventAction: 'Resultado del pago',
      eventLabel: `${from}`,
    }),
  downloadAnalysis: (productName?) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: `Analisis de CV${productName ? '-' + productName : ''}`,
      eventAction: 'Descargar informe del CV',
      eventLabel: `Descarga del informe-Click`,
    }),
  analyzeCvAgain: (productName?) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: `Analisis de CV${productName ? '-' + productName : ''}`,
      eventAction: 'Hacer nuevo analisis del CV',
      eventLabel: `Hacer nuevo analisis-Click`,
    }),
  loadModal: (from, productName?) => {
    if (!productName) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV`,
        eventAction: 'Landing MiGuru',
        eventLabel: 'Impresion - Modal Completa tu CV',
      })
    } else {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV-${productName}`,
        eventAction: 'Acceder al pago',
        eventLabel: `Modal Analiza tu CV con ${productName}-${from}`,
      })
    }
  },
  acceptUnableToBuy: (productName?) => {
    if (!productName) {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV`,
        eventAction: 'Landing MiGuru',
        eventLabel: `Modal Completa tu CV-Click Aceptar`,
      })
    } else {
      TagManager.Push({
        event: 'trackEvent',
        eventCategory: `Analisis de CV-${productName}`,
        eventAction: 'Acceder al pago',
        eventLabel: `Modal Completa tu CV-CTA Aceptar`,
      })
    }
  },
  landing: (from, productName?) =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: `Analisis de CV${productName ? '-' + productName : ''}`,
      eventAction: `Landing ${productName ? productName : 'MiGuru'}`,
      eventLabel: `CTA-${from}`,
    }),
  miguruPreview: from =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Analisis de CV',
      eventAction: 'Landing MiGuru',
      eventLabel: `Ver resultado preliminar-${from}`,
    }),
  miguruPreviewCompra: () =>
    TagManager.Push({
      event: 'trackEvent',
      eventCategory: 'Analisis de CV',
      eventAction: 'Landing MiGuru',
      eventLabel: 'Resultado preliminar-CTA Comprar ahora',
    }),
}
