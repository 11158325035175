export const DOWNLOAD_CURRICULUM = 'DOWNLOAD_CURRICULUM'
export const DOWNLOAD_CURRICULUM_SUCCESS = 'DOWNLOAD_CURRICULUM_SUCCESS'
export const DOWNLOAD_CURRICULUM_ERROR = 'DOWNLOAD_CURRICULUM_ERROR'

export interface TemplateCv {
  templateCv: string
}

export interface DownloadCurriculum {
  type: typeof DOWNLOAD_CURRICULUM
  payload: TemplateCv
}

export interface DownloadCurriculumSuccess {
  type: typeof DOWNLOAD_CURRICULUM_SUCCESS
  payload: string
}

export interface DownloadCurriculumError {
  type: typeof DOWNLOAD_CURRICULUM_ERROR
  payload: string | boolean
}

export interface State {
  data: string | null
  states: {
    loading: boolean
    success: boolean
    error: boolean | string
  }
}

export const initialState: State = {
  data: '',
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type Actions = DownloadCurriculum | DownloadCurriculumSuccess | DownloadCurriculumError
