import React from 'react'
import { CardWrapper, CardContent } from './mixins'

const PageSectionCard = ({ children }) => {
  return (
    <CardWrapper>
      <CardContent>{children}</CardContent>
    </CardWrapper>
  )
}

export default PageSectionCard
