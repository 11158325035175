import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import React from 'react'
import ContentLoader from 'react-content-loader'

const DesktopSkeleton = props => (
  <ContentLoader
    speed={2}
    width={684}
    height={343}
    viewBox="0 0 684 343"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="6" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="55" rx="2" ry="2" width="291" height="16" />
    <rect x="0" y="83" rx="2" ry="2" width="209" height="12" />
    <rect x="0" y="119" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="207" rx="2" ry="2" width="133" height="12" />
    <rect x="330" y="207" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="139" rx="8" ry="8" width="306" height="48" />
    <rect x="0" y="227" rx="8" ry="8" width="306" height="48" />
    <rect x="220" y="299" rx="8" ry="8" width="200" height="48" />
    <rect x="330" y="227" rx="8" ry="8" width="306" height="48" />
  </ContentLoader>
)

const MobileSkeleton = props => (
  <ContentLoader
    speed={2}
    width="100%"
    height={420}
    viewBox="0 0 100% 420"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="4" rx="4" ry="4" width="159" height="16" />
    <rect x="0" y="45" rx="2" ry="2" width="calc(100% - 62px)" height="12" />
    <rect x="0" y="73" rx="2" ry="2" width="209" height="12" />
    <rect x="0" y="101" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="189" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="277" rx="2" ry="2" width="133" height="12" />
    <rect x="0" y="121" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
    <rect x="0" y="209" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
    <rect x="0" y="297" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
    <rect x="0" y="365" rx="8" ry="8" width="calc(100% - 3px)" height="48" />
  </ContentLoader>
)

export const SkeletonChangePass = () => {
  const { isMobile } = useWindowSize()

  return isMobile ? <MobileSkeleton /> : <DesktopSkeleton />
}
