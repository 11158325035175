import styled from 'styled-components'

export const CorazonContainer = styled('div')`
  margin-left: 12px;
`

export const Container = styled('div')`
  display: flex;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  margin-left: 12px;
`
