import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from '../../../../constants'

const Meta = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('account.metaTitle')}</title>
      <link rel="canonical" href={BASE_URL} />
    </Helmet>
  )
}

export default Meta
