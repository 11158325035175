import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.grey0};
  z-index: 999;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
`

export const Container = styled.div`
  justify-content: space-between;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 70px;
`

export const CustomButton = styled(Button)`
  padding: 8px;
  height: fit-content;
  align-self: center;
`

export const CustomImage = styled.img`
  vertical-align: text-top;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
