const fichaAviso = {
  calculadoraSalario: {
    empty: 'Ingresa tu sueldo bruto pretendido y calculamos un aproximado de cuánto te queda de sueldo líquido',
    salaryDescription: 'Es un aproximado a tu sueldo líquido.',
    modal: {
      title: 'Cálculo de sueldo líquido',
      sueldoNetoLabel: 'Sueldo líquido aproximado',
      description:
        '<0>El sueldo líquido</0> en {{pais}} se calcula restando del <0>sueldo bruto</0> (el importe total sumando impuestos y deducciones),',
      impuestos: ' el fondo de pensión, la contribución de salud y el seguro cesantía.',
    },
  },
}

export default fichaAviso
