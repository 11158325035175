import React, { useState } from 'react'
import { SITE_ID } from '../../../../constants'
import { dropdownSitesItems } from '../../../../constants/header/access-bar/dropdown-sites'
import { DropdownCustom, ItemContainer, Text, IconDropdown } from './mixins'

interface Args {
  dropdownOpen: boolean
}

const DropdownContainer = ({ dropdownOpen }: Args) => {
  return (
    <ItemContainer>
      <Text>Sitios de interés</Text>
      <IconDropdown name="icon-light-caret-down" color="#3D47F5" size="20" dropdownOpen={dropdownOpen} />
    </ItemContainer>
  )
}

const DropdownSites = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <DropdownCustom
      onClick={() => setDropdownOpen(!dropdownOpen)}
      dropdownKey="dropdown-sites"
      items={dropdownSitesItems[SITE_ID || 'BMAR']}
      closeButton={false}
      position="left"
      skin="small"
    >
      <DropdownContainer dropdownOpen={dropdownOpen} />
    </DropdownCustom>
  )
}

export default DropdownSites