export const firebaseCustomConfig = {
  BMAR: { appId: '1:33132453332:web:e1329655fc55fab9aeca41', measurementId: 'G-V11EBWY4DX' },
  BMPE: { appId: '1:33132453332:web:b611a47b02308864aeca41', measurementId: 'G-TDPR2XQ7T8' },
  BMMX: { appId: '1:33132453332:web:8e36d4397c0f78a3aeca41', measurementId: 'G-HVLQ297H05' },
  BMVE: { appId: '1:33132453332:web:ac2d99863ce38606aeca41', measurementId: 'G-M6Y2P59QMT' },
  BMPA: { appId: '1:33132453332:web:b159a3d125f1f1b1aeca41', measurementId: 'G-LK3L8C5L3J' },
  BMCL: { appId: '1:33132453332:web:4478c493a0e1fd6faeca41', measurementId: 'G-7PEMM4VZWL' },
  BMEC: { appId: '1:33132453332:web:243cabf1dace3249aeca41', measurementId: 'G-S649CGS1ZL' },
  ZJAR: { appId: '1:33132453332:web:eca7c002ac541a7caeca41', measurementId: 'G-SCL1EJPW80' },
}

export const firebaseVapidKey =
  'BCaOKEnlHvfx7sdMSAkiEKLXUShygcUNmpLND0qJ6tb0svy0qQhNQ8XYonabHefab3WHbSOz3kUZlFLVUC8-Pq8'

export const firebaseConfig = {
  apiKey: 'AIzaSyB01ZAb3eFTaj0OtSWU-iFV1S7uc0exx2o',
  authDomain: 'jobs-navent-b50ab.firebaseapp.com',
  projectId: 'jobs-navent-b50ab',
  storageBucket: 'jobs-navent-b50ab.appspot.com',
  messagingSenderId: '33132453332',
}
