import {
  initialState,
  DeleteAccountActions,
  DeleteAccountState,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_RESET,
  DELETE_ACCOUNT_ERROR,
} from '../types/applicant/account/deleteAccount'
import { status } from '../types/status'

export const reducers = (state = initialState, action: DeleteAccountActions): DeleteAccountState => {
  switch (action.type) {
    case DELETE_ACCOUNT:
      return {
        ...state,
        status: status.loading,
        invalidPassword: false,
      }
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: status.success,
        invalidPassword: false,
      }
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        status: status.error,
        invalidPassword: action.payload,
      }
    case DELETE_ACCOUNT_RESET:
      return {
        ...state,
        status: status.initial,
        invalidPassword: false,
      }
    default:
      return state
  }
}

export default reducers
