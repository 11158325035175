import styled from 'styled-components'

export const MainPage = styled('div')`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin: 20px 0;
    position: relative;
    z-index: 10;
  }
`

export const PageContent = styled('div')`
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-top: 0px;
  }
`

const marginStyles = theme => `
  margin-bottom: 4px;

  @media (min-width: ${theme.screen.mdMin}) {
    margin-bottom: 12px;
  }

  @media (min-width: 1024) {
    margin-bottom: 0;
  }
`

export const CantidadEmpresasWithFilters = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ sortEmpresas, theme }) => (sortEmpresas ? marginStyles(theme) : 'margin-bottom: 16px')}
`

export const SidebarContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  padding: 16px;
  min-height: 427px;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`
