import React, { lazy, Suspense } from 'react'
import { useHistory } from 'react-router-dom'

// ui-kit & mixins
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Page, CustomButton } from './mixins'

// components
import Heading from './components/Heading'
import Image from './components/Image'
import Metas from '../../../components/metas/change-password'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../../components/PageLoader'))

const Component = ({ props }) => {
  const history = useHistory()

  const handleOnClick = () => {
    history.push('/login')
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader showBackButton={false} {...props}>
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col xl={4} offset={{ xl: 2 }} lg={{ size: 6, order: 2 }}>
                <Image />
              </Col>
              <Col lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading />

                <CustomButton
                  onClick={() => handleOnClick()}
                  buttonoptions={{
                    size: 'medium',
                    variant: 'primary',
                    native: false,
                  }}
                >
                  Volver a intentarlo
                </CustomButton>
              </Col>
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

export default Component
