import {
  GetCategories,
  GET_CATEGORIES,
  SendReview,
  SendReviewParams,
  SEND_REVIEW,
  GetApplicationReview,
  GET_APPLICANT_REVIEW,
  GetReviewsCompany,
  GET_REVIEWS_COMPANY,
  GetQuestions,
  GET_QUESTIONS,
  SEND_REVIEW_NO_EMPLOYEE,
  SendReviewNoEmployee,
  SendReviewNoEmployeeParams,
} from '../types/review'

export const getApplicantReview = (companyId: number): GetApplicationReview => {
  return {
    type: GET_APPLICANT_REVIEW,
    payload: companyId,
  }
}

export const sendReview = (params: SendReviewParams): SendReview => {
  return {
    type: SEND_REVIEW,
    payload: params,
  }
}

export const sendReviewNoEmployee = (params: SendReviewNoEmployeeParams): SendReviewNoEmployee => {
  return {
    type: SEND_REVIEW_NO_EMPLOYEE,
    payload: params,
  }
}

export const getCategories = (): GetCategories => {
  return {
    type: GET_CATEGORIES,
    payload: null,
  }
}

export const getQuestions = (isEmployee: boolean): GetQuestions => {
  return {
    type: GET_QUESTIONS,
    payload: isEmployee,
  }
}

export const getReviewsCompany = (companyId: number): GetReviewsCompany => {
  return {
    type: GET_REVIEWS_COMPANY,
    payload: companyId,
  }
}
