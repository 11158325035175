import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const Metas = () => {
  const { t } = useTranslation()
  const pageTitle = t(`seoWordings.payment_mp.title`)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default Metas