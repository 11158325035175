import { numberTropicalizer, Currencies } from '@navent-jobs/config'
import { rangeMaker } from '../../constants/postulaciones/statistics'
import { SITE_ID } from '../../constants'

export const getAverageSalaryRange = ({ salaryEnable, salarioPromedio }) => {
  const isNegative = salarioPromedio - rangeMaker[SITE_ID || 'BMAR'] < 0
  const lowSalary = isNegative ? 0 : salarioPromedio - rangeMaker[SITE_ID || 'BMAR']
  const highSalary = salarioPromedio + rangeMaker[SITE_ID || 'BMAR']
  const currencySymbol = Currencies[SITE_ID || 'BMAR'].symbol

  return salaryEnable
    ? `Entre ${currencySymbol}${numberTropicalizer(lowSalary)} y ${currencySymbol}${numberTropicalizer(highSalary)}`
    : 'Sin información suficiente'
}
