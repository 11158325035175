import { status, StatusType } from './status'

export const SEND_REVIEW = 'SEND_REVIEW'
export const SEND_REVIEW_SUCCESS = 'SEND_REVIEW_SUCCESS'
export const SEND_REVIEW_ERROR = 'SEND_REVIEW_ERROR'

export const SEND_REVIEW_NO_EMPLOYEE = 'SEND_REVIEW_NO_EMPLOYEE'

export const GET_APPLICANT_REVIEW = 'GET_APPLICANT_REVIEW'
export const GET_APPLICANT_REVIEW_SUCCESS = 'GET_APPLICANT_REVIEW_SUCCESS'
export const GET_APPLICANT_REVIEW_ERROR = 'GET_APPLICANT_REVIEW_ERROR'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR'

export const GET_REVIEWS_COMPANY = 'GET_REVIEWS_COMPANY'
export const GET_REVIEWS_COMPANY_SUCCESS = 'GET_REVIEWS_COMPANY_SUCCESS'
export const GET_REVIEWS_COMPANY_ERROR = 'GET_REVIEWS_COMPANY_ERROR'

interface CategoryScoreQuantity {
  score: number
  quantity: number
}

interface ReviewScore {
  categoryId: number
  categoryName: string
  average: number
  quantity: number
  updatedAt: Date
  detail: CategoryScoreQuantity[]
}

interface CalificacionesResCoreDto {
  categories: ReviewScore[]
  recommended: number
  wantsToWorkThere: number
  totalReviews: number
  totalNoEmployeeReviews: number
  totalEmployeeReviews: number
}

export interface ScoresByCategory {
  id: number
  score: number
}

export interface OptionByQuestion {
  id: number
  optionId: number
}

export interface Comment {
  title: string
  description: string
}

export interface ReviewsParams {
  id: number
  name: string
  score: number
}

export interface SendReviewParams {
  companyId: number
  body: {
    scoresByCategory: ScoresByCategory[]
    recommends: boolean
    comment: Comment
  }
}

export interface SendReviewNoEmployeeParams {
  companyId: number
  body: {
    scoresByCategory: ScoresByCategory[]
    optionsByQuestion?: OptionByQuestion[]
  }
}

interface ApplicantReview {
  createdAt: Date
  rating: number
  recommended: number
}

export interface SendReview {
  type: typeof SEND_REVIEW
  payload: SendReviewParams
}
export interface SendReviewSuccess {
  type: typeof SEND_REVIEW_SUCCESS
  payload: null
}
export interface SendReviewError {
  type: typeof SEND_REVIEW_ERROR
  payload: null
}

export interface SendReviewNoEmployee {
  type: typeof SEND_REVIEW_NO_EMPLOYEE
  payload: SendReviewNoEmployeeParams
}

export interface GetApplicationReview {
  type: typeof GET_APPLICANT_REVIEW
  payload: number
}
export interface GetApplicationReviewSuccess {
  type: typeof GET_APPLICANT_REVIEW_SUCCESS
  payload: ApplicantReview
}
export interface GetApplicationReviewError {
  type: typeof GET_APPLICANT_REVIEW_ERROR
  payload: null
}

export interface GetCategories {
  type: typeof GET_CATEGORIES
  payload: null
}
export interface GetCategoriesSuccess {
  type: typeof GET_CATEGORIES_SUCCESS
  payload: []
}
export interface GetCategoriesError {
  type: typeof GET_CATEGORIES_ERROR
  payload: null
}

export interface GetQuestions {
  type: typeof GET_QUESTIONS
  payload: boolean | null
}
export interface GetQuestionsSuccess {
  type: typeof GET_QUESTIONS_SUCCESS
  payload: []
}
export interface GetQuestionsError {
  type: typeof GET_QUESTIONS_ERROR
  payload: null
}

export interface GetReviewsCompany {
  type: typeof GET_REVIEWS_COMPANY
  payload: number
}
export interface GetReviewsCompanySuccess {
  type: typeof GET_REVIEWS_COMPANY_SUCCESS
  payload: CalificacionesResCoreDto
}
export interface GetReviewsCompanyError {
  type: typeof GET_REVIEWS_COMPANY_ERROR
  payload: null
}

export interface ReviewState {
  categories: []
  questions: []
  applicantReview: ApplicantReview | null
  reviews: CalificacionesResCoreDto | null
  states: StatusType
  categoriesStates: StatusType
  questionsStates: StatusType
  reviewsCompanyStates: StatusType
  sendReviewStates: StatusType
}

export const initialState: ReviewState = {
  categories: [],
  questions: [],
  applicantReview: null,
  reviews: null,
  states: status.initial,
  categoriesStates: status.initial,
  questionsStates: status.initial,
  reviewsCompanyStates: status.initial,
  sendReviewStates: status.initial,
}

export type ReviewActions =
  | SendReview
  | SendReviewSuccess
  | SendReviewError
  | SendReviewNoEmployee
  | GetApplicationReview
  | GetApplicationReviewSuccess
  | GetApplicationReviewError
  | GetCategories
  | GetCategoriesSuccess
  | GetCategoriesError
  | GetQuestions
  | GetQuestionsSuccess
  | GetQuestionsError
  | GetReviewsCompany
  | GetReviewsCompanySuccess
  | GetReviewsCompanyError
