import {
  DisponibleInmediatoActions,
  DisponibleInmediatoState,
  SWITCH_DISPONIBLE_INMEDIATO,
  SWITCH_DISPONIBLE_INMEDIATO_ERROR,
  SWITCH_DISPONIBLE_INMEDIATO_SUCCESS,
  initialState,
} from '../types/disponible-inmediato'
import { status } from '../types/status'

const reducers = (
  state: DisponibleInmediatoState = initialState,
  action: DisponibleInmediatoActions
): DisponibleInmediatoState => {
  switch (action.type) {
    case SWITCH_DISPONIBLE_INMEDIATO:
      return {
        ...state,
        switchDisponibleInmediato: {
          ...state.switchDisponibleInmediato,
          status: status.loading,
        },
      }
    case SWITCH_DISPONIBLE_INMEDIATO_SUCCESS:
      return {
        ...state,
        switchDisponibleInmediato: {
          ...state.switchDisponibleInmediato,
          value: action.payload,
          status: status.success,
        },
      }
    case SWITCH_DISPONIBLE_INMEDIATO_ERROR:
      return {
        ...state,
        switchDisponibleInmediato: {
          ...state.switchDisponibleInmediato,
          status: status.error,
        },
      }

    default:
      return state
  }
}

export default reducers
