import styled from 'styled-components'

export const LegendWrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const LegendText = styled('p')`
  margin: 0 0 0 4px;
`
