import {
  CHANGE_PASSWORD_SEND_EMAIL,
  CHANGE_PASSWORD_SEND_EMAIL_SUCCESS,
  CHANGE_PASSWORD_SEND_EMAIL_ERROR,
  CHANGE_PASSWORD_SEND_EMAIL_RESET,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  initialState,
  ChangePasswordActions,
  ChangePasswordState,
} from '../types/change-password'
import { status } from '../types/status'

const reducers = (state: ChangePasswordState = initialState, action: ChangePasswordActions): ChangePasswordState => {
  switch (action.type) {
    case CHANGE_PASSWORD_SEND_EMAIL:
      return {
        ...state,
        sendMail: { ...state.sendMail, status: status.loading },
      }

    case CHANGE_PASSWORD_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendMail: { ...state.sendMail, value: action.payload, status: status.success },
      }

    case CHANGE_PASSWORD_SEND_EMAIL_ERROR:
      return {
        ...state,
        sendMail: { ...state.sendMail, status: status.error },
      }

    case CHANGE_PASSWORD_SEND_EMAIL_RESET:
      return {
        ...state,
        sendMail: { ...state.sendMail, value: null, status: status.initial },
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: { ...state.changePassword, status: status.loading },
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: { ...state.changePassword, value: action.payload, status: status.success },
      }

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: { ...state.changePassword, status: status.error },
      }

    default:
      return state
  }
}

export default reducers
