import styled from 'styled-components'

export const CustomForm = styled.div`
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  max-width: 684px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-color: white;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px;
  }
`

export const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
