import React from 'react'
import { isEmpty } from 'lodash'

// ui-kit
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Col, MainContainer } from '@navent-jobs/ui-kit/components/grid'

// mixins
import {
  ComponentContainer,
  LogoWrapper,
  Logo,
  Title,
  TitleContainer,
  LogoAndTitle,
  Quantity,
  Label,
  FlexContainer,
  Rating,
  CaptionContainer,
  Separator,
  CustomRow,
  ImgGptw,
} from './mixins'

// components
import CompanyRating from '../../../rating'
import logoGPTW from '../../../../../assets/images/calificar-empresas/GPTW_Logo.svg'

// constants
import features from '../../../../../constants/features-per-country'
import { SITE_ID } from '../../../../../constants'

const MAX_CHARS_TITLE = 70
const reviewEnabled = features[SITE_ID || 'BMAR'].reviews.enabled
const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled

const Component = ({ logo, name, jobPostingsQuantity, industry, location, reviewAverage, employeesRange, gptw }) => {
  const { isDesktop } = useWindowSize()
  const hasQuantity = jobPostingsQuantity >= 1
  const showInFirstCol = !isDesktop || !employeesRange

  const GptwOrAverage = () => {
    const showGptw = gptwEnabled && gptw
    if (showGptw || (reviewEnabled && reviewAverage)) {
      return (
        <>
          {showGptw ? (
            <ImgGptw src={logoGPTW} alt="logo GPTW" />
          ) : (
            <Rating>
              <CompanyRating reviewAverage={reviewAverage} singleStar={true} />
            </Rating>
          )}
          <Separator>-</Separator>
        </>
      )
    }
    return null
  }

  const Caption = () => (
    <CaptionContainer>
      <GptwOrAverage />
      <FlexContainer>
        <Icon name="icon-light-list" size="20" color={!hasQuantity ? '#C3C2C8' : '#29293D'} />
        <Quantity hasQuantity={hasQuantity}>{hasQuantity ? jobPostingsQuantity : 'Sin'} avisos activos</Quantity>
      </FlexContainer>
    </CaptionContainer>
  )

  return (
    <ComponentContainer>
      <LogoAndTitle>
        {!isEmpty(logo) && (
          <LogoWrapper isDesktop={isDesktop}>
            <Logo src={logo} isDesktop={isDesktop} />
          </LogoWrapper>
        )}

        <TitleContainer>
          <Title>{name.length > MAX_CHARS_TITLE ? `${name.substring(0, MAX_CHARS_TITLE)}...` : name}</Title>

          {isDesktop && <Caption />}
        </TitleContainer>
      </LogoAndTitle>

      {!isDesktop && <Caption />}

      <MainContainer noGutter>
        <CustomRow noGutter>
          <Col noGutter>
            {industry && (
              <FlexContainer>
                <Icon name="icon-light-layers" size="20" color="#29293D" />
                <Label>{industry}</Label>
              </FlexContainer>
            )}

            {employeesRange && (
              <FlexContainer>
                <Icon name="icon-light-people" size="20" color="#29293D" />
                <Label>{`${employeesRange} empleados`}</Label>
              </FlexContainer>
            )}

            {showInFirstCol && location && (
              <FlexContainer>
                <Icon name="icon-light-location-pin" size="20" color="#29293D" />
                <Label>{location}</Label>
              </FlexContainer>
            )}
          </Col>
          {!showInFirstCol && location && (
            <Col>
              <FlexContainer>
                <Icon name="icon-light-location-pin" size="20" color="#29293D" />
                <Label>{location}</Label>
              </FlexContainer>
            </Col>
          )}
        </CustomRow>
      </MainContainer>
    </ComponentContainer>
  )
}

export default Component
