import styled from 'styled-components'

export const Anchors = styled('div')`
  background-color: #ebebed;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease-in-out;
  overflow: hidden;

  @media (min-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey0};
    margin-bottom: 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }
`

const withIconAnchor = `
  width: auto;
  padding: 8px 15px;
`

const activeAnchor = colors => {
  return `  
  width: calc(100% - 1px); /* El -1 arregla el estilo mobile para que funcione correctamente en iOS 16.2 Safari */
  color: ${colors.primary.normal} !important;

  &::after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: ${colors.primary.normal} !important;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
  }

  & > i {
    &::before {
      background-color: ${colors.primary.normal} !important;
    }
  }
`
}

const disabledAnchor = ` 
  color: #c3c2c8;
  
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #c3c2c8;
    cursor: not-allowed;
  }
  `

export const LinkCustom = styled('a')`
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey48};
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  padding: 0px;
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.grey48};
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: ${({ theme }) => theme.colors.grey16};
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 10px;
    }
  }

  ${({ isActive, theme }) => isActive && activeAnchor(theme.colors)};
  ${({ withIcon }) => withIcon && withIconAnchor};
  ${({ disabled }) => disabled && disabledAnchor};

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    font-size: 16px;
    line-height: 48px;
    padding: 0px 12px;
  }
`
