import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const CustomButton = styled(Button)`
  width: 100%;
  padding: 0 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: 200px;
  }
`

export const Label = styled('div')`
  &:after {
    content: '*';
    font-size: 12px;
    color: red;
    margin-left: 3px;
    vertical-align: 3px;
  }
`

export const WrapperTextarea = styled.div`
  margin-bottom: 12px;
`
