import React from 'react'
import { Trans } from 'react-i18next'
import { SubtitleText, CustomLink, CardNotEnded } from './mixins'

const NotEndedCard = () => {
  return (
    <CardNotEnded>
      <SubtitleText>
        <Trans
          i18nKey="saveCompany.cards.alerts.text"
          components={[
            <CustomLink
              key="alert-link"
              href="/postulantes/notificaciones-y-alertas"
              linkoptions={{ variant: 'primary', type: 'link', size: 'medium' }}
            />,
          ]}
        />
      </SubtitleText>
    </CardNotEnded>
  )
}

export default NotEndedCard
