import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// ui-kit & mixins
import { MainContainer, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'
import services from '../../../services'
import { Page, FormGroup, MessageError } from './mixins'

// components
import Heading from './components/Heading'
import SubmitButton from '../../../components/SubmitButton'
import Image from './components/Image'
import Metas from '../../../components/metas/change-password'

// store
import { changePassword } from '../../../redux/actions/change-password'
import { logoutAction } from '../../../redux/actions/logout'
import { LogoutState } from '../../../redux/types/logout'
import { ChangePasswordState } from '../../../redux/types/change-password'
import { showSnackbarError } from '../../../redux/actions/applicant/curriculum/snackbar'

// lazy components
const LayoutSingleHeader = lazy(
  () => import(/* webpackChunkName: "layout" */ '../../../components/layouts/layout-single-header')
)
const PageLoader = lazy(() => import(/* webpackChunkName: "splash-loader" */ '../../../components/PageLoader'))

interface ChangePasswordRequestProps {
  changePasswordStatus: ChangePasswordState['changePassword']['status']
  logoutStatus: LogoutState['status']
  tokenVencido: boolean
}

const Component = ({ changePasswordStatus, logoutStatus, tokenVencido }: ChangePasswordRequestProps) => {
  const parsedUrl = new URL(window.location.href)

  // El token auto es para autologin y no se tendria que usar aca, es solo para compatibilidad con el CRM.
  // El autologin en esta pantalla esta deshabilitado de todas formas.
  const token = parsedUrl.searchParams.get('token') ?? parsedUrl.searchParams.get('token_auto')

  const history = useHistory()

  const [showColImage, setShowColImage] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)

  const [inputPassword, setInputPassword] = useState('')
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState('')
  const [samePassword, setSamePassword] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isTabletLandscape, isDesktop } = useWindowSize()
  const enabledViewport = isTabletLandscape || isDesktop

  // Init React Hook Form
  const {
    handleSubmit,
    formState,
    formState: { errors },
    register,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = data => {
    if (token) {
      const newData = {
        passwordNueva: data.passwordNueva,
        token,
      }
      dispatch(changePassword(newData))
    }
  }

  const notEmpty = value => {
    return value.replace(/\s/g, '') !== ''
  }

  const checkIsFilled = value => {
    const isFilled = notEmpty(value)
    if (!isFilled) {
      return t('Ingresá una contraseña válida.')
    }
    return isFilled
  }

  const passwordRules = {
    validate: {
      checkIsFilled,
    },
    minLength: {
      value: 6,
      message: t('Ingresá al menos 6 caracteres'),
    },
  }

  const passwordRulesRepeat = {
    validate: {
      checkIsFilled,
    },
  }

  const comparePassword = (password, compareToPassword) => {
    if (password && compareToPassword) {
      const comparePasswordValidate = password === compareToPassword
      setShowMessageError(!comparePasswordValidate)
      setSamePassword(comparePasswordValidate)
      if (!comparePasswordValidate) {
        setError('passwordRepeat', {})
      } else {
        clearErrors('passwordRepeat')
      }
    }
  }

  useEffect(() => {
    setShowColImage(enabledViewport)
  }, [enabledViewport])

  // Effect - Success / Error
  useEffect(() => {
    if (changePasswordStatus.success) {
      if (tokenVencido) {
        history.replace('/cambioPassword/nuevaPassword/enlace-vencido')
      } else {
        history.replace('/cambioPassword/nuevaPassword/exito')
      }
    } else if (changePasswordStatus.error) {
      dispatch(showSnackbarError(t('Falló el cambio de contraseña')))
    }
  }, [changePasswordStatus])

  // Effect - Solo se puede estar deslogueado en esta pantalla
  useEffect(() => {
    if (!token) {
      history.push('/')
    } else {
      const isLogged = services.auth.loginCookieExist()
      if (isLogged) {
        dispatch(logoutAction())
      }
    }
  }, [])

  // Effect - se recarga la pagina luego del logout
  useEffect(() => {
    if (logoutStatus.success) {
      window.location.reload()
    }
  }, [logoutStatus])

  return (
    <Suspense fallback={<PageLoader />}>
      <LayoutSingleHeader showBackButton={false}>
        <Page>
          <Metas />
          <MainContainer>
            <Row>
              <Col lg={6} xl={4} offset={{ xl: 1 }}>
                <Heading />

                <form onSubmit={handleSubmit(onSubmit)} id="form-change-password">
                  <FormGroup>
                    <Input
                      id="passwordNueva"
                      name="passwordNueva"
                      type="password"
                      rules={register(passwordRules) as any}
                      placeholder={t('Ingresá la contraseña')}
                      onChange={({ target: { value } }) => {
                        setInputPassword(value)
                        if (!notEmpty(value)) {
                          setShowMessageError(true)
                          setSamePassword(false)
                        } else {
                          comparePassword(value, inputPasswordRepeat)
                        }
                        if (!notEmpty(value) && !notEmpty(inputPasswordRepeat)) {
                          setShowMessageError(false)
                        }
                      }}
                      fieldOptions={{
                        label: t('Nueva contraseña'),
                        variant: 'darken',
                        maxLength: 50,
                        before: <Icon name="icon-light-lock" color="#3D47F5" size="22" />,
                      }}
                      errors={{ ...errors }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="passwordRepeat"
                      name="passwordRepeat"
                      type="password"
                      rules={register(passwordRulesRepeat) as any}
                      placeholder={t('Repetí la contraseña')}
                      onChange={({ target: { value } }) => {
                        setInputPasswordRepeat(value)
                        if (!notEmpty(value)) {
                          setShowMessageError(false)
                          setSamePassword(false)
                        } else {
                          comparePassword(value, inputPassword)
                        }
                      }}
                      fieldOptions={{
                        label: t('Repetir contraseña'),
                        variant: 'darken',
                        maxLength: 50,
                        before: <Icon name="icon-light-lock" color="#3D47F5" size="22" />,
                      }}
                      errors={{ ...errors }}
                    />

                    {showMessageError && <MessageError>{t('Las contraseñas no coinciden')}</MessageError>}
                  </FormGroup>

                  <SubmitButton
                    id="ctaRecuperarContrasenia"
                    idForm="form-change-password"
                    isLoading={changePasswordStatus.loading}
                    disabled={!formState.isValid || changePasswordStatus.loading || !samePassword}
                  >
                    {t('Aceptar')}
                  </SubmitButton>
                </form>
              </Col>

              {showColImage && (
                <Col lg={6} xl={4} offset={{ xl: 2 }}>
                  <Image />
                </Col>
              )}
            </Row>
          </MainContainer>
        </Page>
      </LayoutSingleHeader>
    </Suspense>
  )
}

const states = ({
  changePasswordStore,
  logoutStore,
}: {
  changePasswordStore: ChangePasswordState
  logoutStore: LogoutState
}) => {
  return {
    changePasswordStatus: changePasswordStore.changePassword.status,
    tokenVencido: changePasswordStore.changePassword.value?.tokenVencido ?? false,
    logoutStatus: logoutStore.status,
  }
}

export default connect(states)(Component)
