import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
`
export const DescriptionWrapper = styled.div`
  margin: 12px 0px 24px;
`
export const DescriptionEmail = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey60};
`
export const DescriptionPush = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey60};
`

export const SuscriptionListTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const SuscriptionDetail = styled.p`
  color: ${({ theme }) => theme.colors.grey84};
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`

export const SuscriptionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    align-items: center;
  }
`
export const LastSuscriptionItem = styled(SuscriptionItem)`
  margin-bottom: 0px;
`
export const ToogleMargin = styled.div`
  margin: 0px 0px 0px 28px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin: 0px 48px 0px 0px;
  }
`
export const ToggleWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  max-width: 298px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    margin-top: 0px;
  }
`
