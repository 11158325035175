import styled from 'styled-components'

export const TitleContainer = styled.div`
  margin-bottom: 32px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 64px;
  }
`

export const MapContainer = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 40px;
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

export const Spacer = styled.div`
  padding-top: 80px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding-top: 120px;
  }
`

export const CustomIframe = styled.iframe`
  width: 100%;
  height: 388px;
`
