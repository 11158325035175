import { merge } from 'lodash'
import es from '../es'
import metatagsListado from './metatags-listado'
import { currencyMask } from './currency-mask'
import filtersSettings from './filters-settings'
import { seoWordings as seoWordingsVe } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import modalCrearAlerta from './modal-crear-alerta'
import curriculumEs from '../es/curriculum'
import curriculumVe from './curriculum'
import newFeatureEs from '../es/new-feature'
import newFeatureVe from './new-feature'
import companySummary from '../es/company-summary'
import postulacionesEs from '../es/postulaciones'
import postulacionesVe from './postulaciones'
import listadoEmpresasEs from '../es/listado-empresas'
import listadoEmpresasVe from './listado-empresas'
import { products as productsEs } from '../es/products'
import { products as productsVe } from './products'
import filters from './filters'
import filtersEs from '../es/filters'
import { perfil as perfilEs } from '../es/perfil'
import { perfil as perfilVe } from './perfil'

const ve = {
  ...es,
  ...metatagsListado,
  newFeature: merge({}, newFeatureEs, newFeatureVe),
  ...filtersSettings,
  currencyMask,
  ...modalCrearAlerta,
  'Postulantes Title': 'Empleos en Venezuela: trabajo y ofertas de empleo - Bumeran 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Venezuela en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Bumeran.',
  Buscar: 'Buscar VE',
  'Ir al inicio': 'Ir al inicio VE',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: 'Bs.',
    'currency-format': 'XXX.XXX.XXX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - bumeran.com.ve',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en bumeran Empleos.',
  seoWordings: merge({}, seoWordingsEs, seoWordingsVe),
  curriculum: merge({}, curriculumEs, curriculumVe),
  companySummary,
  postulaciones: merge({}, postulacionesEs, postulacionesVe),
  listadoEmpresas: merge({}, listadoEmpresasEs, listadoEmpresasVe),
  products: merge({}, productsEs, productsVe),
  ...merge({}, { ...filtersEs }, { ...filters }),
  perfil: merge({}, perfilEs, perfilVe),
}

export default ve
