export const perfil = {
  favoritos: {
    empty: 'Guardá empleos para postularte más tarde',
  },
  empresasFavoritas: {
    empty: 'Guardá empleos para postularte más tarde',
  },
  actualizarCv: {
    cvDesactualizado: 'Revisá tu {{cv}}',
    modal: {
      title: '¿Confirmás que tu {{cv}} está al día?',
      snackError: 'Error, volvé a intentarlo.',
    },
  },
  salarioPretendido: {
    empty: 'Para conocer tu posible salario, postulate a un aviso',
  },
  recomendados: {
    modal: {
      noInteresado: {
        subtitulo: 'Seleccioná una o más respuestas:',
      },
    },
  },
  disponibilidad: {
    title: 'Informá a las empresas tu disponibilidad',
  },
}
