import styled from 'styled-components'

const ellipsis = `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`

export const ComponentContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LogoAndTitle = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const LogoWrapper = styled('div')`
  width: 100%;
  max-width: 68px;
  height: 44px;
  background: white;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey24};
  overflow: hidden;
`

export const Logo = styled('img')`
  border-radius: 4px;
`

export const Title = styled('h2')`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  line-height: 20px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
`

export const TitleContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    align-items: flex-start;
    flex-direction: column;
  }
`

export const ItemGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '0' : '8px')};
`

const stylesLabels = `
  font-size: 14px;
  line-height: 20px;
`

export const Quantity = styled('div')`
  ${stylesLabels};
  color: ${({ theme, hasQuantity }) => (hasQuantity ? theme.colors.grey84 : theme.colors.grey24)};
  margin-left: 6px;
  ${ellipsis}
`

export const DataContainer = styled('div')``

export const Label = styled('span')`
  width: 100%;
  ${stylesLabels};
  color: ${({ theme }) => theme.colors.grey84};
  margin-left: 6px;
  ${ellipsis}
`

export const LocationContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const ImgGptw = styled.img`
  width: 33px;
  height: 33px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  justify-content: space-between;
`

export const SeguirContainer = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: end;
`
