import {
  initialChangePasswordState,
  ChangePasswordState,
  ChangePasswordActions,
  CHANGE_PASSWORD_ACCOUNT_ERROR,
  CHANGE_PASSWORD_ACCOUNT,
  CHANGE_PASSWORD_ACCOUNT_RESET,
  CHANGE_PASSWORD_ACCOUNT_SUCCESS,
} from '../../types/applicant/account/changePassword'

const reducers = (
  state: ChangePasswordState = initialChangePasswordState,
  action: ChangePasswordActions
): ChangePasswordState => {
  switch (action.type) {
    case CHANGE_PASSWORD_ACCOUNT:
      return {
        ...state,
        data: null,
        state: { loading: true, error: false, success: false },
      }
    case CHANGE_PASSWORD_ACCOUNT_ERROR:
      return {
        ...state,
        statusCode: action.payload,
        state: { loading: false, error: true, success: false },
      }
    case CHANGE_PASSWORD_ACCOUNT_SUCCESS:
      return {
        ...state,

        statusCode: '200',
        data: action.payload,
        state: { loading: false, error: false, success: true },
      }
    case CHANGE_PASSWORD_ACCOUNT_RESET:
      return {
        ...state,

        statusCode: null,
        data: null,
        state: { loading: false, error: true, success: false },
      }
    default:
      return state
  }
}

export default reducers
