import { URLData } from '@navent-jobs/interlinking'
import _ from 'lodash'
import { makeURL } from '../utils/search-avisos-v2'

export interface KeyWord extends URLData {
  keyword?: string
}

interface ValueLabel {
  value: string
  label: string
}

interface History {
  url?: string
  busqueda?: ValueLabel
  localidad?: ValueLabel
  provincia?: ValueLabel
  area?: ValueLabel
  modalidad_trabajo?: ValueLabel
  tipo_trabajo?: ValueLabel
}

export class SearchStack {
  private static instance: SearchStack

  private storeKey = 'searchHistory'

  private recentSearchsKey = 'recentSearchs'

  private historyLength

  private queue: string[] = []

  private constructor({ length = 5 }: { length?: number } = {}) {
    const storedString = localStorage.getItem(this.recentSearchsKey)

    if (storedString) {
      this.queue = JSON.parse(storedString as any)
    }

    this.historyLength = length
  }

  public static getInstance(): SearchStack {
    if (!SearchStack.instance) {
      SearchStack.instance = new SearchStack()
    }
    return SearchStack.instance
  }

  public push(search: KeyWord): string {
    const url = makeURL(search)
    const lastSearch = {
      keyword: search.keyword,
      busqueda: search.busqueda,
      url: makeURL({ busqueda: search.busqueda }),
    }

    if (search && search.busqueda) {
      this.queue = Array.from(new Set([JSON.stringify({ ...lastSearch }), ...this.queue])).splice(0, this.historyLength)
    }

    localStorage.setItem(this.storeKey, JSON.stringify(this.queue))

    return url
  }

  // palabra clave - Localidad - Provincia - Área - Modalidad de trabajo - Tipo de puesto.

  // public makeKeyword(filtros) {
  //   return `${filtros.busqueda !== undefined ? filtros.busqueda?.toString() : ''} ${filtros.localidad !== undefined ? '+ ' + filtros.localidad?.toString() : ''} ${filtros.provincia !== undefined ? '+ ' + filtros.provincia?.toString() : ''} ${filtros.area !== undefined ? '+ ' + filtros.area?.toString() : ''} ${filtros.modalidad_trabajo !== undefined ? '+ ' + filtros.modalidad_trabajo?.toString() : ''} ${filtros.tipo_trabajo !== undefined ? '+ ' + filtros.tipo_trabajo?.toString() : ''}`
  // }

  public generateUrl(filter) {
    const filters: any = {}

    Object.keys(filter).forEach(function (key) {
      if (filter[key]) {
        filters[key] = filter[key]
      }
    })

    const filtersToUrl: any = {}

    Object.keys(filters).forEach(function (key) {
      if (filters[key]) {
        filtersToUrl[key] = filters[key].value
      }
    })

    return makeURL(filtersToUrl)
  }

  public pushFilters(filter): any {
    const filtersForRecentSearch: any = {}

    const filtersIncludes = item =>
      item === 'busqueda' ||
      item === 'localidad' ||
      item === 'provincia' ||
      item === 'area' ||
      item === 'modalidad_trabajo' ||
      item === 'tipo_trabajo'

    Object.keys(filter)
      .filter(item => filtersIncludes(item))
      .forEach(function (key, index) {
        if (filter[key]) {
          filtersForRecentSearch[key] = filter[key]
        }
      })

    const filtersToUrl: any = {}

    Object.keys(filtersForRecentSearch).forEach(function (key, index) {
      if (filtersForRecentSearch[key]) {
        filtersToUrl[key] = filtersForRecentSearch[key].value
      }
    })

    const url = makeURL(filtersToUrl)

    const lastSearchFilters: { url: string; busqueda?: { value: string; label: string } } = {
      url,
    }

    if (filter.busqueda) {
      lastSearchFilters.busqueda = { value: filter.busqueda.value, label: filter.busqueda.label }
    }

    Object.keys(filtersForRecentSearch).forEach(function (key, index) {
      if (filtersForRecentSearch[key] && key !== 'busqueda') {
        lastSearchFilters[key] = filtersForRecentSearch[key]
      }
    })

    if (filter && !this.queue.some(q => _.isEqual(lastSearchFilters, JSON.parse(q)))) {
      this.queue = Array.from(new Set([JSON.stringify({ ...lastSearchFilters }), ...this.queue])).splice(
        0,
        this.historyLength
      )
    }

    localStorage.setItem(this.recentSearchsKey, JSON.stringify(this.queue))

    return this.generateUrl(filter)
  }

  // TODO: eliminar a futuro, este método se utiliza para evitar problemas de compatibilidad con la información almacenada en el localStorage
  private isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  public getHistory(): History[] {
    return this.queue.filter(e => this.isJson(e)).map(e => JSON.parse(e))
  }
}
