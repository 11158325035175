import styled from 'styled-components'

export const ImgLogo = styled.img`
  height: ${({ logoStyles }) => (logoStyles?.heightMobile ? `${logoStyles.heightMobile}px` : '24px')};
  margin-bottom: ${({ logoStyles }) => (logoStyles?.marginBottomMobile ? `${logoStyles.marginBottomMobile}px` : '0px')};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    height: ${({ logoStyles }) => (logoStyles?.heightDesktop ? `${logoStyles.heightDesktop}px` : '52px')};
    margin-bottom: ${({ logoStyles }) =>
      logoStyles?.marginBottomDesktop ? `${logoStyles.marginBottomDesktop}px` : '0px'};
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 32px;
  margin-top: 16px;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 52px;
    line-height: 64px;
    margin-bottom: 64px;
    text-align: center;
  }
`

export const Img = styled.img`
  height: 150px;
`

export const Subtitle = styled.p`
  margin: 16px auto;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 24px;
`

export const Page = styled.div`
  padding: 0 16px;
  background: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.grey0};

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding: 105px 63px;
  }
`

export const ColContainer = styled.div`
  text-align: center;
`
