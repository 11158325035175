import React from 'react'
import { DataLayerListadoEmpresas } from '../../gtm-events-listado-empresas'
import { CrossSiteDataLayers } from '../../gtm-events-cross-site'
import SiteInterest from '../../../components/AccessBar/components/dropdown-sites'

export const accessLandingItems = {
  BMAR: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
  ZJAR: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landingEjecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos sin Fronteras'),
    },
  ],
  BMCL: [
    {
      content: <SiteInterest />,
      key: 'sitio-de-interes',
      visibleIn: ['mobile', 'desktop'],
    },
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
  ],
  BMEC: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
  BMMX: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
  BMPA: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
  BMPE: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
  BMVE: [
    {
      href: '/listado-empresas',
      content: 'Buscar empresas',
      key: 'listado-de-empresas',
      icon: 'icon-light-office',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => {
        DataLayerListadoEmpresas.goToCompanyListHeader()
        CrossSiteDataLayers.header('Listado de Empresas')
      },
    },
    {
      href: '/empleos-seniority-junior.html?landing-jovenes-profesionales=true',
      content: 'Jóvenes profesionales',
      key: 'jovenes-profesionales',
      icon: 'icon-light-graduation-cap',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Jovenes Profesionales'),
    },
    {
      href: '/empleos-seniority-gerencia-alta-gerencia-direccion.html?landing-puestos-ejecutivos=true',
      content: 'Puestos ejecutivos',
      key: 'puestos-ejecutivos',
      icon: 'icon-light-briefcase',
      visibleIn: ['mobile', 'desktop'],
      metrics: () => CrossSiteDataLayers.header('Puestos Ejecutivos'),
    },
  ],
}
