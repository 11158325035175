import styled from 'styled-components'

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ iconBtn }) => (iconBtn ? '45%' : '60%')};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey84};
`

export const InfoText = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const InfoValue = styled(InfoText)`
  font-size: ${({ bigger }) => (bigger ? '32px' : '24px')};
  line-height: ${({ bigger }) => (bigger ? '40px' : '32px')};
`
