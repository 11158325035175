import { TagManager } from '@navent-jobs/config'
import { actionOrigin } from './commons/actionurl'

const methods = {
  google: 'Ingresar con Google',
  linkedin: 'Ingresar con Linkedin',
}

export const DataLayer = {
  pageView: () => {
    // page view
    return TagManager.Push({
      event: 'track_content_group',
      content_group: 'Ingreso',
    })
  },
  linkIngresar: () => {
    // link a page login
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Modal registro',
      item_id: 'Ingresa',
    })
  },
  linkARegisto: () => {
    // link a page registro
    return TagManager.Push({
      event: 'select_content',
      content_type: 'Modal login',
      item_id: 'Registrate',
    })
  },

  registrationSuccess: ({ url, method, applicantId }) => {
    // link a page registro
    return TagManager.Push({
      event: 'sign_up',
      user_id_custom: applicantId,
      method: methods[method] ?? 'sesion_Manual',
      origin_type: actionOrigin({ url }),
      user_type: 'Postulante',
    })
  },
}
