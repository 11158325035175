import { Request, Post, Get } from '@navent-jobs/config'
import features from '../constants/features-per-country'
import { SITE_ID } from '../constants'

export class ErrorCompaniesList extends Error {}

const DEFAULT_PAGE_SIZE = 20

class CompaniesList {
  public getCompanies = async ({ page = 0, filtros }) => {
    const request = new Request()
    request.path = `/api/companies/search?pageSize=${DEFAULT_PAGE_SIZE}&page=${page}`
    const filterQuery = filtros.query ? { query: filtros.query } : {}
    request.body = {
      ...filterQuery,
      industries: filtros.industries,
      provinces: filtros.provinces,
      sortByRating: filtros.sortByRating,
      sortByGptw: features[SITE_ID || 'BMAR'].gptw.enabled,
    }
    request.method = Post
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorCompaniesList(error)
      })
  }

  public getCompaniesMostWanted = async () => {
    const request = new Request()
    request.path = '/api/companies/most-searched'
    request.method = Get
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorCompaniesList(error)
      })
  }

  public getCompaniesRecommended = async (pageSize?: number) => {
    const request = new Request()
    request.path = `/api/companies/recommended`
    request.method = Get
    request.params = {
      pageSize,
      sortByGptw: features[SITE_ID || 'BMAR'].gptw.enabled,
    }
    return request
      .call()
      .then(response => {
        return response
      })
      .catch(error => {
        throw new ErrorCompaniesList(error)
      })
  }

  public getCompaniesSuggestion = async (keyword: string, ignoreGptw = false) => {
    const normQuery = keyword
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/´/g, '')
    if (!normQuery) {
      return []
    }

    const request = new Request()
    request.path = `/api/companies/suggestion?keyword=${normQuery}&ignoreGptw=${ignoreGptw}`
    request.method = Get
    return request.call()
  }
}

const companiesList = new CompaniesList()
export default companiesList
