import React from 'react'
import styled from 'styled-components'
import { Loader } from '@navent-jobs/ui-kit/components/loader'
import { makeLoaderWrapper } from './mixins'

const LoaderWrapper = styled.div`
  ${() => makeLoaderWrapper()}
`

const Component = () => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  )
}

export default Component
