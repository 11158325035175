import styled from 'styled-components'

export const MainPage = styled('div')`
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    position: relative;
    z-index: 10;
  }
`

export const PageContent = styled('div')``
