import { Button } from '@navent-jobs/ui-kit/components/button'
import { Col, MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import React from 'react'
import { connect } from 'react-redux'
import { Card, Spacer, CenterContainer } from './mixins'

const Vacancies = ({ careerSite }) => {
  if (!careerSite?.showVacanciesList) {
    return null
  }

  return (
    <Spacer>
      <MainContainer>
        <Row>
          <Col md={12}>
            <CenterContainer>
              <Text size="sm" type="title" fontWeight="semibold">
                Oportunidades de empleo
              </Text>
            </CenterContainer>
          </Col>
          <Col offset={{ md: 0.5 }} md={11}>
            <Card>
              <Text size="md">En este momento no poseemos búsquedas activas.</Text>
            </Card>
          </Col>
          <Col md={12}>
            <CenterContainer>
              <Button
                onClick={() => {
                  window.open(`https://${careerSite?.account}.hiringroom.com/jobs`, '_blank')
                }}
                buttonoptions={{ size: 'small', type: 'fill', variant: 'black' }}
              >
                Ingresar al portal de empleos
              </Button>
            </CenterContainer>
          </Col>
        </Row>
      </MainContainer>
    </Spacer>
  )
}

const states = ({ careerSiteStore }) => ({
  careerSite: careerSiteStore?.careerSite,
})

export default connect(states)(Vacancies)
