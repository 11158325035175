import { status, StatusType } from '../../status'

export const REMOVE_ALERT = 'REMOVE_ALERT'
export const SHOW_MODAL_ALERT_TO_REMOVE = 'SHOW_MODAL_ALERT_TO_REMOVE'
export const HIDE_MODAL_ALERT_TO_REMOVE = 'HIDE_ALERT_TO_REMOVE'
export const REMOVE_ALERT_SUCCESS = 'REMOVE_ALERT_SUCCESS'
export const REMOVE_ALERT_ERROR = 'REMOVE_ALERT_ERROR'
export const REMOVE_ALERT_RESET = 'REMOVE_ALERT_RESET'

export interface RemoveAlertType {
  open: boolean
  id: number | null
}

const initialStatusData = {
  open: false,
  id: null,
}

export interface RemoveAlertState {
  data: RemoveAlertType
  status: StatusType
}

export const initialStateRemoveAlert = {
  data: initialStatusData,
  status: status.initial,
}

export interface RemoveAlert {
  type: typeof REMOVE_ALERT
  payload: number
}

export interface ShowModalAlert {
  type: typeof SHOW_MODAL_ALERT_TO_REMOVE
  payload: number | null
}

export interface HideModalAlert {
  type: typeof HIDE_MODAL_ALERT_TO_REMOVE
  payload: null
}

export interface RemoveAlertSuccess {
  type: typeof REMOVE_ALERT_SUCCESS
  payload: null
}

export interface RemoveAlertError {
  type: typeof REMOVE_ALERT_ERROR
  payload: null
}
export interface RemoveAlertReset {
  type: typeof REMOVE_ALERT_RESET
  payload: null
}

export type RemoveAlertActions =
  | ShowModalAlert
  | HideModalAlert
  | RemoveAlertSuccess
  | RemoveAlertError
  | RemoveAlert
  | RemoveAlertReset
