export const CONFIRM_CANDIDATE_ACCOUNT = 'CONFIRM_CANDIDATE_ACCOUNT'
export const CONFIRM_CANDIDATE_ACCOUNT_SUCCESS = 'CONFIRM_CANDIDATE_ACCOUNT_SUCCESS'
export const CONFIRM_CANDIDATE_ACCOUNT_ERROR = 'CONFIRM_CANDIDATE_ACCOUNT_ERROR'

export interface CandidateAccountConfirmation {
  email: string
}

export interface ConfirmCandidateAccount {
  type: typeof CONFIRM_CANDIDATE_ACCOUNT
  payload: CandidateAccountConfirmation
}

export interface ConfirmCandidateAccountSuccess {
  type: typeof CONFIRM_CANDIDATE_ACCOUNT_SUCCESS
  payload: CandidateAccountConfirmation
}

export interface ConfirmCandidateAccountError {
  type: typeof CONFIRM_CANDIDATE_ACCOUNT_ERROR
  payload: boolean
}

export interface ConfirmCandidateState {
  data: string | {}
  states: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

export const initialState: ConfirmCandidateState = {
  data: '',
  states: {
    loading: false,
    success: false,
    error: false,
  },
}

export type ConfirmCandidateActions =
  | ConfirmCandidateAccount
  | ConfirmCandidateAccountSuccess
  | ConfirmCandidateAccountError
